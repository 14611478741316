import React, {useContext, useEffect, useState} from 'react'
import './styles/EditSheetBasic.scss'
import Fieldset from '../../../components/Fieldset/Fieldset'
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatepicker'
import NumberInput from '../../../components/NumberInput/NumberInput'
import BoxFooter from '../../../components/BoxFooter/BoxFooter'
import Button from '../../../components/Button/Button'
import SheetContext from '../../../context/sheetContext'
import LightBoxesSheetBasic from './LightBoxesSheetBasic/LightBoxesSheetBasic'
import ContentCreateProspects from '../../../components/ContentCreateProspects/ContentCreateProspects'
import ContentCreatorProductCompanies
	from '../../../components/ContentCreatorProductCompanies/ContentCreatorProductCompanies'
import Origins from '../../../components/Origins/Origins'
import ContentClientDetails from './ContentClientDetails/ContentClientDetails'
import ContentFormats from './ContentFormats/ContentFormats'
import ContentTitleNumber from '../ContentTitleNumber/ContentTitleNumber'
import {putService} from "../../../services/script-api";
import AppContext from "../../../context/appContext"

const NewEditSheet = (props) => {
	const {id, data, type, dataProject} = props

	const sheetContext = useContext(SheetContext)
	const appContext = useContext(AppContext)

	const initialErrorFields = {
		title: false,
		createBy: false,
		receivedAt: false,
		Products: false,
		origin: false,
		format: false,
		seasonNumber: false,
		originalProduct: false,
	}

	const [state, setState] = useState(dataProject.basic)
	const [showConfirmHistory, setShowConfirmHistory] = useState(null)
	const [stateError, setStateError] = useState(initialErrorFields)

	const handleChangeDramaturgy = (ev) => {
		setState({...state, title: ev.target.value})
	}

	const handleBlurDramaturgy = (ev) => {
		setStateError({
			...stateError,
			title: ev.target.value.trim() === '',
		})
	}

	const handleChangeDate = (date) => {
		if (!!date) {
			let finalDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
			setState({...state, receivedAt: finalDate})
		}

		setStateError({
			...stateError,
			receivedAt: !date,
		})
	}

	const getSeason = (season) => {
		setState({
			...state,
			seasonNumber: {
				...state.seasonNumber,
				currentStateSeasonNumber: season,
			}
		})
	}

	const getCreators = (items) => {
		setState({
			...state,
			creators: items,
			notCompileCreators: items
		})
	}

	const getProductionCompanies = (item) => {
		setState({
			...state,
			productionCompanies: item,
			notCompileProductionCompanies: item
		})
	}

	const handleBlurCreators = () =>
		setStateError({...stateError, createBy: !state.creators.length})

	const getClientDetails = (item) => {
		setState({
			...state,
			channel: item,
		})
	}

	const getContentFormats = ({key, value, validate}) => {
		setState({
			...state,
			[key]: value,
		})
		if (validate) {
			setStateError({
				...stateError,
				[key]: !value
			})
		}
	}

	const compileOrigins = (origins) => {
		let a = []
		if (!!origins) {
			origins.forEach((origin) => {
				if(!!origin.label) {
					a.push(origin.id || origin.value)
				}
			})
		}
		return !!a.length ? a : origins
	}

	const compileCreators = (item) => {
		let a = []
		item.forEach((el) => {
			if(!!el.label) {
				a.push({
					creatorId: el.id || el.value,
					origin: el.origin,
				})
			}
		})
		return !!a.length ? a : item
	}

	const compileLabelOrigins = (origins) => {
		let a = []
		if (!!origins.list) {
			origins.list.forEach((origin) => {
				if (origin.isChecked) {
					a.push(origin.value)
				}
			})
		}
		return a
	}

	const getOrigins = (item) => {
		setState({
			...state,
			origins: compileLabelOrigins(item),
		})
		setStateError({
			...stateError,
			origins: !compileLabelOrigins(item).length
		})
	}

	const editIsValid = () => !Object.values(stateError).some(item => !!item)

	useEffect(() => {
		const savedSeason = state.seasonNumber.number
		const currentSeason = state.seasonNumber.currentStateSeasonNumber
		const seasons = []

		sheetContext.seasons.result.forEach((item) => seasons.push(item.seasonNumber))

		setStateError({
			...stateError,
			seasonNumber:
				seasons.includes(currentSeason) && savedSeason !== currentSeason,
		})
		// eslint-disable-next-line
	}, [state.seasonNumber])

	const verifyHistory = (e) => {
		e.preventDefault()
		let bool = state.title === data.title
		return bool ? updateProject(e) : setShowConfirmHistory(!bool)
	}

	const updateProject = (e) => {
		e.preventDefault();
		appContext.setShowGlassLoading(true);

		let obj = {
			...state,
			creators: compileCreators(state.creators),
			productionCompanies: compileOrigins(state.productionCompanies),
		}

		putService(type.endPoint, {...obj, ...dataProject.complementaries, ...dataProject.relevance})
			.then((res) => {
				appContext.setShowGlassLoading(false);
				appContext.setCurrentStepBox("");
				sheetContext._getProjectSheet(id, type.endPoint);
				appContext.sendMessage(
					"Ficha de projeto editada com sucesso!",
					"success"
				);
			})
			.catch(() => {
				appContext.setShowGlassLoading(false);
				appContext.sendMessage("Erro ao editar a ficha do projeto.", "error");
			});
	}

	return (
		<div id="EditSheetBasic">
			<form className="registerForm" onSubmit={(e) => verifyHistory(e)}>
				<div>
					{data.seasonNumber > 1 && (
						<div className="formFieldColumn">
							<Fieldset
								autoComplete="off"
								label="Produto Original"
								type="text"
								placeholder=""
								value={sheetContext.list.result.baseTitle}
							/>
						</div>
					)}

					<div className="formField">
						<ContentTitleNumber
							title={state.title}
							titleError={stateError.title}
							handleChangeDramaturgy={handleChangeDramaturgy}
							handleBlurDramaturgy={handleBlurDramaturgy}
							data={data}
						/>

						<div className="formFieldColumn datepicker">
							<CustomDatePicker
								label="Recebido Em"
								name="receivedAt"
								handleChangeDate={handleChangeDate}
								date={new Date(state.receivedAt)}
								isError={stateError.receivedAt}
							/>

							{stateError.receivedAt && (
								<span className="errorMessage">Este campo é obrigatório</span>
							)}
						</div>
					</div>

					{data.seasonNumber > 1 && (
						<div className="formField">
							<NumberInput
								label="Nº da Temporada"
								number={state.seasonNumber.currentStateSeasonNumber}
								getVersion={getSeason}
								error={stateError.seasonNumber}
								errorMessage="Temporada já existente"
							/>
						</div>
					)}
				</div>

				<div className="formFieldColumn">
					<ContentCreateProspects
						getCreators={getCreators}
						label="Criado por:"
						placeholder="Digitar um nome e apertar ENTER"
						isError={stateError.createBy}
						data={state.notCompileCreators.map((item) => ({
							...item,
							label: item.name || item.label,
							suggesterId: item.id || item.value,
							value: item.id || item.value,
						}))}
						handleBlur={handleBlurCreators}
					/>
				</div>

				<div className="formFieldColumn">
					<ContentCreatorProductCompanies
						getProductionCompanies={getProductionCompanies}
						label="Produtora:"
						placeholder="Digitar um nome e apertar ENTER"
						isError={false}
						data={state.notCompileProductionCompanies.map((item) => ({
							...item,
							label: item.name || item.label,
							value: item.id || item.value,
						}))}
					/>
					<span className="optionalText">Opcional</span>
				</div>

				<div className="formField">
					<div className="checkBoxContainer">
						<span className="label">Procedência</span>
						<Origins dataOrigins={state.origins} getOrigins={getOrigins}/>
					</div>
				</div>

				<ContentClientDetails
					clientDetails={state.channel}
					getClients={getClientDetails}
				/>

				<ContentFormats
					currentFormat={state.formatId}
					chaptersOrEpisodes={state.chaptersOrEpisodes}
					programFrequency={state.programFrequency}
					soapOperaSchedule={state.soapOperaSchedule}
					type={type.endPoint}
					getContentFormats={getContentFormats}
				/>

				<BoxFooter>
					<Button
						text="Salvar"
						toValidate={true}
						isValidForm={editIsValid()}
						classButton="buttonGreen large"
						typeButton="submit"
						dataQA="button-prospect-new-save"
					/>
				</BoxFooter>
			</form>

			<LightBoxesSheetBasic
				showConfirmHistory={showConfirmHistory}
				data={data}
				updateProject={updateProject}
				setShowConfirmHistory={setShowConfirmHistory}
			/>
		</div>
	)
}

export default NewEditSheet

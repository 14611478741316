import React from "react";

const FeedbackIcon = (props) => {
  const { color = "#0098CD", size = 48 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15.6 34.8L14.4 27.6 19.2 27.6 20.4 34.8z"
        />
        <path
          fill={color}
          fillRule="nonzero"
          d="M32.4 12h1.2c0-.43-.23-.828-.605-1.042-.373-.213-.833-.21-1.203.008L32.4 12zm-10.2 6v1.2c.214 0 .424-.058.608-.166L22.2 18zM12 18v-1.2c-.662 0-1.2.538-1.2 1.2H12zm0 9.6h-1.2c0 .662.538 1.2 1.2 1.2v-1.2zm10.2 0l.608-1.034c-.184-.108-.394-.166-.608-.166v1.2zm10.2 6l-.608 1.034c.37.219.83.221 1.203.008.375-.214.605-.611.605-1.042h-1.2zm-.608-22.634l-10.2 6 1.216 2.068 10.2-6-1.216-2.068zM22.2 16.8H12v2.4h10.2v-2.4zM10.8 18v9.6h2.4V18h-2.4zM12 28.8h10.2v-2.4H12v2.4zm9.592-.166l10.2 6 1.216-2.068-10.2-6-1.216 2.068zM33.6 33.6V12h-2.4v21.6h2.4z"
        />
        <path stroke={color} strokeWidth="2" d="M21.6 18L21.6 27.6" />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M36.648 18.552c1.124 1.126 1.757 2.65 1.757 4.242 0 1.591-.633 3.116-1.757 4.242"
        />
      </g>
    </svg>
  );
};

export default FeedbackIcon;

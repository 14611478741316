import React from "react";

const ParameterizedSearchIcon = (props) => {
  const { color = "#17B4E1" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="24"
      viewBox="0 0 20 24"
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g stroke={color} strokeWidth="2">
          <g>
            <g>
              <path
                d="M3 18L3 11M3 7L3 0M11 18L11 9M11 5L11 0M19 18L19 13M19 9L19 0M0 11L6 11M8 5L14 5M16.5 13L22 13"
                transform="translate(-537 -411) translate(350 214) rotate(90 4 202)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ParameterizedSearchIcon;

import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import CkEditor from "../../CkEditor/CkEditor";

const FreeText = (props) => {
  const { handleFocus, setter, type, index, data, isEditable } = props;

  const [state, setState] = useState(data);

  const handleChangeText = (ev, value) => {
    setState({
      ...state,
      text: value,
    });
  };

  const handleChangeTitle = (ev) => {
    setState({
      ...state,
      title: ev.target.value,
    });
  };

  useEffect(() => {
    setter(type, state, index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="fieldContainer">
      <div className="fieldHeader">
        <div className="fieldTitle">
          {isEditable ? (
            <TextareaAutosize
              onChange={handleChangeTitle}
              value={!!state.title ? state.title : data.title}
              placeholder="Escreva aqui o seu título"
              onFocus={() => handleFocus(true)}
              onBlur={() => handleFocus(false)}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: data.title }} />
          )}
        </div>
      </div>

      <div className="editorArea">
        {isEditable ? (
          <CkEditor
            onChange={handleChangeText}
            onFocus={() => handleFocus(true)}
            onBlur={() => handleFocus(false)}
            data={data}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: data.text }} />
        )}
      </div>
    </div>
  );
};

export default FreeText;

import React, { useContext } from "react";
import AppContext from "../../../../context/appContext";
import InfoPaper from "./InfoPaper/InfoPaper";
import ContentInfo from "./ContentInfo/ContentInfo";
import ProjectSheetTitle from "./Title/ProjectSheetTitle";
import TagsProject from "./TagsProject/TagsProject";

const InfoPaperExternalProject = ({ list }) => {
  const appContext = useContext(AppContext);

  return (
    <>
      <InfoPaper
        handleClick={() =>
          appContext.setCurrentStepBox("EditBasicProjectSheet")
        }
      >
        <ProjectSheetTitle title="Dados Básicos" />
        <div className="space" />
        <ContentInfo label="CANAL" value={list?.channels?.[0].channel.name} />
        <ContentInfo label="TÍTULO" value={list.title} />
        <ContentInfo label="FORMATO" value={list?.format?.name} />
        <ContentInfo label="EPISÓDIOS" value={list?.chaptersOrEpisodes} />
        <ContentInfo
          label="MINUTAGEM"
          value={`${list?.complementaryData?.minutes} minutos`}
        />
        <ContentInfo
          label="PÚBLICO ALVO"
          value={list?.complementaryData?.targetAudience}
        />
        <ContentInfo label="TAGS">
          <TagsProject tags={list.tags} />
        </ContentInfo>
        <ContentInfo label="PRAZO ESTIMADO" value={list?.deadline?.name} />
      </InfoPaper>

      <InfoPaper>
        <ProjectSheetTitle title="Dados Complementares" />
        <div className="space" />
        <ContentInfo label="TAGLINE" value={list.complementaryData?.tagline} />
        <ContentInfo label="STORYLINE" value={list?.storyLine} />
        <ContentInfo
          label="SINOPSE GERAL DA SÉRIE"
          value={list.complementaryData?.summary}
        />
        <ContentInfo
          label="SINOPSE REDUZIDA DA SÉRIE"
          value={list?.complementaryData?.smallSummary}
        />
        <ContentInfo
          label="DIFERENCIAL DA SÉRIE"
          value={`${
            !!list.complementaryData && list.complementaryData?.differential
          }`}
        />
        <ContentInfo
          label="BREVE CURRÍCULO ROTEIRISTAS"
          value={`${
            !!list.complementaryData && list.complementaryData?.writerCurriculum
          }`}
        />
        <ContentInfo
          label="BREVE CURRÍCULO DIREÇÃO"
          value={`${
            !!list.complementaryData &&
            list.complementaryData?.directorCurriculum
          }`}
        />
      </InfoPaper>
    </>
  );
};

export default InfoPaperExternalProject;

import React, { useContext, useEffect, useState } from "react";
import "./styles/ProjectSheet.scss";
import AppContext from "../../context/appContext";
import ProjectSheetContext from "../../context/sheetContext";
import UserContext from "../../context/userContext";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import TypeIconReturner from "../../components/TypeIconReturner/TypeIconReturner";
import CloudIcon from "../../components/VectorIcons/CloudIcon";
import EditIcon from "../../components/VectorIcons/EditIcon";
import AttachmentIcon from "../../components/VectorIcons/AttachmentIcon";
import ScriptBox from "../../components/ScriptBox/ScriptBox";
import InfoPaperBasic from "./InfoPaper/InfoPaperBasic/InfoPaperBasic";
import InfoPaperComplementary from "./InfoPaper/InfoPaperComplementary/InfoPaperComplementary";
import InfoCard from "../../components/InfoCard/InfoCard";
import TableLoading from "../../components/TableLoading/TableLoading";
import EditSheetBasicResearch from "./EditSheetBasic/EditSheetBasicResearch";
import TableListFilesResearch from "./ListFiles/TableListFilesResearch";
import ConfirmationBox from "../../components/ConfirmationBox/ConfirmationBox";
import {
  deleteService,
  postService,
  putService,
  getService,
} from "../../services/script-api";
import SimpleScriptBox from "./SimpleScriptBox/SimpleScriptBox";
import EditFileResearch from "./EditFile/EditFileResearch";
import UploadService from "../../components/UploadService/UploadService";
import BoxFooter from "../../components/BoxFooter/BoxFooter";
import Button from "../../components/Button/Button";
import UploadSection from "../../components/UploadSection/UploadSection";
import RelatedContentCard from "../../components/RelatedContentCard/RelatedContentCard";
import ScriptBoxCaller from "../../components/ScriptBoxCaller/ScriptBoxCaller";
import ResearchDetails from "./ResearchDetails/ResearchDetails";
import EditResearchRelatedContent from "./EditRelatedContent/EditResearchRelatedContent";
import LoadError from "../../components/LoadError/LoadError";

const ProjectSheetResearch = (props) => {
  const appContext = useContext(AppContext);
  const sheetContext = useContext(ProjectSheetContext);
  const userContext = useContext(UserContext);

  const [stateConfirmationBox, setStateConfirmationBox] = useState({
    open: false,
    currentFile: "",
  });
  const [stateEditFileBox, setStateEditFileBox] = useState({
    open: false,
    currentFile: "",
  });
  const [stateFiles, setStateFiles] = useState([]);
  const [stateResearchTypes, setStateReseachTypes] = useState([]);
  const [stateOriginsOptions, setStateOriginsOptions] = useState([]);
  const [stateAreaThesaurus, setStateAreaThesaurus] = useState([]);

  const getDuplicateFiles = (files) => {
    let filesName = "";
    files.map((e) => (filesName += `<p>${e.name} ja foi selecionado!</p>`));
    appContext.sendMessage(filesName, "error");
  };

  useEffect(() => {
    let body = document.querySelector("body");
    body.scrollTo(0, 0);
    sheetContext._getProjectSheetResearch(props.match.params.id, "researches");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  useEffect(() => {
    getTypes();
    getOrigins();
    getThesaurus(23, "researches/thesaurus", setStateAreaThesaurus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTypes = () => {
    getService("researches/data/types").then((response) => {
      setStateReseachTypes(response.data);
    });
  };

  const getOrigins = () => {
    getService("researches/data/origins").then((response) => {
      setStateOriginsOptions(response.data);
    });
  };

  const getThesaurus = (id, url, setter) => {
    getService(`${url}?origins=${id}`).then((list) => {
      const _list = list.data.map((item) => ({
        value: item.id,
        label: item.name,
        origin: origin,
      }));
      setter(_list);
    });
  };

  const handleDeleteFile = (file) => {
    deleteService("contents/attachments", file.id).then(() => {
      appContext.sendMessage(`Arquivo ${file.fileName} deletado com sucesso`);
      setStateConfirmationBox({
        ...stateConfirmationBox,
        open: false,
      });
      sheetContext._getProjectSheetResearch(
        props.match.params.id,
        "researches"
      );
    });
  };

  const handleConfirmationDeleteFile = (item) => {
    setStateConfirmationBox({
      ...stateConfirmationBox,
      open: true,
      currentFile: item,
    });
  };

  const closedBox = () => {
    setStateConfirmationBox({
      ...stateConfirmationBox,
      open: false,
    });
  };

  const handleEditFile = (item) => {
    setStateEditFileBox({
      ...stateEditFileBox,
      open: true,
      currentFile: item,
    });
  };

  const sendEditFile = (obj) => {
    appContext.setShowGlassLoading(true);
    putService("contents/attachments", obj)
      .then(() => {
        setStateEditFileBox({
          ...stateEditFileBox,
          open: false,
        });
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(
          `Arquivo ${stateEditFileBox.currentFile.fileName} atualizado com sucesso`
        );
        sheetContext._getProjectSheetResearch(
          props.match.params.id,
          "researches"
        );
      })
      .catch(() => {
        appContext.sendMessage(
          `Erro ao tentar atualizar ${stateEditFileBox.currentFile.fileName}!`,
          "error"
        );
        appContext.setShowGlassLoading(false);
      });
  };

  const closedBoxEdit = () => {
    setStateEditFileBox({
      ...stateEditFileBox,
      open: false,
    });
  };

  if (sheetContext.listResearch.loading) {
    return <TableLoading />;
  }

  const headerList = [
    { label: "Tipo", className: "" },
    { label: "Versao", className: "large" },
    { label: "Artigo", className: "editItem" },
    { label: "RECEBIDO EM", className: "editItem" },
  ];

  const list = sheetContext.listResearch.result;

  if (list === undefined) return <LoadError />;

  const getFiles = (files) => {
    setStateFiles({
      ...stateFiles,
      files,
    });
  };

  const sendFile = () => {
    let obj = [];

    stateFiles.files.forEach((file) => {
      let _arr = {
        contentId: props.match.params.id,
        fileId: file._meta.tempFileName,
        fileName: file.originalName,
        version: file.version,
        receivedAt: file.receiveAt,
        attachmentTypeId: file.attachmentTypeId,
        blockNumber: file.blockNumber,
        chapterNumber: file.chapterNumber,
        genresIds: [0],
        spacesIds: [0],
        contentOrigns: [
          {
            contentOriginId: 0,
            contentOriginDescriptions: ["string"],
          },
        ],
        narratives: [0],
      };
      obj.push(_arr);
    });

    appContext.setShowGlassLoading(true);

    postService("contents/attachments", obj)
      .then(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Arquivo cadastrado com sucesso!");
        appContext.setCurrentStepBox("closed");
        sheetContext._getProjectSheetResearch(
          props.match.params.id,
          "researches"
        );
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Erro ao cadastrar o arquivo!", "error");
        appContext.setCurrentStepBox("closed");
        sheetContext._getProjectSheetResearch(
          props.match.params.id,
          "researches"
        );
      });
  };

  const uploadFilesIsValid = () =>
    !!stateFiles.files &&
    !!stateFiles.files.length &&
    stateFiles.files.every((stateFile) => stateFile.isSent === true);

  const compileResearchedFrom = () =>
    [...list.fromAreas, ...list.fromAnalysts, ...list.fromResearchers].filter(
      (item) => !!item.value
    );
  const compileResearchedTo = () =>
    [...list.toAreas, ...list.toCreators].filter((item) => !!item.value);

  const isValidComplementary = () =>
    !!list.origin ||
    !!compileResearchedFrom().length ||
    !!compileResearchedTo().length;

  const researchType = () => stateResearchTypes[list.type - 1].value;

  return (
    <div id="Project">
      <Breadcrumb>
        <div className="projectHeader researchHeader">
          <div className="projectTitle">
            <TypeIconReturner
              type="Pesquisa"
              showBackgroundColor={true}
              responsiveIcon={true}
            />

            <div className="projectResume">
              <h2 className="title">{list.title || ""}</h2>
            </div>
          </div>

          <div className="projectSubtitle">
            <div className="projectType">
              <span>{!!stateResearchTypes.length ? researchType() : ""}</span>
              <div className="dotSeparator" />
              <span>{list.subCategory.category.value || ""}</span>
              <div className="dotSeparator" />
              <span>{list.subCategory.value || ""}</span>
            </div>
          </div>
        </div>
      </Breadcrumb>

      <div className="mainContainer">
        <div className="infoContainer">
          <InfoPaperBasic
            callerEvent={() => appContext.setCurrentStepBox("editBasicData")}
            callerIcon={<EditIcon />}
            havePermission={
              userContext.havePermission(["Researches"]).length === 0
            }
            paperTitle="Dados Básicos"
            projectTitle={list.title}
            type="Pesquisa"
            data={list}
            category={list.subCategory.category.value}
            subCategory={list.subCategory.value}
          />

          {list.subCategory.category.value !== "Publicação" && (
            <InfoPaperComplementary
              callerEvent={() =>
                appContext.setCurrentStepBox("editResearchDetails")
              }
              callerIcon={<EditIcon />}
              havePermission={
                userContext.havePermission(["Researches"]).length === 0
              }
              paperTitle="Detalhes da demanda"
              origin={stateOriginsOptions.filter(
                (item) => item.id === list.origin
              )}
              data={list}
              researchedFrom={compileResearchedFrom()}
              researchedTo={compileResearchedTo()}
              isValid={isValidComplementary()}
            />
          )}
        </div>

        <div className="cardsContainer">
          <InfoCard
            callerEvent={[
              () => appContext.setCurrentStepBox("uploadAttachment"),
              () => appContext.setCurrentStepBox("editAttachment"),
            ]}
            callerIcon={<CloudIcon />}
            cardTitle="Anexos"
            files={list.attachments}
            havePermission={
              userContext.havePermission(["Researches"]).length === 0
            }
          />
        </div>
      </div>

      {list.subCategory.category.value !== "Publicação" && (
        <div className="relatedContentContainer">
          <div className="contentHeader">
            <span className="contentTitle">Conteúdo relacionado</span>

            {userContext.havePermission(["Researches"]).length === 0 && (
              <ScriptBoxCaller
                callerIcon={<EditIcon />}
                event={() => appContext.setCurrentStepBox("editRelatedContent")}
              />
            )}
          </div>

          <div className="contentCardsContainer">
            {list.associations.map((association, index) => {
              return (
                <RelatedContentCard
                  key={index}
                  id={association.contentId}
                  contentType={association.contentType}
                  cardTitle={!!association.type ? association.type.name : ""}
                  productName={association.title}
                  format={association.format}
                  soapOperaSchedule={association.soapOperaSchedule}
                  editable={association.editable}
                />
              );
            })}
          </div>
        </div>
      )}

      {appContext.current.step === "editBasicData" && (
        <ScriptBox
          open={appContext.current.step === "editBasicData"}
          title="Editar Dados Básicos"
          icon={<EditIcon color="#FFF" />}
          marginBottom={true}
        >
          <EditSheetBasicResearch
            id={props.match.params.id}
            data={list}
            researchTypes={stateResearchTypes}
          />
        </ScriptBox>
      )}

      {appContext.current.step === "editResearchDetails" && (
        <ScriptBox
          open={appContext.current.step === "editResearchDetails"}
          title="Editar Dados da Demanda"
          icon={<EditIcon color="#FFF" />}
          marginBottom={true}
        >
          <ResearchDetails
            data={list}
            title={list.title}
            id={props.match.params.id}
            origins={stateOriginsOptions}
            areaThesaurus={stateAreaThesaurus}
          />
        </ScriptBox>
      )}

      {appContext.current.step === "editRelatedContent" && (
        <ScriptBox
          open={appContext.current.step === "editRelatedContent"}
          title="Editar Conteúdo Relacionado"
          icon={<EditIcon color="#FFF" />}
          marginBottom={true}
        >
          <EditResearchRelatedContent
            data={list}
            title={list.title}
            id={props.match.params.id}
          />
        </ScriptBox>
      )}

      {appContext.current.step === "uploadAttachment" && (
        <ScriptBox
          open={appContext.current.step === "uploadAttachment"}
          title="Novo Anexo"
          icon={<AttachmentIcon color="#FFF" />}
          marginBottom={true}
        >
          <div className="scriptBoxUpload">
            <UploadSection>
              <UploadService
                handleOnComplete={getFiles}
                getDuplicateFiles={getDuplicateFiles}
              />
            </UploadSection>
          </div>

          <BoxFooter>
            <div onClick={() => sendFile()}>
              <Button
                text="Salvar"
                toValidate={true}
                isValidForm={uploadFilesIsValid()}
                classButton="buttonGreen large"
                typeButton="submit"
                dataQA="button-prospect-edit"
              />
            </div>
          </BoxFooter>
        </ScriptBox>
      )}

      {appContext.current.step === "editAttachment" && (
        <ScriptBox
          open={appContext.current.step === "editAttachment"}
          icon={<AttachmentIcon color="#FFF" />}
          removePadding={true}
          title="Anexos"
          size="large"
        >
          <TableListFilesResearch
            headerList={headerList}
            handleEditFile={handleEditFile}
            handleConfirmationDeleteFile={handleConfirmationDeleteFile}
            renderCreatedAt={true}
            data={list.attachments}
            havePermission={
              userContext.havePermission(["Researches"]).length === 0
            }
          />
        </ScriptBox>
      )}

      {stateConfirmationBox.open && (
        <ConfirmationBox
          open={stateConfirmationBox.open}
          textButton="Sim, excluir"
          closedBox={closedBox}
          handleEvent={() => handleDeleteFile(stateConfirmationBox.currentFile)}
          title="Tem certeza?"
          text="Este anexo será excluído permanentemente do sistema e essa ação não poderá ser desfeita"
        />
      )}

      {stateEditFileBox.open && (
        <SimpleScriptBox
          open={stateEditFileBox.open}
          toggleScriptBox={() => closedBoxEdit()}
          title={stateEditFileBox.currentFile.fileName}
          icon={<EditIcon color="#FFF" />}
          removePadding={true}
          allowOverflow={true}
        >
          <EditFileResearch
            fileName={stateEditFileBox.currentFile.fileName}
            handleChangeDate={() => {}}
            value={1}
            file={stateEditFileBox.currentFile}
            sendFileEdit={sendEditFile}
          />
        </SimpleScriptBox>
      )}
    </div>
  );
};

export default ProjectSheetResearch;

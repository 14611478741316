import React, { useEffect, useState } from "react";
import { getService } from "../../../services/script-api";
import _ from "lodash";
import FeedbackModal from "./FeedbackModal/FeedbackModal";

const EditFeedback = (props) => {
  const { currentActivity, type, _getActvity, open } = props;

  const [stateProjectAttachment, setStateProjectAttachment] = useState({
    loading: true,
    files: [],
  });
  const [stateActivityAttachment, setStateActivityAttachment] = useState({
    loading: true,
    files: [],
  });
  const [stateFiles, setStateFiles] = useState([]);
  const [stateContentInfo, setStateContentInfo] = useState({
    loading: true,
    info: {},
  });

  const getAttachmentsProjects = () => {
    // getService(`projects/${currentActivity.projectId}/activities/attachments`).then((activity) => {
    getService(`contents/${currentActivity.projectId}/attachments`).then(
      (activity) => {
        setStateProjectAttachment({
          ...stateProjectAttachment,
          loading: false,
          files: activity.data.attachments,
        });
      }
    );
  };

  const getAttachmentsActivity = () => {
    getService(`activities/${currentActivity.id}`).then((activity) => {
      setStateActivityAttachment({
        ...stateActivityAttachment,
        loading: false,
        files: activity.data.attachments,
      });
      setStateContentInfo({
        loading: false,
        info: {
          title: activity.data.contentTitle,
          creators: activity.data.creators,
          format: activity.data.format,
          category: !!activity.data.subCategory
            ? activity.data.subCategory.value
            : "",
          subCategory: !!activity.data.subCategory
            ? activity.data.subCategory.category.value
            : "",
          description: activity.data.description,
        },
      });
    });
  };

  const isComplementaryMaterial = (name) => name === "Material Complementar"

  const compileAll = (data) => {
    let groupall = _.cloneDeep(data);
    return _.groupBy(
      groupall.filter((e) => {
        return (
          !!e &&
          !isComplementaryMaterial(e?.attachmentType?.name) &&
          e?.attachmentType?.name !== "Episódio"
        );
      }),
      (u) => u?.attachmentType?.id
    );
  };

  const compileMaterial = (data) => {
    let emptyArray = [];
    let compiledMaterial = _.cloneDeep(data);
    let material = compiledMaterial.filter(
      (e) => !!e && isComplementaryMaterial(e.attachmentType.name)
    );
    compiledMaterial.filter(
      (e) => !!e && isComplementaryMaterial(e.attachmentType.name)
    );
    material.forEach((e) => {
      emptyArray.push([e]);
    });

    return emptyArray;
  };

  const compiledChapter = (data) => {
    let chapter = _.cloneDeep(data);
    let chapterFilter = chapter.filter(
      (e) => e.attachmentType.name === "Capítulo/Episódio"
    );
    return _.groupBy(
      chapterFilter,
      (u) => u.blockNumber && u.chapterNumber
    );
  };

  const toSeparateVersion = (files) => {
    let obj = [];

    files.forEach((file) => {
      obj.push({ parent: file[0], version: file.filter((e) => e !== file[0]) });
    });

    return obj;
  };

  const compileFiles = (data) => {
    let allAgroup = compileAll(data);
    let materialCompiled = compileMaterial(data);
    let chapterCompiled = compiledChapter(data);

    let mergeGroups = { ...materialCompiled, ...allAgroup, ...chapterCompiled };

    setStateFiles(
      toSeparateVersion(Object.entries(mergeGroups).map((files) => files[1]))
    );
  };

  const setActiveAttachments = (files) => {
    files.forEach((file) => {
      stateActivityAttachment.files.forEach((item) => {
        if (item.fileId === file.fileId) {
          file.isActive = true;
        }
      });
    });

    compileFiles(files);
  };

  let _files = [];

  const makeOlderVersions = (file) => {
    if (!!file.olderVersions?.length) {
      file.olderVersions.forEach((f) => {
        f.isActive = false;
        _files.push(f);
      });
    }
  };

  const makeAttachment = (file) => {
    if (file.attachment) {
      file.attachment.isActive = false;
      _files.push(file.attachment);
    }
  };

  const unifyFiles = () => {
    stateProjectAttachment.files.forEach((file, index) => {
      makeOlderVersions(file);
      makeAttachment(file);
    });

    setActiveAttachments(_files);
  };

  const clearActive = () => {
    let newState = [...stateFiles];
    newState.forEach((file) => {
      file.parent.isActive = false;
      file.version.forEach((v) => (v.isActive = false));
    });

    setStateFiles(newState);
  };

  useEffect(() => {
    if (type !== "Pesquisa") {
      getAttachmentsProjects();
    }
    getAttachmentsActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!stateProjectAttachment.loading && !stateActivityAttachment.loading) {
      // makeActiveAttachments()
      unifyFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProjectAttachment, stateActivityAttachment]);

  return (
    <FeedbackModal
      files={stateFiles}
      clearActive={clearActive}
      type={type}
      _getActvity={_getActvity}
      currentActivity={currentActivity}
      open={open}
      loading={
        !!stateActivityAttachment.loading && !!stateProjectAttachment.loading
      }
      contentInfo={stateContentInfo}
    />
  );
};

export default EditFeedback;

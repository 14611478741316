import React, { useState, useEffect, useContext } from "react";
import "./styles/ProjectPhases.scss";
import PhaseItem from "./PhaseItem/PhaseItem";
import _ from "lodash";
import SheetContext from "../../../context/sheetContext";

const ProjectPhases = (props) => {

  const { phases } = props;
  const [statePhase, setStatePhase] = useState([]);
  const sheetContext = useContext(SheetContext);

  let order = [
    "FOMENTO",
    "DESENVOLVIMENTO",
    "PROJETO (PRODUTORA)",
    "PRÉ PRODUÇÃO",
    "PRODUÇÃO",
    "PÓS PRODUÇÃO",
    "DISTRIBUIÇÃO (EXIBIÇÃO)",
    "DESPRODUÇÃO",
    "MIGRACAO AGRA",
  ];

  const reorderPhases = () => setStatePhase(_.groupBy(phases.list, (u) => u.phaseType));

  useEffect(() => {
    reorderPhases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phases]);

  return (
    <div className="projectPhases">
      {statePhase.length !== 0 &&
        order.map((type, index) => {
          return (
            <React.Fragment key={index}>
              {!!statePhase[type] && (
                <PhaseItem
                  key={index}
                  phaseTitle={
                    !!statePhase[type] &&
                    sheetContext.phasesFromTo(statePhase[type][0].phaseType)
                  }
                  phaseInfo={statePhase[type]}
                />
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default ProjectPhases;

import React from "react";
import "../styles/InfoPaper.scss";
import ScriptBoxCaller from "../../../../components/ScriptBoxCaller/ScriptBoxCaller";
import IsValid from "../isValid/IsValid";

const ContainerPaperRelevance = (props) => {
  const { types } = props;

  const setTypes = (index) => {
    let t = { 0: "Trama", 1: "Temática", 2: "Universo" };
    return t[index];
  };

  return types.map((item, i) => {
    return (
      <React.Fragment key={i}>
        {Object.values(item).some((e) => !!e.length) && (
          <div className="infoField">
            <span className="infoName">{setTypes(i)}</span>
            <div className="infoDataList">
              {!!item.primaries.length && (
                <Position items={item.primaries} position="Principal" />
              )}
              {!!item.secundaries.length && (
                <Position items={item.secundaries} position="Secundária" />
              )}
              {!!item.tertiaries.length && (
                <Position items={item.tertiaries} position="Terciária" />
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  });
};

const Position = (props) => {
  const { items, position } = props;

  return (
    <>
      {!!items.length && (
        <div className="infoData">
          <div className="contentData">
            <span className="contentOriginName">{position}</span>
            <div className="descriptions">
              {items.map((item, index) => {
                return (
                  <span className="descriptionName" key={index}>
                    {item.name}
                    {index + 1 < items.length ? (
                      <span className="originSeparator">,</span>
                    ) : null}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const InfoPaperRelevance = (props) => {
  const {
    paperTitle,
    callerIcon,
    callerEvent,
    plots,
    thematics,
    universes,
    isValid,
    havePermission,
  } = props;

  if (!isValid) {
    return (
      <IsValid
        callerEvent={callerEvent}
        callerIcon={callerIcon}
        paperTitle={paperTitle}
        msg="Ainda não há informações cadastras."
        havePermission={havePermission}
      />
    );
  }

  return (
    <div className="infoPaper">
      <div className="paper">
        <div className="paperTitle">
          <span>{paperTitle}</span>
          {!!callerEvent && havePermission && (
            <ScriptBoxCaller callerIcon={callerIcon} event={callerEvent} />
          )}
        </div>
        <div className="infoFieldContainer">
          <ContainerPaperRelevance types={[plots, thematics, universes]} />
        </div>
      </div>
    </div>
  );
};

export default InfoPaperRelevance;

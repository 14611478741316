import React from "react";

const ThesaurusBook = (props) => {
  const { color = "#000" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      stroke={color}
                      strokeWidth="2"
                      d="M4 0h12v14H0V4c0-2.21 1.79-4 4-4zM17 14H2c-1.105 0-2 .895-2 2s.895 2 2 2h15"
                      transform="translate(-960 -321) translate(256 81) translate(23 154) translate(0 42) translate(669 34) translate(13 11)"
                    />
                    <path
                      stroke={color}
                      strokeWidth="2"
                      d="M17 14c-1.105 0-2 .895-2 2s.895 2 2 2h0"
                      transform="translate(-960 -321) translate(256 81) translate(23 154) translate(0 42) translate(669 34) translate(13 11)"
                    />
                    <text
                      fill={color}
                      fontFamily="GlobotipoTexto-Regular, Globotipo Texto"
                      fontSize="8"
                      transform="translate(-960 -321) translate(256 81) translate(23 154) translate(0 42) translate(669 34) translate(13 11)"
                    >
                      <tspan x="3.456" y="10">
                        Aa
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ThesaurusBook;

import React, { useEffect } from "react";
import "./styles/OverflowMenu.scss";

const OverflowMenu = (props) => {
  const { children, dotsColor = "#A9AAB8" } = props;

  useEffect(() => {
    window.addEventListener("click", function (event) {
      const target = event.target.closest(".overflowMenu");
      const activeClassName = document.querySelector(".openedOverflowMenu");

      if (!target) {
        if (!!activeClassName) {
          activeClassName.classList.remove("openedOverflowMenu");
        }
      }
    });
  }, []);

  const toggleOverflowMenu = (event) => {
    const target = event.target.closest(".overflowMenu");
    const activeClassName = document.querySelector(".openedOverflowMenu");

    if (!!target.classList.contains("openedOverflowMenu")) {
      target.classList.remove("openedOverflowMenu");
    } else {
      if (!!activeClassName) {
        activeClassName.classList.remove("openedOverflowMenu");
      }
      target.classList.add("openedOverflowMenu");
    }
  };

  return (
    <div className="overflowMenu" onClick={(ev) => toggleOverflowMenu(ev)}>
      <svg
        fill={dotsColor}
        height={24}
        stroke={dotsColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        width={24}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="1" />
        <circle cx="12" cy="5" r="1" />
        <circle cx="12" cy="19" r="1" />
      </svg>
      <div className="menu">{children}</div>
    </div>
  );
};

export default OverflowMenu;

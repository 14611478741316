import React, { useContext, useEffect, useState } from 'react'
import './styles/ParameterizedSearchModal.scss'
import ScriptBox from '../../../components/ScriptBox/ScriptBox'
import RadioInput from '../../../components/RadioInput/RadioInput'
import Masks from '../../../components/VectorIcons/Masks'
import Microphone from '../../../components/VectorIcons/Microphone'
import Research from '../../../components/VectorIcons/ResearchesIcon'
import CommomParameters from './CommomParameters/CommomParameters'
import DramaturgyParameters from './DramaturgyParameters/DramaturgyParameters'
import VarietiesParameters from './VarietiesParameters/VarietiesParameters'
import { getService } from '../../../services/script-api'
import AppContext from '../../../context/appContext'
import { Redirect } from 'react-router-dom'
import { isArray } from 'lodash'
import ClearFields from './ClearFields/ClearFields'
import Button from '../../../components/Button/Button'

const ParameterizedSearchModal = (props) => {
  const [stateFilter, setStateFilter] = useState({})
  const [stateOrigins, setStateOrigins] = useState({
    data: [],
    loading: true,
    current: '',
  })
  const [stateRedirect, setStateRedirect] = useState(false)
  const [stateIsValid, setStateIsValid] = useState(false)
  const [stateClear, setStateClear] = useState(false)
  const appContext = useContext(AppContext)

  const { open, clear } = props

  const [typeParameter, setTypeParameter] = useState('')

  const typeOptions = [
    {
      id: 'Dramaturgia',
      value: 'Projetos de Dramaturgia',
      contentType: 'Dramaturgia',
      icon: <Masks color="#EC1045"/>,
      path: 'dramaturgies/filter',
    },
    {
      id: 'Variedades',
      value: 'Projetos de Variedades',
      contentType: 'Variedades',
      icon: <Microphone color="#2D76D4"/>,
      path: 'varieties/filter',
    },
    {
      id: 'Pesquisa',
      value: 'Pesquisa',
      contentType: 'Pesquisa',
      icon: <Research color="#009C69"/>,
      path: 'researches/filter',
    },
  ]

  const handleChange = (e) => {
    setStateFilter({
      ...stateFilter,
      [e.target.name]: e.target.value === 'placeholder' ? null : e.target.value,
    })
  }

  useEffect(() => {
    if (stateFilter.origins !== 'Mercado') {
      setStateFilter({
        ...stateFilter,
        ProductionCompaniesLabel: [],
        ProductionCompanies: [],
      })
    }
    // eslint-disable-next-line
  }, [stateFilter.origins])

  const handleChangeOrigins = (value) => {
    setStateFilter({
      ...stateFilter,
      origins: value,
    })
  }

  const handleChangeTypeParameter = (id, value, path) => {
    appContext.setCurrentTypePath(path)
    setTypeParameter(id)
    appContext.setCurrentSearch(id)
    clearTypesParams()
  }

  const getOptionsProductionCompanies = (value) => {
    setStateFilter({
      ...stateFilter,
      ProductionCompanies: value,
      ProductionCompaniesLabel: !!value ? value : [],
    })
  }

  const getOptionsCreators = (value) => {
    const urlValues = []
    value.forEach((item) => {
      urlValues.push({
        CreatorId: item.suggesterId,
        Name: item.label,
        Origin: item.origin,
      })
    })
    setStateFilter({
      ...stateFilter,
      creators: urlValues,
      creatorsLabel: !!value ? value : [],
    })
  }

  const handleChangeDate = (date, name) => {
    setStateFilter({
      ...stateFilter,
      [name]: date,
    })
  }

  const formatOrigins = (data) => {
    let list = []
    if (!!data.length) {
      data.forEach((e) => {
        list.push({
          id: e.value,
          value: e.value,
          name: e.value,
        })
      })
    }
    return list
  }

  const getOrigins = () => {
    getService('projects/data/origins').then((res) => {
      setStateOrigins({
        ...stateOrigins,
        loading: false,
        data: formatOrigins(res.data),
      })
    })
  }

  const setFilters = (ev) => {
    ev.preventDefault()
    if(stateIsValid) {
      !typeParameter && appContext.setCurrentTypePath('contents/filter')
      appContext.setFilterSearch(stateFilter)
      appContext.setParameterized(true)
      setStateRedirect(true)
    }
  }

  useEffect(() => {
    if (!!stateRedirect) {
      appContext.setCurrentStepBox('closed')
    }
    // eslint-disable-next-line
  }, [stateRedirect])

  const getStateComplementaries = (item) => {
    let f = { ...item, FormatId: item.listComplementaries.FormatId?.value[0] }
    if (!!item) {
      setStateFilter({
        ...stateFilter,
        ...f,
      })
    }
  }

  useEffect(() => {
    getOrigins()
    // appContext.setCurrentTypePath("contents/filter")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clearAll = () => {
    setTypeParameter('')
    appContext.setCurrentSearch('')
    setStateFilter({})
    setStateClear(true)
    // appContext.setCurrentSearch("Todos")
    appContext.setCurrentTypePath('contents/filter')
  }

  const clearTypesParams = () => {
    setStateFilter({})
    setStateClear(true)
  }

  useEffect(() => {
    const typeIsValid = !!typeParameter
    const fieldsIsValid = Object.values(stateFilter).some((item) =>
      isArray(item) ? !!item.length : !!item
    )
    setStateIsValid(typeIsValid || fieldsIsValid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFilter, typeParameter])

  useEffect(() => {
    getOrigins()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTypeParameter(appContext.currentTypeSearch)
    setStateFilter({
      ...stateFilter,
      ...appContext.filter,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.filter])

  return (
    <div id="ParameterizedSearchModal">
      <form onSubmit={(e) => setFilters(e)}>
        <ScriptBox
          title="Busca Parametrizada"
          open={open}
          clear={clear}
          removePadding={true}
        >
          <div className="parameterizedModalContent">
            {stateIsValid && <ClearFields handleClick={clearAll}/>}

            <div className="parametersArea">
              <div id="TypeParameter">
                <RadioInput
                  label="Tipo do projeto"
                  options={typeOptions}
                  value={typeParameter}
                  handleChange={(id, value, path) =>
                    handleChangeTypeParameter(id, value, path)
                  }
                  isValidForm={stateIsValid}
                />
              </div>

              <CommomParameters
                handleChange={handleChange}
                handleChangeOrigins={handleChangeOrigins}
                type={typeParameter}
                origins={stateOrigins}
                currentOrigin={stateFilter.origins}
                creators={stateFilter.creatorsLabel}
                productionCompanies={stateFilter.ProductionCompaniesLabel}
                getOptionsProductionCompanies={getOptionsProductionCompanies}
                getOptionsCreators={getOptionsCreators}
                handleChangeDate={handleChangeDate}
                filter={stateFilter}
                stateClear={stateClear}
                setStateClear={setStateClear}
              />

              {typeParameter === 'Dramaturgia' && (
                <DramaturgyParameters
                  values={stateFilter.listComplementaries}
                  getStateComplementaries={getStateComplementaries}
                />
              )}

              {typeParameter === 'Variedades' && (
                <VarietiesParameters
                  values={stateFilter.listComplementaries}
                  getStateComplementaries={getStateComplementaries}
                />
              )}
            </div>
          </div>

          <div className="parameterizedModalFooter">
            <Button
              text="Ver Resultados"
              classButton="buttonGreen largeRound"
              isValidForm={stateIsValid}
              toValidate={true}
              typeButton="subimit"
            />
          </div>
        </ScriptBox>
      </form>

      {stateRedirect && <Redirect to="/busca"/>}
    </div>
  )
}

export default ParameterizedSearchModal

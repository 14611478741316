import React from "react";
import "./styles/Switch.scss";

const Switch = (props) => {
  const { switchOn, blockLabel, event, switchOnText, replaceYesAndNo } = props;
  const switchClassName = `switch ${switchOn && "switchOn"}`;

  const setActive = (ev) => {
    ev.target.closest(".switch").classList.toggle("switchOn");
    event();
  };

  return (
    <div className="switchBlock">
      <label className={`blockLabel ${switchOn ? "labelOn" : ""}`}>
        {blockLabel}
      </label>

      <div className="switchContainer">
        <div className={switchClassName} onClick={(e) => setActive(e)}>
          <div className="switchKey" />
        </div>
        {!!replaceYesAndNo ? (
          <label className="switchLabel">{switchOnText}</label>
        ) : (
          <label className="switchLabel">
            {switchOn ? (!!switchOnText ? switchOnText : "Sim") : "Não"}
          </label>
        )}
      </div>
    </div>
  );
};

export default Switch;

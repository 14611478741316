import React, { useEffect, useState } from 'react'
import './style/ContentCreatorProductCompanies.scss'
import { getService } from '../../services/script-api'
import ScriptBoxCaller from '../ScriptBoxCaller/ScriptBoxCaller'
import AsyncSelect from 'react-select/async'
import AsyncLoading from '../AsyncLoading/AsyncLoading'
import ErrorIcon from '../VectorIcons/ErrorIcon'
import SimpleScriptBox from '../../views/ProjectSheet/SimpleScriptBox/SimpleScriptBox'
import MultiValueLabel from './MultiValueLabel/MultiValueLabel'
import Option from './Option/Option'
import AddProductionCompanieIcon from '../VectorIcons/ProductionCompanieIcon'
import CreateAndEditProspectsAndCompanies
  from '../../views/ProspectsAndProductions/CreateAndEditProspectsAndCompanies/CreateAndEditProspectsAndCompanies'

const ContentCreatorProductCompanies = (props) => {
  const { getProductionCompanies, label, placeholder, isError, data } = props

  let initialState = { loading: true, data: [], hasOptions: false }

  const [stateProductionCompanies, setProductionCompanies] = useState(initialState)
  const [currentProductionCompanies, setCurrentProductionCompanies] = useState([])
  const [showNewProductionCompanies, setShowNewProductionCompanies] = useState(false)

  const searchOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length > 0) {
      getService('productioncompanies', { term: inputValue }).then(
        (list) => {
          const _list = list.data.map((item) => ({
            value: item.id,
            label: item.name
          }))
          setProductionCompanies({
            ...stateProductionCompanies,
            data: _list,
          })
          callback(_list)
        }
      )
    }
  }

  const handleChange = (value) => {
    setCurrentProductionCompanies(!!value ? value : [])
    getProductionCompanies(!!value ? value : [])
  }

  const _getProductionCompanies = (value) => {
    setCurrentProductionCompanies([...currentProductionCompanies, value])
    getProductionCompanies([...currentProductionCompanies, value])
  }

  useEffect( () => {
    setCurrentProductionCompanies(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div
      className={`formFieldColumn ${isError ? 'isError' : ''}`}
      id="ContentCreatorProductCompanies"
    >
      <label className="label">{label}</label>
      <ScriptBoxCaller
        callerText="+ Nova Produtora"
        event={() => setShowNewProductionCompanies(true)}
      />

      <AsyncSelect
        isMulti
        className="reactSelect"
        classNamePrefix="reactSelect"
        loadOptions={searchOptions}
        options={stateProductionCompanies.data}
        onChange={handleChange}
        components={{
          LoadingIndicator: () => <AsyncLoading/>,
          MultiValueLabel,
          Option,
        }}
        noOptionsMessage={() => 'Nenhum item encontrado'}
        loadingMessage={() => 'Carregando...'}
        placeholder={placeholder ? placeholder : 'Selecione'}
        value={currentProductionCompanies}
        openMenuOnClick={false}
      />

      {isError && (
        <>
          <div className="errorIcon">
            <ErrorIcon/>
          </div>

          <span className="errorMessage">Este campo é obrigatório</span>
        </>
      )}

      {showNewProductionCompanies && (
        <SimpleScriptBox
          open={showNewProductionCompanies}
          title="Nova Produtora"
          icon={<AddProductionCompanieIcon color="#FFF"/>}
          removePadding={true}
          toggleScriptBox={() => setShowNewProductionCompanies(false)}
        >

          <CreateAndEditProspectsAndCompanies
            closed={() => setShowNewProductionCompanies(false)}
            getProspectAndProductionCompanies={() => {}}
            path="productioncompanies"
            name="Produtora"
            getNewItem={_getProductionCompanies}
          />

        </SimpleScriptBox>
      )}
    </div>
  )
}

export default ContentCreatorProductCompanies
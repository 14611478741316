import React, { useEffect, useState } from 'react'
import ClientDetails from '../../../../components/ClientDetails/ClientDetails'
import { getService } from '../../../../services/script-api'

const ContentClientDetails = (props) => {

  const { getClients, clientDetails } = props

  const [state, setState] = useState({ loading: true, list: [] })

  const getThesaurusChannels = () => {
    getService("dramaturgies/thesaurus?origins=28").then((res) => {
      res.data.forEach((e) => {
        clientDetails.forEach((a) => {
          if (a.content.id === e.id) {
            e.isActive = true;
            e.description = a.descriptions[0];
          }
        });
      });
      setState(res.data);
    });
  };

  useEffect(() => {
    getThesaurusChannels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="formField">
      <div className="channelsCheckBoxContainer">
        <span className="label">Canais/Produto</span>

        <ClientDetails details={state} getClients={getClients}/>
      </div>
    </div>
  )
}

export default ContentClientDetails
import React from "react";
import "./styles/EvaluationTitle.scss";
import TextareaAutosize from "react-textarea-autosize";

const EvaluationTitle = (props) => {
  const { title, placeholder, onChange } = props;

  return (
    <div className="evaluationTitle">
      <TextareaAutosize
        onChange={(ev) => onChange(ev.target.value)}
        value={title}
        placeholder={placeholder}
      />
    </div>
  );
};

export default EvaluationTitle;

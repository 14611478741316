import React, { useState, useLayoutEffect } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import InlineEditor from "custom-ckeditor5-build-inline-without-image";
import "./style/CkEditor.scss";

const CkEditor = (props) => {
  const {
    onChange,
    onFocus,
    onBlur,
    data,
    placeholder = "Escreva aqui o texto...",
  } = props;
  const [stateEditor, setStateEditor] = useState("");

  const handleChange = (ev, editor) => {
    onChange(ev, editor.getData());
    setStateEditor(editor.getData());
  };

  useLayoutEffect(() => {
    setStateEditor(data.text);
  }, [data]);

  return (
    <div className="containerCkEditor">
      <CKEditor
        {...props}
        config={{ placeholder }}
        editor={InlineEditor}
        data={!!stateEditor ? stateEditor : data.text}
        onChange={(event, editor) => handleChange(event, editor)}
        onBlur={(event, editor) => onBlur()}
        onFocus={(event, editor) => onFocus()}
      />
    </div>
  );
};

export default CkEditor;

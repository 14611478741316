import React, { useEffect, useState } from "react";
import ReorderButton from "../../../../components/ReorderButton/ReorderButton";
import "./style/HeaderTable.scss";

const HeaderTable = (props) => {
  const { users, reorderList, order, direction } = props;
  const [stateAnalysts, setStateAnalysts] = useState({
    loading: true,
    list: [],
  });

  useEffect(() => {
    setStateAnalysts({
      ...stateAnalysts,
      loading: false,
      list: users,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return (
    <div id="HeaderTable">
      <li
        className="blueArrow title"
        onClick={() => reorderList("ContentTitle", direction === 0 ? 1 : 0)}
      >
        <ReorderButton
          text="Título"
          color="#3898CD"
          direction={
            isNaN(direction) ? "" : order === "ContentTitle" ? direction : ""
          }
        />
      </li>
      <li className="attachmentType">TIPO / ANEXOS</li>
      <li
        className="blueArrow analyst analystHeader"
        onClick={() => reorderList("UserName", direction === 0 ? 1 : 0)}
      >
        <ReorderButton
          text="Analista"
          color="#3898CD"
          direction={
            isNaN(direction) ? "" : order === "UserName" ? direction : ""
          }
        />
      </li>
      <li
        className="blueArrow canceledOrDone"
        onClick={() => reorderList("StatusDate", direction === 0 ? 1 : 0)}
      >
        <ReorderButton
          text="Concluído em"
          color="#3898CD"
          direction={
            isNaN(direction) ? "" : order === "StatusDate" ? direction : ""
          }
        />
      </li>
      <li className="info" />
    </div>
  );
};

export default HeaderTable;

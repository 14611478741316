import React from "react";

const Masks = (props) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2054 3.67279C12.3999 3.82186 10.4676 4.00764 8.07951 3.33171C5.69079 2.65612 4.10597 1.47464 3.478 0.919291C2.58786 0.13197 2.1963 -0.41519 1.81096 0.412431C1.47029 1.14242 1.03468 2.33044 0.613032 3.99355C-0.300692 7.59498 -0.983582 15.1949 4.66475 16.7942C10.3118 18.3921 13.3183 11.4493 14.232 7.84759C14.6537 6.18481 14.8386 4.92569 14.8895 4.11379C14.9475 3.1941 14.3493 3.46113 13.2054 3.67279ZM1.85688 6.65235C1.85688 6.65235 2.74702 5.19042 4.25676 5.64355C5.76743 6.09667 5.8844 7.86037 5.8844 7.86037L1.85688 6.65235ZM5.54218 13.2127C2.88664 12.3913 2.47709 10.1552 2.47709 10.1552L9.61065 12.2612C9.61065 12.2609 8.17072 14.0239 5.54218 13.2127ZM8.0643 8.61722C8.0643 8.61722 8.95321 7.15627 10.4626 7.6107C11.9721 8.06449 12.0909 9.82818 12.0909 9.82818L8.0643 8.61722Z"
        transform="translate(9.10635 3)"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.35845 9.53003C8.5263 10.4491 8.80438 11.3311 9.22894 12.1167C8.84002 12.3835 8.38955 12.5977 7.86917 12.7457C3.50906 13.9858 1.20193 8.64984 0.503841 5.88062C0.181178 4.60184 0.040309 3.63367 0.00277237 3.00919C-0.0344286 2.39828 0.303645 2.46667 0.963283 2.60004C1.06817 2.62125 1.18121 2.6441 1.30215 2.66614C1.92391 2.77985 3.41412 2.9191 5.25738 2.3942C7.10064 1.86966 8.32556 0.957489 8.81021 0.529938C8.90432 0.446808 8.99124 0.367157 9.07193 0.293243C9.19644 0.179169 9.30609 0.0786743 9.40444 0C9.19564 0.605133 8.97586 1.33279 8.75973 2.1853C8.3804 3.68048 8.04083 5.86484 8.17758 8.02289C7.84307 8.01294 7.46902 8.05597 7.05566 8.17969C4.95114 8.81006 4.65298 10.6396 4.65298 10.6396L8.35845 9.53003ZM5.27539 5.05661L2.17678 5.91864C2.17678 5.91864 2.86154 6.96185 4.02346 6.63879C5.18508 6.3154 5.27539 5.05661 5.27539 5.05661Z"
        transform="translate(-0.000335693 4.80823)"
        fill={props.color}
      />
    </svg>
  );
};

Masks.defaultProps = {
  color: "#fff",
  size: 24,
};

export default Masks;

import React from "react";
import RoleIcon from "../RoleIcon/RoleIcon";
import ChairIcon from "../VectorIcons/ChairIcon";

const StarRoleIcon = () => {
  return (
    <RoleIcon backgroundColor="#FEEEC9">
      <ChairIcon />
    </RoleIcon>
  );
};

export default StarRoleIcon;

import React, { useContext } from "react";
import "./styles/CreatorAndResearcher.scss";
import CreatorName from "../CreatorName/CreatorName";
import ResearcherName from "../ResearcherName/ResearcherName";
import Highlighter from "react-highlight-words";
import * as latinize from "latinize";
import AppContext from "../../../../context/appContext";

const CreatorAndResearcher = (props) => {
  const { type, creators, researchers, authors } = props;
  const appContext = useContext(AppContext);

  return (
    <div className="creatorAndResearcher">
      {type === "Pesquisa"
        ? !!researchers.length &&
          researchers.map((researcher, index) => {
            return (
              <ResearcherName
                key={index}
                index={index}
                length={researchers.length}
                researcher={researcher}
              />
            );
          })
        : !!creators &&
          creators.map((creator, index) => {
            return (
              <CreatorName
                key={index}
                index={index}
                length={creators.length}
                creator={creator}
              />
            );
          })}

      {!!authors.length &&
        authors.map((item, index) => {
          return (
            <span className="creatorName" key={index}>
              <Highlighter
                highlightClassName="creatorName markMatch"
                searchWords={appContext.checkPronouns(appContext.searchTerm)}
                autoEscape={true}
                textToHighlight={item}
                sanitize={latinize}
              />
              {authors.length > index + 1 && (
                <span className="separator">|</span>
              )}
            </span>
          );
        })}
    </div>
  );
};

export default CreatorAndResearcher;

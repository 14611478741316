/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { getService } from "../../services/script-api";
import AsyncLoading from "../AsyncLoading/AsyncLoading";
import Option from "../AssociateFieldset/Option/Option";

const SelectOriginalProduct = (props) => {
  const { handleChange, placeholder = "Selecione", endpoint } = props;

  const searchChipsAdapterProducts = (inputValue, callback, pgNumber) => {
    if (inputValue && inputValue.length > 0) {
      getService(endpoint, {
        term: inputValue,
        limit: 25,
        page: pgNumber,
      }).then((list) => {
        const _list = list.data.map((item) => {
          return {
            value: item.productId,
            label: item.title,
            seasonNumbers: item.seasonNumbers,
            formatId: item.formatId,
            format: item.format,
            creators: item.creators,
            type: item.type,
            soapOperaSchedule: item.soapOperaSchedule,
            receivedAt: item.receivedAt,
          };
        });
        setStateProducts({
          ...stateProducts,
          load: _list,
        });
        callback(_list);
        setPageInfo(list?.pagingInfo);
      });
    }
  };

  const initialStateProducts = { create: [], load: [] };

  const [stateProducts, setStateProducts] = useState(initialStateProducts);
  const [pageInfo, setPageInfo] = useState(null);
  const [inputV, setInputV] = useState("");

  useEffect(() => {
    const ReactSelect = document.querySelector(".reactSelect__menu-list");
    ReactSelect &&
      ReactSelect.addEventListener("scroll", () => {
        const mathScroll = ReactSelect.scrollHeight - ReactSelect.scrollTop;
        if (mathScroll < 500 && pageInfo?.totalPages > pageInfo?.currentPage) {
          searchChipsAdapterProducts(
            inputV?.i,
            inputV?.c,
            ++pageInfo.currentPage
          );
        }
      });
  }, [stateProducts]);

  useEffect(() => {
    const getData = setTimeout(() => {
      searchChipsAdapterProducts(inputV?.i, inputV?.c, 1);
    }, 1000);

    return () => clearTimeout(getData);
  }, [inputV]);

  const handleChangeSetOriginalProducts = (item) => {
    handleChange(item);
    setStateProducts({ ...stateProducts, create: item || [] });
  };

  return (
    <div className="SelectOriginalProduct">
      <AsyncSelect
        value={stateProducts.create}
        loadOptions={(i, c) => setInputV({ i: i, c: c })}
        onChange={handleChangeSetOriginalProducts}
        className="reactSelect"
        classNamePrefix="reactSelect"
        components={{
          LoadingIndicator: () => <AsyncLoading />,
          Option,
        }}
        noOptionsMessage={() => "Nenhum item encontrado"}
        loadingMessage={() => "Carregando..."}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SelectOriginalProduct;

import React from "react";

const ReleaseTextIconMarked = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="#1E9F69"
                  fillRule="nonzero"
                  d="M11.413 0L14.38 2.87 18.466 2.292 19.18 6.357 22.826 8.292 21.013 12 22.826 15.708 19.18 17.643 18.466 21.708 14.38 21.13 11.413 24 8.446 21.13 4.36 21.708 3.646 17.643 0 15.708 1.813 12 0 8.292 3.646 6.357 4.36 2.292 8.446 2.87z"
                  transform="translate(-1042.000000, -478.000000) translate(159.476000, 179.000000) translate(0.000000, 281.000000) translate(883.524000, 18.000000)"
                />
                <path
                  stroke="#FFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15.913 9L9.725 15 6.913 12.273"
                  transform="translate(-1042.000000, -478.000000) translate(159.476000, 179.000000) translate(0.000000, 281.000000) translate(883.524000, 18.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ReleaseTextIconMarked;

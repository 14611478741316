import React from "react";
import "./styles/UploadingProgress.scss";
import SimpleButton from "../SimpleButton/SimpleButton";
import CloseIcon from "../VectorIcons/CloseIcon";
import ReturnIcon from "../VectorIcons/ReturnIcon";
import DeleteIcon from "../VectorIcons/DeleteIcon";

const UploadingProgress = (props) => {
  const {
    percentage,
    fileName,
    isUploading,
    handleCancel,
    canceled,
    resend,
    handleRemove,
    file,
  } = props;

  return (
    <div className={`uploadingProgressContainer ${canceled ? "canceled" : ""}`}>
      {isUploading && (
        <div className="progressBar" style={{ width: `${percentage}%` }} />
      )}

      <div className="uploadInfo">
        {isUploading && (
          <span className="progressPercentage">
            {percentage ? percentage : 0}%
          </span>
        )}

        <span className="fileName">{fileName}</span>
      </div>

      <div className="uploadControls">
        {isUploading && (
          <div className="simpleButtonContainer">
            <SimpleButton
              text="Cancelar"
              classButton="buttonRed"
              clickEvent={() => handleCancel(file)}
            >
              <CloseIcon color="#FFF" />
            </SimpleButton>
          </div>
        )}

        {!isUploading && (
          <>
            <div className="simpleButtonContainer resendButton">
              <SimpleButton
                text="Reenviar"
                classButton="buttonGreen"
                clickEvent={() => resend(file)}
              >
                <ReturnIcon />
              </SimpleButton>
            </div>

            <div className="removeButton" onClick={() => handleRemove(file)}>
              <DeleteIcon color="#000" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadingProgress;

import React, { useState, useEffect, useContext } from "react";
import "./styles/ErrorPage.scss";
import doorImage from "./images/door.png";
import securityImage from "./images/security.png";
import AppContext from "../../../context/appContext";
import ScriptIcon from "../../VectorIcons/ScriptIcon";
import SimpleButton from "../../SimpleButton/SimpleButton";
import BackTriangleIcon from "../../VectorIcons/BackTriangleIcon";
import { Redirect } from "react-router-dom";

const ErrorPage = ({ errorCode, errorMessage }) => {
  const appContext = useContext(AppContext);

  const [stateRedirect, setStateRedirect] = useState(false);
  const [stateRedirectTo, setStateRedirectTo] = useState("");

  useEffect(() => {
    appContext.setShowHeader(false);
    // eslint-disable-next-line
  }, []);

  const handleRedirect = (url) => {
    setStateRedirect(true);
    setStateRedirectTo(url);
  };

  const getErrorMessage = (code) => {
    switch (code) {
      case 403:
        return {
          title: "Acesso Negado",
          message: "Entre em contato com o administrador do Script."
        };
      case 500:
        return {
          title: "Erro 500",
          message: "Ocorreu um erro interno no servidor."
        };
      case 404:
        return {
          title: "Página Não Encontrada",
          message: "A página que você está procurando não existe."
        };
      default:
        return {
          title: `Erro ${code}`,
          message: errorMessage || "Ocorreu um erro inesperado."
        };
    }
  };

  const { title, message } = getErrorMessage(errorCode);

  return (
    <div className="errorPage">
      <div className="errorInfo">
        <ScriptIcon width="144" />
        <span className="codeError">Erro {errorCode}</span>
        <span className="errorTitle">{title}</span>
        <span className="contactMessage">{message}</span>

        <SimpleButton
          text="Voltar"
          classButton="buttonBlue extraLarge"
          dataQA=""
          toValidate={false}
          clickEvent={() => handleRedirect("/")}
        >
          <BackTriangleIcon />
        </SimpleButton>
      </div>

      <div className="animationsContainer">
        <div
          className="security"
          style={{ backgroundImage: `url(${securityImage})` }}
        />
        <div
          className="door"
          style={{ backgroundImage: `url(${doorImage})` }}
        />
        <div className="floor" />
      </div>

      {stateRedirect && <Redirect to={stateRedirectTo} />}
    </div>
  );
};

export default ErrorPage;

import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import CheckBox from "../../Conflicts/CheckBox/CheckBox";
import DownloadIcon from "../../../components/VectorIcons/DownloadIcon";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import ListAttachments from "./ListAttachments";
import AppContext from "../../../context/appContext";
import "../ReleasedListAttachments/styles/ReleasedListAttachments.scss";
import { handleDownloadAll } from "../../../shared/utils/downloadAndZipAll";

const unifyProjectAttachments = (array) =>
  array?.map((file) => [file.attachment, ...(file.olderVersions ?? [])]);

const unifyAttachmentsArray = (array) => {
  const unified = [];
  if (!!array.length) {
    array.forEach((item) =>
      item.map((file) => {
        file.isActive = false;
        return unified.push(file);
      })
    );
  }
  return unified;
};

let order = [
  "Bíblia",
  "Bíblia/Projeto",
  "Capítulo/Episódio",
  "Episódio",
  "Escaleta",
  "Ideia/Argumento",
  "Material Complementar",
  "OnePage",
  "Perfil de Personagem",
  "Pré-Sinopse",
  "Projeto",
  "Promo",
  "Roteiro",
  "Roteiro Filme",
  "Sinopse/Arco de Temporada",
  "Roteiro de Episódio",
];

const returnTypeFilesAndOrder = (name) => {
  let enumFiles = {
    "Capítulo/Episódio": "chapterNumber",
    Episódio: "chapterNumber",
    default: "version",
  };
  return enumFiles[name] || enumFiles["default"];
};

const groupBy = (files) =>
  groupAllFiles(
    _.groupBy(
      files,
      (u) =>
        u.attachmentType?.name ?? u.attachmentType?.attachment?.attachmentType?.name ?? ''
    )
  );
const reorderFiles = (files, order) => _.orderBy(files, [order], ["asc"]);
const groupAllFiles = (files) => {
  let f = [];
  order.map((e) => f.push(reorderFiles(files[e], returnTypeFilesAndOrder(e))));
  return f;
};

const ReleasedListAttachmentsController = (props) => {
  const { files, title, type = "textReleased", isChapter } = props;

  const [state, setState] = useState({
    files: [],
    filesGroups: [],
    compiledAttachments: [],
    projectAttachments: [],
  });
  const [stateAttachmentsForZip, setStateAttachmentForZip] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  
  const appContext = useContext(AppContext);

  const validChaptersOrEpisodesDump = state.filesGroups.filter(item => !!item.length)
  const validChaptersOrEpisodes = validChaptersOrEpisodesDump[0]?.filter(item => item.fileId)
  const downloadableAttachments = validChaptersOrEpisodes?.filter(item => item.release)

  const populateListAttachmentForZip = (fileId, name, title, files, isAll) => {
    setStateAttachmentForZip((prevState) => [
      ...prevState,
      {
        ...files,
        name: name,
        fileId: fileId,
      },
    ]);
  };

  const removeAttachmentsForZip = (fileId) => {
    let newState = [...stateAttachmentsForZip];
    setStateAttachmentForZip(newState.filter((e) => e.fileId !== fileId));
  };

  const SingleToggleActive = (index, i, bool) => {
    const newState = { ...state };
    newState.filesGroups[index][i].isActive = !bool
      ? !newState.filesGroups[index][i].isActive
      : bool;
    setState(newState);
  };

  const handleChangeFiles = (fileId, name, title, files, isAll, index, i) => {
    SingleToggleActive(index, i);
    if (!stateAttachmentsForZip.filter((e) => e.fileId === fileId).length) {
      populateListAttachmentForZip(fileId, name, title, files, isAll);
    } else {
      removeAttachmentsForZip(fileId, title);
    }
  };

  const populateAllState = (files) => {
    !checkAll ? setStateAttachmentForZip(files) : setStateAttachmentForZip([]);
    setState({ ...state, ...state.filesGroups });
    setCheckAll(!checkAll);
  };

  const handleCheckAll = () => {
    let a = [];

    if (type === "channels") {
      downloadableAttachments.forEach((file) => {
        file.isActive = !checkAll;
        a.push(file);
      });
    }

    if (type !== "channels") {
      state.filesGroups.forEach((files) => {
        files.forEach((file) => {
          if (file.fileId && file.release) {
            file.isActive = !checkAll;
            a.push(file);
          }
        });
      });
    }

    populateAllState(a);
  };

  const downloadAllCallback = () => {
    setCheckAll(!checkAll);
    setStateAttachmentForZip([]);
  }

  const downloadAll = () =>
    handleDownloadAll({
      attachmentsArray: stateAttachmentsForZip,
      title: title,
      setLoading: appContext.setShowGlassLoading,
      callback: downloadAllCallback
    });

  useEffect(() => {
    setState((prevState) => ({ ...prevState, files: files }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      projectAttachments: unifyProjectAttachments(state.files),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.files]);

  useEffect(() => {
    if (!!state.projectAttachments?.length)
      setState((prevState) => ({
        ...prevState,
        compiledAttachments: unifyAttachmentsArray(state.projectAttachments),
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.projectAttachments]);

  useEffect(() => {
    if (!!state.compiledAttachments.length)
      setState((prevState) => ({
        ...prevState,
        filesGroups: groupBy(state.compiledAttachments),
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.compiledAttachments]);

  return (
    <div className="ReleasedListAttachments">
      <ul
        className={
          type === "channels" ? "releaseAttachmentsHeader" : "attachmentsHeader"
        }
      >
        <li className="type">
          {" "}
          {!!downloadableAttachments?.length && 
            <div onClick={() => handleCheckAll()}>
              <CheckBox isActive={checkAll} />{" "}
            </div>
          }
          {!isChapter && type === "channels" ? "Episódios" : "Capítulo"}
        </li>
        <li className="version">Versão</li>
        <li className="fileComment">Título/Observações</li>
        <li className="receivedAt">Liberado Em</li>
        <li />
      </ul>

      {!!state.filesGroups &&
        state.filesGroups.map((_files, index) => {
          return (
            <div key={index}>
              {_files.map(
                (file, i) =>
                  (type === "textReleased" || !!file.fileId) && (
                    <ListAttachments
                      file={file}
                      index={i}
                      parentIndex={index}
                      title={title}
                      key={i}
                      handleChangeFiles={handleChangeFiles}
                      type={type}
                      isChapter={isChapter}
                    />
                  )
              )}
            </div>
          );
        })}

      {!!downloadableAttachments?.length && 
      <div className="footer">
        <span>Selecione o(s) anexo(s) que deseja baixar </span>

        <SimpleButton
          text="Baixar selecionados"
          classButton="buttonBlue"
          dataQA="button-prospect-edit"
          onBreadcrumb={true}
          isValidForm={stateAttachmentsForZip.length > 1}
          toValidate={true}
          clickEvent={() => downloadAll()}
        >
          <DownloadIcon color="#fff" />
        </SimpleButton>
      </div>}
    </div>
  );
};

export default ReleasedListAttachmentsController;

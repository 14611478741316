import React from "react";
import "./style/Filter.scss";
import CheckBox from "../../../views/Conflicts/CheckBox/CheckBox";
import SimpleButton from "../../SimpleButton/SimpleButton";

const BlockFormats = (props) => {
  const { item, index, stateFilterFormat, getIdFormats } = props;

  return (
    <div className="itemFormats" onClick={() => getIdFormats(item.id)}>
      <CheckBox
        key={index}
        value={item.id}
        label={item.name}
        index={index}
        isActive={stateFilterFormat.includes(item.id)}
      />
    </div>
  );
};

const Filter = (props) => {
  const {
    dramaturgy,
    varieties,
    isDramaturgy,
    isVarieties,
    stateFilterFormat,
    getIdFormats,
    loading,
    sendFilterFormat,
  } = props;

  return (
    <div className="containerFilter">
      <div className="blocksFormats">
        {!!isDramaturgy && (
          <div className="title">
            <span className="label">Dramaturgia</span>
          </div>
        )}
        {!!isDramaturgy &&
          !dramaturgy.loading &&
          dramaturgy.list.map((item, i) => (
            <BlockFormats
              getIdFormats={getIdFormats}
              stateFilterFormat={stateFilterFormat}
              key={i}
              item={item}
              index={i}
              loading={loading}
            />
          ))}
        {!!isVarieties && (
          <div className="title">
            <span className="label">Variedades</span>
          </div>
        )}
        {!!isVarieties &&
          !varieties.loading &&
          varieties.list.map((item, i) => (
            <BlockFormats
              getIdFormats={getIdFormats}
              stateFilterFormat={stateFilterFormat}
              key={i}
              item={item}
              index={i}
              loading={loading}
            />
          ))}
      </div>

      <div className="contentButtonFilter">
        <SimpleButton
          text="FILTRAR"
          classButton="buttonGreen"
          dataQA="button-prospect-edit"
          clickEvent={() => sendFilterFormat()}
        />
      </div>
    </div>
  );
};

export default Filter;

import React, {
  createContext,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import externalProjectsReducer from "./externalProjectsReducer";
import { getService } from "../services/script-api";

const initialContext = {
  currentTerm: "",
  currentTypeSearch: {
    filterType: "",
    filterValue: "",
  },
  completePagingInfo: "",
  currentPagingInfo: "",
  externalProjectsList: {
    loading: true,
    result: [],
  },
};

const ExternalProjects = createContext("");

const ExternalProjectsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(externalProjectsReducer, initialContext);

  const setLoading = (bool) =>
    dispatch({ payload: bool, key: "loading", action: "loading" });

  const setCurrentTerm = (string) =>
    dispatch({ payload: string, action: "currentTerm" });

  const setCurrentTypeSearch = useCallback(({ filterType, filterValue }) => {
    dispatch({
      payload: { filterType, filterValue },
      key: "currentTypeSearch",
      action: "filter",
    });
  }, []);

  const setCurrentTypeSearchType = (type) =>
    dispatch({
      payload: type,
      key: "currentTypeSearch",
      action: "filterType",
    });

  const setCurrentTypeSearchPage = (page) =>
    dispatch({
      payload: page,
      key: "filter",
      action: "pageFilter",
    });

  const setClearCompletePagingInfo = useCallback(
    () =>
      dispatch({
        payload: "",
        action: "clearCompletePagingInfo",
      }),
    []
  );

  const externalProjectsEndpoints = {
    Dramaturgia: "dramaturgies",
    Variedades: "varieties",
    Todos: "contents",
  };

  const currentGetEndpoint =
    externalProjectsEndpoints[state.currentTypeSearch?.filterType];

  const getExternalProjects = useCallback(() => {
    setLoading(true);
    if (state.currentTypeSearch.filterValue?.term) {
      setCurrentTerm(state.currentTypeSearch.filterValue?.term);
    } else {
      setCurrentTerm("");
    }
    getService(currentGetEndpoint, state.currentTypeSearch.filterValue).then(
      (channel) => {
        setLoading(false);
        dispatch({
          payload: channel,
          key: "list",
          action: "list",
          endpoint: currentGetEndpoint,
        });
      }
    );
  }, [currentGetEndpoint, state.currentTypeSearch.filterValue]);

  useEffect(() => {
    if (state.currentTypeSearch.filterType !== "") {
      getExternalProjects();
    }
  }, [state.currentTypeSearch.filterType, getExternalProjects]);

  return (
    <ExternalProjects.Provider
      value={{
        ...state,
        setCurrentTerm,
        setCurrentTypeSearch,
        setCurrentTypeSearchType,
        setCurrentTypeSearchPage,
        setClearCompletePagingInfo,
      }}
    >
      {children}
    </ExternalProjects.Provider>
  );
};

export { ExternalProjects as default, ExternalProjectsProvider };

import React, { useEffect, useContext } from "react";
import { logout } from "../../shared/lib/login-store";
import { requestLogoutUser } from "../../services/auth-api";
import Redirecting from "../Redirecting/Redirecting";
import AppContext from "../../context/appContext";

const Logout = (props) => {
  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext.setShowHeader(false);
    requestLogoutUser();
    logout();
    // eslint-disable-next-line
  }, []);

  return <Redirecting />;
};

export default Logout;

import React from "react";
import "./styles/ImportanceMark.scss";
import ConflictsArrow from "../../../components/VectorIcons/ConflictsArrow";

const ImportanceMark = (props) => {
  const { level, label, isArrow, conflictType } = props;

  const levelColor = (level) => {
    const colors = {
      unclassified: "#9395a3",
      Terciária: "#10af78",
      Secundária: "#df9a37",
      Central: "#ec1045",
    };

    return colors[level];
  };

  return (
    <div className="importanceMark">
      {isArrow ? (
        <ConflictsArrow color={levelColor(level)} direction={conflictType} />
      ) : (
        <div className={`dot ${level}`}>&#x25CF;</div>
      )}

      {!!label && <span className="dotLabel">{label}</span>}
    </div>
  );
};

export default ImportanceMark;

import React from 'react';
import "./styles/TagStatus.scss"

const TagStatus = (props) => {
  const { name, id } = props

  const setColor = (id) => {
    const color = {
      1: "#fad058",
      2: "#3366ff",
      3: "#2e9d68",
      4: "#dc1331"
    }

    return !!color[id] ? color[id] : color[1]
  }

  return (
    <div className={`tagStatus`} style={{background: setColor(id), border: `1px solid ${setColor(id)}`}}>{ name }</div>
  )
}

export default TagStatus;
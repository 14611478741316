import React from "react";
import "./styles/BoxFooter.scss";

const BoxFooter = (props) => {
  const {
    customFooter,
    classBox
  } = props;

  const classType = customFooter ? classBox : "boxFooter";
  
  return <div className={classType}>{props.children}</div>;
};

export default BoxFooter;

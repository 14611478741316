import React from "react";
import "./styles/ResultsHeader.scss";
import ReorderButton from "../../../../components/ReorderButton/ReorderButton";

const ResultsHeader = (props) => {
  const { reorder, handleReorderFilter } = props;

  return (
    <ul className="resultsHeader">
      <li className="headerItem contentTitle">
        <ReorderButton
          text="Título"
          color="#0098CD"
          event={() => handleReorderFilter("Title.Exact")}
          direction={
            reorder["OrderBy.Column"] === "Title.Exact"
              ? reorder["OrderBy.Direction"]
              : ""
          }
        />
      </li>

      <li className="headerItem contentDate">
        <ReorderButton
          color="#0098CD"
          text="Data"
          event={() => handleReorderFilter("ReceivedAt")}
          direction={
            reorder["OrderBy.Column"] === "ReceivedAt"
              ? reorder["OrderBy.Direction"]
              : ""
          }
        />
      </li>
    </ul>
  );
};

export default ResultsHeader;

import React from "react";
import "./styles/DateTooltip.scss";
import EditIcon from "../../../../../components/VectorIcons/EditIcon";

const DateTooltip = () => {
  return (
    <div className="dateTooltip">
      <p>
        A data é atualizada automaticamente ao apertar o botão de{" "}
        <strong>Salvar</strong> no topo da página.
      </p>
      <p>
        Para fixar uma data manual, basta apertar o ícone de edição{" "}
        <EditIcon size={12} />, porém a data deixará de ser atualizada
        automaticamente.
      </p>
    </div>
  );
};

export default DateTooltip;

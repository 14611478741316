import React, { useEffect, useState, useContext } from "react";
import "./styles/ProjectReleasedText.scss";
import { getService } from "../../../services/script-api";
import TableLoading from "../../../components/TableLoading/TableLoading";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import HeaderWithIconType from "../../../components/HeaderWithIconType/HeaderWithIconType";
import { Link } from "react-router-dom";
import ReleasedListAttachmentsController from "../ReleasedListAttachments/ReleasedListAttachmentsController";
import ChaptersAndEpisodes from "../../ChaptersAndEpisodes/ChaptersAndEpisodes";
import AppContext from "../../../context/appContext";
import { handleDownloadAll } from "../../../shared/utils/downloadAndZipAll";
import { extractAttachmentsFromResponse } from "../../../shared/utils/compileAttachments";
import ProjectReleasedTextTabs from "../ProjectReleasedTextTabs/ProjectReleasedTextTabs";
import DownloadAllSection from "../DownloadAllSection/DownloadAllSection";
import { handleChapterOrEpisode } from "../../../shared/utils/handleChapterOrEpisode";
import { handleDramaturgyTypeByFormat } from "../../../shared/utils/handleDramaturgyTypeByFormat";
import NoCommomAttachments from "../NoCommomAttachments/NoCommomAttachments";
import ViewFile from "../../../components/ViewFile/ViewFile";

const ProjectReleasedText = (props) => {
  const [state, setState] = useState({ loading: true, list: [] });
  const [currentTab, setCurrentTab] = useState("");
  const [planningData, setPlanningData] = useState(null);

  const appContext = useContext(AppContext);

  const getProjectReleasedText = () => {
    let filter = {
      origins: [1, 2],
      limit: 1000,
    };

    getService("textrelease", { ...filter, id: props.match.params.id }).then(
      (res) => {
        setCurrentTab(
          res.data[0]?.contentType === "Dramaturgia"
            ? "Capítulos ou Episódios"
            : "Outros"
        );
        setState({
          ...state,
          loading: false,
          list: res.data,
        });
      }
    );
  };

  const chapterOrEpisode = handleChapterOrEpisode(state.list[0]?.groupName);


  const filterChaptersFromAttachments = (attachments) => {
    if (!!attachments?.length) {
      return attachments.filter(
        (item) => item.attachment?.attachmentType?.name !== "Capítulo/Episódio"
      );
    }
  };

  const commomAttachments = filterChaptersFromAttachments(
    state.list[0]?.attachments
  );

  const handleReleasedTabData = {
    "Capítulos ou Episódios": {
      message:
        "Faça download de todos os arquivos de capítulos do projeto aqui.",
      data: planningData?.blocks ?? planningData?.episodes,
      attachments: extractAttachmentsFromResponse({
        attachmentsArray: planningData?.blocks ?? planningData?.episodes,
        onlyValid: true,
        onlyReleased: true,
      }),
    },
    Outros: {
      message:
        "Faça download de todos os arquivos do projeto aqui (exceto capítulos).",
      data: commomAttachments,
      attachments: extractAttachmentsFromResponse({
        attachmentsArray: commomAttachments,
        onlyValid: true,
        onlyReleased: true,
      }),
    },
  };

  const downloadAll = () =>
    handleDownloadAll({
      attachmentsArray: handleReleasedTabData[currentTab].attachments,
      title: state.list[0].title,
      setLoading: appContext.setShowGlassLoading,
    });

  useEffect(() => {
    getProjectReleasedText();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentTab) setPlanningData(null);
  }, [currentTab]);

  if (state.loading) {
    return <TableLoading />;
  }

  return (
    <div className="ProjectReleasedText">
      <div className="headerProjectReleasedText">
        <Breadcrumb>
          <div className="breadcrumbContent">
            <div className="breadcrumbHeader">
              <HeaderWithIconType
                type={state.list[0]?.contentType}
                title={state.list[0]?.title}
              />

              <Link to="/textos-liberados" className="goBack">
                <span>{`« Voltar`}</span>
                <span className="goBackResponsiveText">{` para a lista de textos liberados`}</span>
              </Link>
            </div>

            <ProjectReleasedTextTabs
              contentType={state.list[0]?.contentType}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              chapterOrEpisode={chapterOrEpisode}
            />
          </div>
        </Breadcrumb>
      </div>

      <div className="container">
        {!!handleReleasedTabData[currentTab]?.attachments?.length && (
          <DownloadAllSection
            message={handleReleasedTabData[currentTab].message}
            action={downloadAll}
          />
        )}

        {currentTab === "Capítulos ou Episódios" && (
          <ChaptersAndEpisodes
            isReleased={true}
            contentId={state.list[0]?.id}
            chapterOrEpisode={handleChapterOrEpisode(
              handleDramaturgyTypeByFormat(state.list[0]?.format)
            )}
            type={"channels"}
            getCallback={setPlanningData}
          />
        )}

        {!!commomAttachments.length && currentTab === "Outros" && (
          <ReleasedListAttachmentsController
            files={commomAttachments}
            title={state.list[0]?.title}
          />
        )}

        {!commomAttachments.length && currentTab === "Outros" && (
          <NoCommomAttachments />
        )}
      </div>
      {appContext.current.step === "ViewerFileBox" && (
        <ViewFile url={appContext.file?.url} title={appContext.file?.title} />
      )}
    </div>
  );
};

export default ProjectReleasedText;

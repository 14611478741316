import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import "./style/ListStatusActivities.scss";
import { getService } from "../../services/script-api";
import FilterListStatusActivities from "./FilterListStatusActivities/FilterListStatusActivities";
import TableListActivity from "./TableListActivity/TableListActivity";
import TotalCount from "./TableListActivity/TotalCount/TotalCount";
import TableLoading from "../../components/TableLoading/TableLoading";
import Pagination from "react-js-pagination";
import ActivitiesContext from "../../context/activitiesContext";
import UsersContext from "../../context/userContext";
import AppContext from "../../context/appContext";
import NoContents from "./NoContents/NoContents";
import { Link } from "react-router-dom";
import EditFeedback from "../Activities/EditFeedback/EditFeedback";
import EditActivity from "../Activities/EditActivity/EditActivity";

const ListStatusActivities = (props) => {
  const activityContext = useContext(ActivitiesContext);
  const userContext = useContext(UsersContext);
  const appContext = useContext(AppContext);

  let groups = [
    { id: 1, value: "Desenv. Dram. Curta", name: "Desenv. Dram. Curta" },
    { id: 2, value: "Desenv. Dram. Longa" },
    { id: 3, value: "Desenv. Variedades" },
    { id: 4, value: "Governança" },
    { id: 5, value: "Acomp. Dram." },
    { id: 6, value: "Pesquisas" },
    { id: 7, value: "Acomp. Variedades" },
  ];

  const statusActivity = {
    canceladas: { name: "Canceladas", status: 4, hasFeedback: null },
    concluidas: { name: "Concluídas", status: 6, hasFeedback: null },
    "feedback-pendentes": {
      name: "com feedback Pendentes",
      status: 6,
      hasFeedback: false,
    },
  };

  const isFeedbackScreen = props.match.params.status === "feedback-pendentes";

  const initialFilters = {
    groups: groups.filter(
      (e) =>
        e.value ===
        activityContext.remakeSlugType(props.match.params.type).label
    )[0].id,
    status: statusActivity[props.match.params.status].status,
    limit: 10,
    PageNumber: 1,
    hasFeedback: statusActivity[props.match.params.status].hasFeedback,
    usersId: [],
  };

  const [state, setState] = useState({ loading: true, list: [] });
  const [stateFilter, setStateFilter] = useState(initialFilters);

  const [stateUsers, setStateUsers] = useState({ loading: true, users: [] });
  const [stateGroups, setStateGroups] = useState({ loading: true, groups: [] });
  const [stateOrder, setStateOrder] = useState({});
  const [stateCurrentActivity, setStateCurrentActivity] = useState([]);

  const addNameGroups = (groups) => groups.filter((e) => (e["name"] = e.value));

  const getTypes = () => {
    getService("activities/types").then((res) => {
      setStateGroups({
        ...stateGroups,
        groups: addNameGroups(res.data),
      });
    });
  };

  const handleChangeUser = (ev) => {
    setStateFilter({
      ...stateFilter,
      UserIds: ev.target.value === "placeholder" ? [] : ev.target.value,
      PageNumber: 1,
    });
  };

  const handleChangeTerm = (ev) => {
    setStateFilter({
      ...stateFilter,
      ContentTitle: ev.target.value,
      PageNumber: 1,
    });
  };

  const handleChangeType = (ev) => {
    setStateFilter({
      ...stateFilter,
      Types: ev.target.value === "placeholder" ? [] : ev.target.value,
    });
  };

  const getAnalysts = () =>
    getService("users/analysts", {
      ActivityGroups: userContext.me.user.activityGroups,
    }).then((users) => monthUsers(users.data));

  const monthUsers = (data) => {
    setStateUsers({
      ...stateUsers,
      users: data,
    });
  };

  const handleActivitiesList = (list) => {
    if (isFeedbackScreen)
      return list.filter(
        (activity) => activity.contentType !== "Obra Literária"
      );
    return list;
  };

  const getActivities = (params) => {
    setLoading(true);
    getService("activities/filters", { ...stateFilter, ...params }).then(
      (res) => {
        setState({
          list: handleActivitiesList(res.data),
          pagingInfo: res.pagingInfo,
          loading: false,
        });
      }
    );
  };

  const setLoading = (bool) => {
    setState({
      ...state,
      loading: bool,
    });
  };

  const handlePageChange = (pageNumber) => {
    setLoading(true);
    setStateFilter({
      ...stateFilter,
      UserIds: stateFilter.UserIds,
      PageNumber: pageNumber,
      initial: true,
    });
  };

  const filterEmailAnalyst = (data) => {
    let b = [];
    data.forEach((e) => {
      if (!!e.isActive) {
        b.push(e.id);
      }
    });
    return b;
  };

  const filterAnalysts = (data) => {
    setStateFilter({
      ...stateFilter,
      UserIds: !!filterEmailAnalyst(data).length
        ? filterEmailAnalyst(data)
        : null,
    });
  };

  const getDate = (date, key) => {
    setStateFilter({
      ...stateFilter,
      [key]: !!date ? date : null,
    });
  };

  const reorderList = (name, direction) => {
    setLoading(true);
    setStateOrder({
      ...stateOrder,
      [`OrderBy.Column`]: name,
      [`OrderBy.Direction`]: direction,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onSubmitFilter = () => {
    setLoading(true);
    getActivities();
  };

  useEffect(() => {
    if (stateFilter.PageNumber > 1) getActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFilter.PageNumber]);

  useEffect(() => {
    getActivities(stateOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateOrder]);

  useEffect(() => {
    // getGroups()
    getTypes();
    if (!!userContext.me.user?.activityGroups?.length) getAnalysts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.me.user]);

  return (
    <div id="ListStatusActivities">
      <Breadcrumb>
        <div className="activityHeader">
          <h2>Atividades {statusActivity[props.match.params.status].name}</h2>
          <span>
            {activityContext.remakeSlugType(props.match.params.type).label}
          </span>
        </div>

        <Link to="/atividades" className="goBack">
          <span>{`« Voltar`}</span>
          <span>{` para Atividades Recentes`}</span>
        </Link>
      </Breadcrumb>

      <div className="containerFilterList">
        <div className="container">
          <h2>
            Filtrar atividades{" "}
            {statusActivity[props.match.params.status].name.toLowerCase()}
          </h2>

          <FilterListStatusActivities
            startDate={stateFilter.StartDate}
            endDate={stateFilter.EndDate}
            getDate={getDate}
            users={stateUsers.users}
            handleChangeUser={handleChangeUser}
            handleChangeTerm={handleChangeTerm}
            handleChangeType={handleChangeType}
            groups={stateGroups.groups}
            onSubmitFilter={onSubmitFilter}
            selectedUser={stateFilter.UserIds}
            selectedTypes={stateFilter.Types}
          />
        </div>
      </div>

      {!state.loading ? (
        <>
          <TotalCount
            count={state.pagingInfo.totalItems}
            type={statusActivity[props.match.params.status].name}
          />

          {!state.list.length && <NoContents />}

          {state.list.length && (
            <TableListActivity
              order={stateOrder["OrderBy.Column"]}
              direction={stateOrder["OrderBy.Direction"]}
              reorderList={reorderList}
              activities={state.list}
              filterAnalysts={filterAnalysts}
              setStateCurrentActivity={setStateCurrentActivity}
              currentType={props.match.params.type}
              getActivities={getActivities}
            />
          )}
        </>
      ) : (
        <TableLoading />
      )}

      {!!state.pagingInfo && state.pagingInfo.totalPages > 1 && (
        <div id="PaginationContent" className="container">
          <div
            className={`prevPagination ${
              state.pagingInfo.prevPage && "hasPage"
            }`}
            onClick={
              state.pagingInfo.prevPage
                ? () => handlePageChange(state.pagingInfo.prevPage)
                : null
            }
          >
            <span className="prevArrow" />
            <span className="prevArrow" />
          </div>

          <Pagination
            hideNavigation
            hideFirstLastPages
            activePage={stateFilter.PageNumber}
            itemsCountPerPage={10}
            totalItemsCount={state.pagingInfo.totalItems}
            onChange={handlePageChange}
          />

          <div
            className={`nextPagination ${
              state.pagingInfo.nextPage && "hasPage"
            }`}
            onClick={
              state.pagingInfo.nextPage
                ? () => handlePageChange(state.pagingInfo.nextPage)
                : null
            }
          >
            <span className="nextArrow" />
            <span className="nextArrow" />
          </div>
        </div>
      )}

      {appContext.current.step === "editProjectActivityList" && (
        <EditActivity
          currentActivity={stateCurrentActivity}
          type={stateCurrentActivity.contentType}
          _getActvity={getActivities}
          open={appContext.current.step === "editProjectActivityList"}
        />
      )}

      {appContext.current.step === "editProjectFeedbackList" && (
        <EditFeedback
          currentActivity={stateCurrentActivity}
          type={stateCurrentActivity.contentType}
          _getActvity={getActivities}
          open={appContext.current.step === "editProjectFeedbackList"}
        />
      )}
    </div>
  );
};

export default ListStatusActivities;

import React from "react";
import "./styles/ScriptBoxCaller.scss";

const ScriptBoxCaller = (props) => {
  const { callerText, callerIcon, event, _class } = props;

  return (
    <span className={!!_class ? _class : "scriptBoxCaller"} onClick={event}>
      {callerText}
      {callerIcon}
    </span>
  );
};

export default ScriptBoxCaller;

import React, { useEffect, useState } from 'react'
import './styles/ClientDetails.scss'
import CheckBox from '../../views/Conflicts/CheckBox/CheckBox'
import Fieldset from '../Fieldset/Fieldset'
import SmallLoading from '../SmallLoading/SmallLoading'

const ClientDetails = (props) => {
  const { details, getClients } = props
  const [state, setState] = useState({ loading: true, list: [] })

  const filterDetails = (data) => {
    let items = {}
    data.forEach((e) => {
      e.isActive = !!e.isActive ? e.isActive : false
      if (!e.parentId) {
        items[e.name] = {
          id: e.id,
          name: e.name,
          isActive: e.isActive,
          children: data.filter((f) => f.parentId === e.id),
          description: e.description,
        }
      }
    })

    return items
  }

  useEffect(() => {
    if (!!details.length) {
      setState({
        loading: false,
        list: filterDetails(details),
      })
    }
  }, [details])

  const checkChildrenActive = (newState, childrenId, name) => {
    newState.list[name].children.forEach((e) => {
      if (e.id === childrenId) {
        e.isActive = !e.isActive
      }
    })
  }

  const toggleActive = (name, parentId, childrenId) => {
    let newState = { ...state }
    if (!!childrenId) {
      checkChildrenActive(newState, childrenId, name)
    } else {
      newState.list[name].isActive = !newState.list[name].isActive
      if (!newState.list[name].isActive) {
        clearIsActive(newState.list[name])
      }
    }
    setState(newState)
  }

  const clearIsActive = (client) =>
    client.children.forEach((e) => (e.isActive = false))

  const handleChangeChildrenDescription = (ev, name, parentId, id) => {
    let newState = { ...state }
    newState.list[name].children.filter((e) => e.id === id)[0].description =
      ev.target.value
    setState(newState)
  }

  const handleChangeDescription = (ev, name, id) => {
    let newState = { ...state }
    newState.list[name].description = ev.target.value
    setState(newState)
  }

  const compileClients = () => {
    let channels = []

    Object.entries(state.list).forEach((e) => {
      if (!!e[1].isActive) {
        populateCliente(e[1], channels)
        populateClienteChildrens(e[1].children, channels)
      }
    })

    return channels
  }

  const populateCliente = (client, channels) => {
    channels.push({
      id: client.id,
      descriptions: [!!client.description ? client.description : ''],
    })
  }

  const populateClienteChildrens = (children, channels) => {
    children.forEach((f) => {
      if (!!f.isActive) {
        channels.push({
          id: f.id,
          descriptions: [!!f.description ? f.description : ''],
        })
      }
    })
  }

  useEffect(() => {
    getClients(compileClients())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const order = [
    'TV Globo',
    'Globo Filmes',
    'Canais Globo',
    'Produtos e Serviços Digitais',
    'Outros',
  ]

  return (
    <div className="clientDetailsCheckboxes">
      {!state.loading ? (
        order.map((list, index) => {
          return (
            <div
              className={`parentContainer ${
                state.list[list].isActive ? 'parentIsSelected' : ''
              }`}
              key={index}
            >
              <div
                className="detailItem"
                onClick={() =>
                  toggleActive(state.list[list].name, state.list[list].id)
                }
              >
                <CheckBox
                  value={state.list[list].id}
                  label={state.list[list].name}
                  isActive={state.list[list].isActive}
                />
              </div>

              {!!state.list[list].isActive &&
              state.list[list].name === 'Outros' && (
                <div className="subDetails">
                  <Fieldset
                    label=""
                    type="text"
                    placeholder="Digite o nome do programa"
                    handleChange={(ev) =>
                      handleChangeDescription(
                        ev,
                        state.list[list].name,
                        state.list[list].id
                      )
                    }
                    value={state.list[list].description}
                  />
                </div>
              )}

              <div
                className={`subDetails ${
                  state.list[list].name === 'TV Globo' ? 'tvglobo' : ''
                }`}
              >
                {state.list[list].children.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      {!!state.list[list].isActive && (
                        <div
                          className="detailItem"
                          onClick={() =>
                            toggleActive(
                              state.list[list].name,
                              state.list[list].id,
                              item.id
                            )
                          }
                        >
                          <CheckBox
                            value={item.id}
                            label={item.name}
                            isActive={item.isActive}
                          />
                        </div>
                      )}

                      {((item.name === 'Jornalismo' && !!item.isActive) ||
                        (item.name === 'Esporte' && !!item.isActive)) && (
                        <div className="subDetails">
                          <Fieldset
                            autoComplete="off"
                            label=""
                            value={item.description}
                            type="text"
                            placeholder="Digite o nome do programa"
                            handleChange={(ev) =>
                              handleChangeChildrenDescription(
                                ev,
                                state.list[list].name,
                                state.list[list].id,
                                item.id
                              )
                            }
                          />
                        </div>
                      )}
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          )
        })
      ) : (
        <SmallLoading/>
      )}
    </div>
  )
}

export default ClientDetails

import React, { useEffect, useState } from "react";
// import AssociatedSearch from '../../../components/AssociatedSearch/AssociatedSearch'
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatepicker";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import Fieldset from "../../../components/Fieldset/Fieldset";
import FieldsetNumberMasked from "../../../components/FieldsetNumberMasked/FieldsetNumberMasked";
import FieldsetSelect from "../../../components/FieldsetSelect/FieldsetSelect";
import HelpLabel from "../../../components/HelpLabel/HelpLabel";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import Switch from "../../../components/Switch/Switch";
// import NextArrowIcon from '../../../components/VectorIcons/NextArrowIcon'
import TooltipContent from "../TooltipContent/TooltipContent";
// import AssociatedProject from '../../../components/AssociatedProject/AssociatedProject'
import PublisherCompaniesAndAuthor from "../PublisherCompaniesAndAuthor/PublisherCompaniesAndAuthor";
import ContentCreateProspects from "../../../components/ContentCreateProspects/ContentCreateProspects";
import { getService } from "../../../services/script-api";

const Step1 = (props) => {
  const {
    state,
    stateError,
    sendLiteraryWork,
    setValues,
    handleBlurCreators,
    isValid,
    isValidYear,
    getCurrentFormat,
  } = props;

  const [stateFormat, setStateFormat] = useState({ loading: true, list: [] });

  const getFormats = () => {
    getService(`literaryworks/thesaurus?origins=25`).then((list) => {
      const _list = list.data.map((item) => ({
        value: item.id,
        label: item.name,
        origin: origin,
        ...item,
      }));
      setStateFormat({
        ...stateFormat,
        loading: false,
        list: _list,
      });
    });
  };

  const handleChangeFormat = (value) => {
    const [selected] = stateFormat.list.filter(
      (item) => item.id === parseInt(value)
    );
    getCurrentFormat(selected?.name ? selected.name : "");
    setValues({
      key: "formatId",
      value: value === "placeholder" ? "" : value,
      validate: true,
    });
  };

  const filterCreators = (data) => {
    let a = [];
    if (!!data.length) {
      data.forEach((e) => {
        a.push({
          suggesterId: e.value,
          origin: e.origin,
        });
      });
    }
    return a;
  };

  const getCreators = (data) =>
    setValues({
      key: "suggestedBy",
      value: filterCreators(data),
      validate: true,
    });
  const getPublisherCompaniesAndAuthor = (data, key) =>
    setValues({ key: key, value: data });

  useEffect(() => {
    getFormats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentDate = (value) =>
    new Date(value.getTime() - value.getTimezoneOffset() * 60000).toISOString();

  return (
    <>
      <div className="formField">
        <Switch
          blockLabel="Obra de domínio público?"
          event={() =>
            setValues({ key: "publicDomain", value: !state.publicDomain })
          }
          switchOn={state.publicDomain}
        />

        <div className="dateField">
          <CustomDatePicker
            label="Recebido Em"
            date={!!state.receivedAt ? new Date(state.receivedAt) : ""}
            handleChangeDate={(value) =>
              setValues({
                key: "receivedAt",
                value: !!value ? currentDate(value) : new Date(),
              })
            }
          />
        </div>
      </div>

      <div className="formFieldColumn">
        <Fieldset
          autoComplete="off"
          name="seatonTitle"
          label="Título"
          type="text"
          placeholder="Digite o título da obra literária"
          value={state.title}
          handleChange={(ev) =>
            setValues({ key: "title", value: ev.target.value, validate: true })
          }
          handleBlur={(ev) =>
            setValues({ key: "title", value: ev.target.value, validate: true })
          }
          isError={stateError.title}
          msgError="Este campo é obrigatório"
        />

        <div className="helpContainer">
          <HelpLabel
            label="Utilize letra maiúscula no início das palavras, exceto preposições.&nbsp;"
            tipLabel="Ver exemplos"
          >
            <CustomTooltip
              tipLabel="Ver exemplos"
              dataTip={<TooltipContent />}
            />
          </HelpLabel>
        </div>
      </div>

      <PublisherCompaniesAndAuthor
        path="literaryauthors"
        label="Autor(es)"
        placeholder="Digite o nome do Autor e aperte ENTER"
        getPublisherCompaniesAndAuthor={getPublisherCompaniesAndAuthor}
        keyName="authors"
      />

      <PublisherCompaniesAndAuthor
        path="publishercompanies"
        label="Editora"
        placeholder="Digite o nome da Editora e aperte ENTER"
        optional={true}
        getPublisherCompaniesAndAuthor={getPublisherCompaniesAndAuthor}
        keyName="publishers"
      />

      <div className="formField">
        <div className="formatField">
          <FieldsetSelect
            name="format"
            label="Formato"
            placeholder="Selecione um Formato"
            data={stateFormat.list}
            value={state.formatId}
            selected={state.formatId}
            isError={stateError.formatId}
            msgError="O campo formato é obrigatório"
            handleChange={(ev) => handleChangeFormat(ev.target.value)}
          />
        </div>

        <div className="yearField">
          <FieldsetNumberMasked
            type="text"
            name="year"
            mask="9999"
            label="Ano de publicação"
            placeholder="Digite o ano com até 4 dígitos"
            autoComplete="off"
            value={state.publicationYear}
            handleBlur={isValidYear}
            isError={stateError.publicationYear}
            msgError="Ano deve ser maior que 0 e menor que o ano atual"
            handleChange={(ev) =>
              setValues({
                key: "publicationYear",
                value: parseInt(ev.target.value),
              })
            }
          />
        </div>
      </div>

      <div className="formField">
        <ContentCreateProspects
          label="Solicitado por:"
          createFieldLabel="Novo Solicitante"
          placeholder="Digite o nome de quem sugeriu a obra"
          isError={false}
          getCreators={getCreators}
          handleBlur={handleBlurCreators}
        />
      </div>

      {/*<div className="formFieldColumn">
          <Switch
              blockLabel="Associar a outro projeto?"
              event={() => setAssociationTypes("project")}
              switchOn={stateAssociationTypes.project}
          />

          {stateAssociationTypes.project &&
          <div className="formFieldColumn associationField">
            <AssociatedProject
                handleChange={(value) => setValues({key: "associations", subKey: "projects", value: value})}/>
          </div>
          }
        </div>*/}

      {/*<div className="formFieldColumn">
          <Switch
              blockLabel="Associar a pesquisa?"
              event={() => setAssociationTypes("research")}
              switchOn={stateAssociationTypes.research}
          />

          {stateAssociationTypes.research &&
          <div className="formFieldColumn associationField">
            <AssociatedSearch
                handleChange={(item) => setValues({key: "associations", subKey: 'researchesIds', value: item})}
            />
          </div>
          }
        </div>*/}

      {/*<div className="formFieldColumn">
          <Switch
              blockLabel="Associar a obra literária?"
              event={() => setAssociationTypes("literaryWork")}
              switchOn={stateAssociationTypes.literaryWork}
          />

          {stateAssociationTypes.literaryWork &&
          <>Associar a obra literária</>
          }
        </div>*/}

      <div className="formField buttonField">
        <SimpleButton
          text="Cadastrar"
          classButton="buttonGreen extraLarge"
          iconSide="right"
          isValidForm={isValid()}
          toValidate={true}
          clickEvent={() => sendLiteraryWork()}
        />
      </div>
    </>
  );
};

export default Step1;

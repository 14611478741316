import React from "react";
import ScriptBoxDescription from "../../../../components/ScriptBoxDescription/ScriptBoxDescription";

const DetailsModal = (props) => {
  const { details, close } = props;

  return (
    <ScriptBoxDescription
      open={details.open}
      clear={close}
      title={details.currentItem.title}
      subTitle={details.currentItem.creators}
    >
      <p className="boxParagraph">{details.currentItem.storyline}</p>
    </ScriptBoxDescription>
  );
};

export default DetailsModal;

import React from "react";
import "./styles/ProjectSheet.scss";
import EditIcon from "../../../../../components/VectorIcons/EditIcon";

const InfoPaper = (props) => {
  const { children, hasIco, handleClick = () => {} } = props;

  return (
    <div className="InfoPaper">
      {!!hasIco && (
        <div className="ico" onClick={() => handleClick()}>
          <EditIcon />
        </div>
      )}
      {children}
    </div>
  );
};

export default InfoPaper;

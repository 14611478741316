import React from "react";
import "./style/FilterListStatusActivities.scss";
import FieldsFilter from "../../../components/NoActivityAttachments/FieldsFilter/FieldsFilter";
import FieldsetSelect from "../../../components/FieldsetSelect/FieldsetSelect";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatepicker";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";

const FilterListStatusActivities = (props) => {
  const {
    getDate,
    users,
    handleChangeUser,
    handleChangeType,
    onSubmitFilter,
    groups,
    selectedUser,
    selectedTypes,
    handleChangeTerm,
  } = props;

  const handleChangeDateFilter = (date, key) => {
    if (!!date) {
      let d = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toISOString();
      let finalDate = d.split("T");
      getDate(finalDate[0], key);
    } else {
      getDate(null, key);
    }
  };

  return (
    <div id="FilterListStatusActivities">
      <div className="formItem large">
        <FieldsFilter
          handleChangeFilter={handleChangeTerm}
          label="TÍTULO DO PROJETO"
          name="ContentTitle"
          placeHolder="Digite um título"
        />
      </div>

      <div className="formItem medium">
        <div className="formField">
          <FieldsetSelect
            name="Types"
            label="Tipo"
            placeholder="Selecione um tipo"
            data={groups}
            // value={value}
            handleChange={handleChangeType}
            // isError={stateError.format}
            msgError="Este campo é obrigatório"
            endpoint="dramaturgies"
            selected={selectedTypes}
          />
        </div>
      </div>

      <div className="formItem medium">
        <div className="formField">
          <FieldsetSelect
            name="usersId"
            label="Analista"
            placeholder="Selecione um Analista"
            data={users}
            // value={value}
            handleChange={handleChangeUser}
            // isError={stateError.format}
            msgError="Este campo é obrigatório"
            endpoint="dramaturgies"
            selected={selectedUser}
          />
        </div>
      </div>

      <div className="formItem small">
        <CustomDatePicker
          label="Período"
          name="intervalStart"
          renderSide="left"
          handleChangeDate={(e) => handleChangeDateFilter(e, "StartDate")}
          placeholderText="Data ínicio"
        />
      </div>

      <div className="formItem small">
        <CustomDatePicker
          label="&nbsp;"
          name="intervalEnd"
          renderSide="right"
          handleChangeDate={(e) => handleChangeDateFilter(e, "EndDate")}
          placeholderText="Data fim"
        />
      </div>

      <div className="formItem smaller">
        <SimpleButton
          text="Filtrar"
          classButton="buttonBlue small"
          dataQA=""
          clickEvent={() => onSubmitFilter()}
        />
      </div>
    </div>
  );
};

export default FilterListStatusActivities;

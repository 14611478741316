import React, { useContext, useState } from "react";
import "./styles/RegisterThesaurus.scss";
import ScriptBox from "../../../components/ScriptBox/ScriptBox";
import AppContext from "../../../context/appContext";
import Fieldset from "../../../components/Fieldset/Fieldset";
import Textarea from "../../../components/Textarea/Textarea";
import CreatableFieldset from "../../../components/CreatableFieldset/CreatableFieldset";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import Button from "../../../components/Button/Button";
import AsyncSelect from "react-select/async";
import AsyncLoading from "../../../components/AsyncLoading/AsyncLoading";
import { getService, postService } from "../../../services/script-api";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import AddThesaurusIcon from "../../../components/VectorIcons/AddThesaurusIcon";
import ThesaurusAlert from "../ThesaurusAlert/ThesaurusAlert";
import { sendEvent } from "../../../services/GoogleAnalytics/methods";

const RegisterThesaurus = (props) => {
  const {
    currentType,
    currentOrigin,
    contentType,
    allowedToHaveParentTerm,
    reloadThesaurusList,
  } = props;

  const appContext = useContext(AppContext);

  let initialState = {
    origin: currentOrigin.originId,
    name: "",
    description: "",
    parentThesaurusId: null,
    remissives: [],
    forceSave: false,
    contentType: contentType,
    parentInfo: "",
  };

  const [currentThesaurus, setCurrentThesaurus] = useState(initialState);
  const [stateRegisterError, setStateRegisterError] = useState("");

  const handleChangeTextThesaurus = (ev) => {
    setCurrentThesaurus({
      ...currentThesaurus,
      description: ev.target.value,
    });
  };

  const handleChangeNameThesaurus = (ev) => {
    setCurrentThesaurus({
      ...currentThesaurus,
      [ev.target.name]: ev.target.value.trim(),
    });
  };

  const filterRemissives = (remissives) => {
    let a = [];
    if (!!remissives && !!remissives.length) {
      remissives.forEach((e) => a.push(e.label));
    }
    return a;
  };

  const loadOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length > 0) {
      let url = `${currentType}/thesaurus?Term=${inputValue}&origins=${currentOrigin.originId}`;
      let list = [];
      getService(url, { term: inputValue }).then((e) => {
        e.data.map((item) =>
          list.push({
            value: item.id,
            label: item.name,
            name: item.name,
            id: item.id,
            originId: item.origin,
          })
        );
        callback(list);
      });
    }
  };

  const handleChangeAssociated = (item) => {
    if (!!item) {
      getService(`thesaurus/${item.id}`).then((res) => {
        setCurrentThesaurus({
          ...currentThesaurus,
          parentInfo: res.data,
          parentThesaurusId: !!item ? item.id : initialState.parentThesaurusId,
        });
      });
    } else {
      setCurrentThesaurus({
        ...currentThesaurus,
        parentInfo: "",
      });
    }
  };

  const handleChangeRemissives = (newValue) => {
    setCurrentThesaurus({
      ...currentThesaurus,
      remissives: filterRemissives(newValue),
    });
  };

  const compileErrorValues = (errors) => {
    const errorFields = {
      termFieldValues: [],
      remissivesFieldValues: [],
    };

    const remissives = currentThesaurus.remissives.map((item) =>
      item.toLowerCase()
    );

    errors.forEach((error) => {
      if (error.Name.toLowerCase() === currentThesaurus.name.toLowerCase()) {
        errorFields.termFieldValues.push(error);
      }
      if (remissives.includes(error.Name.toLowerCase())) {
        errorFields.remissivesFieldValues.push(error);
      }
    });

    setStateRegisterError(errorFields);
    setCurrentThesaurus({ ...currentThesaurus, forceSave: true });
  };

  const registerThesaurus = (e) => {
    e.preventDefault();

    const {
      origin,
      contentType,
      name,
      description,
      parentThesaurusId,
      remissives,
      forceSave,
    } = currentThesaurus;
    const postObj = {
      origin,
      contentType,
      name,
      description,
      parentThesaurusId,
      remissives,
      forceSave,
    };

    if (!!name) {
      appContext.setShowGlassLoading(true);
      postService("thesaurus", postObj)
        .then((e) => {
          appContext.setShowGlassLoading(false);
          appContext.setCurrentStepBox("closed");
          sendEvent("Cadastro", "Criação: Thesaurus Admin");
          appContext.sendMessage("Thesaurus cadastrado com sucesso!");
          reloadThesaurusList(currentOrigin.originId, currentOrigin.title);
        })
        .catch((e) => {
          appContext.setShowGlassLoading(false);
          console.log(e.response.data.Validations);
          compileErrorValues(e.response.data.Validations[0].Data);
          console.log("error: ", e.response.data.Validations[0].Data);
        });
    }
  };

  return (
    <div id="RegisterThesaurus">
      {appContext.current.step === "registerThesaurus" && (
        <ScriptBox
          open={appContext.current.step === "registerThesaurus"}
          title={`Criar ${currentOrigin.title}`}
          icon={<AddThesaurusIcon color="#FFF" />}
          marginBottom={true}
        >
          <form onSubmit={(e) => registerThesaurus(e)}>
            <Fieldset
              autoComplete="off"
              name="name"
              label={currentOrigin.title}
              type="text"
              placeholder="Digite o termo"
              handleChange={handleChangeNameThesaurus}
              isAlert={!!stateRegisterError?.termFieldValues?.length}
            />

            <div className="thesaurusAlertsContainer">
              {!!stateRegisterError?.termFieldValues?.length &&
                stateRegisterError.termFieldValues.map((item, index) => {
                  return (
                    <ThesaurusAlert
                      key={index}
                      termName={item.Name}
                      thesaurusName={currentOrigin.title}
                      isRemissive={item.IsRemissive}
                      tree={item.Parent}
                    />
                  );
                })}
            </div>

            <div className="formItem">
              <Textarea
                maxRows={16}
                label="Nota de escopo"
                placeholder="Digite a definição do termo"
                handleChange={handleChangeTextThesaurus}
              />
            </div>

            <CreatableFieldset
              label="Remissiva(s)"
              createMessage="Adicionar a opção:"
              placeholder="Digite um termo e aperte ENTER"
              handleChange={handleChangeRemissives}
              renderCreatedAt={true}
              classNames={`${
                !!stateRegisterError?.remissivesFieldValues?.length
                  ? "isAlert"
                  : ""
              }`}
            />

            <div className="thesaurusAlertsContainer">
              {!!stateRegisterError?.remissivesFieldValues?.length &&
                stateRegisterError.remissivesFieldValues.map((item, index) => {
                  return (
                    <ThesaurusAlert
                      key={index}
                      termName={item.Name}
                      thesaurusName={currentOrigin.title}
                      isRemissive={item.IsRemissive}
                      tree={item.Parent}
                    />
                  );
                })}
            </div>

            {!!allowedToHaveParentTerm.includes(currentOrigin.title) && (
              <div className="formItem">
                <label>Termo pai</label>

                <AsyncSelect
                  isClearable
                  className="reactSelect"
                  classNamePrefix="reactSelect"
                  loadOptions={loadOptions}
                  onChange={handleChangeAssociated}
                  components={{
                    LoadingIndicator: () => <AsyncLoading />,
                  }}
                  noOptionsMessage={() => "Nenhum item encontrado"}
                  loadingMessage={() => "Carregando..."}
                  placeholder="Selecione"
                  openMenuOnClick={false}
                />
              </div>
            )}

            <BoxFooter>
              <SimpleButton
                text="Cancelar"
                classButton="buttonCancel large"
                dataQA=""
                clickEvent={() => appContext.setCurrentStepBox("closed")}
              />
                <Button
                  text={`Criar ${currentOrigin.title}`}
                  toValidate={true}
                  isValidForm={!!currentThesaurus.name}
                  classButton={!stateRegisterError ? "buttonGreen large" : "buttonRed large"}
                  typeButton="submit"
                  dataQA=""
                />
            </BoxFooter>
          </form>
        </ScriptBox>
      )}
    </div>
  );
};

export default RegisterThesaurus;

import React, { createContext, useContext, useReducer } from "react";

import { getService, patchService } from "../services/script-api";
import sheetReduce from "./sheetAndRegisterReduce";
import AppContext from "../context/appContext";

const initialContext = {
  filter: {},
  list: {
    loading: true,
    result: [],
  },
  listVarieties: {
    loading: true,
    result: [],
  },
  listResearch: {
    loading: true,
    result: [],
  },
  listLiteraryWorks: {
    loading: true,
    result: [],
  },
  relevances: {
    loading: true,
    result: [],
  },
  characters: {
    loading: true,
    result: [],
    error: false,
  },
  seasons: {
    loading: true,
    result: [],
  },
};

const ProjectSheet = createContext();

const ProjectShettProvider = ({ children }) => {
  const appContext = useContext(AppContext);

  const [state, dispatch] = useReducer(sheetReduce, initialContext);

  const compileDataId = (data) => {
    let i = [];
    if (!!data) {
      data.forEach((el) => {
        i.push(el.value);
      });
    }

    return i;
  };

  const compileProduct = (data) => {
    let _arrayF = [];
    data.forEach((el) => {
      _arrayF.push(el.id);
    });

    return _arrayF;
  };

  const compileCreators = (creators) => {
    let filter = [];
    creators.forEach((el) => {
      if(!!el.label || !!el.name) {
        filter.push({
          creatorId: el.id || el.value,
          origin: el.origin ? el.origin : "Prospect",
        });
      }
    });
    return !!filter.length ? filter : creators;
  };

  const toggleCharacter = (id, bool, dramaturgiaId) => {
    patchService(`dramaturgies/characters/${id}/active/${bool}`)
      .then(() => {
        appContext.sendMessage(`Personagem ${bool ? "ativado" : "inativado"} com sucesso`);
        _getProjectSheet(dramaturgiaId, "dramaturgies");
      })
      .catch(() => {
        appContext.sendMessage("Algo deu errado", "error");
      });
  };

  const _getProjectSheet = (id, type) =>  get(`${type}/${id}`, "list");

  const _getProjectSheetVarieties = (id, type) =>
    get(`${type}/${id}`, "listVarieties");

  const _getProjectSheetResearch = (id, type) =>
    get(`${type}/${id}`, "listResearch");

  const _getProjectSheetLiteraryWorks = (id, type) =>
     get(`${type}/${id}`, "listLiteraryWorks");

  const getRelevances = () =>
     get(`dramaturgies/characters/relevances`, "relevances");

  const getCharacters = async (id) =>
    await get(`dramaturgies/${id}/characters`, "characters")
      .catch((e) => {
        dispatch({ key: "characters", action: "list", payload: { error: true } })
        loading("characters", false);
      });

  const getSeasons = (id) => get(`products/${id}/seasons`, "seasons");

  const loading = (subject, active) => {
    dispatch({ action: "loading", payload: { subject, active } });
  };

  const clearSheet = () => dispatch({ payload: [], key: "list", action: "list" });

  const get = (path, key) => {
    loading(key, true);
    return getService(path)
      .then((result) => {
        dispatch({ payload: result, key: key, action: "list" });
        loading(key, false);
      })
      .catch((e) => {
        if (e.response.status === 504) throw new Error("504");
        dispatch({ key: key, action: "list", payload: { result: "" } });
        loading(key, false);
      });
  };

  const phasesFromTo = (name) => {
    const names = {
      "PÓS PRODUÇÃO": "PÓS-PRODUÇÃO",
      "PRÉ PRODUÇÃO": "PRÉ-PRODUÇÃO",
      "MIGRACAO AGRA": "MIGRAÇÃO AGRA",
    };
    return names[name] || name;
  };

  return (
    <ProjectSheet.Provider
      value={{
        ...state,
        _getProjectSheet,
        _getProjectSheetVarieties,
        _getProjectSheetResearch,
        _getProjectSheetLiteraryWorks,
        compileDataId,
        compileCreators,
        compileProduct,
        getRelevances,
        getCharacters,
        toggleCharacter,
        getSeasons,
        loading,
        phasesFromTo,
        clearSheet
      }}
    >
      {children}
    </ProjectSheet.Provider>
  );
};

export { ProjectSheet as default, ProjectShettProvider };

import React, { useCallback, useContext, useEffect, useState } from "react";
import "./styles/VersionsList.scss";
import DownloadIcon from "../../../../components/VectorIcons/DownloadIcon";
import EditIcon from "../../../../components/VectorIcons/EditIcon";
import ReleaseTextIcon from "../../../../components/VectorIcons/ReleaseTextIcon";
import ReleaseTextIconMarked from "../../../../components/VectorIcons/ReleaseTextIconMarked";
import RegisterContext from "../../../../context/registerContext";
import ConfirmationBox from "../../../../components/ConfirmationBox/ConfirmationBox";
import { deleteService } from "../../../../services/script-api";
import AppContext from "../../../../context/appContext";
import DeleteIcons from "../../../ProjectSheet/ListFiles/BlockList/DeleteIcons/DeleteIcons";

const VersionsList = (props) => {
  const {
    attachments,
    handleDate,
    handleEditFile,
    handleOpenReleaseTexts,
    getPlanning,
    isLastTableItem,
    handleFileDetails
  } = props;

  
  
  const dateFields = [
    "receivedAt",
    "attachedContentSight",
    "attachedProdutionSight",
    "attachedArtisticApproval",
    "releaseDate",
    "attachedChannelFeedback",
  ];

  const registerContext = useContext(RegisterContext);
  const appContext = useContext(AppContext);

  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const [isFile, setFile] = useState();

  const handleConfirmationBox = (file) => {
    setFile(file);
    setOpenConfirmationBox(true);
  };

  const closedBox = () => {
    setOpenConfirmationBox(false);
  };

  const handleDeleteFile = (file) => {
    appContext.setShowGlassLoading(true);

    deleteService("contents/attachments", file.id)
      .then(() => {
        appContext.sendMessage(`Arquivo ${file.fileName} deletado com sucesso`);
        setOpenConfirmationBox(false);
        getPlanning();
        appContext.setShowGlassLoading(false);
      })
      .catch((error) => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(`Erro ao deletar arquivo`, "error");
        console.log("error: ", error);
      });
  };

  const returnColor = (count) => (!!count ? "#7f808e" : "#ec1045");

  const handleResize = useCallback(() => {
    if (window.innerWidth > 1024 && appContext.current.step === "fileDetailsChaptersOrEpisodes") {
      appContext.setCurrentStepBox("");
    }
  }, [appContext]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <div className="versionsContainer">
      <div className="versionsHeader">
        <span className="version">Ver.</span>
        <span className="versionMobile">Versões</span>
        <span className="file">Arquivo</span>
        {dateFields.map((field, index) => (
          <div key={index}>
            <div className="delivery" style={{ fontWeight: "lighter" }}>Entregue</div>
          </div>
        ))}
      </div>

      

      <ul className="versionsList">
        {attachments.map((item, index) => {
          return (
            <li className="versionItem" key={index}>
              <div className="versionInfo">
                <span className="version">{`${item.version}ª`}</span>
                <div className="fileNameBlock">
                  <span className="file">{item.fileName}</span>
                  {item.reason ?
                    <span className="reason">
                      <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M8 3H6C4.89543 3 4 3.89543 4 5V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V5C20 3.89543 19.1046 3 18 3H14M8 3V12L11 9L14 12V3M8 3H14" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path></g></svg>
                      <div style={{ marginTop: "4px", marginLeft: "4px" }}>{item.reason}</div>
                    </span>
                    :
                    null
                  }
                </div>
                {dateFields.map((field, index) => (
                  <div key={index}>
                    <div className="delivery" style={{ fontWeight: "lighter" }}>
                      {handleDate(item[field] || (field === "releaseDate" && item.firstReleaseDate))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="actions">
                <div className="details" onClick={() => handleFileDetails(item)}>
                  <svg width="24px" height="24px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="48" height="48" fill="white" fillOpacity="0.01"></rect> <path d="M40 27V6C40 4.89543 39.1046 4 38 4H10C8.89543 4 8 4.89543 8 6V42C8 43.1046 8.89543 44 10 44H21" stroke="#0098cd" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M17 12L31 12" stroke="#0098cd" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M17 20L31 20" stroke="#0098cd" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M17 28H23" stroke="#0098cd" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M37 37C37 38.3807 36.4404 39.6307 35.5355 40.5355C34.6307 41.4404 33.3807 42 32 42C29.2386 42 27 39.7614 27 37C27 34.2386 29.2386 32 32 32C34.7614 32 37 34.2386 37 37Z" fill="#e2e4ff"></path> <path d="M39 44L35.5355 40.5355M35.5355 40.5355C36.4404 39.6307 37 38.3807 37 37C37 34.2386 34.7614 32 32 32C29.2386 32 27 34.2386 27 37C27 39.7614 29.2386 42 32 42C33.3807 42 34.6307 41.4404 35.5355 40.5355Z" stroke="#0098cd" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                </div>
                <div onClick={() => 
                  handleEditFile(item)
                  }>
                  <EditIcon />
                </div>
                <span
                  onClick={() =>
                    registerContext.getUrlS3(item.fileId, item.fileName)
                  }
                >
                  <DownloadIcon />
                </span>
                <div
                  onClick={() =>
                    handleOpenReleaseTexts(
                      item,
                      attachments.filter(
                        (attachment) => attachment.fileId !== item.filedId
                      )
                    )
                  }
                >
                  {item.release ? (
                    <ReleaseTextIconMarked />
                  ) : (
                    <ReleaseTextIcon />
                  )}
                </div>
                {(attachments.length > 1 || isLastTableItem) && (
                  <span
                    onClick={() =>
                      !item.release &&
                      !item.activityCount &&
                      handleConfirmationBox(item)
                    }
                  >
                    <DeleteIcons
                      release={item.release}
                      activityCount={item.activityCount}
                      returnColor={returnColor}
                    />
                  </span>
                )}
              </div>
            </li>
          );
        })}
      </ul>

      {openConfirmationBox && (
        <ConfirmationBox
          open={openConfirmationBox}
          textButton="Sim, excluir"
          closedBox={closedBox}
          handleEvent={() => handleDeleteFile(isFile)}
          title="Tem certeza?"
          text="Este anexo será excluído permanentemente do sistema e essa ação não poderá ser desfeita"
        />
      )}

    </div>
  );
};

export default VersionsList;

import React, { useState, useEffect } from "react";
import "./styles/ThesaurusAlert.scss";
import InfoIcon from "../../../components/VectorIcons/InfoIcon";

const ThesaurusAlert = (props) => {
  const { termName, thesaurusName, isRemissive, tree } = props;

  const [stateTree, setStateTree] = useState([]);

  useEffect(() => {
    setStateTree(returnParentTermValues(tree));
    // eslint-disable-next-line
  }, [tree]);

  const handleMessage = (isRemissive) =>
    isRemissive
      ? "é uma remissiva já cadastrada em:"
      : "é um termo já cadastrado em:";

  const returnParentTermValues = (term) => {
    const values = [];

    if (term) {
      if (term.Parent?.Parent) {
        values.push({
          id: term.Parent.Parent.Id,
          name: term.Parent.Parent.Value,
          parent: term.Parent.Parent.Parent,
        });
      }
      if (term.Parent) {
        values.push({
          id: term.Parent.Id,
          name: term.Parent.Value,
          parent: term.Parent.Parent,
        });
      }
      values.push({
        id: term.Id,
        name: term.Value,
        parent: term.Parent,
      });
    }

    return values;
  };

  return (
    <div className="thesaurusAlert">
      <div className="alertIconContainer">
        <InfoIcon color="#ffa810" size={24} />
      </div>

      <div className="alertMessageContainer">
        <span className="alertTerm">{termName}</span>

        <span className="alertMessage">{handleMessage(isRemissive)}</span>

        <span className="alertTermTree">
          <div className="breadcrumbItem">
            {!!stateTree.length ? (
              stateTree.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <span>{item.name}</span>
                    {stateTree.length > index + 1 && (
                      <span className="breadcrumbSeparator">›</span>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <span>{thesaurusName}</span>
            )}

            <span className="breadcrumbSeparator">›</span>
            <span>{termName}</span>
          </div>
        </span>
      </div>
    </div>
  );
};

export default ThesaurusAlert;

const loadingReduce = (action, state) => {
  const newState = { ...state };
  newState["externalProjectsList"]["loading"] = action.payload;
  return newState;
};

const setList = (action, state) => {
  const newState = { ...state };
  newState["externalProjectsList"]["result"] = action.payload.data;
  newState["externalProjectsList"]["pagingInfo"] = action.payload.pagingInfoByType;

  const pagingInfo = action.payload.pagingInfoByType.find(
    (item) => item.type === state.currentTypeSearch.filterType
  );

  newState.currentPagingInfo = pagingInfo;
  if (action.endpoint === "contents") {
    newState.completePagingInfo = action.payload.pagingInfoByType;
  }
  return newState;
};

const setTypeSearch = (action, state) => ({
  ...state,
  [action.key]: action.payload,
});

const setTypeSearchType = (action, state) => ({
  ...state,
  currentTypeSearch: {
    ...state.currentTypeSearch,
    filterType: action.payload,
    filterValue: {
      ...state.currentTypeSearch.filterValue,
      pageNumber: 1,
    },
  },
});

const setTypeSearchPage = (action, state) => ({
  ...state,
  currentTypeSearch: {
    ...state.currentTypeSearch,
    filterValue: {
      ...state.currentTypeSearch.filterValue,
      pageNumber: action.payload,
    },
  },
});

const setClearPagingInfo = (action, state) => ({
  ...state,
  completePagingInfo: action.payload,
});

const setCurrentTerm = (action, state) => ({
  ...state,
  currentTerm: action.payload,
});

export default (state, action) => {
  let _state = {
    loading: () => loadingReduce(action, state),
    currentTerm: () => setCurrentTerm(action, state),
    list: () => setList(action, state),
    filter: () => setTypeSearch(action, state),
    filterType: () => setTypeSearchType(action, state),
    pageFilter: () => setTypeSearchPage(action, state),
    clearCompletePagingInfo: () => setClearPagingInfo(action, state),
  };
  return _state[action.action]();
};

import React from 'react'
import './style/Tag.scss'

const Tags = (props) => {

  const { text, index, handleClick } = props

  return (
    <div id="Tags">
      <div className="parameterTag" key={index}>
        <div className="parameterTerm">{text}</div>
          <div
            className="removeTag"
            onClick={() => handleClick()}
          >
            x
          </div>
      </div>
    </div>
  )
}

export default Tags
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./styles/Conflicts.scss";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ConflictsFilter from "./ConflictsFilter/ConflictsFilter";
import ListCount from "../../components/ListCount/ListCount";
import ImportanceMark from "./ImportanceMark/ImportanceMark";
import ConflictsTable from "./ConflictsTable/ConflictsTable";
import SimpleButton from "../../components/SimpleButton/SimpleButton";
import MapConflictContext from "../../context/mapConflictContext";
import ConflictsNotFoundIcon from "../../components/VectorIcons/ConflictsNotFoundIcon";
import PrintIcon from "../../components/VectorIcons/PrintIcon";
import ContentPagination from "../../components/ContentPagination/ContentPagination";
import { sendEvent } from "../../services/GoogleAnalytics/methods";
import LoadingConflicts from "./LoadingConflicts/LoadingConflicts";

const Conflicts = (props) => {
  const mapContext = useContext(MapConflictContext);

  const [currentTypeConflictSelected, setCurrentTypeConflictSelected] =
    useState("");

  useEffect(() => {
    if (props.pathname !== "/mapa-conflitos") {
      mapContext.clearConflicts();
    }
    // eslint-disable-next-line
  }, [props.pathname]);

  const handlePrint = () => {
    sendEvent("Mapa de Conflitos", "Impressão do mapa");
    window.print();
  };

  const handlePageChange = (filter) => {
    let newFilter = { ...mapContext.filter };
    newFilter.pageNumber = filter.pageNumber;
    newFilter.newMap = false;
    mapContext.setFilter(newFilter);
  };

  const getTypeConflictSelected = (conflictType) =>
    setCurrentTypeConflictSelected(conflictType);

  return (
    <div id="Conflicts">
      <Helmet>
        <title>Script - Mapa de Conflitos</title>
      </Helmet>
      <Breadcrumb>
        <h2>Mapa de Conflitos</h2>
      </Breadcrumb>

      <ConflictsFilter getTypeConflictSelected={getTypeConflictSelected} />

      {!mapContext.list.loading && !mapContext.listAll.loading ? (
        <div className="tableSection">
          {mapContext.list.result?.data?.length &&
          mapContext.listAll.result?.data?.length ? (
            <div className="tableContainer">
              <>
                <div className="listInfo">
                  <ListCount
                    total={
                      !!mapContext.listAll.result.pagingInfo &&
                      mapContext.listAll.result.pagingInfo.totalItems
                    }
                    text="Conflitos encontrados"
                  />
                  <SimpleButton
                    text="Imprimir"
                    dataQA="button-prospect-edit"
                    clickEvent={() => handlePrint()}
                  >
                    <PrintIcon color="#0098CD" />
                  </SimpleButton>

                  <div className="listLegend">
                    <div className="levelContainer">
                      <ImportanceMark label="Central" level="Central" />
                      <ImportanceMark label="Secundária" level="Secundária" />
                      <ImportanceMark label="Terciária" level="Terciária" />
                      <ImportanceMark
                        label="Sem classificação"
                        level="unclassified"
                      />
                    </div>

                    <div className="typeContainer">
                      <ImportanceMark
                        label="Horizontal Passado"
                        isArrow={true}
                        conflictType="Passado"
                      />
                      <ImportanceMark
                        label="Horizontal Futuro"
                        isArrow={true}
                        conflictType="Futuro"
                      />
                      <ImportanceMark
                        label="Vertical"
                        isArrow={true}
                        conflictType="Vertical"
                      />
                    </div>
                  </div>
                </div>

                <div className="renderList">
                  <ConflictsTable
                    list={mapContext.list.result.data}
                    currentTypeConflictSelected={currentTypeConflictSelected}
                  />
                </div>

                <div className="printList">
                  <ConflictsTable list={mapContext.listAll.result.data} />
                </div>

                {!!mapContext.list.result.pagingInfo &&
                  mapContext.list.result.pagingInfo.totalPages > 1 && (
                    <ContentPagination
                      totalItems={mapContext.list.result.pagingInfo.totalItems}
                      itemsPerPage={
                        mapContext.list.result.pagingInfo.itemsPerPage
                      }
                      currentPage={
                        mapContext.list.result.pagingInfo.currentPage
                      }
                      prevPage={mapContext.list.result.pagingInfo.previousPage}
                      nextPage={mapContext.list.result.pagingInfo.nextPage}
                      totalPages={mapContext.list.result.pagingInfo.totalPages}
                      handleChange={handlePageChange}
                    />
                  )}
              </>
            </div>
          ) : (
            !!mapContext.list.result?.data &&
            !!mapContext.listAll.result?.data && (
              <div className="notFoundConflicts">
                <ConflictsNotFoundIcon />
                <span className="notFoundText">Nenhum conflito encontrado</span>
              </div>
            )
          )}
        </div>
      ) : (
        <LoadingConflicts />
      )}
    </div>
  );
};

export default Conflicts;

import React from 'react';
import "./styles/ContentInfo.scss"

const ContentInfo = (props) => {

  const { label, value, children } = props

  return (
    <div className="contentInfo">
      <div className="left-Info">
        <p>{label}</p>
      </div>

      <div className="right-info">
        {!!children ? (
          children
        ) : (
          <p>{value}</p>
        )}
      </div>
    </div>
  );
};

export default ContentInfo;
import React, { useContext } from "react";
import "./styles/ListAttachments.scss";
import AppContext from "../../context/appContext";
import CheckBox from "../../views/Conflicts/CheckBox/CheckBox";
import ActivitiesIcon from "../VectorIcons/ActivitiesIcon";

const ListAttachments = (props) => {
  const { files, toggleActiveCheckBox, toggleActiveVersionCheckBox } = props;

  const appContext = useContext(AppContext);

  const toggleOpenVersionList = (ev) => {
    let targetActive = ev.target.closest(".mainVersionItem");
    let targetParent = targetActive.closest(".attachmentItem");

    if (targetParent) {
      targetParent.classList.toggle("opened");
    }
  };

  const hasValidVersions = (versions) => versions.some((item) => !!item.fileId);
  const returnValidVersions = (versions) =>
    versions.filter((item) => !!item.fileId);

  const isChapterOrEpisode = (type) =>
    ["Capítulo/Episódio", "Episódio"].includes(type);

  return (
    <div className="listAttachments">
      <ul className="attachmentsHeader">
        <li className="type">Tipo</li>
        <li className="version">Versão</li>
        <li className="file">Arquivo</li>
        <li className="receivedAt">Recebido Em</li>
        <li />
      </ul>

      {!!files &&
        files.map((file, index) => {
          return (
            file?.parent?.fileId &&
            file?.parent?.fileName && (
              <div className="attachmentItem" key={index}>
                <ul className="mainVersionItem">
                  <li
                    className="type"
                    onClick={() => toggleActiveCheckBox(file, index)}
                  >
                    <CheckBox isActive={file.parent.isActive} />

                    <span className="typeName">
                      {file.parent.attachmentType?.name}

                      {` ${
                        isChapterOrEpisode(file.parent.attachmentType?.name)
                          ? file.parent.chapterNumber
                          : ""
                      }`}
                    </span>

                    {isChapterOrEpisode(file.parent.attachmentType?.name) && (
                      <span className="blockNumber">
                        Bloco{" "}
                        {file.parent.blockNumber > 0 && file.parent.blockNumber}
                      </span>
                    )}
                  </li>

                  <li
                    className={`version ${
                      hasValidVersions ? "hasVersions" : ""
                    }`}
                    onClick={(ev) => toggleOpenVersionList(ev)}
                  >
                    <span className="versionNumber">
                      {!!file.version && file.parent.version}ª
                    </span>
                    <span className="versionText">versão</span>
                    {hasValidVersions(file.version) && (
                      <div className="itemArrow" />
                    )}
                  </li>

                  <li className="file">{file.parent.fileName}</li>

                  <li className="receivedAt">
                    {appContext.formatDate(
                      appContext.convertDatePtBr(file.parent.receivedAt)
                    )}
                  </li>

                  <li className="activitiesNumber">
                    <span>{file.parent.activityCount ?? 0}</span>
                    <ActivitiesIcon color="#7070B3" />
                  </li>
                </ul>

                <ul className="versionsList">
                  {returnValidVersions(file.version).map((item, i) => {
                    return (
                      <div className="versionItem" key={i}>
                        <li
                          className="type"
                          onClick={() =>
                            toggleActiveVersionCheckBox(item, index, i)
                          }
                        >
                          <CheckBox isActive={item.isActive} />

                          <span className="typeName">
                            {!!item.attachmentType && item.attachmentType?.name}

                            {` ${
                              item.attachmentType?.name ===
                                "Capítulo/Episódio" ||
                              item.attachmentType?.name === "Episódio"
                                ? item.chapterNumber
                                : ""
                            }`}
                          </span>

                          {(item.attachmentType?.name === "Capítulo/Episódio" ||
                            item.attachmentType?.name === "Episódio") && (
                            <span className="blockNumber">
                              Bloco {item.blockNumber}
                            </span>
                          )}
                        </li>

                        <li
                          className="version"
                          onClick={(ev) => toggleOpenVersionList(ev)}
                        >
                          <span className="versionNumber">{item.version}ª</span>
                          <span className="versionText">versão</span>
                        </li>
                        <li className="file">{item.fileName}</li>

                        <li className="receivedAt">
                          {appContext.formatDate(
                            appContext.convertDatePtBr(item.receivedAt)
                          )}
                        </li>

                        <li className="activitiesNumber">
                          <span>{item.activityCount ?? 0}</span>
                          <ActivitiesIcon color="#7070B3" />
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </div>
            )
          );
        })}
    </div>
  );
};

export default ListAttachments;

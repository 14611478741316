import React, { useContext } from 'react'
import CreatorIcon from '../VectorIcons/CreatorIcon'
import TypeIconReturner from '../TypeIconReturner/TypeIconReturner'
import { Link } from 'react-router-dom'
import SheetIcon from '../VectorIcons/SheetIcon'
import ListAttachments from '../ListAttachments/ListAttachments'
import SimpleButton from '../SimpleButton/SimpleButton'
import NewActivityIcon from '../VectorIcons/NewActivityIcon'
import NoResultsFiles from '../NoResultsFiles/NoResultsFiles'
import AppContext from "../../context/appContext"

const ContentListAttachments = (props) => {

  const { data, files, toggleActiveCheckBox, toggleActiveVersionCheckBox, createIsValid, toggleOpenList } = props

  const { type, title, creators,
    researchers, format, soapOperaSchedule, researchCategory,
    researchSubCategory, lastAttachmentReceivedDate, id, origins,
  } = data
  const appContext = useContext(AppContext)

  const setRedirect = () => appContext.redirectToSheet(type, id)

  return (
    <div className="contentItem">
      <div className="header">
        <li className="title">Título</li>

        <li className="creator">
          <CreatorIcon color="#575863"/>
        </li>

        <li className="details">Detalhes</li>
        <li className="origin">Procedência</li>
        <li className="lastAttachment">Último anexo</li>
      </div>

      <div className="listItem opened">
        <div className="projectDetails" onClick={(ev) => toggleOpenList(ev)}>
          <li className="title">
            <div className="itemArrow"/>

            <TypeIconReturner
              type={type}
              responsiveIcon={true}
            />

            <span className="projectTitle">{title}</span>
          </li>

          <li className="creator">
            {type !== 'Pesquisa'
              ? creators.map((item, index) => {
                return (
                  <div key={index}>
                    <span>{item.name}</span>
                    {index + 1 < creators.length && (
                      <span className="creatorSeparator">|</span>
                    )}
                  </div>
                )
              })
              : researchers.map((item, index) => {
                return (
                  <div key={index}>
                    <span>{item}</span>
                    {index + 1 < researchers.length && (
                      <span className="creatorSeparator">|</span>
                    )}
                  </div>
                )
              })}
          </li>

          <li className="details">
            <span>
              {type !== 'Pesquisa'
                && `${format} 
                 ${format === 'Novela' ? '•' : ''}
                 ${format === 'Novela' ? soapOperaSchedule : ''}`}
            </span>
            <span>
              {type === "Pesquisa" && `${researchCategory} • ${researchSubCategory}`}
            </span>
          </li>

          <li className="origin">
            {!!origins &&
            origins.map((origin, index) => {
              return (
                <div key={index}>
                  <span>{origin}</span>
                  {index + 1 < origins.length && (
                    <span className="creatorSeparator">|</span>
                  )}
                </div>
              )
            })}
          </li>

          <li className="lastAttachment">
            <span>
              {!!lastAttachmentReceivedDate
                ? appContext.formatDate(
                  appContext.convertDatePtBr(
                    lastAttachmentReceivedDate
                  )
                )
                : '-'}
            </span>
          </li>

          <Link to={setRedirect()}>
            <SheetIcon/>
          </Link>
        </div>

        <div className="projectAttachments">
          {files.length > 0 ? (
            <>
              <ListAttachments
                files={files}
                toggleActiveCheckBox={toggleActiveCheckBox}
                toggleActiveVersionCheckBox={toggleActiveVersionCheckBox}
                contentType={type}
              />

              <div className="footer">
                <span>Selecione o(s) anexo(s) para adicionar à atividade</span>

                <SimpleButton
                  text="Criar atividade"
                  classButton="buttonBlue"
                  dataQA="button-prospect-edit"
                  onBreadcrumb={true}
                  isValidForm={createIsValid()}
                  toValidate={true}
                  clickEvent={() =>
                    appContext.setCurrentStepBox(
                      type !== 'Pesquisa'
                        ? 'createProjectActivity'
                        : 'createResearchActivity'
                    )
                  }
                >
                  <NewActivityIcon/>
                </SimpleButton>
              </div>
            </>
          ) : (
            <NoResultsFiles
              redirectToSheet={setRedirect()}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ContentListAttachments
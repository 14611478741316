import React, { useContext, useEffect, useState } from 'react'
import LightBoxesContentAttachments from '../../../components/ContentListAttachments/LightBoxesContentAttachments/LightBoxesContentAttachments'
import ContentListAttachments from '../../../components/ContentListAttachments/ContentListAttachments'
import './styles/ContentItem.scss'
import AttachmentContext from '../../../context/attachmentsContext'

const separateFiles = (data, _files) => {
  data.forEach((file, index) => {
    if (file[index]) {
      let attachments = file[index].attachment
      attachments.activitiesNumber = file[index].activitiesNumber
      _files.push(attachments)
      let oldVersion = file[index].olderVersions
      if (!!oldVersion) {
        oldVersion.forEach((old) => {
          old.activitiesNumber = file[index].activitiesNumber
          _files.push(old)
        })
      }
    }
  })
}

const ContentItem = (props) => {
  const {
    currentProject,
    currentFiles,
    showList,
    type,
    history,
    contentType,
  } = props
  const [stateFiles, setStateFiles] = useState('')
  const [stateSelectFiles, setStateSelectFiles] = useState(false)
  const [stateCurrentProject, setCurrentProject] = useState(false)

  const attachmentContext = useContext(AttachmentContext)


  const chaptersOrEpisodes = () => {
    const types = {
      "Dramaturgia": "Capítulo/Episódio",
      "Variedades": "Episódio",
    }
    return types[contentType];
  }

  const toggleOpenList = (ev) => {
    let targetActive = ev.target.closest('.listItem')

    if (targetActive) {
      targetActive.classList.toggle('opened')
    }
  }

  const toSeparateVersion = (files) => {
    let obj = []

    files.forEach((file) => {
      obj.push({ parent: file[0], version: file.filter((e) => e !== file[0]) })
    })

    return obj
  }


  const compileFiles = (data) => {
    let allGroup = attachmentContext.compileAll(data, chaptersOrEpisodes())
    let chapterCompiled = attachmentContext.compiledChapter(data, type, chaptersOrEpisodes())
    let materialCompiled = attachmentContext.compileMaterial(data, 'Material Complementar')

    let mergeGroups = {...allGroup, ...materialCompiled, ...chapterCompiled}
    setStateFiles(
      toSeparateVersion(Object.entries(mergeGroups).map((files) => files[1]))
    )
  }

  const unifyFiles = (data) => {
    let _files = []
    separateFiles(data, _files)
    return !!data.length ? compileFiles(_files) : setStateFiles([])
  }

  const toggleActiveCheckBox = (file, index) => {
    let newState = [...stateFiles]
    newState[index].parent.isActive = !newState[index].parent.isActive
    setStateFiles(newState)
  }

  const toggleActiveVersionCheckBox = (item, index, versionIndex) => {
    let newState = [...stateFiles]
    newState[index].version[versionIndex].isActive =
      !newState[index].version[versionIndex].isActive
    setStateFiles(newState)
  }

  const clearActive = () => {
    let newState = [...stateFiles]
    newState.forEach((file) => {
      file.parent.isActive = false
      file.version.forEach((v) => (v.isActive = false))
    })

    setStateFiles(newState)
  }

  const createIsValid = () => {
    const hasVersions = stateCurrentProject.type !== 'Pesquisa'
    const parentIsValid =
      !!stateFiles.length && stateFiles.some((item) => !!item.parent.isActive)
    const versionIsValid = hasVersions
      ? !!stateFiles.length &&
      stateFiles.some(
        (item) => !!item.version.some((version) => !!version.isActive)
      )
      : hasVersions

    return parentIsValid || versionIsValid
  }

  useEffect(() => {
    setCurrentProject(currentProject)
  }, [currentProject])

  useEffect(() => {
    unifyFiles(currentFiles)
    // eslint-disable-next-line
  }, [currentFiles])

  if (!stateCurrentProject || !showList) return <div/>

  return (
    <>
      <ContentListAttachments
        files={stateFiles}
        data={stateCurrentProject}
        toggleActiveCheckBox={toggleActiveCheckBox}
        toggleActiveVersionCheckBox={toggleActiveVersionCheckBox}
        createIsValid={createIsValid} toggleOpenList={toggleOpenList}
      />

      <LightBoxesContentAttachments
        stateSelectFiles={stateSelectFiles}
        setStateSelectFiles={setStateSelectFiles}
        stateFiles={stateFiles} toggleActiveCheckBox={toggleActiveCheckBox}
        toggleActiveVersionCheckBox={toggleActiveVersionCheckBox}
        clearActive={clearActive}
        currentProject={currentProject}
        type={type} history={history}
      />
    </>
  )
}

export default ContentItem

import React from "react";
import "./styles/NoAttachments.scss";

const NoAttachments = (props) => {
  const { message } = props;

  return (
    <div className="noAttachments">
      <span className="noAttachmentsMessage">{message}</span>
    </div>
  );
};

export default NoAttachments;

import React, {useContext} from "react";
import ScriptBox from "../../../components/ScriptBox/ScriptBox";
import EditIcon from "../../../components/VectorIcons/EditIcon";
import NewEditSheet from "../EditSheetBasic/NewEditSheet";
import AppContext from "../../../context/appContext";
import SheetContext from "../../../context/sheetContext";
import TitleHistory from "../TitleHistory/TitleHistory";
import EditSheetComplement from "../EditSheetComplement/EditSheetComplement";
import EditSheetRelevance from "../EditSheetRelevance/EditSheetRelevance";
import EditRelatedContent from "../EditRelatedContent/EditRelatedContent";
import ProjectRecommendation from "../ProjectRecommendation/ProjectRecommendation";
import TelevisionIcon from "../../../components/VectorIcons/TelevisionIcon";
import EditSheetVarietyComplement from "../EditSheetComplement/EditSheetVarietyComplement";
import EditSheetLiteraryWorks from "../EditSheetBasic/EditSheetLiteraryWorks";
import EditSheetComplementariesLiteraryWorks
  from "../EditSheetComplementariesLiteraryWorks/EditSheetComplementariesLiteraryWorks";
import CreateAndEditCharacter from '../CreateAndEditCharacter/CreateAndEditCharacter'
import EditStatusExternalProject from "../../../components/EditStatusExternalProject/EditStatusExternalProject";

const LightBoxes = (props) => {
  const {id, data, type, dataProject, evaluationDeadline, getStatusExternalProject = () => {}, handleChangeStatusExternalProject = () => {}} = props;

  const appContext = useContext(AppContext);
  const sheetContext = useContext(SheetContext);

  return (
    <div id="contentLightBoxesProjectSheet">

      {appContext.current.step === "editExternalProject" &&
      <ScriptBox
        open={appContext.current.step === "editExternalProject"}
        title="Status do projeto"
        icon={<EditIcon color="#FFF"/>}
        removePadding={true}
        marginBottom={true}
      >
        <EditStatusExternalProject
          getStatusExternalProject={getStatusExternalProject}
          id={id}
          evaluationDeadline={evaluationDeadline}
          handleChangeStatusExternalProject={handleChangeStatusExternalProject} />
      </ScriptBox>
      }


      {appContext.current.step === "editBasicData" && (
        <ScriptBox
          open={appContext.current.step === "editBasicData"}
          title="Editar Dados Básicos"
          icon={<EditIcon color="#FFF"/>}
          marginBottom={true}
        >
          {type.endPoint === "literaryworks" ? (
            <EditSheetLiteraryWorks
              id={id}
              data={data}
              dataProject={dataProject}
            />
          ) : (
            <NewEditSheet
              type={type}
              id={id}
              data={data}
              dataProject={dataProject}
            />
          )}
        </ScriptBox>
      )}

      {appContext.current.step === "editComplementaryData" && (
        <ScriptBox
          open={appContext.current.step === "editComplementaryData"}
          title="Editar Dados Complementares"
          icon={<EditIcon color="#FFF"/>}
          marginBottom={true}
        >
          {type.endPoint === "dramaturgies" &&
          <EditSheetComplement id={id} data={data} dataProject={dataProject}/>
          }
          {type.endPoint === "varieties" &&
          <EditSheetVarietyComplement id={id} data={data} dataProject={dataProject}/>
          }
          {type.endPoint === "literaryworks" &&
          <EditSheetComplementariesLiteraryWorks id={id} data={data} dataProject={dataProject}/>
          }
        </ScriptBox>
      )}

      {appContext.current.step === "editRelevanceComplementaryData" && (
        <ScriptBox
          open={appContext.current.step === "editRelevanceComplementaryData"}
          title="Dados Complementares por Relevância"
          icon={<EditIcon color="#FFF"/>}
          removePadding={true}
          marginBottom={true}
        >
          <EditSheetRelevance id={id} data={data} dataProject={dataProject}/>
        </ScriptBox>
      )}

      {appContext.current.step === "editRelatedContent" && (
        <ScriptBox
          open={appContext.current.step === "editRelatedContent"}
          title="Editar Conteúdo Relacionado"
          icon={<EditIcon color="#FFF"/>}
          marginBottom={true}
        >
          <EditRelatedContent
            data={data}
            title={data.title}
            id={id}
            type={type.endPoint}
          />
        </ScriptBox>
      )}

      {appContext.current.step === "recomendation" && (
        <ScriptBox
          open={appContext.current.step === "recomendation"}
          title="Recomendação do Projeto"
          icon={<EditIcon color="#FFF"/>}
          removePadding={true}
          size="small"
        >
          <ProjectRecommendation
            recommendation={sheetContext.list.result?.recommendation}
            id={id}
            type={type.endPoint}
          />
        </ScriptBox>
      )}

      {appContext.current.step === "createCharacter" && (
        <ScriptBox
          open={appContext.current.step === "createCharacter"}
          title="Novo Personagem"
          icon={<TelevisionIcon color="#FFF"/>}
          removePadding={true}
          marginBottom={true}
        >
          <CreateAndEditCharacter id={id} action="create"/>
        </ScriptBox>
      )}

      {appContext.current.step === "titleHistory" && (
        <ScriptBox
          open={appContext.current.step === "titleHistory"}
          title="Histórico de Títulos"
          removePadding={true}
        >
          <TitleHistory listHistory={sheetContext.list.result?.histories}/>
        </ScriptBox>
      )}
    </div>
  );
}
;

export default LightBoxes;

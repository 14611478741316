import React from 'react';

const IconProjectExternal = (props) => {

  const { color = "#0098CD" } = props;

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1 1)" stroke={color} strokeWidth="2" fill="none" fillRule="evenodd"
         strokeLinecap="round" strokeLinejoin="round">
        <rect width="20" height="20" rx="2.18"/>
        <path d="M5 0v20M15 0v20M0 10h20M0 5h5M0 15h5M15 15h5M15 5h5"/>
      </g>
    </svg>
  );
};

export default IconProjectExternal;
import React, { useState, useContext, useEffect } from "react";
import "./styles/FeedbackModal.scss";
import BoxFooter from "../../../../components/BoxFooter/BoxFooter";
import Button from "../../../../components/Button/Button";
import AppContext from "../../../../context/appContext";
import AttachmentIcon from "../../../../components/VectorIcons/AttachmentIcon";
import DownloadIcon from "../../../../components/VectorIcons/DownloadIcon";
import {
  getService,
  postService,
  putService,
} from "../../../../services/script-api";
import ScriptBox from "../../../../components/ScriptBox/ScriptBox";
import ModalHeader from "../../NewActivity/ModalHeader/ModalHeader";
import FeedbackForm from "../../FeedbackForm/FeedbackForm";

const FeedbackModal = (props) => {
  const { files, currentActivity, _getActvity, open, contentInfo } = props;

  const appContext = useContext(AppContext);

  const initialFeedback = {
    feedbackPerson: currentActivity.feedback?.feedbacker || "",
    feedbackDate:
      !!currentActivity.feedback && currentActivity.feedback?.form.id !== 4
        ? currentActivity.feedback?.date
        : new Date(),
    feedbackType: currentActivity.feedback?.form.id || "",
    feedbackObservation: currentActivity.feedback?.text || "",
    feedbackApplicable: !!currentActivity.feedback
      ? currentActivity.feedback.applicable
      : true,
  };

  const initialState = {
    type: currentActivity.type.id,
    attachments: [],
  };

  const [stateFeedback, setStateFeedback] = useState(initialFeedback);

  const [stateFormOptions, setStateFormOptions] = useState([]);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (contentInfo.info.description) {
      setState({ ...state, description: contentInfo.info.description });
    }
    // eslint-disable-next-line
  }, [contentInfo.info]);

  const getOptions = (path, setter) => {
    getService(`${path}`).then((response) => {
      let compiledData = response.data.map((item) => {
        return {
          id: item.id,
          name: item.value,
          label: item.value,
          value: item.id,
          ...item,
        };
      });

      setter(compiledData);
    });
  };

  useEffect(() => {
    getOptions("activities/feedback/form", setStateFormOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeToggleFeedback = () => {
    if (stateFeedback.feedbackApplicable) {
      setStateFeedback({
        ...stateFeedback,
        feedbackApplicable: !stateFeedback.feedbackApplicable,
      });
    } else {
      setStateFeedback({
        ...stateFeedback,
        feedbackApplicable: !stateFeedback.feedbackApplicable,
        feedbackPerson: "",
        feedbackType: 4,
        feedbackObservation: "",
      });
    }
  };

  const handleChangeFeedbackPerson = (ev) => {
    setStateFeedback({ ...stateFeedback, feedbackPerson: ev.target.value });
  };

  const handleChangeFeedbackDate = (date) => {
    if (!!date) {
      let finalDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toISOString();
      setStateFeedback({ ...stateFeedback, feedbackDate: finalDate });
    }
  };

  const handleChangeFeedbackType = (id, value) => {
    setStateFeedback({ ...stateFeedback, feedbackType: id });
  };

  const handleChangeFeedbackObservation = (ev) => {
    setStateFeedback({
      ...stateFeedback,
      feedbackObservation: ev.target.value,
    });
  };

  const sendFeedbackForm = (e) => {
    e.preventDefault();
    appContext.setShowGlassLoading(true);

    let obj = {
      activityId: currentActivity.id,
      form: !!stateFeedback.feedbackApplicable ? stateFeedback.feedbackType : 4,
      feedbacker: stateFeedback.feedbackPerson,
      applicable: stateFeedback.feedbackApplicable,
      text: stateFeedback.feedbackObservation,
    };

    if (!!stateFeedback.feedbackDate) {
      obj = {
        ...obj,
        date: stateFeedback.feedbackDate,
      };
    }

    postService(`activities/feedback`, obj)
      .then(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Feedback adicionado com sucesso!");
        appContext.setCurrentStepBox("closed");
        _getActvity();
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Erro ao adicionar o feedback!", "error");
      });
  };

  const startSend = (e) => {
    if (!!currentActivity.feedback) {
      sendPutFeedback(e);
    } else {
      sendFeedbackForm(e);
    }
  };

  const sendPutFeedback = (e) => {
    e.preventDefault();
    appContext.setShowGlassLoading(true);

    let obj = {
      id: currentActivity.feedback?.id,
      form: !!stateFeedback.feedbackApplicable ? stateFeedback.feedbackType : 4,
      applicable: stateFeedback.feedbackApplicable,
      feedbacker: stateFeedback.feedbackPerson,
      text: stateFeedback.feedbackObservation,
    };

    if (!!stateFeedback.feedbackDate) {
      obj = {
        ...obj,
        date: stateFeedback.feedbackDate,
      };
    }

    putService(`activities/feedback`, obj)
      .then(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Feedback editado com sucesso!");
        appContext.setCurrentStepBox("closed");
        _getActvity();
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Erro ao editar o feedback!", "error");
      });
  };

  const submitIsValid = () => {
    if (!!stateFeedback.feedbackApplicable) {
      return (
        !!stateFeedback.feedbackPerson &&
        !!stateFeedback.feedbackType &&
        !!stateFeedback.feedbackDate &&
        stateFeedback.feedbackType !== 4
      );
    } else {
      return true;
    }
  };

  useEffect(() => {
    appContext.setShowGlassLoading(files.length === 0 && contentInfo.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <ScriptBox
      open={open}
      icon={
        <ModalHeader
          type={currentActivity.contentType}
          title={currentActivity.contentTitle}
          creators={contentInfo.info.creators}
          format={contentInfo.info.format}
        />
      }
      resizeHeader={true}
      removePadding={true}
      marginBottom={true}
    >
      <form onSubmit={(e) => startSend(e)}>
        <div className="createActivity">
          <div className="createActivityContainer">
            <div className="field">
              <span className="fieldLabel">
                <AttachmentIcon color="#ADAFEF" size={20} />
                Anexos
              </span>

              <div className="insideContainer">
                <div className="attachmentBoxes">
                  {!!files &&
                    files.map((file, index) => {
                      return (
                        <React.Fragment key={index}>
                          {file.parent.isActive && (
                            <div className="attachmentBox" key={index}>
                              <span className="attachmentType">
                                {file.parent?.attachmentType?.name}
                              </span>

                              <span className="attachmentName">
                                <DownloadIcon color="#0098CD" size={20} />
                                {file.parent.fileName}
                              </span>

                              <span className="receivedAt">
                                {appContext.formatDate(
                                  appContext.convertDatePtBr(
                                    file.parent.receivedAt
                                  )
                                )}
                              </span>
                            </div>
                          )}

                          {file.version.map((version, i) => {
                            return (
                              <React.Fragment key={i}>
                                {version.isActive && (
                                  <div className="attachmentBox" key={i}>
                                    <span className="attachmentType">
                                      {version.attachmentType.name}
                                    </span>

                                    <span className="attachmentName">
                                      <DownloadIcon color="#0098CD" size={20} />
                                      {version.fileName}
                                    </span>

                                    <span className="receivedAt">
                                      {appContext.formatDate(
                                        appContext.convertDatePtBr(
                                          version.receivedAt
                                        )
                                      )}
                                    </span>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>

              <FeedbackForm
                data={stateFeedback}
                formOptions={stateFormOptions.filter((e) => e.value !== "N/A")}
                handleChangeToggleFeedback={handleChangeToggleFeedback}
                handleChangeFeedbackPerson={handleChangeFeedbackPerson}
                handleChangeFeedbackDate={handleChangeFeedbackDate}
                handleChangeFeedbackType={handleChangeFeedbackType}
                handleChangeFeedbackObservation={
                  handleChangeFeedbackObservation
                }
              />
            </div>
          </div>

          <BoxFooter>
            <Button
              text="Salvar feedback"
              toValidate={true}
              isValidForm={submitIsValid()}
              classButton="buttonGreen large"
              typeButton="submit"
              dataQA=""
            />
          </BoxFooter>
        </div>
      </form>
    </ScriptBox>
  );
};

export default FeedbackModal;

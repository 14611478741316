import React, { useState, useEffect } from "react";
import "./styles/ThesaurusScope.scss";
import { getService } from "../../services/script-api";
import SimpleScriptBox from "../../views/ProjectSheet/SimpleScriptBox/SimpleScriptBox";
import ModalTitle from "./ModalTitle/ModalTitle";
import TableLoading from "../TableLoading/TableLoading";
import ThesaurusSearch from "./ThesaurusSearch/ThesaurusSearch";
import ScopeBreadcrumb from "./ScopeBreadcrumb/ScopeBreadcrumb";
import ScopeList from "./ScopeList/ScopeList";
import ScopeInformation from "./ScopeInformation/ScopeInformation";
import OpenScope from "./OpenScope/OpenScope";

const ThesaurusScope = (props) => {
  const {
    children,
    thesaurus,
    thesaurusName,
    stateName,
    addThesaurus,
    selecteds = [],
    searchable,
    showBreadcrumb,
    hasHierarchy,
    origin,
  } = props;

  const [stateOpenScope, setStateOpenScope] = useState(false);
  const [stateThesaurusList, setStateThesaurusList] = useState(thesaurus);
  const [stateLoading, setStateLoading] = useState(true);
  const [stateSearchInput, setStateSearchInput] = useState("");
  const [stateSteps, setStateSteps] = useState([""]);
  const [stateCurrentScope, setStateCurrentScope] = useState("");

  useEffect(() => {
    stateSteps.length > 1 ? getThesaurus() : setStateCurrentScope("");
    // eslint-disable-next-line
  }, [stateSteps]);

  useEffect(() => {
    if (!!thesaurus.length && !stateThesaurusList.length) {
      setStateThesaurusList(thesaurus);
      setStateLoading(false);
      !!hasHierarchy && !stateThesaurusList.length && getHierarchies();
    }
    // eslint-disable-next-line
  }, [thesaurus]);

  const getThesaurus = () => {
    setStateLoading(true);
    getService(`thesaurus/${stateSteps[stateSteps.length - 1]}`).then((res) => {
      setStateLoading(false);
      setStateCurrentScope(res.data);
    });
  };

  const getHierarchies = () => {
    setStateLoading(true);
    getService("dramaturgies/thesaurus/hierarchies", { origin: origin }).then(
      (res) => {
        setStateLoading(false);
        setStateThesaurusList(res.data[0].terms);
      }
    );
  };

  const handleCloseModal = () => {
    setStateOpenScope(false);
    setStateCurrentScope("");
    setStateSteps([""]);
    setStateCurrentScope("");
  };

  const thesaurusAlreadySelected = () => {
    return !!selecteds.length
      ? selecteds.some((item) => item.value === stateCurrentScope.id)
      : !!selecteds.length;
  };

  const handleSearchedRemissivesStyles = (remissive) => {
    return stateSearchInput.remissives.includes(remissive) ? "searchTerm" : "";
  };

  const compileBreadcrumb = () => {
    const breadcrumb = [];
    !!stateCurrentScope.parent?.parent &&
      breadcrumb.push({ ...stateCurrentScope.parent.parent });
    !!stateCurrentScope.parent &&
      breadcrumb.push({ ...stateCurrentScope.parent });
    return breadcrumb;
  };

  const handleGoBack = () =>
    stateSteps.length > 1 &&
    setStateSteps((prev) => prev.slice(0, prev.length - 1));

  const eraseStateSteps = () => setStateSteps([""]);

  const handleAddThesaurus = () => {
    const { value, label, id, name } = stateCurrentScope;
    const newThesaurusValue = {
      value: value || id,
      label: label || name,
      origin: stateName,
    };
    handleCloseModal();
    addThesaurus([...selecteds, newThesaurusValue]);
  };

  return (
    <div className="thesaurusScope">
      {children}

      <OpenScope openScope={setStateOpenScope} />

      {stateOpenScope && (
        <SimpleScriptBox
          open={stateOpenScope}
          toggleScriptBox={() => handleCloseModal()}
          title={<ModalTitle title={thesaurusName} />}
          removePadding={true}
        >
          <div className="scopeContent">
            {stateLoading ? (
              <TableLoading />
            ) : (
              <>
                {searchable && (
                  <ThesaurusSearch
                    options={thesaurus}
                    searchInput={stateSearchInput}
                    setSearchInput={setStateSearchInput}
                    setStateSteps={setStateSteps}
                  />
                )}

                {!stateCurrentScope && (
                  <ScopeList
                    list={stateThesaurusList}
                    setStateSteps={setStateSteps}
                  />
                )}

                {showBreadcrumb && !!stateCurrentScope && (
                  <ScopeBreadcrumb
                    scope={stateCurrentScope}
                    topLevel={thesaurusName}
                    breadcrumb={compileBreadcrumb()}
                    setStateSteps={setStateSteps}
                    eraseSteps={eraseStateSteps}
                  />
                )}

                {!!stateCurrentScope && (
                  <ScopeInformation
                    currentScope={stateCurrentScope}
                    searchInput={stateSearchInput}
                    alreadyUsed={thesaurusAlreadySelected}
                    addThesaurus={handleAddThesaurus}
                    remissiveStyles={handleSearchedRemissivesStyles}
                    showBreadcrumb={showBreadcrumb}
                    handleGoBack={handleGoBack}
                  />
                )}

                {!!stateCurrentScope &&
                  !!hasHierarchy &&
                  !!stateCurrentScope.children.length && (
                    <ScopeList
                      list={stateCurrentScope.children}
                      setStateSteps={setStateSteps}
                      childrenList={true}
                    />
                  )}
              </>
            )}
          </div>
        </SimpleScriptBox>
      )}
    </div>
  );
};

export default ThesaurusScope;

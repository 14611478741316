import React from "react";
import "./styles/AsyncLoading.scss";

const AsyncLoading = () => {
  return (
    <div className="asyncLoadingContainer">
      <div className="asyncLoading" />
    </div>
  );
};

export default AsyncLoading;

import React from 'react'
import './styles/Button.scss'

const Button = (props) => {
  const {
    iconSide = 'left',
    classButton,
    dataQA,
    children,
    typeButton,
    text,
    clickEvent,
    onBreadcrumb,
    rippleEffect,
    isValidForm,
    toValidate,
  } = props

  const setClass = `${isValidForm ? 'active' : 'disabled'}`
  const isActive = toValidate ? setClass : ''
  const _default = () => 'default'
  const typeClass = `${classButton} button ${rippleEffect ? 'rippleButton' : ''}`

  return (
    <button
      className={`simpleButton ${typeClass} ${isActive}`}
      type={!!typeButton ? typeButton : 'button'}
      onClick={!!clickEvent ? clickEvent : () => _default()}
      data-qa={`${dataQA}`}
    >
      {iconSide !== 'left' ? (
        <>
          <span className={`${onBreadcrumb && 'hideOnBreadcrumb'}`}>
            {text}
          </span>
          {children}
        </>
      ) : (
        <>
          {children}
          <span className={`${onBreadcrumb && 'hideOnBreadcrumb'}`}>
            {text}
          </span>
        </>
      )}
    </button>
  )
}

export default Button

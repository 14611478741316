import React, { useContext } from "react";
import AppContext from "../../context/appContext";
import "./styles/GlassLoading.scss";

const GlassLoading = () => {
  const appContext = useContext(AppContext);

  return (
    appContext.showGlassLoading && (
      <div id="GlassLoading">
        <div className="loadingCircle" />
      </div>
    )
  );
};

export default GlassLoading;

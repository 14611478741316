import React, { useContext, useEffect, useState } from "react";
import "./styles/CharactersTable.scss";
import OverflowMenu from "../../../components/OverflowMenu/OverflowMenu";
import EditIcon from "../../../components/VectorIcons/EditIcon";
import PowerIcon from "../../../components/VectorIcons/PowerIcon";
import ImportanceMark from "../../Conflicts/ImportanceMark/ImportanceMark";
import DetailedTelevisionIcon from "../../../components/VectorIcons/DetailedTelevisionIcon";
import SheetContext from "../../../context/sheetContext";
import AppContext from "../../../context/appContext";
import ConfirmationBox from "../../../components/ConfirmationBox/ConfirmationBox";
import ScriptBoxCaller from "../../../components/ScriptBoxCaller/ScriptBoxCaller";
import ScriptBoxDescription from "../../../components/ScriptBoxDescription/ScriptBoxDescription";
import ScriptBox from "../../../components/ScriptBox/ScriptBox";
import TelevisionIcon from "../../../components/VectorIcons/TelevisionIcon";
import TableLoading from "../../../components/TableLoading/TableLoading";
import CreateAndEditCharacter from '../CreateAndEditCharacter/CreateAndEditCharacter'
import AlertBox from '../../../components/AlertBox/AlertBox'

const CharactersTable = (props) => {
  const { havePermission } = props;

  const sheetContext = useContext(SheetContext);
  const appContext = useContext(AppContext);
  const [stateConfirmation, setStateConfirmation] = useState(false);
  const [stateCurrentCharacter, setStateCurrentCharacter] = useState([]);
  const [stateDetailsBox, setStateDetailsBox] = useState({
    open: false,
    currentItem: "",
  });

  const { id } = props;
  const { getCharacters, characters } = sheetContext;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getCharacters(id);
    // eslint-disable-next-line
  }, []);

  if (sheetContext.characters.loading) return <TableLoading />;

  if (characters && characters.error) 
  return <AlertBox 
    hasCloseIcon={false}
    text={`Erro ao carregar personagens. Por favor, contacte o administrador.`}
    type="error"
    color="#ff9494"
    />;

  const actionCharacter = (character, action) => {
    setStateCurrentCharacter(character);
    action === "active"
      ? setStateConfirmation(true)
      : appContext.setCurrentStepBox("editCharacter");
  };

  const handleDetailsItem = (character) => {
    const names = [];
    character.identifications.forEach((name, index) => {
      names.push(
        `${index > 0 ? " " : ""}${name.name ?? ""}${name.lastName ?? ""}${
          !!name.nickName ? "(" + name.nickName.trim() + ")" : ""
        }`
      );
    });

    const compiledNames = names.toString();
    const activities = character.activities.toString().replace(/,/g, ", ");

    setStateDetailsBox({
      ...stateDetailsBox,
      open: true,
      currentItem: {
        names: compiledNames,
        activities: activities,
        description: character.description,
      },
    });
  };

  const closedDescription = () => {
    setStateDetailsBox({
      ...stateDetailsBox,
      open: false,
    });
  };

  // eslint-disable-next-line
  const capitalizeString = (string) => {
    let capitalized = string;

    if (!!string) {
      const stringArr = string.split(" ");
      capitalized = "";
      stringArr.forEach((item, index) => {
        capitalized =
          capitalized +
          item.slice(0, 1).toUpperCase() +
          item.slice(1).toLowerCase() +
          `${index + 1 < stringArr.length ? " " : ""}`;
      });
    }

    return capitalized;
  };

  return (
    <div className="charactersTable">
      {!!sheetContext.characters?.result?.length ? (
        <>
          <ul className="charactersTableHeader">
            <li className="charactersItemHeader characterName">Nome</li>
            <li className="charactersItemHeader talentNames">Talento</li>
            <li className="charactersItemHeader characterRelevance">
              Relevância
            </li>
            <li className="charactersItemHeader characterWeight">Peso</li>
            <li className="charactersItemHeader characterDetails">
              Atividades/Características
            </li>
            <li className="charactersItemHeader characterDescription">
              Descrição/Observação
            </li>
            <li className="charactersItemHeader characterOptions" />
          </ul>

          {sheetContext.characters.result.map((character, index) => {
            return (
              <ul className="charactersListItems" key={index}>
                <li
                  className={`charactersItem ${
                    !character.isActive ? "opacity" : ""
                  }`}
                >
                  <div className="containerCharactersItem">
                    <div className="characterNames">
                      {character.identifications.map((identification, i) => {
                        return (
                          <React.Fragment key={i}>
                            <div className="characterName">
                              {!!identification.name && (
                                <span className="name">
                                  {identification.name}{" "}
                                  {identification.lastName}
                                </span>
                              )}

                              {!!identification.nickName && (
                                <>
                                  <span className="nickName">
                                    {identification.nickName.trim()}
                                  </span>
                                  {i + 1 < character.identifications.length ? (
                                    <div className="characterSeparator">|</div>
                                  ) : null}
                                </>
                              )}
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>

                  <div className="talentNames">
                    {!!character.phases.length
                      ? character.phases.map((talent, index) => {
                          return (
                            <div className="talentName" key={index}>
                              <span>{!!talent && talent.talent}</span>
                              <span>
                                {!!talent.phase
                                  ? ` (${talent.phase.trim()})`
                                  : ""}
                              </span>
                              {index + 0 < character.phases.length &&
                              character.phases.every(
                                (item) => !!item.talent
                              ) ? (
                                <div className="characterSeparator">|</div>
                              ) : (
                                "-"
                              )}
                            </div>
                          );
                        })
                      : "-"}
                  </div>

                  <div className="characterRelevance">
                    <ImportanceMark
                      level={
                        !!character.relevance
                          ? character.relevance.value
                          : "unclassified"
                      }
                    />
                    <span className="relevanceType">
                      {!!character.relevance && character.relevance.value}
                    </span>
                  </div>

                  <div className="characterWeight">
                    {!!character.weight ? character.weight : "-"}
                  </div>

                  <div className="characterDetails">
                    <div className="detailsTags">
                      {character.activities.map((activitie, ind) => {
                        return (
                          <span className="detailTag" key={ind}>
                            {activitie}
                          </span>
                        );
                      })}
                    </div>
                  </div>

                  <div className="characterDescription">
                    <span>
                      {!!character.description
                        ? `${character.description.slice(0, 35).trim()}${
                            character.description.length >= 35 ? "... " : ""
                          }`
                        : "-"}

                      {!!character.description &&
                      character.description.length >= 35 ? (
                        <ScriptBoxCaller
                          callerText="mais"
                          event={() => handleDetailsItem(character)}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>

                  <div className="characterOptions">
                    {havePermission && (
                      <OverflowMenu parentClassName="characterOptions">
                        {character.isActive && (
                          <div
                            className="optionItem"
                            onClick={() => actionCharacter(character, "edit")}
                          >
                            <EditIcon />
                            <span>Editar</span>
                          </div>
                        )}
                        <div
                          className="optionItem"
                          onClick={() => actionCharacter(character, "active")}
                        >
                          <PowerIcon
                            color={`${
                              character.isActive ? "#EC1045" : "#009C69"
                            }`}
                          />
                          <span>
                            {character.isActive ? "Inativar" : "Ativar"}
                          </span>
                        </div>
                      </OverflowMenu>
                    )}
                  </div>
                </li>
              </ul>
            );
          })}
        </>
      ) : (
        <div className="noCharactersCreated">
          <DetailedTelevisionIcon />
          <span className="noCharactersMessage">
            Nenhum personagem cadastrado.
          </span>
        </div>
      )}

      {appContext.current.step === "editCharacter" && (
        <ScriptBox
          open={appContext.current.step === "editCharacter"}
          title="Editar Personagem"
          icon={<TelevisionIcon color="#FFF" />}
          removePadding={true}
          marginBottom={true}
        >
          <CreateAndEditCharacter action="edit" id={id} data={stateCurrentCharacter} />
        </ScriptBox>
      )}

      {stateConfirmation && (
        <ConfirmationBox
          open={stateConfirmation}
          closedBox={() => setStateConfirmation(false)}
          handleEvent={() =>
            sheetContext.toggleCharacter(
              stateCurrentCharacter.id,
              !stateCurrentCharacter.isActive,
              id
            )
          }
          title="Tem certeza?"
          textButton={`Sim, ${
            stateCurrentCharacter.isActive ? "Inativar" : "Ativar"
          }`}
          text={`Este personagem será ${
            stateCurrentCharacter.isActive ? "Inativado" : "Ativado"
          }!`}
          buttonColor={stateCurrentCharacter.isActive ? "#EC1045" : "#009C69"}
        />
      )}

      {stateDetailsBox.open && (
        <ScriptBoxDescription
          open={stateDetailsBox.open}
          clear={() => closedDescription()}
          title={stateDetailsBox.currentItem.names}
          subTitle={stateDetailsBox.currentItem.activities}
        >
          <p className="boxParagraph">
            {stateDetailsBox.currentItem.description}
          </p>
        </ScriptBoxDescription>
        )}
    </div>
      );
};

export default CharactersTable;

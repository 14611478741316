import React, { useContext, useState } from "react";
import "./styles/SearchHeader.scss";
import { Redirect } from "react-router-dom";
import SearchIcon from "../VectorIcons/SearchIcon";
import Button from "../Button/Button";
import AppContext from "../../context/appContext";
import ChannelContext from "../../context/externalProjectsContext";
import UserContext from "../../context/userContext";

const SearchHeader = () => {
  const [inputFocus, setInputFocus] = useState("#FFF");

  const appContext = useContext(AppContext);
  const channelContext = useContext(ChannelContext);
  const userContext = useContext(UserContext);

  const isChannelRole =
    userContext.havePermission(["ExternalProject"]).length === 0;
  const searchRole = isChannelRole ? "channels" : "script";

  const [state, setState] = useState({
    filter: "",
    redirect: false,
  });

  const scriptSearch = () => {
    appContext.setCurrentTypePath("contents");
    appContext.setCurrentTerm(state.filter);
    appContext.setFilterSearch({
      Term: state.filter,
      PageNumber: 1,
      Limit: 12,
    });
    appContext.setParameterized(false);
  };

  const channelsSearch = () => {
    channelContext.setCurrentTerm(state.filter);
    channelContext.setCurrentTypeSearch({
      filterType: "Todos",
      filterValue: { Limit: 12, term: state.filter },
    });
    appContext.setRedirectSearch(true);
  };

  const handleUserTypeSearch = (type = "script") => {
    if (type === "script") {
      return scriptSearch();
    }
    if (type === "channels") {
      return channelsSearch();
    }
    return;
  };

  const handleRedirect = (type = "script") => {
    const types = {
      script: "/busca",
      channels: "/projetos-externos",
    };
    return types[type];
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUserTypeSearch(searchRole);
  };

  const handleChange = (ev) => {
    setState({
      ...state,
      filter: ev.target.value,
    });
  };

  const redirectUrl = handleRedirect(searchRole);

  return (
    <div className="searchHeader">
      <form onSubmit={(e) => handleSubmit(e)}>
        <input
          onChange={(e) => handleChange(e)}
          type="text"
          placeholder="Buscar"
          autoComplete="on"
          onBlur={() => setInputFocus("#FFF")}
          onFocus={() => setInputFocus("#17B4E1")}
        />

        <Button typeButton="submit">
          <SearchIcon strokeCollor={inputFocus} />
        </Button>

        {appContext.redirect && <Redirect to={redirectUrl} />}
      </form>
    </div>
  );
};

export default SearchHeader;

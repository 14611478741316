import React, { useState } from 'react';
import "./styles/TabsTypesNavigation.scss"
import Masks from "../VectorIcons/Masks";
import Microphone from "../VectorIcons/Microphone";

const TabsTypesNavigation = (props) => {
  const { data, setFilter, filter } = props

  let initialFilter = { origins: [1, 2], limit: 12, channelIds: [] }

  const [stateCurrentType, setCurrentType] = useState("all")

  const icons = (type) => {
    let list = {
      "Dramaturgia Curta": (<Masks color={`${type === stateCurrentType ? "#fff" : "#adafef"}`}/>),
      "Dramaturgia Longa": (<Masks color={`${type === stateCurrentType ? "#fff" : "#adafef"}`}/>),
      "Variedades": (<Microphone color={`${type === stateCurrentType ? "#fff" : "#adafef"}`}/>)
    }

    return list[type];
  }

  const clearFilter = () => {
    let f = {...filter}
    delete f.groupId
    setFilter(f)
    setCurrentType("all")
  }

  const setCurrenTypeAndFilter = (id, type) => {
    setCurrentType(type)
    setFilter({...initialFilter, groupId: id})
  }

  const ContentTypesFree = (props) => {
    const {contents} = props

    return (
      <div className="searchesNavigationContainer">
        <div className={`type ${
            stateCurrentType === "all" ? "active" : ""
          }`}
          onClick={() => clearFilter()}
        >
          <span className="typeLabel">Todos</span>
        </div>
        {!!contents &&
        contents.map((item) => {
          return (
            <div
              className={`type ${
                stateCurrentType === item.value ? "active" : ""
              }`}
              onClick={() => setCurrenTypeAndFilter(item.id, item.value)}
              key={item.id}
            >
              {icons(item.value)}
              <span className="typeLabel">{item.value}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="searchNavigation">
      <ContentTypesFree contents={data}/>
    </div>
  );
};

export default TabsTypesNavigation;
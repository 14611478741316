import React from "react";
import "./styles/Option.scss";
import ReactImageFallback from "react-image-fallback";
import ProspectRoleIcon from "../../ProspectsRoleIcon/ProspectRoleIcon";
import StarRoleIcon from "../../ProspectsRoleIcon/StarRoleIcon";

const Option = ({ children, innerRef, innerProps, isFocused, ...props }) => {
  const { photo, origin, label, role } = props.data;

  return (
    <div
      className={`option ${isFocused ? "isFocused" : ""}`}
      ref={innerRef}
      {...innerProps}
    >
      <div className="optionInfo">
        <div className="optionImage">
          <ReactImageFallback
            src={photo}
            fallbackImage={
              origin === "Star" ? <StarRoleIcon /> : <ProspectRoleIcon />
            }
            className="valueLabelImage"
          />
        </div>

        <div className="label">
          <span className="labelName">{label}</span>
          <span className="labelRole">
            {role ? role : origin === "Prospect" ? "Proponente" : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Option;

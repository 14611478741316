import React, { useContext, useEffect, useState } from "react";
import Textarea from "../../../components/Textarea/Textarea";
import AsyncLoading from "../../../components/AsyncLoading/AsyncLoading";
import Select from "react-select";
import Button from "../../../components/Button/Button";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import { getService, putService } from "../../../services/script-api";
import AppContext from "../../../context/appContext";
import SheetContext from "../../../context/sheetContext";

const EditSheetComplementariesLiteraryWorks = (props) => {
  const { id, data, dataProject } = props;

  const [state, setState] = useState({
    genres: data.genres,
    universe: data.universe,
    storyLine: data.storyLine,
  });
  const [stateOptions, setStateOptions] = useState({
    universe: [],
    genres: [],
  });

  const appContext = useContext(AppContext);
  const sheetContext = useContext(SheetContext);

  const handleChangeStoryLine = (ev) => {
    setState({
      ...state,
      storyLine: ev.target.value,
    });
  };

  const getThesaurus = (origin, key) => {
    getService(`literaryworks/thesaurus?origins=${origin}`).then((res) => {
      setStateOptions((prevState) => {
        return {
          ...prevState,
          [key]: res.data,
        };
      });
    });
  };

  useEffect(() => {
    getThesaurus(33, "universe");
    getThesaurus(34, "genres");
    // eslint-disable-next-line
  }, []);

  const compileId = (item) => {
    let a = [];

    if (!!item) {
      item.forEach((res) => {
        a.push(res.value || res.id);
      });
    }

    return a;
  };

  const onChange = (value, key) => {
    setState({
      ...state,
      [key]: value || [],
    });
  };

  const putLiteraryWorks = () => {
    let compileState = {
      genresIds: compileId(state.genres),
      universeIds: compileId(state.universe),
      storyLine: state.storyLine,
    };

    let obj = {
      ...dataProject.basic,
      ...compileState,
      id: id,
    };

    appContext.setShowGlassLoading(true);
    putService("literaryworks", obj)
      .then((res) => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Ficha editada com sucesso!");
        appContext.setCurrentStepBox("closed");
        sheetContext._getProjectSheet(id, "literaryworks");
      })
      .catch((e) => {
        appContext.sendMessage("Erro ao editar ficha!", "error");
      });
  };

  return (
    <div id="EditSheetComplementariesLiteraryWorks">
      <div className="fieldset">
        <label>GÊNERO</label>
        <Select
          className="reactSelect"
          classNamePrefix="reactSelect"
          value={!!state.genres.length && state.genres.map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          }))}
          options={
            stateOptions.genres.map((item) => ({
              ...item,
              label: item.name,
              value: item.id,
            }))
          }
          onChange={(ev) => onChange(ev, "genres")}
          placeholder="Digite um nome e aperte ENTER"
          components={{
            LoadingIndicator: () => <AsyncLoading />,
          }}
          isMulti
        />
      </div>

      <div className="fieldset">
        <label>UNIVERSO</label>
        <Select
          className="reactSelect"
          classNamePrefix="reactSelect"
          onChange={(ev) => onChange(ev, "universe")}
          value={!!state.universe.length && state.universe.map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          }))}
          options={stateOptions.universe.map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          }))}
          placeholder="Digite um nome e aperte ENTER"
          components={{
            LoadingIndicator: () => <AsyncLoading />,
          }}
          isMulti
        />
      </div>

      <Textarea
        handleChange={handleChangeStoryLine}
        label="storyline"
        maxRows={16}
        value={state.storyLine}
      />

      <BoxFooter>
        <Button
          text="Salvar"
          toValidate={true}
          isValidForm={true}
          classButton="buttonGreen large"
          typeButton="submit"
          clickEvent={putLiteraryWorks}
          dataQA="button-prospect-new-save"
        />
      </BoxFooter>
    </div>
  );
};

export default EditSheetComplementariesLiteraryWorks;

import React from "react";

const ThesaurusIcon = (props) => {
  const { handleClick } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      onClick={() => handleClick()}
    >
      <g fill="#999BE0">
        <path d="M7.007 14C10.871 14 14 10.864 14 7s-3.129-7-6.993-7C3.137 0 0 3.136 0 7s3.136 7 7.007 7zM7 1.4c3.094 0 5.6 2.506 5.6 5.6s-2.506 5.6-5.6 5.6S1.4 10.093 1.4 7 3.907 1.4 7 1.4z" />
        <path d="M6.94 9.27c.047 0 .07-.023.07-.07l.07-1.24c.227-.093.443-.208.65-.345.207-.137.388-.297.545-.48.157-.183.28-.388.37-.615.09-.227.135-.48.135-.76 0-.287-.052-.55-.155-.79-.103-.24-.247-.447-.43-.62s-.405-.308-.665-.405c-.26-.097-.55-.145-.87-.145-.233 0-.472.025-.715.075-.243.05-.452.118-.625.205-.053.027-.08.067-.08.12v.55c0 .067.023.083.07.05.173-.087.357-.153.55-.2.193-.047.397-.07.61-.07.22 0 .42.033.6.1s.335.16.465.28c.13.12.232.265.305.435.073.17.11.355.11.555 0 .22-.038.418-.115.595-.077.177-.183.335-.32.475-.137.14-.295.265-.475.375-.18.11-.373.205-.58.285-.053.02-.08.057-.08.11l.04 1.46c0 .047.023.07.07.07h.45zm-.22 1.8c.16 0 .292-.058.395-.175.103-.117.155-.262.155-.435s-.052-.318-.155-.435c-.103-.117-.235-.175-.395-.175-.16 0-.292.058-.395.175-.103.117-.155.262-.155.435s.052.318.155.435c.103.117.235.175.395.175z" />
        <path d="M6.94 9.27c.047 0 .07-.023.07-.07l.07-1.24c.227-.093.443-.208.65-.345.207-.137.388-.297.545-.48.157-.183.28-.388.37-.615.09-.227.135-.48.135-.76 0-.287-.052-.55-.155-.79-.103-.24-.247-.447-.43-.62s-.405-.308-.665-.405c-.26-.097-.55-.145-.87-.145-.233 0-.472.025-.715.075-.243.05-.452.118-.625.205-.053.027-.08.067-.08.12v.55c0 .067.023.083.07.05.173-.087.357-.153.55-.2.193-.047.397-.07.61-.07.22 0 .42.033.6.1s.335.16.465.28c.13.12.232.265.305.435.073.17.11.355.11.555 0 .22-.038.418-.115.595-.077.177-.183.335-.32.475-.137.14-.295.265-.475.375-.18.11-.373.205-.58.285-.053.02-.08.057-.08.11l.04 1.46c0 .047.023.07.07.07h.45zm-.22 1.8c.16 0 .292-.058.395-.175.103-.117.155-.262.155-.435s-.052-.318-.155-.435c-.103-.117-.235-.175-.395-.175-.16 0-.292.058-.395.175-.103.117-.155.262-.155.435s.052.318.155.435c.103.117.235.175.395.175z" />
      </g>
    </svg>
  );
};

export default ThesaurusIcon;

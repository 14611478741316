import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { getService } from "../../services/script-api";
import "./styles/ReleasedTexts.scss";
import ReleasedItemProject from "./ReleasedItemProject/ReleasedItemProject";
import TableLoading from "../../components/TableLoading/TableLoading";
import ListHeader from "./ListHeader/ListHeader";
import FilteredReleased from "./FilteredReleased/FilteredReleased";
import CountReleasedAttachments from "./CountReleasedAttachments/CountReleasedAttachments";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ReleasedFilterFields from "./ReleasedFilterFields/ReleasedFilterFields";
import ContentPagination from "../../components/ContentPagination/ContentPagination";
import NoContents from "../ListStatusActivities/NoContents/NoContents";
import { Link } from "react-router-dom";
import TabsTypesNavigation from "../../components/TabsTypesNavigation/TabsTypesNavigation";
import UserContext from "../../context/userContext";
import AppContext from "../../context/appContext";
import ViewFile from "../../components/ViewFile/ViewFile";

const ReleasedTexts = () => {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const userChannelIds = userContext?.me?.user?.channels?.map(
    (channel) => channel.id
  );
  const isChannelRole = userContext?.me?.user?.roles?.includes("Channel");

  const [state, setState] = useState({ loading: true, list: {} });
  const [filter, setFilter] = useState({
    origins: [1, 2],
    limit: 12,
    channelIds: [],
    filter: 0,
    idFilter: 0,
  });
  const [alternativeLoading, setAlternativeLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    primary: "",
    secondary: "",
  });
  const [thesaurusList, setThesaurusList] = useState([]);
  const [direction, setDirection] = useState("");
  const [stateTabsTypeNavigation, setStateTabsTypeNavigation] = useState({
    loading: true,
    list: [],
  });

  const handlePageChange = (page) => {
    setState({ ...state, loading: true });
    setFilter({ ...filter, pageNumber: page.pageNumber });
  };

  const orderByReleaseDate = () => {
    const newDirection = direction === 0 ? 1 : 0;
    setState({ ...state, loading: true });
    setFilter({
      ...filter,
      "OrderBy.Column": "ReceivedAt",
      "OrderBy.Direction": newDirection,
    });
    setDirection(newDirection);
  };

  const handleAddChannelId = (arrayIds) => {
    setFilter((prevState) => ({ ...prevState, channelIds: arrayIds }));
  };

  const handleDeleteChannelId = (id) => {
    let channels = filter.channelIds.filter((channel) => channel !== id);
    const { primary, secondary } = selectedFilters;

    const newSelectedFilters = {
      primary: primary !== id ? primary : "",
      secondary: secondary !== id ? secondary : "",
    };

    if (!newSelectedFilters.primary) {
      newSelectedFilters.secondary = "";
      channels = [];
    }

    setFilter((prevState) => ({ ...prevState, channelIds: channels }));
    setSelectedFilters(newSelectedFilters);
  };

  const returnSelectedFilters = () => {
    return thesaurusList.filter((item) => filter.channelIds.includes(item.id));
  };

  const handleChannelIdsFilter = () => {
    const newFilter = { ...filter };

    const isFiltersSelected =
      selectedFilters.primary || selectedFilters.secondary;

    const thesaurusChildren = thesaurusList
      .filter((item) => !!item.parentId)
      .map((item) => item.id);

    const filteredChildrenChannelsIds = userChannelIds?.filter((id) =>
      thesaurusChildren.includes(id)
    );

    if (isChannelRole && !isFiltersSelected) {
      newFilter.channelIds = filteredChildrenChannelsIds;
      newFilter.filter = 0;
      newFilter.idFilter = 0;
    }

    if (isChannelRole && isFiltersSelected) {
      const handledType = selectedFilters.secondary
        ? selectedFilters.secondary
        : selectedFilters.primary;

      const roleIdsAndFilter = filteredChildrenChannelsIds;
      newFilter.channelIds = [...new Set(roleIdsAndFilter)];
      newFilter.filter = 1;
      newFilter.idFilter = handledType;
    }

    if (!isChannelRole && newFilter.channelIds.length) {
      newFilter.channelIds = selectedFilters.secondary
        ? selectedFilters.secondary
        : selectedFilters.primary;
    }

    return newFilter;
  };

  const getFormatsFilter = (formatIds) =>
    setFilter({ ...filter, formatIds: formatIds, pageNumber: 1 });

  const getReleaseds = () => {
    if (!state.loading) setState({ ...state, loading: true });
    setAlternativeLoading(true);

    if (filter.title || filter.channelIds.length || filter.groupId) {
      getService("textrelease/simple", handleChannelIdsFilter()).then((res) => {
        setState({
          ...state,
          loading: false,
          list: res,
        });
        setAlternativeLoading(false)
      });
    } else {
      setAlternativeLoading(false)
    }
  };

  const setLoadingTabsTypeNavigation = (bool) => {
    setStateTabsTypeNavigation((prevState) => {
      return {
        ...prevState,
        loading: bool,
      };
    });
  };

  const getTabsTypeNavigation = () => {
    setLoadingTabsTypeNavigation(true);
    getService("textrelease/groups").then((res) => {
      setStateTabsTypeNavigation((prevState) => {
        return {
          ...prevState,
          loading: false,
          list: res.data,
        };
      });
    });
  };

  const getThesaurusList = () => {
    getService("dramaturgies/thesaurus?origins=28").then((res) => {
      const list = res.data.map((item) => ({ ...item, value: item.id }));
      setThesaurusList(list);
    });
  };

  useEffect(() => {
    if (!userContext?.me?.loading && thesaurusList.length) getReleaseds();
    // eslint-disable-next-line
  }, [filter, thesaurusList.length]);

  useEffect(() => {
    getThesaurusList();
    getTabsTypeNavigation();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>Script - Textos Liberados</title>
      </Helmet>
      <div id="ReleasedTexts">
        <Breadcrumb>
          <h2>Textos Liberados</h2>
        </Breadcrumb>

        <div id="TabsTypesNavigation">
          {!stateTabsTypeNavigation.loading && (
            <TabsTypesNavigation
              setFilter={setFilter}
              filter={filter}
              data={stateTabsTypeNavigation.list}
            />
          )}
        </div>

        <ReleasedFilterFields
          setFilter={setFilter}
          thesaurusList={thesaurusList}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          handleAddChannelId={handleAddChannelId}
          isChannelRole={isChannelRole}
        />

        <div className="container">
          <div className="paginationHeader">
            <div className="info">
              <CountReleasedAttachments
                countAttachments={
                  state.list?.pagingInfo?.extraTotals?.totalAttachments
                }
                countProjects={state.list?.pagingInfo?.totalItems}
              />

              {!!filter.channelIds.length && (
                <FilteredReleased
                  selectedFiltersThesaurus={returnSelectedFilters()}
                  handleDeleteChannelId={handleDeleteChannelId}
                />
              )}
            </div>

            <ListHeader
              direction={direction}
              orderByReleaseDate={orderByReleaseDate}
              getFormatsFilter={getFormatsFilter}
            />
          </div>
        </div>

        {!state.loading && !state.list.data.length && <NoContents />}

        {alternativeLoading && <TableLoading />}

        {!state.loading && !!state.list.data.length && (
          <div className="container contentReleasedAttachments">
            {state.list.data.map((project, index) => {
              return (
                <ul className="contentListAttachmentsReleased" key={index}>
                  <li>
                    <Link to={`/texto-liberado/${project.id}`}>
                      <ReleasedItemProject project={project} />
                    </Link>
                  </li>
                </ul>
              );
            })}
          </div>
        )}

        {!!state.list.pagingInfo && state.list.pagingInfo.totalPages > 1 && (
          <div className="containerPagination">
            <div className="container">
              <ContentPagination
                totalItems={state.list.pagingInfo.totalItems}
                itemsPerPage={state.list.pagingInfo.itemsPerPage}
                currentPage={state.list.pagingInfo.currentPage}
                prevPage={state.list.pagingInfo.previousPage}
                nextPage={state.list.pagingInfo.nextPage}
                totalPages={state.list.pagingInfo.totalPages}
                handleChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
      {appContext.current?.step === "ViewerFileBox" && (
        <ViewFile url={appContext.file?.url} title={appContext.file?.title} />
      )}
    </>
  );
};

export default ReleasedTexts;

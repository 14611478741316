import React, { useContext, useEffect, useState } from "react";
import "./styles/PhasesCard.scss";
import AppContext from "../../context/appContext";
import SheetContext from "../../context/sheetContext";
import ScriptBoxCaller from "../ScriptBoxCaller/ScriptBoxCaller";
import { getService } from "../../services/script-api";
import ScriptBox from "../ScriptBox/ScriptBox";
import ProjectPhases from "../../views/ProjectSheet/ProjectPhases/ProjectPhases";
import SmallLoading from "../SmallLoading/SmallLoading";

const PhasesCard = (props) => {
  const { cardTitle, projectId, type } = props;

  const appContext = useContext(AppContext);
  const sheetContext = useContext(SheetContext);

  const [stateIsOpen, setStateIsOpen] = useState(false);
  const [statePhases, setStatePhases] = useState({ loading: true, list: [] });

  const getProjectPhases = () => {
    getService(`${type}/${projectId}/phases`)
      .then((response) => {
        setStatePhases({
          loading: false,
          list: response.data,
        });
      })
      .catch((e) => {
        setStatePhases((prevState) => {
          return {
            ...prevState,
            loading: false,
          };
        });
      });
  };

  useEffect(() => {
    getProjectPhases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (statePhases.loading)
    return (
      <div className="centerLoading">
        <SmallLoading />
      </div>
    );

  return (
    <div className="infoCard">
      <div
        className={`phasesCardHeader ${stateIsOpen ? "opened" : ""}`}
        onClick={() => setStateIsOpen(!stateIsOpen)}
      >
        <div className="cardArrow" />
        <span className="cardTitle">{cardTitle}</span>
      </div>

      <div className={`cardContentContainer ${stateIsOpen ? "opened" : ""}`}>
        {!statePhases.loading && !!statePhases.list.length ? (
          statePhases.list.slice(0, 3).map((phase, index) => {
            return (
              <div className="cardContent" key={index}>
                <div className="phaseCardItem">
                  <span className="phaseTitle">
                    {sheetContext.phasesFromTo(phase.phaseType)}
                  </span>

                  {phase.phasePeriod.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="phaseName">{item.title}</div>
                        <div className="phaseInfo">
                          <span className="phasePeriod">
                            {`${appContext.formatDate(
                              appContext.convertDatePtBr(item.beginDate)
                            )}`}
                            {!!item.endDate && " - "}
                            {!!item.endDate &&
                              `${appContext.formatDate(
                                appContext.convertDatePtBr(item.endDate)
                              )}`}
                          </span>
                          <span className="phaseStatus">{item.status}</span>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <div className="cardContent">
            <span>Não há nenhuma fase.</span>
          </div>
        )}

        {!statePhases.loading && statePhases.list.length > 3 && (
          <div className="cardContent">
            <div className="allAttachments">
              <ScriptBoxCaller
                callerText="Ver todos as fases"
                event={() => appContext.setCurrentStepBox("phases")}
              />
            </div>
          </div>
        )}
      </div>

      {appContext.current.step === "phases" && (
        <ScriptBox
          open={appContext.current.step === "phases"}
          title="Fases do projeto"
        >
          <ProjectPhases phases={statePhases} />
        </ScriptBox>
      )}
    </div>
  );
};

export default PhasesCard;

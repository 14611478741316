import React from "react";
import "./styles/ParameterizedSearchButton.scss";
import ParameterizedSearchIcon from "../../../components/VectorIcons/ParameterizedSearchIcon";

const ParameterizedSearchButton = (props) => {
  const { onClick } = props;

  return (
    <div id="ParameterizedSearchButton" onClick={onClick}>
      <ParameterizedSearchIcon />
      <span className="buttonText">Busca Parametrizada</span>
    </div>
  );
};

export default ParameterizedSearchButton;

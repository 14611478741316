import React from "react";
import CloseIcon from "../../VectorIcons/CloseIcon";

const ClearIndicator = ({ innerRef, innerProps }) => {
  return (
    <div className="clearIndicator" ref={innerRef} {...innerProps}>
      <CloseIcon color="#17A3D4" />
    </div>
  );
};

export default ClearIndicator;

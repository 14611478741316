const attachmentsAdapter = ({ attachments, onlyValid, onlyReleased }) => {
  let attachmentsArray = [];
  if (!attachments.length) return;

  attachments.forEach((attachment) => {
    attachmentsArray = [
      ...attachmentsArray,
      attachment.attachment,
      ...(Array.isArray(attachment.olderVersions) ? attachment.olderVersions : []),
    ];
  });

  if (onlyValid || onlyReleased)
    return filterAttachments({ attachmentsArray, onlyValid, onlyReleased });

  return attachmentsArray;
};

const filterAttachments = ({ attachmentsArray, onlyValid, onlyReleased }) => {
  let attachments = attachmentsArray;

  if (onlyValid) {
    attachments = attachmentsArray.filter((attachment) => !!attachment.fileId);
  }

  if (onlyReleased) {
    attachments = attachmentsArray.filter((attachment) => !!attachment.release);
  }

  return attachments;
};

export const extractAttachmentsFromResponse = ({
  attachmentsArray,
  onlyValid,
  onlyReleased,
}) => {
  let attachments = [];
  if (attachmentsArray?.length) {
    attachmentsArray.forEach((attachmentItem) => {
      const handleAdd = !!attachmentItem.attachments
        ? attachmentItem.attachments
        : [attachmentItem];
      attachments = [...attachments, ...handleAdd];
    });
  }

  return attachmentsAdapter({ attachments, onlyValid, onlyReleased });
};

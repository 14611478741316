import React from "react";
import "react-tippy/dist/tippy.css";
import "./styles/CustomTooltip.scss";
import { Tooltip } from "react-tippy";

const CustomTooltip = (props) => {
  const {
    tipLabel,
    dataTip,
    position = "top",
    className = "tipBalloon",
    theme = "dark",
  } = props;

  return (
    <div className="tooltip">
      <Tooltip
        position={position}
        trigger="mouseenter"
        className={className}
        animateFill={false}
        duration={150}
        arrow={true}
        html={dataTip}
        theme={theme}
      >
        {tipLabel}
      </Tooltip>
    </div>
  );
};

export default CustomTooltip;

import { useEffect, useContext } from "react";
import ReactGa from "react-ga";
import { useLocation } from "react-router-dom";
import UserContext from "../../context/userContext";
import { ANALYTICS_ID } from "../../shared/constants/env";

const GoogleAnalytics = (props) => {
  const { children } = props;

  const location = useLocation();

  const userContext = useContext(UserContext);
  const userId = userContext.me.user.id;

  useEffect(() => {
    if (!!userId) {
      ReactGa.initialize(ANALYTICS_ID, {
        debug: false,
        titleCase: false,
        gaOptions: {
          userId: userId,
          clientId: userId,
        },
      });
      ReactGa.pageview(location.pathname);
    }
  }, [location.pathname, userId]);

  return children;
};

export default GoogleAnalytics;

import React, { createContext, useEffect, useReducer } from "react";
import { getService, deleteServiceQString } from "../services/script-api";
import mapConflictReduce from "./mapConflictReduce";

const initialContext = {
  filter: "",
  filterAll: "",
  list: {
    loading: false,
    result: [],
  },
  listAll: {
    loading: false,
    result: [],
  },
};

const MapConflict = createContext();

const MapConflictProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mapConflictReduce, initialContext);

  useEffect(() => {
    if (state.filter !== "") {
      _getConflicts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filter]);

  useEffect(() => {
    if (state.filterAll !== "") {
      _getAllConflicts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filterAll]);

  const clearConflicts = () => {
    dispatch({ payload: [], key: "list", action: "list" });
    dispatch({ payload: [], key: "listAll", action: "list" });
  };

  const setFilter = (filter) =>
    dispatch({ payload: filter, key: "filter", action: "filter" });

  const setFilterAll = (filter) =>
    dispatch({ payload: filter, key: "filterAll", action: "filter" });

  const setRequestStartDate = (startDate) =>
    dispatch({ payload: startDate, key: "startDate", action: "date" });

  const setRequestEndDate = (endDate) =>
    dispatch({ payload: endDate, key: "endDate", action: "date" });

  const _getConflicts = () => get(`conflictmap`, "list", state.filter);

  const _getAllConflicts = () => get(`conflictmap`, "listAll", state.filterAll);

  const loading = (subject, active) => {
    dispatch({ action: "loading", payload: { subject, active } });
  };

  const get = async (path, key, filter) => {
    loading(key, true);
    await getService(path, filter)
      .then((result) => {
        dispatch({ payload: result, key: key, action: "list" });
        loading(key, false);
      })
      .catch((e) => {
        dispatch({
          key: key,
          action: "list",
          payload: { result: "", pagination: {} },
        });
        loading(key, false);
      });
  };

  const removeConflictTerm = async (data) => {
    let obj = {
      beginDate: data.beginDate,
      endDate: data.endDate,
      conflictType: data.conflictType,
    };

    await deleteServiceQString(`conflictmap/term/${data.termId}`, obj).then(
      (res) => {
        const itWasTheLastItemOnThePage = state.list.result.data.length === 1;
        const newPageNumber = itWasTheLastItemOnThePage
          ? state.list.result.pagingInfo.previousPage
          : state.list.result.pagingInfo.currentPage;

        setFilter({
          ...obj,
          limit: 12,
          pageNumber: newPageNumber ?? state.list.result.pagingInfo.currentPage,
          newMap: false,
        });

        setFilterAll({
          ...obj,
          limit: 12000,
          pageNumber: 1,
          newMap: false,
        });
      }
    );
  };

  const removeConflictCharacter = async (data) => {
    let obj = {
      beginDate: data.beginDate,
      endDate: data.endDate,
      conflictType: data.conflictType,
    };

    const path = `conflictmap/term/${data.termId}/item/${data.characterId}`;
    await deleteServiceQString(path, obj).then(() => {
      const itWasTheLastItemOnThePage = state.list.result.data.length === 1;
      const newPageNumber = itWasTheLastItemOnThePage
        ? state.list.result.pagingInfo.previousPage
        : state.list.result.pagingInfo.currentPage;
      setFilter({
        ...obj,
        limit: 12,
        pageNumber: newPageNumber ?? state.list.result.pagingInfo.currentPage,
        newMap: false,
      });

      // setFilterAll({
      //   ...obj,
      //   limit: 12000,
      //   pageNumber: 1,
      //   newMap: false,
      // });
    });
  };

  return (
    <MapConflict.Provider
      value={{
        ...state,
        setFilter,
        setFilterAll,
        setRequestStartDate,
        setRequestEndDate,
        removeConflictTerm,
        removeConflictCharacter,
        clearConflicts,
      }}
    >
      {children}
    </MapConflict.Provider>
  );
};

export { MapConflict as default, MapConflictProvider };

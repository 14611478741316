import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./styles/RegisterDramaturgyAndVarieties.scss";
import AppContext from "../../context/appContext";
import RegisterContext from "../../context/registerContext";
import ScriptBox from "../../components/ScriptBox/ScriptBox";
import { postService } from "../../services/script-api";
import RegisterSuccess from "../RegisterSuccess/RegisterSuccess";
import { sendEvent } from "../../services/GoogleAnalytics/methods";
import AddProductionCompanieIcon from "../../components/VectorIcons/AddProductionCompanieIcon";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import AttachmentsDramaturgyAndVarieties from "../../components/AttachmentsDramaturgyAndVarieties";
import TableLoading from "../../components/TableLoading/TableLoading";
import ContentHeaderRegister from "../RegisterDramaturgyAndVarieties/ContentHeaderRegister/ContentHeaderRegister";
import CreateAndEditProspectsAndCompanies from "../ProspectsAndProductions/CreateAndEditProspectsAndCompanies/CreateAndEditProspectsAndCompanies";

const RegisterDramaturgyAndVarieties = (props) => {
  const context = useContext(AppContext);
  const registerContext = useContext(RegisterContext);

  let currentType = props.match.params.type;

  let compareTypes = {
    dramaturgia: {
      endPoint: "dramaturgies",
      permission: "Dramaturgies",
      isValidComplementary: () => {},
    },
    variedades: {
      endPoint: "varieties",
      permission: "Varieties",
      isValidComplementary: () => {},
    },
  };

  const newDate = new Date();
  const currentDate = new Date(
    newDate.getTime() - newDate.getTimezoneOffset() * 60000
  ).toISOString();

  const initialState = {
    season: {
      currentSeasonNumber: 1,
    },
    receivedAt: currentDate,
    creators: [],
    attachments: [],
    productions: [],
  };

  const initialErrorFields = {
    title: false,
    receivedAt: false,
    createBy: false,
    origin: false,
    format: false,
    seasonNumber: false,
    originalProduct: false,
  };

  const [state, setState] = useState(initialState);
  const [stateCurrentStep, setStateCurrentStep] = useState(1);
  const [stateError] = useState(initialErrorFields);

  const resolveFormatData = () => {
    let filter = context.currentOriginFormat.result.filter(
      (item) => item.id === state.season?.formatId?.id
    );
    if (!!filter.length) return filter;
    return context.currentOriginFormat.result;
  };

  const getDramaturgy = (key, value) => setState({ ...state, [key]: value });

  const getSeason = (season) => {
    setState((prevState) => ({
      ...prevState,
      season: season,
    }));
  };

  const formatCreators = (item) => {
    let creators = [];
    item.forEach((el) => {
      creators.push({
        creatorId: el.value,
        origin: el.origin,
      });
    });
    return creators;
  };

  const getAssociatedProjects = (associatedProjects) =>
    setState({ ...state, associatedProjects });
  const getResearches = (associatedResearches) =>
    setState({ ...state, associatedResearches });
  const getAttachments = (attachments) => setState({ ...state, attachments });
  const getCreators = (creators) =>
    setState({ ...state, creators: formatCreators(creators) });
  const getProductions = (productions) => setState({ ...state, productions });
  const getOrigin = (origins) => setState({ ...state, origins });
  const getClients = (clients) => setState({ ...state, clients });
  const getFormat = (format) => {
    setState((prevState) => {
      return {
        ...prevState,
        format: format,
      };
    });
  };
  const getSchedule = (schedule) => setState({ ...state, schedule });
  const getProgramFrequency = (programFrequency) => {
    setState({ ...state, programFrequency });
  };

  const validAttachmentTypes = context?.currentOriginFileType?.result?.filter(
    (item) => item.name !== "Capítulo/Episódio"
  );

  const postDramaturgy = () => {
    let sendObj = {
      productId: state.season?.productId,
      seasonNumber: state.season?.currentStateSeasonNumber,
      associations: {
        projects: state.associatedProjects,
        researchesIds: state.associatedResearches,
      },
      attachments: state.attachments,
      receivedAt: state.receivedAt,
      creators: state.creators,
      chaptersOrEpisodes: state.chapter,
      formatId: state.format?.id,
      origins: state.origins,
      channel: state.clients,
      productionCompanies: state.productions,
      soapOperaSchedule: state.schedule,
      programFrequency: state.programFrequency,
      title: state.title,
    };

    context.setShowGlassLoading(true);

    postService(compareTypes[currentType].endPoint, sendObj)
      .then((res) => {
        context.sendMessage(
          "Projeto de Dramaturgia cadastrado com sucesso!",
          "success"
        );
        setState({
          ...state,
          id: res.data,
        });
        setStateCurrentStep(4);
        context.setShowGlassLoading(false);
        sendEvent("Cadastro", "Projeto de Dramaturgia");
      })
      .catch((e) => {
        let msg = "";
        if (e.response) {
          e.response.data.Validations.forEach((error) => {
            msg += `<p>${error.Message} ${error.Property}</p>`;
          });

          context.sendMessage(msg, "error");
        }

        context.setShowGlassLoading(false);
      });
  };

  useEffect(() => {
    context.getTypeThesaurus("22", compareTypes[currentType].endPoint);
    context.getTypeThesaurus("21", compareTypes[currentType].endPoint);
    context.getTypeThesaurus("1", compareTypes[currentType].endPoint);
    registerContext._getSoap();
    registerContext._getProgramFrequency();
    registerContext._getOrigiins();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [stateCurrentStep]);

  if (stateCurrentStep === 4) {
    return (
      <RegisterSuccess
        id={state.id}
        title={state.title}
        type={props.match.params.type}
      />
    );
  }

  if (
    !!context.currentOriginFormat.loading ||
    !!context.currentOriginFileType.loading ||
    !!registerContext.listFrequencies.loading ||
    !!registerContext.listOrigins.loading ||
    !!registerContext.listSoap.loading
  ) {
    return <TableLoading />;
  }

  return (
    <div id="DramaturgyProject">
      <Helmet>
        <title>
          Script - Cadastro de Projeto de{" "}
          {currentType.charAt(0).toUpperCase() + currentType.slice(1)}
        </title>
      </Helmet>
      <ContentHeaderRegister
        title={state.title}
        type={currentType}
        setCurrentStep={setStateCurrentStep}
        currentStep={stateCurrentStep}
      />

      <section className="registerContainer">
        <form className="registerForm">
          <div style={{ display: stateCurrentStep === 1 ? "block" : "none" }}>
            <Step1
              setStateCurrentStep={setStateCurrentStep}
              getCreators={getCreators}
              getProducts={getProductions}
              getDramaturgy={getDramaturgy}
              getOrigin={getOrigin}
              getSeason={getSeason}
              getFormat={getFormat}
              getClients={getClients}
              type={compareTypes[currentType].endPoint}
            />
          </div>

          <div style={{ display: stateCurrentStep === 2 ? "block" : "none" }}>
            <Step2
              formatOptions={resolveFormatData()}
              stateError={stateError}
              msgError="Este campo é obrigatório"
              endpoint="dramaturgies"
              selected={
                !!state.season?.formatId
                  ? parseInt(state.season.formatId.id)
                  : state.format?.id
              }
              title={state.title}
              getAssociatedProjects={getAssociatedProjects}
              getResearches={getResearches}
              getFormat={getFormat}
              getSchedule={getSchedule}
              getProgramFrequency={getProgramFrequency}
              getDramaturgy={getDramaturgy}
              setStateCurrentStep={setStateCurrentStep}
              format={state.format ? state.format : null}
            />
          </div>

          <div style={{ display: stateCurrentStep === 3 ? "block" : "none" }}>
            <AttachmentsDramaturgyAndVarieties
              thesaurus={validAttachmentTypes}
              send={postDramaturgy}
              getAttachments={getAttachments}
              stateAttachments={state.attachments || []}
            />
          </div>
        </form>

        {context.current.step === "newProductionCompanies" && (
          <ScriptBox
            open={context.current.step === "newProductionCompanies"}
            title="Nova Produtora"
            icon={<AddProductionCompanieIcon color="#FFF" />}
            marginBottom={true}
          >
            <CreateAndEditProspectsAndCompanies
              closed={() => context.setCurrentStepBox("")}
              path="productioncompanies"
              name="Produtora"
              type="productioncompanies"
            />
          </ScriptBox>
        )}
      </section>
    </div>
  );
};

export default RegisterDramaturgyAndVarieties;

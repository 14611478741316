export const compileOptions = (listItems) => {
  const identifyList = !!listItems?.blocks?.length
  ? listItems?.blocks
  : listItems;

  if (!!listItems?.blocks?.length) {
    return identifyList
      ?.map((block) =>
        block?.attachments?.map((item) => ({
          id: item?.attachment?.id,
          value: `Bloco ${block?.blockNumber} - Capítulo ${item?.attachment?.chapterNumber}`,
          block: block?.blockNumber,
          chaption: item?.attachment?.chapterNumber,
          version: item?.attachment?.version,
          numberChaptersBlock: block?.numberChaptersBlock,
          rescheduledDelivery: block?.rescheduledDelivery,
          rescheduledRelease: block?.rescheduledRelease,
          scheduleDelivery: block?.scheduleDelivery,
          scheduleRelease: block?.scheduleRelease,
        }))
      )
      ?.flat();
  } else {
    return identifyList?.episodes
      ?.map((episode) =>
        episode?.attachments?.map((item) => ({
          id: item?.attachment?.id,
          value: `Episodio ${item?.attachment?.chapterNumber}`,
          chaption: item?.attachment?.chapterNumber,
          version: item?.attachment?.version,
          numberAttachmentsEpisodes: episode?.numberAttachmentsEpisodes,
          rescheduledDelivery: episode?.rescheduledDelivery,
          rescheduledRelease: episode?.rescheduledRelease,
          scheduleDelivery: episode?.scheduleDelivery,
          scheduleRelease: episode?.scheduleRelease,
        }))
      )
      ?.flat();
  }
}
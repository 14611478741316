import React, { createContext, useContext, useEffect, useReducer } from "react";
import activitiesReduce from "./activitiesReduce.js";
import UserContext from "./userContext";

const initialContext = {
  currentType: "Desenv. Dram. Curta",
};

const Activities = createContext();

const ActivitiesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(activitiesReduce, initialContext);

  const userContext = useContext(UserContext);

  const makeSlugType = (type) => {
    let types = {
      "Desenv. Dram. Curta": {
        label: "desenv-dram-curta",
        type: "Dramaturgia",
      },
      "Desenv. Dram. Longa": {
        label: "desenv-dram-longa",
        type: "Dramaturgia",
      },
      "Desenv. Variedades": { label: "desenv-variedades", type: "Variedades" },
      Governança: { label: "governanca", type: "" },
      "Acomp. Dram.": { label: "acomp-dram", type: "Dramaturgia" },
      Pesquisas: { label: "pesquisas", type: "Pesquisa" },
      "Acomp. Variedades": { label: "acomp-variedades", type: "Variedades" },
    };

    return types[type];
  };

  const remakeSlugType = (type) => {
    let types = {
      "desenv-dram-curta": {
        label: "Desenv. Dram. Curta",
        type: "Dramaturgia",
      },
      "desenv-dram-longa": {
        label: "Desenv. Dram. Longa",
        type: "Dramaturgia",
      },
      "desenv-variedades": { label: "Desenv. Variedades", type: "Variedades" },
      governanca: { label: "Governança", type: "" },
      "acomp-dram": { label: "Acomp. Dram.", type: "Dramaturgia" },
      "acomp-dram.": { label: "Acomp. Dram.", type: "Dramaturgia" },
      pesquisas: { label: "Pesquisas", type: "Pesquisa" },
      "acomp-variedades": { label: "Acomp. Variedades", type: "Variedades" },
    };

    return types[type];
  };

  const makeTypeEvaluations = (type) => {
    const types = {
      "relatorio-de-video": "Relatório de Vídeo",
      "relatorio-de-texto": "Relatório de Texto",
      certificacao: "Certificação",
      "parecer-da-criacao": "Parecer da Criação",
    };

    return types[type];
  };

  const remakeTypeEvaluations = (type) => {
    const types = {
      "Relatório de Vídeo": "relatorio-de-video",
      "Relatório de Texto": "relatorio-de-texto",
      Certificação: "certificacao",
      Parecer: "parecer-da-criacao",
    };

    return types[type];
  };

  const setCurrentType = (type) => {
    dispatch({ action: "set", key: "set", payload: type });
  };

  const setTypesCurrent = (t) => {
    const _types = {
      1: "Desenv. Dram. Curta",
      2: "Desenv. Dram. Longa",
      3: "Desenv. Variedades",
      4: "Governança",
      5: "Acomp. Dram.",
      6: "Pesquisas",
      7: "Acomp. Variedades",
    };

    return _types[t];
  };

  const activitiesContentTypeNames = {
    Dramaturgia: "dramaturgia",
    Variedades: "variedades",
    Pesquisa: "Pesquisa",
    "Obra Literária": "obra-literaria",
  };

  useEffect(() => {
    if (!!userContext.me.user?.activityGroups?.length) {
      setCurrentType(setTypesCurrent(userContext.me.user.activityGroups[0]));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [userContext]);

  return (
    <Activities.Provider
      value={{
        ...state,
        remakeSlugType,
        makeSlugType,
        makeTypeEvaluations,
        remakeTypeEvaluations,
        setCurrentType,
        activitiesContentTypeNames,
      }}
    >
      {children}
    </Activities.Provider>
  );
};

export { Activities as default, ActivitiesProvider };

import React from "react";

const FeedbackIconDone = (props) => {
  const { size = 26 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 26 27"
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#999BE0"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 20L3 14 7 14 8 20z"
        />
        <path
          fill="#999BE0"
          fillRule="nonzero"
          d="M18 1h1c0-.359-.192-.69-.504-.868-.311-.178-.694-.176-1.003.006L18 1zM9.5 6v1c.178 0 .353-.048.507-.138L9.5 6zM1 6V5c-.552 0-1 .448-1 1h1zm0 8H0c0 .552.448 1 1 1v-1zm8.5 0l.507-.862C9.853 13.048 9.678 13 9.5 13v1zm8.5 5l-.507.862c.309.182.692.184 1.003.006.312-.178.504-.509.504-.868h-1zM17.493.138l-8.5 5 1.014 1.724 8.5-5L17.493.138zM9.5 5H1v2h8.5V5zM0 6v8h2V6H0zm1 9h8.5v-2H1v2zm7.993-.138l8.5 5 1.014-1.724-8.5-5-1.014 1.724zM19 19V1h-2v18h2z"
        />
        <path stroke="#999BE0" strokeWidth="2" d="M9 6L9 14" />
        <path
          stroke="#999BE0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M21.54 6.46c.937.938 1.464 2.209 1.464 3.535s-.527 2.597-1.464 3.535"
        />
        <g transform="translate(11 12)">
          <circle cx="7.5" cy="7.5" r="7.5" fill="#7070B3" fillRule="nonzero" />
          <path
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M11.333 5L6.667 9.673 4.667 7.673"
          />
        </g>
      </g>
    </svg>
  );
};

export default FeedbackIconDone;

import React from "react";
import "./styles/TitleHistory.scss";
import HistoryItem from "./HistoryItem/HistoryItem";

const TitleHistory = (props) => {
  const { listHistory } = props;

  return (
    <div className="titleHistory">
      <div className="historyHeader">
        <span className="title">Título</span>
        <span className="updatedBy">Alterado por</span>
      </div>

      <ul className="historyList">
        {listHistory.map((item, index) => {
          return (
            <HistoryItem
              key={index}
              title={item.title}
              updatedBy={item.user}
              updatedAt={item.date}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default TitleHistory;

import React, { useEffect, useState } from "react";
import FieldsetSelect from "../../../../components/FieldsetSelect/FieldsetSelect";
import { getService } from "../../../../services/script-api";
import Select from "react-select";
import { isArray } from "lodash";
import SmallLoading from "../../../../components/SmallLoading/SmallLoading";
import _ from "lodash";
import "./styles/VarietiesParameters.scss";

const VarietiesParameters = (props) => {
  const { values, getStateComplementaries } = props;

  let initialState = {
    genres: [],
    subGenres: [],
    plot: [],
    format: [],
    tags: [],
  };

  const [stateOptions, setStateOptions] = useState({
    loading: false,
    data: {},
  });
  const [selected, setSelected] = useState({});
  const [complementaries, setComplementaries] = useState(initialState);
  const [currentSubGenres, setCurrentSubGenres] = useState([]);
  const [fullComplementaries, setFullComplementaries] = useState(values || {});

  useEffect(() => {
    getThesaurus(15, "genres");
    getThesaurus(19, "subGenres");
    getThesaurus(20, "tags");
    getThesaurus(22, "format");
    getProgramFrequency();
    // appContext.getTypeThesaurus(2, 'dramaturgies')
    // eslint-disable-next-line
  }, []);

  const getThesaurus = (id, key, origin) => {
    setStateOptions({
      ...stateOptions,
      loading: true,
    });
    getService(`varieties/thesaurus?origins=${id}`).then((list) => {
      let newState = { ...stateOptions };
      const _list = list.data.map((item) => ({
        value: item.id,
        label: item.name,
        origin: origin,
        ...item,
      }));

      newState.data[key] = _list;

      setStateOptions({
        ...stateOptions,
        loading: false,
        ...newState,
      });
    });
  };

  const getProgramFrequency = () => {
    getService("varieties/programfrequencies").then((list) => {
      let newState = { ...stateOptions };
      const _list = list.data.map((item) => ({
        value: item.id,
        label: item.value,
        name: item.value,
        ...item,
      }));

      newState.data.programFrequency = _list;

      setStateOptions({
        ...stateOptions,
        loading: false,
        ...newState,
      });
    });
  };

  const filterIdTypes = (item) => {
    let a = [];
    if (!!item && isArray(item)) {
      item.forEach((e) => a.push(e.value));
      return a;
    }
    return item || a;
  };

  const populateComplementaries = (value, type) => {
    setComplementaries({
      ...complementaries,
      [type]: filterIdTypes(value),
    });
  };

  const onChange = (value, type, event) => {
    const handleValue = value !== "placeholder" ? value : "";

    let a = {};

    if (type === "genres" && value === null) {
      a["subGenres"] = [];
      populateComplementaries([], "subGenres");
      setCurrentSubGenres([]);
    } else {
      populateComplementaries(handleValue, type);
    }

    setSelected({
      ...selected,
      [type]: handleValue,
    });

    mountComplementaries(handleValue, type, event);
  };

  const translateFormat = (type) =>
    type === "FormatId" ? "Formato" : "Periodicidade";

  const mountComplementaries = (value, type, event) => {
    let full = { ...fullComplementaries };

    full[type] = value;

    if (type === "FormatId" || type === "ProgramFrequency") {
      const id = event.nativeEvent.target.selectedIndex;
      const idText = event.nativeEvent.target[id].text;

      full[type] = {
        label: idText,
        value: [value],
        field: translateFormat(type),
      };
    }

    if (!value) delete full[type];

    setFullComplementaries(full);
  };

  const filterIds = (item) => {
    let list = [];

    if (!!item && _.isArray(item)) {
      item.forEach((b) => list.push(b.value));
    }

    return !!list.length ? list : item;
  };

  const filterIdComplementaries = (item) => {
    let a = {};
    if (!_.isEmpty(item)) {
      Object.entries(item).forEach((value) => {
        a[value[0]] = filterIds(value[1]);
      });

      setComplementaries({
        ...complementaries,
        ...a,
      });

      getStateComplementaries(mountObj());
    }
  };

  const filterSubGenres = () => {
    if (stateOptions?.data?.subGenres && stateOptions?.data?.subGenres.length) {
      let subGenres = stateOptions?.data?.subGenres.filter((e) =>
        complementaries.genres.includes(e.parentId)
      );
      let s = subGenres.filter((e) => complementaries.subGenres.includes(e.id));
      setCurrentSubGenres(s);
    }
  };

  const checkHasSubGenres = () => {
    if (!!selected.genres?.length)
      return selected.genres.some((item) => !!item.children.length);
  };

  const mountObj = () => {
    let list = {
      Complementaries: [
        ...complementaries.genres,
        ...complementaries.subGenres,
        ...complementaries.plot,
        ...complementaries.tags,
      ],
      FormatId: complementaries.FormatId,
      CharacterIdentifications: complementaries.character,
      ProgramFrequency: complementaries.ProgramFrequency,
      listComplementaries: fullComplementaries,
    };
    return list;
  };

  useEffect(() => {
    filterIdComplementaries(values, "values");
    setSelected({ ...selected, ...values });
    if (!!values?.subGenres)
      setCurrentSubGenres(values.subGenres || selected.subGenres);
    // eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    getStateComplementaries(mountObj());
    // eslint-disable-next-line
  }, [complementaries]);

  useEffect(() => {
    if (!!complementaries.genres.length) {
      filterSubGenres();
    }
    // eslint-disable-next-line
  }, [complementaries.genres, complementaries.subGenres]);

  return (
    <div id="VarietiesParameters">
      {stateOptions.loading ? (
        <div className="loadingContainer">
          <SmallLoading />
        </div>
      ) : (
        <div className="parametersSection">
          <div className="parameter">
            <FieldsetSelect
              data={stateOptions.data?.format || []}
              label="Formato"
              placeholder="Selecione um formato"
              name="FormatId"
              handleChange={(ev) => onChange(ev.target.value, "FormatId", ev)}
              selected={selected?.FormatId?.value || ""}
            />

            {selected?.FormatId?.label === "Programa" && (
              <FieldsetSelect
                data={stateOptions.data?.programFrequency || []}
                label="Periodicidade"
                placeholder="Selecione"
                name="ProgramFrequency"
                handleChange={(ev) =>
                  onChange(ev.target.value, "ProgramFrequency", ev)
                }
                selected={selected?.ProgramFrequency?.value || ""}
              />
            )}
          </div>

          {!stateOptions.loading && (
            <div className="parameter">
              <label className="labelParameter">Gênero</label>
              <Select
                className="reactSelectDramaturgyParameters"
                classNamePrefix="reactSelect"
                options={stateOptions.data.genres}
                value={selected?.genres}
                onChange={(ev) => onChange(ev, "genres")}
                placeholder="Digite um nome e aperte ENTER"
                noOptionsMessage={() => "Nenhum item encontrado"}
                loadingMessage={() => "Carregando..."}
                isMulti
              />
            </div>
          )}

          {checkHasSubGenres() && (
            <div className="parameter">
              <label className="labelParameter">Subgêneros</label>
              <Select
                className="reactSelectDramaturgyParameters"
                classNamePrefix="reactSelect"
                options={
                  !!complementaries.genres.length &&
                  !!stateOptions?.data?.subGenres
                    ? stateOptions?.data?.subGenres.filter((e) =>
                        complementaries.genres.includes(e.parentId)
                      )
                    : stateOptions.data.subGenres
                }
                value={currentSubGenres}
                onChange={(ev) => onChange(ev, "subGenres")}
                placeholder="Digite um nome e aperte ENTER"
                noOptionsMessage={() => "Nenhum item encontrado"}
                loadingMessage={() => "Carregando..."}
                isMulti
              />
            </div>
          )}

          <div className="parameter">
            <label className="labelParameter">Tags</label>
            <Select
              className="reactSelectDramaturgyParameters"
              classNamePrefix="reactSelect"
              options={stateOptions.data.tags}
              value={selected?.tags}
              onChange={(ev) => onChange(ev, "tags")}
              placeholder="Digite um nome e aperte ENTER"
              noOptionsMessage={() => "Nenhum item encontrado"}
              loadingMessage={() => "Carregando..."}
              isMulti
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VarietiesParameters;

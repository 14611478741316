import React, { useContext, useState } from "react";
import "./styles/ListItem.scss";
import TypeIconReturner from "../../../components/TypeIconReturner/TypeIconReturner";
import TagStatus from "../../../components/TagStatus/TagStatus";
import { Link } from "react-router-dom";
import AppContext from "../../../context/appContext";
import { calculateDifferenceInDays } from "../../../shared/utils/calculateDifferenceInDays";
import Highlighter from "react-highlight-words";
import * as latinize from "latinize";
import ScriptBoxCaller from "../../../components/ScriptBoxCaller/ScriptBoxCaller";
import DetailsModal from "../../NewSearch/SearchResults/DetailsModal/DetailsModal";

const ListItem = (props) => {
  const { channels, searchTerm } = props;

  const [showMoreInfo, setShowMoreInfo] = useState({
    open: false,
    currentItem: null,
  });

  const appContext = useContext(AppContext);

  const handleChangeMoreInfo = ({ open, item }) => {
    setShowMoreInfo({
      open: open,
      currentItem: item,
    });
  };

  const returnDays = (days) => (days + 1 > 0 ? `${days + 1} dias` : "-");

  return (
    <>
      {channels.map((channel, key) => {
        return (
          <ul className="ListItem" key={key}>
            <li className="title">
              <div className="iconType">
                <TypeIconReturner
                  type={
                    channel.type?.description?.toLowerCase() || channel.type
                  }
                  showBackgroundColor={false}
                  responsiveIcon={true}
                />
              </div>

              <div className="contentText">
                <div className="title">
                  <div className="titleContent">
                    <Link
                      to={`/ficha-projeto/${
                        channel.type?.description?.toLowerCase() ||
                        channel.type?.toLowerCase()
                      }/${channel.id}`}
                    >
                      <Highlighter
                        highlightClassName="markMatch"
                        searchWords={appContext.checkPronouns(searchTerm)}
                        autoEscape={true}
                        textToHighlight={channel.title}
                        sanitize={latinize}
                      />
                      &nbsp; | &nbsp;{" "}
                    </Link>

                    <div className="creator">
                      {channel.creators.map((creator, index) => {
                        return (
                          <div key={creator.name}>
                            <span>{creator.name}</span>
                            {index + 1 < channel.creators.length && (
                              <span className="creatorSeparator">|</span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="storylineContent">
                  <p className="storyline">
                    {channel.storyline && `${channel.storyline}`}
                  </p>
                  {channel.storyline?.length > 70 && "..."}
                  {channel.storyline?.length > 70 && (
                    <ScriptBoxCaller
                      callerText="mais"
                      event={() =>
                        handleChangeMoreInfo({ open: true, item: channel })
                      }
                    />
                  )}
                </div>
              </div>
            </li>

            <li className="date">
              {appContext.formatDate(
                appContext.convertDatePtBr(channel.receivedAt)
              )}
            </li>

            <li className="status">
              <TagStatus
                name={channel.projectStatus?.description}
                id={channel.projectStatus?.id}
              />
            </li>

            <li
              className={`${
                returnDays(calculateDifferenceInDays(channel.deadline)) < 0
                  ? "red"
                  : ""
              } analise`}
            >
              {returnDays(calculateDifferenceInDays(channel.deadline))}
            </li>
          </ul>
        );
      })}

      {showMoreInfo.open && (
        <DetailsModal
          details={showMoreInfo}
          close={() => handleChangeMoreInfo({ open: false, item: null })}
        />
      )}
    </>
  );
};

export default ListItem;

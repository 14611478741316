import React from "react";
import "./styles/EpisodesHeader.scss";

const EpisodesHeader = () => {
  const dateFields = [
    "Entrega",
    "Parecer conteúdo",
    "Parecer Produção",
    "Aprov. Dir. Gênero",
    "Liberação",
    "Notes do Canal",
  ];

  return (
    <div className="episodesHeader">
      <div className="datesHeaderRow">
        {dateFields.map((field, index) => (
          <div className="dateHeaderCell" key={index}>
            <span className="dateType">{field.toUpperCase()}</span>
            <div className="datesNames">
              <span className="dateName">PLANEJADO</span>
              <span className="dateName">ENTREGUE</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EpisodesHeader;

import React from "react";
import { PulseLoader } from "react-spinners";

const SmallLoading = (props) => {
  const { size = 12 } = props;

  return (
    <div id="smallLoading">
      <PulseLoader
        sizeUnit={"px"}
        size={size}
        color={"#7070B3"}
        loading={true}
      />
    </div>
  );
};

export default SmallLoading;

import React from "react";
import "./styles/RoleIcon.scss";

const RoleIcon = (props) => {
  const { backgroundColor } = props;

  return (
    <div className="roleIcon" style={{ backgroundColor: backgroundColor }}>
      {props.children}
    </div>
  );
};

export default RoleIcon;

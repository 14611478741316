import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import "./styles/Research.scss";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import RoundBackButton from "../../components/RoundBackButton/RounbBackButton";
import StepCounter from "../../components/StepCounter/StepCounter";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import { getService } from "../../services/script-api";
import _ from "lodash";
import AppContext from "../../context/appContext";
import { postService } from "../../services/script-api";
import RegisterSuccess from "../RegisterSuccess/RegisterSuccess";
import { sendEvent } from "../../services/GoogleAnalytics/methods";

const Research = () => {
  const appContext = useContext(AppContext);
  const [stateResearchId, setStateResearchId] = useState("");
  const [stateCurrentStep, setStateCurrentStep] = useState(1);
  const [stateMaxSteps, setStateMaxSteps] = useState(3);
  const [stateResearchTypes, setStateReseachTypes] = useState([]);
  const [stateResearchType, setStateResearchType] = useState(null);
  const [stateResearchTitle, setStateResearchTitle] = useState("");
  const [stateResearchCategory, setStateResearchCategory] = useState({
    id: 1,
    value: "Produtos",
  });
  const [stateResearchCategories, setStateResearchCategories] = useState("");
  const [stateResearchSubCategory, setStateResearchSubCategory] = useState(0);
  const [stateResearchSubCategories, setStateResearchSubCategories] =
    useState("");
  const [stateAreaThesaurus, setStateAreaThesaurus] = useState([]);
  const [stateOriginsOptions, setStateOriginsOptions] = useState([]);
  const [stateOrigin, setStateOrigin] = useState({ id: 0, value: null });
  const [statePeople, setStatePeople] = useState(null);
  const [stateAssociatedProjects, setStateAssociatedProjects] = useState([]);
  const [stateAssociatedResearches, setStateAssociatedResearches] = useState(
    []
  );
  const [stateAttachments, setStateAttachments] = useState([]);
  useEffect(() => {
    getTypes();
    getOrigins();
    getCategoriesAndSubCategories();
    getThesaurus(23, "researches/thesaurus", setStateAreaThesaurus);
  }, []);
  useEffect(() => {
    setStateResearchType(stateResearchTypes[0]);
  }, [stateResearchTypes]);
  const checkLoading = () => {
    const getIsDone =
      !!stateResearchTypes.length &&
      !!stateOriginsOptions.length &&
      !!stateResearchCategories.length &&
      !!stateAreaThesaurus.length;
    appContext.setShowGlassLoading(!getIsDone);
  };
  useEffect(() => {
    checkLoading();
    // eslint-disable-next-line
  }, [
    stateResearchTypes,
    stateOriginsOptions,
    stateResearchCategories,
    stateAreaThesaurus,
  ]);
  const getThesaurus = (id, url, setter) => {
    getService(`${url}?origins=${id}`).then((list) => {
      const _list = list.data.map((item) => ({
        value: item.id,
        label: item.name,
        origin: origin,
      }));
      setter(_list);
    });
  };
  const getTypes = () => {
    getService("researches/data/types").then((response) => {
      setStateReseachTypes(response.data);
    });
  };
  const getOrigins = () => {
    getService("researches/data/origins").then((response) => {
      setStateOriginsOptions(response.data);
    });
  };
  const getCategoriesAndSubCategories = () => {
    getService("researches/data/subcategories").then((response) => {
      const grouped = Object.entries(
        _.groupBy(response.data, (u) => u.category.id)
      );
      let categories = [];
      let subCategories = [];
      grouped.forEach((group) => categories.push(group[1][0].category));
      grouped.forEach((group) => {
        subCategories.push(group[1]);
      });
      setStateResearchCategories(categories);
      setStateResearchSubCategories(subCategories);
    });
  };
  const compilePeoples = (key) => {
    const compileToArray = [];
    const peoples = {
      1: "fromAnalystIds",
      2: "fromAreaIds",
      3: "fromResearcherIds",
      4: "toCreatorIds",
      5: "toAreaIds",
    };
    const listsObj = {
      fromAnalystIds: [],
      fromAreaIds: [],
      fromResearcherIds: [],
      toCreatorIds: [],
      toAreaIds: [],
    };
    statePeople.forEach((item, index) => {
      !!item.peopleId.length &&
        item.peopleId.forEach((people) =>
          compileToArray.push({
            peopleId: people.value,
            type: statePeople[index].type,
          })
        );
    });
    compileToArray.forEach((item) =>
      listsObj[peoples[item.type]].push(item.peopleId)
    );
    return listsObj[key];
  };
  const getResearchType = (type) => setStateResearchType(type);
  const getResearchTitle = (title) => setStateResearchTitle(title);
  const getResearchCategory = (category) => setStateResearchCategory(category);
  const getResearchSubCategory = (subCategory) =>
    setStateResearchSubCategory(subCategory);
  const getOrigin = (origin) => setStateOrigin(origin);
  const getPeople = (people) => setStatePeople(people);
  const getAttachments = (attachments) => setStateAttachments(attachments);
  const getAssociatedProjects = (associatedProjects) =>
    setStateAssociatedProjects(associatedProjects);
  const getAssociatedResearches = (associatedResearches) =>
    setStateAssociatedResearches(associatedResearches);
  const clearStep2 = () => {
    setStateOrigin({ id: 0, value: null });
    setStatePeople([]);
    setStateAssociatedProjects([]);
    setStateAssociatedResearches([]);
  };
  const isPeopleIds = (people) =>
    !!compilePeoples(people).length ? compilePeoples(people) : [];
  const postResearch = () => {
    const postObject = {
      associations: {
        projects: !!stateAssociatedProjects.length
          ? [
              {
                typeAssociationId: null,
                contentsId: stateAssociatedProjects,
              },
            ]
          : [],
        researchesIds: stateAssociatedResearches,
      },
      attachments: stateAttachments,
      type: stateResearchType.id,
      subCategoryId: stateResearchSubCategory.id,
      origin: stateOrigin.id,
      fromAnalystIds: isPeopleIds("fromAnalystIds"),
      fromAreaIds: isPeopleIds("fromAreaIds"),
      fromResearcherIds: isPeopleIds("fromResearcherIds"),
      toCreatorIds: isPeopleIds("toCreatorIds"),
      toAreaIds: isPeopleIds("toAreaIds"),
      title: stateResearchTitle,
    };
    appContext.setShowGlassLoading(true);
    postService("researches", postObject)
      .then((res) => {
        appContext.sendMessage("Pesquisa cadastrada com sucesso!", "success");
        setStateResearchId(res.data);
        setStateCurrentStep(4);
        appContext.setShowGlassLoading(false);
        sendEvent("Cadastro", "Pesquisa");
      })
      .catch((e) => {
        let msg = "";
        if (e.response) {
          e.response.data.Validations.forEach((error) => {
            msg += `<p>${error.Message} ${error.Property}</p>`;
          });
          appContext.sendMessage(msg, "error");
        }
        appContext.setShowGlassLoading(false);
      });
  };
  if (stateCurrentStep === 4) {
    return (
      <RegisterSuccess
        id={stateResearchId}
        title={stateResearchTitle}
        type="pesquisa"
      />
    );
  }
  return (
    <div id="Research">
      <Helmet>
        <title>Script - Cadastro de Pesquisa</title>
      </Helmet>
      <Breadcrumb>
        <div className="registerContainer">
          <div className="infoContainer">
            {stateCurrentStep > 1 && (
              <div
                className="backButtonContainer"
                onClick={() => setStateCurrentStep(stateCurrentStep - 1)}
              >
                <RoundBackButton color="#17B4E1" backgroundColor="#24233D" />
              </div>
            )}
            <div className="registerTitle">
              <h2>
                {stateCurrentStep > 1
                  ? `${stateResearchTitle}`
                  : "Cadastrar: Pesquisa"}
              </h2>
              {stateCurrentStep > 1 && (
                <div>
                  <span>{stateResearchType.value}</span>
                  <div className="separator" />
                  <span>{stateResearchCategory.value}</span>
                  <div className="separator" />
                  <span>{stateResearchSubCategory.value}</span>
                </div>
              )}
            </div>
          </div>
          <StepCounter currentStep={stateCurrentStep} maxStep={stateMaxSteps} />
        </div>
      </Breadcrumb>
      <div className="researchContainer">
        <form className="researchForm">
          <div style={{ display: stateCurrentStep === 1 ? "block" : "none" }}>
            <Step1
              currentStep={stateCurrentStep}
              setStateCurrentStep={setStateCurrentStep}
              maxSteps={stateMaxSteps}
              setStateMaxSteps={setStateMaxSteps}
              getResearchType={getResearchType}
              getResearchTitle={getResearchTitle}
              getResearchCategory={getResearchCategory}
              getResearchSubCategory={getResearchSubCategory}
              researchTypes={stateResearchTypes}
              categories={stateResearchCategories}
              subCategories={stateResearchSubCategories}
              getOrigin={getOrigin}
              originsOptions={stateOriginsOptions}
              clearStep2={clearStep2}
              postResearch={postResearch}
            />
          </div>
          <div
            style={{
              display:
                stateResearchCategory.value === "Publicação" &&
                stateCurrentStep === 2
                  ? "none"
                  : stateCurrentStep === 2
                  ? "block"
                  : "none",
            }}
          >
            <Step2
              currentStep={stateCurrentStep}
              setStateCurrentStep={setStateCurrentStep}
              getOrigin={getOrigin}
              getPeople={getPeople}
              areaThesaurus={stateAreaThesaurus}
              getAssociatedProjects={getAssociatedProjects}
              getAssociatedResearches={getAssociatedResearches}
              originsOptions={stateOriginsOptions}
              researchCategory={stateResearchCategory}
              origin={stateOrigin}
              postResearch={postResearch}
            />
          </div>
          <div
            style={{
              display:
                stateResearchCategory.value === "Publicação" &&
                stateCurrentStep === 2
                  ? "block"
                  : stateCurrentStep === 3
                  ? "block"
                  : "none",
            }}
          >
            <Step3
              stateAttachments={stateAttachments}
              getAttachments={getAttachments}
              postResearch={postResearch}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Research;

import React from "react";

const NewActivityIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C10.6934 1 9.58301 1.83496 9.1709 3H5C3.89551 3 3 3.89551 3 5V19C3 19.7041 3.36426 20.3232 3.91406 20.6797C4.1123 20.8086 4.33398 20.9023 4.57129 20.9541C4.70898 20.9844 4.85254 21 5 21H13.5C14.0527 21 14.5 20.5527 14.5 20C14.5 19.7803 14.4297 19.5771 14.3096 19.4121C14.1279 19.1621 13.833 19 13.5 19H5V5H19V13.5C19 14.0527 19.4473 14.5 20 14.5C20.2793 14.5 20.5312 14.3857 20.7129 14.2021C20.8906 14.0215 21 13.7734 21 13.5V5C21 3.89551 20.1045 3 19 3H14.8291C14.417 1.83496 13.3066 1 12 1ZM12.75 3.5C12.75 3.91406 12.4141 4.25 12 4.25C11.5859 4.25 11.25 3.91406 11.25 3.5C11.25 3.28125 11.3438 3.08398 11.4932 2.94629C11.627 2.82422 11.8047 2.75 12 2.75C12.1797 2.75 12.3457 2.81348 12.4746 2.91895C12.6426 3.05664 12.75 3.26562 12.75 3.5Z"
        fill="#fff"
      ></path>
      <path
        d="M8 9C7.44727 9 7 8.55273 7 8C7 7.95215 7.00293 7.90527 7.00977 7.85938C7.02637 7.74512 7.06152 7.63672 7.1123 7.53809C7.19043 7.38867 7.30566 7.26074 7.44531 7.16699L7.50488 7.13086C7.53223 7.11523 7.55957 7.10059 7.58887 7.08789C7.71484 7.03125 7.85352 7 8 7C8.55273 7 9 7.44727 9 8C9 8.2666 8.89648 8.50879 8.72656 8.6875C8.67383 8.74316 8.61523 8.79199 8.55176 8.83398C8.39355 8.93848 8.2041 9 8 9Z"
        fill="#fff"
      ></path>
      <path
        d="M7 12C7 12.5527 7.44727 13 8 13C8.55273 13 9 12.5527 9 12C9 11.4473 8.55273 11 8 11C7.44727 11 7 11.4473 7 12Z"
        fill="#fff"
      ></path>
      <path
        d="M7 16C7 16.5527 7.44727 17 8 17C8.55273 17 9 16.5527 9 16C9 15.7529 8.91016 15.5273 8.7627 15.3525C8.5791 15.1367 8.30566 15 8 15C7.90625 15 7.81543 15.0127 7.72949 15.0371C7.69238 15.0479 7.65527 15.0605 7.62012 15.0752L7.5752 15.0947L7.52637 15.1191C7.21289 15.2881 7 15.6191 7 16Z"
        fill="#fff"
      ></path>
      <path
        d="M16 7H11C10.7715 7 10.5615 7.07617 10.3936 7.20508C10.2725 7.29785 10.1729 7.41797 10.1035 7.55664C10.0371 7.69043 10 7.84082 10 8C10 8.55273 10.4473 9 11 9H16C16.3281 9 16.6191 8.84277 16.8008 8.59863C16.8701 8.50684 16.9238 8.40234 16.958 8.29004C16.9678 8.25879 16.9756 8.22656 16.9814 8.19434C16.9932 8.13184 17 8.06641 17 8C17 7.44727 16.5527 7 16 7Z"
        fill="#fff"
      ></path>
      <path
        d="M16 11H11C10.4473 11 10 11.4473 10 12C10 12.5527 10.4473 13 11 13H16C16.5527 13 17 12.5527 17 12C17 11.4473 16.5527 11 16 11Z"
        fill="#fff"
      ></path>
      <path
        d="M11 15H16C16.1172 15 16.2295 15.0205 16.334 15.0576C16.5381 15.1299 16.7109 15.2666 16.8301 15.4424L16.8682 15.5029L16.8994 15.5625C16.9189 15.6035 16.9365 15.6455 16.9502 15.6885C16.9824 15.7861 17 15.8906 17 16C17 16.5527 16.5527 17 16 17H11C10.4473 17 10 16.5527 10 16C10 15.4473 10.4473 15 11 15Z"
        fill="#fff"
      ></path>
      <path
        d="M21 17C21 16.4473 20.5527 16 20 16C19.4473 16 19 16.4473 19 17V19H17C16.4473 19 16 19.4473 16 20C16 20.5527 16.4473 21 17 21H19V23C19 23.5527 19.4473 24 20 24C20.5527 24 21 23.5527 21 23V21H23C23.5527 21 24 20.5527 24 20C24 19.4473 23.5527 19 23 19H21V17Z"
        fill="#fff"
      ></path>
    </svg>
  );
};

export default NewActivityIcon;

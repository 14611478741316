const transformDateToUTC = (day) => {
  if (!day) {
    return false;
  }

  const formatDay = new Intl.DateTimeFormat("pt-br").format(day).slice(0, 2);
  const dayUTC = new Date(day)?.toISOString().split("T")[0];
  const hourUTC = new Date(day)?.toLocaleString("pt-BR").split(" ")[1];

  return `${dayUTC?.slice(0, 8) + formatDay}T${hourUTC}`;
};

export default transformDateToUTC;



import React, { useContext } from 'react'
import './styles/ReleaseTextInfo.scss'
import AppContext from '../../../../../context/appContext'
import Switch from '../../../../../components/Switch/Switch'

const ReleaseMessage = () => <span>Manter esta versão liberada</span>

const ReleaseTextInfo = (props) => {
  const { currentAttachment, haveVersion, handleChange } = props
  const { formatDate, convertDatePtBr } = useContext(AppContext)

  const typeName = currentAttachment.attachmentType.name
  const condition = ['Capítulo/Episódio', 'Episódio'].includes(typeName)

  return (
    <div className={`ReleaseTextInfo ${haveVersion ? "noPadding" : ""}`}>
      <div className="type">
        {typeName}
        {condition && (
          <>
            <span className="chapterNumber">{currentAttachment.chapterNumber}</span>
            <span className="blockInfo">{`Bloco ${currentAttachment.blockNumber}`}</span>
          </>
        )}
      </div>

      <div className="version">{`${currentAttachment.version}ª Versão`}</div>

      <div className="date">
        {formatDate(convertDatePtBr(currentAttachment.receivedAt))}
      </div>

      {haveVersion &&
      <div className="switchRelease">
        <Switch
          event={() => handleChange(currentAttachment.id)}
          switchOn={currentAttachment.release}
          replaceYesAndNo={true}
          switchOnText={<ReleaseMessage/>}
        />
      </div>
      }
    </div>
  )
}

export default ReleaseTextInfo

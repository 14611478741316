import React, { useState, useEffect, useContext } from "react";
import "./styles/Unauthorized.scss";
import doorImage from "./images/door.png";
import securityImage from "./images/security.png";
import AppContext from "../../../context/appContext";
import ScriptIcon from "../../VectorIcons/ScriptIcon";
import SimpleButton from "../../SimpleButton/SimpleButton";
import BackTriangleIcon from "../../VectorIcons/BackTriangleIcon";
import { Redirect } from "react-router-dom";

const Unauthorized = () => {
  const appContext = useContext(AppContext);

  const [stateRedirect, setStateRedirect] = useState(false);
  const [stateRedirectTo, setStateRedirectTo] = useState("");

  useEffect(() => {
    appContext.setShowHeader(false);
    // eslint-disable-next-line
  }, []);

  const handleRedirect = (url) => {
    setStateRedirect(true);
    setStateRedirectTo(url);
  };

  return (
    <div className="unauthorized">
      <div className="unauthorizedInfo">
        <ScriptIcon width="144" />

        <span className="codeError">Erro 403</span>
        <span className="accessDenied">Acesso Negado</span>
        <span className="contactMessage">
          Entre em contato com o administrador do Script.
        </span>

        <SimpleButton
          text="Voltar"
          classButton="buttonBlue extraLarge"
          dataQA=""
          toValidate={false}
          clickEvent={() => handleRedirect("/")}
        >
          <BackTriangleIcon />
        </SimpleButton>
      </div>

      <div className="animationsContainer">
        <div
          className="security"
          style={{ backgroundImage: `url(${securityImage})` }}
        />
        <div
          className="door"
          style={{ backgroundImage: `url(${doorImage})` }}
        />
        <div className="floor" />
      </div>

      {stateRedirect && <Redirect to={stateRedirectTo} />}
    </div>
  );
};

export default Unauthorized;

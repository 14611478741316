import React, { StrictMode } from "react";
import "./styles/base.scss";
import { AppProvider } from "./context/appContext";
import GlassLoading from "./components/GlassLoading/GlassLoading";
import Routes from "./routes";
import { UserProvider } from "./context/userContext";
import { AttachmentsProvider } from "./context/attachmentsContext";
import SystemAlerts from "./components/SystemAlerts/SystemAlerts";
import { ExternalProjectsProvider } from "./context/externalProjectsContext";

function App() {
  return (
    <StrictMode>
      <AppProvider>
        <AttachmentsProvider>
          <UserProvider>
            <ExternalProjectsProvider>
              <GlassLoading />
              <Routes />
              <SystemAlerts />
            </ExternalProjectsProvider>
          </UserProvider>
        </AttachmentsProvider>
      </AppProvider>
    </StrictMode>
  );
}

export default App;

import axios from "axios";
import { TOKEN_BASE_URL, REFRESH_TOKEN_URL, AUTHORIZE_BASE_URL, SCOPE, CLIENT_ID, LOGOUT_BASE_URL } from "../shared/constants/env";


export const postAuth = ({ code }) => {
  const url = `${TOKEN_BASE_URL}`;

  return axios.post(url, null, {
    headers: {      
      "cache-control": "no-cache",
    },
    params: {
      code: code,
      originUrl: `${window.location.origin}/auth`,
    }
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error('Error making POST request:', error);
      throw error;
    });
};
export const refreshAuth = (refreshToken) => {
  const url = `${REFRESH_TOKEN_URL}`;

  return axios.post(url, null, {
    headers: {      
      "cache-control": "no-cache",
    },
    params: {
      refreshToken: refreshToken      
    }
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error('Error making POST request:', error);
      throw error;
    });
};

export const requestAutorizationCode = () => {
  window.location = `${AUTHORIZE_BASE_URL}?response_type=code&scope=${SCOPE}&client_id=${CLIENT_ID}&redirect_uri=${window.location.origin}/auth`;
};

export const requestLogoutUser = () => {
  window.location = `${LOGOUT_BASE_URL}?client_id=${CLIENT_ID}&logout_uri=${window.location.origin}/logout`;
};

import React from "react";

const SingleValueThesaurusSearch = ({
  children,
  innerRef,
  innerProps,
  isFocused,
  ...props
}) => {
  const remissives = props.data.remissives;
  const inputValue = props.selectProps.inputValue.toLowerCase();
  const validRemissives = remissives.filter((remissive) =>
    remissive.toLowerCase().includes(inputValue)
  );

  return (
    <div
      className={`singleValueSearch ${isFocused ? "isFocused" : ""}`}
      ref={innerRef}
      {...innerProps}
    >
      <div className="optionInfo">
        <div className="contentName">
          <span className="optionName">{props.data.label}</span>

          {!!validRemissives.length && (
            <div className="remissivesList">
              {validRemissives.map((remissive, index) => {
                return (
                  <div className="remissiveItem" key={index}>
                    <div className="remissiveTag">rem</div>
                    <span className="remissiveText">{remissive}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleValueThesaurusSearch;

import React from "react";
import "./style/FieldsFilter.scss";

const FieldsFilter = (props) => {
  const {
    type = "text",
    label,
    name,
    placeHolder,
    handleChangeFilter = () => {},
  } = props;

  return (
    <div className="fieldsFilter">
      <label>{label}</label>
      <input
        onChange={(e) => handleChangeFilter(e)}
        type={type}
        name={name}
        placeholder={placeHolder}
      />
    </div>
  );
};

export default FieldsFilter;

import React, { useContext, useEffect } from "react";
import "./styles/SeasonsCarousel.scss";
import CarouselCard from "./CarouselCard/CarouselCard";
import Carousel from "./Carousel/Carousel";
import SheetContext from "../../../context/sheetContext";

const SeasonsCarousel = (props) => {
  const { id, productId, title, type } = props;

  const context = useContext(SheetContext);

  useEffect(() => {
    !!productId && context.getSeasons(productId);
    // eslint-disable-next-line
  }, []);

  if (context.seasons.loading) {
    return "";
  }

  if (context.seasons.result.length <= 1) {
    return "";
  }

  return (
    <div className="seasonsCarousel">
      <div className="carouselContainer">
        <div className="seasonsHeader">
          <span className="seasonName">{title}</span>
          <span className="seasonSeparator">/</span>
          <span className="seasonsTitle">Temporadas</span>
        </div>

        <Carousel
          dots={false}
          speed={800}
          infinite={false}
          slidesToShow={6}
          slidesToScroll={6}
        >
          {context.seasons.result.map((season, index) => (
            <CarouselCard
              key={index}
              data={season}
              type={type}
              currentId={id}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default SeasonsCarousel;

import React from "react";
import "./styles/MultiValueLabel.scss";
import ReactImageFallback from "react-image-fallback";
import ProspectRoleIcon from "../../ProspectsRoleIcon/ProspectRoleIcon";
import StarRoleIcon from "../../ProspectsRoleIcon/StarRoleIcon";

const MultiValueLabel = ({
  children,
  innerRef,
  innerProps,
  isFocused,
  ...props
}) => {
  const { photo, origin, label, role } = props.data;

  return (
    <div
      className={`multiValue ${isFocused ? "isFocused" : ""}`}
      ref={innerRef}
      {...innerProps}
    >
      <div className="valueLabelContainer">
        <div className="valueLabel">
          <div className="contentImage">
            <ReactImageFallback
              src={photo}
              fallbackImage={
                origin === "Star" ? <StarRoleIcon /> : <ProspectRoleIcon />
              }
              className="valueLabelImage"
            />
          </div>

          <div className="label">
            <span className="labelName">{label}</span>
            <span className="labelRole">
              {role ? role : origin === "Prospect" ? "Proponente" : ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiValueLabel;

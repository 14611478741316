import React, { useState, useEffect } from "react";
import "./styles/SimpleScriptBox.scss";
import RoundCloseButton from "../../../components/RoundCloseButton/RoundCloseButton";

const SimpleScriptBox = (props) => {
  const [stateBox, setStateBox] = useState({ open: false });

  const {
    title,
    children,
    open,
    icon,
    toggleScriptBox,
    removePadding,
    allowOverflow,
    marginBottom,
    className,
    size,
  } = props;

  useEffect(() => {
    setTimeout(() => {
      setStateBox({ open: open });
    }, 100);
  }, [open]);

  return (
    <div
      className={`SimpleScriptBox ${stateBox.open ? "active" : ""} ${
        removePadding ? "removePadding" : ""
      } ${className ? className : ""} ${!!size ? size : ""}`}
    >
      <div className="bgScriptBox" />
      <div className="windowScriptBox">
        <div className="header">
          {icon}
          <h2>{title}</h2>
          <div className="closeIcon" onClick={toggleScriptBox}>
            <RoundCloseButton />
          </div>
        </div>

        <div
          className={`content ${allowOverflow ? "allowOverflow" : ""} ${
            marginBottom ? "marginBottom" : ""
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default SimpleScriptBox;

import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./styles/ExternalProjects.scss";
import HeaderListItem from "./HeaderListItem/HeaderListItem";
import ListItem from "./ListItem/ListItem";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ExternalProjectsContext from "../../context/externalProjectsContext";
import TableLoading from "../../components/TableLoading/TableLoading";
import ContentPagination from "./ContentPagination/ContentPagination";
import Navigation from "./Navigation/Navigation";
import AppContext from "../../context/appContext";
import SearchNoResult from "../../components/VectorIcons/SearchNoResult";

const ExternalProjects = (props) => {
  const {
    currentTerm,
    setCurrentTypeSearchPage,
    setCurrentTypeSearch,
    externalProjectsList,
    currentPagingInfo,
    currentTypeSearch,
    setClearCompletePagingInfo,
    completePagingInfo,
  } = useContext(ExternalProjectsContext);
  const { setRedirectSearch, redirect } = useContext(AppContext);

  const handlePageChange = (page) => setCurrentTypeSearchPage(page);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      mounted = true;
      setCurrentTypeSearch({
        filterType: "Todos",
        filterValue: { Limit: 12 },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      setCurrentTypeSearch({
        filterType: "",
        filterValue: "",
      });
      setClearCompletePagingInfo();
    };
  }, [setCurrentTypeSearch, setClearCompletePagingInfo]);

  useEffect(() => {
    if (redirect) setRedirectSearch(false);
  }, [redirect, setRedirectSearch]);

  return (
    <div id="ExternalProjects">
      <Helmet>
        <title>Script - Projetos Externos</title>
      </Helmet>
      <Breadcrumb>
        <div className="externalProjectsHeader">
          {!currentTerm && <h2 className="searchTitle">Projetos Externos</h2>}
          {currentTerm && (
            <h2 className="searchTitle">{`Busca por: ${currentTerm}`}</h2>
          )}
        </div>
      </Breadcrumb>

      {completePagingInfo && !!externalProjectsList?.result?.length && (
        <Navigation pagingInfo={completePagingInfo} />
      )}

      <div className="externalProjectsContainer">
        {!externalProjectsList?.loading ? (
          !!externalProjectsList?.result?.length && (
            <>
              <HeaderListItem />
              <ListItem
                channels={externalProjectsList?.result}
                searchTerm={currentTerm}
              />
            </>
          )
        ) : (
          <TableLoading />
        )}

        {!externalProjectsList?.loading &&
          !!currentTerm &&
          !externalProjectsList?.result?.length && (
            <>
              <div className="noResults">
                <SearchNoResult />
                <span className="mainText">
                  {"Nenhum resultado encontrado"}
                </span>
                <span className="subText">
                  {"Verifique o termo buscado e tente novamente"}
                </span>
              </div>
            </>
          )}

        {!!currentPagingInfo &&
          currentPagingInfo?.pagingInfo?.totalPages > 1 && (
            <ContentPagination
              pagination={currentPagingInfo.pagingInfo}
              filter={currentTypeSearch.filterValue}
              handlePageChange={handlePageChange}
            />
          )}
      </div>
    </div>
  );
};

export default ExternalProjects;

import React from "react";
import "./styles/ClearIndicator.scss";
import BackClearIndicator from "../../../VectorIcons/BackClearIndicator";

const ClearIndicator = ({ innerRef, innerProps }) => {
  return (
    <div className="clearIndicator" ref={innerRef} {...innerProps}>
      <BackClearIndicator color="#AAACB9" />
    </div>
  );
};

export default ClearIndicator;

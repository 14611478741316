import React from "react";
import "./styles/TableList.scss";
import EditIcon from "../VectorIcons/EditIcon";
import TableLoading from "../TableLoading/TableLoading";

const TableList = (props) => {
  const { headerList, handleEdit, nameIcon, data, loading } = props;

  return (
    <section id="Table">
      <div className="tableContainer">
        {loading ? (
          <TableLoading />
        ) : (
          <>
            <div className="tableList">
              <ul className="itemsList">
                <li className="listItem headerItem">
                  {headerList.map((item, index) => {
                    return (
                      <span key={index} className={item.className}>
                        {item.label}
                      </span>
                    );
                  })}
                </li>
                {data.map((item, index) => {
                  return (
                    <li className="listItem" key={index}>
                      <div className="listFlex">
                        <span className={`${headerList[0].className} name`}>
                          {nameIcon}
                          {item.name}
                        </span>
                        <span className="large contactsItem">
                          {item.contacts.map((contact, i) => {
                            return (
                              <div key={i}>
                                <span>
                                  {" "}
                                  {contact.value}{" "}
                                  {i + 1 < item.contacts.length ? "|" : ""}{" "}
                                </span>
                                {item.contacts.length && <span>&nbsp;</span>}
                              </div>
                            );
                          })}
                        </span>
                      </div>

                      <span
                        className="editItem"
                        onClick={() => handleEdit(item)}
                      >
                        <EditIcon />
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default TableList;

import React, { useContext } from "react";
import "./styles/Redirecting.scss";
import FullCircleLoading from "../LoadingCircles/FullCircleLoading/FullCircleLoading";
import UserContext from "../../context/userContext";
import { Redirect } from "react-router-dom";

const Redirecting = () => {
  const userContext = useContext(UserContext);

  const redirectToPermissionPage = (permission) => {
    const permissionPages = {
      TextRelease: "/textos-liberados",
    };
    if (userContext.havePermission([permission]).length === 0) {
      return permissionPages[permission];
    }
  };

  if (
    !userContext.me.loading &&
    userContext.monoPermission("TextRelease") &&
    redirectToPermissionPage("TextRelease")
  )
    return <Redirect to={redirectToPermissionPage("TextRelease")} />;

  return (
    <div className="redirecting">
      <FullCircleLoading />
      <span className="redirectingMessage">Redirecionando</span>
    </div>
  );
};

export default Redirecting;

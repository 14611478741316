import React from "react";
import "./styles/ClearFields.scss";

const ClearFields = (props) => {
  const { handleClick } = props;

  return (
    <div className="clearFields">
      <div className="clearArea" onClick={handleClick}>
        <span className="cross">x</span>
        <span>Limpar todos os campos</span>
      </div>
    </div>
  );
};

export default ClearFields;

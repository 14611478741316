import React, { useContext } from "react";
import AppContext from "../../../context/appContext";
import SimpleScriptBox from "../../../views/ProjectSheet/SimpleScriptBox/SimpleScriptBox";
import AddAttachments from "../../../views/Activities/NewActivity/AddAttachments/AddAttachments";
import ScriptBox from "../../ScriptBox/ScriptBox";
import ModalHeader from "../../../views/Activities/NewActivity/ModalHeader/ModalHeader";
import CreateActivity from "../../../views/Activities/NewActivity/CreateActivity/CreateActivity";
import AttachmentIcon from "../../VectorIcons/AttachmentIcon";

const LightBoxesContentAttachments = (props) => {
  const {
    stateSelectFiles,
    setStateSelectFiles,
    stateFiles,
    toggleActiveCheckBox,
    toggleActiveVersionCheckBox,
    clearActive,
    currentProject,
    type,
    history,
  } = props;

  const appContext = useContext(AppContext);

  return (
    <div>
      {stateSelectFiles && (
        <SimpleScriptBox
          open={stateSelectFiles}
          title="Selecionar anexos"
          icon={<AttachmentIcon />}
          removePadding={true}
          resizeHeader={true}
          toggleScriptBox={() => setStateSelectFiles(false)}
        >
          <AddAttachments
            files={stateFiles}
            toggleActiveCheckBox={toggleActiveCheckBox}
            toggleActiveVersionCheckBox={toggleActiveVersionCheckBox}
            clearActive={clearActive}
            closedModal={() => setStateSelectFiles(false)}
          />
        </SimpleScriptBox>
      )}

      {appContext.current.step === "createProjectActivity" && (
        <ScriptBox
          open={appContext.current.step === "createProjectActivity"}
          icon={
            <ModalHeader
              title={currentProject.title}
              creators={currentProject.creators}
              type={currentProject.type}
              format={currentProject.format}
            />
          }
          resizeHeader={true}
          removePadding={true}
          marginBottom={true}
        >
          <CreateActivity
            appContext={appContext}
            files={stateFiles}
            toggleActiveCheckBox={toggleActiveCheckBox}
            toggleActiveVersionCheckBox={toggleActiveVersionCheckBox}
            listAttachments={() => setStateSelectFiles(true)}
            clearActive={clearActive}
            type={type}
            history={history}
            currentProject={currentProject.id}
          />
        </ScriptBox>
      )}
    </div>
  );
};

export default LightBoxesContentAttachments;

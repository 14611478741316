import React from 'react';
import "./styles/ProjectSheetTitle.scss"

const ProjectSheetTitle = (props) => {

  const { title } = props

  return (
    <h2 className="ProjectSheetTitle">
      {title}
    </h2>
  );
};

export default ProjectSheetTitle;
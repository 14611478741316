import React from "react";
import DeliveryAndRelease from "../DeliveryAndRelease/DeliveryAndRelease";
import "./styles/TableHeader.scss";

const TableHeader = (props) => {
  const { isChapter, type } = props;

  return (
    <div className="blocksTableHeader">
      {type === "projectSheet" ? (
        <>
          <span className="headerItem cell blockNumber mainCell" />
          <span className="headerItem cell chaptersNumber mainCell">
            {isChapter && "Capítulos"}
          </span>
          {isChapter && <DeliveryAndRelease />}
          <div className="placeholder" />
        </>
      ) : (
        <>
          <span className="headerItem cell blockNumber mainCell">
            Nº do {!!isChapter ? "Bloco" : "Episódio"}
          </span>
          <span className="headerItem cell chaptersNumber mainCell">
            {!!isChapter && "Nº de Capítulos"}
          </span>
          <span className="headerItem cell mainCell">Liberação Planejada</span>
          <span className="headerItem cell mainCell">Liberado em</span>
          <div className="placeholder" />
        </>
      )}
    </div>
  );
};

export default TableHeader;

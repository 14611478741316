import { getService } from "../../services/script-api";
import * as JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

const downloadFile = (file) =>
  getService(`contents/attachments/presignedurl/download`, {
    fileId: file.fileId,
  }).then((res) => res.data.url);

const getAllUrl = (attachmentsArray) => {
  return Promise.all(
    attachmentsArray.map(async (file) =>
      downloadFile(file).then((url) => (file.url = url))
    )
  );
};

export const handleDownloadAll = async ({
  attachmentsArray,
  title,
  setLoading,
  callback,
}) => {
  setLoading(true);

  const allUrl = await getAllUrl(attachmentsArray);

  const JSZip = require("jszip");
  const zip = new JSZip();

  const zipFileName = `${title}.zip`;

  attachmentsArray.forEach((attachment, index) => {
    JSZipUtils.getBinaryContent(attachment.url, (err, data) => {
      if (err) throw err;

      const splitStringOnDots = (string) => string.split(".");
      const splitedFileName = splitStringOnDots(attachment.fileName);
      const extension = splitedFileName.length - 1;

      zip.file(
        `${splitedFileName[0]}-${index}.${splitedFileName[extension]}`,
        data,
        { binary: true }
      );

      if (Object.keys(zip.files).length === allUrl.length) {
        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(content, zipFileName);
          setLoading(false);
          if (callback) callback(); 
        });
      }
    });
  });
};

import React from 'react'
import Fieldset from '../../../components/Fieldset/Fieldset'
import HelpLabel from '../../../components/HelpLabel/HelpLabel'
import CustomTooltip from '../../../components/CustomTooltip/CustomTooltip'

const tooltipContent = () => {
  return (
    <ul>
      <li>O Outro Lado do Paraíso</li>
      <li>Tá no Ar: a TV na TV</li>
      <li>Treze Dias Longe do Sol</li>
    </ul>
  )
}

const ContentTitleNumber = (props) => {

  const { data, title, handleChangeDramaturgy, handleBlurDramaturgy, titleError } = props

  return (
    <div className="formFieldColumn titleNumber">
      <Fieldset
        autoComplete="off"
        name="seatonTitle"
        label={`${
          data.seasonNumber === 1 ? 'Título' : 'Título de Temporada'
        }`}
        type="text"
        placeholder={`${
          data.seasonNumber === 1
            ? 'Digite o nome do projeto'
            : 'Digite o nome da Temporada'
        }`}
        value={title}
        handleChange={handleChangeDramaturgy}
        handleBlur={handleBlurDramaturgy}
        isError={titleError}
        msgError="Este campo é obrigatório"
      />

      <div className="helpContainer">
        <HelpLabel
          label="Utilize letra maiúscula no início das palavras, exceto preposições.&nbsp;"
          tipLabel="Ver exemplos"
        >
          <CustomTooltip
            tipLabel="Ver exemplos"
            dataTip={tooltipContent()}
          />
        </HelpLabel>
      </div>
    </div>
  )
}

export default ContentTitleNumber
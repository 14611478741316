import React, { useState, useEffect } from "react";
import FieldsetSelect from "../../../../../components/FieldsetSelect/FieldsetSelect";
import { getService } from "../../../../../services/script-api";
import CkEditor from "../../CkEditor/CkEditor";

const Conclusion = (props) => {
  const { handleFocus, title, data, type, setter, index, isEditable } = props;

  const [stateConclusionOptions, setStateConclusionOptions] = useState([]);
  const [state, setState] = useState(data);

  const getOptions = () => {
    getService("activities/evaluations/data/conclusions/types").then(
      (response) => {
        const compiledData = [];
        response.data.forEach((item) => {
          compiledData.push({ id: item.id, name: item.value });
        });
        setStateConclusionOptions(compiledData);
      }
    );
  };

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    setter(type, { ...state, title: title }, index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleChangeConclusion = (ev) => {
    const id = ev.nativeEvent.target.selectedIndex;
    const idText = ev.nativeEvent.target[id].text;
    const newState = {
      id: parseInt(ev.target.value),
      name: idText,
    };
    setState({ ...state, evaluation: newState.id });
  };

  const handleChangeText = (ev, value) => {
    setState({ ...state, text: value });
  };

  return (
    <div className="fieldContainer">
      <div className="fieldHeader">
        <div className="fieldTitle">
          <span>{title}</span>
        </div>
      </div>

      <div className="adequacySelects">
        <FieldsetSelect
          label="Avaliação"
          placeholder="Selecione"
          darkBackgroundColor={true}
          data={stateConclusionOptions}
          handleChange={handleChangeConclusion}
          handleFocus={() => handleFocus(true)}
          handleBlur={() => handleFocus(false)}
          selected={data.evaluation}
          disabled={!isEditable}
        />
      </div>

      <div className="editorArea">
        {isEditable ? (
          <CkEditor
            onChange={handleChangeText}
            onFocus={() => handleFocus(true)}
            onBlur={() => handleFocus(false)}
            data={data}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: data.text }} />
        )}
      </div>
    </div>
  );
};

export default Conclusion;

import React, { useContext, useState } from "react";
import "./styles/SearchInput.scss";
import SearchIcon from "../VectorIcons/SearchIcon";
import AppContext from "../../context/appContext";
import Button from "../Button/Button";
import { Redirect } from "react-router-dom";

const SearchInput = () => {
  const context = useContext(AppContext);

  const [state, setState] = useState({
    filter: "",
    redirect: false,
  });

  const handleChange = (ev) => {
    setState({
      ...state,
      filter: ev.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    context.setCurrentTypePath("contents");
    context.setFilterSearch({ Term: state.filter, PageNumber: 1, Limit: 12 });
    context.setCurrentTerm(state.filter);
    context.setParameterized(false);
    setState({
      ...state,
      redirect: true,
    });
  };

  return (
    <div className="searchInput">
      <form onSubmit={(e) => handleSubmit(e)}>
        <input
          type="text"
          placeholder="Buscar"
          autoComplete="on"
          onChange={(e) => handleChange(e)}
        />

        <Button typeButton="submit">
          <SearchIcon strokeCollor="#17B4E1" />
        </Button>
      </form>

      {state.redirect && <Redirect to="/busca" />}
    </div>
  );
};

export default SearchInput;

import React, { useContext } from "react";
import "./styles/ConflictsGraphic.scss";
import _ from "lodash";
import SoapTimeline from "./SoapTimeline/SoapTimeline";
import SeriesTimeline from "./SeriesTimeline/SeriesTimeline";
import AppContext from "../../../context/appContext";

const ConflictsGraphic = (props) => {
  const { conflicts, conflictColoredText } = props;

  const appContext = useContext(AppContext);

  const conflictsByYears = [];

  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const equalBeginEndDate = (begin, end) => begin === end;

  const checkIfYearExists = (year) =>
    conflictsByYears.some((containedYear) => containedYear.year === year);

  const populateConflicts = (seasonBeginDate, conflictObject) => {
    conflictsByYears.push({
      year: new Date(seasonBeginDate).getFullYear(),
      conflictsOfYear: [conflictObject],
    });
  };

  const replaceConflicts = (beginDateYear, conflictObject) => {
    const containedYearIndex = conflictsByYears.findIndex(
      (item) => item.year === beginDateYear
    );
    conflictsByYears[containedYearIndex].conflictsOfYear.push(conflictObject);
  };

  const mountConflictObject = (conflict) => {
    const conflictObject = {
      soapOperaSchedule: conflict.soapOperaSchedule,
      name: conflict.productTitle,
      beginDate: conflict.seasonBeginDate,
      endDate: conflict.seasonEndDate,
      relevance: conflict.relevance,
      format: conflict.format,
    };

    return conflictObject;
  };

  const returnConflictsFormatedByYear = (conflicts) => {
    conflicts.forEach((conflict) => {
      const beginDateYear = appContext
        .sumDate(conflict.seasonBeginDate, 1)
        .getFullYear();
      const endDateYear = appContext
        .sumDate(conflict.seasonEndDate, 1)
        .getFullYear();
      const conflictObject = mountConflictObject(conflict);

      if (!checkIfYearExists(beginDateYear)) {
        populateConflicts(
          appContext.sumDate(conflict.seasonBeginDate, 1),
          conflictObject
        );
      } else {
        replaceConflicts(beginDateYear, conflictObject);
      }

      if (!equalBeginEndDate(beginDateYear, endDateYear)) {
        if (!checkIfYearExists(endDateYear)) {
          populateConflicts(
            appContext.sumDate(conflict.seasonEndDate, 1),
            conflictObject
          );
        } else {
          replaceConflicts(endDateYear, conflictObject);
        }
      }
    });
    return _.orderBy(conflictsByYears, ["year"], ["asc"]);
  };

  const returnConflictInfo = (conflict, currentYear) => {
    const startDate = appContext.sumDate(conflict.beginDate, 1);
    const finalDate = appContext.sumDate(conflict.endDate, 1);
    const firstDayOfYear = new Date(`01/01/${startDate.getFullYear()}`);
    const timeDifferenceFromStartOfYear = Math.abs(startDate - firstDayOfYear);
    const daysFromStartOfYear =
      timeDifferenceFromStartOfYear / (1000 * 3600 * 24);
    const daysFromStartOfYearPercentage = (daysFromStartOfYear * 100) / 365;

    const startedThisYear = currentYear.year === startDate.getFullYear();
    const endedThisYear = currentYear.year === finalDate.getFullYear();

    const lastDayOfCurrentYear = new Date(`12/31/${currentYear.year}`);
    const milisecondsToDays = 1000 * 3600 * 24;
    let durationInYear;
    let daysDifferenceInYear;

    if (startedThisYear && endedThisYear) {
      durationInYear = Math.abs(startDate - finalDate);
      daysDifferenceInYear = durationInYear / milisecondsToDays;
    }

    if (startedThisYear && !endedThisYear) {
      durationInYear = Math.abs(startDate - lastDayOfCurrentYear);
      daysDifferenceInYear = durationInYear / milisecondsToDays;
    }

    if (!startedThisYear && endedThisYear) {
      durationInYear = Math.abs(lastDayOfCurrentYear - finalDate);
      daysDifferenceInYear = 365 - durationInYear / milisecondsToDays;
    }

    const durationPercentage = (daysDifferenceInYear * 100) / 365;

    return {
      daysFromStartOfYearPercentage: daysFromStartOfYearPercentage,
      startedThisYear: startedThisYear,
      endedThisYear: endedThisYear,
      durationPercentage: durationPercentage,
    };
  };

  const returnWidthPercentage = (value) => `${value}%`;

  return (
    <div className="conflictsGraphic">
      {returnConflictsFormatedByYear(conflicts).map((year, index) => {
        return (
          <div className="timeTable" key={index}>
            <div className="yearHeader">{year.year}</div>

            <div className="months">
              {months.map((month, index) => (
                <div className="monthCell" key={index}>
                  <span className="monthName">{month}</span>
                </div>
              ))}
            </div>

            {year.conflictsOfYear.map((conflict, index) => {
              return conflict.format.toLowerCase() === "novela" ? (
                <SoapTimeline
                  key={index}
                  width={returnWidthPercentage}
                  value={returnConflictInfo}
                  color={conflictColoredText}
                  conflict={conflict}
                  year={year}
                />
              ) : (
                <SeriesTimeline
                  key={index}
                  width={returnWidthPercentage}
                  value={returnConflictInfo}
                  color={conflictColoredText}
                  conflict={conflict}
                  year={year}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ConflictsGraphic;

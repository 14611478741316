import React, { useContext } from "react";
import "./style/ActivityNotifications.scss";
import FeedbackIcon from "../../../components/VectorIcons/FeedbackIcon";
import { Link } from "react-router-dom";
import CanceledActivitiesIcon from "../../../components/VectorIcons/CanceledActivitiesIcon";
import ConcludedActivitiesIcon from "../../../components/VectorIcons/ConcludedActivitiesIcon";
import ActivitiesContext from "../../../context/activitiesContext";
import { ClipLoader } from "react-spinners";

const ActivityNotifications = (props) => {
  const activitiesContext = useContext(ActivitiesContext);

  const { countActivities, loading } = props;

  const replaceSlug = (str) =>
    str.replace(/\. /g, "-").replace(/[ç]/g, "c").toLowerCase();

  const Feedback = () => {
    return (
      <Link
        to={`/atividade/${replaceSlug(
          activitiesContext.currentType
        )}/feedback-pendentes`}
        className="btnAction"
      >
        <FeedbackIcon color="#db1a33" size={55} />
        <span> Feedbacks Pendentes </span>
        <div className="count">
          {loading ? (
            <ClipLoader
              sizeUnit={"px"}
              size={20}
              color={"#7070B3"}
              loading={true}
            />
          ) : (
            <div>{countActivities}</div>
          )}
        </div>
      </Link>
    );
  };

  const CanceledActivities = () => {
    return (
      <Link
        to={`/atividade/${replaceSlug(
          activitiesContext.currentType
        )}/canceladas`}
        className="btnAction"
      >
        <CanceledActivitiesIcon />
        <span> Ver atividades Canceladas </span>
      </Link>
    );
  };

  const DoneActivities = () => {
    return (
      <Link
        to={`/atividade/${replaceSlug(
          activitiesContext.currentType
        )}/concluidas`}
        className="btnAction"
      >
        <ConcludedActivitiesIcon />
        <span> Ver atividades Concluídas </span>
      </Link>
    );
  };

  return (
    <div id="ActivityNotification">
      <div className="actionButtons">
        {activitiesContext.currentType !== "Pesquisas" && <Feedback />}
        <CanceledActivities />
        <DoneActivities />
      </div>
    </div>
  );
};

export default ActivityNotifications;

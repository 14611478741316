import React from "react";

const ActivitiesIcon = (props) => {
  const { color = "#FFF", size = 24 } = props;

  return (
    <svg
      style={{ marginBottom: "2px" }}
      fill="none"
      height={size}
      viewBox="0 0 24 20"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 16C7 16.5523 7.44772 17 8 17C8.55228 17 9 16.5523 9 16C9 15.4477 8.55228 15 8 15C7.44772 15 7 15.4477 7 16Z"
        fill={color}
      />
      <path
        d="M7 12C7 12.5523 7.44772 13 8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12Z"
        fill={color}
      />
      <path
        d="M7 8C7 8.55228 7.44772 9 8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8Z"
        fill={color}
      />
      <path
        d="M16 7H11C10.4477 7 10 7.44772 10 8C10 8.55228 10.4477 9 11 9H16C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7Z"
        fill={color}
      />
      <path
        d="M16 11H11C10.4477 11 10 11.4477 10 12C10 12.5523 10.4477 13 11 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11Z"
        fill={color}
      />
      <path
        d="M16 15H11C10.4477 15 10 15.4477 10 16C10 16.5523 10.4477 17 11 17H16C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15Z"
        fill={color}
      />
      <path
        d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C4.86 3 4.73 3.01 4.6 3.04C4.21 3.12 3.86 3.32 3.59 3.59C3.41 3.77 3.26 3.99 3.16 4.23C3.06 4.46 3 4.72 3 5V19C3 19.27 3.06 19.54 3.16 19.78C3.26 20.02 3.41 20.23 3.59 20.42C3.86 20.69 4.21 20.89 4.6 20.97C4.73 20.99 4.86 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 2.75C12.41 2.75 12.75 3.09 12.75 3.5C12.75 3.91 12.41 4.25 12 4.25C11.59 4.25 11.25 3.91 11.25 3.5C11.25 3.09 11.59 2.75 12 2.75ZM19 19H5V5H19V19Z"
        fill={color}
      />
    </svg>
  );
};

export default ActivitiesIcon;

import React from "react";
import "./styles/ListCount.scss";

const ListCount = (props) => {
  const { total, text } = props;

  return (
    <h2 className="itemsCounter">
      {total} {text}
    </h2>
  );
};

export default ListCount;

import React, { useEffect, useContext, useState } from "react";
import { Watermark } from "antd";
import  UserContext  from "../../context/userContext"
import axios  from "axios";

const WatermarkContent = (props) => {

    const userContext = useContext(UserContext);

    const [ip, setIP] = useState('');
    const [name, setName] = useState('')
    const [date] = useState(new Date());


   

    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip)
      };
    
    const formatedate = (date) => {
        return `${date.getDate().toString().padStart(2, '0')}/${
            (date.getMonth() + 1).toString().padStart(2, '0')
          }/${date.getFullYear()}`;
    }

    const dataUserWatermark = [
        name + ' '+ formatedate(date) + ' - '+ ip
    ] 

    useEffect(()=>{
        getData();
        setName(userContext.me.user.name)
    },[userContext.me.user.name]);

    return(
        <div>
           <Watermark
                content={dataUserWatermark} 
                rotate={-45}
                gap={[0,0 ]}
                offset={[0, -0]}
                font={{
                    color:'#36345a26',
                    fontSize: 20
                }}
                >
                {props.children}
            </Watermark>
        </div>
    );
}

export default WatermarkContent;
import React, { useContext, useEffect, useState } from "react";
import "./styles/ContentItem.scss";
import TypeIconReturner from "../../../components/TypeIconReturner/TypeIconReturner";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import NewActivityIcon from "../../../components/VectorIcons/NewActivityIcon";
import { Link } from "react-router-dom";
import SheetIcon from "../../../components/VectorIcons/SheetIcon";
import ScriptBox from "../../../components/ScriptBox/ScriptBox";
import SimpleScriptBox from "../../../views/ProjectSheet/SimpleScriptBox/SimpleScriptBox";
import AttachmentIcon from "../../../components/VectorIcons/AttachmentIcon";
import ModalHeader from "../../../views/Activities/NewActivity/ModalHeader/ModalHeader";
import AddAttachments from "../../../views/Activities/NewActivity/AddAttachments/AddAttachments";
import CreateActivity from "../../../views/Activities/NewActivity/CreateActivity/CreateActivity";
import ListAttachments from "../../ListAttachments/ListAttachments";
import AttachmentContext from "../../../context/attachmentsContext";

const ContentItem = (props) => {
  const {
    appContext,
    currentProject,
    currentFiles,
    context,
    type,
    history,
    index,
    getFiles,
    setLoading,
    contentType,
  } = props;
  const [stateFiles, setStateFiles] = useState("");
  const [stateSelectFiles, setStateSelectFiles] = useState(false);
  const [stateCurrentProject, setCurrentProject] = useState(false);

  const attachmentContext = useContext(AttachmentContext);

  const chaptersOrEpisodes = () => {
    const types = {
      Dramaturgia: "Capítulo/Episódio",
      Variedades: "Episódio",
    };
    return types[contentType];
  };

  let _files = [];
  const unifyFiles = (data) => {
    data.forEach((file) => {
      let attachments = file.attachment;
      _files.push(attachments);
      let oldVersion = file.olderVersions;
      if (!!oldVersion) {
        oldVersion.forEach((old) => {
          _files.push(old);
        });
      }
    });

    if (!!data.length) {
      compileFiles(_files);
    } else {
      setStateFiles([]);
    }
  };

  const toggleOpenList = (ev) => {
    let targetActive = ev.target.closest(".listItem");

    if (targetActive) {
      targetActive.classList.toggle("opened");
    }
  };

  const compileFiles = (data) => {
    let allGroup = attachmentContext.compileAll(data, chaptersOrEpisodes());
    let chapterCompiled = attachmentContext.compiledChapter(
      data,
      type,
      chaptersOrEpisodes()
    );
    let materialCompiled = attachmentContext.compileMaterial(
      data,
      "Material Complementar"
    );

    let mergeGroups = { ...allGroup, ...chapterCompiled, ...materialCompiled };
    setStateFiles(
      toSeparateVersion(Object.entries(mergeGroups).map((files) => files[1]))
    );
  };

  const toSeparateVersion = (files) => {
    let obj = [];

    files.forEach((file) => {
      obj.push({ parent: file[0], version: file.filter((e) => e !== file[0]) });
    });

    return obj;
  };

  const toggleActiveCheckBox = (file, parentIndex) => {
    let newState = [...stateFiles];
    newState[parentIndex].parent.isActive =
      !newState[parentIndex].parent.isActive;
    setStateFiles(newState);
  };

  const toggleActiveVersionCheckBox = (item, parentIndex, versionIndex) => {
    let newState = [...stateFiles];
    newState[parentIndex].version[versionIndex].isActive =
      !newState[parentIndex].version[versionIndex].isActive;
    setStateFiles(newState);
  };

  const clearActive = () => {
    let newState = [...stateFiles];
    newState.forEach((file) => {
      file.parent.isActive = false;
      file.version.forEach((v) => (v.isActive = false));
    });

    setStateFiles(newState);
  };

  const createIsValid = () => {
    const hasVersions = stateCurrentProject.type !== "Pesquisa";
    const parentIsValid =
      !!stateFiles.length && stateFiles.some((item) => !!item.parent.isActive);
    const versionIsValid = hasVersions
      ? !!stateFiles.length &&
        stateFiles.some(
          (item) => !!item.version.some((version) => !!version.isActive)
        )
      : hasVersions;

    return parentIsValid || versionIsValid;
  };

  useEffect(() => {
    setCurrentProject(currentProject);
  }, [currentProject]);

  useEffect(() => {
    unifyFiles(currentFiles);
    // eslint-disable-next-line
  }, [currentFiles]);

  if (!stateCurrentProject) return <div />;

  return (
    <div className="listItem">
      <div className="projectDetails" onClick={(ev) => toggleOpenList(ev)}>
        <li className="title">
          <div className="itemArrow" />

          <TypeIconReturner
            type={currentProject.contentType}
            responsiveIcon={true}
          />

          <span className="projectTitle">{stateCurrentProject.title}</span>
        </li>

        <li className="creator">
          {stateCurrentProject.type !== "Pesquisa"
            ? stateCurrentProject.creators.map((item, i) => {
                return (
                  <div key={i}>
                    <span>{item.name}</span>
                    {index + 1 < stateCurrentProject.creators.length && (
                      <span className="creatorSeparator">|</span>
                    )}
                  </div>
                );
              })
            : stateCurrentProject.researchers.map((item) => {
                return (
                  <div key={index}>
                    <span>{item}</span>
                    {index + 1 < stateCurrentProject.researchers.length && (
                      <span className="creatorSeparator">|</span>
                    )}
                  </div>
                );
              })}
        </li>

        <li className="details">
          <span>
            {stateCurrentProject.type !== "Pesquisa"
              ? `${stateCurrentProject.format} 
                 ${stateCurrentProject.format === "Novela" ? "•" : ""}
                 ${
                   stateCurrentProject.format === "Novela"
                     ? stateCurrentProject.soapOperaSchedule
                     : ""
                 }`
              : `${stateCurrentProject.researchCategory} • ${stateCurrentProject.researchSubCategory}`}
          </span>
        </li>

        <li className="origin">
          {!!stateCurrentProject.origins &&
            stateCurrentProject.origins.map((origin, indexOrigin) => {
              return (
                <div key={indexOrigin}>
                  <span>{origin}</span>
                  {index + 1 < stateCurrentProject.origins.length && (
                    <span className="creatorSeparator">|</span>
                  )}
                </div>
              );
            })}
        </li>

        <li className="lastAttachment">
          <span>
            {appContext.formatDate(
              appContext.convertDatePtBr(
                stateCurrentProject.lastAttachmentReceivedDate
              )
            )}
          </span>
        </li>

        <Link
          to={appContext.redirectToSheet(contentType, stateCurrentProject.id)}
        >
          <SheetIcon />
        </Link>
      </div>

      <div className="projectAttachments">
        <ListAttachments
          files={stateFiles}
          toggleActiveCheckBox={toggleActiveCheckBox}
          toggleActiveVersionCheckBox={toggleActiveVersionCheckBox}
          contentType={stateCurrentProject.type}
        />

        <div className="footer">
          <span>Selecione o(s) anexo(s) para adicionar à atividade</span>

          <SimpleButton
            text="Criar atividade"
            classButton="buttonBlue"
            dataQA="button-prospect-edit"
            onBreadcrumb={true}
            isValidForm={createIsValid()}
            toValidate={true}
            clickEvent={() =>
              context.setCurrentStepBox(`createProjectActivity_${index}`)
            }
          >
            <NewActivityIcon />
          </SimpleButton>
        </div>
      </div>

      {stateSelectFiles && (
        <SimpleScriptBox
          open={stateSelectFiles}
          title="Selecionar anexos"
          icon={<AttachmentIcon />}
          removePadding={true}
          resizeHeader={true}
          toggleScriptBox={() => setStateSelectFiles(false)}
        >
          <AddAttachments
            files={stateFiles}
            toggleActiveCheckBox={toggleActiveCheckBox}
            toggleActiveVersionCheckBox={toggleActiveVersionCheckBox}
            clearActive={clearActive}
            closedModal={() => setStateSelectFiles(false)}
          />
        </SimpleScriptBox>
      )}

      {appContext.current.step === `createProjectActivity_${index}` && (
        <ScriptBox
          open={appContext.current.step === `createProjectActivity_${index}`}
          icon={
            <ModalHeader
              title={currentProject.title}
              creators={currentProject.creators}
              type={currentProject.contentType}
              format={currentProject.format}
            />
          }
          resizeHeader={true}
          removePadding={true}
          marginBottom={true}
        >
          <CreateActivity
            appContext={appContext}
            files={stateFiles}
            toggleActiveCheckBox={toggleActiveCheckBox}
            toggleActiveVersionCheckBox={toggleActiveVersionCheckBox}
            listAttachments={() => setStateSelectFiles(true)}
            clearActive={clearActive}
            type={type}
            history={history}
            currentProject={currentProject.id}
            getFiles={getFiles}
            setLoading={setLoading}
          />
        </ScriptBox>
      )}
    </div>
  );
};

export default ContentItem;

import React, { useContext, useEffect, useState } from 'react'
import CreatableFieldset from '../../../components/CreatableFieldset/CreatableFieldset'
import BoxFooter from '../../../components/BoxFooter/BoxFooter'
import Button from '../../../components/Button/Button'
import Fieldset from '../../../components/Fieldset/Fieldset'
import { postService, putService } from '../../../services/script-api'
import AppContext from '../../../context/appContext'
import { sendEvent } from '../../../services/GoogleAnalytics/methods'

const CreateAndEditProspectsAndCompanies = (props) => {
  const { closed, getProspectAndProductionCompanies, path, name, getNewItem, action, data } = props

  const initialState = { name: '', contacts: [] }

  const initialErrorFields = {
    name: false,
    contacts: false,
    isValid: false,
  }

  const context = useContext(AppContext)
  const [state, setState] = useState(initialState)
  const [stateError, setStateError] = useState(initialErrorFields)

  useEffect(() => {
    context.closeMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect( () => {
    setState({
      ...state,
      ...data
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const formatContacts = (contacts) => {
    if (contacts.length > 0) {
      let newContact = []
      contacts.forEach((e) => {
        newContact.push({
          value: e.value,
        })
      })

      return newContact
    }
  }

  const clearForm = () => {
    setState(initialState)
    setStateError(initialErrorFields)
  }

  const returnObjType = (res, item) => {
    let object = {...item, id: res.data, label: state.name, value: res.data}
    let obj = {
      Proponente: {...object, suggesterId: object.id, role: 'proponente', origin: 'Prospect'},
      Produtora: {...object}
    }
    return obj[name]
  }

  const actionProspectAndCompanies = (obj) => action === "edit" ? putService(path, { id: data.id, ...obj }) : postService(path, obj)

  const postProduct = () => {
    let obj = {
      name: state.name,
      contacts: formatContacts(state.contacts),
    }

    if (stateError.isValid) {
      context.setShowGlassLoading(true)

      actionProspectAndCompanies(obj)
        .then((res) => {
          context.setShowGlassLoading(false)
          context.sendMessage(`Cadastro efetudo com sucesso!`)
          sendEvent(`${name}s`, `Cadastro de ${name}`)
          closed()
          if (!!getProspectAndProductionCompanies) getProspectAndProductionCompanies({})
          if (!!getNewItem) getNewItem(returnObjType(res, obj))
          clearForm()
        })
        .catch((e) => {
          context.setShowGlassLoading(false)
          context.sendMessage(`Erro ao fazer o cadastrar.`, 'error')
          if (!!closed) {
            closed()
          } else {
            context.setCurrentStepBox('closed')
          }
        })
    }
  }

  const handleChange = (ev) => {
    ev.preventDefault()
    setState({
      ...state,
      name: ev.target.value,
    })
    setStateError({
      ...setStateError,
      isValid: state.name.trim() !== '',
    })
  }

  const onBlurField = (ev) => {
    setStateError({
      ...stateError,
      isValid: state.name.trim() !== '',
      [ev.target.name]: ev.target.value.trim() === '',
    })
  }

  const handleChangeContact = (newValue) => {
    setState({
      ...state,
      contacts: newValue,
    })

    setStateError({
      ...setStateError,
      isValid: state.name.trim() !== '',
    })
  }

  return (
    <div>
      <Fieldset
        autoComplete="off"
        name="name"
        label="Nome"
        type="text"
        placeholder={`Digite o nome`}
        handleChange={handleChange}
        handleBlur={onBlurField}
        value={state.name}
        isError={stateError.name}
        msgError="Este campo é obrigatório"
      />

      <CreatableFieldset
        label="Contato"
        createMessage="Adicionar a opção:"
        placeholder="Digite um contato e aperte ENTER"
        handleChange={handleChangeContact}
        renderCreatedAt={true}
        values={state.contacts}
      />

      {stateError.contacts && <div>Preencha o campo contact</div>}

      <BoxFooter>
        <Button
          text="Salvar"
          toValidate={true}
          isValidForm={stateError.isValid}
          classButton="buttonGreen large"
          dataQA="button-prospect-new-save"
          clickEvent={() => postProduct()}
        />
      </BoxFooter>
    </div>
  )
}

export default CreateAndEditProspectsAndCompanies

import React from "react";
import "./styles/RoundCloseButton.scss";
import CloseIcon from "../VectorIcons/CloseIcon";

const RoundCloseButton = (props) => {
  const { color = "#FFF" } = props;

  return (
    <div id="RoundCloseButton">
      <CloseIcon color={color} />
    </div>
  );
};

export default RoundCloseButton;

import React from "react";
import "./styles/RegisterCard.scss";
import { Link } from "react-router-dom";

const RegisterCard = (props) => {
  const { iconCollor, option, children, className, redirectTo } = props;

  return (
    <Link
      to={!!redirectTo ? redirectTo : "/"}
      className={`registerCard ${className ? className : ""}`}
    >
      <div className="cardIcon" style={{ backgroundColor: `${iconCollor}` }}>
        {children}
      </div>
      <span className="cardTitle">{option}</span>
    </Link>
  );
};

export default RegisterCard;

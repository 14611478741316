import React, { useState, useRef, useContext } from "react";
import "./styles/ChapterItem.scss";
import NoAttachments from "../NoAttachments/NoAttachments";
import DeleteIcon from "../../../../components/VectorIcons/DeleteIcon";
import Button from "../../../../components/Button/Button";
import ConflictsArrow from "../../../../components/VectorIcons/ConflictsArrow";
import AppContext from "../../../../context/appContext";
import { putService } from "../../../../services/script-api";
import VersionsListChapter from "../VersionsListChapter/VersionsListChapter";

const ChapterItem = (props) => {
  const {
    index,
    item,
    attachments,
    handleDate,
    noAttachmentsMessage,
    handleFileDetails,
    handleEditFile,
    handleOpenReleaseTexts,
    getPlanning,
    isLastTableItem,
    isFirstTableItem,
    isLastItem,
    handleDeleteChapter,
  } = props;

  const [stateIsOpen, setStateIsOpen] = useState(false);
  const appContext = useContext(AppContext);
  const deleteButtonRef = useRef(null);

  const toggleIsOpen = (event) => {
    const deleteRef = deleteButtonRef.current;
    const clickedOnDeleteButton = deleteRef?.contains(event.target);

    if (clickedOnDeleteButton) return;
    setStateIsOpen((prevState) => !prevState);
  };

  const putMoveFile = async (ev) => {
    ev.stopPropagation();

    await Promise.all(
      attachments?.map(async (item) => {
        await putService("contents/attachments", {
          id: item?.id,
          version: item?.version,
          receivedAt: item?.receivedAt,
          attachmentTypeId: item?.attachmentType?.id,
          release: item?.release,
          comment: item?.comment,
          releaseDate: item?.releaseDate,
          blockNumber: isLastItem
            ? item?.blockNumber + 1
            : item?.blockNumber - 1,
          chapterNumber: item?.chapterNumber,
        });
      })
    )
      .then(() => {
        appContext.sendMessage("Capítulo movido com sucesso!");
        getPlanning();
      })
      .catch(() => {
        appContext.sendMessage("Erro ao mover o capítulo!", "error");
      });
  };

  // const chapterHasAttachments = item.fileId;

  const validAttachments = attachments.filter(
    (attachment) => !!attachment.fileId
  );

  // const hasAnReleasedAttachment = attachments.some(
  //   (attachment) => !!attachment.release
  // );

  const isChapterDeleteable =
    isLastTableItem && isLastItem && !validAttachments.length;

  const chapterReleaseDate = attachments
    .filter((file) => !!file.firstReleaseDate)
    .reduce((max, current) => {
      if (max) {
        return new Date(max) < new Date(current.firstReleaseDate)
          ? max
          : current.firstReleaseDate;
      }
      return current.firstReleaseDate;
    }, 0);

  return (
    <li className={`attachmentItem ${stateIsOpen ? "open" : ""}`}>
      <div className="chapterItem" onClick={toggleIsOpen}>
        <span className="headerItem chapters">
          <div className="triangle" />
          {`Capítulo ${item.chapterNumber}`}
        </span>

        <span className="headerItem delivery">
          {handleDate(item.receivedAt)}
        </span>

        <span className="headerItem released">
          {handleDate(chapterReleaseDate)}
        </span>

        <div className="actions">
          {(isLastItem || index === 0) &&
            (!isLastTableItem || !isLastItem) &&
            (!isFirstTableItem || index !== 0) &&
            !(isLastItem && index === 0) && (
              <Button
                text={`Mover para o ${
                  isLastItem ? "Próximo Bloco" : "Bloco Anterior"
                }`}
                classButton="borderedButton small"
                iconSide="left"
                clickEvent={putMoveFile}
              >
                <ConflictsArrow
                  color="#17b4e1"
                  direction={isLastItem ? "Vertical" : "VerticalCima"}
                />
              </Button>
            )}

          {/* {chapterHasAttachments &&
            (hasAnReleasedAttachment ? (
              <ReleaseTextIconMarked />
            ) : (
              <ReleaseTextIcon />
            ))} */}

          {isChapterDeleteable && (
            <div
              onClick={() => handleDeleteChapter(item.id)}
              ref={deleteButtonRef}
            >
              <DeleteIcon />
            </div>
          )}
        </div>
      </div>

      <div className="chapterItemToggle">
        {!!validAttachments.length ? (
          <VersionsListChapter
            handleFileDetails={handleFileDetails}
            attachments={validAttachments}
            handleDate={handleDate}
            getPlanning={getPlanning}
            handleEditFile={handleEditFile}
            handleOpenReleaseTexts={handleOpenReleaseTexts}
            isLastTableItem={isLastTableItem}
          />
        ) : (
          <NoAttachments message={noAttachmentsMessage} />
        )}
      </div>
    </li>
  );
};

export default ChapterItem;

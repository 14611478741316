import React from "react";
import "./styles/ScopeBreadcrumb.scss";

const ScopeBreadcrumb = (props) => {
  const { topLevel, setStateSteps, eraseSteps, breadcrumb } = props;

  const handleSetSteps = (id) =>
    !!id ? setStateSteps((prev) => [...prev, id]) : eraseSteps();

  return (
    <div className="scopeBreadcrumb">
      <div className="breadcrumbItem">
        <span className="breadcrumbName" onClick={() => handleSetSteps("")}>
          {topLevel}
        </span>

        <span className="modalTitleSeparator">{`›`}</span>
      </div>

      {breadcrumb.map((item, index) => {
        return (
          <div className="breadcrumbItem" key={index}>
            <span
              className="breadcrumbName"
              onClick={() => handleSetSteps(item.id)}
            >
              {item.value}
            </span>

            <span className="modalTitleSeparator">{`›`}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ScopeBreadcrumb;

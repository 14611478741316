import React from "react";

const BackTriangleIcon = (props) => {
  const { color = "#FFF" } = props;

  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 1.41421C6 0.523309 4.92286 0.077142 4.29289 0.707107L0.707106 4.29289C0.316582 4.68342 0.316582 5.31658 0.707106 5.70711L4.29289 9.29289C4.92286 9.92286 6 9.47669 6 8.58579L6 1.41421Z"
        fill={color}
      />
    </svg>
  );
};

export default BackTriangleIcon;

import React, { createContext, useReducer } from 'react'
import registerReduce from './sheetAndRegisterReduce'
import { getService, getServiceByte } from '../services/script-api'
import axios from "axios";

const initialContext = {
  filter: '',
  listOrigins: {
    loading: true,
    result: [],
  },
  listSoap: {
    loading: true,
    result: [],
  },
  listFrequencies: {
    loading: true,
    result: [],
  },
}

const Register = createContext()

const RegisterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(registerReduce, initialContext)

  const loading = (subject, active) => {
    dispatch({ action: 'loading', payload: { subject, active } })
  }
  
  const get = async (path, key) => {
    loading(key, true)
    await getService(path)
      .then((result) => {
        dispatch({ payload: result, key: key, action: 'list' })
        loading(key, false)
      })
      .catch((e) => {
        dispatch({
          key: key,
          action: 'list',
          payload: { result: '', pagination: {} },
        })
        loading(key, false)
      })
  }

  const _getProgramFrequency = () => get(`varieties/programfrequencies`, 'listFrequencies')
  const _getSoap = () => get(`dramaturgies/soapoperaschedules`, 'listSoap')
  const _getOrigiins = () => get(`projects/data/origins`, 'listOrigins')

  const downloadEmployeeData = (url, name) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = name
        a.click()
      })
    })
  }

  const downloadEmployeeDataPdf = (data, name) => {
    let fileName = name;

    const fileExtension = name.split('.').pop().toLowerCase();
    if (fileExtension !== 'pdf') {
      fileName = name.replace(/\.[^/.]+$/, "") + ".pdf";
    }
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    const formattedDate = `${date}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;
  
    return ` - ${formattedDate} - ${formattedTime}`;
  }

  const getIp = async () => {
    var res = null
    try{
       res = await axios.get("https://api.ipify.org/?format=json");
    }
    catch(e){
      console.log('Error to get IP');
    }
    return res
  }


  //New function Download Byte and Link
  const getUrlS3 = async (fileId, fileName) => {
    const currentDate = getDate(); 
    try {
      const ipResponse = await getIp();
      const ip = ipResponse ? ipResponse.data.ip : ''; 
      var type = fileName.split('.');
      const fileType = type[1];
      
      if (fileType === 'pdf' || fileType === 'docx' || fileType === 'doc' || fileType === 'txt') {
        getServiceByte(`contents/attachments/presignedbyte/download`, {
          fileId: fileId,
          ipAddress:  ip + currentDate
        }).then(arrayBuffer => {
          try {
            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
            downloadEmployeeDataPdf(blob, fileName);
          } catch (error) {
            console.error('Erro na criação ou no download do arquivo: ', error);
          }
        });
      } else {
        getService(`contents/attachments/presignedurl/download`, {
          fileId: fileId,
        }).then((res) => {
          downloadEmployeeData(res.data.url, fileName);
        });
      }
    } catch (error) {
      console.error('Erro ao obter o endereço IP: ', error);
    }
  };
  

  return (
    <Register.Provider
      value={{
        ...state,
        _getSoap,
        _getProgramFrequency,
        _getOrigiins,
        getUrlS3,
      }}
    >
      {children}
    </Register.Provider>
  )
}

export { Register as default, RegisterProvider }

import React, { useContext, useEffect, useState } from "react";
import "./styles/NewActivity.scss";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import AppContext from "../../../context/appContext";
import FilterProjects from "./FilterProject/FilterProject";
import { getService } from "../../../services/script-api";
import ContentItem from "../ContentItem/ContentItem";
import ActivitiesContext from "../../../context/activitiesContext";
import ScriptBox from "../../../components/ScriptBox/ScriptBox";
import ModalHeader from "./ModalHeader/ModalHeader";
import CreateActivity from "./CreateActivity/CreateActivity";
import NoActivityAttachments from "../../../components/NoActivityAttachments/NoActivityAttachments";
import SimpleScriptBox from "../../ProjectSheet/SimpleScriptBox/SimpleScriptBox";
import AttachmentIcon from "../../../components/VectorIcons/AttachmentIcon";
import AddAttachments from "./AddAttachments/AddAttachments";
import AttachmentContext from "../../../context/attachmentsContext";

const NewActivity = (props) => {
  const [currentStateProjects, setcurrentStateProjects] = useState("");
  const [stateCurrentFiles, setCurrentFiles] = useState([]);
  const [currentListFiles, setCurrentListFiles] = useState([]);
  const [stateShowAttachments, setStateShowAttachments] = useState(false);
  const [stateSelectFiles, setStateSelectFiles] = useState(false);
  const [stateFiles, setStateFiles] = useState("");

  const appContext = useContext(AppContext);
  const activitiesContext = useContext(ActivitiesContext);
  const attachmentContext = useContext(AttachmentContext);

  const handleChangeProjects = (item) => getAttachments(item);

  const getAttachments = (item) => {
    let files = [];
    appContext.setShowGlassLoading(true);
    getService(`projects/${item.id}/activities/attachments`).then(
      (attachment) => {
        if (!!attachment.data && !!attachment.data.attachments) {
          files = attachment.data.attachments.map(() => {
            return { ...attachment.data.attachments };
          });
        }

        setcurrentStateProjects({
          ...attachment.data,
          ...item,
        });

        appContext.setShowGlassLoading(false);
        setCurrentFiles(files);
      }
    );
  };

  useEffect(() => {
    unifyFiles(stateCurrentFiles);
    // eslint-disable-next-line
  }, [stateCurrentFiles]);

  const filterFiles = (data, file) =>
    setCurrentListFiles(data.filter((e) => e !== file));

  const makeListFiles = (file) => {
    let newState = [...currentListFiles];
    if (!currentListFiles.includes(file[0])) {
      newState.push(file[0]);
      setCurrentListFiles(newState);
    } else {
      filterFiles(newState, file[0]);
    }
  };

  const toggleActiveCheckBox = (file, parentIndex) => {
    let newState = [...stateFiles];
    newState[parentIndex].parent.isActive =
      !newState[parentIndex].parent.isActive;
    setStateFiles(newState);
  };

  const toggleActiveVersionCheckBox = (item, parentIndex, versionIndex) => {
    let newState = [...stateFiles];
    newState[parentIndex].version[versionIndex].isActive =
      !newState[parentIndex].version[versionIndex].isActive;
    setStateFiles(newState);
  };

  const clearActive = () => {
    let newState = [...stateFiles];
    newState.forEach((file) => {
      file.parent.isActive = false;
      file.version.forEach((v) => (v.isActive = false));
    });

    setStateFiles(newState);
  };

  const unifyFiles = (data) => {
    let _files = [];
    separateFiles(data, _files);
    return !!data.length ? compileFiles(_files) : setStateFiles([]);
  };

  const separateFiles = (data, _files) => {
    data.forEach((file, index) => {
      if (file[index]) {
        let attachments = file[index].attachment;
        attachments.activitiesNumber = file[index].activitiesNumber;
        _files.push(attachments);
        let oldVersion = file[index].olderVersions;
        if (!!oldVersion) {
          oldVersion.forEach((old) => {
            old.activitiesNumber = file[index].activitiesNumber;
            _files.push(old);
          });
        }
      }
    });
  };

  const chaptersOrEpisodes = () => {
    const types = {
      Dramaturgia: "Capítulo/Episódio",
      Variedades: "Episódio",
    };
    return types[currentStateProjects.contentType];
  };

  const compileFiles = (data) => {
    let allGroup = attachmentContext.compileAll(data, chaptersOrEpisodes());
    let chapterCompiled = attachmentContext.compiledChapter(
      data,
      currentStateProjects.type,
      chaptersOrEpisodes()
    );
    let materialCompiled = attachmentContext.compileMaterial(
      data,
      "Material Complementar"
    );

    let mergeGroups = { ...allGroup, ...materialCompiled, ...chapterCompiled };
    setStateFiles(
      toSeparateVersion(Object.entries(mergeGroups).map((files) => files[1]))
    );
  };

  const toSeparateVersion = (files) => {
    let obj = [];

    files.forEach((file) => {
      obj.push({ parent: file[0], version: file.filter((e) => e !== file[0]) });
    });

    return obj;
  };

  const showAttachments = (bool) => setStateShowAttachments(bool);

  const clearFiles = () => {
    setcurrentStateProjects("");
    setCurrentFiles([]);
    setCurrentListFiles([]);
  };

  return (
    <div id="NewActivity">
      <Breadcrumb>
        <div className="newActivityHeader">
          <h2>Nova Atividade</h2>
          <span>
            {activitiesContext.remakeSlugType(props.match.params.slug).label}
          </span>
        </div>

        <Link to="/atividades" className="goBack">
          <span>{`« Voltar`}</span>
          <span>{` para a lista de atividades`}</span>
        </Link>
      </Breadcrumb>

      <div id="SearchArea">
        <div className="searchContainer">
          <span className="searchTitle">{`Buscar ${
            activitiesContext
              .remakeSlugType(props.match.params.slug)
              .type.toLowerCase() === "pesquisa"
              ? "pesquisa"
              : "projeto"
          }`}</span>

          <div className="formArea">
            <FilterProjects
              openBox={() => appContext.setCurrentStepBox("createActivity")}
              getCurrentItem={handleChangeProjects}
              currentId={currentStateProjects.id}
              showAttachments={showAttachments}
              clearFiles={clearFiles}
            />
          </div>

          {currentStateProjects.type !== "Pesquisa" && (
            <ContentItem
              appContext={appContext}
              currentProject={currentStateProjects}
              currentFiles={stateCurrentFiles}
              makeListFiles={makeListFiles}
              countFiles={currentListFiles.length}
              context={appContext}
              showList={stateShowAttachments}
              getFiles={getAttachments}
              history={props.history}
              type={
                activitiesContext.remakeSlugType(props.match.params.slug).label
              }
              contentType={currentStateProjects.contentType}
              // getCurrentContentFiles={setCurrentContentFiles}
            />
          )}
        </div>
      </div>

     
        <div className="noActivityAttachments">
          <NoActivityAttachments slug={props.match.params.slug} />
        </div>
      
      {stateSelectFiles && (
        <SimpleScriptBox
          open={stateSelectFiles}
          title="Selecionar anexos"
          icon={<AttachmentIcon />}
          removePadding={true}
          resizeHeader={true}
          toggleScriptBox={() => setStateSelectFiles(false)}
        >
          <AddAttachments
            files={stateFiles}
            toggleActiveCheckBox={toggleActiveCheckBox}
            toggleActiveVersionCheckBox={toggleActiveVersionCheckBox}
            clearActive={clearActive}
            closedModal={() => setStateSelectFiles(false)}
          />
        </SimpleScriptBox>
      )}

      {appContext.current.step === "createResearchActivity" && (
        <ScriptBox
          open={appContext.current.step === "createResearchActivity"}
          icon={
            <ModalHeader
              title={currentStateProjects.title}
              creators={currentStateProjects.researchers}
              type={currentStateProjects.type}
              category={currentStateProjects.researchCategory}
              subCategory={currentStateProjects.researchSubCategory}
            />
          }
          resizeHeader={true}
          removePadding={true}
          marginBottom={true}
        >
          <CreateActivity
            currentProject={currentStateProjects.id}
            files={stateFiles}
            history={props.history}
            getFiles={getAttachments}
            projectType={currentStateProjects.type}
            type={
              activitiesContext.remakeSlugType(props.match.params.slug).label
            }
            listAttachments={() => setStateSelectFiles(true)}
          />
        </ScriptBox>
      )}
    </div>
  );
};

export default NewActivity;

import React, { useContext } from "react";
import "./styles/LoadingConflicts.scss";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import CloseIcon from "../../../components/VectorIcons/CloseIcon";
import FullCircleLoading from "../../../components/LoadingCircles/FullCircleLoading/FullCircleLoading";
import MapConflictContext from "../../../context/mapConflictContext";

const LoadingConflicts = () => {
  const mapContext = useContext(MapConflictContext);

  return (
    <div className="loadingConflicts">
      <span className="loadingConflictsTitle">Processando conflitos...</span>
      {mapContext.listAll.loading && (
        <span className="loadingConflictsMessage">
          Esta ação pode demorar alguns minutos. Por favor, aguarde.
        </span>
      )}

      <FullCircleLoading />

      {mapContext.listAll.loading && (
        <SimpleButton
          text="Cancelar"
          classButton="buttonConfirm medium"
          dataQA="button-prospect-edit"
          clickEvent={() => {}}
        >
          <CloseIcon color="#fff" />
        </SimpleButton>
      )}
    </div>
  );
};

export default LoadingConflicts;

import React from "react";

const ErrorCloudIcon = () => {
  return (
    <svg
      width="25"
      height="18"
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.006 7H16.746C16.3718 5.551 15.5988 4.23599 14.5146 3.2044C13.4305 2.1728 12.0787 1.46599 10.6129 1.16428C9.1471 0.862561 7.62606 0.97804 6.22262 1.49759C4.81918 2.01714 3.58961 2.91993 2.67362 4.10338C1.75763 5.28683 1.19196 6.70348 1.04088 8.19235C0.889794 9.68123 1.15936 11.1826 1.81895 12.526C2.47853 13.8693 3.50169 15.0007 4.77215 15.7916C6.04262 16.5824 7.50945 17.0011 9.00597 17H18.006C19.3321 17 20.6038 16.4732 21.5415 15.5355C22.4792 14.5979 23.006 13.3261 23.006 12C23.006 10.6739 22.4792 9.40215 21.5415 8.46447C20.6038 7.52678 19.3321 7 18.006 7Z"
        stroke="#43444E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.52979 12.11C7.54499 11.3887 8.75946 11.0012 10.0048 11.0012C11.2501 11.0012 12.4646 11.3887 13.4798 12.11"
        stroke="#43444E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="7" y="6" width="2" height="2" rx="1" fill="#43444E" />
      <rect x="11" y="6" width="2" height="2" rx="1" fill="#43444E" />
    </svg>
  );
};

export default ErrorCloudIcon;

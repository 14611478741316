import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const PermissionNavLinkItem = (props) => {
  const { havePermission, to, label, icon } = props;

  const Icon = icon;

  const location = useLocation();

  if (havePermission)
    return (
      <NavLink to={to} activeClassName="active">
        <Icon color={location.pathname.startsWith(to) ? "#fff" : "#17b4e1"} />
        <span>{label}</span>
      </NavLink>
    );

  return <></>;
};

export default PermissionNavLinkItem;

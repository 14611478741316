import React from "react";
import InfoCards from "../InfoCards/InfoCards";
import InfoPaperExternalProducer from "../InfoPaper/InfoPaperExternalProject/InfoPaperExternalProducer";

const ProducerInfo = ({ list, owner }) => {
  return (
    <div className="mainContainer">
      <div className="infoContainer">
        <InfoPaperExternalProducer list={list} owner={owner} />
      </div>
      <div className="cardsContainer">
        <InfoCards handleEvent={() => {}} hasBaseboard={true} title="Endereço">
          <span className="adressInfo">
            {list?.productionCompanies?.[0]?.address?.streetName},{" "}
            {list?.productionCompanies?.[0]?.address?.number}
          </span>
          <span className="adressInfo">
            {list?.productionCompanies?.[0]?.address?.complement}
          </span>
          <span className="adressInfo">
            {list?.productionCompanies?.[0]?.address?.district}
          </span>
          <span className="adressInfo">
            {list?.productionCompanies?.[0]?.address?.city} /{" "}
            {list?.productionCompanies?.[0]?.address?.state}
          </span>
          <span className="adressInfo">
            {list?.productionCompanies?.[0]?.address?.postalCode}
          </span>
        </InfoCards>
      </div>
    </div>
  );
};

export default ProducerInfo;

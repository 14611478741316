import React from "react";
import "./styles/NoBlocksOrEpisodes.scss";
import SimpleButton from "../../../../components/SimpleButton/SimpleButton";

const NoBlocksOrEpisodes = (props) => {
  const { projectType, type, handleCreate } = props;

  const typeNames = {
    Capítulos: "Blocos",
    Episódios: "Episódios",
  };

  return (
    <div className="noBlocksOrEpisodes">
      <span className="message">
        {type === "channels"
          ? `Este projeto ainda não possui textos liberados do tipo ${typeNames[projectType]}.`
          : `Este projeto ainda não possui ${typeNames[projectType]} planejados.`}
      </span>
      {type !== "channels" && (
        <SimpleButton
          text={`Criar ${typeNames[projectType]}`}
          classButton="buttonBlue"
          clickEvent={handleCreate}
        />
      )}
    </div>
  );
};

export default NoBlocksOrEpisodes;

import React from "react";
import "./styles/FieldsetNumberMasked.scss";
import ReactInputMask from "react-input-mask";
import ErrorIcon from "../VectorIcons/ErrorIcon";

const FieldsetNumberMasked = (props) => {
  const {
    type,
    name,
    label,
    value,
    placeholder,
    handleChange,
    autoComplete,
    isError,
    msgError,
    handleBlur,
    _class,
    darkBackgroundColor,
    mask,
  } = props;

  return (
    <fieldset className={`fieldset ${_class || ""} ${isError ? "error" : ""}`}>
      {label && <label htmlFor={name}>{label}</label>}

      <div
        className={`inputContainer ${
          darkBackgroundColor ? "darkBackgroundColor" : ""
        }`}
      >
        <ReactInputMask
          value={value}
          mask={mask}
          maskChar={false}
          alwaysShowMask={true}
          type={type}
          name={name}
          autoComplete={autoComplete}
          placeholder={placeholder}
          onBlur={handleBlur ? (e) => handleBlur(e) : () => {}}
          onChange={handleChange ? (ev) => handleChange(ev) : () => {}}
        />

        {isError && (
          <div className="errorIcon">
            <ErrorIcon />
          </div>
        )}
      </div>

      {isError && (
        <>
          <span className="errorMessage">{msgError}</span>
        </>
      )}
    </fieldset>
  );
};

export default FieldsetNumberMasked;

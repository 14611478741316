import React from "react";
import "./styles/ChaptersList.scss";
import ChapterItem from "../ChapterItem/ChapterItem";

const ChaptersList = (props) => {
  const {
    attachments,
    handleDate,
    noAttachmentsMessage,
    handleEditFile,
    handleOpenReleaseTexts,
    getPlanning,
    handleDeleteChapter,
    isLastTableItem,
    isFirstTableItem,
  } = props;

  return (
    <div className="chaptersList">
      <div className="listAttachmentsHeader">
        <span className="headerItem chapters">Capítulos</span>
        <span className="headerItem delivery">Entregue</span>
        <span className="headerItem released">Liberado</span>
      </div>

      <ul className="attachmentsList">
        {attachments.map((item, index) => {
          return (
            <ChapterItem
              key={index}
              index={index}
              item={item.attachment}
              attachments={[item.attachment, ...(item.olderVersions ?? [])]}
              originalAttachments={{
                attachment: item.attachment,
                versions: item.olderVersions || [],
              }}
              handleDate={handleDate}
              noAttachmentsMessage={noAttachmentsMessage}
              getPlanning={getPlanning}
              handleEditFile={handleEditFile}
              handleOpenReleaseTexts={handleOpenReleaseTexts}
              isLastTableItem={isLastTableItem}
              isFirstTableItem={isFirstTableItem}
              isLastItem={attachments.length === index + 1}
              handleDeleteChapter={handleDeleteChapter}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default ChaptersList;

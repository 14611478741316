import React from "react";
import "./styles/AddMore.scss";

const AddMore = (props) => {
  const { text, onClick } = props;

  return (
    <div className="addMore" onClick={() => onClick}>
      <span className="text">{text}</span>
    </div>
  );
};

export default AddMore;

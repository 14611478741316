import React from "react";
import "./styles/RoundBackButton.scss";
import BackArrow from "../VectorIcons/BackArrow";

const RoundBackButton = (props) => {
  const { color, backgroundColor } = props;

  return (
    <div id="RoundBackButton" style={{ backgroundColor: backgroundColor }}>
      <BackArrow color={color} />
    </div>
  );
};

export default RoundBackButton;

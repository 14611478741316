import React, { useContext, useState } from 'react'
import AppContext from '../context/appContext'
import UploadSection from './UploadSection/UploadSection'
import UploadService from './UploadService/UploadService'
import Button from './Button/Button'

const Step3 = (props) => {
  const { send, thesaurus, stateAttachments, getAttachments } = props

  const [_stateAttachments, _setStateAttachments] = useState([])
  const context = useContext(AppContext)

  const getDuplicateFiles = (files) => {
    let filesName = ''
    files.map((e) => (filesName += `<p>${e.name} ja foi selecionado!</p>`))
    context.sendMessage(filesName, 'error')
  }

  const formatFiles = (attachments) => {
    let files = []

    if (!!attachments.length) {
      attachments.forEach((file) => {
        let tempObjFiles = {
          fileId: file._meta.tempFileName,
          fileName: file.originalName,
          version: file.version,
          receivedAt: file.receiveAt,
          attachmentTypeId: file.attachmentTypeId,
          blockNumber: file.blockNumber,
          chapterNumber: parseInt(file.chapterNumber),
        }

        files.push(tempObjFiles)
      })
    }
    return files
  }

  const step3isValid = () => {
    const verifyStateFiles = stateAttachments.every(
      (file) => !!file.attachmentTypeId
    )
    const _verifyStateFiles = _stateAttachments.every((file) => !!file.isSent)

    return !!stateAttachments.length
      ? verifyStateFiles && _verifyStateFiles
      : stateAttachments.length === 0
  }

  const handleFiles = (files) => {
    _setStateAttachments(files)
    getAttachments(formatFiles(files))
  }

  const handleSend = () => step3isValid() && send()

  return (
    <div className="formFieldColumn">
      <UploadSection>
        <UploadService
          thesaurusFileType={thesaurus}
          handleOnComplete={handleFiles}
          getDuplicateFiles={getDuplicateFiles}
        />
      </UploadSection>

      <div className="formField buttonField">
        <Button
          text="Finalizar agora"
          classButton="buttonGreen extraLarge"
          isValidForm={step3isValid()}
          toValidate={true}
          clickEvent={() => handleSend()}
        />
      </div>
    </div>
  )
}

export default Step3

import React, { useState, useContext } from "react";
import SimpleButton from "../../../../components/SimpleButton/SimpleButton";
import "./style/FilterProjects.scss";
import { getService } from "../../../../services/script-api";
import AttachmentIcon from "../../../../components/VectorIcons/AttachmentIcon";
import NewActivityIcon from "../../../../components/VectorIcons/NewActivityIcon";
import AppContext from "../../../../context/appContext";
import SelectContent from "../../../../components/SelectContent/SelectContent";

const FilterProjects = (props) => {
  const { getCurrentItem, currentId, showAttachments, clearFiles } = props;

  const [stateProjects, setStateProjects] = useState({ load: [] });
  const [currentProject, setCurrentProject] = useState("");

  const appContext = useContext(AppContext);

  const filterByExacts = (string, array) => {
    return array.filter(
      (item) => item.title.toLowerCase() === string.toLowerCase()
    );
  };

  const getProjects = (inputValue, callback) => {
    const convertForSelect = (data) => {
      return { value: data.id, label: data.title, ...data };
    };

    const filterProjetct = (project) =>
      project.filter((e) => e.id !== currentId);

    if (inputValue && inputValue.length > 1) {
      getService("contents/filter", { title: inputValue, limit: 10000 }).then(
        (list) => {
          let collection = [];
          list.data.map((item) => collection.push(convertForSelect(item)));

          const exactsFound = filterByExacts(inputValue, collection);
          const exactsFoundIds = exactsFound.map((found) => found?.id);
          if (exactsFound.length)
            collection = collection.filter(
              (item) => !exactsFoundIds.includes(item.id)
            );
          const compiledList = [...exactsFound, ...collection];

          setStateProjects({
            ...stateProjects,
            load: filterProjetct(compiledList),
          });
          callback(filterProjetct(compiledList));
        }
      );
    }
  };

  const handleChangeProjects = (item) => {
    clearFiles();
    showAttachments(false);
    setCurrentProject(!!item ? item : "");
    if (!!item) getCurrentItem(item);
  };

  return (
    <div className="filterProjectsContainer">
      <SelectContent
        options={getProjects}
        onChange={handleChangeProjects}
        classNamePrefix="filterProjects"
        noOptionsMessage="Nenhum item encontrado"
        loadingMessage="Carregando..."
        placeholder="Digite um título"
      />
      {currentProject.type === "Pesquisa" ||
      currentProject.type === "Obra Literária" ? (
        <SimpleButton
          text="Criar atividade"
          classButton="buttonBlue"
          dataQA="button-prospect-edit"
          isValidForm={!!currentProject}
          toValidate={true}
          clickEvent={() =>
            appContext.setCurrentStepBox("createResearchActivity")
          }
        >
          <NewActivityIcon />
        </SimpleButton>
      ) : (
        <SimpleButton
          text="Listar anexos"
          classButton="buttonBlue"
          dataQA="button-prospect-edit"
          isValidForm={!!currentProject}
          toValidate={true}
          clickEvent={() => showAttachments(true)}
        >
          <AttachmentIcon />
        </SimpleButton>
      )}
    </div>
  );
};

export default FilterProjects;

import React from "react";
import PropTypes from "prop-types";

const ScriptIcon = (props) => {
  return (
    <div className="scriptIcon">
      <svg
        width={props.width}
        viewBox="0 0 540 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M39.1819 146.349C44.7074 146.349 50.0949 145.386 56.5874 141.533C62.3893 137.817 68.6056 131.762 77.9991 121.166L91.8131 104.79C93.8852 102.313 93.747 98.7352 91.2605 96.5334C88.774 94.4692 84.9061 94.7444 82.834 97.2215L69.2963 113.185L63.2182 120.065C62.6656 115.524 60.7316 110.57 55.206 101.212C53.2721 97.7719 49.4042 92.2674 48.8516 89.3775C48.7135 88.5519 48.4372 87.0381 50.9237 84.1483C53.134 81.6712 52.7195 77.9557 50.233 75.7539C48.0228 73.8273 44.0168 73.9649 42.0828 76.3044L3 122.772C6.47747 125.041 13.5026 128.325 19.3081 121.458C24.0656 115.83 39.4581 97.6343 39.4581 97.6343C40.4251 99.6985 41.5302 101.763 44.9837 107.267C50.9237 117.313 51.4763 120.065 51.4763 123.23C51.4763 125.982 50.233 128.735 48.1609 130.799C46.6414 132.313 44.0167 134.514 39.1819 134.514C34.7614 134.514 31.0316 132.45 29.9265 131.487C27.3019 129.423 23.5721 129.973 21.6382 132.588C19.5661 135.203 20.1186 138.918 22.6051 140.845C25.2298 142.909 32.2749 146.349 39.1819 146.349Z"
          fill="#fff"
        />
        <path
          d="M158.376 102.451C155.751 100.387 152.021 100.662 149.949 103.139L139.589 115.386C136.964 118.414 131.853 123.918 126.051 128.047C119.973 132.45 114.309 134.514 108.784 134.514C96.4893 134.514 93.5884 126.258 95.9367 116.212C97.18 111.12 98.2851 107.542 99.5284 103.276C102.291 93.6435 107.402 85.1116 118.592 85.1116C122.874 85.1116 126.604 86.3501 129.919 88.8271L128.814 92.9555C127.985 96.1206 129.505 99.4233 132.682 100.249C135.859 101.075 139.589 99.2856 140.418 96.1205L142.352 88.4143C142.904 86.3501 142.352 84.2859 140.97 82.7721C134.892 76.5796 127.156 73.2769 118.592 73.2769C113.48 73.2769 108.507 74.2402 104.087 76.7172C102.706 77.4053 101.462 78.2309 100.219 79.1942C95.246 82.9098 90.4112 88.2766 84.1949 95.5701L68.447 114.148C66.3749 116.625 66.6512 120.065 69.1377 122.129C70.2428 123.093 71.6242 123.643 73.0056 123.643C74.6633 123.643 76.3209 123.093 77.5642 121.579C78.2549 120.753 84.6093 113.322 84.6093 113.322C82.1228 122.542 83.366 131.212 88.0628 137.267C90.273 140.294 93.3121 142.496 96.7656 144.01C100.357 145.523 104.363 146.349 108.784 146.349C116.796 146.349 124.946 143.459 132.958 137.817C139.036 133.414 143.733 128.735 148.015 123.643L158.928 110.707C161 108.23 160.862 104.653 158.376 102.451Z"
          fill="#fff"
        />
        <path
          d="M180.956 146.349C189.106 146.349 194.079 142.221 203.197 131.349L221.984 109.056C224.056 106.579 224.056 102.864 221.569 100.799C219.083 98.5976 215.353 98.7352 213.005 101.487L189.521 129.285C185.515 133.964 183.995 134.377 182.614 134.377C180.956 134.377 179.16 133.001 179.16 130.799C179.16 129.285 179.575 127.496 180.404 125.57C184.962 114.973 191.455 101.763 196.98 88.9647C198.224 86.0748 197.947 83.4602 195.461 81.8089L187.587 76.9924L189.383 74.9282C191.455 72.4512 191.455 68.7357 188.968 66.6715C186.482 64.4697 182.752 64.6073 180.404 67.3595L146.421 107.405C144.211 109.882 144.487 113.597 147.112 115.662C149.599 117.863 153.328 117.45 155.4 114.973L180.127 85.7996L184.133 88.139C179.851 98.3223 174.464 109.469 169.491 120.753C168.109 123.918 166.728 127.909 166.728 132.037C166.728 135.891 167.971 139.331 170.32 141.946C172.944 144.698 176.812 146.349 180.956 146.349Z"
          fill="#fff"
        />
        <path
          d="M218.156 132.45C217.879 133.551 217.603 135.615 217.603 136.716C217.603 141.257 221.057 146.349 228.378 146.349C237.772 146.349 243.435 140.157 250.48 131.762L269.129 109.607C271.201 107.13 271.339 103.139 268.853 101.075C266.366 98.8728 262.222 99.4232 260.15 102.038L239.567 126.395C236.943 129.423 233.489 134.102 230.174 134.102L244.264 83.0474C244.679 81.396 244.817 80.5703 244.817 80.0199C244.817 76.9924 242.192 74.3778 238.739 74.3778C237.081 74.3778 235.285 75.0658 234.18 76.442L203.927 112.221C201.855 114.698 202.132 118.689 204.618 120.616C207.105 122.542 210.558 122.542 212.768 120.065L225.753 104.653C222.3 117.175 218.156 132.45 218.156 132.45ZM244.817 63.9192C249.099 63.9192 252.691 60.3413 252.691 56.0753C252.691 51.6717 249.099 48.2314 244.817 48.2314C240.396 48.2314 236.943 51.6717 236.943 56.0753C236.943 60.3413 240.396 63.9192 244.817 63.9192Z"
          fill="#fff"
        />
        <path
          d="M255.557 178C258.182 178 260.53 176.349 261.221 173.734C261.221 173.596 262.878 167.404 271.167 138.23C276.002 144.147 284.29 146.212 291.335 146.212C305.564 146.212 318.687 141.257 335.816 122.542L351.426 103.964C353.498 101.487 353.222 97.9095 350.735 95.8453C348.249 93.6435 344.519 94.0564 342.309 96.5334L326.975 114.698C323.936 118.139 321.45 120.891 318.963 123.093L320.206 119.102C321.864 113.185 322.555 110.845 323.66 106.441C324.903 101.9 325.317 97.6343 324.903 93.6435C324.35 89.1023 322.831 85.2492 320.344 81.9465C317.305 78.0933 311.365 73.4145 300.452 73.4145C296.723 73.4145 291.611 74.2402 286.224 77.2676C285.533 76.0291 284.29 75.0658 282.909 74.653C279.731 73.6897 276.416 75.4787 275.449 78.6438C274.206 82.6345 270.2 89.928 264.951 96.2582L249.755 114.285C247.683 116.9 247.959 120.616 250.584 122.68C251.689 123.505 252.932 124.056 254.314 124.056C255.971 124.056 257.905 122.955 259.01 121.579L265.641 113.735C251.827 163.551 249.755 170.431 249.755 170.569C248.926 173.734 250.722 176.899 253.899 177.862C254.452 178 255.004 178 255.557 178ZM300.452 85.2492C305.287 85.2492 308.879 86.6253 310.951 89.2399C313.299 92.405 313.852 97.4967 312.194 103.276C311.089 107.818 310.398 110.019 308.879 115.386C306.392 124.331 302.11 134.514 290.921 134.514C279.593 134.514 274.62 125.57 278.35 112.772C279.041 110.432 279.731 107.405 280.837 104.102C282.77 97.2215 285.81 92.1298 289.954 88.9647C293.96 85.9372 297.828 85.2492 300.452 85.2492Z"
          fill="#fff"
        />
        <path
          d="M381.06 85.1116C384.375 85.1116 387 82.4969 387 79.1942C387 76.0291 384.375 73.2769 381.06 73.2769H370.838L378.988 49.4852C379.955 46.3201 378.021 43.1551 374.844 42.1918C371.667 41.3661 368.489 43.2927 367.522 46.3201L358.681 72.5888C356.195 79.8823 353.432 83.3226 344.729 93.6435L328.567 112.772C326.495 115.249 326.633 118.964 329.12 121.166C330.225 122.129 331.606 122.542 332.987 122.542C334.645 122.542 336.303 121.854 337.546 120.478L349.288 106.579L344.177 125.294C341.3 135.453 348.092 146.212 359.096 146.212C370.713 146.212 372.871 134.514 372.871 134.514C363.758 137.703 353.265 136.927 355.642 128.459L367.522 85.1116H381.06Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M506.438 98.5294C527.054 74.5075 537 41.4288 537 9.3274C537 7.64927 536.333 6.03988 535.144 4.85326C533.956 3.66664 532.345 3 530.665 3C518.171 3.00001 509.79 4.18687 503.44 5.90261C498.523 7.23114 494.815 8.9123 491.937 10.2177C491.16 10.57 490.443 10.8953 489.779 11.1785C487.724 12.0556 486.284 13.9492 485.989 16.1618C484.337 28.5503 481.044 39.8413 474.471 51.3592L474.399 50.9403C473.079 43.2961 471.211 34.165 468.737 27.4221C468.062 25.5825 466.571 24.159 464.701 23.5675C462.831 22.976 460.792 23.2831 459.179 24.3993C428.198 45.8464 408.925 82.665 407.329 121.112C403.363 123.682 400.37 125.36 397.695 126.859C395.367 128.165 393.279 129.335 390.999 130.842C385.467 134.497 380.56 141.29 379 145.999C379.256 145.999 379.511 145.999 379.767 146C385.487 146.005 391.079 146.01 396.506 143.365C401.446 140.958 408.88 136.634 414.841 132.792C455.275 136.891 485.956 122.395 506.438 98.5294ZM459.304 40.2019C438.129 57.8811 424.157 84.3374 420.72 112.653C430.731 105.04 441.414 94.7771 449.571 86.2153C451.982 83.6836 455.992 83.584 458.527 85.9928C461.062 88.4016 461.162 92.4066 458.75 94.9383C451.293 102.766 441.12 112.692 430.867 120.797C459.763 120.107 481.555 108.077 496.817 90.2938C513.756 70.5566 522.944 43.3505 524.184 15.7806C516.178 16.1079 510.753 17.0365 506.749 18.1183C503.105 19.1027 500.63 20.1885 498.032 21.3613C495.214 38.9956 488.976 55.0878 475.671 72.1718C474.132 74.1486 471.57 75.0326 469.137 74.4264C466.704 73.8202 464.858 71.8378 464.429 69.3705C464.288 68.559 464.082 67.1411 463.832 65.4087C463.729 64.7017 463.62 63.9422 463.503 63.1503C463.088 60.316 462.558 56.8238 461.913 53.0916C461.169 48.7852 460.295 44.2979 459.304 40.2019Z"
          fill="#17B4E1"
        />
      </svg>
    </div>
  );
};

ScriptIcon.propTypes = {
  width: PropTypes.string.isRequired,
};

export default ScriptIcon;

import React from "react";
import "./styles/ReorderButton.scss";
import ReorderIcon from "../VectorIcons/ReorderIcon";

const ReorderButton = (props) => {
  const { color, text, event, direction } = props;

  return (
    <div className="reorderButton" onClick={event}>
      <div className="reorderArrows">
        <ReorderIcon
          color={direction !== "" && !direction ? color : "#C0C3F8"}
        />
        <ReorderIcon
          color={direction !== "" && !!direction ? color : "#C0C3F8"}
        />
      </div>

      <span className="reorderText">{text}</span>
    </div>
  );
};

export default ReorderButton;

import React from "react";
import "./styles/CheckBox.scss";
import CheckIcon from "../../../components/VectorIcons/CheckIcon";

const CheckBox = (props) => {
  const { label, isActive } = props;

  return (
    <div className={`checkBox ${isActive ? "checked" : ""}`}>
      <div className="check">
        <div className="icon">
          <CheckIcon color="#FFF" />
        </div>
      </div>

      <span className="label">{label}</span>
    </div>
  );
};

export default CheckBox;

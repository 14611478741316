import React from "react";
import "./styles/ComplementariesInfo.scss";
import _ from "lodash";

const ComplementariesInfo = (props) => {
  const { complementaries } = props;

  return (
    <div className="complementariesInfoContainer">
      {!!complementaries &&
        Object.entries(_.groupBy(complementaries, (u) => u.field)).map(
          (item, index) => {
            return (
              <div className="complementariesInfo" key={index}>
                <span className="keyName">{`${item[0]}:`}</span>

                {item[1].map((group, index) => {
                  return (
                    <div className="keyValue" key={index}>
                      <span>{group.name}</span>

                      {item[1].length > index + 1 && (
                        <span className="separator">•</span>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          }
        )}
    </div>
  );
};

export default ComplementariesInfo;

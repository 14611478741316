import React, { useContext, useState } from "react";
import UploadService from "../../../components/UploadService/UploadService";
import Button from "../../../components/Button/Button";
import UploadSection from "../../../components/UploadSection/UploadSection";
import AppContext from "../../../context/appContext";

const Step3 = (props) => {
  const { postResearch, thesaurus, stateAttachments, getAttachments } = props;

  const [_stateAttachments, _setStateAttachments] = useState([]);

  const context = useContext(AppContext);

  const getDuplicateFile = (files) => {
    let filesName = "";
    files.map((e) => (filesName += `<p>${e.name} ja foi selecionado!</p>`));
    context.sendMessage(filesName, "error");
  };

  const formatFiles = (attachments) => {
    let files = [];

    if (!!attachments.length) {
      const initialDate = new Date();
      const finalDate = new Date(
        initialDate.getTime() - initialDate.getTimezoneOffset() * 60000
      ).toISOString();

      attachments.forEach((file) => {
        let tempObjFiles = {
          fileId: file._meta.tempFileName,
          fileName: file.originalName,
          version: 1,
          receivedAt: !!file.receiveAt ? file.receiveAt : finalDate,
          attachmentTypeId: null,
          blockNumber: null,
          chapterNumber: null,
        };

        files.push(tempObjFiles);
      });
    }
    return files;
  };

  const step3isValid = () => {
    // const verifyStateFiles = stateAttachments.every(file => !!file.attachmentTypeId)
    const _verifyStateFiles = _stateAttachments.every((file) => !!file.isSent);
    return !!stateAttachments.length
      ? _verifyStateFiles
      : stateAttachments.length === 0;
  };

  const handleFiles = (files) => {
    _setStateAttachments(files);
    getAttachments(formatFiles(files));
  };

  return (
    <div className="formFieldColumn">
      <UploadSection>
        <UploadService
          thesaurusFileType={thesaurus}
          handleOnComplete={handleFiles}
          getDuplicateFiles={getDuplicateFile}
        />
      </UploadSection>

      <div className="formField buttonField">
        <Button
          text="Finalizar agora"
          classButton="buttonGreen extraLarge"
          isValidForm={step3isValid()}
          toValidate={true}
          clickEvent={() => postResearch()}
        />
      </div>
    </div>
  );
};

export default Step3;

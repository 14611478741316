import React from "react";

const AddThesaurusIcon = (props) => {
  const { color = "#0098CD" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      viewBox="0 0 21 24"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill={color}
              fillRule="nonzero"
              d="M10.5 21c.552 0 1-.448 1-1s-.448-1-1-1v2zm5.5-7.5c0 .552.448 1 1 1s1-.448 1-1h-2zM2 18V3H0v15h2zm8.5 1H3v2h7.5v-2zM3 2h12V0H3v2zm13 1v10.5h2V3h-2zm-1-1c.552 0 1 .448 1 1h2c0-1.657-1.343-3-3-3v2zM0 18c0 1.657 1.343 3 3 3v-2c-.552 0-1-.448-1-1H0zM2 3c0-.552.448-1 1-1V0C1.343 0 0 1.343 0 3h2z"
              transform="translate(-1156 -264) translate(1156 264)"
            />
            <path
              stroke={color}
              strokeWidth="2"
              d="M5 2v7l2.5-1.5L10 9V2"
              transform="translate(-1156 -264) translate(1156 264)"
            />
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 17L17 23M14 20L20 20"
              transform="translate(-1156 -264) translate(1156 264)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AddThesaurusIcon;

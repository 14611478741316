import React, { useEffect, useState } from "react";
import "./style/NavigationThesarus.scss";
import ListItemsThesaurus from "../ListItemsThesaurus/ListItemsThesaurus";
import TableLoading from "../../../components/TableLoading/TableLoading";
import SelectArrowIcon from "../../../components/VectorIcons/SelectArrowIcon";

const NavigationThesarus = (props) => {
  const {
    setCurrentOrigin,
    currentType,
    setHasIntegration,
    selected,
    origins,
    getOriginThesaurus,
  } = props;
  const [currentTextSelect, setCurrentTextSelect] = useState("Formato");
  const [activeSelect, setActiveSelect] = useState(false);
  const [state, setState] = useState({ loading: true, list: [] });

  const dramaturgies = [
    { title: "Dados Básicos", list: ["Formato"] },
    {
      title: "Dados Complementares",
      list: [
        "Origem",
        "Gênero",
        "Subgênero",
        "Espaço",
        "Tempo",
        "Época",
        "Modelo Narrativo",
      ],
    },
    {
      title: "Dados Complementares por relevância",
      list: ["Trama", "Temática", "Universo"],
    },
    { title: "Personagens", list: ["Atividades/Características"] },
  ];

  const varieties = [
    { title: "Dados Básicos", list: ["Formato"] },
    { title: "Dados Complementares", list: ["Gênero", "Subgênero", "tags"] },
  ];

  const selectFirstThesaurus = () => {
    if (!origins.loading) {
      const initialOrigin = origins.origins.filter(
        (e) => e.thesaurusOrigin.value === "Formato"
      )[0];
      const { id, value } = initialOrigin.thesaurusOrigin;
      getOriginThesaurus(id, value);
      setCurrentOrigin(initialOrigin);
      setHasIntegration(true);
    }
  };

  useEffect(() => {
    selectFirstThesaurus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentType]);

  useEffect(() => {
    currentTextSelect === "Formato" && selectFirstThesaurus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origins, currentType]);

  const setTypes = (types) => {
    let t = {
      dramaturgies: dramaturgies,
      varieties: varieties,
    };
    return t[types];
  };

  const setFilter = (list) => {
    let f = [];
    list.forEach((c) => {
      f.push({
        title: c.title,
        list: origins.origins.filter((e) =>
          c.list.includes(e.thesaurusOrigin.value)
        ),
      });
    });

    return f;
  };

  const makeGroupOrigin = () => {
    if (!origins.loading) {
      setState({
        ...state,
        loading: false,
        list: setFilter(setTypes(currentType)),
      });
    }
  };

  useEffect(() => {
    makeGroupOrigin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origins, currentType]);

  const toggleActiveSelect = () => setActiveSelect(!activeSelect);

  const setClass = (bool) => (!!bool ? "activeSelect" : "");

  const setCurrentTitle = (title) => setCurrentTextSelect(title);

  if (origins.loading) return <TableLoading />;

  return (
    <div id="NavigationThesarus">
      <h2>Dicionários</h2>
      <div className="separator" />

      <div className={`contentListItemThesaurus ${setClass(activeSelect)}`}>
        <div className="titleMobile" onClick={() => toggleActiveSelect()}>
          <h3>{currentTextSelect} </h3>
          <SelectArrowIcon />
        </div>

        <div className="contentAbsoluteListThesaurus">
          {!state.loading &&
            state.list.map((e, index) => {
              return (
                <ListItemsThesaurus
                  setCurrentTitle={setCurrentTitle}
                  setHasIntegration={setHasIntegration}
                  toggleActiveSelect={toggleActiveSelect}
                  setCurrentOrigin={setCurrentOrigin}
                  title={e.title}
                  list={e.list}
                  key={index}
                  selected={selected}
                  getOriginThesaurus={getOriginThesaurus}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default NavigationThesarus;

import React, { useState, useEffect } from "react";
import Switch from "../../../components/Switch/Switch";
import SwitchText from "../../../components/SwitchText/SwitchText";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import NextArrowIcon from "../../../components/VectorIcons/NextArrowIcon";
import AssociatedProject from "../../../components/AssociatedProject/AssociatedProject";
import AssociatedSearch from "../../../components/AssociatedSearch/AssociatedSearch";
import FieldsetSelect from "../../../components/FieldsetSelect/FieldsetSelect";
import DeleteIcon from "../../../components/VectorIcons/DeleteIcon";
import Button from "../../../components/Button/Button";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import AsyncLoading from "../../../components/AsyncLoading/AsyncLoading";
import { getService } from "../../../services/script-api";

const Step2 = (props) => {
  const {
    currentStep,
    setStateCurrentStep,
    getOrigin,
    getPeople,
    getAssociatedProjects,
    getAssociatedResearches,
    originsOptions,
    areaThesaurus,
    postResearch,
    researchCategory,
    origin,
  } = props;

  const researchedFromMock = [
    { id: 1, name: "Analista(s)" },
    { id: 2, name: "Área(s)" },
    { id: 3, name: "Pesquisador(es)" },
  ];

  const researchToMock = [
    { id: 4, name: "Criador(es)" },
    { id: 5, name: "Área(s)" },
  ];

  const [stateOrigin, setStateOrigin] = useState(originsOptions[0]);
  const [stateOriginsOptions, setStateOriginsOptions] =
    useState(originsOptions);
  const [stateAssociateProject, setStateAssociateProject] = useState(false);
  const [stateAssociateResearch, setStateAssociateResearch] = useState(false);
  const [stateAssociatedProjects, setStateAssociatedProjects] = useState([]);
  const [stateAssociatedSearches, setStateAssociatedSearches] = useState([]);
  const [stateResearchFrom, setStateResearchFrom] = useState([]);
  const [stateResearchTo, setStateResearchTo] = useState([]);
  const [statePeople, setStatePeople] = useState([]);
  const [stateCreatorsOptions, setStateCreatorsOptions] = useState([]);
  const [stateUsersOptions, setStateUsersOptions] = useState([]);

  useEffect(() => {
    if (researchCategory.value === "Publicação") {
      setStateResearchFrom([
        {
          peopleId: 0,
          type: nextOptionSelected(stateResearchFrom, researchedFromMock),
        },
      ]);
      setStateResearchTo([
        {
          peopleId: 0,
          type: nextOptionSelected(stateResearchTo, researchToMock),
        },
      ]);
      setStateAssociateProject(false);
      setStateAssociateResearch(false);
      setStateAssociatedProjects([]);
      setStateAssociatedSearches([]);
    }
    setStateOrigin(origin);
    // eslint-disable-next-line
  }, [researchCategory]);

  useEffect(() => {
    setStateResearchFrom([
      {
        peopleId: 0,
        type: nextOptionSelected(stateResearchFrom, researchedFromMock),
      },
    ]);
    setStateResearchTo([
      {
        peopleId: 0,
        type: nextOptionSelected(stateResearchTo, researchToMock),
      },
    ]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setStateOriginsOptions(originsOptions);
    setStateOrigin(originsOptions[0]);
    getOrigin(originsOptions[0]);
    // eslint-disable-next-line
  }, [originsOptions]);

  const handleOrigin = (id, value) => {
    setStateOrigin({ id: id, value: value });
    getOrigin({ id: id, value: value });
  };

  const handleChangeAssociateProject = () => {
    setStateAssociateProject(!stateAssociateProject);
  };

  const handleChangeAssociateResearch = () => {
    setStateAssociateResearch(!stateAssociateResearch);
  };

  const handleChangeAssociatedProjects = (item) => {
    setStateAssociatedProjects(item);
    getAssociatedProjects(item);
  };

  const handleChangeAssociatedSearches = (item) => {
    setStateAssociatedSearches(item);
    getAssociatedResearches(item);
  };

  const handleAddResearchFrom = () => {
    const newState = [...stateResearchFrom];
    newState.push({
      peopleId: 0,
      type: nextOptionSelected(stateResearchFrom, researchedFromMock),
    });
    setStateResearchFrom(newState);
  };

  const handleAddResearchTo = () => {
    const newState = [...stateResearchTo];
    newState.push({
      peopleId: 0,
      type: nextOptionSelected(stateResearchTo, researchToMock),
    });
    setStateResearchTo(newState);
  };

  const nextOptionSelected = (state, options) => {
    let currentUsedOptions = [];
    state.forEach((item) => currentUsedOptions.push(item.type));

    let unusedOptions = options.filter((option) => {
      return currentUsedOptions.indexOf(option.id) === -1;
    });

    if (!!unusedOptions.length > 0) {
      return unusedOptions[0].id;
    }
  };

  const handleChangePeopleType = (ev, state, setState, index) => {
    const newState = [...state];
    newState[index].type = parseInt(ev.target.value);
    newState[index].peopleId = [];
    setState(newState);
  };

  useEffect(() => {
    const newState = [...stateResearchFrom, ...stateResearchTo];
    setStatePeople(newState);
    getPeople(newState);
    // eslint-disable-next-line
  }, [stateResearchFrom, stateResearchTo]);

  const filterOptions = (position, state, options) => {
    let compiled = [];
    let currentUsedOptions = [];

    let currentPositionOption = options.filter(
      (item) => item.id === state[position].type
    );
    state.forEach((item) => currentUsedOptions.push(item.type));

    let unusedOptions = options.filter(
      (option) => currentUsedOptions.indexOf(option.id) === -1
    );
    compiled =
      currentPositionOption.length > 0
        ? [...unusedOptions, compileNewOption(currentPositionOption)]
        : unusedOptions;

    return compiled;
  };

  const compileNewOption = (options) => {
    let newOptionCompiled = {
      id: options[0].id,
      name: options[0].name,
    };
    return newOptionCompiled;
  };

  const removeContentOriginalField = (item, state, setState) => {
    const newState = [...state];
    let n = newState.filter((i) => i !== item);
    setState(n);
  };

  const searchChipsAdapterProjects = (inputValue, callback) => {
    if (inputValue && inputValue.length > 0) {
      getService("integrations/stars", { term: inputValue })
        .then((list) => {
          const _list = list.data.map((item) => {
            return { value: item.id, label: item.name };
          });
          setStateCreatorsOptions({
            ...stateCreatorsOptions,
            load: _list,
          });
          callback(_list);
        })
        .catch((e) => {
          callback([]);
        });
    } else callback([]);
  };

  const searchChipsAdapterUsers = (inputValue, callback) => {
    if (inputValue && inputValue.length > 0) {
      getService("users/analysts", {
        term: inputValue,
        activityGroups: [1, 2, 3, 4, 5, 6, 7],
      })
        .then((list) => {
          const _list = list.data.map((item) => {
            return { value: item.id, label: item.name };
          });
          setStateUsersOptions({
            ...stateUsersOptions,
            load: _list,
          });
          callback(_list);
        })
        .catch((e) => {
          callback([]);
        });
    } else callback([]);
  };

  const handleChangePeopleId = (data, index, state, setState) => {
    const peopleId = [];
    const newState = [...state];

    if (!!data) data.forEach((item) => peopleId.push(item));
    newState[index].peopleId = peopleId;

    setState(newState);
  };

  const step2isValid = () => {
    if (stateAssociateProject && stateAssociateResearch) {
      return (
        statePeople.every((item) => !!item.peopleId.length) &&
        !!stateAssociatedProjects.length &&
        !!stateAssociatedSearches.length
      );
    } else if (stateAssociateProject) {
      return (
        statePeople.every((item) => !!item.peopleId.length) &&
        !!stateAssociatedProjects.length
      );
    } else if (stateAssociateResearch) {
      return (
        statePeople.every((item) => !!item.peopleId.length) &&
        !!stateAssociatedSearches.length
      );
    } else {
      return statePeople.every((item) => !!item.peopleId.length);
    }
  };

  return (
    <>
      <div className="formField origin">
        <SwitchText
          label="Origem"
          data={originsOptions}
          handleChange={handleOrigin}
          value={!!stateOriginsOptions.length && stateOrigin.id}
          size="small"
        />
      </div>

      <div className="formFieldColumn">
        <label className="fieldLabel">Pesquisado por</label>

        {stateResearchFrom.map((item, index) => {
          return (
            <div className="formField" key={index}>
              <div
                className={`contentOriginContainer ${
                  stateResearchFrom.length > 1 ? "showDeleteIcon" : ""
                }`}
              >
                <FieldsetSelect
                  placeholder=""
                  data={filterOptions(
                    index,
                    stateResearchFrom,
                    researchedFromMock
                  )}
                  selected={stateResearchFrom[index].type}
                  handleChange={(ev) =>
                    handleChangePeopleType(
                      ev,
                      stateResearchFrom,
                      setStateResearchFrom,
                      index
                    )
                  }
                  msgError="Este campo é obrigatório"
                  endpoint="dramaturgies"
                />

                {item.type === 1 && (
                  <AsyncSelect
                    isMulti
                    className="reactSelect"
                    classNamePrefix="reactSelect"
                    value={item.peopleId}
                    loadOptions={searchChipsAdapterUsers}
                    onChange={(item) =>
                      handleChangePeopleId(
                        item,
                        index,
                        stateResearchFrom,
                        setStateResearchFrom
                      )
                    }
                    components={{
                      LoadingIndicator: () => <AsyncLoading />,
                    }}
                    noOptionsMessage={() => "Nenhum item encontrado"}
                    loadingMessage={() => "Carregando..."}
                    placeholder="Digite um nome e aperte ENTER"
                  />
                )}

                {item.type === 2 && (
                  <Select
                    value={item.peopleId}
                    options={areaThesaurus}
                    className="reactSelect"
                    classNamePrefix="reactSelect"
                    onChange={(item) =>
                      handleChangePeopleId(
                        item,
                        index,
                        stateResearchFrom,
                        setStateResearchFrom
                      )
                    }
                    placeholder="Selecione"
                    isMulti
                    noOptionsMessage={() => "Nenhum item encontrado"}
                  />
                )}

                {item.type === 3 && (
                  <AsyncSelect
                    isMulti
                    className="reactSelect"
                    classNamePrefix="reactSelect"
                    value={item.peopleId}
                    loadOptions={searchChipsAdapterProjects}
                    onChange={(item) =>
                      handleChangePeopleId(
                        item,
                        index,
                        stateResearchFrom,
                        setStateResearchFrom
                      )
                    }
                    components={{
                      LoadingIndicator: () => <AsyncLoading />,
                    }}
                    noOptionsMessage={() => "Nenhum item encontrado"}
                    loadingMessage={() => "Carregando..."}
                    placeholder="Digite um nome e aperte ENTER"
                  />
                )}

                {stateResearchFrom.length > 1 && (
                  <div
                    className="deleteIcon"
                    onClick={() =>
                      removeContentOriginalField(
                        item,
                        stateResearchFrom,
                        setStateResearchFrom
                      )
                    }
                  >
                    <DeleteIcon />
                  </div>
                )}
              </div>
            </div>
          );
        })}

        {stateResearchFrom.length < researchedFromMock.length && (
          <div className="dashedBox" onClick={() => handleAddResearchFrom()}>
            <span>+ Adicionar outro</span>
          </div>
        )}
      </div>

      <div className="formFieldColumn">
        <label className="fieldLabel">Para quem é esta pesquisa?</label>

        {stateResearchTo.map((item, index) => {
          return (
            <div className="formField" key={index}>
              <div
                className={`contentOriginContainer ${
                  stateResearchTo.length > 1 ? "showDeleteIcon" : ""
                }`}
              >
                <FieldsetSelect
                  placeholder=""
                  data={filterOptions(index, stateResearchTo, researchToMock)}
                  selected={stateResearchTo[index].type}
                  handleChange={(ev) =>
                    handleChangePeopleType(
                      ev,
                      stateResearchTo,
                      setStateResearchTo,
                      index
                    )
                  }
                  msgError="Este campo é obrigatório"
                  endpoint="dramaturgies"
                />

                {item.type === 4 && (
                  <AsyncSelect
                    isMulti
                    className="reactSelect"
                    classNamePrefix="reactSelect"
                    value={item.peopleId}
                    loadOptions={searchChipsAdapterProjects}
                    onChange={(item) =>
                      handleChangePeopleId(
                        item,
                        index,
                        stateResearchTo,
                        setStateResearchTo
                      )
                    }
                    components={{
                      LoadingIndicator: () => <AsyncLoading />,
                    }}
                    noOptionsMessage={() => "Nenhum item encontrado"}
                    loadingMessage={() => "Carregando..."}
                    placeholder="Digite um nome e aperte ENTER"
                  />
                )}

                {item.type === 5 && (
                  <Select
                    value={item.peopleId}
                    options={areaThesaurus}
                    className="reactSelect"
                    classNamePrefix="reactSelect"
                    onChange={(item) =>
                      handleChangePeopleId(
                        item,
                        index,
                        stateResearchTo,
                        setStateResearchTo
                      )
                    }
                    placeholder="Selecione"
                    isMulti
                    noOptionsMessage={() => "Nenhum item encontrado"}
                  />
                )}

                {stateResearchTo.length > 1 && (
                  <div
                    className="deleteIcon"
                    onClick={() =>
                      removeContentOriginalField(
                        item,
                        stateResearchTo,
                        setStateResearchTo
                      )
                    }
                  >
                    <DeleteIcon />
                  </div>
                )}
              </div>
            </div>
          );
        })}

        {stateResearchTo.length < researchToMock.length && (
          <div className="dashedBox" onClick={() => handleAddResearchTo()}>
            <span>+ Adicionar outro</span>
          </div>
        )}
      </div>

      <div className="formFieldColumn">
        <Switch
          blockLabel="Associar a projeto?"
          switchOn={stateAssociateProject}
          event={() => handleChangeAssociateProject()}
        />

        {stateAssociateProject && (
          <div className="formField" style={{ zIndex: 10 }}>
            <AssociatedProject handleChange={handleChangeAssociatedProjects} />
          </div>
        )}
      </div>

      <div className="formFieldColumn">
        <Switch
          blockLabel="Associar a outra pesquisa?"
          switchOn={stateAssociateResearch}
          event={() => handleChangeAssociateResearch()}
        />

        {stateAssociateResearch && (
          <div className="formField" style={{ zIndex: 10 }}>
            <AssociatedSearch handleChange={handleChangeAssociatedSearches} />
          </div>
        )}
      </div>

      <div className="formField buttonField">
        <Button
          text="Finalizar agora"
          classButton="buttonGreen extraLarge"
          toValidate={true}
          isValidForm={step2isValid()}
          clickEvent={() => postResearch()}
        />

        <SimpleButton
          text="Próximo: Anexos"
          classButton="buttonBlue extraLarge"
          dataQA="button-prospect-edit"
          iconSide="right"
          toValidate={true}
          isValidForm={step2isValid()}
          clickEvent={
            step2isValid()
              ? () => setStateCurrentStep(currentStep + 1)
              : () => {}
          }
        >
          <NextArrowIcon />
        </SimpleButton>
      </div>
    </>
  );
};

export default Step2;

import React from "react";
import "./style/NoContents.scss";

const NoContents = (props) => {
  return (
    <div className="NoContents container">
      <p>Nenhum resultado encontrado</p>
      <p>Verifique o filtro selecionado e tente novamente.</p>
    </div>
  );
};

export default NoContents;

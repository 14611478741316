import React from "react";

const ChairIcon = (props) => {
  const { color = "#EEB65F" } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9c3 1 6 1 6 1s3 0 6-1M6 4c3 1 6 1 6 1s3 0 6-1"
        stroke={color}
        strokeWidth="2"
      ></path>
      <path
        d="M18.53 15.348l.848-.53-1.06-1.696-.848.53 1.06 1.696zM5.47 21.152a1 1 0 001.06 1.696l-1.06-1.696zm12-7.5l-12 7.5 1.06 1.696 12-7.5-1.06-1.696z"
        fill={color}
      ></path>
      <path
        d="M5.47 15.348l-.848-.53 1.06-1.696.848.53-1.06 1.696zm13.06 5.804a1 1 0 01-1.06 1.696l1.06-1.696zm-12-7.5l12 7.5-1.06 1.696-12-7.5 1.06-1.696z"
        fill={color}
      ></path>
      <path
        d="M7 2a1 1 0 00-2 0h2zM5 2v12h2V2H5zM19 2a1 1 0 10-2 0h2zm-2 0v12h2V2h-2z"
        fill={color}
      ></path>
      <path
        d="M3.5 14h17"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      ></path>
    </svg>
  );
};

export default ChairIcon;

import React, { useContext, useEffect, useState } from "react";
import "./styles/NoActivityAttachments.scss";
import { getService } from "../../services/script-api";
import ActivitiesContext from "../../context/activitiesContext";
import AppContext from "../../context/appContext";
import TableLoading from "../TableLoading/TableLoading";
import ContentItem from "./ContentItem/ContentItem";
import CreatorIcon from "../VectorIcons/CreatorIcon";
import ContentPagination from "../ContentPagination/ContentPagination";
import ListCount from "../ListCount/ListCount";
import ReorderButton from "../ReorderButton/ReorderButton";
import Filter from "./Filter/Filter";
import FilterIcon from "../VectorIcons/FilterIcon";

const NoActivityAttachments = (props) => {
  const { slug } = props;

  const activitiesContext = useContext(ActivitiesContext);
  const appContext = useContext(AppContext);
  const [state, setState] = useState({ loading: true, data: [] });
  const [stateFilterFormat, setStateFilterFormat] = useState([]);
  const [stateShowFormats, setShowFormats] = useState(false);
  const [stateFilter, setStateFilter] = useState({
    Origins: [1, 2],
    OnlyWithoutActivities: true,
  });
  const [stateOrderDirection, setStateOrderDirection] = useState("");
  const [formatDramaturgy, setFormatDramaturgy] = useState({
    loading: true,
    list: [],
  });
  const [formatVarieties, setFormatVarieties] = useState({
    loading: true,
    list: [],
  });

  useEffect(() => {
    setLoading();
    getProjectAttachmentsWithoutActivities();
    getDramaturgyAndVarietiesFormats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, stateFilter]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const handleClickOutside = (event) => {
    const optionElement = event.target.closest(".details");
    if (!optionElement) {
      setShowFormats(false);
    }
  };

  const getProjectAttachmentsWithoutActivities = () => {
    getFiles("projects/activities/attachments");
  };

  const getDramaturgyAndVarietiesFormats = () => {
    getFormats(
      "dramaturgies/thesaurus?Origins=22",
      formatDramaturgy,
      setFormatDramaturgy
    );
    getFormats(
      "varieties/thesaurus?Origins=22",
      formatVarieties,
      setFormatVarieties
    );
  }

  const getFiles = (endpoint) => {
    getService(endpoint, stateFilter)
      .then((res) => {
        setState({
          ...state,
          ...res,
          loading: false,
        });
      })
      .catch((e) => {
        setState({
          ...state,
          loading: false,
        });
      });
  };

  const getFormats = (endpoint, _state, _setState) => {
    getService(endpoint).then((format) => {
      _setState({
        ..._state,
        list: format.data,
        loading: false,
      });
    });
  };

  const setLoading = () => {
    setState({
      ...state,
      loading: true,
    });
  };

  const _setFilter = (filter, format) => {
    if (!!format || format === "") setStateFilterFormat(format);
    setStateFilter(filter);
  };

  const handlePageChange = (page) => {
    setLoading();
    _setFilter({ ...stateFilter, pageNumber: page.pageNumber });
  };

  const orderByReceivedAt = () => {
    const newDirection = stateOrderDirection === 0 ? 1 : 0;

    setStateFilter({
      ...stateFilter,
      "OrderBy.Column": "ReceivedAt",
      "OrderBy.Direction": newDirection,
    });

    setStateOrderDirection(newDirection);
  };

  const getIdFormats = (id) => {
    let newState = [...stateFilterFormat];
    if (newState.includes(id)) {
      newState = newState.filter((e) => e !== id);
    } else {
      newState.push(id);
    }

    setStateFilterFormat(newState);
  };

  const sendFilterFormat = () => {
    setShowFormats(false);
    _setFilter({ ...stateFilter, FormatIds: stateFilterFormat, pageNumber: 1 });
  };

  return (
    <div className="noActivityAttachments">
      <span className="noActivityTitle">Anexos sem atividades</span>
      <div className="contentItem">
        {!!state.data.length && (
          <div className="listCounters">
            <ListCount
              total={state.pagingInfo.extraTotals?.totalAttachments}
              text="Anexos"
            />
            <span className="counterSeparator">|</span>
            <ListCount total={state.pagingInfo.totalItems} text="Projetos" />
          </div>
        )}

        <div className="header">
          <li className="title">Título</li>

          <li className="creator">
            <CreatorIcon color="#575863" />
          </li>
          <li className="details">
            <span onClick={() => setShowFormats(!stateShowFormats)}>
              Formatos <FilterIcon />
            </span>
            {!!stateShowFormats && (
              <Filter
                dramaturgy={formatDramaturgy}
                varieties={formatVarieties}
                isDramaturgy={true}
                isVarieties={true}
                stateFilterFormat={stateFilterFormat}
                getIdFormats={getIdFormats}
                loading={state.loading}
                sendFilterFormat={sendFilterFormat}
              />
            )}
          </li>
          <li className="origin">Procedência</li>
          <li className="lastAttachment" onClick={() => orderByReceivedAt()}>
            <ReorderButton
              text="Último anexo"
              color="#3898CD"
              direction={stateOrderDirection}
            />
          </li>
        </div>

        {!state.loading ? (
          state.data.map((file, index) => {
            return (
              <ContentItem
                appContext={appContext}
                currentFiles={file.attachments}
                currentProject={file}
                contentType={file.contentType}
                context={appContext}
                key={index}
                index={index}
                getFiles={() => getProjectAttachmentsWithoutActivities()}
                type={activitiesContext.remakeSlugType(slug).label}
                setLoading={setLoading}
              />
            );
          })
        ) : (
          <TableLoading />
        )}

        {state.data.length === 0 && !state.loading && (
          <div className="noAttachmentsFound">
            Nenhum anexo sem atividade encontrado para o(s) formato(s)
            selecionado(s)
          </div>
        )}
      </div>

      {!!state.pagingInfo && state.pagingInfo.totalPages > 1 && (
        <div className="containerPagination">
          <div className="container">
            <ContentPagination
              totalItems={state.pagingInfo.totalItems}
              itemsPerPage={state.pagingInfo.itemsPerPage}
              currentPage={state.pagingInfo.currentPage}
              prevPage={state.pagingInfo.previousPage}
              nextPage={state.pagingInfo.nextPage}
              totalPages={state.pagingInfo.totalPages}
              handleChange={handlePageChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NoActivityAttachments;

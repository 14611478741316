import React, { useState, useContext } from "react";
import AppContext from "../../../context/appContext";
import Pagination from "react-js-pagination";

const SearchPagination = (props) => {
  const { pagination } = props;

  const appContext = useContext(AppContext);

  const [currentPage, setCurrentPage] = useState({
    ...appContext.filter,
    PageNumber: pagination?.pagingInfo?.currentPage,
  });

  const handlePageChange = (page) => {
    let p = { PageNumber: page };
    appContext.setFilterSearch({ ...currentPage, ...p });
    setCurrentPage({ ...currentPage, PageNumber: page });
  };

  if (!pagination) return <div />;

  return (
    <div id="PaginationContent">
      <div
        className={`prevPagination ${
          pagination?.pagingInfo?.previousPage && "hasPage"
        }`}
        onClick={
          pagination.pagingInfo?.previousPage
            ? () => handlePageChange(pagination.pagingInfo?.previousPage)
            : null
        }
      >
        <span className="prevArrow" />
        <span className="prevArrow" />
      </div>

      <Pagination
        hideNavigation
        hideFirstLastPages
        activePage={pagination?.pagingInfo?.currentPage}
        itemsCountPerPage={pagination.pagingInfo.itemsPerPage}
        totalItemsCount={pagination.pagingInfo.totalItems}
        onChange={handlePageChange}
        hideDisabled
      />

      <div
        className={`nextPagination ${
          pagination.pagingInfo.nextPage && "hasPage"
        }`}
        onClick={
          pagination.pagingInfo.nextPage
            ? () => handlePageChange(pagination.pagingInfo.nextPage)
            : null
        }
      >
        <span className="nextArrow" />
        <span className="nextArrow" />
      </div>
    </div>
  );
};

export default SearchPagination;

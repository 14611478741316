import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { getService, postService } from "../../../services/script-api";

const PublisherCompaniesAndAuthor = (props) => {
  const {
    path,
    label,
    placeholder,
    optional,
    getPublisherCompaniesAndAuthor,
    keyName,
    data,
  } = props;

  const [state, setState] = useState({ loading: false, list: [] });
  const [term, setTerm] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [publisherCompaniesAndAuthor, setPublisherCompaniesAndAuthor] =
    useState(!!data ? data : []);

  const getPublisherCompanies = (text) => {
    setLoading(true);
    setTerm(text);
    setState({
      ...state,
      loading: true,
    });
    getService(!term ? path : `${path}?term=${text}`).then((res) => {
      setState({
        ...state,
        loading: false,
        list: res.data,
      });
      setLoading(false);
    });
  };

  const onChange = (value) => {
    getPublisherCompaniesAndAuthor(value, keyName);
    setPublisherCompaniesAndAuthor(value);
  };

  // const handleSearch = (term) => {
  //   getPublisherCompanies(term);
  // };

  const handleSearch = (text) => {
    if (term !== text) getPublisherCompanies(text);
  };

  const handleCreate = (inputValue) => {
    setLoading(true);
    postService(path, [{ name: inputValue }]).then((res) => {
      let newState = [...publisherCompaniesAndAuthor];
      setLoading(false);
      newState.push({
        value: res.data[0],
        label: inputValue,
      });

      getPublisherCompaniesAndAuthor(newState, keyName);
      setPublisherCompaniesAndAuthor(newState);
      getPublisherCompanies("");
    });
  };

  useEffect(() => {
    getPublisherCompanies("");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="formFieldColumn">
      <label>{label}</label>
      <CreatableSelect
        className="reactSelect"
        isMulti
        classNamePrefix="reactSelect"
        options={state.list.map((item) => ({
          value: item.id,
          label: item.name,
        }))}
        value={publisherCompaniesAndAuthor}
        onChange={onChange}
        isLoading={isLoading}
        onCreateOption={handleCreate}
        placeholder={placeholder}
        formatCreateLabel={(inputValue) => {
          handleSearch(inputValue);
          return `Criar: "${inputValue}"`;
        }}
        noOptionsMessage={() => {
          handleSearch("");
          return "Nenhum item encontrado";
        }}
      />
      {optional && <span className="optionalText">Opcional</span>}
    </div>
  );
};

export default PublisherCompaniesAndAuthor;

import React from "react";
import "./style/ThesaurusTypeNavigation.scss";

const ThesaurusTypeNavigation = (props) => {
  const { title, icon, isActive, setCurrentType } = props;

  return (
    <div
      className={`ThesaurusTypeNavigation ${!!isActive ? "active" : ""}`}
      onClick={() => setCurrentType()}
    >
      <div className="iconType">{icon}</div>

      <div className="title">{title}</div>
    </div>
  );
};

export default ThesaurusTypeNavigation;

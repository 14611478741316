import React from "react";

const CanceledActivitiesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g stroke="#EEB65F" strokeWidth="2.667">
          <g>
            <g transform="translate(-516 -365) translate(460 351) translate(58 16)">
              <circle cx="13.333" cy="13.333" r="13.333" />
              <path d="M17.333 9.333L9.333 17.333M9.333 9.333L17.333 17.333" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CanceledActivitiesIcon;

import React, { useState, useEffect, useContext } from "react";
import "./styles/ReleaseTextsModal.scss";
import ReleaseTextIcon from "../../../../components/VectorIcons/ReleaseTextIcon";
import SimpleScriptBox from "../../SimpleScriptBox/SimpleScriptBox";
import SimpleButton from "../../../../components/SimpleButton/SimpleButton";
import Switch from "../../../../components/Switch/Switch";
import Textarea from "../../../../components/Textarea/Textarea";
import ReleaseTextInfo from "./ReleaseTextInfo/ReleaseTextInfo";
import { putService } from "../../../../services/script-api";
import AppContext from "../../../../context/appContext";
import AlertBox from "../../../../components/AlertBox/AlertBox";
import ReleaseVersions from "../ReleaseVersions/ReleaseVersions";
import { sendEvent } from "../../../../services/GoogleAnalytics/methods";

const ReleaseTextsModal = (props) => {
  const { releaseTexts, setStateOpen, reload, projectType, submitCallback } =
    props;

  const [state, setState] = useState(releaseTexts);
  const [countUpdates, setCountUpdates] = useState(0);
  const [stateEditedFiles, setStateEditedFiles] = useState([]);
  const [sentAnalytics, setSentAnalytics] = useState(false);

  const appContext = useContext(AppContext);

  const handleChange = (value) => {
    const newStateEdited = [...stateEditedFiles];
    if (!newStateEdited.find((e) => e.id === state.current.id)) {
      newStateEdited.push(state.current);
    }
    setStateEditedFiles(newStateEdited);
    const newState = { ...state };
    newState.current.comment = value;
    setState(newState);
  };

  const ReleaseMessage = () => (
    <span>
      Sim, <strong>Liberar Texto.</strong>
    </span>
  );

  const isValid = () => !!stateEditedFiles.length;

  const changeCurrentRelease = () => {
    const newState = { ...state };
    const newStateEdited = [...stateEditedFiles];
    newStateEdited.push(newState.current);
    newState.current.release = Number(!newState.current.release);
    setState(newState);
    setStateEditedFiles(newStateEdited);
  };

  const changeRelease = (id) => {
    const newState = { ...state };
    const newStateEdited = [...stateEditedFiles];
    let target = newState.versions.filter((e) => e.id === id)[0];
    newStateEdited.push(target);
    target.release = Number(!target.release);
    setState(newState);
    setStateEditedFiles(newStateEdited);
  };

  const handleAnalytics = () => {
    if (!sentAnalytics) {
      sendEvent(`Projeto de ${projectType}`, "Liberação de anexo");
      setSentAnalytics(true);
    }
  };

  const createNewDateTime = () => {
    const newDate = new Date();
    return new Date(
      newDate.getTime() - newDate.getTimezoneOffset() * 60000
    ).toISOString();
  };

  const sendRelease = () => {
    appContext.setShowGlassLoading(true);
    stateEditedFiles.forEach((files) => {
      putService(`contents/attachments`, {
        ...files,
        attachmentTypeId: files.attachmentType.id,
        release: files.release,
        comment: !!state.versions ? state.current.comment : files.comment,
        releaseDate: !!files.release ? createNewDateTime() : null,
      })
        .then(() => {
          setCountUpdates((prevState) => prevState + 1);
          handleAnalytics();
        })
        .catch(() => {
          appContext.setShowGlassLoading(false);
          if (submitCallback) submitCallback();
          appContext.sendMessage("Erro ao atualizar texto!", "error");
        });
    });
  };

  useEffect(() => {
    if (!!stateEditedFiles.length && countUpdates === stateEditedFiles.length) {
      appContext.setShowGlassLoading(false);
      appContext.sendMessage("Texto atualizado com sucesso!");
      appContext.setCurrentStepBox("closed");
      reload();
      if (submitCallback) submitCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countUpdates]);

  return (
    <SimpleScriptBox
      open={state.isOpen}
      toggleScriptBox={() => setStateOpen(false)}
      title="Liberação de texto"
      size="small"
      icon={<ReleaseTextIcon color="#fff" />}
      removePadding={true}
    >
      <div id="ReleaseTextsModal">
        <div className="releaseTextsModalContent">
          <div className="contentReleaseTextInfo">
            <ReleaseTextInfo
              haveVersion={false}
              handleChange={changeRelease}
              currentAttachment={releaseTexts.current}
            />
          </div>

          <Switch
            blockLabel="Deseja liberar este texto?"
            event={() => changeCurrentRelease()}
            switchOn={!!state.current.release}
            switchOnText={<ReleaseMessage />}
          />

          {state.versions?.length > 1 &&
            state.versions.some(
              (e) => !!e.release && e.id !== state.current.id
            ) && (
              <AlertBox text="Existem outras versões liberadas deste anexo." />
            )}

          <ReleaseVersions
            data={state.versions.filter((e) => e.id !== state.current.id)}
            handleChange={changeRelease}
          />

          <Textarea
            maxRows={12}
            label="Observações"
            placeholder="Escreva aqui as suas observações sobre a liberação do texto"
            handleChange={(event) => handleChange(event.target.value)}
            value={state.current.comment}
          />

          <span className="optionalText">Opcional</span>
        </div>
        <div className="releaseTextsFooter">
          <SimpleButton
            text="Voltar"
            classButton="buttonCancel medium"
            clickEvent={() => setStateOpen(false)}
          />

          <SimpleButton
            text="Salvar"
            classButton="buttonGreen medium"
            toValidate={true}
            isValidForm={isValid()}
            clickEvent={() => sendRelease()}
          />
        </div>
      </div>
    </SimpleScriptBox>
  );
};

export default ReleaseTextsModal;

import React, { useEffect } from "react";
import "./styles/AttachmentsDropdown.scss";
import CloseIcon from "../VectorIcons/CloseIcon";

const AttachmentsDropdown = (props) => {
  const { attachments } = props;

  const handleTarget = (event) => {
    const target = event.target.closest(".moreAttachments");
    const activeClassName = document.querySelector(".openedDropdown");

    if (!target) {
      if (!!activeClassName) {
        activeClassName.classList.remove("openedDropdown");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleTarget);

    return () => {
      window.removeEventListener("click", handleTarget);
    };
  }, []);

  const toggleOpenClose = (event) => {
    const target = event.target.closest(".moreAttachments");
    const activeClassName = document.querySelector(".openedDropdown");

    if (!!target.classList.contains("openedDropdown")) {
      target.classList.remove("openedDropdown");
    } else {
      if (!!activeClassName) {
        activeClassName.classList.remove("openedDropdown");
      }
      target.classList.add("openedDropdown");
    }
  };

  const isPlural = (value) => (value > 1 ? "s" : "");

  return (
    <div id="BalloonDropdown">
      <div className="visibleContainer">
        <div className="moreAttachments">
          <span onClick={(ev) => toggleOpenClose(ev)}>
            + {attachments.length - 1} anexo{isPlural(attachments.length - 1)}
          </span>

          <div className="invisibleContainer">
            <div className="triangle" />

            <div className="dropdownContent">
              <div className="closeIcon" onClick={(ev) => toggleOpenClose(ev)}>
                <CloseIcon color="#979797" />
              </div>

              <ul className="dropdownList">
                {!!attachments.length &&
                  attachments.slice(1).map((item, index) => {
                    return (
                      <li className="fileItem" key={index}>
                        {!!item?.attachmentType &&
                        (item?.attachmentType?.name === "Capítulo/Episódio" ||
                        item?.attachmentType?.name === "Episódio") ? (
                          <>
                            <span className="fileCap">
                              Cap {item.chapterNumber}
                            </span>
                            <span className="fileBlock">
                              Bloco {item.blockNumber}
                            </span>
                            <span className="fileVersion">
                              {item.version}º Versão
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="fileCap">
                              {item?.attachmentType?.name}
                            </span>
                            <span className="fileVersion">
                              {item?.version}º Versão
                            </span>
                          </>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttachmentsDropdown;

import React from "react";

const ConflictsArrow = (props) => {
  const { color = "#000", direction } = props;

  const returnRotateDirection = (rotateDirection) => {
    const options = {
      Passado: 0,
      Futuro: 180,
      Vertical: -90,
      VerticalCima: 90,
    };

    return options[rotateDirection];
  };

  return (
    <svg
      style={{ transform: `rotate(${returnRotateDirection(direction)}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M10 5L0 5M5 10L0 5 5 0" transform="translate(1 1)" />
      </g>
    </svg>
  );
};

export default ConflictsArrow;

const getReduce = (action, state) => {
  return {
    ...state,
    ...{
      [action.key]: {
        ...state[action.key],
        result: action.payload.data,
        pagingInfo: action.payload.pagingInfoByType,
      },
    },
  };
};

const setHeader = (action, state) => {
  return {
    ...state,
    ...{
      [action.key]: {
        ...state[action.key],
        pagingInfo: action.payload.pagingInfoByType,
      },
    },
  };
};

const _default = (action, state) => {
  const newState = { ...state, [action.key]: action.payload };
  return newState;
};

const loadingReduce = (action, state) => {
  const newState = { ...state };
  newState[action.payload.subject]["loading"] = action.payload.active;
  return newState;
};

const setFilter = (action, state) => {
  const newState = { ...state, [action.key]: action.payload };
  return newState;
};

export default (state, action) => {
  let _state = {
    loading: () => loadingReduce(action, state),
    header: () => setHeader(action, state),
    list: () => getReduce(action, state),
    filter: () => setFilter(action, state),
    default: () => _default(action, state),
    step: () => _default(action, state),
  };

  return _state[action.action]();
};

import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import UserContext from "../../../context/userContext";
import OverflowMenu from "../../OverflowMenu/OverflowMenu";

const SubMenu = (props) => {
  const { subMenuLinks } = props;

  const userContext = useContext(UserContext);

  return (
    <OverflowMenu dotsColor="#17b4e1">
      {subMenuLinks.map(({ to, label }) => (
        <NavLink
          key={to}
          to={to}
          className="menuLinks"
          activeClassName="active"
        >
          {label}
        </NavLink>
      ))}

      {userContext.havePermission(["Thesaurus"]).length === 0 && (
        <>
          <div className="governaceItem">GOVERNANÇA</div>

          <NavLink
            to="/admin/thesaurus"
            className="menuLinks"
            activeClassName="active"
          >
            Thesaurus
          </NavLink>
        </>
      )}
    </OverflowMenu>
  );
};

export default SubMenu;

import React from "react";

const TooltipContent = () => {
  return (
    <ul>
      <li>O Outro Lado do Paraíso</li>
      <li>Tá no Ar: a TV na TV</li>
      <li>Treze Dias Longe do Sol</li>
    </ul>
  );
};

export default TooltipContent;

import React from "react";
import "./styles/ThesaurusItem.scss";
import MoreWithCircle from "../../VectorIcons/MoreWithCircle";

const ThesaurusItem = (props) => {
  const { currentThesaurus, setStateSteps } = props;
  const { label, value, id } = currentThesaurus;

  return (
    <li
      className="thesaurusItem"
      onClick={() => setStateSteps((prev) => [...prev, id])}
    >
      <MoreWithCircle />
      <span>{label || value}</span>
    </li>
  );
};

export default ThesaurusItem;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./styles/UploadedFile.scss";
import DeleteIcon from "../VectorIcons/DeleteIcon";
import WrapperInput from "./UploadInputs";

const UploadedFile = (props) => {
  const {
    fileName,
    handleBlur,
    handleRemove,
    file,
    thesaurusFileType,
    index,
    setFileInfo,
    inputName,
    dramaturgyList,
    isChapter
  } = props;

  const initialDate = new Date();

  const initialState = {
    attachmentTypeId: "",
    version: 1,
    numberChaptersBlock: 0,
    numberAttachmentsEpisodes: 0,
    rescheduledDelivery: null,
    rescheduledRelease: null,
    scheduleDelivery: null,
    scheduleRelease: null,
    receiveAt: initialDate,
    blockNumber: 1,
    chapterNumber: 0,
    reason: null,
    structuringReview:null
  };

  const [state, setstate] = useState(initialState);
  const [stateFileType, setStateFileType] = useState("");

  const getType = (ev) => {
    const id = ev.nativeEvent.target.selectedIndex;
    const idText = ev.nativeEvent.target[id].text;
    const newState = {
      id: parseInt(ev.target.value),
      name: idText,
    };

    if (newState.name !== "Capítulo/Episódio" || newState.name !== "Episódio") {
      setstate({
        ...state,
        blockNumber: 1,
        chapterNumber: 0,
        attachmentTypeId: newState.id,
      });
      setStateFileType(newState.name);
    } else {
      setstate({
        ...state,
        attachmentTypeId: newState.id,
      });
      setStateFileType(newState.name);
    }
  };

  const getVersion = (version) => {
    setstate({
      ...state,
      version: version,
    });
  };

  const getDate = (date) => {
    let d = new Date(date);

    setstate({
      ...state,
      receiveAt: d,
    });
  };

  const getBlock = (version) => {
    setstate({
      ...state,
      blockNumber: version,
    });
  };

  const getChapter = (ev) => {
    setstate({
      ...state,
      chapterNumber: ev.target.value,
    });
  };

  const [notDefault, setNotDefault] = useState(false);
  const setStructuringReview =(has) =>{
    setNotDefault(true)
    setstate({
      ...state,
      structuringReview: has,
    });
  }

  const getReason = (ev) => {
    setstate({ ...state, reason: ev?.target?.value });
};

  const getChapterAndBlock = (ev) => {
    const chapterId = ev.target.value;
    const chapter = dramaturgyList.find(
      (chapterItem) => chapterItem?.id === Number(chapterId)
    );

    setstate({
      ...state,
      chapterNumber: chapter?.chaption,
      blockNumber: chapter?.block,
      numberChaptersBlock: chapter?.numberChaptersBlock,
      numberAttachmentsEpisodes: chapter?.numberAttachmentsEpisodes,
      rescheduledDelivery: chapter?.rescheduledDelivery,
      rescheduledRelease: chapter?.rescheduledRelease,
      scheduleDelivery: chapter?.scheduleDelivery,
      scheduleRelease: chapter?.scheduleRelease,
      version: chapter?.version + 1,
      id: chapter?.id,
      attachmentTypeId: 1211,
    });
  };

  useEffect(() => {
    setFileInfo(state, index);
    if (notDefault && !state.structuringReview) {
      setstate({ ...state, reason: null });
      setNotDefault(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="uploadedFileContainer">
      <div className="fileHeader">
        <span className="fileName">{fileName}</span>

        <div className="removeButton" onClick={() => handleRemove(file)}>
          <DeleteIcon color="#000" />
        </div>
      </div>

      <WrapperInput
        handleBlur={handleBlur}
        thesaurusFileType={thesaurusFileType}
        state={state}
        getType={getType}
        getVersion={getVersion}
        getDate={getDate}
        stateFileType={stateFileType}
        getBlock={getBlock}
        getChapter={getChapter}
        getChapterAndBlock={getChapterAndBlock}
        dramaturgyList={dramaturgyList}
        inputComponent={inputName}
        getReason={getReason}
        setStructuringReview={setStructuringReview}
        isChapter={isChapter}
      />
    </div>
  );
};

export default UploadedFile;

import React from "react";
import "./styles/ProjectReleasedTextTabs.scss";

const ProjectReleasedTextTabs = (props) => {
  const { contentType, currentTab, setCurrentTab, chapterOrEpisode } = props;
  
  return (
    <div className="tabs">
      {contentType === "Dramaturgia" && (
        <>
          <span
            className={`tab ${
              currentTab === "Capítulos ou Episódios" ? "active" : ""
            }`}
            onClick={() => setCurrentTab("Capítulos ou Episódios")}
          >
            {chapterOrEpisode === "Capítulos"
              ? "Capítulos/Episódios"
              : "Episódios"}
          </span>
          <span
            className={`tab ${currentTab === "Outros" ? "active" : ""}`}
            onClick={() => setCurrentTab("Outros")}
          >
            Outros Anexos
          </span>
        </>
      )}
    </div>
  );
};

export default ProjectReleasedTextTabs;

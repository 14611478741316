import React, { useEffect, useContext, useState } from "react";
import "./styles/AssociateFieldset.scss";
import FieldsetSelect from "../FieldsetSelect/FieldsetSelect";
import DeleteIcon from "../VectorIcons/DeleteIcon";
import AppContext from "../../context/appContext";
import AsyncSelect from "react-select/async";
import AsyncLoading from "../../components/AsyncLoading/AsyncLoading";
import { getService } from "../../services/script-api";
import Option from "./Option/Option";

const AssociateFieldset = (props) => {
  const {
    id,
    indexPosition,
    removeField,
    handleChangeCreatable,
    projectsLength,
    handleChangeAssociationType,
    endpoint,
    thesaurusNumber,
    data,
    selected,
    value,
    defaultValue,
    showPlaceholder,
  } = props;

  const initialStateProjects = { create: [], load: [] };
  const context = useContext(AppContext);
  const [stateOptions, setStateOptions] = useState(initialStateProjects);

  const handleChangeAssociation = (ev) => {
    const id = ev.nativeEvent.target.selectedIndex;
    const idText = ev.nativeEvent.target[id].text;
    handleChangeAssociationType(ev.target.value, indexPosition, idText);
  };

  useEffect(() => {
    context.getTypeThesaurus(thesaurusNumber, endpoint);
    // eslint-disable-next-line
  }, []);

  const searchChipsAdapterProjects = (inputValue, callback) => {
    if (inputValue && inputValue.length > 0) {
      getService("contents", { term: inputValue })
        .then((list) => {
          const _list = list.data
            .filter((item) => item.type !== "Pesquisa")
            .map((item) => {
              return { ...item, value: item.id, label: item.title };
            });

          setStateOptions({
            ...stateOptions,
            load: _list.filter((item) => item.value !== parseInt(id)),
          });
          callback(_list.filter((item) => item.value !== parseInt(id)));
        })
        .catch((e) => {
          callback([]);
        });
    } else callback([]);
  };

  const handleChangeCreateProjects = (items, actionMeta) => {
    handleChangeCreatable(items, actionMeta.action, indexPosition);
    setStateOptions({ ...stateOptions, create: items || [] });
  };

  return (
    <div
      className={`associateFieldset ${
        projectsLength > 1 ? "showDeleteIcon" : ""
      }`}
      style={{ zIndex: 15 - indexPosition }}
    >
      <FieldsetSelect
        data={data}
        placeholder={showPlaceholder ? "Selecione" : ""}
        handleChange={handleChangeAssociation}
        selected={selected}
      />

      <AsyncSelect
        isMulti
        className="reactSelect"
        classNamePrefix="reactSelect"
        value={value}
        defaultValue={defaultValue}
        loadOptions={searchChipsAdapterProjects}
        onChange={handleChangeCreateProjects}
        components={{
          LoadingIndicator: () => <AsyncLoading />,
          Option,
        }}
        noOptionsMessage={() => "Nenhum item encontrado"}
        loadingMessage={() => "Carregando..."}
        placeholder="Selecione"
      />

      {projectsLength > 1 && (
        <div className="deleteIcon" onClick={() => removeField(indexPosition)}>
          <DeleteIcon />
        </div>
      )}
    </div>
  );
};

export default AssociateFieldset;

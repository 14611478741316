import React from "react";

const TelevisionIcon = (props) => {
  const { color } = props;

  return (
    <svg width="24" height="24" fill="none" color={color} size="24">
      <path
        d="M11 12a2 2 0 100-4 2 2 0 000 4z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
      ></path>
      <path
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20 17v6M17 20h6"
      ></path>
      <path
        d="M13.5 21a1 1 0 100-2v2zm5.5-7.5a1 1 0 102 0h-2zM3 18V7H1v11h2zm10.5 1H4v2h9.5v-2zM4 6h14V4H4v2zm15 1v6.5h2V7h-2zm-1-1a1 1 0 011 1h2a3 3 0 00-3-3v2zM1 18a3 3 0 003 3v-2a1 1 0 01-1-1H1zM3 7a1 1 0 011-1V4a3 3 0 00-3 3h2z"
        fill={color}
      ></path>
      <path
        d="M15 1l-4 4-4-4"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M5.293 15.793a1 1 0 101.414 1.414l-1.414-1.414zm10 1.414a1 1 0 001.414-1.414l-1.414 1.414zm-8.586 0c.335-.335.907-.646 1.693-.87.772-.221 1.678-.337 2.6-.337v-2c-1.078 0-2.172.134-3.15.414-.964.275-1.892.714-2.557 1.379l1.414 1.414zM11 16c.922 0 1.828.116 2.6.337.786.224 1.358.535 1.693.87l1.414-1.414c-.665-.665-1.593-1.104-2.557-1.38C13.172 14.135 12.078 14 11 14v2z"
        fill={color}
      ></path>
    </svg>
  );
};

export default TelevisionIcon;

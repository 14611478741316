import React from "react";
import "./styles/ModalTitle.scss";

const ModalTitle = (props) => {
  const { title } = props;

  return (
    <div className="modalTitle">
      <span className="titlePrefix">{`Thesaurus`}</span>
      <span className="modalTitleSeparator">{`›`}</span>
      <span>{`${title}`}</span>
    </div>
  );
};

export default ModalTitle;

import React from 'react'
import './styles/SelectContent.scss'
import AsyncSelect from 'react-select/async'
import AsyncLoading from '../AsyncLoading/AsyncLoading'
import Option from './Option/Option'
import SingleValue from '../../components/SingleValue/SingleValue'
import DropdownIndicator from '../../components/DropdownIndicator/DropdownIndicator'
import ClearIndicator from './ClearIndicator/ClearIndicator'

const SelectContent = (props) => {
  const {
    options,
    onChange,
    noOptionsMessage,
    loadingMessage,
    placeholder,
    classNamePrefix,
  } = props

  return (
    <div className="selectContent">
      <AsyncSelect
        className="reactSelect"
        classNamePrefix={classNamePrefix}
        loadOptions={options}
        onChange={onChange}
        components={{
          LoadingIndicator: () => <AsyncLoading/>,
          DropdownIndicator,
          Option,
          SingleValue,
          ClearIndicator,
        }}
        noOptionsMessage={() => noOptionsMessage}
        loadingMessage={() => loadingMessage}
        placeholder={placeholder}
        openMenuOnClick={false}
        isClearable
      />
    </div>
  )
}

export default SelectContent

const {
  // Endpoints
  REACT_APP_BASE_URL: BASE_URL,

  // Auth
  REACT_APP_TOKEN_BASE_URL: TOKEN_BASE_URL,
  REACT_APP_REFRESH_TOKEN_URL: REFRESH_TOKEN_URL,
  REACT_APP_AUTHORIZE_BASE_URL: AUTHORIZE_BASE_URL,
  REACT_APP_LOGOUT_BASE_URL: LOGOUT_BASE_URL,
  REACT_APP_API_KEY: API_KEY,
  REACT_APP_CLIENT_ID: CLIENT_ID,
  REACT_APP_SCOPE: SCOPE,
  REACT_APP_ANALYTICS_ID: ANALYTICS_ID,
  REACT_APP_AUTHORIZATION_KEY: AUTHORIZATION_KEY,
} = process.env;
  
export {
  BASE_URL,
  TOKEN_BASE_URL,
  REFRESH_TOKEN_URL,
  AUTHORIZE_BASE_URL,
  LOGOUT_BASE_URL,
  API_KEY,
  CLIENT_ID,
  SCOPE,
  ANALYTICS_ID,
  AUTHORIZATION_KEY,
};
  
import React from "react";

const InfoIcon = (props) => {
  const { color = "#5C5B97", size = 15 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 15 15"
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g stroke={color}>
          <g>
            <g transform="translate(-168 -371) translate(80 241) translate(89 131)">
              <circle cx="6.667" cy="6.667" r="6.667" />
              <path d="M6.667 9.333L6.667 6.667M6.667 4L6.673 4" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default InfoIcon;

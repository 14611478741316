import React from 'react'
import "./styles/CountReleasedAttachments.scss"

const CountReleasedAttachments = (props) => {

  const { countAttachments, countProjects } = props

  return (
    <div id="CountReleasedAttachments">
      {countAttachments} Anexos Liberados &nbsp; | &nbsp; { countProjects } projetos
    </div>
  )
}

export default CountReleasedAttachments
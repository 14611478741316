import React from "react";

const EditIcon = (props) => {
  const { color = "#0098CD", size = 20 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path
          d="M9 17.121L18 17.121M13.5.621C13.898.223 14.437 0 15 0c.279 0 .554.055.812.161.257.107.491.263.688.46.197.197.353.431.46.688.106.258.161.533.161.812s-.055.554-.161.812c-.107.257-.263.491-.46.688L4 16.121l-4 1 1-4L13.5.621z"
          transform="translate(1 1.879)"
        />
      </g>
    </svg>
  );
};

export default EditIcon;

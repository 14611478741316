import React from "react";

const ActivityStatusIcon = (props) => {
  const { color = "#ADAFEF" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill={color}>
        <path d="M1.75 13c.966 0 1.75-.784 1.75-1.75S2.716 9.5 1.75 9.5C.783 9.5 0 10.284 0 11.25S.783 13 1.75 13zM10.25 4.5c1.243 0 2.25-1.007 2.25-2.25S11.493 0 10.25 0 8 1.007 8 2.25 9.007 4.5 10.25 4.5zM4.5 18c.828 0 1.5-.672 1.5-1.5S5.328 15 4.5 15 3 15.672 3 16.5 3.672 18 4.5 18zM17 18c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1zM10.25 20c.69 0 1.25-.56 1.25-1.25s-.56-1.25-1.25-1.25S9 18.06 9 18.75 9.56 20 10.25 20zM19.25 12c-.414 0-.75-.336-.75-.75s.336-.75.75-.75.75.336.75.75-.336.75-.75.75zM17 7.5c1.38 0 2.5-1.119 2.5-2.5S18.38 2.5 17 2.5 14.5 3.619 14.5 5s1.12 2.5 2.5 2.5zM4 7c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z" />
      </g>
    </svg>
  );
};

export default ActivityStatusIcon;

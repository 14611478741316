import React from "react";
import "./styles/UploadSection.scss";

const UploadSection = (props) => {
  const { children, title = "Anexe aqui o material recebido." } = props;

  return (
    <div className="uploadSection">
      <span className="attachmentTitle">Anexos</span>
      <span className="attachmentSubTitle">{title}</span>

      {children}

      <span className="optionalText">Opcional</span>
    </div>
  );
};

export default UploadSection;

import React, { useContext } from 'react'
import EditIcon from '../../../components/VectorIcons/EditIcon'
import AddThesaurusIcon from '../../../components/VectorIcons/AddThesaurusIcon'
import MoreWithCircle from '../../../components/VectorIcons/MoreWithCircle'
import TableLoading from '../../../components/TableLoading/TableLoading'
import AppContext from "../../../context/appContext"

const BlockRight = (props) => {

  const { originsThesaurus, hasIntegration, _getThesaurusById, countItems } = props

  const appContext = useContext(AppContext)

  return (
    <div className="blockRight">
      {!originsThesaurus.loading ? (
        <div className="containerThesaurus">
          <div className="blockRightIcons">
            <div
              className="updateOrigins"
              onClick={() => appContext.setCurrentStepBox('updateOrigins')}
            >
              <EditIcon/>
            </div>

            {!hasIntegration && (
              <div
                className="registerThesaurus"
                onClick={() =>
                  appContext.setCurrentStepBox('registerThesaurus')
                }
              >
                <AddThesaurusIcon/>
              </div>
            )}
          </div>
          <h3>{originsThesaurus.title}</h3>

          <ul
            className={`thesaurusList ${countItems(
              originsThesaurus.list.length
            )}`}
          >
            {originsThesaurus.list.map((item, index) => {
              return (
                <li
                  className="thesaurusItem"
                  key={index}
                  onClick={() => _getThesaurusById(item.id)}
                >
                  <MoreWithCircle/>
                  {item.name || item.value}
                </li>
              )
            })}
          </ul>
        </div>
      ) : (
        <>{!!originsThesaurus.list.length && <TableLoading/>}</>
      )}
    </div>
  )
}

export default BlockRight

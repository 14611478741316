import React from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "../../VectorIcons/FeatherIcon";
import ScriptIcon from "../../VectorIcons/ScriptIcon";

const LogoLink = () => {
  return (
    <Link to="/">
      <div className="logoIcon">
        <ScriptIcon width="108" />
      </div>
      <div className="featherIcon">
        <FeatherIcon />
      </div>
    </Link>
  );
};

export default LogoLink;

import React, { useState, useEffect } from "react";
import "./styles/ConfirmationBox.scss";
import SimpleButton from "../SimpleButton/SimpleButton";

const ConfirmationBox = (props) => {
  const [stateBox, setStateBox] = useState({ open: false });

  const {
    title,
    text,
    open,
    toggleScriptBox,
    handleEvent,
    closedBox,
    textButton,
    buttonColor,
    children,
  } = props;

  useEffect(() => {
    setTimeout(() => {
      setStateBox({ open: open });
    }, 100);
  }, [open]);

  const _handleEvent = () => {
    handleEvent();
    closedBox();
  };

  return (
    <div className={`ConfirmationBox ${stateBox.open ? "active" : ""}`}>
      <div className="ConfirmationBoxBackground" onClick={toggleScriptBox} />
      <div className={`ConfirmationWindow ${!!children ? "largeWindow" : ""}`}>
        <h2 className="confirmationTitle">{title}</h2>

        {!!text && <p className="confirmationMessage">{text}</p>}

        <div className="confirmationButtons">
          {!!children ? (
            children
          ) : (
            <>
              <SimpleButton
                text="Cancelar"
                classButton="buttonCancel large"
                dataQA="button-prospect-edit"
                clickEvent={() => closedBox()}
              />

              <SimpleButton
                text={textButton}
                classButton="buttonConfirm large"
                dataQA="button-prospect-edit"
                buttonColor={buttonColor}
                clickEvent={() => _handleEvent()}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationBox;

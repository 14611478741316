import React, { useEffect, useState } from "react";
import ScriptBoxCaller from "../../components/ScriptBoxCaller/ScriptBoxCaller";
import { getService } from "../../services/script-api";
import AsyncLoading from "../../components/AsyncLoading/AsyncLoading";
import AsyncSelect from "react-select/async";
import "./style/ContentCreateProspects.scss";
import AddProspectIcon from "../../components/VectorIcons/AddProspectIcon";
import Option from "./Option/Option";
import MultiValueLabel from "./MultiValueLabel/MultiValueLabel";
import ErrorIcon from "../VectorIcons/ErrorIcon";
import SimpleScriptBox from "../../views/ProjectSheet/SimpleScriptBox/SimpleScriptBox";
import CreateAndEditProspectsAndCompanies from "../../views/ProspectsAndProductions/CreateAndEditProspectsAndCompanies/CreateAndEditProspectsAndCompanies";

const ContentCreateProspects = (props) => {
  const {
    getCreators,
    label,
    placeholder,
    isError,
    handleBlur,
    data,
    createFieldLabel,
  } = props;

  let initialState = { loading: true, data: [], hasOptions: false };

  const [stateProspects, setStateProspects] = useState(initialState);
  const [currentProspects, setCurrentProspects] = useState([]);
  const [showNewProspects, setShowNewProspects] = useState(false);

  const searchProspectOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length > 0) {
      getService("projects/data/creators", { term: inputValue }).then(
        (list) => {
          const _list = list.data.map((item) => ({
            value: item.id,
            label: item.name,
            photo: item.photo,
            role: item.role,
            origin: item.origin,
            suggesterId: item.id,
          }));
          setStateProspects({
            ...stateProspects,
            data: _list,
          });
          callback(_list);
        }
      );
    }
  };

  const handleChange = (value) => {
    setCurrentProspects(!!value ? value : []);
    getCreators(!!value ? value : []);
  };

  const getProspects = (value) => {
    setCurrentProspects([...currentProspects, value]);
    getCreators([...currentProspects, value]);
  };

  useEffect(() => {
    setCurrentProspects(!!data ? data : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div
      className={`formFieldColumn ${isError ? "isError" : ""}`}
      id="ContentCreateProspects"
    >
      <label className="label">{label}</label>
      <ScriptBoxCaller
        callerText={createFieldLabel ? `+ ${createFieldLabel}` : "+ Novo Proponente"}
        event={() => setShowNewProspects(true)}
      />

      <AsyncSelect
        isMulti
        className="reactSelect"
        classNamePrefix="reactSelect"
        loadOptions={searchProspectOptions}
        options={stateProspects.data}
        onChange={handleChange}
        onBlur={handleBlur}
        components={{
          LoadingIndicator: () => <AsyncLoading />,
          MultiValueLabel,
          Option,
        }}
        noOptionsMessage={() => "Nenhum item encontrado"}
        loadingMessage={() => "Carregando..."}
        placeholder={placeholder ? placeholder : "Selecione"}
        value={currentProspects}
        openMenuOnClick={false}
      />

      {isError && (
        <>
          <div className="errorIcon">
            <ErrorIcon />
          </div>

          <span className="errorMessage">Este campo é obrigatório</span>
        </>
      )}

      {showNewProspects && (
        <SimpleScriptBox
          open={showNewProspects}
          title={createFieldLabel || "Novo Proponente"}
          icon={<AddProspectIcon color="#FFF" />}
          removePadding={true}
          toggleScriptBox={() => setShowNewProspects(false)}
        >
          <CreateAndEditProspectsAndCompanies
            closed={() => setShowNewProspects(false)}
            getProspectAndProductionCompanies={() => {}}
            path="prospects"
            name="Proponente"
            getNewItem={getProspects}
          />
        </SimpleScriptBox>
      )}
    </div>
  );
};

export default ContentCreateProspects;

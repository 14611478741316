import React from "react";
import ScriptBoxCaller from "../../../../components/ScriptBoxCaller/ScriptBoxCaller";

const IsValid = (props) => {
  const { callerEvent, callerIcon, paperTitle, msg, havePermission } = props;

  return (
    <div className="infoPaper">
      <div className="paper">
        <div className="paperTitle">
          <span>{paperTitle}</span>
          {!!callerEvent && havePermission && (
            <ScriptBoxCaller callerIcon={callerIcon} event={callerEvent} />
          )}
        </div>

        <div className="infoFieldContainer">
          <div className="infoField">
            <span className="noInfo">{msg}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsValid;

import React, { useState, useContext } from "react";
import "./styles/RegisterSuccess.scss";
import SimpleButton from "../../components/SimpleButton/SimpleButton";
import NewActivityIcon from "../../components/VectorIcons/NewActivityIcon";
import MoreIcon from "../../components/VectorIcons/MoreIcon";
import { Redirect, Link } from "react-router-dom";
import CheckIcon from "../../components/VectorIcons/CheckIcon";
import AppContext from "../../context/appContext";

const RegisterSuccess = (props) => {
  const { id, title, type } = props;

  const [stateRedirect, setStateRedirect] = useState(false);
  const [stateRedirectTo, setStateRedirectTo] = useState("");

  const appContext = useContext(AppContext);

  const handleRedirect = (url) => {
    setStateRedirect(true);
    setStateRedirectTo(url);
  };

  const handleTypeMessage = () => {
    let message = {
      dramaturgia: "O projeto",
      variedades: "O projeto",
      pesquisa: "A pesquisa",
      "obra-literaria": "A obra literaria",
    };

    return message[type];
  };

  return (
    <div id="RegisterSuccess">
      <div className="successIconContainer">
        <div className="container">
          <div className="successIcon">
            <CheckIcon color="#FFF" size={50} />
          </div>
        </div>
      </div>

      <div className="container">
        <h2>
          {handleTypeMessage()}{" "}
          <Link to={appContext.redirectToSheet(type, id)}>{title}</Link> foi
          cadastrado com sucesso!
        </h2>

        <p>E agora o que deseja fazer?</p>

        <div className="buttons">
          <SimpleButton
            text="Novo Cadastro"
            classButton="buttonBlue extraLarge"
            dataQA="button-prospect-edit"
            iconSide="left"
            clickEvent={() => handleRedirect("/cadastrar")}
          >
            <MoreIcon color="#FFF" />
          </SimpleButton>

          <SimpleButton
            text="Criar atividade"
            classButton="buttonGreen extraLarge"
            dataQA="button-prospect-edit"
            iconSide="left"
            clickEvent={() => handleRedirect("/atividades")}
          >
            <NewActivityIcon />
          </SimpleButton>
        </div>

        {stateRedirect && <Redirect to={stateRedirectTo} />}
      </div>
    </div>
  );
};

export default RegisterSuccess;

import React from "react";
import "./styles/DeleteTooltip.scss";

const DeleteTooltip = (props) => {
  const { type } = props;

  const tooltipTypeMessage = (messageType) => {
    const types = {
      activity:
        "Este anexo está vinculado à atividade e não pode ser excluído. Para deletar o arquivo, é necessário editar a atividade.",
      release:
        "Este anexo está marcado como liberado e não pode ser excluído. Para deletar o arquivo, é necessário retirar a liberação.",
    };
    return types[messageType];
  };

  return (
    <div className="deleteTooltip">
      <p>{tooltipTypeMessage(type)}</p>
    </div>
  );
};

export default DeleteTooltip;

import React, { useState, useContext, useEffect } from "react";
import "./styles/ActivityEvaluation.scss";
import AppContext from "../../../context/appContext";
import EvaluationHeader from "./EvaluationHeader/EvaluationHeader";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import EvaluationField from "./EvaluationField/EvaluationField";
import EvaluationPaper from "./EvaluationPaper/EvaluationPaper";
import EvaluationTitle from "./EvaluationTitle/EvaluationTitle";
import ContentInfo from "./ContentInfo/ContentInfo";
import CheckIcon from "../../../components/VectorIcons/CheckIcon";
import setTypesFields from "./TypesFields/TypesFields";
import ActivitiesContext from "../../../context/activitiesContext";
import { getService, putService } from "../../../services/script-api";
import TableLoading from "../../../components/TableLoading/TableLoading";
import _ from "lodash";
import ViewFile from "../../../components/ViewFile/ViewFile";

const ActivityEvaluation = (props) => {
  const activitiesContext = useContext(ActivitiesContext);

  const type = props.match.params.type;
  const evaluationType = activitiesContext.makeTypeEvaluations(
    props.match.params.evaluation
  );
  const evaluationId = props.match.params.evaluationId;

  const appContext = useContext(AppContext);

  const [prevState, setPrevState] = useState([]);
  const [stateFields, setStateFields] = useState([]);
  const [stateTitle, setStateTitle] = useState(evaluationType);
  const [title, setTitle] = useState(evaluationType);
  const [stateEvaluationData, setStateEvaluationData] = useState({
    loading: true,
    list: [],
  });

  const normalTexts = [
    "storylineLogline",
    "resume",
    "strongPoints",
    "complete",
    "attentionPoints",
    "conclusion",
    "adequation",
    "plotAnalysis",
    "resume",
    "keyWord",
    "observation",
    "guestSummary",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    getEvaluation();
    appContext.setShowHeader(false);

    return () => {
      appContext.setShowHeader(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEvaluation = () => {
    appContext.setShowGlassLoading(true);

    getService(
      `activities/evaluations/${returnEndpointName(
        evaluationType
      )}/${evaluationId}`
    ).then((res) => {
      appContext.setShowGlassLoading(false);
      setStateEvaluationData({
        ...stateEvaluationData,
        loading: false,
        list: res.data,
      });

      if (!!res.data.title) {
        setStateTitle(res.data.title);
        setTitle(res.data.title);
      }
    });
  };

  useEffect(() => {
    compileFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateEvaluationData]);

  const handleEvaluationDate = () => {
    if (stateEvaluationData.list.fixedEvaluationDate) {
      return stateEvaluationData.list.evaluationDate;
    } else {
      return (
        stateEvaluationData.list.updatedAt || stateEvaluationData.list.createdAt
      );
    }
  };

  const mountDate = (date) => {
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth();
    const day = new Date(date).getDate();
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    const seconds = new Date().getSeconds();

    const mountedDate = new Date(year, month, day, hours, minutes, seconds);
    const finalDate = new Date(
      mountedDate.getTime() - mountedDate.getTimezoneOffset() * 60000
    ).toISOString();

    return finalDate;
  };

  const getDate = (date) => {
    const newState = { ...stateEvaluationData };
    if (!!date) {
      newState.list.evaluationDate = mountDate(date);
      newState.list.fixedEvaluationDate = true;
      setStateEvaluationData(newState);
      sendEvaluation();
    }
  };

  const verifyNormalText = (item) =>
    normalTexts.includes(item[0]) &&
    !!item[1] &&
    (!!item[1].title || !!item[1].text);

  const normalText = [];

  const mountNormalTexts = (item) => {
    if (verifyNormalText(item)) {
      normalText.push({
        name: returnFieldName(item[0]),
        value: item[1],
      });
    }
  };

  const freeTexts = [];
  const moutFreeTexts = (item) => {
    if (!!item && item[0] === "freeTexts") {
      item[1].forEach((e) => {
        freeTexts.push({
          name: returnFieldName(item[0]),
          value: e,
        });
      });
    }
  };

  const compileFields = () => {
    Object.entries(stateEvaluationData.list).forEach((item) => {
      mountNormalTexts(item);
      moutFreeTexts(item);
    });

    const combined = [...normalText, ...freeTexts];
    const compiled = [];

    combined.forEach((field) => {
      compiled.push({
        ...field,
        order: field.value.order,
      });
    });

    const ordered = _.orderBy(compiled, ["order"], ["asc"]);
    setStateFields(ordered);
  };

  const returnEndpointName = (type) => {
    const endpoints = {
      "Parecer da Criação": "opnions",
      Certificação: "certifications",
      "Relatório de Texto": "textreports",
      "Relatório de Vídeo": "videoreports",
    };

    return endpoints[type];
  };

  const opnionsCertificationsSchema = {
    storylineLogline: {
      order: 0,
      title: "",
      text: "",
    },
    resume: {
      order: 0,
      title: "",
      text: "",
    },
    conclusion: {
      evaluation: 1,
      order: 0,
      title: "",
      text: "",
    },
    strongPoints: {
      order: 0,
      title: "",
      text: "",
    },
    adequation: {
      hourId: null,
      formatId: null,
      plataformId: null,
      order: 0,
      title: "",
      text: "",
    },
    complete: {
      order: 0,
      title: "",
      text: "",
    },
    attentionPoints: {
      order: 0,
      title: "",
      text: "",
    },
    title: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    evaluationDate: new Date(),
    fixedEvaluationDate: false,
    freeTexts: [
      {
        order: 0,
        title: "",
        text: "",
      },
    ],
  };

  const textReportSchema = {
    plotAnalysis: {
      order: 0,
      title: "",
      text: "",
    },
    resume: {
      order: 0,
      title: "",
      text: "",
    },
    keyWord: {
      order: 0,
      title: "",
      text: "",
    },
    attentionPoints: {
      order: 0,
      title: "",
      text: "",
    },
    observation: {
      order: 0,
      title: "",
      text: "",
    },
    title: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    evaluationDate: new Date(),
    fixedEvaluationDate: false,
    freeTexts: [
      {
        order: 0,
        title: "",
        text: "",
      },
    ],
  };

  const videoReportSchema = {
    guestSummary: {
      order: 0,
      title: "",
      text: "",
    },
    keyWord: {
      order: 0,
      title: "",
      text: "",
    },
    attentionPoints: {
      order: 0,
      title: "",
      text: "",
    },
    observation: {
      order: 0,
      title: "",
      text: "",
    },
    title: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    evaluationDate: new Date(),
    fixedEvaluationDate: false,
    freeTexts: [
      {
        order: 0,
        title: "",
        text: "",
      },
    ],
  };

  const returnFieldTypes = (contentType, activityType) => {
    const types = {
      dramaturgia: {
        "Parecer da Criação": setTypesFields.dramaturgyParecerFieldTypes,
        Certificação: setTypesFields.dramaturgyCertificacaoFieldTypes,
        "Relatório de Texto": setTypesFields.dramaturgyRelatorioTextoFieldTypes,
        "Relatório de Vídeo": setTypesFields.dramaturgyRelatorioVideoFieldTypes,
      },
      variedades: {
        "Parecer da Criação": setTypesFields.varietyParecerFieldTypes,
        Certificação: setTypesFields.varietyCertificacaoFieldTypes,
        "Relatório de Texto": setTypesFields.varietyRelatorioTextoFieldTypes,
        "Relatório de Vídeo": setTypesFields.varietyRelatorioVideoFieldTypes,
      },
      "obra-literaria": {
        "Parecer da Criação": setTypesFields.dramaturgyParecerFieldTypes,
        "Relatório de Texto": setTypesFields.dramaturgyRelatorioTextoFieldTypes,
      },
    };

    return types[contentType][activityType];
  };

  const returnNameKey = (name) => {
    const _state = {
      Storyline: "storylineLogline",
      Logline: "storylineLogline",
      "Resumo do Parecer": "resume",
      "Pontos Fortes": "strongPoints",
      "Pontos de Atenção": "attentionPoints",
      "Parecer Completo": "complete",
      Conclusão: "conclusion",
      Adequação: "adequation",
      "Texto Livre": "freeTexts",
      "Análise de trama": "plotAnalysis",
      Resumo: "resume",
      Observações: "observation",
      "Palavras-chave": "keyWord",
      "Resumo de Convidados": "guestSummary",
    };

    return _state[name];
  };

  const returnFieldName = (key) => {
    const _state = {
      storylineLogline:
        type === "dramaturgia" || type === "obra-literaria"
          ? "Storyline"
          : "Logline",
      resume:
        evaluationType === "Parecer da Criação" ||
        evaluationType === "Certificação"
          ? "Resumo do Parecer"
          : "Resumo",
      strongPoints: "Pontos Fortes",
      attentionPoints: "Pontos de Atenção",
      complete: "Parecer Completo",
      conclusion: "Conclusão",
      adequation: "Adequação",
      freeTexts: "Texto Livre",
      plotAnalysis: "Análise de trama",
      observation: "Observações",
      keyWord: "Palavras-chave",
      guestSummary: "Resumo de Convidados",
    };

    return _state[key];
  };

  const handleAddField = (fieldName) => {
    const fields = [...stateFields];
    let value = {
      name: fieldName,
      value: {
        order: 0,
        title: "",
        text: "",
      },
    };
    let mountFieldName = {
      Conclusão: { ...value, value: { ...value.value, evaluation: 1 } },
      Adequação: {
        ...value,
        value: {
          ...value.value,
          hourId: null,
          formatId: null,
          plataformId: null,
        },
      },
    };

    setStateFields(
      !!mountFieldName[fieldName]
        ? [...fields, mountFieldName[fieldName]]
        : [...fields, value]
    );
  };

  const handleRemoveField = (index) => {
    appContext.setShowGlassLoading(true);
    const fields = stateFields.filter((item, i) => i !== index);
    setStateFields([]);
    setPrevState(fields);
  };

  useEffect(() => {
    if (!!prevState.length) {
      setStateFields(prevState);
    }
    appContext.setShowGlassLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevState]);

  const setStateTypes = (key, value, index) => {
    const newState = [...stateFields];

    newState[index]["order"] = index;
    newState[index]["value"]["order"] = index;
    newState[index]["value"]["title"] = value.title;
    newState[index]["value"]["text"] = value.text;

    if (key === "Conclusão") {
      newState[index]["value"]["evaluation"] = value.evaluation;
    }
    if (key === "Adequação") {
      newState[index]["value"]["hourId"] = value.hourId;
      newState[index]["value"]["formatId"] = value.formatId;
      newState[index]["value"]["plataformId"] = value.plataformId;
    }
    setStateFields(newState);
  };

  const onFocus = () => sendEvaluation();

  const returnPutSchema = (activityType) => {
    const schemas = {
      "Parecer da Criação": opnionsCertificationsSchema,
      Certificação: opnionsCertificationsSchema,
      "Relatório de Texto": textReportSchema,
      "Relatório de Vídeo": videoReportSchema,
    };

    return schemas[activityType];
  };

  const makeObjEvaluation = () => {
    const fields = { ...returnPutSchema(evaluationType) };
    const freeTextsArray = [];

    stateFields.forEach((field) => {
      if (field.name !== "Texto Livre") {
        fields[returnNameKey(field.name)] = {
          ...field.value,
          title: field.name,
        };
      }

      if (field.name === "Texto Livre") {
        freeTextsArray.push(field.value);
      }
    });

    return {
      ...returnPutSchema(evaluationType),
      ...fields,
      freeTexts: freeTextsArray,
      title: stateTitle,
      evaluationDate: stateEvaluationData.list.evaluationDate,
      fixedEvaluationDate: stateEvaluationData.list.fixedEvaluationDate,
    };
  };

  const sendEvaluation = (e) => {
    if (!!e) e.preventDefault();

    putService(
      `activities/evaluations/${returnEndpointName(
        evaluationType
      )}/${evaluationId}`,
      makeObjEvaluation()
    )
      .then(() => {
        appContext.sendMessage(`${evaluationType} editado(a) com sucesso!`);
        setTitle(stateTitle);
        getEvaluation();
      })
      .catch(() => {
        appContext.sendMessage(`Erro ao editar ${evaluationType}!`, "error");
      });
  };

  useEffect(() => {
    setInterval(() => {
      // sendEvaluation()
    }, 5000);
  }, []);

  if (stateEvaluationData.loading) return <TableLoading />;

  return (
    <>
      {appContext.current.step === "ViewerFileBox" && (
        <ViewFile url={appContext.file?.url} title={appContext.file?.title} />
      )}
      <form onSubmit={(e) => sendEvaluation(e)}>
        <div className="activityEvaluation">
          <EvaluationHeader
            type={title || stateEvaluationData.list.title}
            userName={stateEvaluationData.list.user?.name}
            isEditable={stateEvaluationData.list.editable}
          />

          <EvaluationPaper>
            <span className="savedAt">
              <CheckIcon color="#9395A3" size={15} />
              {`Salvo em `}
              {appContext.formatDate(
                appContext.convertDatePtBr(stateEvaluationData.list.updatedAt)
              )}
              {` às `}
              {appContext
                .convertDateAndTimePtBr(stateEvaluationData.list.updatedAt)
                .slice(10, 16)}
            </span>

            <EvaluationTitle
              title={stateTitle}
              placeholder="Escreva aqui o seu título"
              onChange={setStateTitle}
            />

            <ContentInfo
              type={type}
              projectName={stateEvaluationData.list.project.title}
              projectId={stateEvaluationData.list.project.id}
              creators={stateEvaluationData.list.project?.creators}
              suggestedBy={stateEvaluationData.list.project?.suggestedBy}
              format={stateEvaluationData.list.project.format.name}
              genres={stateEvaluationData.list.project.genres || []}
              attachments={stateEvaluationData.list.attachments}
              date={handleEvaluationDate()}
              getDate={getDate}
              isEditable={stateEvaluationData.list.editable}
            />

            <div className="fieldsContainer">
              {stateFields.map((item, index) => {
                return (
                  <EvaluationField
                    key={index}
                    index={index}
                    fieldType={item.name}
                    data={item.value}
                    setter={setStateTypes}
                    onFocus={onFocus}
                    handleRemoveField={handleRemoveField}
                    contentType={type}
                    isEditable={stateEvaluationData.list.editable}
                  />
                );
              })}
            </div>
            {stateEvaluationData.list.editable && (
              <>
                <div className="dotsSeparator">
                  <div className="dot" />
                  <div className="dot" />
                  <div className="dot" />
                </div>

                <div className="buttonsContainer">
                  {returnFieldTypes(type, evaluationType)
                    .filter(
                      (field) =>
                        !stateFields.map((i) => i.name).includes(field) &&
                        field !== "Texto Livre"
                    )
                    .map((item, index) => {
                      return (
                        <SimpleButton
                          key={index}
                          text={`+ ${item}`}
                          classButton="borderedButton"
                          clickEvent={() => handleAddField(item)}
                          dataQA=""
                        />
                      );
                    })}

                  <SimpleButton
                    text="+ Texto Livre"
                    classButton="borderedButton"
                    clickEvent={() => handleAddField("Texto Livre")}
                    dataQA=""
                  />
                </div>
              </>
            )}
          </EvaluationPaper>
        </div>
      </form>
    </>
  );
};

export default ActivityEvaluation;

import React, { useContext } from "react";
import "./styles/PhaseItem.scss";
import AppContext from "../../../../context/appContext";

const PhaseItem = (props) => {
  const { phaseTitle, phaseInfo } = props;

  const appContext = useContext(AppContext);

  return (
    <div className="phaseItem">
      <span className="phaseTitle">
        <div className="phaseDot" />
        {phaseTitle}
      </span>

      <div className="phaseBlockContainer">
        {!!phaseInfo &&
          phaseInfo.map((phase, index) => {
            return (
              <React.Fragment key={index}>
                {phase.phasePeriod.map((item, i) => {
                  return (
                    <div className="phaseBlock" key={i}>
                      <div className="phaseName">{item.title}</div>
                      <div className="phaseInfo">
                        <span
                          className={`phasePeriod ${
                            item.status === "Em andamento" ? "inProgress" : ""
                          }`}
                        >
                          {`${appContext.formatDate(
                            appContext.convertDatePtBr(item.beginDate)
                          )}`}
                          {!!item.endDate && " - "}
                          {!!item.endDate &&
                            `${appContext.formatDate(
                              appContext.convertDatePtBr(item.endDate)
                            )}`}
                        </span>
                      </div>
                      <span
                        className={`phaseStatus ${
                          item.status === "Em andamento" ? "inProgress" : ""
                        }`}
                      >
                        {item.status}
                      </span>
                    </div>
                  );
                })}
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default PhaseItem;

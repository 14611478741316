import React from "react";

const ConflictsNotFoundIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="140"
      viewBox="0 0 140 140"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#999BE0"
          d="M49 32.995L30.38 32.995 43.447 0.438 11.107 0.438 0 55.028 12.74 55.028 5.553 98.438z"
          transform="translate(49 24.063)"
        />
        <path
          fill="#C0C3F8"
          fillRule="nonzero"
          d="M70 0C31.34 0 0 31.34 0 70s31.34 70 70 70 70-31.34 70-70S108.66 0 70 0zM12.727 70c0-13.523 4.698-25.941 12.535-35.738l80.478 80.476c-9.797 7.837-22.217 12.535-35.74 12.535-31.63 0-57.273-25.643-57.273-57.273zm102.011 35.74L34.262 25.262C44.059 17.425 56.477 12.727 70 12.727c31.63 0 57.273 25.643 57.273 57.273 0 13.523-4.698 25.943-12.535 35.74z"
        />
      </g>
    </svg>
  );
};

export default ConflictsNotFoundIcon;

const getReduce = (action, state) => {
  const stateObject = { 
    ...state,
    ...{ [action.key]: { ...state[action.key], result: action.payload.data} },  
  };

  if (action.key === "characters") stateObject[action.key].error = action.payload.error;

  return stateObject;
};

const loadingReduce = (action, state) => {
  const newState = { ...state };
  newState[action.payload.subject]["loading"] = action.payload.active;
  return newState;
};

export default (state, action) => {
  let _state = {
    loading: () => loadingReduce(action, state),
    list: () => getReduce(action, state),
    default: () => getReduce(action, state),
  };

  return _state[action.action]();
};

import React from "react";
import "./styles/StatusColorBall.scss";

const StatusColorBall = (props) => {
  const { status } = props;

  const returnStatusColor = (status) => {
    const colors = {
      "Não Iniciada": "transparent",
      "Em Andamento": "#EFC953",
      Pausada: "#E57300",
      Cancelada: "#45413F",
      "Em Revisão": "#2D76D4",
      Concluída: "#6EC07A",
    };
    return colors[status];
  };

  return (
    <div
      className="statusColorBall"
      style={{
        backgroundColor: returnStatusColor(status),
        border: `${status === "Não Iniciada" ? "1px solid #AABEE7" : "none"}`,
      }}
    />
  );
};

export default StatusColorBall;

import React from "react";

const DetailedTelevisionIcon = () => {
  return (
    <svg width="200" height="180" fill="none" color="#fff" size="24">
      <circle cx="108" cy="108" r="64" fill="#DADDFE"></circle>
      <path
        d="M67.845 160v-8.66h5.774L71.144 160M148.258 160v-8.66h-5.773l2.474 8.66M105.377 64.401a.67.67 0 01-.609-.387L90.581 33.713a.67.67 0 011.215-.572l14.188 30.304a.673.673 0 01-.607.956zM111.682 62.65a.67.67 0 01-.632-.894L132.595.449a.671.671 0 011.265.445L112.314 62.2a.668.668 0 01-.632.451z"
        fill="#999BE0"
      ></path>
      <path
        d="M125.364 67.288H90.28l.038-.085a14.307 14.307 0 0113.093-8.54h8.821a14.308 14.308 0 0113.094 8.54l.038.085z"
        fill="#999BE0"
      ></path>
      <path
        d="M149.302 151.376a1078.794 1078.794 0 01-82.504 0c-5.71-.218-10.378-4.615-10.957-10.288-2.18-21.361-3.165-42.733-2.747-64.196.118-6.058 4.91-11.002 10.977-11.249a1073.656 1073.656 0 0187.962 0c6.065.247 10.858 5.191 10.976 11.249.418 21.463-.569 42.835-2.747 64.196-.58 5.673-5.249 10.07-10.96 10.288z"
        fill="#C0C3F8"
      ></path>
      <path
        d="M138.673 132.751a388.976 388.976 0 01-62.287 0c-5.665-.454-10.304-4.757-11.135-10.38-1.741-11.782-1.741-23.32 0-35.102.831-5.622 5.47-9.924 11.135-10.378a388.743 388.743 0 0162.287 0c5.666.454 10.304 4.756 11.134 10.378 1.743 11.782 1.743 23.32 0 35.102-.83 5.623-5.468 9.926-11.134 10.38z"
        fill="#DBDDFA"
      ></path>
      <path
        d="M65.251 90.13c.831-5.624 5.47-9.926 11.136-10.38a388.74 388.74 0 0162.286 0c5.666.454 10.304 4.756 11.134 10.38.787 5.324 1.216 10.6 1.292 15.87.091-6.39-.338-12.774-1.292-19.23-.83-5.623-5.468-9.925-11.134-10.38a388.74 388.74 0 00-62.286 0c-5.666.454-10.305 4.757-11.136 10.38a119.35 119.35 0 00-1.29 19.23c.076-5.27.503-10.546 1.29-15.87zM146.572 145.46H69.949a4.667 4.667 0 01-4.667-4.667v-.094a4.667 4.667 0 014.667-4.667h76.623a4.667 4.667 0 014.668 4.667v.094a4.667 4.667 0 01-4.668 4.667z"
        fill="#D2D5FD"
      ></path>
      <path
        d="M73.77 140.782a3.235 3.235 0 11-6.47 0 3.235 3.235 0 016.47 0zM80.622 144.017a3.235 3.235 0 110-6.47 3.235 3.235 0 010 6.47zM92.996 138.495a3.234 3.234 0 11-4.574 4.574 3.234 3.234 0 014.574-4.574zM146.45 141.239h-4.071a.732.732 0 01-.734-.732v-.015c0-.405.327-.733.734-.733h4.071c.405 0 .732.328.732.733v.015a.732.732 0 01-.732.732z"
        fill="#C0C3F8"
      ></path>
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x="63"
        y="75"
        width="89"
        height="59"
      >
        <path
          d="M138.673 132.751a388.975 388.975 0 01-62.287 0c-5.665-.454-10.304-4.757-11.135-10.38-1.741-11.782-1.741-23.32 0-35.102.831-5.622 5.47-9.924 11.135-10.378a388.742 388.742 0 0162.287 0c5.666.454 10.304 4.756 11.134 10.378 1.743 11.782 1.743 23.32 0 35.102-.83 5.623-5.468 9.926-11.134 10.38z"
          fill="#ACE1DA"
        ></path>
      </mask>
      <g mask="url(#prefix__a)">
        <path fill="#D2D5FD" d="M53 107.391h112v35.304H53z"></path>
        <ellipse
          opacity="0.8"
          cx="107.833"
          cy="122.875"
          rx="17.238"
          ry="7.182"
          fill="#fff"
        ></ellipse>
        <path
          opacity="0.25"
          d="M90.475 91.827l-3.09-2.847-.029.007-1.237-1.128-.06-.017.065-.025L72.963 75.69 60.253 63.98l-6.477 3.442-.53.118 7.762 11.739 29.373 44.428h34.69L90.475 91.827z"
          fill="#F4F4F4"
        ></path>
        <path
          opacity="0.25"
          d="M125.337 91.827l3.091-2.847.028.007 1.237-1.128.06-.017-.065-.025 13.161-12.127 12.71-11.711 6.476 3.442.53.118-7.761 11.739-29.373 44.428h-34.69l34.596-31.879z"
          fill="#F4F4F4"
        ></path>
        <path
          opacity="0.25"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.424 48.864l-7.006-3.56-13.423 76.026a3.294 3.294 0 00-.4 1.545c0 .165.014.329.04.492l-.04.226h.085c.865 3.629 8.215 6.464 17.153 6.464 8.939 0 16.288-2.835 17.153-6.464h.085l-.041-.222c.027-.164.041-.33.041-.496 0-.544-.145-1.074-.42-1.584l-13.227-72.427z"
          fill="#F2F2F2"
        ></path>
      </g>
    </svg>
  );
};

export default DetailedTelevisionIcon;

import React from "react";
import "../styles/UploadedFile.scss";
import CustomDatePicker from "../../CustomDatePicker/CustomDatepicker";
import NumberInput from "../../NumberInput/NumberInput";
import FieldsetSelect from "../../FieldsetSelect/FieldsetSelect";
import FieldsetNumberMasked from "../../FieldsetNumberMasked/FieldsetNumberMasked";

const UploadInputDefault = (props) => {
  const {
    handleBlur,
    thesaurusFileType,
    state,
    getType,
    getVersion,
    getDate,
    stateFileType,
    getBlock,
    getChapter,
  } = props;

  const newDate = state.receivedAt ? new Date(state.receivedAt) : new Date();

  return (
    <>
      <div className={`fileInfo ${!thesaurusFileType ? "onlyDatepicker" : ""}`}>
        {!!thesaurusFileType && (
          <FieldsetSelect
            label="Tipo"
            name="attachmentTypeId"
            placeholder="Selecione um tipo"
            darkBackgroundColor={true}
            origin="1"
            data={thesaurusFileType?.map((item) => item?.id !== 1211 && item)}
            selected={state.attachmentTypeId}
            handleChange={getType}
            handleBlur={handleBlur}
          />
        )}

        {!!thesaurusFileType && (
          <NumberInput
            label="Versão"
            getVersion={getVersion}
            darkBackgroundColor={true}
          />
        )}

        <CustomDatePicker
          label="Recebido Em"
          name="attachmentReceivedAt"
          darkBackgroundColor={true}
          handleChangeDate={getDate}
          maxDate={new Date()}
          date={(e) => (!!e ? e : newDate)}
        />
      </div>

      {(stateFileType === "Capítulo/Episódio" ||
        stateFileType === "Episódio") && (
        <div className="chapter">
          <NumberInput
            label="Nº DO BLOCO"
            darkBackgroundColor={true}
            number={state.blockNumber === "" ? "" : parseInt(state.blockNumber)}
            getVersion={getBlock}
          />

          <FieldsetNumberMasked
            mask="9999"
            autoComplete="off"
            name="name"
            label="CAPÍTULO(S)"
            type="text"
            placeholder="Digite um número entre 1 e 9999"
            handleChange={getChapter}
            value={state.chapterNumber}
            isValid={true}
            msgError="Este campo é obrigatório"
            darkBackgroundColor={true}
          />
        </div>
      )}
    </>
  );
};

export default UploadInputDefault;

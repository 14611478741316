import React, { useContext, useState } from "react";
import AppContext from "../../../context/appContext";
import "./styles/TableListFile.scss";
import TableLoading from "../../../components/TableLoading/TableLoading";
import EditIcon from "../../../components/VectorIcons/EditIcon";
import DownloadIcon from "../../../components/VectorIcons/DownloadIcon";
import DeleteIcon from "../../../components/VectorIcons/DeleteIcon";
import ListCount from "../../../components/ListCount/ListCount";
import SimpleScriptBox from "../SimpleScriptBox/SimpleScriptBox";
import RegisterContext from "../../../context/registerContext";
import DeleteTooltip from "./DeleteTooltip/DeleteTooltip";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";

const TableListFilesResearch = (props) => {
  const {
    handleEditFile,
    data,
    loading,
    handleConfirmationDeleteFile,
    havePermission,
  } = props;
  const appContext = useContext(AppContext);
  const registerContext = useContext(RegisterContext);

  const [state, setState] = useState(false);

  const returnColor = (count) => !!count ? "#7f808e" : "#ec1045"

  return (
    <section id="TableListFiles">
      <div className="filesListCounter">
        <ListCount
          total={data.length}
          text={`${
            data.length > 1 ? "Anexos encontrados" : "Anexo encontrado"
          }`}
        />
      </div>

      <div className="tableContainer researchFilesTable">
        {loading ? (
          <TableLoading />
        ) : (
          data.length > 0 && (
            <>
              <div className="tableList">
                <ul className="itemsList">
                  <li className="headerList">
                    <span className="headerName">Arquivo</span>
                    <span className="headerReceivedAt">Recebido em</span>
                    <span className="headerActions" />
                  </li>

                  {data.map((file, index) => {
                    return (
                      <li className="groupList" key={index}>
                        {
                          <div className="listItem mainItem">
                            <span className="lightText fileName">
                              {" "}
                              {file.fileName}{" "}
                            </span>
                            <span className="lightText receivedAt">
                              {" "}
                              {appContext.convertDatePtBr(file.receivedAt)}{" "}
                            </span>

                            <div className="actions">
                              {havePermission && (
                                <span
                                  className="actionItem"
                                  onClick={() => handleEditFile(file)}
                                >
                                  <EditIcon />
                                </span>
                              )}

                              <span
                                className="actionItem download"
                                onClick={() =>
                                  registerContext.getUrlS3(
                                    file.fileId,
                                    file.fileName
                                  )
                                }
                              >
                               
                                <DownloadIcon />
                              </span>

                              {havePermission && (
                                <span
                                  className={`actionItem ${
                                    file.activityCount > 0 ? "blockDelete" : ""
                                  }`}
                                  onClick={() => {
                                    file.activityCount === 0 &&
                                      handleConfirmationDeleteFile(file);
                                  }}
                                >
                                  {file.activityCount > 0 ? (
                                    <CustomTooltip
                                      tipLabel={
                                        <DeleteIcon
                                          color={returnColor(file.activityCount)}
                                        />
                                      }
                                      dataTip={
                                        <DeleteTooltip
                                          activities={file.activityCount}
                                        />
                                      }
                                    />
                                  ) : (
                                    <DeleteIcon
                                      color={returnColor(file.activityCount)}
                                    />
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                        }
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          )
        )}
      </div>

      {state && (
        <SimpleScriptBox open={state} toggleScriptBox={() => setState(false)}>
          <div onClick="">SIM</div>
          <div onClick="">NÃO</div>
        </SimpleScriptBox>
      )}
    </section>
  );
};

export default TableListFilesResearch;

import React, { useContext } from "react";
import "./styles/CreatorName.scss";
import AppContext from "../../../../context/appContext";
import Highlighter from "react-highlight-words";
import * as latinize from "latinize";

const CreatorName = (props) => {
  const { index, creator, length } = props;

  const appContext = useContext(AppContext);

  return (
    <span className="creatorName">
      <Highlighter
        highlightClassName="creatorName markMatch"
        searchWords={appContext.checkPronouns(appContext.searchTerm)}
        autoEscape={true}
        textToHighlight={creator.name}
        sanitize={latinize}
      />

      {length > index + 1 && <span className="separator">|</span>}
    </span>
  );
};

export default CreatorName;

import React, { createContext, useReducer, useEffect } from "react";
import { getService } from "../services/script-api";
import prospectsAndProductionsReduce from "./prospectsAndProductionsReduce";

const initialContext = {
  filter: "",
  currentPath: [],
  list: {
    loading: true,
    result: [],
  },
};

const ProspectsAndProductions = createContext();

const ProspectsAndProductionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(prospectsAndProductionsReduce, initialContext);

  useEffect( () => {
    if(state.filter !== "") {
      getProspectsAndProductions()
    }
    // eslint-disable-next-line
  }, [state.filter])

  const setFilter = (filter) => dispatch({ payload: filter, key: "filter", action: "filter" });
  const setCurrentPath = (path) => dispatch({ payload: path, key: "currentPath", action: "currentPath" });

  const loading = (subject, active) => {
    dispatch({ action: "loading", payload: { subject, active } });
  };

  const getProspectsAndProductions = (path, key) => {
    loading("list", true);
    getService(state.currentPath, state.filter)
      .then((result) => {
        dispatch({ payload: result, key: "list", action: "list" });
        loading("list", false);
      })
      .catch((e) => {
        dispatch({
          key: "list",
          action: "list",
          payload: { result: "", pagination: {} },
        });
        loading("list", false);
      });
  };

  return (
    <ProspectsAndProductions.Provider
      value={{
        ...state,
        setFilter,
        getProspectsAndProductions,
        setCurrentPath
      }}
    >
      {children}
    </ProspectsAndProductions.Provider>
  );
};

export { ProspectsAndProductions as default, ProspectsAndProductionsProvider };

import React from "react";
import "./styles/Financing.scss";
import NumberFormat from "react-number-format";

const Financing = (props) => {
  const { label, value } = props;

  return (
    <div className="Financing">
      <div className="label">{label}</div>
      <div className="value">
        <NumberFormat
          value={value}
          displayType={"text"}
          thousandSeparator="."
          decimalSeparator=","
          prefix={"R$"}
          renderText={(value, props) => <div {...props}>{value}</div>}
        />
        <span class="tooltipvalue">
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"R$"}
            renderText={(value, props) => <div {...props}>{value}</div>}
          />
        </span>
      </div>
    </div>
  );
};

export default Financing;

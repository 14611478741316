import React, { useState, useEffect } from "react";
import FieldsetSelect from "../../../../../components/FieldsetSelect/FieldsetSelect";
import { getService } from "../../../../../services/script-api";
import CkEditor from "../../CkEditor/CkEditor";

const Adequacy = (props) => {
  const {
    handleFocus,
    title,
    contentType,
    type,
    data,
    setter,
    index,
    isEditable,
  } = props;

  const [stateHoursOptions, setStateHoursOptions] = useState([]);
  const [stateFormatOptions, setStateFormatOptions] = useState([]);
  const [statePlatformOptions, setStatePlatformOptions] = useState([]);
  const [state, setState] = useState(data);

  const getThesaurus = (contentType, origin, setter) => {
    getService(
      `${returnEndpointName(contentType)}/thesaurus?Origins=${origin}`
    ).then((list) => {
      setter(list.data);
    });
  };

  useEffect(() => {
    getThesaurus(contentType, 24, setStateHoursOptions);
    getThesaurus(contentType, 22, setStateFormatOptions);
    getThesaurus(contentType, 26, setStatePlatformOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setter(type, { ...state, title: title }, index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const returnEndpointName = (contentType) => {
    const endpoints = {
      dramaturgia: "dramaturgies",
      variedade: "varieties",
    };
    return endpoints[contentType];
  };

  const handleChangeHours = (ev) => {
    const id = ev.nativeEvent.target.selectedIndex;
    const idText = ev.nativeEvent.target[id].text;
    const newState = {
      id: parseInt(ev.target.value),
      name: idText,
    };
    setState({ ...state, hourId: newState.id });
  };

  const handleChangeFormat = (ev) => {
    const id = ev.nativeEvent.target.selectedIndex;
    const idText = ev.nativeEvent.target[id].text;
    const newState = {
      id: parseInt(ev.target.value),
      name: idText,
    };
    setState({ ...state, formatId: newState.id });
  };

  const handleChangePlatform = (ev) => {
    const id = ev.nativeEvent.target.selectedIndex;
    const idText = ev.nativeEvent.target[id].text;
    const newState = {
      id: parseInt(ev.target.value),
      name: idText,
    };
    setState({ ...state, plataformId: newState.id });
  };

  const returnScheduleOptions = (type) => {
    const types = {
      dramaturgia: stateHoursOptions,
      variedade: stateHoursOptions,
    };

    return types[type];
  };

  const returnFormatOptions = (type) => {
    const types = {
      dramaturgia: stateFormatOptions,
      variedade: stateFormatOptions,
    };

    return types[type];
  };

  const handleChangeText = (ev, value) => {
    setState({ ...state, text: value });
  };

  return (
    <div className="fieldContainer">
      <div className="fieldHeader">
        <div className="fieldTitle">
          <span>{title}</span>
        </div>
      </div>

      <div className="adequacySelects">
        <FieldsetSelect
          label={contentType === "dramaturgia" ? "Horário" : "Turno"}
          placeholder="Selecione"
          darkBackgroundColor={true}
          data={returnScheduleOptions(contentType) || []}
          handleChange={handleChangeHours}
          handleFocus={() => handleFocus(true)}
          handleBlur={() => handleFocus(false)}
          selected={data.hourId}
          disabled={!isEditable}
        />

        <FieldsetSelect
          label="Formato"
          placeholder="Selecione"
          darkBackgroundColor={true}
          data={returnFormatOptions(contentType) || []}
          handleChange={handleChangeFormat}
          handleFocus={() => handleFocus(true)}
          handleBlur={() => handleFocus(false)}
          selected={data.formatId}
          disabled={!isEditable}
        />

        <FieldsetSelect
          label="Plataforma"
          placeholder="Selecione"
          darkBackgroundColor={true}
          data={statePlatformOptions || []}
          handleChange={handleChangePlatform}
          handleFocus={() => handleFocus(true)}
          handleBlur={() => handleFocus(false)}
          selected={data.plataformId}
          disabled={!isEditable}
        />
      </div>

      <div className="editorArea">
        {isEditable ? (
          <CkEditor
            onChange={handleChangeText}
            onFocus={() => handleFocus(true)}
            onBlur={() => handleFocus(false)}
            data={data}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: data.text }} />
        )}
      </div>
    </div>
  );
};

export default Adequacy;

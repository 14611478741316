import React, { useContext, useEffect, useState } from 'react'
import "./styles/ActivitiesCard.scss";
import ScriptBoxCaller from "../ScriptBoxCaller/ScriptBoxCaller";
import SheetActivityItem from "../../views/ProjectSheet/SheetActivityItem/SheetActivityItem";
import { getService } from '../../services/script-api'
import AppContext from "../../context/appContext"
import ScriptBox from '../ScriptBox/ScriptBox'
import ActivitiesAttachmentsList from '../../views/ProjectSheet/ActivitiesAttachmentsList/ActivitiesAttachmentsList'
import SmallLoading from '../SmallLoading/SmallLoading'

const ActivitiesCard = (props) => {

  const { cardTitle, contentType, projectId } = props;
  const appContext = useContext(AppContext)

  const [state, setState ] = useState({loading: true, list: []})

  const getProjectActivities = () => {
    getService(`projects/${projectId}/activities`, {
      types: [1, 2, 3, 4],
      status: 6,
      limit: 100,
    }).then((response) => {
      setState({
        loading: false,
        list: response.data,
      })
    })
  }

  useEffect(() => {
    getProjectActivities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (state.loading) return (<div className="centerLoading"><SmallLoading /></div>)

  return (
    <div className="infoCard">
      <div className="cardHeader">
        <span className="cardTitle">{cardTitle}</span>
      </div>

      {!!state.list.length ? (
        state.list.slice(0, 3).map((item, index) => {
          return (
            <SheetActivityItem
              key={index}
              item={item}
              contentType={contentType}
            />
          );
        })
      ) : (
        <div className="cardContent">
          <span>Não há nenhuma atividade.</span>
        </div>
      )}

      {state.list.length > 3 && (
        <div className="cardContent">
          <div className="allAttachments">
            <ScriptBoxCaller
              callerText="Ver todas as atividades"
              event={() => appContext.setCurrentStepBox('activitiesAttachments')}
            />
          </div>
        </div>
      )}

      {appContext.current.step === 'activitiesAttachments' && (
        <ScriptBox
          open={appContext.current.step === 'activitiesAttachments'}
          title="Atividades Recentes"
          size="small"
          removePadding={true}
        >
          <ActivitiesAttachmentsList
            activities={state.list}
            contentType="dramaturgia"
          />
        </ScriptBox>
      )}
    </div>
  );
};

export default ActivitiesCard;

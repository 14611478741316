import React from "react";

const AddProspectIcon = (props) => {
  const { color = "FFF" } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="3" r="2" stroke={color} strokeWidth="2"></circle>
      <path
        d="M10.45 3a1 1 0 000 2V3zM10 12a1 1 0 100 2v-2zm.45-7H17V3h-6.55v2zM18 6v5h2V6h-2zm-1 6h-7v2h7v-2zm1-1a1 1 0 01-1 1v2a3 3 0 003-3h-2zm-1-6a1 1 0 011 1h2a3 3 0 00-3-3v2z"
        fill={color}
      ></path>
      <path
        d="M12 3v1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      ></path>
      <path
        d="M9.106 17.553a1 1 0 101.788.894l-1.788-.894zM12 14l.894-.447h-1.788L12 14zm1.106 4.447a1 1 0 101.788-.894l-1.788.894zm-2.212 0l2-4-1.788-.894-2 4 1.788.894zm.212-4l2 4 1.788-.894-2-4-1.788.894z"
        fill={color}
      ></path>
      <path
        d="M6 15v4M3 19v-3c0-1.333-.2-2-1-2s-1-.667-1-2v-1c0-.333.2-1.2 1-2s2-1 2.5-1H6c.333 0 1 0 2 .5 1.265.632 1 1 2 1 .8 0 1.5-.5 3-1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      ></path>
      <path
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20 17v6M17 20h6"
      ></path>
    </svg>
  );
};

export default AddProspectIcon;

import React from "react";
import RoleIcon from "../RoleIcon/RoleIcon";
import ProspectIcon from "../VectorIcons/ProspectIcon";

const ProspectRoleIcon = () => {
  return (
    <RoleIcon backgroundColor="#DADDFE">
      <ProspectIcon color="#B2B4F1" />
    </RoleIcon>
  );
};

export default ProspectRoleIcon;

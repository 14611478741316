import React, { useState, useEffect } from "react";
import "./styles/ThesaurusDescription.scss";
import { getService } from "../../services/script-api";
import ThesaurusBook from "../VectorIcons/ThesaurusBook";
import SimpleScriptBox from "../../views/ProjectSheet/SimpleScriptBox/SimpleScriptBox";
import SmallLoading from "../SmallLoading/SmallLoading";

const ThesaurusDescription = (props) => {
  const { label, originId } = props;

  const [stateOrigin, setStateOrigin] = useState({ loading: true, data: "" });
  const [stateShowDescription, setStateShowDescription] = useState(false);

  useEffect(() => {
    !stateOrigin.data &&
      getService("thesaurus/origins").then((res) => {
        const { data } = res;
        const [origin] = data.filter((item) => item.id === originId);
        setStateOrigin({ loading: false, data: origin });
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div id="ThesaurusDescription">
      <span className="descriptionLabel">{label}</span>

      {!stateOrigin.loading ? (
        <div onClick={() => setStateShowDescription(true)}>
          <ThesaurusBook color="#0098CD" />
        </div>
      ) : (
        <SmallLoading />
      )}

      {stateShowDescription && (
        <SimpleScriptBox
          open={stateShowDescription}
          title="Thesaurus"
          removePadding={true}
          toggleScriptBox={() => setStateShowDescription(false)}
        >
          <div className="descriptionContainer">
            <h2>{stateOrigin.data.thesaurusOrigin.value}</h2>
            <p>
              {!!stateOrigin.data.description
                ? stateOrigin.data.description
                : "Não há nota de escopo cadastrada para este termo."}
            </p>
          </div>
        </SimpleScriptBox>
      )}
    </div>
  );
};

export default ThesaurusDescription;

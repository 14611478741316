const setUser = (action, state) => {
  const newState = { ...state };
  newState.me.loading = false;
  // ["Admin", "Dramaturgies", "Varieties", "Researches", "ContentCreateProspects", "ProductionCompanies",
  // "Migration", "ConflictMap", "ActivitiesOLD"]

  newState.me.user = { ...action.payload };
  // newState.me.user = {...action.payload, sectionsAllowed: ["Varieties"]}
  // newState.me.user = {...action.payload, sectionsAllowed: ["Dramaturgies"]}
  return newState;
};

export default (state, action) => setUser(action, state);

import React, { createContext, useEffect, useReducer } from "react";

import appReducer from "./appReducer";
import { getService } from "../services/script-api";
import transformDateToUTC from "../shared/utils/formatDate";

const initialContext = {
  current: {},
  message: null,
  step: [],
  showHeader: true,
  isFull: true,
  showMobileSearch: false,
  showGlassLoading: false,
  showGlassDatepicker: false,
  isRippleEffect: true,
  currentOriginFormat: {
    result: [],
  },
  currentTypePath: "contents/filter",
  currentDataOrigin: [],
  currentOriginAssociation: [],
  currentOriginFileType: [],
  filter: "",
  file: { url: "", title: "" },
  teste: {},
  redirect: false,
  countTypeSearch: null,
  searchListHeader: {
    loading: "",
    pagingInfo: [],
  },
  searchList: {
    loading: "",
    result: [],
  },
  isParameterized: false,
  currentTypeSearch: "",
  searchTerm: "",
  isQuotesDouble: false,
  isChapter: false,
};

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialContext);

  const goTo = (url, type) => window.open(url, type);

  const setCurrentStepBox = (step) => {
    setIsRippleEffect(step === "closed");
    setDispatch({ step: step }, "current");
  };

  const setCurrentFileUrl = (fileId, fileName) => {
    setDispatch({ url: "", title: "" }, "file");
    getService(`contents/attachments/presignedurl/download`, {
      fileId: fileId,
    }).then((res) => {
      setDispatch({ url: res.data.url, title: fileName }, "file");
    });
  };

  const setShowHeader = (bool) => setDispatch(bool, "showHeader");

  const setShowFullHeader = (bool) => setDispatch(bool, "isFull");

  const setShowMobileSearch = (bool) => setDispatch(bool, "showMobileSearch");

  const setShowGlassLoading = (bool) => setDispatch(bool, "showGlassLoading");

  const setIsChapter = (bool) => {
    return setDispatch(bool, 'isChapter');
  }

  const setShowGlassDatepicker = (bool) =>
    setDispatch(bool, "showGlassDatepicker");

  const setIsRippleEffect = (bool) => setDispatch(bool, "isRippleEffect");

  const sendMessage = (text, type) => {
    let msg = null;
    if (text) msg = { text, type: type ? type : "success" };
    setDispatch(msg, "message");

    window.setTimeout(() => {
      setDispatch(null, "message");
    }, 6500);
  };

  const closeMessage = () => setDispatch(null, "message");

  const getTypeThesaurus = (origin, endpoint) => {
    const origins = {
      22: () => getDataOriginFormat(endpoint),
      21: () => getDataOriginAssociation(endpoint),
      1: () => getDataOriginTypeFile(endpoint),
      2: () => getDataOrigin(endpoint),
    };

    return origins[origin]();
  };

  const getDataOriginFormat = (endpoint) =>
    get(`${endpoint}/thesaurus?origins=22`, "currentOriginFormat");

  const getDataOrigin = (endpoint) =>
    get(`${endpoint}/thesaurus?origins=2`, "currentDataOrigin");

  const getDataOriginAssociation = (endpoint) =>
    get(`${endpoint}/thesaurus?origins=21`, "currentOriginAssociation");

  const getDataOriginTypeFile = (endpoint) =>
    get(`${endpoint}/thesaurus?origins=1`, "currentOriginFileType");

  const convertDatePtBr = (date, yearFormat = 'AAAA') => {
    if (date) {
      const formattedDate = (
        new Date(date.replace(/Z/g, "")).toLocaleString("pt-br") || ""
      ).substring(0, 10);

      if (yearFormat === 'AA') {
        const parts = formattedDate.split('/');

        const shortYear = parts[2].substring(2);
        return `${parts[0]}/${parts[1]}/${shortYear}`;
      } else {
        return formattedDate
      }
    }
  };

  const convertDateAndTimePtBr = (date) =>
    new Date(date).toLocaleString("pt-br");

  let stringMonth = (month) => {
    let date = {
      "01": "Jan",
      "02": "Fev",
      "03": "Mar",
      "04": "Abr",
      "05": "Mai",
      "06": "Jun",
      "07": "Jul",
      "08": "Ago",
      "09": "Set",
      10: "Out",
      11: "Nov",
      12: "Dez",
    };

    return date[month];
  };

  const formatDate = (date) => {
    if (date) {
      let d = date.split("/");
      let day = d[0];
      let month = d[1];
      let year = d[2];
      let finalDate = day + " " + stringMonth(month) + " " + year;
      return finalDate;
    }
  };

  const clearQuoteDouble = (term) => term.replace(/"/g, "");

  const setCurrentTerm = (term) => {
    if (term !== undefined) {
      setDispatch(
        !!term.match(/"/g) && !!term.match(/"/g).length,
        "isQuotesDouble"
      );
      setDispatch(clearQuoteDouble(term), "searchTerm");
    }
  };

  const setParameterized = (bool) => setDispatch(bool, "isParameterized");

  const setFilterSearch = (filter) => setDispatch(filter, "filter");

  const setRedirectSearch = (bool) => setDispatch(bool, "redirect");

  const setCurrentTypePath = (value) => setDispatch(value, "currentTypePath");

  const endpoints = [
    "dramaturgies/filter",
    "varieties/filter",
    "researches/filter",
  ];

  const setEndpoint = (value) => {
    if (endpoints.includes(value)) return value;
    return state.isParameterized ? "contents/filter" : "contents";
  };

  const setEndpointList = (path) => {
    if (path.includes("/filter")) return path;
    return state.isParameterized ? path + "/filter" : path;
  };

  const filterSchema = [
    "Complementaries",
    "FormatId",
    "CharacterIdentifications",
    "characterActivities",
    "SoapOperaSchedule",
    "ProgramFrequency",
    "title",
    "recommendationTypeIds",
    "creators",
    "origins",
    "ReceivedAtBegin",
    "ReceivedAtEnd",
    "ProductionCompanies",
    "PageNumber",
    "Limit",
  ];

  const hasItem = (item) => {
    if (item[0] === "SoapOperaSchedule" || item[0] === "ProgramFrequency") {
      return item[1].value[0];
    }

    if (item[0] === "FormatId") {
      return item[1];
    }
  };

  const formatObjFilter = () => {
    const filter = {};
    Object.entries(state.filter).forEach((item) => {
      if (filterSchema.includes(item[0]) && !!item[1]) {
        filter[item[0]] = !!hasItem(item) ? hasItem(item) : item[1];
      }
    });
    return filter;
  };

  const _getHeaderSearch = () => {
    loading("searchListHeader", true);
    getService(setEndpoint(state.currentTypePath), {
      ...formatObjFilter(),
      term: state.filter.Term || state.filter.title,
    }).then((res) => {
      dispatch({ payload: res, key: "searchListHeader", action: "header" });
      loading("searchListHeader", false);
    });
  };

  const _getGlobalSearch = () => {
    loading("searchList", true);
    setRedirectSearch(true);
    _getHeaderSearch();
    getService(setEndpointList(state.currentTypePath), {
      ...formatObjFilter(),
      ...state.filter,
      Term: state.filter.Title || state.filter.Term,
    }).then((res) => {
      if (res?.pagingInfoByType[0]?.type)
        setCurrentSearch(res.pagingInfoByType[0].type);
      dispatch({ payload: res, key: "searchList", action: "list" });
      setCurrentTerm(state.filter.title, {
        ...formatObjFilter(),
        Term: state.filter.Title || state.filter.Term,
      });
      loading("searchList", false);
      setRedirectSearch(false);
    });
  };

  useEffect(() => {
    if (state.filter !== "") {
      _getGlobalSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filter]);

  const loading = (subject, active) =>
    dispatch({ action: "loading", payload: { subject, active } });

  const get = async (path, key) => {
    loading(key, true);
    await getService(path)
      .then((result) => {
        dispatch({ payload: result, key: key, action: "list" });
        loading(key, false);
      })
      .catch((e) => {
        dispatch({
          key: key,
          action: "list",
          payload: { result: "", pagination: {} },
        });
        loading(key, false);
      });
  };

  const setCurrentSearch = (type) => setDispatch(type, "currentTypeSearch");

  const setDispatch = (payload, key) => {
    dispatch({ action: "default", key: key, payload: payload });
  };

  const sumDate = (date, days) => {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  };

  const getNumericHashFromString = (str, hash = 0) => {
    if (!str) return hash;

    const newHash = str.charCodeAt(0) + hash; //eslint-disable-line
    return getNumericHashFromString(str.substring(1), newHash);
  };

  const generateColorFromName = (name, saturation = 35, lightness = 70) => {
    const hue = getNumericHashFromString(name) % 360;
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const pronouns = [
    "o",
    "os",
    "a",
    "as",
    "um",
    "uns",
    "uma",
    "umas",
    "a",
    "ao",
    "aos",
    "à",
    "às",
    "de",
    "do",
    "dos",
    "da",
    "das",
    "em",
    "no",
    "nos",
    "na",
    "nas",
    "por",
    "pelo",
    "pelos",
    "pela",
    "pelas",
    "seu",
    "sua",
  ];

  const checkPronouns = (text) => {
    text = text.split(" ");
    if (state.isQuotesDouble) {
      text.forEach((e) => {
        if (pronouns.includes(e)) {
          text = text.filter((f) => e !== f);
        }
      });
    } else {
      text = [text.toString().replace(/,/g, " ")];
    }
    return text;
  };

  const redirectToSheet = (type, id) => {
    const types = {
      dramaturgia: `/ficha-projeto/dramaturgia/${id}`,
      variedades: `/ficha-projeto/variedades/${id}`,
      pesquisa: `/ficha-pesquisa/${id}`,
      "obra-literaria": `/ficha-projeto/obra-literaria/${id}`,
      "obra literária": `/ficha-projeto/obra-literaria/${id}`,
    };

    return types[type.toLowerCase()];
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        sendMessage,
        closeMessage,
        setCurrentStepBox,
        setShowHeader,
        setShowFullHeader,
        setShowMobileSearch,
        setShowGlassLoading,
        setShowGlassDatepicker,
        getTypeThesaurus,
        setIsRippleEffect,
        _getGlobalSearch,
        convertDatePtBr,
        goTo,
        convertDateAndTimePtBr,
        setCurrentTerm,
        formatDate,
        setFilterSearch,
        setCurrentSearch,
        setRedirectSearch,
        sumDate,
        generateColorFromName,
        setCurrentTypePath,
        checkPronouns,
        setParameterized,
        redirectToSheet,
        transformDateToUTC,
        setCurrentFileUrl,
        setIsChapter,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext as default, AppProvider };

import React from "react";

const CloudIcon = (props) => {
  const { color = "#007eb5" } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 9.5c0 .63-.09 1.237-.256 1.813A5.002 5.002 0 0 1 18 21H5.5a4.5 4.5 0 0 1-2.349-8.34A4.5 4.5 0 0 1 7.498 7 6.502 6.502 0 0 1 20 9.5z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      ></path>
      <path
        d="M12 9.5v7M16 13.5l-4-4-4 4"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export default CloudIcon;

import React, { useContext, useEffect, useState } from 'react'
import './styles/CreateAndEditCharacter.scss'
import BoxFooter from '../../../components/BoxFooter/BoxFooter'
import Button from '../../../components/Button/Button'
import SwitchText from '../../../components/SwitchText/SwitchText'
import CharacterNamesInput from '../CharacterNamesInput/CharacterNamesInput'
import SheetContext from '../../../context/sheetContext'
import AppContext from '../../../context/appContext'
import { getService, postService, putService } from '../../../services/script-api'
import ActivitiesCharacter from './ActivitiesCharacter/ActivitiesCharacter'
import CharacterDescription from './CharacterDesctiption/CharacterDescription'

const compileActivitiesData = (data) => {
  let listActivies = []
  data.forEach((e) => {
    listActivies.push({
      label: e,
      value: e,
    })
  })
  return listActivies
}

let splitIdentification = (_array) => {
  let a = []
  let split = _array.trim().split(' ')

  split.forEach((b) => {
    if (!!b.trim()) {
      a.push({
        label: b,
        value: b,
      })
    }
  })
  return a
}

const compileIdentificationsData = (identifications) => {
  let items = []

  identifications.forEach((item) => {
    items.push({
      name: !!item.name && splitIdentification(item.name),
      lastName: !!item.lastName && splitIdentification(item.lastName),
      nickName: !!item.nickName && splitIdentification(item.nickName),
    })
  })

  return items
}

const compileForId = (identifications) => {
  let compile = []
  if (!!identifications) {
    identifications.forEach((identification) => {
      compile.push(identification.label)
    })
  }

  return compile
}

const compileLabel = (items) => {
  let text = ''
  if (!!items) {
    items.forEach((item) => {
      text += item.label + ' '
    })
  }

  return text
}

const compileIdentifications = (obj) => {
  let identification = []
  obj.forEach((item) => {
    identification.push({
      name: compileLabel(item.name),
      lastName: compileLabel(item.lastName),
      nickName: compileLabel(item.nickName),
    })
  })

  return identification
}

const loadOptions = (inputValue, callback, origin) => {
  if (inputValue && inputValue.length > 0) {
    getService(`dramaturgies/thesaurus`, {
      Term: inputValue,
      origins: origin,
    }).then((list) => {
      const _list = list.data.map((item) => {
        return { value: item.id, label: item.name }
      })
      callback(_list)
    })
  }
}

const isCreate = (action) => action === "create"

const CreateAndEditCharacter = (props) => {
  const { id, data, action } = props

  const initialState = { name: '', lastName: '', nickName: '' }

  const sheetContext = useContext(SheetContext)
  const appContext = useContext(AppContext)

  let initialStateCreateAndEdit = {
    relevance: isCreate(action) ? 1 : data?.relevance?.id,
    identifications: isCreate(action) ? [initialState] : compileIdentificationsData(data?.identifications),
    activities: isCreate(action) ? [] : compileActivitiesData(data?.activities),
    description: isCreate(action) ? "" : data?.description
  }

  const [state, setState] = useState(initialStateCreateAndEdit)

  const addCharacterNameField = () => {
    setState({
      ...state,
      identifications: [...state.identifications, initialState]
    })
  }

  const addIdentifications = (key, value, index) => {
    const newState = {...state}
    newState.identifications[index][key] = !!value ? value : []
    setState(newState)
  }

  const handleChangeDescription = (ev) => {
    if (ev.target.value.length <= 1024) {
      setState({
        ...state,
        description: ev.target.value
      })
    }
  }

  const removeIdentification = (position) => {
    const newState = [...state]
    let filter = newState.identifications.filter((item, index) => index !== position)
    setState(filter)
  }

  const getSchedule = (schedule) => setState({ ...state, relevance: schedule })

  const getActivities = (item) => setState({ ...state, activities: item })

  const isValid = () => {
    const isValidIdentifications = state.identifications.every((item) => !!item.name.length)
    return isValidIdentifications && state.relevance > 0
  }

  const sendPutCharacter = (characters) => putService('dramaturgies/characters', characters)
  const sendPostCharacter = (characters) => postService('dramaturgies/characters', characters)
  const actionCharacter = (characters) => isCreate(action) ? sendPostCharacter({...characters, projectId: id}) : sendPutCharacter({...characters, id: data?.id})

  const sendCharacters = (e) => {
    e.preventDefault()

    let characters = {
      activities: compileForId(state.activities),
      identifications: compileIdentifications(state.identifications),
      description: state.description,
      relevance: state.relevance,
    }
    if (isValid()) {
      appContext.setShowGlassLoading(true)

      actionCharacter(characters)
        .then(() => {
          appContext.setShowGlassLoading(false)
          appContext.sendMessage(`Personagem ${action === "create" ? "criado" : "atualizado"} com sucesso!`)
          appContext.setCurrentStepBox('closed')
          sheetContext._getProjectSheet(id, 'dramaturgies')
        })
        .catch(() => {
          appContext.setShowGlassLoading(false)
          appContext.sendMessage('Erro ao atualizar o personagem!', 'error')
          appContext.sendMessage(`Erro ao ${action === "create" ? "criar" : "atualizar"} com sucesso!`)
          sheetContext._getProjectSheet(id, 'dramaturgies')
        })
    }
  }

  useEffect(() => {
    sheetContext.getRelevances()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="createCharacter">
      <form onSubmit={(e) => sendCharacters(e)}>
        <div className="formField">
          {state.identifications.map((item, index) => {
            return (
              <CharacterNamesInput
                key={index}
                index={index}
                loadOptions={loadOptions}
                defaultValues={item}
                characterName={item}
                getIdentifications={addIdentifications}
                totalCharacterNames={state.identifications.length}
                handleRemoveNameField={() => removeIdentification(index)}
              />
            )
          })}

          <div className="dashedBox" onClick={() => addCharacterNameField()}>
            <span>+ Adicionar outro</span>
          </div>
        </div>

        <div className="formField">
          {!sheetContext.relevances.loading && (
            <SwitchText
              label="Relevância"
              data={sheetContext.relevances.result}
              handleChange={getSchedule}
              size="large"
              value={state.relevance}
            />
          )}
        </div>

        <ActivitiesCharacter handleChange={(item) => getActivities(item)} defaultValue={state.activities}/>
        <CharacterDescription description={state.description} handleChangeDescription={handleChangeDescription} />

        <BoxFooter>
          <Button
            text="Salvar"
            toValidate={true}
            isValidForm={isValid()}
            classButton="buttonGreen large"
            typeButton="submit"
            dataQA="button-prospect-new-save"
          />
        </BoxFooter>
      </form>
    </div>
  )
}

export default CreateAndEditCharacter
import React from "react";
import "./styles/SoapTimeline.scss";

const SoapGraphicBar = (props) => {
  const { width, value, color, conflict, year } = props;
  const { durationPercentage, startedThisYear, daysFromStartOfYearPercentage } = value(conflict, year);

  return (
    <div className="timelineItem">
      <div className="timelineItemInfo">
        <span className="timelineHour">
          {!!conflict.soapOperaSchedule
            ? `${conflict.soapOperaSchedule.slice(0, 2)}h`
            : "-"}
        </span>
        <span className="timelineItemInfoSeparator">|</span>
        <span className="timelineConflictName">{conflict.name}</span>
      </div>

      <div className="timelineTrackContainer">
        <div className="timelineTrack" />
        <div
          className="graphicBar"
          style={{
            width: width(durationPercentage),
            minWidth: "5px",
            backgroundColor: color(conflict.relevance),
            left: `${startedThisYear ? daysFromStartOfYearPercentage : 0}%`,
          }}
        />
      </div>
    </div>
  );
};

export default SoapGraphicBar;

import React from "react";
import "./styles/BlocksOrChaptersTable.scss";
import TableHeader from "./TableHeader/TableHeader";
import TableItem from "./TableItem/TableItem";
import EpisodesHeader from "./EpisodesHeader/EpisodesHeader";

const BlocksTable = (props) => {
  const {
    chapterOrEpisode,
    list,
    setStateAction,
    typeValues,
    handleOpenReleaseTexts,
    handleEditFile,
    getPlanning,
    handleDeleteChapter,
    type,
    handleFileDetails,
    projectTitle,
    isReleased
  } = props;

  const isChapter = chapterOrEpisode === "Capítulos";
  const mainCellTitle = isChapter ? "Bloco" : "Episódio";

  return (
    <div id="BlocksOrChaptersTable">
      <TableHeader isChapter={isChapter} type={type} />

      {isChapter 
        ?
        (<TableHeader isChapter={isChapter} type={type} /> )
        :
        (<div className="notMobile">
        {!isReleased && <EpisodesHeader />}
        </div>)
      }

      <ul>
        {list.map((item, index) => {
          return (
            <TableItem
              key={index}
              item={item}
              isLastItem={index === list.length - 1}
              isFirstItem={index === 0}
              setStateAction={setStateAction}
              isChapter={isChapter}
              mainCellTitle={mainCellTitle}
              typeValues={typeValues}
              getPlanning={getPlanning}
              handleFileDetails={handleFileDetails}
              handleEditFile={handleEditFile}
              handleOpenReleaseTexts={handleOpenReleaseTexts}
              handleDeleteChapter={handleDeleteChapter}
              type={type}
              projectTitle={projectTitle}
              isReleased={isReleased}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default BlocksTable;

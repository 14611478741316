import React from "react";
import "./styles/ScopeList.scss";
import ThesaurusItem from "../ThesaurusItem/ThesaurusItem";

const ScopeList = (props) => {
  const { list, setStateSteps, childrenList } = props;

  return (
    <ul className={`scopeList ${!!childrenList ? "childrenList" : ""}`}>
      {list.map((item, index) => {
        return (
          <ThesaurusItem
            key={index}
            currentThesaurus={item}
            setStateSteps={setStateSteps}
          />
        );
      })}
    </ul>
  );
};

export default ScopeList;

import React from 'react';
import Pagination from "react-js-pagination";

const ContentPagination = (props) => {

  const {pagination, handlePageChange, filter} = props
  
  return (
    <div id="PaginationContent">
      {!!pagination.previousPage &&
      <div
        className={`prevPagination ${pagination.previousPage && "hasPage"}`}
        onClick={pagination.previousPage ? () => handlePageChange(pagination.previousPage) : null}
      >
        <span className="prevArrow"/>
        <span className="prevArrow"/>
      </div>
      }

      <Pagination
        hideNavigation
        hideFirstLastPages
        activePage={filter?.pageNumber}
        itemsCountPerPage={pagination?.itemsPerPage}
        totalItemsCount={pagination?.totalItems}
        onChange={handlePageChange}
      />
      {!!pagination.nextPage &&
      <div
        className={`nextPagination ${pagination.nextPage && "hasPage"}`}
        onClick={pagination.nextPage ? () => handlePageChange(pagination.nextPage) : null}
      >
        <span className="nextArrow"/>
        <span className="nextArrow"/>
      </div>
      }
    </div>
  );
};

export default ContentPagination;
import React, { useLayoutEffect, useContext } from "react";
import AppContext from "../../context/appContext";
import "./styles/Login.scss";
import ScriptIcon from "../../components/VectorIcons/ScriptIcon";
import Button from "../../components/Button/Button";
import GloboIcon from "../../components/VectorIcons/GloboIcon";
import { Link } from "react-router-dom";

const Login = (props) => {
  const appContext = useContext(AppContext);

  useLayoutEffect(() => {
    appContext.setShowHeader(false);
    appContext.setShowFullHeader(false);

    return () => {
      appContext.setShowHeader(true);
      appContext.setShowFullHeader(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="Login">
      <div className="mainContainer">
        <ScriptIcon width="245" />
        <span className="loginText">
          Sistema de gestão de conteúdo artístico da Globo
        </span>

        <Link to="/auth">
          <Button text="Entrar" classButton="buttonBlue largeRound">
            <GloboIcon color="#FFF" />
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Login;

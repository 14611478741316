import React from "react";

const SearchNoResult = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="140"
      viewBox="0 0 140 140"
    >
      <g fill="none" fillRule="evenodd">
        <ellipse
          cx="53.908"
          cy="54.535"
          fill="#C0C3F8"
          rx="53.908"
          ry="54.535"
        />
        <ellipse
          cx="53.908"
          cy="54.535"
          fill="#EDEEFE"
          rx="42.644"
          ry="43.14"
        />
        <path
          stroke="#999BE0"
          strokeLinecap="round"
          strokeWidth="2.5"
          d="M54.713 24.419c-2.772 0-5.458.373-8.012 1.072-13 3.562-22.563 15.58-22.563 29.858"
        />
        <path d="M98.161 97.674L107.816 107.442" />
        <path
          fill="#C0C3F8"
          d="M104.833 104.424c3.788-3.83 9.964-3.867 13.795-.08l.08.08 17.838 18.046c3.844 3.89 3.844 10.147 0 14.036-3.787 3.831-9.963 3.867-13.795.08l-.08-.08-17.838-18.046c-3.844-3.889-3.844-10.147 0-14.036zM94.894 94.894c.992-.992 2.6-.992 3.593 0l4.619 4.62c.992.991.992 2.6 0 3.592s-2.6.992-3.593 0l-4.619-4.62c-.992-.991-.992-2.6 0-3.592z"
        />
        <path
          stroke="#E2E4FF"
          strokeLinecap="round"
          strokeWidth="2.5"
          d="M114.253 109.07L131.954 126.977"
        />
      </g>
    </svg>
  );
};

export default SearchNoResult;

import React from "react";
import Tags from "../../../components/Tags/Tags";
import "./styles/FilteredReleased.scss";

const FilteredReleased = (props) => {
  const { selectedFiltersThesaurus, handleDeleteChannelId } = props;

  return (
    <div id="FilteredReleased">
      <h2>Filtrando por: </h2>
      <div className="tags">
        {selectedFiltersThesaurus.map((filter, index) => (
          <Tags
            key={index}
            text={filter.name}
            handleClick={() => handleDeleteChannelId(filter.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default FilteredReleased;

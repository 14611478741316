import React from "react";
import "./styles/FieldsetSelect.scss";
// import ErrorIcon from '../VectorIcons/ErrorIcon'

const FieldsetSelect = (props) => {
  const {
    name,
    label,
    placeholder,
    handleChange,
    isError,
    msgError,
    data,
    handleBlur,
    handleFocus,
    darkBackgroundColor,
    selected,
    disabled,
    custom = false
  } = props;

  return (
    <fieldset className={`fieldset ${isError ? "error" : ""}`}>
      {label && <label htmlFor={name}>{label}</label>}

      <div
        className={`inputContainer ${
          darkBackgroundColor ? "darkBackgroundColor" : ""
        } ${disabled ? "disabledFieldsetSelect" : ""}`}
      >
        <select
          id={name}
          value={!!selected ? selected : "placeholder"}
          className={`${isError && "error"} selectArea`}
          name={name}
          onChange={handleChange ? (ev) => handleChange(ev) : () => {}}
          onBlur={handleBlur}
          onFocus={handleFocus}
          disabled={disabled}
        >
          {placeholder !== "" && (
            <option value={"placeholder"}>{placeholder}</option>
          )}
          {!!data?.length && custom &&
            data.map((item, i) => {
              return (
                <option key={i} value={item}>
                  {item}
                </option>
              );
            })}
          {!!data?.length && !custom &&
            data.map((item, i) => {
              return (
                <option key={i} value={item.id}>
                  {item.name || item.value}
                </option>
              );
            })}
        </select>
      </div>

      {isError && (
        <>
          <span className="errorMessage">{msgError}</span>
          {/* <div className="errorIcon">
            <ErrorIcon/>
          </div> */}
        </>
      )}
    </fieldset>
  );
};

export default FieldsetSelect;

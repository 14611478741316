import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import AppContext from "../../context/appContext";
import UserContext from "../../context/userContext";
import "./styles/RegisterOptions.scss";
import RegisterCard from "../../components/RegisterCard/RegisterCard";
import MasksIcon from "../../components/VectorIcons/Masks";
import Microphone from "../../components/VectorIcons/Microphone";
import Clipboard from "../../components/VectorIcons/Clipboard";
import RoundCloseButton from "../../components/RoundCloseButton/RoundCloseButton";
import LiteraryWorkIcon from "../../components/VectorIcons/LiteraryWorkIcon";

const RegisterOptions = (props) => {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  useEffect(() => {
    appContext.setShowFullHeader(false);

    return () => {
      appContext.setShowFullHeader(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userContext.me.loading) return "";

  return (
    <div id="RegisterOptions">
      <Helmet>
        <title>Script - Cadastro de Projetos</title>
      </Helmet>
      <div className="closeButton" onClick={() => props.history.push("/")}>
        <RoundCloseButton color="#36355A" />
      </div>
      <span className="optionsTitle">O que você quer cadastrar?</span>
      <span className="optionsSubTitle">Escolha uma opção</span>
      <div className="cardsContainer">
        <RegisterCard
          option="Projeto de Dramaturgia"
          iconCollor="rgb(236, 16, 69)"
          className={
            !!userContext.havePermission(["Dramaturgies"]).length
              ? ""
              : "active"
          }
          redirectTo="cadastrar/dramaturgia"
        >
          <MasksIcon />
        </RegisterCard>
        <RegisterCard
          option="Projeto de Variedades"
          iconCollor="rgb(45, 118, 212)"
          className={
            !!userContext.havePermission(["Varieties"]).length ? "" : "active"
          }
          redirectTo="cadastrar/variedades"
        >
          <Microphone />
        </RegisterCard>
        <RegisterCard
          option="Pesquisa"
          iconCollor="rgb(0, 156, 105)"
          className={
            !!userContext.havePermission(["Researches"]).length ? "" : "active"
          }
          redirectTo="pesquisa/cadastrar"
        >
          <Clipboard />
        </RegisterCard>
        <RegisterCard
          option="Obra Literária"
          iconCollor="rgb(223, 154, 55)"
          className={
            !!userContext.havePermission(["LiteraryWorks"]).length
              ? ""
              : "active"
          }
          redirectTo="obra-literaria/cadastrar"
        >
          <LiteraryWorkIcon />
        </RegisterCard>
      </div>
    </div>
  );
};

export default RegisterOptions;

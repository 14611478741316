import React from "react";
import CreatableSelect from "react-select/creatable";
import "./styles/CreatableFieldset.scss";

const CreatableFieldset = (props) => {
  const {
    name,
    label,
    handleChange,
    createMessage,
    values,
    placeholder,
    classNames,
  } = props;

  return (
    <div className="creatableFieldset">
      <label htmlFor={name}>{label}</label>

      <CreatableSelect
        isMulti
        className={`reactSelect ${classNames}`}
        classNamePrefix="reactSelect"
        value={values}
        onChange={(newValue, actionMeta, index) =>
          handleChange(newValue, actionMeta, index)
        }
        noOptionsMessage={() => "Nenhum resultado encontrado"}
        formatCreateLabel={(inputValue) => `${createMessage} "${inputValue}"`}
        placeholder={placeholder}
        openMenuOnClick={false}
      />
    </div>
  );
};

export default CreatableFieldset;

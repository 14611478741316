import React, { useEffect, useContext } from "react";

import qs from "qs";
import { current, handleLogin } from "../../shared/lib/login-store";
import { postAuth, requestAutorizationCode } from "../../services/auth-api";

import UserContext from "../../context/userContext";
import Redirecting from "../Redirecting/Redirecting";
import AppContext from "../../context/appContext";

function Auth(props) {
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);

  const redirectToRootPage = () => {
    if (
      props.location.state &&
      !!props.location.state.redirect &&
      props.location.state.redirect.trim()
    ) {
      let url = props.location.state.redirect.trim();

      let to = url.toString().replace(window.location.origin, "");

      to = to.indexOf("/") === 0 ? to : `/${to}`;

      props.history.push(to);
    } else props.history.push(`/`);
  };

  const redirectToLogoutPage = () => {
    window.location = "/logout";
  };
  const redirectToUnauthorizedPage = () => {
    window.location = "/acesso-negado";
  };

  useEffect(() => {
    let _params = window.location.search;
    if (_params) _params = _params.substr(1);

    const parsedParams = qs.parse(_params);

    //Verifica se há comando de autenticação
    if (!current()) {
      if (parsedParams["code"]) {
        postAuth(parsedParams)
          .then((tokens) => {
            handleLogin(tokens);
            userContext.getRoles();
            userContext
              .loadOrCreateUser()
              .then(() => {
                redirectToRootPage();
              })
              .catch((e) => {
                redirectToUnauthorizedPage();
              });
          })
          .catch((e) => {
            //Force the user's logout when the request token failed.
            redirectToLogoutPage();
          });
      } else {
        requestAutorizationCode();
      }
    } else {
      userContext
        .loadOrCreateUser()
        .then(() => {
          redirectToRootPage();
        })
        .catch((e) => {
          redirectToUnauthorizedPage();
        });
    }

    appContext.setShowHeader(false);
    // eslint-disable-next-line
  }, []);

  return <Redirecting />;
}

export default Auth;

import React from "react";
import "./styles/ModalHeader.scss";
import TypeIconReturner from "../../../../components/TypeIconReturner/TypeIconReturner";

const ModalHeader = (props) => {
  const { title, creators, type, format, category, subCategory } = props;

  return (
    <div className="modalHeader">
      <div className="icon">
        <TypeIconReturner type={type} responsiveIcon={true} />
      </div>

      <div className="headerInfo">
        <span className="title">{title}</span>

        {type !== "Pesquisa" && (
          <div className="creators">
            {!!creators &&
              creators.map((creator, index) => {
                return (
                  <React.Fragment key={index}>
                    <span>{creator.name}</span>
                    {index + 1 < creators.length && (
                      <span className="creatorSeparator">|</span>
                    )}
                  </React.Fragment>
                );
              })}
          </div>
        )}

        {type !== "Pesquisa" ? (
          <span className="type">{format}</span>
        ) : (
          <span className="categories">
            {!!category && !!subCategory && `${category} • ${subCategory}`}
          </span>
        )}
      </div>
    </div>
  );
};

export default ModalHeader;

import React, {useContext, useEffect, useState} from "react";
import "./styles/EditSheetComplement.scss";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import Button from "../../../components/Button/Button";
import AppContext from "../../../context/appContext";
import {getService, putService} from "../../../services/script-api";
import SheetContext from "../../../context/sheetContext";
import Textarea from "../../../components/Textarea/Textarea";
import CreatableSelect from "react-select/creatable";
import FieldsetSelect from "../../../components/FieldsetSelect/FieldsetSelect";
import DeleteIcon from "../../../components/VectorIcons/DeleteIcon";
import _ from "lodash";
import ThesaurusSelect from "../../../components/ThesaurusSelect/ThesaurusSelect";
import ThesaurusScope from "../../../components/ThesaurusScope/ThesaurusScope";
import ThesaurusDescription from "../../../components/ThesaurusDescription/ThesaurusDescription";

const EditSheetComplement = (props) => {
	const {id, data, dataProject } = props;

	const appContext = useContext(AppContext);
	const sheetContext = useContext(SheetContext);

	let objOrigin = {
		id: 0,
		descriptions: [],
	};

	const compileContentOriginIds = (item) => {
		let content = [];

		if (!!item.length) {
			item.forEach((el) => {
				content.push({
					id: el.content.id,
					descriptions: el.descriptions,
				});
			});
		}

		return content;
	};

	useEffect(() => {
		compileContentOriginIds(data.contentOrigns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initialStateContentOrigins = !!data.contentOrigns.length
		? compileContentOriginIds(data.contentOrigns)
		: [objOrigin];

	const convertOptionSelect = (options, origin) => {
		let o = [];

		options.forEach((opt) => {
			o.push({
				value: opt.id,
				label: opt.name,
				origin: origin,
			});
		});

		return o;
	};

	const initialState = {
		narratives: convertOptionSelect(data.narratives, "narratives"),
		genres: convertOptionSelect(data.genres, "genres"),
		subGenres: convertOptionSelect(data.subGenres, "subGenres"),
		spaces: convertOptionSelect(data.spaces, "spaces"),
		periods: convertOptionSelect(data.periods, "periods"),
		epochs: convertOptionSelect(data.epochs, "epochs"),
		storyLine: data.storyLine,
	};

	const [state, setState] = useState(initialState);
	const [stateContentOrigins, setStateContentOrigins] = useState(initialStateContentOrigins);
	const [stateNarrative, setStateNarrative] = useState(state.narratives);
	const [stateGenres, setStateGenres] = useState(state.genres);
	const [stateSubGenres, setStateSubGenres] = useState(state.subGenres);
	const [stateSpace, setStateSpace] = useState(state.spaces);
	const [statePeriod, setStatePeriod] = useState(state.periods);
	const [stateEpoch, setStateEpoch] = useState(state.epochs);

	useEffect(() => {
		getThesaurus(13, setStateNarrative, "narratives");
		getThesaurus(15, setStateGenres, "genres");
		getThesaurus(19, setStateSubGenres, "subGenres");
		getThesaurus(12, setStateSpace, "spaces");
		getThesaurus(14, setStatePeriod, "periods");
		getThesaurus(16, setStateEpoch, "epochs");
		appContext.getTypeThesaurus(2, "dramaturgies");
		// eslint-disable-next-line
	}, []);

	const compileContentOrigins = (data) => {
		let e = [];
		if (!!data && !!data.length) {
			data.forEach((el) => e.push({value: el, label: el}));
		}
		return e;
	};

	const getThesaurus = (id, _setState, origin) => {
		getService(`dramaturgies/thesaurus?origins=${id}`).then((list) => {
			const _list = list.data.map((item) => ({
				value: item.id,
				label: item.name,
				origin: origin,
				...item,
			}));
			_setState(_list);
		});
	};

	const nextOptionSelected = () => {
		let currentUsedOptions = [];

		stateContentOrigins.forEach((item) =>
			currentUsedOptions.push(item.contentOriginId)
		);

		let unusedOptions = appContext.currentDataOrigin.result.filter((option) => {
			return currentUsedOptions.indexOf(option.id) === -1;
		});

		if (!!unusedOptions.length > 0) return unusedOptions[0].id;
	};

	const addContentOriginsField = () => {
		if (
			stateContentOrigins.length < appContext.currentDataOrigin.result.length
		) {
			const newState = [...stateContentOrigins];
			newState.push({
				id: nextOptionSelected(),
				descriptions: [],
			});
			setStateContentOrigins(newState);
		}
	};

	const removeContentOriginalField = (item) => {
		const newState = [...stateContentOrigins];
		let n = newState.filter((i) => i !== item);
		setStateContentOrigins(n);
	};

	const handleChange = (item, origin) => {
		setState({
			...state,
			subGenres:
				origin === "genres"
					? !!checkGenres(item)
					? state.subGenres
					: []
					: state.subGenres,
			epochs:
				origin === "periods"
					? !!checkEpoch(item)
					? state.epochs
					: []
					: state.epochs,
			[origin]: !!item ? item : [],
		});
	};

	const handleChangeStoryLine = (ev) => {
		setState({
			...state,
			storyLine: ev.target.value,
		});
	};

	const handleChangeDescriptions = (newValue, actionMeta, index) => {
		const actions = {
			"create-option": () => handleCreateDescriptions(newValue, index),
			"remove-value": () => handleCreateDescriptions(newValue, index),
			"pop-value": () => handleCreateDescriptions(newValue, index),
			clear: () => handleRemoveAllDescription(index),
		};

		return actions[actionMeta.action]();
	};

	const handleCreateDescriptions = (newValue, index) => {
		let obj = [];
		if (!!newValue) {
			newValue.forEach((el) => {
				obj.push(el.value);
			});
		}
		const newState = [...stateContentOrigins];
		newState[index].descriptions = obj;
		setStateContentOrigins(newState);
	};

	const handleRemoveAllDescription = (index) => {
		const newState = [...stateContentOrigins];
		newState[index].descriptions = "";
		setStateContentOrigins(newState);
	};

	const handleChangeOrigin = (ev, index) => {
		const newState = [...stateContentOrigins];
		newState[index].id =
			ev.target.value === "Selecione" ? 0 : parseInt(ev.target.value);
		setStateContentOrigins(newState);
	};

	const _updateDataComplement = (ev) => {
		ev.preventDefault();

		delete dataProject.basic.notCompileCreators
		delete dataProject.basic.notCompileProductionCompanies

		let obj = {
			...dataProject.basic,
			channel: compileContentOriginIds(data.channel),
			id: parseInt(id),
			genresIds: sheetContext.compileDataId(state.genres),
			subGenresIds: sheetContext.compileDataId(state.subGenres),
			spacesIds: sheetContext.compileDataId(state.spaces),
			contentOrigns: stateContentOrigins,
			narratives: sheetContext.compileDataId(state.narratives),
			periods: sheetContext.compileDataId(state.periods),
			epochIds: sheetContext.compileDataId(state.epochs),
			storyLine: !!state.storyLine ? state.storyLine.trim() : "",
			...dataProject.relevance
		};

		appContext.setShowGlassLoading(true);

		putService("dramaturgies", obj)
			.then(() => {
				appContext.setShowGlassLoading(false);
				appContext.sendMessage("Ficha atualizada com sucesso!");
				appContext.setCurrentStepBox("closed");
				sheetContext._getProjectSheet(id, "dramaturgies");
			})
			.catch(() => {
				appContext.setShowGlassLoading(false);
				appContext.sendMessage("Erro ao atualizar a ficha!", "error");
				// appContext.setCurrentStepBox("closed");
				// sheetContext._getProjectSheet(id, "dramaturgies");
			});
	};

	const filterOptions = (position) => {
		let compiled = [];

		if (!!appContext.currentDataOrigin.result) {
			let currentUsedOptions = [];
			let currentPositionOption = appContext.currentDataOrigin.result.filter(
				(item) => item.id === stateContentOrigins[position].contentOriginId
			);

			stateContentOrigins.forEach((item) =>
				currentUsedOptions.push(item.contentOriginId)
			);

			let unusedOptions = appContext.currentDataOrigin.result.filter(
				(option) => currentUsedOptions.indexOf(option.id) === -1
			);

			compiled =
				currentPositionOption.length > 0
					? [...unusedOptions, compileNewOption(currentPositionOption)]
					: unusedOptions;
		}

		return compiled;
	};

	const compileNewOption = (options) => {
		return {
			id: options[0].id,
			origin: options[0].origin,
			name: options[0].name,
			description: options[0].description,
		};
	};

	const editIsValid = () => {
		let verifyContentOriginId = !!stateContentOrigins.every(
			(origin) => !!origin.id > 0
		);
		let verifyContentDescription = !!stateContentOrigins.every(
			(origin) => !!origin.descriptions.length
		);

		return (
			(verifyContentDescription && verifyContentOriginId) ||
			(!verifyContentDescription && !verifyContentOriginId)
		);
	};
	const checkGenres = (item) =>
		_.findKey(item, (item) => item.label.indexOf("Comédia") !== -1);

	const checkEpoch = (item) =>
		_.findKey(item, (item) => item.label.indexOf("Época") !== -1);

	return (
		<div id="EditSheetComplement">
			<form
				className="registerForm"
				onSubmit={(ev) => _updateDataComplement(ev)}
			>
				<div className="formFieldColumn">
					<ThesaurusDescription label="Origem" originId={5}/>

					{stateContentOrigins.map((item, index) => {
						return (
							<div className="formField" key={index}>
								<div
									className={`contentOriginContainer ${
										stateContentOrigins.length > 1 ? "showDeleteIcon" : ""
									}`}
									style={{zIndex: 15 - index}}
								>
									<FieldsetSelect
										placeholder="Selecione"
										data={filterOptions(index)}
										value={item.id}
										selected={stateContentOrigins[index].id}
										handleChange={(ev) => handleChangeOrigin(ev, index)}
										msgError="Este campo é obrigatório"
										endpoint="dramaturgies"
									/>

									<CreatableSelect
										isMulti
										className="reactSelect"
										classNamePrefix="reactSelect"
										value={compileContentOrigins(
											stateContentOrigins[index].descriptions
										)}
										onChange={(newValue, actionMeta) =>
											handleChangeDescriptions(newValue, actionMeta, index)
										}
										noOptionsMessage={() => {
											return "Nenhum resultado encontrado";
										}}
										formatCreateLabel={(inputValue) => {
											return `Adicionar a opção: "${inputValue}"`;
										}}
										placeholder="Digite um nome e aperte ENTER"
										openMenuOnClick={false}
									/>

									{stateContentOrigins.length > 1 && (
										<div
											className="deleteIcon"
											onClick={() => removeContentOriginalField(item)}
										>
											<DeleteIcon/>
										</div>
									)}
								</div>
							</div>
						);
					})}

					{!!appContext.currentDataOrigin.result &&
					stateContentOrigins.length <
					appContext.currentDataOrigin.result.length && (
						<div
							className="dashedBox"
							onClick={() => addContentOriginsField()}
						>
							<span>+ Adicionar outro</span>
						</div>
					)}
				</div>

				<div className="formFieldColumn">
					<ThesaurusDescription label="Modelo Narrativo" originId={12}/>

					<ThesaurusScope
						thesaurusName="Modelo Narrativo"
						thesaurus={stateNarrative}
						selecteds={state.narratives}
						stateName="narratives"
						addThesaurus={(item) => handleChange(item, "narratives")}
						searchable={false}
					>
						<ThesaurusSelect
							value={state.narratives}
							defaultValue={state.narratives}
							options={stateNarrative}
							isMulti={true}
							onChange={(item) => handleChange(item, "narratives")}
							placeholder="Digite um nome e aperte ENTER"
							noOptionsMessage="Nenhum item encontrado"
						/>
					</ThesaurusScope>
				</div>

				<div className="formFieldColumn">
					<ThesaurusDescription label="Gênero" originId={14}/>

					<ThesaurusScope
						thesaurusName="Gênero"
						thesaurus={stateGenres}
						selecteds={state.genres}
						stateName="genres"
						addThesaurus={(item) => handleChange(item, "genres")}
						searchable={false}
					>
						<ThesaurusSelect
							value={state.genres}
							defaultValue={state.genres}
							options={stateGenres}
							isMulti={true}
							onChange={(item) => handleChange(item, "genres")}
							placeholder="Digite um nome e aperte ENTER"
							noOptionsMessage="Nenhum item encontrado"
						/>
					</ThesaurusScope>
				</div>

				<div
					className="formFieldColumn"
					style={{
						display: state.genres.some((item) => item.label === "Comédia")
							? "flex"
							: "none",
					}}
				>
					<ThesaurusDescription label="Subgênero" originId={16}/>

					<ThesaurusScope
						thesaurusName="SubGênero"
						thesaurus={stateSubGenres}
						selecteds={state.subGenres}
						stateName="subGenres"
						addThesaurus={(item) => handleChange(item, "subGenres")}
						searchable={false}
					>
						<ThesaurusSelect
							value={state.subGenres}
							defaultValue={state.subGenres}
							options={stateSubGenres}
							isMulti={true}
							onChange={(item) => handleChange(item, "subGenres")}
							placeholder="Digite um nome e aperte ENTER"
							noOptionsMessage="Nenhum item encontrado"
						/>
					</ThesaurusScope>
				</div>

				<div className="formFieldColumn">
					<ThesaurusDescription label="Espaço" originId={11}/>

					<ThesaurusScope
						thesaurusName="Espaço"
						thesaurus={stateSpace}
						selecteds={state.spaces}
						stateName="spaces"
						addThesaurus={(item) => handleChange(item, "spaces")}
						searchable={false}
					>
						<ThesaurusSelect
							value={state.spaces}
							defaultValue={state.spaces}
							options={stateSpace}
							isMulti={true}
							onChange={(item) => handleChange(item, "spaces")}
							placeholder="Digite um nome e aperte ENTER"
							noOptionsMessage="Nenhum item encontrado"
						/>
					</ThesaurusScope>
				</div>

				<div className="formFieldColumn">
					<ThesaurusDescription label="Tempo" originId={13}/>

					<ThesaurusScope
						thesaurusName="Tempo"
						thesaurus={statePeriod}
						selecteds={state.periods}
						stateName="periods"
						addThesaurus={(item) => handleChange(item, "periods")}
						searchable={false}
					>
						<ThesaurusSelect
							value={state.periods}
							defaultValue={state.periods}
							options={statePeriod}
							isMulti={true}
							onChange={(item) => handleChange(item, "periods")}
							placeholder="Digite um nome e aperte ENTER"
							noOptionsMessage="Nenhum item encontrado"
						/>
					</ThesaurusScope>
				</div>

				<div
					className="formFieldColumn"
					style={{
						display: state.periods.some((item) => item.label === "Época")
							? "flex"
							: "none",
					}}
				>
					<ThesaurusDescription label="Época" originId={15}/>

					<ThesaurusScope
						thesaurusName="Época"
						thesaurus={stateEpoch}
						selecteds={state.epochs}
						stateName="epochs"
						addThesaurus={(item) => handleChange(item, "epochs")}
						searchable={false}
					>
						<ThesaurusSelect
							value={state.epochs}
							defaultValue={state.epochs}
							options={stateEpoch}
							isMulti={true}
							onChange={(item) => handleChange(item, "epochs")}
							placeholder="Digite um nome e aperte ENTER"
							noOptionsMessage="Nenhum item encontrado"
						/>
					</ThesaurusScope>
				</div>

				<div className="formField storylineField">
					<Textarea
						handleChange={handleChangeStoryLine}
						label="storyline"
						maxRows={16}
						value={state.storyLine}
					/>
				</div>

				<BoxFooter>
					<Button
						text="Salvar"
						toValidate={true}
						isValidForm={editIsValid()}
						classButton="buttonGreen large"
						typeButton="submit"
						dataQA="button-prospect-new-save"
					/>
				</BoxFooter>
			</form>
		</div>
	);
};

export default EditSheetComplement;

import React, { useContext } from 'react'
import './styles/ReleasedItemProject.scss'
import TypeIconReturner from '../../../components/TypeIconReturner/TypeIconReturner'
import AppContext from '../../../context/appContext'

const ReleasedItemProject = (props) => {
  const { project } = props
  const {
    contentType,
    title,
    creators,
    format,
    soapOperaSchedule,
    lastAttachmentReleaseDate,
    countAttachments,
  } = project

  const appContext = useContext(AppContext)

  const toggleListReleaseActivity = (ev) => {
    let targetActive = ev.target.closest('.contentListAttachmentsReleased')

    if (targetActive) {
      targetActive.classList.toggle('active')
    }
  }

  return (
    <div
      className="releasedItemProject"
      onClick={(e) => toggleListReleaseActivity(e)}
    >
      <div className="containerTitleAndCreators">
        <div className="contentTitle">
          <TypeIconReturner type={contentType} responsiveIcon={true}/>
          <span className="title">{`${title} (${countAttachments})`}</span>
        </div>

        <div className="creatorsReleased">
          {!!creators.length &&
          creators.map((creator, i) => {
            return (
              <div key={i}>
                <span>{creator.name}</span>
                {i + 1 < creators.length && (
                  <span className="creatorSeparator">|</span>
                )}
              </div>
            )
          })}
        </div>
      </div>

      <div className="formatReleased">
        {format}
        {format === 'Novela' ? soapOperaSchedule : ''}
      </div>

      <span className="lastAttachment">
        {!!lastAttachmentReleaseDate && 'Liberado em '} 
        <span className="date">
          {!!lastAttachmentReleaseDate && appContext.formatDate(
            appContext.convertDatePtBr(lastAttachmentReleaseDate)
          )}
        </span>
      </span>
    </div>
  )
}

export default ReleasedItemProject

import React from "react";

const FilterIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 5.83333C2.5 5.3731 2.8731 5 3.33333 5H16.6667C17.1269 5 17.5 5.3731 17.5 5.83333C17.5 6.29357 17.1269 6.66667 16.6667 6.66667H3.33333C2.8731 6.66667 2.5 6.29357 2.5 5.83333Z"
        fill="#0098cd"
      ></path>
      <path
        d="M5 10C5 9.53976 5.3731 9.16667 5.83333 9.16667H14.1667C14.6269 9.16667 15 9.53976 15 10C15 10.4602 14.6269 10.8333 14.1667 10.8333H5.83333C5.3731 10.8333 5 10.4602 5 10Z"
        fill="#0098cd"
      ></path>
      <path
        d="M8.33333 14.1667C8.33333 13.7064 8.70643 13.3333 9.16667 13.3333H10.8333C11.2936 13.3333 11.6667 13.7064 11.6667 14.1667C11.6667 14.6269 11.2936 15 10.8333 15H9.16667C8.70643 15 8.33333 14.6269 8.33333 14.1667Z"
        fill="#0098cd"
      ></path>
    </svg>
  );
};

export default FilterIcon;

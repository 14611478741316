import React, { useState } from "react";
import "./style/EditFile.scss";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatepicker";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import Button from "../../../components/Button/Button";

const EditFile = (props) => {
  const { file, sendFileEdit } = props;

  const initialState = {
    version: file.version,
    chapterNumber: file.chapterNumber,
    blockNumber: file.blockNumber,
    receivedAt: file.receivedAt,
  };

  const [state, setstate] = useState(initialState);

  const getDate = (date) => {
    let d = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
    setstate({
      ...state,
      receivedAt: d,
    });
  };

  const sendEditFiles = (e) => {
    e.preventDefault();
    let obj = {
      id: file.id,
      version: state.version,
      receivedAt: state.receivedAt,
      attachmentTypeId: null,
      blockNumber: null,
      chapterNumber: null,
    };

    sendFileEdit(obj);
  };

  return (
    <div className="EditFileContainer">
      <form onSubmit={(e) => sendEditFiles(e)}>
        <div className="fileInfo">
          <CustomDatePicker
            label="Recebido Em"
            name="attachmentReceivedAt"
            darkBackgroundColor={false}
            handleChangeDate={getDate}
            date={new Date(state.receivedAt)}
          />
        </div>

        <BoxFooter>
          <Button
            text="Salvar"
            classButton="buttonGreen large"
            typeButton="submit"
            dataQA="button-prospect-new-save"
          />
        </BoxFooter>
      </form>
    </div>
  );
};

export default EditFile;

import React from "react";

const RefreshIcon = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.66046V5.66046H5.2003"
        stroke="#17B4E1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75712 8.99377C3.21103 10.2207 4.07135 11.2739 5.20846 11.9947C6.34556 12.7155 7.69785 13.0648 9.06157 12.9901C10.4253 12.9153 11.7266 12.4206 12.7693 11.5803C13.8121 10.74 14.5398 9.59976 14.8429 8.33135C15.146 7.06294 15.0081 5.73508 14.4498 4.54784C13.8915 3.36061 12.9432 2.37831 11.7477 1.74896C10.5523 1.1196 9.17441 0.87729 7.82176 1.05853C6.4691 1.23976 5.21494 1.83473 4.24823 2.75378L1 5.66044"
        stroke="#17B4E1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshIcon;

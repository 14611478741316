import React, { useState, useContext } from "react";
import "./styles/ConflictsTable.scss";
import ImportanceMark from "../ImportanceMark/ImportanceMark";
import ScriptBoxCaller from "../../../components/ScriptBoxCaller/ScriptBoxCaller";
import ScriptBoxDescription from "../../../components/ScriptBoxDescription/ScriptBoxDescription";
import AppContext from "../../../context/appContext";
import MapConflictContext from "../../../context/mapConflictContext";
import DeleteIcon from "../../../components/VectorIcons/DeleteIcon";
import ConfirmationBox from "../../../components/ConfirmationBox/ConfirmationBox";
import ConflictsGraphic from "../ConflictsGraphic/ConflictsGraphic";

const ConflictsTable = (props) => {
  const { list, currentTypeConflictSelected } = props;

  const [stateDescriptionBox, setStateDescriptionBox] = useState("");
  const [stateConfirmationBoxTerm, setStateConfirmationBoxTerm] = useState({
    open: false,
    term: "",
    termId: "",
  });

  const [stateConfirmationBoxCharacter, setStateConfirmationBoxCharacter] =
    useState({
      open: false,
      identification: "",
      termId: "",
      characterId: "",
      productTitle: "",
    });

  const appContext = useContext(AppContext);
  const mapContext = useContext(MapConflictContext);

  const toggleOpenList = (ev) => {
    const target = ev.target;
    const parent = target.closest(".conflictItem");
    const targetIsRemoveButton =
      target.className === "removeButton" || !!target.closest(".removeButton");
    const targetIsValid =
      target.className === "conflictHeader" ||
      !!target.closest(".conflictHeader");
    const canOpen = !targetIsRemoveButton && targetIsValid;

    if (canOpen) {
      parent.classList.toggle("opened");
    }
  };

  const conflictColoredText = (conflictType) => {
    const colors = {
      unclassified: "#9395a3",
      Terciária: "#10af78",
      Secundária: "#df9a37",
      Central: "#ec1045",
    };

    return conflictType === "" ? colors["unclassified"] : colors[conflictType];
  };

  const handleDescriptionItem = (item) => {
    const names = [];
    item.characterIdentifications.forEach((name, index) => {
      names.push(
        `${index > 0 ? " " : ""}${name.name ?? ""} ${name.lastName ?? ""} ${
          !!name.nickName ? "(" + name.nickName.trim() + ")" : ""
        }`
      );
    });

    const compiledNames = names.toString();

    setStateDescriptionBox({
      ...stateDescriptionBox,
      open: true,
      currentDescription: {
        identifications: compiledNames,
        productTitle: item.productTitle,
        description: item.characterDescription,
      },
    });
  };

  const closedDescription = () => {
    setStateDescriptionBox({
      ...stateDescriptionBox,
      open: false,
    });
  };

  const removeConflictTerm = async (termId) => {
    let obj = {
      termId: termId,
      beginDate: mapContext.filter.beginDate,
      endDate: mapContext.filter.endDate,
      conflictType: mapContext.filter.conflictType,
    };

    await mapContext
      .removeConflictTerm(obj)
      .then((x) => {
        appContext.sendMessage("Conflito removido com sucesso.");
      })
      .catch((e) => {
        console.log(e);
        appContext.sendMessage("Erro ao remover conflito!", "error");
      });
  };

  const removeConflictCharacter = async (termId, characterId, itemId) => {
    let obj = {
      termId: termId,
      characterId: characterId || itemId,
      beginDate: mapContext.filter.beginDate,
      endDate: mapContext.filter.endDate,
      conflictType: mapContext.filter.conflictType,
    };

    await mapContext
      .removeConflictCharacter(obj)
      .then((x) => {
        appContext.sendMessage("Item retirado do conflito com sucesso.");
      })
      .catch((e) => {
        appContext.sendMessage("Erro ao retirar o item do conflito!", "error");
      });
  };

  const handleConfirmationRemoveTerm = (termId, term) => {
    setStateConfirmationBoxTerm({
      ...stateConfirmationBoxTerm,
      open: true,
      termId,
      term: term.term,
    });
  };

  const handleConfirmationRemoveCharacter = (termId, conflictItem) => {
    setStateConfirmationBoxCharacter({
      ...stateConfirmationBoxTerm,
      termId,
      ...conflictItem,
      open: true,
    });
  };

  const closedBoxTerm = () => {
    setStateConfirmationBoxTerm({
      ...stateConfirmationBoxTerm,
      open: false,
    });
  };

  const closedBoxCharacter = () => {
    setStateConfirmationBoxCharacter({
      ...stateConfirmationBoxCharacter,
      open: false,
    });
  };

  const isNull = (string) => (!!string ? string : "");

  const setMessageDeleteConflict = (item) => {
    let msg = {
      "Nome do Personagem": `Ao confirmar, o personagem ${
        !!item.characterIdentifications && item.characterIdentifications[0].name
      } de ${
        item.productTitle
      } será desconsiderado deste conflito e não poderá ser resgatado.`,
      "Perfil do Personagem": `Ao confirmar, o personagem ${
        !!item.characterIdentifications && item.characterIdentifications[0].name
      } de ${
        item.productTitle
      } será desconsiderado deste conflito e não poderá ser resgatado.`,
      Trama: `Ao confirmar, a ${currentTypeConflictSelected.value.toLowerCase()} ${
        item.thesaurusName
      } de ${
        item.productTitle
      } será desconsiderado deste conflito e não poderá ser resgatado.`,
      Temática: `Ao confirmar, a ${currentTypeConflictSelected.value.toLowerCase()} ${
        item.thesaurusName
      } de ${
        item.productTitle
      } será desconsiderado deste conflito e não poderá ser resgatado.`,
      Universo: `Ao confirmar, o ${currentTypeConflictSelected.value.toLowerCase()} ${
        item.thesaurusName
      } de ${
        item.productTitle
      } será desconsiderado deste conflito e não poderá ser resgatado.`,
    };
    return msg[currentTypeConflictSelected.value];
  };

  const isMale = ["nome do personagem", "perfil do personagem", "universo"];
  const isCharacter = ["nome do personagem", "perfil do personagem"];

  return (
    <div className="conflictsTable">
      {list.map((item, indexList) => {
        return (
          <div
            className="conflictItem breakPage"
            id={indexList}
            key={indexList}
          >
            <li className="conflictHeader" onClick={(ev) => toggleOpenList(ev)}>
              <div className="itemArrow" />
              <span className="conflictName">
                {item.term} {` (${item.conflictingItems.length})`}
              </span>

              <div className="conflictsLevel">
                {item.relevances.map((relevance, indexItem) => {
                  return (
                    <ImportanceMark
                      level={relevance === "" ? "unclassified" : relevance}
                      key={indexItem}
                    />
                  );
                })}
              </div>

              <div
                className="removeButton"
                onClick={() => handleConfirmationRemoveTerm(item.termId, item)}
              >
                <DeleteIcon color="#000" />
              </div>
            </li>

            {item.conflictingItems.map((conflictItem, indexConflictItem) => {
              return (
                <li className="conflict" key={indexConflictItem}>
                  <div className="conflictBox">
                    <div className="productInfo">
                      <span
                        className="productInfoName"
                        style={{
                          color: conflictColoredText(conflictItem.relevance),
                        }}
                      >
                        {conflictItem.productTitle
                          ? conflictItem.productTitle
                          : ""}
                      </span>
                      <span className="productPeriod">
                        {conflictItem.productTitle
                          ? `${appContext.formatDate(
                              appContext.convertDatePtBr(
                                conflictItem.seasonBeginDate
                              )
                            )} - ${
                              !!conflictItem.seasonEndDate
                                ? appContext.formatDate(
                                    appContext.convertDatePtBr(
                                      conflictItem.seasonEndDate
                                    )
                                  )
                                : "-"
                            }`
                          : ""}
                      </span>
                    </div>
                  </div>

                  {!!conflictItem.characterIdentifications ? (
                    <>
                      <div className="conflictBox">
                        <div className="characterInfo">
                          <span
                            className="characterName"
                            style={{
                              color: conflictColoredText(
                                conflictItem.relevance
                              ),
                            }}
                          >
                            {!!conflictItem.characterIdentifications &&
                              isNull(
                                conflictItem.characterIdentifications[0].name
                              ) +
                                " " +
                                isNull(
                                  conflictItem.characterIdentifications[0]
                                    .lastName
                                )}
                            <span className="nickName">
                              {!!conflictItem.characterIdentifications[0]
                                .nickName && (
                                <>
                                  ($
                                  {
                                    conflictItem.characterIdentifications[0]
                                      .nickName
                                  }
                                  )
                                </>
                              )}
                            </span>
                          </span>
                          <p className="characterDescription">
                            <span>
                              {`${conflictItem.characterDescription
                                .slice(0, 100)
                                .trim()}${
                                conflictItem.characterDescription.length >= 100
                                  ? "... "
                                  : ""
                              }`}
                            </span>
                            {!!conflictItem.characterDescription &&
                              conflictItem.characterDescription.length >=
                                100 && (
                                <ScriptBoxCaller
                                  callerText="mais"
                                  event={() =>
                                    handleDescriptionItem(conflictItem)
                                  }
                                />
                              )}
                          </p>
                        </div>
                      </div>

                      <div className="conflictBox">
                        <div className="conflictTags">
                          {!!conflictItem.characterActivities &&
                            conflictItem.characterActivities.map(
                              (tag, tagIndex) => (
                                <span className="conflictTag" key={tagIndex}>
                                  {tag}
                                </span>
                              )
                            )}
                        </div>
                      </div>

                      <div className="conflictBox">
                        <div className="productInfo">
                          {!!conflictItem.conflictedSeasons &&
                            conflictItem.conflictedSeasons.map(
                              (conflictedProducts, productsIndex) => {
                                return (
                                  <div
                                    className="conflictedProducts"
                                    key={productsIndex}
                                  >
                                    <ImportanceMark
                                      level={
                                        conflictedProducts.conflictRelevance ===
                                        ""
                                          ? "unclassified"
                                          : conflictedProducts.conflictRelevance
                                      }
                                      conflictType={
                                        conflictedProducts.conflictMoment
                                      }
                                      isArrow={true}
                                    />

                                    {`${conflictedProducts.productTitle} • `}
                                    <span className="conflictDays">
                                      {conflictedProducts.daysOfConflict} dias
                                      {conflictedProducts.conflictMoment ===
                                      "Vertical"
                                        ? " simultâneos"
                                        : " de intervalo"}
                                    </span>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>

                      <div
                        className={`removeButton ${
                          item.conflictingItems.length < 3 ? "hide" : ""
                        }`}
                        onClick={() =>
                          handleConfirmationRemoveCharacter(
                            item.termId,
                            conflictItem
                          )
                        }
                      >
                        <DeleteIcon color="#000" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="conflictBox" />
                      <div className="conflictBox">
                        <div className="productInfo">
                          {!!conflictItem.conflictedSeasons &&
                            conflictItem.conflictedSeasons.map(
                              (conflictedProducts, productsIndex) => {
                                return (
                                  <div
                                    className="conflictedProducts"
                                    key={productsIndex}
                                  >
                                    <ImportanceMark
                                      level={
                                        conflictedProducts.conflictRelevance ===
                                        ""
                                          ? "unclassified"
                                          : conflictedProducts.conflictRelevance
                                      }
                                      conflictType={
                                        conflictedProducts.conflictMoment
                                      }
                                      isArrow={true}
                                    />

                                    {`${conflictedProducts.productTitle} • `}
                                    <span className="conflictDays">
                                      {conflictedProducts.daysOfConflict} dias
                                      {conflictedProducts.conflictMoment ===
                                      "Vertical"
                                        ? " simultâneos"
                                        : " de intervalo"}
                                    </span>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                      <div
                        className={`removeButton ${
                          item.conflictingItems.length < 3 ? "hide" : ""
                        }`}
                        onClick={() =>
                          handleConfirmationRemoveCharacter(
                            item.termId,
                            conflictItem
                          )
                        }
                      >
                        <DeleteIcon color="#000" />
                      </div>
                    </>
                  )}
                </li>
              );
            })}

            <ConflictsGraphic
              conflicts={item.conflictingItems}
              conflictColoredText={conflictColoredText}
            />
          </div>
        );
      })}

      {stateConfirmationBoxTerm.open && (
        <ConfirmationBox
          open={stateConfirmationBoxTerm.open}
          textButton="Excluir Conflito"
          closedBox={closedBoxTerm}
          handleEvent={() =>
            removeConflictTerm(stateConfirmationBoxTerm.termId)
          }
          title="Deseja excluir este conflito?"
          text={`Ao confirmar, o conflito ${stateConfirmationBoxTerm.term} será deletado e não poderá ser resgatado.`}
        />
      )}

      {stateConfirmationBoxCharacter.open && (
        <ConfirmationBox
          open={stateConfirmationBoxCharacter.open}
          textButton={`Excluir ${
            isCharacter.includes(
              currentTypeConflictSelected.value.toLowerCase()
            )
              ? "personagem"
              : currentTypeConflictSelected.value.toLowerCase()
          }`}
          closedBox={closedBoxCharacter}
          handleEvent={() =>
            removeConflictCharacter(
              stateConfirmationBoxCharacter.termId,
              stateConfirmationBoxCharacter.characterId,
              stateConfirmationBoxCharacter.itemId
            )
          }
          title={`Deseja excluir ${
            isMale.includes(currentTypeConflictSelected.value.toLowerCase())
              ? "o"
              : "a"
          } ${currentTypeConflictSelected.value.toLowerCase()} deste conflito?`}
          text={setMessageDeleteConflict(stateConfirmationBoxCharacter)}
        />
      )}

      {stateDescriptionBox.open && (
        <ScriptBoxDescription
          open={stateDescriptionBox.open}
          clear={() => closedDescription()}
          title={stateDescriptionBox.currentDescription.identifications}
          subTitle={stateDescriptionBox.currentDescription.productTitle}
        >
          <p className="boxParagraph">
            {stateDescriptionBox.currentDescription.description}
          </p>
        </ScriptBoxDescription>
      )}
    </div>
  );
};

export default ConflictsTable;

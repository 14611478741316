import React from "react";
import "./style/ListItemsThesaurus.scss";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import InfoIcon from "../../../components/VectorIcons/InfoIcon";

const ListItemsThesaurus = (props) => {
  const {
    title,
    list,
    setCurrentOrigin,
    toggleActiveSelect,
    setCurrentTitle,
    setHasIntegration,
    selected,
    getOriginThesaurus,
  } = props;

  const setActive = (origin) => {
    const { id, value } = origin.thesaurusOrigin;
    setCurrentOrigin(origin);
    getOriginThesaurus(id, value);
    setHasIntegration(origin.hasIntegration);
    setCurrentTitle(origin.thesaurusOrigin.value);
    toggleActiveSelect(false);
  };

  return (
    <div className="ListItemsThesaurus">
      <h3>{title}</h3>
      <ul>
        {!!list &&
          list.map((origin, i) => {
            return (
              <li
                className={`li-list ${
                  origin.thesaurusOrigin.value === selected
                    ? "activeThesaurus"
                    : ""
                }`}
                key={i}
                onClick={() => setActive(origin)}
              >
                {origin.thesaurusOrigin.value}
                <>
                  {origin.hasIntegration && (
                    <CustomTooltip
                      tipLabel={<InfoIcon />}
                      dataTip="Integrado com outros sistemas"
                      position="right"
                      className="thesaurusTooltip"
                      theme="light"
                    />
                  )}
                </>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ListItemsThesaurus;

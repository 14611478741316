import React, { useContext, useEffect, useState } from "react";
import "./style/ScriptBox.scss";
import AppContext from "../../context/appContext";
import RoundCloseButton from "../RoundCloseButton/RoundCloseButton";

const ScriptBox = (props) => {
  const [stateBox, setStateBox] = useState({ open: false });
  const context = useContext(AppContext);

  const {
    title,
    children,
    clear,
    icon,
    size,
    removePadding,
    allowOverflow,
    resizeHeader,
    marginBottom,
  } = props;

  useEffect(() => {
    setTimeout(() => {
      setStateBox({ open: props.open });
    }, 100);
  }, [props.open]);

  const _closed = () =>
    typeof clear === "function" ? clear() : context.setCurrentStepBox("closed");

  return (
    <div
      className={`ScriptBox ${stateBox.open ? "active" : ""} ${
        !!size ? size : ""
      }`}
    >
      <div className="bgScriptBox" />
      <div className="windowScriptBox">
        <div className={`header ${resizeHeader ? "resizeHeader" : ""}`}>
          {icon}
          <h2>{title}</h2>
          <div className="closeIcon" onClick={() => _closed()}>
            <RoundCloseButton />
          </div>
        </div>

        <div
          className={`content ${removePadding ? "removePadding" : ""} ${
            allowOverflow ? "allowOverflow" : ""
          } ${marginBottom ? "marginBottom" : ""}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ScriptBox;

import React from 'react'
import Select from 'react-select'

const ResearchSelect = (props) => {

  const { item, data, handleChange } = props

  return (
    <Select
      value={item.peopleId}
      className="reactSelect"
      classNamePrefix="reactSelect"
      options={data}
      placeholder="Selecione"
      onChange={handleChange}
      isMulti
      noOptionsMessage={() => 'Nenhum item encontrado'}
    />
  )
}

export default ResearchSelect
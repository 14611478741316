import React, { useContext, useState } from "react";
import "./styles/EditSheetBasicLiteraryWork.scss";
import AppContext from "../../../context/appContext";
import SheetContext from "../../../context/sheetContext";
import { putService } from "../../../services/script-api";
import { sendEvent } from "../../../services/GoogleAnalytics/methods";
import Step1LiteraryWorks from "./Step1LiteraryWorks/Step1LiteraryWorks";

const EditSheetLiteraryWorks = (props) => {
  const { data, id, dataProject } = props;

  const appContext = useContext(AppContext);
  const sheetContext = useContext(SheetContext);

  const newDate = new Date();
  let currentDate = new Date(
    newDate.getTime() - newDate.getTimezoneOffset() * 60000
  ).toISOString();

  const [state, setState] = useState({
    publicDomain: data.publicDomain || false,
    receivedAt: currentDate,
    authors: data.authors || [],
    suggestedBy:
      data.suggestedBy.map((item) => ({
        ...item,
        label: item.name,
        suggesterId: item.id,
        value: item.id,
      })) || [],
    publishers: data.publishers || [],
    title: data.title || "",
    formatId: data.format?.id || "",
    publicationYear: data.publicationYear || "",
  });

  const [stateError, setStateError] = useState({ title: false });
  const [stateAssociationTypes, setStateAssociationTypes] = useState({
    project: false,
    research: false,
    literaryWork: false,
  });
  const [currentFormat, setCurrentFormat] = useState("");

  const handleBlurCreators = () =>
    setStateError({ ...stateError, suggestedBy: !state.suggestedBy.length });

  const getValues = ({ key, subKey, value, validate }) => {
    const handleKeysValue = !!subKey
      ? { ...state[key], [subKey]: value }
      : value;
    setState({ ...state, [key]: handleKeysValue });
    if (validate)
      setStateError({ ...stateError, [key]: !value || !value.length });
  };

  const setAssociationTypes = (key) =>
    setStateAssociationTypes({
      ...stateAssociationTypes,
      [key]: !stateAssociationTypes[key],
    });

  const getCurrentFormat = (value) => setCurrentFormat(value);

  const sendLiteraryWork = () => {
    let obj = {
      ...state,
      id: id,
      authors: state.authors.map((e) => e.value || e.id),
      publishers: state.publishers.map((e) => e.value || e.id),
      ...dataProject.complementaries
    };

    appContext.setShowGlassLoading(true);
    putService("literaryworks", obj)
      .then((res) => {
        appContext.sendMessage(
          "Ficha editada com sucesso!",
          "success"
        );
        appContext.setShowGlassLoading(false);
        appContext.setCurrentStepBox("closed");
        sendEvent("Obra Literária", "Edição", currentFormat);
        sheetContext._getProjectSheet(id, "literaryworks");
      })
      .catch(() => {
        appContext.sendMessage(
          "Error ao editar ficha!",
          "error"
        );
        appContext.setShowGlassLoading(false);
      });
  };

  const isBiggerCurrentYear = () =>
    !!state.publicationYear
      ? state.publicationYear <= newDate.getFullYear()
      : !state.publicationYear;

  const isValidYear = () => state.publicationYear > 0 && state.publicationYear <= newDate.getFullYear();


  const setErrorYear = () =>
    setStateError({ ...stateError, publicationYear: !isValidYear() });

  const isValid = () =>
    !!state.authors?.length &&
    !!state.formatId &&
    !!state.title &&
    isBiggerCurrentYear();

  return (
    <div id="EditSheetLiteraryWork">
      <form className="literaryForm">
        <Step1LiteraryWorks
          state={state}
          stateError={stateError}
          setValues={getValues}
          stateAssociationTypes={stateAssociationTypes}
          setAssociationTypes={setAssociationTypes}
          sendLiteraryWork={sendLiteraryWork}
          handleBlurCreators={handleBlurCreators}
          isValid={isValid}
          isValidYear={setErrorYear}
          getCurrentFormat={getCurrentFormat}
        />
      </form>
    </div>
  );
};

export default EditSheetLiteraryWorks;

import React, { useState, useContext, useEffect } from "react";
import AppContext from "../../context/appContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/CustomDatepicker.scss";
import CalendarIcon from "../VectorIcons/CalendarIcon";
import InputMask from "react-input-mask";
import ptbr from "date-fns/locale/pt-BR";

const CustomDatePicker = (props) => {
  const {
    label,
    handleChangeDate,
    darkBackgroundColor,
    date,
    onCalendarOpen,
    onClose,
    disabled,
    placeholderText,
    startDate,
    maxDate,
    isError,
    clear,
    setClear,
    isScroll
  } = props;

  const initialSelectedDate = !!placeholderText
    ? ""
    : !!date
    ? date
    : new Date();

  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const [isFocused, setIsFocused] = useState(false);
  const [portalVersion, setPortalVersion] = useState(false);

  const context = useContext(AppContext);

  const dayName = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1, 3);
  };

  const handleOnFocus = () => {
    if(!!isScroll) {
      setTimeout(() => {
        let target = document.querySelector(".ScriptBox .windowScriptBox .content")
        if (target) {
          target.scrollTo(0, 400);
        }
      }, 100)
    }
    context.setShowGlassDatepicker(true);
    setIsFocused(true);
  };

  const handleOnBlur = () => {
    context.setShowGlassDatepicker(false);
    setIsFocused(false);
  };

  const handleChange = (date) => {
    handleChangeDate(date);
    setSelectedDate(date);
    !!setClear && setClear(false);
  };

  const handleOnClose = () => {
    handleOnBlur();
    if (!!onClose) {
      onClose();
    }
  };

  const customInput = () => {
    return (
      <InputMask
        mask="99/99/9999"
        maskChar={false}
        alwaysShowMask={false}
        type="text"
      />
    );
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setPortalVersion(width <= 1024);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const clearDate = () => handleChange("");

  useEffect(() => {
    clear && clearDate();
    // eslint-disable-next-line
  }, [clear]);

  useEffect(() => {
    setSelectedDate(date);
    // eslint-disable-next-line
  }, [date]);

  return (
    <div
      className={`customDatepicker ${
        darkBackgroundColor ? "darkBackground" : ""
      } ${isFocused ? "focused" : ""} ${disabled ? "disabled" : ""}`}
    >
      {context.showGlassDatepicker && !portalVersion && (
        <div className="datepickerGlass" />
      )}
      <span className="datepickerLabel">{label}</span>

      {!portalVersion ? (
        <div
          className={`inputBackground ${
            darkBackgroundColor ? "darkBackground" : ""
          } ${isError ? "isError" : ""}`}
        >
          <DatePicker
            locale={ptbr}
            maxDate={maxDate}
            formatWeekDay={(nameOfDay) => dayName(nameOfDay)}
            dateFormat="dd/MM/yyyy"
            dateFormatCalendar="LLLL"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={60}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onCalendarClose={handleOnClose}
            selected={selectedDate}
            onChange={handleChange}
            customInput={customInput()}
            onCalendarOpen={onCalendarOpen}
            disabled={disabled ? disabled : false}
            startDate={startDate ? startDate : maxDate}
            minDate={startDate}
            placeholderText={!!placeholderText ? placeholderText : ""}
          />

          <CalendarIcon color="#17B4E1" />
        </div>
      ) : (
        <div
          className={`withPortalVersion ${
            darkBackgroundColor ? "darkBackground" : ""
          }`}
        >
          <DatePicker
            locale={ptbr}
            maxDate={maxDate}
            formatWeekDay={(nameOfDay) => dayName(nameOfDay)}
            dateFormat="dd/MM/yyyy"
            dateFormatCalendar="LLLL"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={60}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onCalendarClose={handleOnClose}
            selected={selectedDate}
            onChange={handleChange}
            customInput={customInput()}
            onCalendarOpen={onCalendarOpen}
            disabled={disabled ? disabled : false}
            placeholderText={!!placeholderText ? placeholderText : ""}
            startDate={startDate}
            minDate={startDate}
            withPortal
          />

          <CalendarIcon color="#17B4E1" />
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;

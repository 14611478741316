import React, { useEffect, useState } from 'react'
import CheckBox from '../CheckBox/CheckBox'
import { getService } from '../../services/script-api'

const Origins = (props) => {

  const { getOrigins, dataOrigins } = props

  const [state, setState] = useState({ loading: true, list: [] })
  const [stateError, setStateError] = useState(false)

  const getDataOrigin = () => {
    getService('projects/data/origins').then((res) => {
      const data = [...res.data]
      data.forEach((origin, index) => {
        data[index].isChecked = dataOrigins.includes(origin.value);
      })
      setState({
        loading: false,
        list: data
      })
    })
  }

  const getBoxItems = (key, i) => {
    const newState = {...state}
    newState.list[i].isChecked = !newState.list[i].isChecked
    setState(newState)
    getOrigins(newState)
    isErrorOrigins()
  }

  const isErrorOrigins = () => setStateError(state.list.filter((el) => !el.isChecked).length === 2)

  useEffect(() => {
    getDataOrigin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id="Origins">
      <div className="checkBoxes">
        {!!state.list &&
        state.list.map((item, i) => {
          return (
            <CheckBox
              key={i}
              value={item.id}
              label={item.value}
              event={(e) => getBoxItems(e, i)}
              index={i}
              isActive={item.isChecked}
            />
          )
        })}
      </div>

      {stateError && (
        <span className="errorMessage">Este campo é obrigatório</span>
      )}
    </div>
  )
}

export default Origins
import React from "react";

const GloboIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.539 32C57.539 36.1554 57.5177 40.2545 57.1185 44.2286C56.9006 46.3924 55.8232 46.8891 53.8164 47.101C46.877 47.8324 39.1924 48 32 47.9787C24.8076 48 17.123 47.8309 10.1836 47.101C8.17676 46.8876 7.09943 46.3939 6.88152 44.2286C6.48229 40.2545 6.45943 36.1554 6.45943 32C6.45943 27.8446 6.48076 23.7455 6.88 19.7714C7.09638 17.6076 8.17524 17.1109 10.1821 16.899C17.1215 16.1676 24.8061 16 31.9985 16.0213C39.1909 16 46.8754 16.1691 53.8149 16.899C55.8217 17.1124 56.8991 17.6061 57.117 19.7714C57.5162 23.7455 57.539 27.8446 57.539 32ZM64 32C64 49.6229 49.661 64 32 64C14.339 64 0 49.6229 0 32C0 14.3771 14.339 0 32 0C49.661 0 64 14.3771 64 32ZM18.9943 32C18.9943 24.8686 24.8 19.0476 32 19.0476C39.2 19.0476 45.0057 24.8686 45.0057 32C45.0057 39.1314 39.2 44.9524 32 44.9524C24.8 44.9524 18.9943 39.1314 18.9943 32Z"
        fill={props.color}
      />
    </svg>
  );
};

export default GloboIcon;

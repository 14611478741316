import React from "react";
import "./styles/TableListVideos.scss";
import TableLoading from "../../../components/TableLoading/TableLoading";

const TableListVideos = (props) => {
  const { videos, loading } = props;

  const isYoutube = (url) => {
    if (!!url) {
      let p =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      return p.test(url);
    }
  };

  return (
    <section id="TableListVideos">
      <div className="tableContainer">
        {loading ? (
          <TableLoading />
        ) : (
          <div className="tableList">
            <ul className="itemsList">
              <li className="headerList">
                <span className="headerType">TÍTULO</span>
                <span className="headerVersion">PLATAFORMA</span>
              </li>

              {videos?.map((video, index) => {
                return (
                  <li className="groupList" key={index}>
                    <div
                      className="listItem"
                      onClick={() => window.open(video.url, "_blank")}
                    >
                      <div className="nameAndPlatform">
                        <a href>{video.title}</a>
                        <p>
                          {!!video.url && !!isYoutube(video.url)
                            ? "Youtube"
                            : "Vimeo"}
                        </p>
                      </div>
                      
                      {video.password && (
                        <span className="password">{`Senha: ${video.password}`}</span>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default TableListVideos;

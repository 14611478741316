import React, { useState, useContext, useRef } from "react";
import "./styles/TableChapterItem.scss";
import AppContext from "../../../../context/appContext";
import EditIcon from "../../../../components/VectorIcons/EditIcon";
import ChaptersList from "../ChaptersList/ChaptersList";
import TypeCell from "../TypeCell/TypeCell";
import VersionsList from "../VersionsList/VersionsList";
import DeliveryAndRelease from "../DeliveryAndRelease/DeliveryAndRelease";
import DeleteIcon from "../../../../components/VectorIcons/DeleteIcon";
import TableChapterItemDates from "../TableChapterItemDates/TableChapterItemDates";
import NoAttachments from "../NoAttachments/NoAttachments";
import ReleasedListAttachmentsController from "../../../ReleasedTexts/ReleasedListAttachments/ReleasedListAttachmentsController";

const TableChapterItem = (props) => {
  const {
    item,
    isChapter,
    isLastItem,
    isFirstItem,
    mainCellTitle,
    setStateAction,
    typeValues,
    getPlanning,
    handleFileDetails,
    handleEditFile,
    handleOpenReleaseTexts,
    handleDeleteChapter,
    type,
    projectTitle,
  } = props;

  const [stateIsOpen, setStateIsOpen] = useState(false);

  const appContext = useContext(AppContext);

  const deleteButtonRef = useRef(null);

  // const toggleIsOpen = () => setStateIsOpen((prevState) => !prevState);

  const toggleIsOpen = (event) => {
    const deleteRef = deleteButtonRef.current;
    const clickedOnDeleteButton = deleteRef?.contains(event.target);

    if (clickedOnDeleteButton) return;
    setStateIsOpen((prevState) => !prevState);
  };

  const hasAttachments = item.attachments.some((chapter) => {
    return (
      chapter.attachment.fileId ||
      chapter.olderVersions.some((version) => !!version.fileId)
    );
  });

  // const blockIsReleased = item.attachments.every((chapter) => {
  //   const mainAttachmentIsReleased =
  //     chapter.attachment.fileId && chapter.attachment.release;

  //   if (mainAttachmentIsReleased) return mainAttachmentIsReleased;
  //   if (!!chapter.olderVersions)
  //     return chapter.olderVersions.some(
  //       (olderVersion) => !!olderVersion.release
  //     );

  //   return mainAttachmentIsReleased;
  // });

  const isEpisodeDeleteable = !isChapter && isLastItem && !hasAttachments;
  const episodeId = item?.attachments[0]?.attachment?.id;

  const handleEdit = (blockInfo) => {
    appContext.setCurrentStepBox("CreateAndUpdateBlockPlanning");
    setStateAction({ type: "edit", data: blockInfo });
  };

  const compileEpisodes = (attachments) => {
    let files = [];
    if (attachments) {
      attachments.forEach((episode) => {
        files = [
          ...files,
          episode.attachment,
          ...(episode.olderVersions ?? []),
        ];
      });
    }
    return files.filter((fileItem) => !!fileItem.fileId);
  };

  const compiledEpisodes = compileEpisodes(item.attachments);

  const isReleasedOrIsReceived = (key) =>
    item.attachments.every((file) => !!file.attachment[key]);

  // const handleReleaseDate = (lastReleaseDate) => {
  //   if (!isChapter) return lastReleaseDate;
  //   if (isReleasedOrIsReceived("releaseDate") && lastReleaseDate)
  //     return lastReleaseDate;
  // };

  const handleDeliveryDate = (lastReceivedDate) => {
    if (isReleasedOrIsReceived("receivedAt") && lastReceivedDate)
      return lastReceivedDate;
  };

  const handleDate = (date) => {
    if (date) return `${appContext.convertDatePtBr(date)}`;
    return <span className="datePlaceholder">{"-"}</span>;
  };

  const scheduleDelivery = handleDate(item.scheduleDelivery);
  const rescheduledDelivery = handleDate(item.rescheduledDelivery);
  const lastAttachmentReceiveDate = handleDate(
    handleDeliveryDate(item.lastAttachmentReceiveDate)
  );
  const scheduleRelease = handleDate(item.scheduleRelease);
  const rescheduledRelease = handleDate(item.rescheduledRelease);
  // const lastAttachmentReleaseDate = handleDate(
  //   handleReleaseDate(item.lastAttachmentReleaseDate)
  // );
  const lastAttachmentReleaseDate = handleDate(item.lastAttachmentReleaseDate);

  const listValidate = item.attachments.filter(
    (file) => !!file.attachment.fileId
  );
  
  return (
    <li className={`blockListItem ${stateIsOpen ? "open" : ""}`}>
      <div className="blockListItemHeaderNotClick">
        <div className="blockListItemHeader" onClick={toggleIsOpen}>
          <TypeCell
            isChapter={isChapter}
            mainCellTitle={mainCellTitle}
            number={item.blockNumber || item.episodeNumber}
          />

          <span className="cell chaptersNumber mainCell">
            {isChapter && item.numberChaptersBlock}
            <span className="responsive">{isChapter && "Capítulos"}</span>
          </span>

          <TableChapterItemDates
            scheduleDelivery={scheduleDelivery}
            rescheduledDelivery={rescheduledDelivery}
            lastAttachmentReceiveDate={lastAttachmentReceiveDate}
            scheduleRelease={scheduleRelease}
            rescheduledRelease={rescheduledRelease}
            lastAttachmentReleaseDate={lastAttachmentReleaseDate}
            type={type}
          />
        </div>

        <div className="blockActions">
          {type !== "channels" && (
            <div onClick={() => handleEdit(item)}>
              <EditIcon />
            </div>
          )}

          {/* {hasAttachments &&
            (blockIsReleased ? <ReleaseTextIconMarked /> : <ReleaseTextIcon />)} */}

          {isEpisodeDeleteable && type !== "channels" && (
            <div
              onClick={() => handleDeleteChapter(episodeId)}
              ref={deleteButtonRef}
            >
              <DeleteIcon />
            </div>
          )}
        </div>
      </div>

      <div className="responsiveDates" onClick={toggleIsOpen}>
        <DeliveryAndRelease
          showDates={true}
          scheduleDelivery={scheduleDelivery}
          rescheduledDelivery={rescheduledDelivery}
          lastAttachmentReceiveDate={lastAttachmentReceiveDate}
          scheduleRelease={scheduleRelease}
          rescheduledRelease={rescheduledRelease}
          lastAttachmentReleaseDate={lastAttachmentReleaseDate}
        />
      </div>

      <div className="blockListItemContent">
        {isChapter && type === "projectSheet" && (
          <ChaptersList
            attachments={item.attachments}
            handleDate={handleDate}
            noAttachmentsMessage={typeValues.messages.noAttachmentsMessage}
            getPlanning={getPlanning}
            handleEditFile={handleEditFile}
            handleOpenReleaseTexts={handleOpenReleaseTexts}
            handleDeleteChapter={handleDeleteChapter}
            isLastTableItem={isLastItem}
            isFirstTableItem={isFirstItem}
          />
        )}

        {!isChapter && !!compiledEpisodes.length && type === "projectSheet" && (
          <>
          <div>BBBBB</div>
          <VersionsList
            handleFileDetails={handleFileDetails}
            attachments={compiledEpisodes}
            handleDate={handleDate}
            getPlanning={getPlanning}
            handleEditFile={handleEditFile}
            handleOpenReleaseTexts={handleOpenReleaseTexts}
            isLastTableItem={isLastItem}
          />
          </>
          
        )}

        {type === "channels" && !!listValidate?.length && (
          <ReleasedListAttachmentsController
            files={listValidate}
            title={projectTitle}
            type={type}
            isChapter={isChapter}
          />
        )}

        {((!isChapter && !compiledEpisodes.length) ||
          (!listValidate?.length && type === "channels")) && (
          <NoAttachments message={typeValues.messages.noAttachmentsMessage} />
        )}
      </div>
    </li>
  );
};

export default TableChapterItem;

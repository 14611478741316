import React, { useState, useContext, useEffect } from "react";
import "./styles/CreateActivity.scss";
import BoxFooter from "../../../../components/BoxFooter/BoxFooter";
import Button from "../../../../components/Button/Button";
import AppContext from "../../../../context/appContext";
import ActivityStatusIcon from "../../../../components/VectorIcons/ActivityStatusIcon";
import ActivitiesIcon from "../../../../components/VectorIcons/ActivitiesIcon";
import CreatorIcon from "../../../../components/VectorIcons/CreatorIcon";
import AttachmentIcon from "../../../../components/VectorIcons/AttachmentIcon";
import DownloadIcon from "../../../../components/VectorIcons/DownloadIcon";
import Textarea from "../../../../components/Textarea/Textarea";
import CloseIcon from "../../../../components/VectorIcons/CloseIcon";
import StatusSelect from "../StatusSelect/StatusSelect";
import GroupSelect from "../GroupSelect/GroupSelect";
import AnalystSelect from "../AnalystSelect/AnalystSelect";
import RadioInput from "../../../../components/RadioInput/RadioInput";
import { getService, postService } from "../../../../services/script-api";
import ScriptBoxCaller from "../../../../components/ScriptBoxCaller/ScriptBoxCaller";
import UserContext from "../../../../context/userContext";
import { sendEvent } from "../../../../services/GoogleAnalytics/methods";
import UploadSection from "../../../../components/UploadSection/UploadSection";
import UploadService from "../../../../components/UploadService/UploadService";

const compileFiles = (files) => {
  let f = [];
  if (!!files.length) {
    files.forEach((e) => {
      if (e?.parent?.isActive) {
        f.push(e?.parent?.id);
      }
      if (!!e?.version?.length) {
        e.version.forEach((v) => {
          if (v.isActive) {
            f.push(v.id);
          }
        });
      }
    });
  }

  return f;
};

const ListAttachmentsVersion = (props) => {
  const {
    indexVersion,
    indexFile,
    appContext,
    version,
    toggleActiveVersionCheckBox,
  } = props;

  return (
    <div className="attachmentBox" key={indexVersion}>
      <span className="attachmentType">{version.attachmentType.name}</span>
      <span className="attachmentName">
        <DownloadIcon color="#0098CD" size={20} />
        {version.fileName}
      </span>
      <span className="receivedAt">
        {appContext.formatDate(appContext.convertDatePtBr(version.receivedAt))}
      </span>

      <div
        className="removeAttachment"
        onClick={() =>
          toggleActiveVersionCheckBox(version, indexFile, indexVersion)
        }
      >
        <CloseIcon color="#ADAFEF" size={16} strokeWidth={3} />
      </div>
    </div>
  );
};

const ListAttachmentsParent = (props) => {
  const { indexFile, file, appContext, toggleActiveCheckBox } = props;

  return (
    <div className="attachmentBox" key={indexFile}>
      <span className="attachmentType">{file?.parent?.attachmentType?.name}</span>
      <span className="attachmentName">
        <DownloadIcon color="#0098CD" size={20} />
        {file.parent.fileName}
      </span>
      <span className="receivedAt">
        {appContext.formatDate(
          appContext.convertDatePtBr(file.parent.receivedAt)
        )}
      </span>

      <div
        className="removeAttachment"
        onClick={() => toggleActiveCheckBox(file.parent, indexFile)}
      >
        <CloseIcon color="#ADAFEF" size={16} strokeWidth={3} />
      </div>
    </div>
  );
};

const HeaderAttachments = (props) => {
  const {
    state,
    stateTypes,
    handleChangeActivityType,
    handleChangeDescription,
    stateStatus,
    getStatus,
    stateGroups,
    stateSelectedGroup,
    getGroupsId,
    stateUsers,
    getUsersId,
    projectType,
  } = props;

  return (
    <>
      {projectType !== "Pesquisa" && (
        <div className="field">
          <span className="fieldLabel">
            <ActivitiesIcon color="#ADAFEF" size={22} />
            Tipo de atividade
          </span>

          <div className="insideContainer">
            <RadioInput
              options={stateTypes || []}
              value={state.type}
              handleChange={handleChangeActivityType}
            />
          </div>

          <div className="insideContainer">
            <Textarea
              maxRows={16}
              label="Descrição da atividade"
              placeholder="Descreva os detalhes da atividade"
              handleChange={handleChangeDescription}
              value={state.description}
            />
          </div>
        </div>
      )}

      <div className="field">
        <span className="fieldLabel">
          <ActivityStatusIcon color="#ADAFEF" size={20} />
          Status
        </span>

        <div className="insideContainer">
          <StatusSelect
            options={stateStatus}
            value={!!state.status ? state.status : stateStatus[0]}
            handleChange={getStatus}
            placeholder="Selecione"
          />
        </div>
      </div>

      <div className="field">
        <span className="fieldLabel">
          <CreatorIcon color="#ADAFEF" size={20} />
          Analista
        </span>
        <div className="outerContainer">
          <div className="insideContainer">
            <GroupSelect
              options={stateGroups}
              value={
                !!stateSelectedGroup
                  ? stateSelectedGroup
                  : stateGroups.filter((item) => item.label === props.type)[0]
              }
              getGroupsId={getGroupsId}
              placeholder="Selecione o grupo"
            />
          </div>

          <div className="insideContainer">
            <AnalystSelect
              options={stateUsers.filter((user) =>
                user.groups.includes(stateSelectedGroup.value)
              )}
              value={state.userId}
              getUsersId={getUsersId}
              placeholder="Selecione o analista"
              isClearable={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const CreateActivity = (props) => {
  const {
    files,
    toggleActiveVersionCheckBox,
    toggleActiveCheckBox,
    listAttachments,
    clearActive,
    currentProject,
    getFiles,
    setLoading,
    history,
    projectType,
  } = props;

  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  const initialState = {
    type: projectType === "Pesquisa" ? 1 : "",
    description: "",
    group: "",
    status: "",
    userId: "",
    attachments: [],
  };
  const [stateTypes, setStateTypes] = useState([]);
  const [stateGroups, setStateGroups] = useState([]);
  const [stateStatus, setStateStatus] = useState([]);
  const [stateUsers, setStateUsers] = useState([]);
  const [stateSelectedGroup, setStateSelectedGroup] = useState("");
  const [_stateAttachments, _setStateAttachments] = useState([]);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    const loading =
      !!stateTypes.length &&
      !!stateGroups.length &&
      !!stateStatus.length &&
      stateUsers.length;
    appContext.setShowGlassLoading(!loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateTypes, stateGroups, stateStatus, stateUsers]);

  const getOptions = (path, setter) => {
    getService(`${path}`).then((response) => {
      let compiledData = response.data.map((item) => {
        return {
          id: item.id,
          name: item.value,
          label: item.value,
          value: item.id,
          ...item,
        };
      });

      setter(compiledData);
    });
  };

  const getUsers = () => {
    getService("users/analysts", {
      ActivityGroups: userContext.me.user.activityGroups,
    }).then((response) => {
      let compiledUsers = response.data.map((item) => {
        return { label: item.name, value: item.id, groups: item.groups };
      });

      setStateUsers(compiledUsers);
    });
  };

  useEffect(() => {
    getOptions("activities/types", setStateTypes);
    getOptions("activities/groups", setStateGroups);
    getOptions("activities/status", setStateStatus);
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeActivityType = (id, value) => {
    setState({ ...state, type: id });
  };

  const handleChangeDescription = (ev) => {
    setState({ ...state, description: ev.target.value });
  };

  const getStatus = (item) =>
    setState({ ...state, status: { value: item?.id, label: item?.name } });
  const getGroupsId = (item) => {
    setStateSelectedGroup({ value: item?.id, label: item?.name });
    setState({ ...state, userId: initialState.userId });
  };

  const getUsersId = (item) => {
    if (item) {
      setState({ ...state, userId: { value: item.value, label: item.label } });
    } else {
      setState({ ...state, userId: initialState.userId });
    }
  };

  const sendActivityCreationAnalytics = () => {
    if (!!stateTypes.length) {
      const [type] = stateTypes.filter((type) => type.id === state.type);
      sendEvent(
        "Atividades - Criação de atividade (Projeto)",
        `Tipo: ${type.value} - Grupo: ${stateSelectedGroup.label}`
      );
    }
  };

  const sendActivity = (filesId) => {
    if (!_stateAttachments.length) {
      filesId.preventDefault();
    }
    appContext.setShowGlassLoading(true);
    let obj = {
      status: state.status.value,
      type: state.type,
      userId: state.userId.value || null,
      group: stateSelectedGroup.value,
      contentId: currentProject,
      description: state.description,
      attachments:
        projectType !== "Pesquisa" && !!files?.length
          ? compileFiles(files)
          : !!_stateAttachments.length
          ? filesId
          : [],
    };

    postService(
      projectType !== "Pesquisa"
        ? "projects/activities"
        : "researches/activities",
      obj
    )
      .then(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(
          `Atividade de ${projectType} cadastrada com sucesso!`
        );
        appContext.setCurrentStepBox("closed");
        if (projectType !== "Obra Literária" && projectType !== "Pesquisa") {
          sendActivityCreationAnalytics();
          if (!!setLoading) setLoading();
          if (!!clearActive) clearActive();
          if (!!getFiles) getFiles();
        } else {
          sendEvent(
            `Atividades - Criação de atividade (${
              projectType === "Obra Literária" ? "obra literária" : "pesquisa"
            })`,
            `Grupo: ${stateSelectedGroup.label}`
          );
        }

        if (!!history) history.push("/atividades");
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(
          `Erro ao cadastrar uma atividade de ${projectType}!`,
          "error"
        );
      });
  };

  const makeFiles = (files) => {
    appContext.setShowGlassLoading(true);

    const attachments = [];
    files.forEach((file) => {
      attachments.push({
        contentId: currentProject,
        fileId: file._meta.tempFileName,
        fileName: file.originalName,
        version: file.version,
        receivedAt: file.receiveAt,
        attachmentTypeId: "",
        blockNumber: 1,
        chapterNumber: 0,
      });
    });
    return attachments;
  };

  const sendAttachments = (e) => {
    e.preventDefault();

    postService("contents/attachments", makeFiles(_stateAttachments))
      .then((res) => {
        sendActivity(res.data);
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Erro ao fazer upload de anexo(s)", "error");
      });
  };

  const handleFiles = (files) => _setStateAttachments(files);

  const getDuplicateFiles = (files) => {
    let filesName = "";
    files.map((e) => (filesName += `<p>${e.name} ja foi selecionado!</p>`));
    appContext.sendMessage(filesName, "error");
  };

  const submitIsValid = () => {
    const incompleteStatus =
      state.status.value === 1 ||
      state.status.value === 4 ||
      state.status.value === 5;

    if (incompleteStatus) {
      return !!state.type && !!stateSelectedGroup.value;
    } else if (!incompleteStatus && !!state.status) {
      return (
        !!state.type &&
        !!stateSelectedGroup.value &&
        !!state.status &&
        !!state.userId &&
        (!!compileFiles(files).length ||
          !!_stateAttachments.length ||
          projectType === "Pesquisa" ||
          projectType === "Obra Literária")
      );
    }
  };

  useEffect(() => {
    !state.status && !!stateStatus.length && getStatus(stateStatus[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStatus]);

  useEffect(() => {
    !state.groups &&
      !!stateGroups.length &&
      getGroupsId(stateGroups.filter((item) => item.label === props.type)[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateGroups]);

  return (
    <form
      onSubmit={(e) =>
        !!_stateAttachments.length ? sendAttachments(e) : sendActivity(e)
      }
    >
      <div className="createActivity">
        <div className="createActivityContainer">
          <HeaderAttachments
            state={state}
            stateTypes={
              projectType !== "Obra Literária"
                ? stateTypes
                : stateTypes
                    ?.filter((item) => item?.id === 1 || item?.id === 3)
                    ?.map((item) => ({
                      id: item?.id,
                      value: item?.label,
                    }))
            }
            handleChangeActivityType={handleChangeActivityType}
            handleChangeDescription={handleChangeDescription}
            stateStatus={stateStatus}
            getStatus={getStatus}
            stateGroups={stateGroups}
            stateSelectedGroup={stateSelectedGroup}
            getGroupsId={getGroupsId}
            stateUsers={stateUsers}
            getUsersId={getUsersId}
            projectType={projectType}
          />

          <div className="field">
            {!!files?.length && (
              <span className="fieldLabel">
                <AttachmentIcon color="#ADAFEF" size={20} />
                Anexos
              </span>
            )}

            <div className="insideContainer">
              <div className="attachmentBoxes">
                {!!files &&
                  files?.map((file, index) => {
                    return (
                      <React.Fragment key={index}>
                        {file?.parent?.isActive && (
                          <ListAttachmentsParent
                            indexFile={index}
                            file={file}
                            appContext={appContext}
                            toggleActiveCheckBox={toggleActiveCheckBox}
                          />
                        )}

                        {file?.version?.map((version, i) => {
                          return (
                            <React.Fragment key={i}>
                              {version.isActive && (
                                <ListAttachmentsVersion
                                  indexVersion={i}
                                  indexFile={index}
                                  appContext={appContext}
                                  version={version}
                                  toggleActiveVersionCheckBox={
                                    toggleActiveVersionCheckBox
                                  }
                                />
                              )}
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
              </div>
              {projectType !== "Pesquisa" && !!files?.length && (
                <span className="addAttachment">
                  <ScriptBoxCaller
                    callerText="Selecionar um anexo"
                    event={() => listAttachments()}
                  />
                </span>
              )}
              {projectType === "Pesquisa" && (
                <div className="field">
                  <div className="insideContainer">
                    <UploadSection>
                      <UploadService
                        handleOnComplete={handleFiles}
                        getDuplicateFiles={getDuplicateFiles}
                      />
                    </UploadSection>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <BoxFooter>
          <Button
            text="Salvar atividade"
            toValidate={true}
            isValidForm={submitIsValid()}
            classButton="buttonGreen large"
            typeButton="submit"
            dataQA="button-prospect-new-save"
          />
        </BoxFooter>
      </div>
    </form>
  );
};

export default CreateActivity;

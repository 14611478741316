import React, { useState, useContext, useEffect } from "react";
import "./styles/EditActivity.scss";
import BoxFooter from "../../../../components/BoxFooter/BoxFooter";
import Button from "../../../../components/Button/Button";
import AppContext from "../../../../context/appContext";
import ActivityStatusIcon from "../../../../components/VectorIcons/ActivityStatusIcon";
import ActivitiesIcon from "../../../../components/VectorIcons/ActivitiesIcon";
import CreatorIcon from "../../../../components/VectorIcons/CreatorIcon";
import AttachmentIcon from "../../../../components/VectorIcons/AttachmentIcon";
import DownloadIcon from "../../../../components/VectorIcons/DownloadIcon";
import Textarea from "../../../../components/Textarea/Textarea";
import CloseIcon from "../../../../components/VectorIcons/CloseIcon";
import RadioInput from "../../../../components/RadioInput/RadioInput";
import {
  getService,
  postService,
  putService,
} from "../../../../services/script-api";
import ScriptBoxCaller from "../../../../components/ScriptBoxCaller/ScriptBoxCaller";
import StatusSelect from "../../NewActivity/StatusSelect/StatusSelect";
import GroupSelect from "../../NewActivity/GroupSelect/GroupSelect";
import AnalystSelect from "../../NewActivity/AnalystSelect/AnalystSelect";
import ScriptBox from "../../../../components/ScriptBox/ScriptBox";
import ModalHeader from "../../NewActivity/ModalHeader/ModalHeader";
import UsersContext from "../../../../context/userContext";
import DeleteIcon from "../../../../components/VectorIcons/DeleteIcon";
import UploadSection from "../../../../components/UploadSection/UploadSection";
import UploadService from "../../../../components/UploadService/UploadService";

const EditModalActivity = (props) => {
  const {
    files,
    filesAttachment,
    toggleActiveVersionCheckBox,
    toggleActiveCheckBox,
    listAttachments,
    clearActive,
    currentActivity,
    _getActvity,
    open,
    contentInfo,
    type,
  } = props;

  const appContext = useContext(AppContext);
  const usersContext = useContext(UsersContext);

  const initialState = {
    type: currentActivity.type.id,
    description: "",
    group: "",
    status: {
      label: currentActivity.status.value,
      value: currentActivity.status.id,
    },
    userId: !!currentActivity.user
      ? { label: currentActivity.user.name, value: currentActivity.user.id }
      : "",
    attachments: [],
  };

  const [stateTypes, setStateTypes] = useState([]);
  const [stateGroups, setStateGroups] = useState([]);
  const [stateStatus, setStateStatus] = useState([]);
  const [stateUsers, setStateUsers] = useState({ loading: true, list: [] });
  const [stateSelectedGroup, setStateSelectedGroup] = useState({
    label: currentActivity.group.value,
    value: currentActivity.group.id,
  });
  const [state, setState] = useState(initialState);
  const [_stateAttachments, _setStateAttachments] = useState([]);
  const [isValidFile, setIsValidFile] = useState(true);
  const [currentFiles, setCurrentFiles] = useState([]);

  useEffect(() => {
    if (contentInfo.info.description) {
      setState({ ...state, description: contentInfo.info.description });
    }
    // eslint-disable-next-line
  }, [contentInfo.info]);

  useEffect(() => {
    _setStateAttachments(filesAttachment);
  }, [filesAttachment]);

  const getOptions = (path, setter) => {
    getService(`${path}`).then((response) => {
      let compiledData = response.data.map((item) => {
        return {
          id: item.id,
          name: item.value,
          label: item.value,
          value: item.id,
          ...item,
        };
      });

      setter(compiledData);
    });
  };

  const getUsers = () => {
    getService("users/analysts", {
      ActivityGroups: usersContext.me.user.activityGroups,
    }).then((response) => {
      let compiledUsers = response.data.map((item) => {
        return { label: item.name, value: item.id, groups: item.groups };
      });

      setStateUsers({ loading: false, list: compiledUsers });
    });
  };

  useEffect(() => {
    getOptions("activities/types", setStateTypes);
    getOptions("activities/groups", setStateGroups);
    getOptions("activities/status", setStateStatus);
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeActivityType = (id, value) => {
    setState({ ...state, type: id });
  };

  const handleChangeDescription = (ev) => {
    setState({ ...state, description: ev.target.value });
  };

  const getStatus = (item) =>
    setState({ ...state, status: { value: item.id, label: item.name } });
  const getGroupsId = (item) => {
    setStateSelectedGroup({ value: item.id, label: item.name });
    setState({ ...state, userId: "" });
  };

  useEffect(() => {
    !state.status && !!stateStatus.length && getStatus(stateStatus[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStatus]);

  useEffect(() => {
    // !state.groups && !!stateGroups.length && getGroupsId(stateGroups.filter(item => item.label === props.type)[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateGroups]);

  const getUsersId = (item) => {
    if (item) {
      setState({ ...state, userId: { value: item.value, label: item.label } });
    } else {
      setState({ ...state, userId: "" });
    }
  };

  const compileFiles = (files) => {
    let f = [];
    files.forEach((e) => {
      if (e.parent.isActive) {
        f.push(e.parent.id);
      }
      e.version.forEach((v) => {
        if (v.isActive) {
          f.push(v.id);
        }
      });
    });

    return f;
  };

  const sendActivity = (filesId) => {
    if (type !== "Pesquisa") {
      filesId.preventDefault();
    }
    appContext.setShowGlassLoading(true);

    let obj = {
      status: state.status.value,
      type: type === "Pesquisa" ? 1 : state.type,
      userId: state.userId.value,
      id: currentActivity.id,
      group: stateSelectedGroup.value,
      contentId: currentActivity.projectId,
      description: state.description,
      attachments: type === "Pesquisa" ? filesId : compileFiles(files),
    };

    putService(
      type !== "Pesquisa" ? "projects/activities" : "researches/activities",
      obj
    )
      .then(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(`Atividade de ${type} editada com sucesso!`);
        appContext.setCurrentStepBox("closed");
        if (type !== "Pesquisa") clearActive();
        _getActvity();
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(
          `Erro ao editar uma atividade de ${type}!`,
          "error"
        );
      });
  };

  const handleFiles = (files) => {
    const newFiles = [];

    if (!!files.length) setIsValidFile(false);
    files.forEach((f) => {
      if (!!f.isSent) {
        newFiles.push(f);
      }
    });

    setCurrentFiles(newFiles);
    if (!!newFiles.length) setIsValidFile(true);
  };

  const handleRemoveFile = (file) => {
    const newState = [..._stateAttachments];
    _setStateAttachments(newState.filter((e) => e !== file));
  };

  const getDuplicateFiles = (files) => {
    let filesName = "";
    files.map((e) => (filesName += `<p>${e.name} ja foi selecionado!</p>`));
    appContext.sendMessage(filesName, "error");
  };

  const makeFiles = (files) => {
    appContext.setShowGlassLoading(true);

    const attachments = [];
    files.forEach((file) => {
      if (!file.id) {
        attachments.push({
          contentId: currentActivity.projectId,
          fileId: file._meta.tempFileName,
          fileName: file.originalName,
          version: file.version,
          receivedAt: file.receiveAt,
          attachmentTypeId: "",
          blockNumber: 1,
          chapterNumber: 0,
        });
      }
    });
    return attachments;
  };

  const getFilesId = (files) => {
    let f = [];
    files.forEach((file) => {
      f.push(file.id);
    });

    return f;
  };

  const sendAttachments = (e) => {
    e.preventDefault();

    if (!!currentFiles.length) {
      postService("contents/attachments", makeFiles(currentFiles))
        .then((res) => {
          sendActivity([...res.data, ...getFilesId(_stateAttachments)]);
        })
        .catch(() => {
          appContext.setShowGlassLoading(false);
        });
    } else {
      sendActivity(getFilesId(_stateAttachments));
    }
  };

  const submitIsValid = () => {
    const incompleteStatus =
      state.status.value === 1 ||
      state.status.value === 4 ||
      state.status.value === 5;

    if (incompleteStatus) {
      return !!state.type && !!stateSelectedGroup.value;
    } else if (!incompleteStatus && !!state.status) {
      return (
        !!state.type &&
        !!stateSelectedGroup.value &&
        !!state.status &&
        !!state.userId &&
        (!!compileFiles(files).length ||
          !!currentFiles.length ||
          type === "Obra Literária")
      );
    }
  };

  useEffect(() => {
    appContext.setShowGlassLoading(
      (files.length === 0 && contentInfo.loading) || stateUsers.loading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, stateUsers]);

  return (
    <ScriptBox
      open={open}
      icon={
        <ModalHeader
          type={currentActivity.contentType}
          title={currentActivity.contentTitle}
          creators={contentInfo.info.creators}
          format={contentInfo.info.format}
        />
      }
      resizeHeader={true}
      removePadding={true}
      marginBottom={true}
    >
      <form
        onSubmit={(e) =>
          type === "Pesquisa" ? sendAttachments(e) : sendActivity(e)
        }
      >
        <div className="createActivity">
          <div className="createActivityContainer">
            {type !== "Pesquisa" && (
              <div className="field">
                <span className="fieldLabel">
                  <ActivitiesIcon color="#ADAFEF" size={22} />
                  Tipo de atividade
                </span>

                <div className="insideContainer">
                  <RadioInput
                    options={
                      type !== "Obra Literária"
                        ? stateTypes
                        : stateTypes
                            ?.filter((item) => item?.id === 1 || item?.id === 3)
                            ?.map((item) => ({
                              id: item?.id,
                              value: item?.label,
                            })) || []
                    }
                    value={state.type}
                    handleChange={handleChangeActivityType}
                    disabled={true}
                  />
                </div>

                <div className="insideContainer">
                  <Textarea
                    maxRows={16}
                    label="Descrição da atividade"
                    placeholder="Descreva os detalhes da atividade"
                    handleChange={handleChangeDescription}
                    value={state.description}
                  />
                </div>
              </div>
            )}

            <div className="field">
              <span className="fieldLabel">
                <ActivityStatusIcon color="#ADAFEF" size={20} />
                Status
              </span>

              <div className="insideContainer">
                <StatusSelect
                  options={stateStatus}
                  value={!!state.status ? state.status : stateStatus[0]}
                  handleChange={getStatus}
                  placeholder="Selecione"
                />
              </div>
            </div>

            <div className="field">
              <span className="fieldLabel">
                <CreatorIcon color="#ADAFEF" size={20} />
                Analista
              </span>
              <div className="outerContainer">
                <div className="insideContainer">
                  <GroupSelect
                    options={stateGroups}
                    value={
                      !!stateSelectedGroup
                        ? stateSelectedGroup
                        : stateGroups.filter(
                            (item) => item.label === props.type
                          )[0]
                    }
                    getGroupsId={getGroupsId}
                    placeholder="Selecione o grupo"
                  />
                </div>

                <div className="insideContainer">
                  <AnalystSelect
                    options={stateUsers.list.filter((user) =>
                      user.groups.includes(stateSelectedGroup.value)
                    )}
                    value={state.userId}
                    getUsersId={getUsersId}
                    placeholder="Selecione o analista"
                    isClearable={true}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <span className="fieldLabel">
                <AttachmentIcon color="#ADAFEF" size={20} />
                Anexos
              </span>
              {type !== "Pesquisa" && (
                <div className="insideContainer">
                  <div className="attachmentBoxes">
                    {!!files &&
                      files.map((file, index) => {
                        return (
                          <React.Fragment key={index}>
                            {file.parent.isActive && (
                              <div className="attachmentBox" key={index}>
                                <span className="attachmentType">
                                  {file.parent?.attachmentType?.name}
                                </span>
                                <span className="attachmentName">
                                  <DownloadIcon color="#0098CD" size={20} />
                                  {file.parent.fileName}
                                </span>
                                <span className="receivedAt">
                                  {appContext.formatDate(
                                    appContext.convertDatePtBr(
                                      file.parent.receivedAt
                                    )
                                  )}
                                </span>

                                <div
                                  className="removeAttachment"
                                  onClick={() =>
                                    toggleActiveCheckBox(file.parent, index)
                                  }
                                >
                                  <CloseIcon
                                    color="#ADAFEF"
                                    size={16}
                                    strokeWidth={3}
                                  />
                                </div>
                              </div>
                            )}

                            {file.version.map((version, i) => {
                              return (
                                <React.Fragment key={i}>
                                  {version.isActive && (
                                    <div className="attachmentBox" key={i}>
                                      <span className="attachmentType">
                                        {version.attachmentType.name}
                                      </span>
                                      <span className="attachmentName">
                                        <DownloadIcon
                                          color="#0098CD"
                                          size={20}
                                        />
                                        {version.fileName}
                                      </span>

                                      <span className="receivedAt">
                                        {appContext.formatDate(
                                          appContext.convertDatePtBr(
                                            version.receivedAt
                                          )
                                        )}
                                      </span>

                                      <div
                                        className="removeAttachment"
                                        onClick={() =>
                                          toggleActiveVersionCheckBox(
                                            version,
                                            index,
                                            i
                                          )
                                        }
                                      >
                                        <CloseIcon
                                          color="#ADAFEF"
                                          size={16}
                                          strokeWidth={3}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                  </div>
                  <span className="addAttachment">
                    <ScriptBoxCaller
                      callerText="Selecionar um anexo"
                      event={() => listAttachments()}
                    />
                  </span>
                </div>
              )}
              {type === "Pesquisa" && (
                <div className="field">
                  <div className="listFiles">
                    <div className="insideContainer">
                      {_stateAttachments.map((file, i) => {
                        return (
                          <div className="containerFiles" key={i}>
                            <div className="fileInfo">
                              <h2>{file.fileName || file.originalName}</h2>
                              <span className="receivedAtFile">
                                {`Entregue em `}
                                {appContext.formatDate(
                                  appContext.convertDatePtBr(file.receivedAt)
                                )}
                                {` às `}
                                {appContext
                                  .convertDateAndTimePtBr(file.receivedAt)
                                  .slice(10, 16)}
                              </span>
                            </div>

                            {state.status.label !== "Concluída" && (
                              <div
                                className="deleteFiles"
                                onClick={() => handleRemoveFile(file)}
                              >
                                <DeleteIcon color="#000000" />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="insideContainer">
                    <UploadSection>
                      <UploadService
                        handleOnComplete={handleFiles}
                        getDuplicateFiles={getDuplicateFiles}
                      />
                    </UploadSection>
                  </div>
                </div>
              )}
            </div>
          </div>

          <BoxFooter>
            <Button
              text="Salvar atividade"
              toValidate={true}
              isValidForm={
                submitIsValid() && (isValidFile || type !== "Pesquisa")
              }
              classButton="buttonGreen large"
              typeButton="submit"
              dataQA="button-prospect-new-save"
            />
          </BoxFooter>
        </div>
      </form>
    </ScriptBox>
  );
};

export default EditModalActivity;

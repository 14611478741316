import React from "react";
import "./styles/ResearcherName.scss";

const ResearcherName = (props) => {
  const { index, length, researcher } = props;

  return (
    <span className="researcher">
      <span>{researcher}</span>
      {index + 1 < length ? ", " : ""}
    </span>
  );
};

export default ResearcherName;

import React, { useContext, useEffect } from "react";
import "./styles/SheetActivityItem.scss";
import AppContext from "../../../context/appContext";
import { Link } from "react-router-dom";
import AttachmentsDropdown from "../../../components/AttachmentsDropdown/AttachmentsDropdown";
import Avatar from "../../../components/Avatar/Avatar";

const SheetActivityItem = (props) => {
  const { item, contentType } = props;

  const appContext = useContext(AppContext);

  const remakeTypeEvaluations = (type) => {
    const types = {
      "Relatório de Vídeo": "relatorio-de-video",
      "Relatório de Texto": "relatorio-de-texto",
      Certificação: "certificacao",
      Parecer: "parecer-da-criacao",
    };
    return types[type.value];
  };

  const verifyFormat = (value) =>
    !!value.attachments[0]?.attachmentType &&
    value.attachments[0]?.attachmentType.name === "Capítulo/Episódio";

  useEffect(() => {
    const isModalOpen = appContext.current.step === "activitiesAttachments";

    return () => {
      if (isModalOpen) appContext.setCurrentStepBox("closed");
    };
  });

  return (
    <div className="sheetActivityItem">
      <div className="cardContent">
        <div>
          <div className="attachmentName">
            <Link
              to={`/atividades/${contentType}/${remakeTypeEvaluations(
                item.type
              )}/${item.evaluationId}`}
            >
              {item.type.value}
            </Link>
          </div>

          <span className="attachmentDate">
            {appContext.convertDatePtBr(item.doneDateTime)}
          </span>
        </div>

        <div className="activityAttachments">
          {!!item.attachments && !!item.attachments.length && (
            <>
              <div className="lastFile">
                {verifyFormat(item) && (
                  <>
                    <span className="file">
                      Cap {item.attachments[0].chapterNumber}
                    </span>
                    <span className="fileBlock">
                      Bloco {item.attachments[0].blockNumber}
                    </span>
                  </>
                )}
                {verifyFormat(item) ? (
                  <span className="fileVersion">
                    {item.attachments[0].version}º Versão
                  </span>
                ) : (
                  <span className="fileVersion">
                    {item.attachments[0].attachmentType?.name} -{" "}
                    {item.attachments[0].version}º Versão
                  </span>
                )}
              </div>

              {item.attachments?.length > 1 && (
                <AttachmentsDropdown attachments={item.attachments} />
              )}
            </>
          )}
        </div>

        <div className="avatar">
          {!!item.user && <Avatar letter={item.user.name.slice(0, 1)} />}
          <span>{!!item.user.name ? item.user.name : "Sem analista"}</span>
        </div>
      </div>
    </div>
  );
};

export default SheetActivityItem;

import React from "react";
import "./styles/HelpLabel.scss";

const HelpLabel = (props) => {
  const { label, children } = props;

  return (
    <span className="helpLabel">
      {label}
      {children}
    </span>
  );
};

export default HelpLabel;

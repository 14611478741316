import React, { useContext, useEffect } from "react";
import "./styles/Header.scss";
import { NavLink, useLocation } from "react-router-dom";
import RegisterIcon from "../VectorIcons/RegisterIcon";
import ActivitiesIcon from "../VectorIcons/ActivitiesIcon";
import SearchHeader from "../SearchHeader/SearchHeader";
import SearchIcon from "../VectorIcons/SearchIcon";
import MobileSearch from "../MobileSearch/MobileSearch";
import ConflictsIcon from "../VectorIcons/ConflictsIcon";
import AppContext from "../../context/appContext";
import UserContext from "../../context/userContext";
import ReleaseTextIcon from "../VectorIcons/ReleaseTextIcon";
import IconProjectExternal from "../VectorIcons/IconProjectExternal";
import PermissionNavLinkItem from "./PermissionNavLinkItem/PermissionNavLinkItem";
import LogoLink from "./LogoLink/LogoLink";
import SubMenu from "./SubMenu/SubMenu";
import AvatarData from "./AvatarData/AvatarData";

const Header = (props) => {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  const location = useLocation();

  const isNotTextRelaseRole = (() => {
    if (!userContext.me.loading)
      return !userContext.monoPermission("TextRelease");
  })();

  const haveManyPermissions =
    isNotTextRelaseRole &&
    ["Activities", "ConflictMap", "TextRelease", "ExternalProject"].every(
      (role) => userContext.havePermission([role]).length === 0
    );

  const haveManyPermissionsClassName = haveManyPermissions && "manyItems";

  const isExternal =
    userContext.havePermission(["ExternalProject"]).length === 0;

  const permissionedPages = [
    {
      role: ["Activities"],
      to: "/atividades",
      label: "Atividades",
      icon: ActivitiesIcon,
    },
    {
      role: ["ConflictMap"],
      to: "/mapa-conflitos",
      label: "Conflitos",
      icon: ConflictsIcon,
    },
    {
      role: ["ExternalProject"],
      to: "/projetos-externos",
      label: "Projetos Externos",
      icon: IconProjectExternal,
    },
    {
      role: ["TextRelease"],
      to: "/textos-liberados",
      label: "Textos Liberados",
      icon: ReleaseTextIcon,
    },
  ];

  const subMenuLinks = [
    {
      to: "/produtoras",
      label: "Produtoras",
    },
    {
      to: "/proponentes",
      label: "Proponentes",
    },
  ];

  useEffect(() => {
    const body = document.querySelector("body");
    const currentStep = appContext.current.step;
    const hasCurrentStep = !!currentStep;
    const canScroll = !hasCurrentStep || currentStep === "closed";
    body.style.overflow = !!canScroll ? "auto" : "hidden";
    // eslint-disable-next-line
  }, [appContext.current]);

  if (appContext.showHeader)
    return (
      <header id="Header" className={`${haveManyPermissionsClassName}`}>
        {appContext.isFull && (
          <>
            <LogoLink />

            {!userContext.me.loading && isNotTextRelaseRole && <SearchHeader />}
          </>
        )}

        {!userContext.me.loading && (
          <>
            {appContext.showMobileSearch && <MobileSearch />}

            <nav onClick={() => appContext.setRedirectSearch(false)}>
              {isNotTextRelaseRole && appContext.isFull && (
                <div
                  className="navItem mobileSearchIcon"
                  onClick={() => appContext.setShowMobileSearch(true)}
                >
                  <SearchIcon strokeCollor="#17b4e1" />
                </div>
              )}

              {isNotTextRelaseRole && !isExternal && (
                <NavLink to="/cadastrar" activeClassName="active">
                  <RegisterIcon
                    color={
                      location.pathname.startsWith("/cadastrar")
                        ? "#fff"
                        : "#17b4e1"
                    }
                  />
                  <span>Cadastro</span>
                </NavLink>
              )}

              {permissionedPages.map(({ role, to, label, icon }) => (
                <PermissionNavLinkItem
                  key={to}
                  havePermission={userContext.havePermission(role).length === 0}
                  to={to}
                  label={label}
                  icon={icon}
                />
              ))}

              <AvatarData name={userContext.getFullName()} />

              {isNotTextRelaseRole && !isExternal && (
                <SubMenu subMenuLinks={subMenuLinks} />
              )}
            </nav>
          </>
        )}
      </header>
    );

  return <></>;
};

export default Header;

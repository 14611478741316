import React from "react";
import "./styles/OpenScope.scss";
import ThesaurusBook from "../../VectorIcons/ThesaurusBook";

const OpenScope = (props) => {
  const { openScope } = props;

  return (
    <div className="openScope" onClick={() => openScope(true)}>
      <ThesaurusBook />
    </div>
  );
};

export default OpenScope;

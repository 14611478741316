import React, { useState, useEffect, useContext } from 'react'
import './styles/EditSheetRelevance.scss'
import BoxFooter from '../../../components/BoxFooter/BoxFooter'
import Button from '../../../components/Button/Button'
import { getService, putService } from '../../../services/script-api'
import SheetContext from '../../../context/sheetContext'
import AppContext from '../../../context/appContext'
import PlotAndThematicAndUniverse from './RelevanceTypes/PlotAndThematicAndUniverse'

const EditSheetRelevance = (props) => {
  const { id, data, dataProject } = props

  const compileDataId = (data) => {
    let i = []
    data.forEach((el) => i.push(el.id))
    return i
  }

  const compileLabel = (data) => {
    if (data.length === 0) return
    return data.map((item) => ({
      value: item.id || item.value,
      label: item.name || item.label,
    }))
  }

  const initialState = {
    plot: {
      primariesIds: data.plot.primaries,
      secundariesIds: data.plot.secundaries,
      tertiaryIds: data.plot.tertiaries,
    },
    thematic: {
      primariesIds: data.thematic.primaries,
      secundariesIds: data.thematic.secundaries,
      tertiaryIds: data.thematic.tertiaries,
    },
    universe: {
      primariesIds: data.universe.primaries,
      secundariesIds: data.universe.secundaries,
      tertiaryIds: data.universe.tertiaries,
    },
  }

  const appContext = useContext(AppContext)
  const sheetContext = useContext(SheetContext)

  const [stateCurrentTab, setStateCurrentTab] = useState('plot')
  const [state, setState] = useState(initialState)
  const [statePlotPrimaries, setStatePlotPrimaries] = useState([])
  const [statePlotSecundaries, setStatePlotSecundaries] = useState([])
  const [statePlotTertiaryIds, setStatePlotTertiaryIds] = useState([])

  const [stateThematicPrimaries, setStateThematicPrimaries] = useState([])
  const [stateThematicSecundaries, setStateThematicSecundaries] = useState([])
  const [stateThematicTertiaryIds, setStateThematicTertiaryIds] = useState([])

  const [stateUniversesPrimaries, setStateUniversesPrimaries] = useState([])
  const [stateUniversesSecundaries, setStateUniversesSecundaries] = useState(
    []
  )
  const [stateUniversesTertiaryIds, setStateUniversesTertiaryIds] = useState(
    []
  )

  const getThesaurus = (id) =>
    getService(`dramaturgies/thesaurus?origins=${id}`)

  const _setStates = (id, _setStates) => {
    let _list = []
    getThesaurus(id).then((res) => {
      _list = res.data.map((item) => ({
        value: item.id,
        label: item.name,
        ...item,
      }))
      _setStates.forEach((setter) => setter(_list))
    })
  }

  useEffect(() => {
    _setStates(3, [
      setStatePlotPrimaries,
      setStatePlotSecundaries,
      setStatePlotTertiaryIds,
    ])
    _setStates(6, [
      setStateThematicPrimaries,
      setStateThematicSecundaries,
      setStateThematicTertiaryIds,
    ])
    _setStates(9, [
      setStateUniversesPrimaries,
      setStateUniversesSecundaries,
      setStateUniversesTertiaryIds,
    ])
    // eslint-disable-next-line
  }, [])

  const compileItem = (items) => {
    let i = []
    items.forEach((item) => {
      if (!i.includes(item.value)) {
        i.push(item.value)
      }
    })
    return i
  }

  const verifyType = (item) => {
    if (item.length === 0) return []
    return !!item[0].id ? compileDataId(item) : compileItem(item)
  }

  const handleChange = (item, stateKey, subState) => {
    const newState = { ...state }
    newState[stateKey][subState] = !!item ? item : []
    setState(newState)
  }

  const compileContentOriginIds = (item) => {
    let content = []

    if (!!item.length) {
      item.forEach((el) => {
        content.push({
          id: el.content.id,
          descriptions: el.descriptions,
        })
      })
    }

    return content
  }

  const sendDateRelevance = (ev) => {
    ev.preventDefault()

    let obj = {
      ...dataProject.basic,
      ...dataProject.complementaries,
      contentOrigns: compileContentOriginIds(data.contentOrigns),
      channel: compileContentOriginIds(data.channel),
      plot: {
        primariesIds: verifyType(state.plot.primariesIds),
        secundariesIds: verifyType(state.plot.secundariesIds),
        tertiaryIds: verifyType(state.plot.tertiaryIds),
      },
      thematic: {
        primariesIds: verifyType(state.thematic.primariesIds),
        secundariesIds: verifyType(state.thematic.secundariesIds),
        tertiaryIds: verifyType(state.thematic.tertiaryIds),
      },
      universe: {
        primariesIds: verifyType(state.universe.primariesIds),
        secundariesIds: verifyType(state.universe.secundariesIds),
        tertiaryIds: verifyType(state.universe.tertiaryIds),
      }
    }

    appContext.setShowGlassLoading(true)

    putService('dramaturgies', obj)
      .then(() => {
        appContext.setShowGlassLoading(false)
        appContext.sendMessage('Ficha atualizada com sucesso!')
        appContext.setCurrentStepBox('closed')
        sheetContext._getProjectSheet(id, 'dramaturgies')
      })
      .catch(() => {
        // appContext.setShowGlassLoading(false);
        appContext.sendMessage('Erro ao atualizar a ficha!', 'error')
        sheetContext._getProjectSheet(id, 'dramaturgies')
      })
  }

  return (
    <div className="editSheetRelevance">
      <div className="relevanceNavigation">
        <div
          className={`tab ${stateCurrentTab === 'plot' ? 'active' : ''}`}
          onClick={() => setStateCurrentTab('plot')}
        >
          <span>Trama</span>
        </div>
        <div
          className={`tab ${stateCurrentTab === 'thematic' ? 'active' : ''}`}
          onClick={() => setStateCurrentTab('thematic')}
        >
          <span>Temática</span>
        </div>
        <div
          className={`tab ${stateCurrentTab === 'universe' ? 'active' : ''}`}
          onClick={() => setStateCurrentTab('universe')}
        >
          <span>Universo</span>
        </div>
      </div>

      <form className="relevanceForm" onSubmit={(e) => sendDateRelevance(e)}>
        <div style={{ display: stateCurrentTab === 'plot' ? 'block' : 'none' }}>
          <p className="relevanceTip">
            Classifique as tramas por relevância principal, secundária ou
            terciária.
          </p>

          <PlotAndThematicAndUniverse
            options={{
              primaries: statePlotPrimaries,
              secundaries: statePlotSecundaries,
              tertiaries: statePlotTertiaryIds,
            }}
            defaultValuePrimaries={compileLabel(state.plot.primariesIds)}
            defaultValueSecundaries={compileLabel(state.plot.secundariesIds)}
            defaultValueTertiary={compileLabel(state.plot.tertiaryIds)}
            handleChange={handleChange}
            thesaurusName="Trama"
            keyName="plot"
            origin={3}
          />
        </div>

        <div
          style={{ display: stateCurrentTab === 'thematic' ? 'block' : 'none' }}
        >
          <p className="relevanceTip">
            Classifique as temáticas por relevância principal, secundária ou
            terciária.
          </p>

          <PlotAndThematicAndUniverse
            options={{
              primaries: stateThematicPrimaries,
              secundaries: stateThematicSecundaries,
              tertiaries: stateThematicTertiaryIds,
            }}
            handleChange={handleChange}
            defaultValuePrimaries={compileLabel(state.thematic.primariesIds)}
            defaultValueSecundaries={compileLabel(
              state.thematic.secundariesIds
            )}
            defaultValueTertiary={compileLabel(state.thematic.tertiaryIds)}
            thesaurusName="Temática"
            keyName="thematic"
            origin={6}
          />
        </div>

        <div
          style={{ display: stateCurrentTab === 'universe' ? 'block' : 'none' }}
        >
          <p className="relevanceTip">
            Classifique os universos por relevância principal, secundária ou
            terciária.
          </p>
          <PlotAndThematicAndUniverse
            options={{
              primaries: stateUniversesPrimaries,
              secundaries: stateUniversesSecundaries,
              tertiaries: stateUniversesTertiaryIds,
            }}
            handleChange={handleChange}
            defaultValuePrimaries={compileLabel(state.universe.primariesIds)}
            defaultValueSecundaries={compileLabel(
              state.universe.secundariesIds
            )}
            defaultValueTertiary={compileLabel(state.universe.tertiaryIds)}
            thesaurusName="Universe"
            keyName="universe"
            origin={9}
          />
        </div>

        <BoxFooter>
          <Button
            text="Salvar"
            toValidate={true}
            isValidForm={true}
            classButton="buttonGreen large"
            typeButton="submit"
            dataQA="button-prospect-new-save"
          />
        </BoxFooter>
      </form>
    </div>
  )
}

export default EditSheetRelevance

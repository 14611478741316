import React, { useContext } from "react";
import AppContext from "../../../../context/appContext";

import InfoPaper from "./InfoPaper/InfoPaper";
import ContentInfo from "./ContentInfo/ContentInfo";
import ProjectSheetTitle from "./Title/ProjectSheetTitle";

const InfoPaperExternalProducer = ({ list, owner }) => {
  const appContext = useContext(AppContext);

  return (
    <InfoPaper
      handleClick={() => appContext.setCurrentStepBox("EditBasicProjectSheet")}
    >
      <ProjectSheetTitle title="Dados da Produtora" />
      <div className="space" />
      <ContentInfo
        label="NÚMERO ANCINE"
        value={list?.productionCompanies?.[0]?.ancine || "-"}
      />
      <ContentInfo
        label="RAZÃO SOCIAL"
        value={list?.productionCompanies?.[0]?.corporateName}
      />
      <ContentInfo
        label="NOME FANTASIA"
        value={list?.productionCompanies?.[0]?.name}
      />
      <ContentInfo label="CNPJ" value={list?.productionCompanies?.[0]?.cnpj} />
      <ContentInfo
        label="INSCRIÇÃO ESTADUAL"
        value={list?.productionCompanies?.[0]?.stateRegistration}
      />
      <ContentInfo label="NOME DO RESPONSÁVEL" value={owner?.name || "-"} />
      <ContentInfo label="EMAIL" value={owner?.email || "-"} />
      <ContentInfo
        label="TELEFONE"
        value={`${list?.productionCompanies?.[0]?.phones?.[0]?.areaCode.slice(
          -2
        )} ${list?.productionCompanies?.[0]?.phones?.[0]?.number}`}
      />
    </InfoPaper>
  );
};

export default InfoPaperExternalProducer;

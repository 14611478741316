import React from "react";
import "./styles/Avatar.scss";

const Avatar = (props) => {
  const { letter, bgColor } = props;

  return (
    <div className="avatarIcon" style={bgColor}>
      <span className="avatarLetter">{letter}</span>
    </div>
  );
};

export default Avatar;

import React from "react";
import "./styles/NoCommomAttachments.scss";

const NoCommomAttachments = () => {
  return (
    <div className="noCommomAttachments">
      <span className="message">Não há outros anexos liberados</span>
    </div>
  );
};

export default NoCommomAttachments;

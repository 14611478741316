import React, { useEffect, useState } from "react";
import "./styles/DramaturgyParameters.scss";
import "./styles/ReactSelectDramaturgyParameters.scss";
import Fieldset from "../../../../components/Fieldset/Fieldset";
import Select from "react-select";
import { getService } from "../../../../services/script-api";
import FieldsetSelect from "../../../../components/FieldsetSelect/FieldsetSelect";
import _ from "lodash";
import SmallLoading from "../../../../components/SmallLoading/SmallLoading";
import CreatableFieldset from "../../../../components/CreatableFieldset/CreatableFieldset";

const DramaturgyParameters = (props) => {
  const { values, getStateComplementaries } = props;

  let initialState = {
    genres: [],
    subGenres: [],
    narratives: [],
    plot: [],
    thematic: [],
    universe: [],
    periods: [],
    space: [],
    epochs: [],
    characterActivities: [],
  };

  const [stateOptions, setStateOptions] = useState({
    loading: false,
    data: {},
  });
  const [selected, setSelected] = useState("");
  const [complementaries, setComplementaries] = useState(initialState);
  const [fullComplementaries, setFullComplementaries] = useState(values || {});

  useEffect(() => {
    getThesaurus(13, "narratives");
    getThesaurus(15, "genres");
    getThesaurus(19, "subGenres");
    getThesaurus(3, "plot");
    getThesaurus(6, "thematic");
    getThesaurus(9, "universe");
    getThesaurus(22, "format");
    getThesaurus(14, "periods");
    getThesaurus(12, "space");
    getThesaurus(16, "epochs");
    getSoapOperaSchedule();
    // appContext.getTypeThesaurus(2, 'dramaturgies')
    // eslint-disable-next-line
  }, []);

  const getThesaurus = (id, key, origin) => {
    setStateOptions({
      ...stateOptions,
      loading: true,
    });
    getService(`dramaturgies/thesaurus?origins=${id}`).then((list) => {
      let newState = { ...stateOptions };
      const _list = list.data.map((item) => ({
        value: item.id,
        label: item.name,
        origin: origin,
        ...item,
      }));

      newState.data[key] = _list;

      setStateOptions({
        ...stateOptions,
        loading: false,
        ...newState,
      });
    });
  };

  const getSoapOperaSchedule = () => {
    getService("dramaturgies/soapoperaschedules").then((list) => {
      let newState = { ...stateOptions };
      const _list = list.data.map((item) => ({
        value: item.id,
        label: item.value,
        name: item.value,
        ...item,
      }));

      newState.data.soapOperaSchedule = _list;

      setStateOptions({
        ...stateOptions,
        loading: false,
        ...newState,
      });
    });
  };

  const filterIdTypes = (item) => {
    let a = [];
    if (!!item && _.isArray(item)) {
      item.forEach((e) => a.push(e.value));
      return a;
    }
    return item || a;
  };

  const populateComplementaries = (value, type) => {
    setComplementaries({
      ...complementaries,
      [type]: filterIdTypes(value),
    });
  };

  const translateFormat = (type) =>
    type === "FormatId" ? "Formato" : "Horário";

  const onChange = (value, type, event) => {
    const handleValue = value !== "placeholder" ? value : "";

    setSelected({
      ...selected,
      [type]: handleValue,
    });

    mountComplementaries(handleValue, type, event);
    populateComplementaries(handleValue, type);
  };

  const handleChangeCharacters = (newValue) => {
    setComplementaries({
      ...complementaries,
      characterActivities: !!newValue ? newValue : [],
    });

    mountComplementaries(
      !!newValue ? newValue : [],
      "characterActivities",
      () => {}
    );
  };

  const setFields = (item) => {
    !!item &&
      item.forEach((e) => (e["field"] = "Atividades / Características"));
    return item;
  };

  const mountComplementaries = (value, type, event) => {
    let full = { ...fullComplementaries };

    full[
      type === "characterActivities" ? "Atividades / Características" : type
    ] = type === "characterActivities" ? setFields(value) : value;

    if (type === "FormatId" || type === "SoapOperaSchedule") {
      const id = event.nativeEvent.target.selectedIndex;
      const idText = event.nativeEvent.target[id].text;

      full[type] = {
        label: idText,
        value: [value],
        field: translateFormat(type),
      };
    }

    if (!value) delete full[type];

    setFullComplementaries(full);
  };

  const filterIds = (item) => {
    let list = [];

    if (!!item && _.isArray(item)) {
      item.forEach((b) => list.push(b.value));
    }

    return !!list.length ? list : item;
  };

  const filterIdComplementaries = (item) => {
    let a = {};
    if (!_.isEmpty(item)) {
      Object.entries(item).forEach((value) => {
        a[value[0]] = filterIds(value[1]);
      });

      setComplementaries({
        ...complementaries,
        ...a,
      });

      getStateComplementaries(mountObj());
    }
  };

  const mountObj = () => {
    let list = {
      Complementaries: [
        ...complementaries.narratives,
        ...complementaries.genres,
        ...complementaries.subGenres,
        ...complementaries.plot,
        ...complementaries.thematic,
        ...complementaries.universe,
        ...complementaries.periods,
        ...complementaries.space,
        ...complementaries.epochs,
      ],
      characterActivities: complementaries.characterActivities,
      FormatId: complementaries.FormatId?.value,
      CharacterIdentifications: complementaries.character,
      SoapOperaSchedule: complementaries.SoapOperaSchedule,
      listComplementaries: fullComplementaries,
    };
    return list;
  };

  useEffect(() => {
    getStateComplementaries(mountObj());
    // eslint-disable-next-line
  }, [complementaries]);

  useEffect(() => {
    let v = {
      ...values,
      characterActivities: !!values
        ? values["Atividades / Características"]
        : [],
    };
    filterIdComplementaries(v, "values");
    setSelected({ ...selected, ...v });
    // eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    let f = { ...fullComplementaries };
    if (
      !!selected?.periods &&
      !selected?.periods?.find((e) => e.name === "Época")
    ) {
      delete f.epochs;
      setFullComplementaries(f);
      setSelected({ ...selected, epochs: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected?.periods]);

  return (
    <div id="DramaturgyParameters">
      {stateOptions.loading ? (
        <div className="loadingContainer">
          <SmallLoading />
        </div>
      ) : (
        <div>
          <div className="parametersSection">
            <div className="parameter">
              <FieldsetSelect
                data={stateOptions.data?.format || []}
                label="Formato"
                placeholder="Selecione um formato"
                name="FormatId"
                handleChange={(ev) => onChange(ev.target.value, "FormatId", ev)}
                selected={selected?.FormatId?.value || ""}
              />

              {selected?.FormatId?.label === "Novela" && (
                <FieldsetSelect
                  data={stateOptions.data?.soapOperaSchedule || []}
                  label="Horário"
                  placeholder="Selecione um horário"
                  name="SoapOperaSchedule"
                  handleChange={(ev) =>
                    onChange(ev.target.value, "SoapOperaSchedule", ev)
                  }
                  selected={selected?.SoapOperaSchedule?.value || ""}
                />
              )}
            </div>

            <div className="parameter">
              <div className="subParameter">
                <label className="labelParameter">Gênero</label>
                <Select
                  className="reactSelectDramaturgyParameters"
                  classNamePrefix="reactSelect"
                  options={stateOptions.data.genres}
                  value={selected?.genres}
                  onChange={(ev) => onChange(ev, "genres")}
                  placeholder="Digite um nome e aperte ENTER"
                  noOptionsMessage={() => "Nenhum item encontrado"}
                  loadingMessage={() => "Carregando..."}
                  isMulti
                />
              </div>

              {!!selected?.genres &&
                selected.genres.some((item) => item.label === "Comédia") && (
                  <div className="subParameter">
                    <label className="labelParameter">Subgêneros</label>
                    <Select
                      className="reactSelectDramaturgyParameters"
                      classNamePrefix="reactSelect"
                      options={stateOptions.data.subGenres}
                      value={selected?.subGenres}
                      onChange={(ev) => onChange(ev, "subGenres")}
                      placeholder="Digite um nome e aperte ENTER"
                      noOptionsMessage={() => "Nenhum item encontrado"}
                      loadingMessage={() => "Carregando..."}
                      isMulti
                    />
                  </div>
                )}
            </div>

            <div className="parameter">
              <label className="labelParameter">Modelo narrativo</label>
              <Select
                className="reactSelectDramaturgyParameters"
                classNamePrefix="reactSelect"
                options={stateOptions.data.narratives}
                value={selected?.narratives}
                onChange={(ev) => onChange(ev, "narratives")}
                placeholder="Digite um nome e aperte ENTER"
                noOptionsMessage={() => "Nenhum item encontrado"}
                loadingMessage={() => "Carregando..."}
                isMulti
              />
            </div>

            <div className="parameter">
              <label className="labelParameter">Espaço</label>
              <Select
                className="reactSelectDramaturgyParameters"
                classNamePrefix="reactSelect"
                options={stateOptions.data.space}
                value={selected?.space}
                onChange={(ev) => onChange(ev, "space")}
                placeholder="Digite um nome e aperte ENTER"
                noOptionsMessage={() => "Nenhum item encontrado"}
                loadingMessage={() => "Carregando..."}
                isMulti
              />
            </div>

            <div className="parameter">
              <label className="labelParameter">Tempo</label>
              <Select
                className="reactSelectDramaturgyParameters"
                classNamePrefix="reactSelect"
                options={stateOptions.data.periods}
                value={selected?.periods}
                onChange={(ev) => onChange(ev, "periods")}
                placeholder="Digite um nome e aperte ENTER"
                noOptionsMessage={() => "Nenhum item encontrado"}
                loadingMessage={() => "Carregando..."}
                isMulti
              />
            </div>

            {!!selected?.periods?.find((e) => e.name === "Época") && (
              <div className="parameter">
                <label className="labelParameter">Época</label>
                <Select
                  className="reactSelectDramaturgyParameters"
                  classNamePrefix="reactSelect"
                  options={stateOptions.data.epochs}
                  value={selected?.epochs}
                  onChange={(ev) => onChange(ev, "epochs")}
                  placeholder="Digite um nome e aperte ENTER"
                  noOptionsMessage={() => "Nenhum item encontrado"}
                  loadingMessage={() => "Carregando..."}
                  isMulti
                />
              </div>
            )}
          </div>

          <div className="parametersSection">
            <div className="parameter">
              <label className="labelParameter">Trama</label>
              <Select
                className="reactSelectDramaturgyParameters"
                classNamePrefix="reactSelect"
                options={stateOptions.data.plot}
                value={selected?.plot}
                onChange={(ev) => onChange(ev, "plot")}
                placeholder="Digite um nome e aperte ENTER"
                noOptionsMessage={() => "Nenhum item encontrado"}
                loadingMessage={() => "Carregando..."}
                isMulti
              />
            </div>

            <div className="parameter">
              <label className="labelParameter">Temática</label>
              <Select
                className="reactSelectDramaturgyParameters"
                classNamePrefix="reactSelect"
                options={stateOptions.data.thematic}
                value={selected?.thematic}
                onChange={(ev) => onChange(ev, "thematic")}
                placeholder="Digite um nome e aperte ENTER"
                noOptionsMessage={() => "Nenhum item encontrado"}
                loadingMessage={() => "Carregando..."}
                isMulti
              />
            </div>

            <div className="parameter">
              <label className="labelParameter">Universo</label>
              <Select
                className="reactSelectDramaturgyParameters"
                classNamePrefix="reactSelect"
                options={stateOptions.data.universe}
                value={selected?.universe}
                onChange={(ev) => onChange(ev, "universe")}
                placeholder="Digite um nome e aperte ENTER"
                noOptionsMessage={() => "Nenhum item encontrado"}
                loadingMessage={() => "Carregando..."}
                isMulti
              />
            </div>
          </div>

          <div className="parametersSection paddingBottom">
            <div className="parameter">
              <Fieldset
                label="Nome de Personagem"
                type="text"
                placeholder="Digite um nome"
                value={selected?.character}
                handleChange={(ev) => onChange(ev.target.value, "character")}
              />
            </div>

            <div className="parameter">
              <CreatableFieldset
                label="Atividades/Características"
                createMessage="Adicionar a opção:"
                placeholder="Digite um nome e aperte ENTER"
                handleChange={handleChangeCharacters}
                renderCreatedAt={true}
                values={selected?.characterActivities}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DramaturgyParameters;

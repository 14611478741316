import React, { useState, useContext } from "react";
import "./styles/ContentInfo.scss";
import { Link } from "react-router-dom";
import ThesaurusIcon from "../../../../components/VectorIcons/ThesaurusIcon";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import DateTooltip from "./DateTooltip/DateTooltip";
import EditIcon from "../../../../components/VectorIcons/EditIcon";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatepicker";
import AppContext from "../../../../context/appContext";
import { getService } from "../../../../services/script-api";
import _ from "lodash";
import ProspectIcon from "../../../../components/VectorIcons/ProspectIcon";
import FileView from "../../../../components/VectorIcons/FileView";

const ContentInfo = (props) => {
  const {
    projectName,
    creators,
    suggestedBy,
    format,
    genres,
    attachments,
    date,
    getDate,
    type,
    projectId,
    isEditable,
  } = props;

  const appContext = useContext(AppContext);

  const [stateEditDate, setStateEditDate] = useState(true);

  const downloadEmployeeData = (url, name) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      });
    });
  };

  const getUrlS3 = (fileId, fileName) => {
    getService(`contents/attachments/presignedurl/download`, {
      fileId: fileId,
    }).then((res) => {
      downloadEmployeeData(res.data.url, fileName);
    });
  };

  const compileFiles = (data) => {
    let allAgroup = compileAll(data);
    let materialCompiled = compileMaterial(data);
    let chapterCompiled = compiledChapter(data);

    let mergeGroups = { ...materialCompiled, ...allAgroup, ...chapterCompiled };
    let merge = Object.entries(mergeGroups).map((files) => files[1]);

    return merge;
  };

  const compiledChapter = (data) => {
    let chapter = _.cloneDeep(data);
    let chapterFilter = chapter.filter(
      (e) =>
        e.attachmentType?.name === "Capítulo/Episódio" ||
        e.attachmentType?.name === "Episódio"
    );

    let compiled = _.groupBy(
      chapterFilter,
      (u) => u.blockNumber && u.chapterNumber
    );

    return compiled;
  };

  const compileAll = (data) => {
    let groupall = _.cloneDeep(data);
    return _.groupBy(
      groupall.filter(
        (e) =>
          e.attachmentType?.name !== "Material Complementar" &&
          e.attachmentType?.name !== "Capítulo/Episódio" &&
          e.attachmentType?.name !== "Episódio"
      ),
      (u) => u.attachmentType?.id
    );
  };

  const compileMaterial = (data) => {
    let emptyArray = [];
    let compiledMaterial = _.cloneDeep(data);
    let material = compiledMaterial.filter(
      (e) => e.attachmentType?.name === "Material Complementar"
    );
    compiledMaterial.filter(
      (e) => e.attachmentType?.name === "Material Complementar"
    );
    material.map((e) => emptyArray.push([e]));

    return emptyArray;
  };

  const checkVersion = (version) =>
    version > 1 ? `<span>(${version}ª versão)</span>` : "";

  const returnTypeAttachment = (attachment) => {
    let files = {
      "Capítulo/Episódio": `<div class="typeBloco">bloco ${attachment.blockNumber}</div>`,
      Episódio: `<div class="typeBloco">bloco ${attachment.blockNumber}</div>`,
      default: "",
    };
    return files[attachment.attachmentType?.name] || files["defaut"];
  };

  const creatorsOrSuggesters = {
    dramaturgia: creators,
    variedades: creators,
    "obra-literaria": suggestedBy,
  };

  return (
    <div className="contentInfo">
      <div className="infoLine">
        <span className="infoName">
          {type === "obra-literaria" ? "Obra Literária" : "Projeto"}
        </span>

        <Link to={appContext.redirectToSheet(type, projectId)}>
          {projectName}
        </Link>
      </div>

      <div className="infoLine">
        <span className="infoName">
          {type === "obra-literaria" ? "Solicitado por" : "Criado por"}
        </span>
        <div className="infoData creators">
          {creatorsOrSuggesters[type].map((creator, index) => {
            return (
              <div key={index}>
                <span className="creatorName">
                  {creator.origin === 1 && (
                    <ProspectIcon color="#B2B4F1" width={18} height={18} />
                  )}
                  {creator.name}
                </span>
                {index + 1 < creatorsOrSuggesters[type].length && (
                  <span className="infoSeparator">|</span>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className="infoLine">
        <span className="infoName">Formato</span>
        <div className="infoData">{format}</div>
      </div>

      <div className="infoLine">
        <span className="infoName">Gênero</span>
        <div className="infoData">
          {!!genres.length ? (
            genres.map((genre, index) => {
              return (
                <div key={index}>
                  <span className="creatorName">{genre}</span>
                  {index + 1 < genres.length && (
                    <span className="infoSeparator">|</span>
                  )}
                </div>
              );
            })
          ) : (
            <span>-</span>
          )}
        </div>
      </div>

      {!!attachments.length && (
        <div className="infoLine">
          <span className="infoName">Anexos</span>
          <div className="infoData">
            {compileFiles(attachments).map((files, index) => {
              return (
                <div className="contentListFiles" key={index}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: returnTypeAttachment(files[0]),
                    }}
                  />
                  {_.orderBy(files, ["version"], ["asc"]).map(
                    (attachment, i) => {
                      return (
                        <div className="childrenAttachment" key={i}>
                          {attachment.fileName?.slice(-3) !== "fdx" &&
                            attachment.fileName !== null && (
                              <div
                                className="fileName"
                                onClick={() => {
                                  appContext.setCurrentFileUrl(
                                    attachment.fileId,
                                    attachment.fileName
                                  );
                                  appContext.setCurrentStepBox("ViewerFileBox");
                                }}
                              >
                                <FileView />
                              </div>
                            )}
                          <span
                            className="fileName"
                            onClick={() =>
                              getUrlS3(attachment.fileId, attachment.fileName)
                            }
                          >
                            {attachment.attachmentType?.name ===
                              "Capítulo/Episódio" ||
                            attachment.attachmentType?.name === "Episódio" ? (
                              <div>
                                Cap {attachment.chapterNumber}
                                {attachment.version > 1 && (
                                  <span
                                    className="fileVersion"
                                    dangerouslySetInnerHTML={{
                                      __html: checkVersion(attachment.version),
                                    }}
                                  />
                                )}
                              </div>
                            ) : (
                              <div>
                                {attachment.attachmentType?.name}
                                {!!attachment.version > 1 && (
                                  <span
                                    className="fileVersion"
                                    dangerouslySetInnerHTML={{
                                      __html: checkVersion(attachment.version),
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </span>
                          {i + 1 < files.length && (
                            <span className="infoSeparator"> | </span>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className="infoLine">
        <span className="infoName">
          Data
          <CustomTooltip
            tipLabel={<ThesaurusIcon handleClick={() => {}} />}
            dataTip={<DateTooltip />}
          />
        </span>

        <div className="infoData">
          {stateEditDate ? (
            <>
              {appContext.formatDate(appContext.convertDatePtBr(date))}
              {` às `}
              {appContext.convertDateAndTimePtBr(date).slice(10, 16)}

              {isEditable && (
                <div
                  className="editDate"
                  onClick={() => setStateEditDate(false)}
                >
                  <EditIcon size={14} />
                </div>
              )}
            </>
          ) : (
            <div className="datepickerContainer">
              <CustomDatePicker
                darkBackgroundColor={true}
                date={new Date(date)}
                handleChangeDate={getDate}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentInfo;

import React from "react";

const StepCounterIcon = (props) => {
  const { step, maxStep } = props;

  const minValue = 339;
  const valueFraction = minValue / maxStep;
  const stepValue = minValue - step * valueFraction;

  return (
    <svg width="24" height="24" viewBox="0 0 120 120">
      <circle
        cx="60"
        cy="60"
        r="54"
        fill="none"
        stroke="#484879"
        strokeWidth="12"
      ></circle>
      <circle
        cx="60"
        cy="60"
        r="54"
        fill="none"
        stroke="#31C38E"
        strokeWidth="12"
        strokeDasharray="339.292"
        strokeDashoffset={stepValue.toString()}
      ></circle>
    </svg>
  );
};

export default StepCounterIcon;

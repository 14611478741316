import React, { useContext } from "react";
import "./styles/HistoryItem.scss";
import AppContext from "../../../../context/appContext";
import Avatar from "../../../../components/Avatar/Avatar";

const HistoryItem = (props) => {
  const { title, updatedBy, updatedAt } = props;

  const appContext = useContext(AppContext);

  return (
    <li className="historyItem">
      <div className="title">{title}</div>

      <div className="updatedBy">
        <Avatar
          letter={updatedBy.email.slice(0, 1)}
          bgColor={{
            backgroundColor: appContext.generateColorFromName(updatedBy.name),
          }}
        />
        {updatedBy.name}
      </div>

      <div className="updatedAt">
        {appContext.formatDate(appContext.convertDatePtBr(updatedAt))}
      </div>
    </li>
  );
};

export default HistoryItem;

import React, { useState } from "react";
import "./styles/EvaluationField.scss";
import FreeText from "./FieldTypes/FreeText";
import DeleteIcon from "../../../../components/VectorIcons/DeleteIcon";
import NormalText from "./FieldTypes/NormalText";
import Adequacy from "./FieldTypes/Adequacy";
import Conclusion from "./FieldTypes/Conclusion";

const EvaluationField = (props) => {
  const {
    fieldType,
    handleRemoveField,
    index,
    contentType,
    setter,
    data,
    isEditable,
    onFocus,
  } = props;

  const [stateIsFocused, setStateIsFocused] = useState(false);

  const handleFocus = (bool) => {
    setStateIsFocused(bool);
    if (!bool) {
      onFocus();
    }
  };

  const returnFieldType = (type) => {
    const types = {
      "Texto Livre": (
        <FreeText
          isEditable={isEditable}
          data={data}
          handleFocus={handleFocus}
          setter={setter}
          index={index}
          type={fieldType}
        />
      ),
      Adequação: (
        <Adequacy
          isEditable={isEditable}
          data={data}
          handleFocus={handleFocus}
          setter={setter}
          index={index}
          type={fieldType}
          title={type}
          contentType={contentType}
        />
      ),
      Conclusão: (
        <Conclusion
          isEditable={isEditable}
          data={data}
          handleFocus={handleFocus}
          setter={setter}
          index={index}
          type={fieldType}
          title={type}
        />
      ),
      Default: (
        <NormalText
          isEditable={isEditable}
          data={data}
          handleFocus={handleFocus}
          type={fieldType}
          index={index}
          setter={setter}
          title={type}
        />
      ),
    };

    return !!types[type] ? types[type] : types["Default"];
  };

  return (
    <div>
      <div className={`evaluationField ${stateIsFocused ? "isFocused" : ""}`}>
        {returnFieldType(fieldType)}

        <div
          className="removeButton"
          onMouseDown={() => handleRemoveField(index)}
        >
          <DeleteIcon color="#3F3F3F" />
        </div>
      </div>
    </div>
  );
};

export default EvaluationField;

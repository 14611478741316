import React from "react";

const ProspectIcon = (props) => {
  const { color = "FFF", width, height } = props;

  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5.5" cy="4.5" r="2.5" stroke={color} strokeWidth="2"></circle>
      <path
        d={"M12 4a1 1 0 100 2V4zm-1 11a1 1 0 100 2v-2zm1-9h8V4h-8v2zm9 "
          + "1v7h2V7h-2zm-1 8h-9v2h9v-2zm1-1a1 1 0 01-1 1v2a3 3 0 003-3h-2zm-1-8a1 "
          + "1 0 011 1h2a3 3 0 00-3-3v2z"}
        fill={color}
      ></path>
      <path
        d="M15 4v1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      ></path>
      <path
        d={"M11.606 20.553a1 1 0 101.788.894l-1.788-.894zM15 16l.894-.447h-1.788L15 "
          + "16zm1.606 5.447a1 1 0 101.788-.894l-1.788.894zm-3.212 0l2.5-5-1.788-.894-2.5 "
          + "5 1.788.894zm.712-5l2.5 5 1.788-.894-2.5-5-1.788.894z"}
        fill={color}
      ></path>
      <path
        d={"M7 17v5M4 22v-5c0-.333-.2-1-1-1s-1-.667-1-1v-2c0-.333.2-1.2 1-2s2-1 "
          + "2.5-1H7c.333 0 1.2.2 2 1 1 1 1 1 2 1 .8 0 1.5-.5 3-1"}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      ></path>
    </svg>
  );
};

export default ProspectIcon;

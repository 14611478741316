import React from "react";
import "../styles/InfoPaper.scss";
import ScriptBoxCaller from "../../../../components/ScriptBoxCaller/ScriptBoxCaller";
import IsValid from "../isValid/IsValid";

const InfoField = (props) => {
	const {items, label} = props;

	return (
		<>
			{!!items && !!items.length && (
				<div className="infoField">
					<span className="infoName">{label}</span>
					<div className="infoDataList">
						{items.map((item, index) => (
							<span className="infoData" key={index}>
                {" "}
								{item.name || item.value}
								{index + 1 < items.length ? ", " : null}{" "}
              </span>
						))}
					</div>
				</div>
			)}
		</>
	);
};

const InfoPaperComplementary = (props) => {
	const {data, paperTitle, callerIcon, callerEvent, isValid, havePermission, contentOrigns, researchedFrom, researchedTo} = props;

	const {
		narratives,
		genres,
		subGenres,
		tags,
		spaces,
		periods,
		epochs,
		storyLine,
		logLine,
		origin,
		universe,
	} = {...data};

	if (!isValid) {
		return (
			<IsValid
				callerEvent={callerEvent}
				callerIcon={callerIcon}
				paperTitle={paperTitle}
				msg="Ainda não há informações cadastras."
				havePermission={havePermission}
			/>
		);
	}

	return (
		<div className="infoPaper">
			<div className="paper">
				<div className="paperTitle">
					<span>{paperTitle}</span>
					{!!callerEvent && havePermission && (
						<ScriptBoxCaller callerIcon={callerIcon} event={callerEvent}/>
					)}
				</div>

				<div className="infoFieldContainer">
					{!!contentOrigns && !!contentOrigns.length && (
						<div className="infoField">
							<span className="infoName">Origem</span>
							<div className="infoDataList">
								{contentOrigns.map((orign, index) => {
									return (
										<span className="infoData" key={index}>
                      <div className="contentData">
                        <span className="contentOriginName">
                          {orign.content.name}
                        </span>

                        <div className="descriptions">
                          {orign.descriptions.map((item, descriptionsIndex) => (
														<span
															className="descriptionName"
															key={descriptionsIndex}
														>
                              {item}
															{descriptionsIndex + 1 <
															orign.descriptions.length ? (
																<span className="originSeparator">,</span>
															) : null}
                            </span>
													))}
                        </div>
                      </div>
                    </span>
									);
								})}
							</div>
						</div>
					)}

					<InfoField items={narratives} label="MODELO NARRATIVO"/>
					<InfoField items={genres} label="GÊNERO"/>
					<InfoField items={subGenres} label="SUBGÊNERO"/>
					<InfoField items={universe} label="UNIVERSO"/>
					<InfoField items={spaces} label="ESPAÇO"/>
					<InfoField items={periods} label="TEMPO"/>
					<InfoField items={epochs} label="ÉPOCA"/>
					<InfoField items={tags} label="TAGS"/>
					<InfoField items={origin} label="ORIGEM"/>
					<InfoField items={researchedFrom} label="PESQUISADO POR"/>
					<InfoField items={researchedTo} label="PESQUISADO PARA"/>

					{!!storyLine && (
						<div className="infoField">
							<span className="infoName">Storyline</span>
							<span className="infoData">{storyLine}</span>
						</div>
					)}

					{!!logLine && (
						<div className="infoField">
							<span className="infoName">logline</span>
							<span className="infoData">{logLine}</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default InfoPaperComplementary;

import React from "react";
import "./styles/SwitchText.scss";
import CheckIcon from "../VectorIcons/CheckIcon";

const SwitchText = (props) => {
  const { label, data, size, handleChange, value } = props;

  const setActive = (ev, id, value) => {
    let parent = ev.target.closest(".switchText");
    let target = parent.querySelector(".selected");

    if (target) target.classList.remove("selected");

    ev.target.closest(".switchOption").classList.add("selected");
    handleChange(id, value);
  };

  return (
    <div className="switchTextContainer">
      <label className="switchLabel">{label}</label>

      <ul className={`switchText ${size}`} style={{}}>
        {data.map((item, index) => {
          return (
            <li
              key={index}
              className={`switchOption ${value === item.id ? "selected" : ""}`}
              onClick={(e) => setActive(e, item.id, item.value)}
            >
              <CheckIcon color="#17B4E1" />
              {item.value}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SwitchText;

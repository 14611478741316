import React, { useState } from "react";
import AsyncLoading from "../AsyncLoading/AsyncLoading";
import AsyncSelect from "react-select/async";
import { getService } from "../../services/script-api";
import Option from "../AssociateFieldset/Option/Option";

const AssociatedSearch = (props) => {
  const { handleChange, value, defaultValue, id } = props;

  const [state, setState] = useState({
    researchsIds: [],
    load: [],
    created: [],
  });

  const loadOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length > 0) {
      getService("researches", { term: inputValue }).then((list) => {
        const _list = list.data
          .filter((item) => item.id !== id)
          .map((item) => {
            return { ...item, value: item.id, label: item.title };
          });
        setState({
          ...state,
          load: _list,
        });
        callback(_list);
      });
    }
  };

  const filterAssociated = (items) => {
    if (!!items) {
      const list = [];
      items.forEach((el) => {
        list.push(el.value);
      });
      return list;
    } else {
      return [];
    }
  };

  const handleChangeAssociated = (item) => {
    const newState = { ...state };
    newState.researchsIds = item;

    setState(newState);
    if (!!value) {
      handleChange(item);
    } else {
      handleChange(filterAssociated(item));
    }
  };

  return (
    <div className="AssociatedProject">
      <div className="formField">
        <AsyncSelect
          isMulti
          className="reactSelect"
          classNamePrefix="reactSelect"
          loadOptions={loadOptions}
          value={value}
          defaultValue={defaultValue}
          onChange={handleChangeAssociated}
          components={{
            LoadingIndicator: () => <AsyncLoading />,
            Option,
          }}
          noOptionsMessage={() => "Nenhum item encontrado"}
          loadingMessage={() => "Carregando..."}
          placeholder="Selecione"
          openMenuOnClick={false}
        />
      </div>
    </div>
  );
};

export default AssociatedSearch;

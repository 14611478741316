import React, { useContext } from "react";
import "./styles/CreateNewActivity.scss";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import NewActivityIcon from "../../../components/VectorIcons/NewActivityIcon";
import { Link } from "react-router-dom";
import ActivitiesContext from "../../../context/activitiesContext";

const CreateNewActivity = (props) => {
  const { currentType } = props;

  const context = useContext(ActivitiesContext);

  return (
    <div className="newActivity">
      <span className="newActivityText">{`Crie uma nova atividade de ${currentType}`}</span>

      <Link
        to={`atividades/${
          context.makeSlugType(currentType).label
        }/nova-atividade`}
      >
        <SimpleButton
          text="Nova Atividade"
          classButton="buttonBlue extraLarge"
          dataQA=""
          toValidate={false}
          clickEvent={() => {}}
        >
          <NewActivityIcon />
        </SimpleButton>
      </Link>
    </div>
  );
};

export default CreateNewActivity;

const setCurrentType = (currentType, state) => {
  const newState = { ...state };
  newState["currentType"] = currentType.payload;
  return newState;
};

export default (state, action) => {
  let _state = {
    set: () => setCurrentType(action, state),
  };
  return _state[action.action]();
};

import React, { useContext } from "react";
import "./styles/RelatedContentCard.scss";
import TypeIconReturner from "../TypeIconReturner/TypeIconReturner";
import { Link } from "react-router-dom";
import AppContext from "../../context/appContext";

const RelatedContentCard = (props) => {
  const {
    index,
    id,
    cardTitle,
    contentType,
    productName,
    format,
    soapOperaSchedule,
    editable,
  } = props;

  const appContext = useContext(AppContext);

  const returnColor = (type, colorLocale) => {
    const types = {
      color: {
        Dramaturgia: "#ec1045",
        Variedades: "#2d76d4",
        Pesquisa: "#009c69",
      },
      background: {
        Dramaturgia: "#ffdee4",
        Variedades: "#E3F0FF",
        Pesquisa: "#DEFFF2",
      },
    };

    return types[colorLocale][type];
  };

  return (
    <div
      key={index}
      className="relatedContentCard"
      style={{ backgroundColor: returnColor(contentType, "background") }}
    >
      <div
        className="relatedCardHeader"
        style={{ backgroundColor: returnColor(contentType, "color") }}
      >
        {!!cardTitle && editable && contentType !== "Pesquisa" && (
          <span className="relatedCardTitle">{cardTitle || ""}</span>
        )}
      </div>

      <div className="relatedCardInfo">
        <Link
          to={appContext.redirectToSheet(contentType, id)}
          className="relatedLink"
        />

        <TypeIconReturner type={contentType} />

        <span className="relatedProductName">{productName}</span>

        <div className="relatedProductDetails">
          <span className="details">
            {`
              ${contentType}
              ${!!format ? "•" : ""} 
              ${!!format ? format : ""}
              ${format === "Novela" ? "•" : ""}
              ${!!format && format === "Novela" ? soapOperaSchedule : ""}
            `}
          </span>
        </div>
      </div>
    </div>
  );
};

export default RelatedContentCard;

const FormAndFieldsPutObj = [
  {key: "scheduleDelivery", label: "ENTREGA PLANEJADA"},
  {key: "rescheduledDelivery", label: "ENTREGA REPLANEJADA"},
  {key: "scheduleRelease", label: "LIBERAÇÃO PLANEJADA"},
  {key: "rescheduledRelease", label: "LIBERAÇÃO REPLANEJADA"}
];

const formAndFieldsPostObj = [
  {key: "scheduleDelivery", label: "ENTREGA PLANEJADA"},
  {key: "scheduleRelease", label: "LIBERAÇÃO PLANEJADA"},
];

const postBlock = (item) => {
  return {
    contentId: item.contentId,
    attachmentTypeId: 1211,
    blockNumber: item.blockNumber ?? 1,
    episodeNumber: item.episodeNumber,
    numberChaptersBlock: item.numberChaptersBlock,
    scheduleDelivery: item.scheduleDelivery,
    scheduleRelease: item.scheduleRelease,
  };
};

const PutBlock = (item) => {
  return {
    id: item.id,
    contentId: item.contentId,
    blockNumber: item.blockNumber,
    episodeNumber: item.episodeNumber,
    scheduleDelivery: item.scheduleDelivery,
    rescheduledDelivery: item.rescheduledDelivery,
    scheduleRelease: item.scheduleRelease,
    rescheduledRelease: item.rescheduledRelease,
    plannedContentSight: item.plannedContentSight,
    plannedProdutionSight: item.plannedProdutionSight,
    plannedArtisticApproval: item.plannedArtisticApproval,
    plannedChannelFeedback: item.plannedChannelFeedback,
  };
};

const FormAndFieldsPutObjDramaCurta = [
  {key: "scheduleDelivery", label: "ENTREGA PLANEJADO"},
  {key: "plannedContentSight", label: "PARECER CONTEÚDO PLANEJADO"},
  {key: "plannedProdutionSight", label: "PARECER PRODUÇÃO PLANEJADO"},
  {key: "plannedArtisticApproval", label: "APROVAÇÃO DIR. GÊNERO PLANEJADO"},
  {key: "scheduleRelease", label: "LIBERAÇÃO PLANEJADO"},
  {key: "plannedChannelFeedback", label: "NOTES DO CANAL PLANEJADO"}
];

const FormAndFieldsPutObjAttachDramaCurta = [
  {key: "attachedContentSight", label: "PARECER CONTEÚDO ANEXO"},
  {key: "attachedProdutionSight", label: "PARECER PRODUÇÃO ANEXO"},
  {key: "attachedArtisticApproval", label: "APROVAÇÃO DIR. GÊNERO ANEXO"},
  {key: "attachedChannelFeedback", label: "NOTES DO CANAL ANEXO"}
];

export default {
  FormAndFieldsPutObjAttachDramaCurta,
  FormAndFieldsPutObjDramaCurta,
  formAndFieldsPostObj,
  FormAndFieldsPutObj,
  postBlock,
  PutBlock
}
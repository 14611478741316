import React from "react";

const SearchIcon = (props) => {
  return (
    <div className="searchIcon">
      <svg
        fill="none"
        stroke={props.strokeCollor || ""}
        height={24}
        width={24}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="11" cy="11" r="8" />
        <line x1="21" x2="16.65" y1="21" y2="16.65" />
      </svg>
    </div>
  );
};

export default SearchIcon;

import React, { useContext, useEffect, useState } from "react";
import "./style/ScriptBoxDescription.scss";
import AppContext from "../../context/appContext";
import RoundCloseButton from "../RoundCloseButton/RoundCloseButton";

const ScriptBoxDescription = (props) => {
  const [stateBox, setStateBox] = useState({ open: false });
  const context = useContext(AppContext);

  const { title, subTitle, children, clear, icon, size } = props;

  useEffect(() => {
    setTimeout(() => {
      setStateBox({ open: props.open });
    }, 100);
  }, [props.open]);

  const _closed = () => {
    if (typeof clear === "function") {
      clear();
    }
    context.setCurrentStepBox("closed");
  };

  return (
    <div
      className={`ScriptBoxDescription ${stateBox.open ? "active" : ""} ${
        !!size ? size : ""
      }`}
    >
      <div className="bgScriptBox" onClick={() => _closed()} />
      <div className="windowScriptBox">
        <div className="header">
          {icon}
          <div className="scriptBoxTitleContainer">
            <h2>{title}</h2>

            {typeof subTitle === "object" ? (
              <p className="scriptBoxSubTitle">
                {subTitle.map((creator, index) => {
                  return (
                    <span key={index}>
                      {creator.name}
                      {index + 1 < subTitle.length ? <span>, </span> : ""}{" "}
                    </span>
                  );
                })}
              </p>
            ) : (
              <p className="scriptBoxSubTitle">{subTitle}</p>
            )}
          </div>

          <div className="closeIcon" onClick={() => _closed()}>
            <RoundCloseButton />
          </div>
        </div>

        <div
          className={`${
            props.removePadding ? "content removePadding" : "content"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ScriptBoxDescription;

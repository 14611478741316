import React from "react";
import "./styles/FeedbackForm.scss";
import FeedbackIcon from "../../../components/VectorIcons/FeedbackIcon";
import CheckBox from "../../../components/CheckBox/CheckBox";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatepicker";
import RadioInput from "../../../components/RadioInput/RadioInput";
import Fieldset from "../../../components/Fieldset/Fieldset";
import Textarea from "../../../components/Textarea/Textarea";

const FeedbackForm = (props) => {
  const {
    data,
    formOptions,
    handleChangeToggleFeedback,
    handleChangeFeedbackPerson,
    handleChangeFeedbackObservation,
    handleChangeFeedbackDate,
    handleChangeFeedbackType,
  } = props;

  return (
    <div className="feedbackForm">
      <div className="feedbackLabel">
        <FeedbackIcon color="#ADAFEF" size={30} />
        Feedback
        <div className="feedbackNoApply">
          <CheckBox
            value={!data.feedbackApplicable}
            // event={(e) => toggleActive(e, option)}
            event={() => handleChangeToggleFeedback()}
            isActive={!data.feedbackApplicable}
            label="Não se aplica"
          />
        </div>
      </div>

      {data.feedbackApplicable && (
        <>
          <div className="feedbackFields">
            <Fieldset
              autoComplete="off"
              label="Quem deu o feedback?"
              type="text"
              placeholder="Digite um nome"
              value={data.feedbackPerson}
              handleChange={handleChangeFeedbackPerson}
              // handleBlur={handleBlurDramaturgy}
              // isError={stateError.title.isError}
              // msgError={stateError.title.errorMessage}
            />

            <CustomDatePicker
              darkBackgroundColor={true}
              label="Data do Feedback"
              date={
                !!data.feedbackDate ? new Date(data.feedbackDate) : new Date()
              }
              handleChangeDate={handleChangeFeedbackDate}
            />
          </div>

          <div className="formType">
            <label>Forma</label>
            <RadioInput
              options={formOptions || []}
              value={data.feedbackType}
              handleChange={handleChangeFeedbackType}
            />
          </div>

          <Textarea
            maxRows={16}
            label="Observações sobre o feedback"
            placeholder="Escreva aqui as observações sobre o feedback"
            handleChange={handleChangeFeedbackObservation}
            value={data.feedbackObservation}
          />
        </>
      )}
    </div>
  );
};

export default FeedbackForm;

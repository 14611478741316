import React, {useContext, useEffect, useState} from "react";
import "./styles/EditSheetComplement.scss";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import Button from "../../../components/Button/Button";
import AppContext from "../../../context/appContext";
import {getService, putService} from "../../../services/script-api";
import SheetContext from "../../../context/sheetContext";
import Textarea from "../../../components/Textarea/Textarea";
import ThesaurusSelect from "../../../components/ThesaurusSelect/ThesaurusSelect";

const EditSheetComplement = (props) => {
	const { data, dataProject, id } = props;

	const appContext = useContext(AppContext);
	const sheetContext = useContext(SheetContext);

	useEffect(() => {
		getThesaurus(15, setStateGenres, "genres");
		getThesaurus(19, setStateSubGenres, "subGenres");
		getThesaurus(20, setStateTags, "tags");
		// eslint-disable-next-line
	}, []);

	const getThesaurus = (id, _setState, origin) => {
		getService(`varieties/thesaurus?origins=${id}`).then((list) => {
			const _list = list.data.map((item) => ({
				value: item.id,
				label: item.name,
				origin: origin,
				parentId: item.parentId,
				...item,
			}));
			_setState({data: _list, filtered: []});
		});
	};

	const convertOptionSelect = (options, origin) => {
		let o = [];

		options.forEach((opt) => {
			o.push({
				value: opt.id,
				label: opt.name,
				origin: origin,
				parentId: opt.parentId,
			});
		});

		return o;
	};

	const initialState = {
		genres: convertOptionSelect(data.genres, "genres"),
		subGenres: convertOptionSelect(data.subGenres, "subGenres"),
		tags: convertOptionSelect(data.tags, "tags"),
		logLine: data.logLine,
	};

	const [state, setState] = useState(initialState);
	const [stateGenres, setStateGenres] = useState({data: state.genres});
	const [stateSubGenres, setStateSubGenres] = useState({
		data: state.subGenres,
		filtered: state.subGenres,
	});
	const [stateTags, setStateTags] = useState({data: state.tags});

	useEffect(() => {
		if (!!state.genres.length && !!stateSubGenres.data) {
			const validParentIds = [];
			const validSubGenres = [];
			state.genres.forEach((genre) => validParentIds.push(genre.value));

			state.subGenres.forEach((subGenre) => {
				if (validParentIds.includes(subGenre.parentId)) {
					validSubGenres.push(subGenre);
				}
			});

			setState({...state, subGenres: validSubGenres});
			setStateSubGenres({
				...stateSubGenres,
				filtered: stateSubGenres.data.filter((i) =>
					validParentIds.includes(i.parentId)
				),
			});
		} else {
			setState({...state, subGenres: []});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.genres, stateSubGenres.data]);

	const handleChange = (item, origin) => {
		setState({
			...state,
			[origin]: !!item ? item : [],
		});

		if (!!item && origin === "genres") {
			const genresValues = [];
			item.forEach((item) => genresValues.push(item.value));

			setStateSubGenres({
				...stateSubGenres,
				filtered: stateSubGenres.data.filter((i) =>
					genresValues.includes(i.parentId)
				),
			});
		}
	};

	const handleChangeLogLine = (ev) => {
		setState({
			...state,
			logLine: ev.target.value,
		});
	};

	const compileDataId = (data) => {
		let i = [];
		if (!!data) {
			data.forEach((el) => i.push(el.value));
		}
		return i;
	};

	const compileContentOriginIds = () => {
		let content = [];

		if (data.channel.length > 0) {
			data.channel.forEach((el) => {
				content.push({
					id: el.content.id,
					descriptions: el.descriptions,
				});
			});
		}

		return content;
	};

	const _updateDataComplement = (ev) => {
		ev.preventDefault();

		let objBasic = {
			...dataProject.basic,
			creators: sheetContext.compileCreators(data.creators),
			productionCompanies: sheetContext.compileProduct(data.productionCompanies),
		}

		let obj = {
			...objBasic,
			channel: compileContentOriginIds(),
			genresIds: compileDataId(state.genres),
			subGenresIds: compileDataId(state.subGenres),
			tagsIds: compileDataId(state.tags),
			logLine: !!state.logLine ? state.logLine.trim() : "",
		};

		appContext.setShowGlassLoading(true);

		putService("varieties", obj)
			.then(() => {
				appContext.setShowGlassLoading(false);
				appContext.sendMessage("Ficha atualizada com sucesso!");
				appContext.setCurrentStepBox("closed");
				sheetContext._getProjectSheet(id, "varieties");
			})
			.catch(() => {
				appContext.setShowGlassLoading(false);
				appContext.sendMessage("Erro ao atualizar a ficha!", "error");
				// appContext.setCurrentStepBox("closed");
				//sheetContext._getProjectSheet(id, "varieties");
			});
	};

	return (
		<div id="EditSheetComplement">
			<form
				className="registerForm"
				onSubmit={(ev) => _updateDataComplement(ev)}
			>
				<div className="formField">
					<div className="formFieldColumn">
						<label className="fieldLabel">Gênero</label>

						<ThesaurusSelect
							defaultValue={stateGenres.data}
							options={stateGenres.data}
							isMulti={true}
							onChange={(item) => handleChange(item, "genres")}
							placeholder="Digite um nome e aperte ENTER"
							noOptionsMessage="Nenhum item encontrado"
						/>
					</div>

					<div className="formFieldColumn">
						<label className="fieldLabel">SubGênero</label>

						<ThesaurusSelect
							defaultValue={stateSubGenres.filtered}
							value={state.subGenres}
							options={!!state.genres.length ? stateSubGenres.filtered : []}
							isMulti={true}
							onChange={(item) => handleChange(item, "subGenres")}
							placeholder="Digite um nome e aperte ENTER"
							noOptionsMessage="Nenhum item encontrado"
						/>
					</div>
				</div>

				<div className="formFieldColumn">
					<label className="fieldLabel">Tags</label>

					<ThesaurusSelect
						options={stateTags.data}
						defaultValue={stateTags.data}
						isMulti={true}
						onChange={(item) => handleChange(item, "tags")}
						placeholder="Digite um nome e aperte ENTER"
						noOptionsMessage="Nenhum item encontrado"
					/>
				</div>

				<div className="formField storylineField">
					<Textarea
						handleChange={handleChangeLogLine}
						label="Logline"
						placeholder="Escreva aqui o logline"
						maxRows={16}
						value={state.logLine}
					/>
				</div>

				<BoxFooter>
					<Button
						text="Salvar"
						toValidate={false}
						// isValidForm={editIsValid()}
						classButton="buttonGreen large"
						typeButton="submit"
						dataQA="button-prospect-new-save"
					/>
				</BoxFooter>
			</form>
		</div>
	);
};

export default EditSheetComplement;

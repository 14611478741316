import React, { useState } from "react";
import { getService } from "../../../../services/script-api";
import AsyncLoading from "../../../../components/AsyncLoading/AsyncLoading";
import AsyncSelect from "react-select/async";

const ResearchAsyncSelect = (props) => {
  const { item, handleChange, endPoint } = props;
  const [state, setState] = useState([]);

  const setObj = {
    "users/analysts": { activityGroups: [1, 2, 3, 4, 5, 6, 7] },
    "integrations/stars": {},
  };

  const loadOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length > 0) {
      getService(endPoint, {
        term: inputValue,
        ...setObj[endPoint],
      })
        .then((list) => {
          const _list = list.data.map((value) => ({
            value: value.id,
            label: value.name,
          }));
          setState({ ...state, load: _list });
          callback(_list);
        })
        .catch((e) => {
          callback([]);
        });
    } else callback([]);
  };

  return (
    <AsyncSelect
      isMulti
      value={item.peopleId}
      loadOptions={loadOptions}
      onChange={handleChange}
      className="reactSelect"
      classNamePrefix="reactSelect"
      components={{
        LoadingIndicator: () => <AsyncLoading />,
      }}
      noOptionsMessage={() => "Nenhum item encontrado"}
      loadingMessage={() => "Carregando..."}
      placeholder="Digite um nome e aperte ENTER"
    />
  );
};

export default ResearchAsyncSelect;

import React, { useState, useContext, useEffect, useRef } from "react";
import "./styles/NewSearch.scss";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import SearchResults from "./SearchResults/SearchResults";
import SearchNoResult from "../../components/VectorIcons/SearchNoResult";
import AppContext from "../../context/appContext";
import SearchNavigation from "../../components/SearchNavigation/SearchNavigation";
import SearchPagination from "./SearchPagination/SearchPagination";
import TableLoading from "../../components/TableLoading/TableLoading";
import ParametersList from "./ParametersList/ParametersList";
import WatermarkGlobal from "../../components/Watermark/Watermark";
import _ from "lodash";

const compileTextResearch = (isParameterized) => {
  let text = {
    contents: {
      title: `Busca por: `,
      mainText: "Nenhum resultado encontrado",
      subText: "Verifique o termo buscado e tente novamente..",
    },
    default: {
      title: "Busca Parametrizada",
      mainText: "Nenhum resultado encontrado com esses parâmetros",
      subText: "Experimente excluir alguns parâmetros e tente novamente.",
    },
  };
  return isParameterized ? text["default"] : text["contents"];
};

const NewSearch = (props) => {
  const appContext = useContext(AppContext);

  const {
    searchListHeader,
    searchList,
    currentTypeSearch,
    searchTerm,
    filter,
    isParameterized,
  } = appContext;

  const isHeaderLoading = searchListHeader.loading;
  const isListLoading = searchList.loading;
  const validSearch =
    searchList.loading !== "" && searchListHeader.loading !== "";

  const initialStateReorder = {
    "OrderBy.Column": "",
    "OrderBy.Direction": "",
  };
  const [stateReorder, setStateReorder] = useState(initialStateReorder);

  const searchRef = useRef(null);

  const scrollToTop = () => {
    if (searchRef.current) {
      window.scrollTo({
        top: searchRef.current.offsetTop - 64,
      });
    }
  };

  useEffect(() => {
    scrollToTop();
    // eslint-disable-next-line
  }, [filter.PageNumber]);

  useEffect(() => {
    return () => {
      appContext.setCurrentTerm("");
    };
    // eslint-disable-next-line
  }, []);

  const hasParameters = () =>
    Object.values(appContext.filter).some((item) => !_.isEmpty(item) || !!item);

  const currentDirectionType = (bool) => (bool === 0 ? 1 : 0);

  const handleReorderFilter = (reorderType) => {
    const currentDirection = stateReorder["OrderBy.Direction"];
    const isDifferentType = reorderType !== stateReorder["OrderBy.Column"];

    setStateReorder({
      "OrderBy.Column": reorderType,
      "OrderBy.Direction": isDifferentType
        ? 0
        : currentDirectionType(currentDirection),
    });
  };

  useEffect(() => {
    if (!!stateReorder["OrderBy.Column"]) {
      appContext.setFilterSearch({ ...filter, ...stateReorder });
    }
    // eslint-disable-next-line
  }, [stateReorder]);

  useEffect(() => {
    if (!filter["OrderBy.Column"]) setStateReorder(initialStateReorder);
    // eslint-disable-next-line
  }, [filter]);

  const hasPage = (data) =>
    data?.pagingInfo.filter((e) => e.type === currentTypeSearch)[0];

  return (
    <div id="NewSearch" ref={searchRef}>
    
      <Breadcrumb>
        <div className="searchHeader">
          <h2 className="searchTitle">
            {compileTextResearch(isParameterized).title}{" "}
          </h2>
          {!isParameterized && <h2 className="searchTerms">{searchTerm}</h2>}
        </div>
      </Breadcrumb>

      {!isHeaderLoading && !isListLoading ? (
        <>
          {validSearch && (
            <SearchNavigation
              contentTypes={searchList.pagingInfo}
              contentTypesHeader={searchListHeader.pagingInfo}
            />
          )}
          <WatermarkGlobal>
          <div id="ResultSection">
            <div className="sectionContainer">
              {appContext.isParameterized && (
                <div className="leftContainer">
                  <ParametersList
                    parameters={appContext.filter}
                    hasParameters={hasParameters()}
                  />
                </div>
              )}

              {!isHeaderLoading && !isListLoading && (
                <div className="rightContainer">
                  {!!searchList.result.length && !searchListHeader?.loading && (
                    <>
                      <SearchResults
                        list={searchList.result}
                        reorder={stateReorder}
                        handleReorderFilter={handleReorderFilter}
                      />

                      {hasPage(searchList) &&
                        searchList?.pagingInfo.filter(
                          (e) => e.type === currentTypeSearch
                        )[0].pagingInfo?.totalPages > 1 && (
                          <SearchPagination
                            pagination={
                              searchList.pagingInfo.filter(
                                (e) => e.type === currentTypeSearch
                              )[0]
                            }
                          />
                        )}
                    </>
                  )}
                  {validSearch && !searchList.result.length && (
                    <>
                      <div className="noResults">
                        <SearchNoResult />
                        <span className="mainText">
                          {compileTextResearch().mainText}
                        </span>
                        <span className="subText">
                          {compileTextResearch().subText}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          </WatermarkGlobal>
        </>
      ) : (
        <TableLoading />
      )}
    </div>
  );
};

export default NewSearch;

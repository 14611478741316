import React, { useContext, useEffect, useState } from 'react'
import './styles/EditRelatedContent.scss'
import Switch from '../../../components/Switch/Switch'
import BoxFooter from '../../../components/BoxFooter/BoxFooter'
import Button from '../../../components/Button/Button'
import AppContext from '../../../context/appContext'
import SheetContext from '../../../context/sheetContext'
import AssociatedSearch from '../../../components/AssociatedSearch/AssociatedSearch'
import AssociateFieldset from '../../../components/AssociateFieldset/AssociateFieldset'
import { patchService } from '../../../services/script-api'
import _ from 'lodash'

const EditRelatedContent = (props) => {
  const { data, title, id, type } = props

  const context = useContext(AppContext)
  const sheetContext = useContext(SheetContext)

  const [stateToggleAssociation, setStateToggleAssociation] = useState(false)
  const [stateToggleResearch, setStateToggleResearch] = useState(false)
  const [stateAssociatedProjects, setStateAssociatedProjects] = useState([])
  const [stateAssociatedAllProjects, setStateAssociatedAllProjects] = useState(
    []
  )
  const [stateAssociatedResearches, setStateAssociatedResearches] = useState(
    []
  )

  useEffect(() => {
    const groupedAssociatedProjects = _.groupBy(
      stateAssociatedProjects,
      (u) => u.typeId
    )
    const newArray = Object.entries(groupedAssociatedProjects)
    const compiledArray = []
    newArray.forEach((item) => {
      let projects = []
      item[1].forEach((item) => (projects = [...projects, ...item.projects]))
      compiledArray.push({ typeId: parseInt(item[0]), projects: projects })
    })

    setStateAssociatedAllProjects(compiledArray)
  }, [stateAssociatedProjects])

  const nextOptionSelected = () => {
    let currentUsedOptions = []
    stateAssociatedAllProjects.forEach((item) =>
      currentUsedOptions.push(item.typeId)
    )

    let unusedOptions = context.currentOriginAssociation.result.filter(
      (option) => {
        return currentUsedOptions.indexOf(option.id) === -1
      }
    )

    if (!!unusedOptions.length > 0) {
      return unusedOptions[0].id
    }
  }

  const blankProjectAssociation = {
    typeId: nextOptionSelected(),
    projects: [],
  }

  const blankResearchAssociation = []

  const compileSheetAssociatedProjects = () => {
    let content = []
    let projectAssociations = data.associations.filter(
      (item) => item.contentType !== 'Pesquisa'
    )
    let editableAssociations = projectAssociations.filter(
      (item) => !!item.editable
    )

    if (!!editableAssociations.length) {
      editableAssociations.forEach((el) => {
        content.push({
          typeId: el.type.id,
          projects: [{ contentId: el.contentId, title: el.title }],
        })
      })
    }
    return content
  }

  const compileSheetAssociatedResearches = () => {
    let content = []
    let researchAssociations = data.associations.filter(
      (item) => item.contentType === 'Pesquisa'
    )

    if (!!researchAssociations.length) {
      researchAssociations.forEach((el) => {
        content.push({ contentId: el.contentId, title: el.title })
      })
    }
    return content
  }

  useEffect(() => {
    let projectAssociations =
      !!data.associations &&
      data.associations.filter((item) => item.contentType !== 'Pesquisa')
    let editableAssociations = projectAssociations.filter(
      (item) => !!item.editable
    )
    const hasAssociatedProjects = !!editableAssociations.length
    const hasAssociatedResearches =
      !!data.associations.length &&
      !!data.associations.filter((item) => item.contentType === 'Pesquisa')
        .length
    setStateAssociatedProjects(
      hasAssociatedProjects
        ? compileSheetAssociatedProjects()
        : [blankProjectAssociation]
    )
    setStateAssociatedResearches(
      hasAssociatedResearches
        ? compileSheetAssociatedResearches()
        : blankResearchAssociation
    )
    setStateToggleAssociation(hasAssociatedProjects)
    setStateToggleResearch(hasAssociatedResearches)
    // eslint-disable-next-line
  }, [data.associations])

  const createMarkup = (string) => {
    return { __html: string }
  }

  const toggleAssociation = () =>
    setStateToggleAssociation(!stateToggleAssociation)
  const toggleResearch = () => setStateToggleResearch(!stateToggleResearch)

  const addAssociatedProject = () => {
    if (
      stateAssociatedAllProjects.length <
      context.currentOriginAssociation.result.length
    ) {
      const newState = [...stateAssociatedAllProjects]
      newState.push({
        typeId: nextOptionSelected(),
        projects: [],
      })
      setStateAssociatedAllProjects(newState)
    }
  }

  const handleChangeAssociatedProject = (newValue, actionMeta, index) => {
    const actions = {
      'create-option': () => handleChangeAssociation(newValue, index),
      'select-option': () => handleChangeAssociation(newValue, index),
      'remove-value': () => handleChangeAssociation(newValue, index),
      'pop-value': () => handleChangeAssociation(newValue, index),
      clear: () => handleChangeAssociation(newValue, index),
    }

    return actions[actionMeta]()
  }

  const handleChangeAssociation = (newValue, index) => {
    let projects = []
    if (!!newValue) {
      newValue.forEach((item) => {
        projects.push({ contentId: item.value, title: item.label })
      })
    }

    const newState = [...stateAssociatedAllProjects]
    newState[index].projects = projects
    setStateAssociatedAllProjects(newState)
  }

  const handleChangeAssociationType = (value, index) => {
    const newState = [...stateAssociatedAllProjects]
    newState[index].typeId = parseInt(value)

    setStateAssociatedAllProjects(newState)
  }

  const removeAssociatedProject = (indexOf) => {
    const newState = stateAssociatedAllProjects.filter(
      (item, index) => index !== indexOf
    )
    setStateAssociatedAllProjects(newState)
  }

  const handleChangeAssociatedResearch = (d) => {
    let researches = []

    if (!!d && !!d.length) {
      d.forEach((item) => {
        researches.push({ contentId: item.value, title: item.label })
      })
    }
    setStateAssociatedResearches(researches)
  }

  const compileAssociatedProjects = (d) => {
    let e = []
    if (!!d) {
      d.projects.forEach((item) => {
        e.push({ value: item.contentId, label: item.title })
      })
    }
    return e
  }

  const compileAssociatedResearches = (d) => {
    let e = []
    if (!!d && !!d.length) {
      d.forEach((item) => {
        e.push({ value: item.contentId, label: item.title })
      })
    }
    return e
  }

  const compileProjects = (projects) => {
    let list = []
    projects.map((project) => list.push(project.contentId))

    return list
  }

  const compileAssociationProjects = () => {
    let projects = []
    stateAssociatedAllProjects.forEach((project) => {
      let list = {
        typeAssociationId: project.typeId,
        contentsId: compileProjects(project.projects),
      }
      projects.push(list)
    })
    return projects
  }

  const compileAssociationResearchs = () => {
    let list = []
    stateAssociatedResearches.forEach((researchs) => {
      list.push(researchs.contentId)
    })
    return list
  }

  const filterOptions = (position) => {
    let currentUsedOptions = []

    let currentPositionOption = context.currentOriginAssociation.result.filter(
      (item) => item.id === stateAssociatedAllProjects[position].typeId
    )

    stateAssociatedAllProjects.forEach((item) =>
      currentUsedOptions.push(item.typeId)
    )

    let unusedOptions = context.currentOriginAssociation.result.filter(
      (option) => currentUsedOptions.indexOf(option.id) === -1
    )

    return currentPositionOption.length > 0
      ? [...unusedOptions, compileNewOption(currentPositionOption)]
      : unusedOptions
  }

  const compileNewOption = (options) => {

    return {
      id: options[0].id,
      origin: options[0].origin,
      name: options[0].name,
      description: options[0].description,
    }
  }

  const editIsValid = () => {
    const verifyProjectsFields = stateToggleAssociation
      ? stateAssociatedAllProjects.every((item) => !!item.projects.length)
      : true
    const verifyResearchesField = stateToggleResearch
      ? !!stateAssociatedResearches.length
      : true
    return verifyProjectsFields && verifyResearchesField
  }

  const _updateDataComplement = (ev) => {
    ev.preventDefault()

    let obj = {
      contentId: id,
      projects: stateToggleAssociation ? compileAssociationProjects() : [],
      researchesIds: stateToggleResearch ? compileAssociationResearchs() : [],
    }

    context.setShowGlassLoading(true)

    patchService('contents/associations', obj)
      .then(() => {
        context.setShowGlassLoading(false)
        context.sendMessage('Ficha atualizada com sucesso!')
        context.setCurrentStepBox('closed')
        sheetContext._getProjectSheet(id, type)
      })
      .catch((e) => {
        context.setShowGlassLoading(false)
        context.sendMessage('Erro ao atualizar a ficha!', 'error')
        // context.setCurrentStepBox('closed')
        sheetContext._getProjectSheet(id, type)
      })
  }

  return (
    <form
      onSubmit={(e) => _updateDataComplement(e)}
      className="editRelatedContent"
    >
      <div className="formField">
        <Switch
          blockLabel="Associar a outro projeto?"
          switchOnText={
            <div
              dangerouslySetInnerHTML={createMarkup(
                `Sim, <strong>${title}</strong> é:`
              )}
            />
          }
          event={() => toggleAssociation()}
          switchOn={stateToggleAssociation}
        />
      </div>

      {
        <div style={{ display: stateToggleAssociation ? 'block' : 'none' }}>
          {!!stateAssociatedAllProjects.length &&
          stateAssociatedAllProjects.map((item, index) => {
            return (
              <div key={index} className="formFieldColumn">
                <AssociateFieldset
                  id={id}
                  indexPosition={index}
                  projectsLength={stateAssociatedAllProjects.length}
                  removeField={removeAssociatedProject}
                  handleChangeCreatable={handleChangeAssociatedProject}
                  associations={stateAssociatedAllProjects}
                  handleChangeAssociationType={handleChangeAssociationType}
                  endpoint={type}
                  thesaurusNumber={21}
                  data={filterOptions(index)}
                  selected={
                    !!stateAssociatedAllProjects[index].typeId
                      ? stateAssociatedAllProjects[index].typeId
                      : filterOptions(index)[0]
                  }
                  value={compileAssociatedProjects(
                    stateAssociatedAllProjects[index]
                  )}
                />
              </div>
            )
          })}
        </div>
      }

      {stateToggleAssociation &&
      stateAssociatedAllProjects.length <
      context.currentOriginAssociation.result.length && (
        <div className="dashedBox" onClick={() => addAssociatedProject()}>
          <span>+ Adicionar outro</span>
        </div>
      )}

      <div className="formField">
        <Switch
          blockLabel="Associar à pesquisa?"
          switchOn={stateToggleResearch}
          event={() => toggleResearch()}
        />
      </div>

      {stateToggleResearch && (
        <div className="formFieldColumn" style={{ zIndex: 10 }}>
          <AssociatedSearch
            value={compileAssociatedResearches(stateAssociatedResearches)}
            defaultValue={compileAssociatedResearches(
              stateAssociatedResearches
            )}
            handleChange={handleChangeAssociatedResearch}
          />
        </div>
      )}

      <BoxFooter>
        <Button
          text="Salvar"
          toValidate={true}
          isValidForm={editIsValid()}
          classButton="buttonGreen large"
          typeButton="submit"
          dataQA="button-prospect-new-save"
        />
      </BoxFooter>
    </form>
  )
}

export default EditRelatedContent

import React, { useState } from "react";
import "./styles/ThesaurusSearch.scss";
import Select from "react-select";
import AsyncLoading from "../../AsyncLoading/AsyncLoading";
import SingleValueThesaurusSearch from "./SingleValueThesaurusSearch/SingleValueThesaurusSearch";
import ClearIndicator from "./ClearIndicator/ClearIndicator";
import OptionThesaurus from '../../OptionThesaurus/OptionThesaurus'

const ThesaurusSearch = (props) => {
  const { options, searchInput, setSearchInput, setStateSteps } = props;

  const [stateSearchTerm, setStateSearchTerm] = useState("");

  const filterOption = (option, inputValue) => {
    const { label, data } = option;
    const remissives = data.remissives.map((item) => item.toLowerCase());
    const input = inputValue.toLowerCase();
    setStateSearchTerm(inputValue);

    return (
      label.toLowerCase().includes(input) ||
      !!remissives.filter((remissive) => remissive.includes(input)).length
    );
  };

  const handleChange = (value) => {
    if (!!value) {
      setSearchInput({
        ...value,
        remissives: value.remissives.filter((item) =>
          item.toLowerCase().includes(stateSearchTerm.toLocaleLowerCase())
        ),
      });
      setStateSteps((prev) => [...prev, value.id]);
    } else {
      setSearchInput("");
    }
  };

  return (
    <div className="thesaurusSearch">
      <Select
        className="reactSelect"
        classNamePrefix="thesaurusSearch"
        options={options}
        filterOption={filterOption}
        value={searchInput}
        onChange={(value) => handleChange(value)}
        placeholder="Buscar"
        components={{
          LoadingIndicator: () => <AsyncLoading />,
          OptionThesaurus,
          SingleValue: SingleValueThesaurusSearch,
          ClearIndicator,
        }}
        noOptionsMessage={() => "Nenhum termo encontrado"}
        isMulti={false}
        isClearable={true}
        openMenuOnClick={false}
        openMenuOnFocus={false}
      />
    </div>
  );
};

export default ThesaurusSearch;

import React from "react";
import "./styles/TopSection.scss";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import RoundBackButton from "../../../components/RoundBackButton/RounbBackButton";
// import StepCounter from '../../../components/StepCounter/StepCounter'

const TopSection = (props) => {
  const { currentStep, setStep, title } = props;

  return (
    <Breadcrumb>
      <div className="registerContainer">
        <div className="infoContainer">
          {currentStep > 1 && (
            <div
              className="backButtonContainer"
              onClick={() => setStep(currentStep - 1)}
            >
              <RoundBackButton color="#17B4E1" backgroundColor="#24233D" />
            </div>
          )}

          <div className="registerTitle">
            <h2>
              {currentStep > 1 ? `${title}` : "Cadastrar: Obra Literária"}
            </h2>
            {currentStep > 1 && (
              <div>
                <span>Obra Literária</span>
              </div>
            )}
          </div>
        </div>

        {/* <StepCounter currentStep={currentStep} maxStep={2} />*/}
      </div>
    </Breadcrumb>
  );
};

export default TopSection;

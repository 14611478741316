import React, { useRef, useState } from "react";
import "./styles/ActivitiesNavigation.scss";
import SmallLoading from "../../../components/SmallLoading/SmallLoading";
import NextArrowIcon from "../../../components/VectorIcons/NextArrowIcon";

const ActivitiesNavigation = (props) => {
  const { setCurrentType, currentType, groups, loading } = props;

  const [currentScroll, setCurrentScroll] = useState(["left"]);

  const tabsListRef = useRef(null);
  const containerRef = useRef(null);

  const handleScroll = () => {
    const scrollLeft = tabsListRef.current.scrollLeft;
    const containerWidth = containerRef.current.scrollWidth;
    const scrollSize = containerWidth - containerRef.current.clientWidth;

    if (scrollLeft !== 0 && scrollLeft !== scrollSize) {
      if (!currentScroll.includes("left") || !currentScroll.includes("right"))
        setCurrentScroll(["left", "right"]);
    }

    if (scrollLeft === 0) setCurrentScroll(["left"]);
    if (scrollLeft === scrollSize) setCurrentScroll(["right"]);
  };

  const handleScrollLeft = () => {
    tabsListRef.current.scrollLeft -= 300;
    setCurrentScroll(["left"]);
  };

  const handleScrollRight = () => {
    tabsListRef.current.scrollLeft += 300;
    setCurrentScroll(["right"]);
  };

  const LoadingLayout = () => {
    return (
      <div className="activitiesNavigation">
        <div className="activitiesNavigationContainer centerLoading">
          <SmallLoading />
        </div>
      </div>
    );
  };

  if (loading) return <LoadingLayout />;

  return (
    <div className="activitiesNavigation">
      <div className="outterContainer">
        <div
          className="activitiesNavigationContainer"
          ref={tabsListRef}
          onScroll={handleScroll}
        >
          <div className="typesContainer" ref={containerRef}>
            {groups.map((group, index) => {
              return (
                <div
                  className={`type ${
                    group.value === currentType ? "active" : ""
                  }`}
                  key={index}
                  onClick={() => setCurrentType(group.value)}
                >
                  {group.value}
                </div>
              );
            })}
          </div>
        </div>

        {currentScroll.includes("left") && (
          <div className="goRight" onClick={handleScrollRight}>
            <NextArrowIcon />
          </div>
        )}

        {currentScroll.includes("right") && (
          <div className="goLeft" onClick={handleScrollLeft}>
            <NextArrowIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivitiesNavigation;

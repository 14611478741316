import React, { useContext, useEffect, useState, useCallback } from "react";
import "./styles/TableListFile.scss";
import AppContext from "../../../context/appContext";
import UserContext from "../../../context/userContext";
import TableLoading from "../../../components/TableLoading/TableLoading";
import ListCount from "../../../components/ListCount/ListCount";
import SimpleScriptBox from "../SimpleScriptBox/SimpleScriptBox";
import _ from "lodash";
import BlockList from "./BlockList/BlockList";
import AttachmentsContext from "../../../context/attachmentsContext";
import ReleaseTextsModal from "./ReleaseTextsModal/ReleaseTextsModal";

const TableListFiles = (props) => {
  const {
    handleEditFile,
    data,
    loading,
    handleConfirmationDeleteFile,
    havePermission,
    condition,
    type,
    projectType,
    reload,
    isExternal,
  } = props;

  const [state, setState] = useState(false);
  const [stateListFiles, setStateListFiles] = useState([]);

  const [stateOpen, setStateOpen] = useState(false);
  const [stateCurrent, setStateCurrent] = useState([]);

  const attachmentContext = useContext(AttachmentsContext);
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  const haveExternalPermission =
    userContext.havePermission(["ExternalProject"]).length === 0;

  const compileFiles = useCallback(() => {
    let allGroup = attachmentContext.compileAll(data, condition);
    let chapterCompiled = attachmentContext.compiledChapter(
      data,
      type,
      condition
    );
    let materialCompiled = attachmentContext.compileMaterial(
      data,
      "Material Complementar"
    );
    let mergeGroups = { ...allGroup, ...materialCompiled, ...chapterCompiled };

    const mergeObj = Object.entries(mergeGroups).map((files) => files[1]);

    setStateListFiles(
      mergeObj?.filter((item) => item?.[0]?.attachmentType?.id !== 1211)
    );
  }, [attachmentContext, data, type, condition]);

  const toggleGroupList = (ev) => {
    let targetActive = ev.target.closest(".groupList");

    if (targetActive) {
      targetActive.classList.toggle("opened");
    }
  };

  const returnColor = (count) => (!!count ? "#7f808e" : "#ec1045");

  const handleOpenReleaseTexts = (currentAttachment, versions) => {
    let copyCurrent = _.cloneDeep(currentAttachment);
    setStateOpen(true);
    setStateCurrent({
      current: copyCurrent,
      versions,
    });
  };

  const compileFilesForChannels = useCallback((filesArray) => {
    const itemsWithType = filesArray.map((item) => ({
      ...item,
      type: item.attachmentType.name,
    }));
    const groupedByType = _.groupBy(itemsWithType, "type");
    const groupedByTypeOrdered = Object.fromEntries(
      Object.entries(groupedByType).sort((a, b) => {
        return a[0].toLowerCase() > b[0].toLowerCase() ? -1 : 1;
      })
    );
    const groupedByTypeEntries = Object.entries(groupedByTypeOrdered);
    const groupedAndOrdered = groupedByTypeEntries.map((item) =>
      item[1].sort((a, b) => {
        return a.fileName.toLowerCase() > b.fileName.toLowerCase() ? 1 : -1;
      })
    );
    setStateListFiles(groupedAndOrdered.flat(1));
  }, []);

  useEffect(() => {
    if (haveExternalPermission) compileFilesForChannels(data);
    if (!haveExternalPermission) compileFiles();
  }, [haveExternalPermission, compileFilesForChannels, compileFiles, data]);

  const DefaultList = () => {
    return (
      <div className="tableList">
        <ul className="itemsList">
          <li className="headerList">
            <span className="headerType">Tipo</span>
            <span className="headerVersion">Versão</span>
            <span className="headerName">Arquivo</span>
            <span className="headerReceivedAt">Recebido em</span>
            <span className="headerReleasedAt">Última liberaçao</span>
            <span
              className={`headerActions ${
                haveExternalPermission || isExternal ? "external" : ""
              }`}
            />
          </li>

          {stateListFiles.map((list, groupIndex) => {
            let l = _.maxBy(list, (item) => item.version ?? -Infinity);
            return (
              <li className="groupList" key={groupIndex}>
                {
                  <BlockList
                    item={l}
                    list={list}
                    condition={condition}
                    toggleGroupList={toggleGroupList}
                    havePermission={havePermission}
                    handleEditFile={handleEditFile}
                    handleConfirmationDeleteFile={handleConfirmationDeleteFile}
                    returnColor={returnColor}
                    handleOpenReleaseTexts={handleOpenReleaseTexts}
                    _class="mainItem"
                    type={type}
                    isExternal={isExternal}
                  />
                }

                <div className="colapsedItems">
                  {_.orderBy(list, ["version"], ["desc"])
                    .slice(1)
                    .map((item, itemIndex) => {
                      return (
                        <BlockList
                          item={item}
                          list={list}
                          condition={condition}
                          toggleGroupList={toggleGroupList}
                          havePermission={havePermission}
                          handleEditFile={handleEditFile}
                          handleConfirmationDeleteFile={
                            handleConfirmationDeleteFile
                          }
                          returnColor={returnColor}
                          handleOpenReleaseTexts={handleOpenReleaseTexts}
                          _class="colapsedItem"
                          key={itemIndex}
                          type={type}
                          isExternal={isExternal}
                        />
                      );
                    })}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const ChannelsList = () => {
    return (
      <div className="tableList">
        <ul className="itemsList">
          <li className="headerList">
            <span className="headerType">Tipo</span>
            <span className="headerName">Arquivo</span>
            <span className="headerReceivedAt">Recebido em</span>
            <span
              className={`headerActions ${
                haveExternalPermission || isExternal ? "external" : ""
              }`}
            />
          </li>

          {stateListFiles.map((list, groupIndex) => {
            return (
              <li className="groupList" key={groupIndex}>
                {
                  <BlockList
                    item={list}
                    list={list}
                    condition={condition}
                    havePermission={havePermission}
                    handleEditFile={handleEditFile}
                    handleConfirmationDeleteFile={handleConfirmationDeleteFile}
                    returnColor={returnColor}
                    isExternal={isExternal}
                    haveExternalPermission={haveExternalPermission}
                    _class="mainItem"
                  />
                }
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const handleAttachmentsList = haveExternalPermission ? (
    <ChannelsList />
  ) : (
    <DefaultList />
  );

  return (
    <section id="TableListFiles">
      <div className="filesListCounter">
        <ListCount
          total={stateListFiles.length}
          text={`${
            stateListFiles.length > 1
              ? "Anexos encontrados"
              : "Anexo encontrado"
          }`}
        />
      </div>

      <div className="tableContainer">
        {loading ? (
          <TableLoading />
        ) : (
          stateListFiles.length > 0 && handleAttachmentsList
        )}
      </div>

      {state && (
        <SimpleScriptBox open={state} toggleScriptBox={() => setState(false)}>
          <div onClick="">SIM</div>
          <div onClick="">NÃO</div>
        </SimpleScriptBox>
      )}

      {stateOpen && (
        <ReleaseTextsModal
          releaseTexts={stateCurrent}
          setStateOpen={setStateOpen}
          reload={reload}
          projectType={projectType}
          submitCallback={() => appContext.setCurrentStepBox("editAttachment")}
        />
      )}
    </section>
  );
};

export default TableListFiles;

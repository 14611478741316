import React, { useContext, useEffect, useState } from "react";
import FieldsetSelect from "../../../components/FieldsetSelect/FieldsetSelect";
import FieldsetNumberMasked from "../../../components/FieldsetNumberMasked/FieldsetNumberMasked";
import SwitchText from "../../../components/SwitchText/SwitchText";
import Switch from "../../../components/Switch/Switch";
import AssociateFieldset from "../../../components/AssociateFieldset/AssociateFieldset";
import NextArrowIcon from "../../../components/VectorIcons/NextArrowIcon";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import AssociatedSearch from "../../../components/AssociatedSearch/AssociatedSearch";
import AppContext from "../../../context/appContext";
import RegisterContext from "../../../context/registerContext";
import ThesaurusDescription from "../../../components/ThesaurusDescription/ThesaurusDescription";

const Step2 = (props) => {
  const {
    formatOptions,
    selected,
    getDramaturgy,
    value,
    getSchedule,
    getProgramFrequency,
    title,
    getAssociatedProjects,
    setStateCurrentStep,
    getResearches,
    getFormat,
    format,
  } = props;

  const context = useContext(AppContext);
  const registerContext = useContext(RegisterContext);

  const initialErrorFields = {
    title: false,
    createBy: false,
    origin: false,
    format: false,
    seasonNumber: false,
    originalProduct: false,
  };

  const initialFormat = { id: "", label: "" };

  const [stateAssociateProject, setStateAssociateProject] = useState(false);
  const [stateAssociatedProjects, setStateAssociatedProjects] = useState([]);
  const [stateAssociateToSearch, setStateAssociateToSearch] = useState(false);
  const [stateAssociatedSearches, setStateAssociatedSearches] = useState([]);
  const [stateError, setStateError] = useState(initialErrorFields);
  const [stateFormat, setStateFormat] = useState(initialFormat);
  const [stateSchedule, setStateSchedule] = useState(null);
  const [stateProgramFrequency, setStateProgramFrequency] = useState(null);

  useEffect(() => {
    if (!stateAssociateProject) setStateAssociatedProjects([]);
    // eslint-disable-next-line
  }, [stateAssociateProject]);

  useEffect(() => {
    getAssociatedProjects(compileAssociationProjects());
    // eslint-disable-next-line
  }, [stateAssociatedProjects]);

  useEffect(() => {
    if (!stateAssociateToSearch) setStateAssociatedSearches([]);
    // eslint-disable-next-line
  }, [stateAssociateToSearch]);

  useEffect(() => {
    !!format && setStateFormat(format);
    if (stateFormat.label !== "Novela") {
      setStateSchedule(null);
      getSchedule(null);
    }

    if (stateFormat.label !== "Programa") {
      setStateProgramFrequency(null);
      getProgramFrequency(null);
    }
    // eslint-disable-next-line
  }, [format]);

  const setSchedule = (schedule) => {
    const handleValue = stateSchedule === schedule ? null : schedule;
    setStateSchedule(handleValue);
    getSchedule(handleValue);
  };

  const setProgram = (schedule) => {
    setStateProgramFrequency(schedule);
    getProgramFrequency(schedule);
  };

  const addAssociatedProject = () => {
    if (
      stateAssociatedProjects.length <
      context.currentOriginAssociation.result.length
    ) {
      const newState = [...stateAssociatedProjects];
      newState.push({
        typeId: nextOptionSelected(),
        projects: [],
      });
      setStateAssociatedProjects(newState);
    }
  };

  const toggleAssociationProject = () => {
    addAssociatedProject();
    setStateAssociateProject(!stateAssociateProject);
  };

  const removeAssociatedProject = (indexOf) => {
    const newState = stateAssociatedProjects.filter(
      (item, index) => index !== indexOf
    );
    setStateAssociatedProjects(newState);
  };

  const handleChangeAssociatedProject = (newValue, actionMeta, index) =>
    handleChangeAssociation(newValue, index);

  const handleChangeAssociation = (newValue, index) => {
    let projects = [];
    if (!!newValue) {
      newValue.forEach((item) => {
        projects.push({ contentId: item.value, title: item.label });
      });
    }

    const newState = [...stateAssociatedProjects];
    newState[index].projects = projects;
    setStateAssociatedProjects(newState);
  };

  const handleChangeAssociationType = (value, index) => {
    const newState = [...stateAssociatedProjects];
    newState[index].typeId = parseInt(value);

    setStateAssociatedProjects(newState);
  };

  const nextOptionSelected = () => {
    let currentUsedOptions = [];
    stateAssociatedProjects.forEach((item) =>
      currentUsedOptions.push(item.typeId)
    );

    let unusedOptions = context.currentOriginAssociation.result.filter(
      (option) => {
        return currentUsedOptions.indexOf(option.id) === -1;
      }
    );

    if (!!unusedOptions.length > 0) {
      return unusedOptions[0].id;
    }
  };

  const filterOptions = (position) => {
    let currentUsedOptions = [];

    let currentPositionOption = context.currentOriginAssociation.result.filter(
      (item) => item.id === stateAssociatedProjects[position].typeId
    );

    stateAssociatedProjects.forEach((item) =>
      currentUsedOptions.push(item.typeId)
    );

    let unusedOptions = context.currentOriginAssociation.result.filter(
      (option) => currentUsedOptions.indexOf(option.id) === -1
    );

    let compiled =
      currentPositionOption.length > 0
        ? [...unusedOptions, compileNewOption(currentPositionOption)]
        : unusedOptions;

    return compiled;
  };

  const compileNewOption = (options) => {
    let newOptionCompiled = {
      id: options[0].id,
      origin: options[0].origin,
      name: options[0].name,
      description: options[0].description,
    };

    return newOptionCompiled;
  };

  const compileAssociatedProjects = (data) => {
    let e = [];
    if (!!data) {
      data.projects.forEach((item) => {
        e.push({ value: item.contentId, label: item.title });
      });
    }
    return e;
  };

  const compileProjects = (projects) => {
    let list = [];
    projects.map((project) => list.push(project.contentId));
    return list;
  };

  const compileAssociationProjects = () => {
    let projects = [];
    if (!!stateAssociatedProjects.length) {
      stateAssociatedProjects.forEach((project) => {
        let list = {
          typeAssociationId: project.typeId,
          contentsId: compileProjects(project.projects),
        };
        projects.push(list);
      });
    }
    return projects;
  };

  const handleChangeAssociated = (item) => {
    setStateAssociatedSearches(item);
    getResearches(item);
  };

  const step2isValid = () => {
    let isValidFormat = !!stateFormat.id && !stateError.format;
    let isValidSearch = !!stateAssociatedSearches.length;
    let isValidProject = stateAssociatedProjects.every(
      (item) => !!item.projects.length
    );

    if (stateAssociateProject && stateAssociateToSearch) {
      return isValidFormat && isValidSearch && isValidProject;
    } else if (stateAssociateProject) {
      return isValidProject && isValidFormat;
    } else if (stateAssociateToSearch) {
      return isValidSearch && isValidFormat;
    } else {
      return isValidFormat;
    }
  };

  const handleChangeFormat = (ev) => {
    const id = ev.nativeEvent.target.selectedIndex;
    const idText = ev.nativeEvent.target[id].text;

    setError("format", isNaN(parseInt(ev.target.value)));

    const parsedId = parseInt(ev.target.value);
    const handleId = !isNaN(parsedId) ? parsedId : "placeholder";

    setStateFormat({ id: handleId, label: idText });
    getFormat({ id: handleId, label: idText });
  };

  const handleBlurFormat = (ev) => {
    setError("format", isNaN(parseInt(ev.target.value)));
  };

  const setError = (key, value) => {
    setStateError({
      ...stateError,
      [key]: value,
    });
  };

  const createMarkup = (string) => {
    return { __html: string };
  };

  return (
    <>
      <div className="formField">
        <div className="thesaurusDescriptionField">
          <ThesaurusDescription label="Formato" originId={19} />
          <FieldsetSelect
            name="formatId"
            placeholder="Selecione um Formato"
            data={formatOptions}
            value={value}
            handleChange={handleChangeFormat}
            handleBlur={handleBlurFormat}
            isError={stateError.format}
            msgError="Este campo é obrigatório"
            endpoint="dramaturgies"
            selected={selected || "placeholder"}
          />
        </div>

        {!!format && format.id !== "" && (
          <div className="smallField">
            <FieldsetNumberMasked
              mask="9999"
              autoComplete="off"
              name="chapters"
              label={stateFormat.label === "Série" ? "Episódios" : "Capítulos"}
              type="text"
              placeholder=""
              msgError="Este campo é obrigatório"
              handleChange={(ev) =>
                getDramaturgy("chapter", parseInt(ev.target.value))
              }
            />
            <span className="optionalText">Opcional</span>
          </div>
        )}
      </div>

      {!!format && format.label === "Novela" && (
        <div className="formFieldColumn">
          <SwitchText
            label="Horário"
            data={registerContext.listSoap.result}
            handleChange={setSchedule}
            size="large"
            value={stateSchedule}
          />
          <span className="optionalText">Opcional</span>
        </div>
      )}

      {!!format && format.label === "Programa" && (
        <div className="formField">
          <SwitchText
            label="Periodicidade"
            data={registerContext.listFrequencies.result}
            handleChange={setProgram}
            size="small"
            value={stateProgramFrequency}
          />
        </div>
      )}

      <div className="formField">
        <Switch
          blockLabel="Associar a outro projeto?"
          event={() => toggleAssociationProject()}
          switchOn={stateAssociateProject}
          switchOnText={
            <div
              dangerouslySetInnerHTML={createMarkup(
                `Sim, <strong>${title}</strong> é:`
              )}
            />
          }
        />
      </div>

      {stateAssociateProject &&
        stateAssociatedProjects.map((item, index) => {
          return (
            <div key={index} className="formField">
              <AssociateFieldset
                indexPosition={index}
                projectsLength={stateAssociatedProjects.length}
                removeField={removeAssociatedProject}
                handleChangeCreatable={handleChangeAssociatedProject}
                associations={stateAssociatedProjects}
                handleChangeAssociationType={handleChangeAssociationType}
                endpoint="dramaturgies"
                thesaurusNumber={21}
                selected={
                  !!stateAssociatedProjects[index].typeId !== 0
                    ? stateAssociatedProjects[index].typeId
                    : filterOptions(index)[0]
                }
                data={filterOptions(index)}
                value={compileAssociatedProjects(
                  stateAssociatedProjects[index]
                )}
              />
            </div>
          );
        })}

      {stateAssociateProject &&
        stateAssociatedProjects.length <
          context.currentOriginAssociation.result.length && (
          <div className="dashedBox" onClick={() => addAssociatedProject()}>
            <span>+ Adicionar outro</span>
          </div>
        )}

      <div className="formField">
        <Switch
          blockLabel="Associar à pesquisa?"
          switchOn={stateAssociateToSearch}
          event={() => setStateAssociateToSearch(!stateAssociateToSearch)}
        />
      </div>

      {stateAssociateToSearch && (
        <div className="formFieldColumn" style={{ zIndex: 10 }}>
          <AssociatedSearch handleChange={handleChangeAssociated} />
        </div>
      )}

      <div className="formField buttonField">
        <SimpleButton
          text="Próximo: Anexos"
          classButton="buttonBlue extraLarge"
          iconSide="right"
          dataQA="button-prospect-edit"
          isValidForm={step2isValid()}
          toValidate={true}
          clickEvent={step2isValid() ? () => setStateCurrentStep(3) : () => {}}
        >
          <NextArrowIcon />
        </SimpleButton>
      </div>
    </>
  );
};

export default Step2;

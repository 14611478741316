import React from 'react'
import ConfirmationBox from '../../../../components/ConfirmationBox/ConfirmationBox'
import SimpleButton from '../../../../components/SimpleButton/SimpleButton'

const LightBoxesSheetBasic = (props) => {

  const { showConfirmHistory, setShowConfirmHistory, data, updateProject } = props

  return (
    <>
      {showConfirmHistory &&
        <ConfirmationBox
          open={showConfirmHistory}
          closedBox={() => setShowConfirmHistory(false)}
          title="Deseja alterar o título do projeto?"
          text={
            <p>
              <span>
                Ao salvar esta alteração, você pode registrar ou não o título
                antigo <strong>{` ${data.title} `}</strong> no histórico do
                projeto.
              </span>
            </p>
          }
        >
          <div>
            <SimpleButton
              text="Cancelar"
              classButton="buttonCancel large"
              dataQA="button-prospect-edit"
              clickEvent={() => setShowConfirmHistory(false)}
            />

            <SimpleButton
              text="Alterar e não registrar"
              classButton="buttonConfirm large"
              dataQA="button-prospect-edit"
              clickEvent={(e) => updateProject(e, false)}
            />

            <SimpleButton
              text="Registrar e alterar"
              classButton="buttonBlue large"
              dataQA="button-prospect-edit"
              clickEvent={(e) => updateProject(e, true)}
            />
          </div>
        </ConfirmationBox>
      }
    </>
  )
}

export default LightBoxesSheetBasic
import React, { useContext, useEffect, useState } from "react";
import "./styles/InfoCard.scss";
import ScriptBoxCaller from "../ScriptBoxCaller/ScriptBoxCaller";
import AppContext from "../../context/appContext";
import Register from "../../context/registerContext";
import ReleaseTextIconMarked from "../VectorIcons/ReleaseTextIconMarked";

const InfoCard = (props) => {
  const {
    callerIcon,
    callerEvent,
    cardTitle,
    files,
    havePermission,
    condition,
    isExternal,
  } = props;

  const context = useContext(AppContext);
  const registerContext = useContext(Register);
  const [filesFiltred, setFiles] = useState();

  useEffect(() => {
    !!files?.length &&
      setFiles(files?.filter((file) => file?.attachmentType?.id !== 1211));
  }, [files]);

  return (
    <div className="infoCard">
      <div className="cardHeader">
        <span className="cardTitle">{cardTitle}</span>
        <div className="uploadButton">
          {!!callerEvent && havePermission && (
            <ScriptBoxCaller callerIcon={callerIcon} event={callerEvent[0]} />
          )}
        </div>
      </div>

      {!!filesFiltred && filesFiltred.length > 0 ? (
        filesFiltred.slice(0, 3).map((item, index) => {
          return (
            <div className="cardContent" key={index}>
              <div>
                <span className="attachmentType">
                  {!!item.attachmentType && item.attachmentType.name}
                </span>
                <span className="attachmentDate">
                  {context.convertDatePtBr(item.receivedAt)}
                </span>
              </div>

              <div className="attachmentInfo">
                {!!item.attachmentType &&
                  item.attachmentType.name === condition && (
                    <>
                      <span className="capInfo">Cap {item.chapterNumber}</span>
                      <span className="blockInfo">
                        Bloco {item.blockNumber}
                      </span>
                    </>
                  )}

                {!isExternal && 
                  <span className="attachmentVersion">
                    <span>
                      {!!item.attachmentType && `${item.version}ª versão`}
                    </span>
                    {!!item.release && <ReleaseTextIconMarked />}
                  </span>
                }
              </div>

              <div className="attachmentName">
                <div
                  onClick={() =>
                    registerContext.getUrlS3(item.fileId, item.fileName)
                  }
                >
                 {item.fileName}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="cardContent">
          <span>Não há nenhum anexo.</span>
        </div>
      )}

      {!!files?.length && (
        <div className="cardContent">
          <div className="allAttachments">
            <ScriptBoxCaller
              callerText="Ver todos os anexos"
              event={callerEvent[1]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoCard;

import React, { useContext } from "react";
import "./styles/RecommendationCard.scss";
import AppContext from "../../../context/appContext";
import EditIcon from "../../../components/VectorIcons/EditIcon";
import ScriptBoxCaller from "../../../components/ScriptBoxCaller/ScriptBoxCaller";
import Avatar from "../../../components/Avatar/Avatar";

const RecommendationCard = (props) => {
  const { cardTitle, callerEvent, recommendation } = props;

  const appContext = useContext(AppContext);

  return (
    <div id="RecomendationCard">
      <div className="cardHeader">
        <span className="cardTitle">{cardTitle}</span>
        <ScriptBoxCaller callerIcon={<EditIcon />} event={callerEvent} />
      </div>

      <div className="cardContentContainer">
        {!!recommendation ? (
          <div className="cardContent">
            <span className="recomendationType">
              {recommendation.recommendationType.value}
            </span>
            <p className="justification">{recommendation.justification}</p>

            <div className="userInfo">
              <div className="userAvatar">
                <Avatar
                  bgColor={{
                    backgroundColor: appContext.generateColorFromName(
                      recommendation.user.name
                    ),
                  }}
                  letter={recommendation.user.name.slice(0, 1)}
                />
                <span className="userName">{recommendation.user.name}</span>
              </div>

              <span className="recomendationDate">
                {appContext.formatDate(
                  appContext.convertDatePtBr(recommendation.recommendationDate)
                )}
              </span>
            </div>
          </div>
        ) : (
          <div className="cardContent">
            <span>Projeto sem recomendação.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecommendationCard;

import React, { useContext, useEffect, useState } from 'react'
import ThesaurusDescription from '../../../../components/ThesaurusDescription/ThesaurusDescription'
import FieldsetSelect from '../../../../components/FieldsetSelect/FieldsetSelect'
import SwitchText from '../../../../components/SwitchText/SwitchText'
import FieldsetNumberMasked from '../../../../components/FieldsetNumberMasked/FieldsetNumberMasked'
import RegisterContext from '../../../../context/registerContext'
import { getService } from '../../../../services/script-api'

const ContentFormats = (props) => {

  const { type, currentFormat, chaptersOrEpisodes, soapOperaSchedule, programFrequency, getContentFormats } = props

  const registerContext = useContext(RegisterContext)

  const [state, setState] = useState({ loading: true, list: [] })
  const [stateError, setStateError] = useState(false)
  const [currentFormatId, setCurrentFormatId] = useState(currentFormat || "")
  const [stateSoap, setStateSoap] = useState({loading: true, list: []})
  const [currentSchedule, setCurrentSchedule] = useState(soapOperaSchedule || programFrequency || "")
  const [stateChapter, setStateChapter] = useState(chaptersOrEpisodes)

  const getFormatOrigins = () => {
    getService(`${type}/thesaurus?origins=22`).then((res) => {
      setState({
        ...state,
        loading: false,
        list: res.data
      })
    })
  }

  const getSoapSchedule = () => {
    getService('dramaturgies/soapoperaschedules').then((res) => {
      setStateSoap({
        ...stateSoap,
        loading: false,
        list: res.data
      })
    })
  }

  const selectedFormat = () => state.list.filter((e) => e.id === parseInt(currentFormatId))

  const handleChangeFormat = (ev) => {
    let int = parseInt(ev.target.value)
    setCurrentFormatId(ev.target.value)
    setStateError(!int)
    const [selected] = state.list.filter((e) => e.id === parseInt(ev.target.value))
    getContentFormats({ key: "formatId", value: selected.id, validate: true })
  }

  const handleBlurFormat = (ev) => {
    let int = parseInt(ev.target.value)
    setStateError(!int)
  }

  const filterSoapFrequency = (id) => obj[type].data.filter((e) => e.id === parseInt(id))

  const handleChange = (id) => {
    const handleValue = currentSchedule === id ? null : id;
    const [selected] = filterSoapFrequency(id)
    setCurrentSchedule(handleValue)
    getContentFormats({ key: obj[type].key, value: !!handleValue ? selected.id : null })
  }

  const handleChapter = (ev) => {
    setStateChapter(ev.target.value)
    getContentFormats({ key: "chaptersOrEpisodes", value: parseInt(ev.target.value) })
  }

  let obj = {
    dramaturgies: {
      label: "Horário",
      data: !stateSoap.loading ? stateSoap.list : [],
      handleChange: handleChange,
      size:"large",
      value: currentSchedule,
      key: "soapOperaSchedule",
      show: selectedFormat()[0]?.name === 'Novela'
    },
    varieties: {
      label: "Periodicidade",
      data: registerContext.listFrequencies.result,
      handleChange: handleChange,
      size:"small",
      value: currentSchedule,
      key: "programFrequency",
      show: selectedFormat()[0]?.name === 'Programa'
    }
  }

  useEffect(() => {
    getFormatOrigins()
    if(type === "dramaturgies") getSoapSchedule()
    if(type === "varieties") registerContext._getProgramFrequency()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (state.loading) return <div/>

  return (
    <div className="formField formatField">
      <div className="formFieldColumn">
        <div className="thesaurusDescriptionField">
          <ThesaurusDescription label="Formato" originId={19}/>

          <FieldsetSelect
            name="formatId"
            placeholder="Selecione um Formato"
            data={state.list}
            handleChange={handleChangeFormat}
            handleBlur={handleBlurFormat}
            isError={stateError}
            msgError="Este campo é obrigatório"
            selected={currentFormatId === "placeholder" ? null : currentFormatId}
          />
        </div>

        {!!currentFormatId && obj[type].show &&
          <div className="formField">
            <SwitchText {...obj[type]} />
          </div>
        }
      </div>

      <div className="chaptersContainer">
        <FieldsetNumberMasked
          mask="9999"
          autoComplete="off"
          name="chapters"
          label={type === "dramaturgies" ? "capítulos" : "Episódios"}
          type="text"
          placeholder=""
          value={stateChapter}
          msgError="Este campo é obrigatório"
          handleChange={handleChapter}
        />

        <span className="optionalText">Opcional</span>
      </div>
    </div>
  )
}

export default ContentFormats
import React from "react";
import "./styles/AnalystSelect.scss";
import Select from "react-select";
import Avatar from "../../../../components/Avatar/Avatar";
import SelectArrowIcon from "../../../../components/VectorIcons/SelectArrowIcon";

const Option = ({ children, innerRef, innerProps }) => {
  return (
    <div className="analystSelectOption" ref={innerRef} {...innerProps}>
      <Avatar letter={children.slice(0, 1)} />
      <span className="optionText">{children}</span>
    </div>
  );
};

const SingleValue = ({ children, innerRef, innerProps }) => {
  return (
    <div className="analystSingleValue" ref={innerRef} {...innerProps}>
      <Avatar letter={children.slice(0, 1)} />
      <span className="optionText">{children}</span>
    </div>
  );
};

const DropdownIndicator = ({ innerRef, innerProps, isFocused }) => {
  return (
    <div
      className={`dropdownIndicator ${isFocused ? "isFocused" : ""}`}
      ref={innerRef}
      {...innerProps}
    >
      <SelectArrowIcon />
    </div>
  );
};

const AnalystSelect = (props) => {
  const { options, value, defaultValue, placeholder, getUsersId, isClearable } =
    props;

  return (
    <Select
      className="analystSelect"
      classNamePrefix="analystSelect"
      placeholder={placeholder}
      onChange={getUsersId}
      options={options}
      value={value}
      defaultValue={defaultValue}
      components={{ Option, SingleValue, DropdownIndicator }}
      isClearable={isClearable}
    />
  );
};

export default AnalystSelect;

import React from "react";
import "./styles/RadioInput.scss";

const RadioInput = (props) => {
  const { label, options, value, handleChange, disabled } = props;

  const handleSelected = (id, value, path) => {
    handleChange(id, value, path);
  };

  return (
    <div className={`radioInput ${disabled ? "disabledRadio" : ""}`}>
      <label className="label">{label}</label>

      <div className="radioOptionList">
        {!!options.length &&
          options.map((item, index) => {
            return (
              <div
                className="radioOption"
                onClick={() => handleSelected(item.id, item.value, item.path)}
                key={index}
              >
                <div
                  className={`radioButton ${
                    value === item.id ? "selectedRadio" : ""
                  }`}
                >
                  <div className="radioDot" />
                </div>

                {!!item.icon && <div className="icon">{item.icon}</div>}

                <span className="radioText">{item.value}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default RadioInput;

import React from 'react'
import Textarea from '../../../../components/Textarea/Textarea'

const CharacterDescription = (props) => {

  const { description, handleChangeDescription } = props

  return (
    <div className="formFieldColumn">
      <Textarea
        maxRows={16}
        label="Descrição/Observação"
        placeholder="Digite a descrição/observação do personagem"
        handleChange={handleChangeDescription}
        value={description}
      />

      {!!description && (
        <>
          {description.length < 1024 ? (
            <span className="charactersCounter">
                  {1024 - description.length} caracteres restantes
                </span>
          ) : (
            <span className="charactersCounterError">
                  Limite de 1024 caracteres atingido
                </span>
          )}
        </>
      )}
    </div>
  )
}

export default CharacterDescription
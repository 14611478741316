import React from "react";
import "./style/Breadcrumb.scss";

const Breadcrumb = (props) => {
  return (
    <div id="Breadcrumb">
      <div className="container">{props.children}</div>
    </div>
  );
};

export default Breadcrumb;

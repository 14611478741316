import React from "react";
import SelectArrowIcon from "../VectorIcons/SelectArrowIcon";

const DropdownIndicator = ({ innerRef, innerProps, isFocused, ...props }) => {
  const hasValue = props.hasValue;
  const isLoading = props.selectProps.isLoading;

  return (
    <div
      className={`dropdownIndicator ${isFocused ? "isFocused" : ""} ${
        isLoading ? "isLoading" : ""
      } ${!!hasValue ? "hasValue" : ""}`}
      ref={innerRef}
      {...innerProps}
    >
      <SelectArrowIcon />
    </div>
  );
};

export default DropdownIndicator;

import React, { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import TypeIconReturner from "../../../components/TypeIconReturner/TypeIconReturner";
import { Link } from "react-router-dom";
import AttachmentsDropdown from "../../../components/AttachmentsDropdown/AttachmentsDropdown";
import Avatar from "../../../components/Avatar/Avatar";
import StatusColorBall from "../StatusColorBall/StatusColorBall";
import ConfigIcon from "../../../components/VectorIcons/ConfigIcon";
import DragAreaIcon from "../../../components/VectorIcons/DragAreaIcon";
import AppContext from "../../../context/appContext";
import ActivityContext from "../../../context/activitiesContext";

const ActivitiesUsers = (props) => {
  const {
    activity,
    index,
    handleEditActivity,
    name,
    bgColor,
    verifyFormat,
    getItemStyle,
  } = props;

  const appContext = useContext(AppContext);
  const activityContext = useContext(ActivityContext);

  const authorsOrCreators = {
    "Obra Literária": "authors",
    Dramaturgia: "creators",
    Variedades: "creators",
  };

  return (
    <Draggable
      key={`item_${activity.id}`}
      draggableId={`item_${activity.id}_${name}`}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          className="activityItem"
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <div className="activityCell activityTitle">
            <TypeIconReturner
              type={activity.contentType}
              responsiveIcon={true}
            />

            <div className="containerText">
              {activity.contentType === "Pesquisa" && (
                <span onClick={() => handleEditActivity(activity)}>
                  {activity.contentTitle}
                </span>
              )}

              {activity.contentType !== "Pesquisa" && (
                <Link
                  to={`/atividades/${
                    activityContext.activitiesContentTypeNames[
                      activity.contentType
                    ]
                  }/${activityContext.remakeTypeEvaluations(
                    activity.type.value
                  )}/${activity.evaluationId}`}
                >
                  <span>{activity.contentTitle}</span>
                </Link>
              )}

              {activity.contentType !== "Pesquisa" && (
                <div className="Author">
                  {!!activity[authorsOrCreators[activity.contentType]]?.length &&
                    activity[authorsOrCreators[activity.contentType]]?.map(
                      (creator, creatorIndex) => {
                        return (
                          <div className="creator" key={creator}>
                            <span>{creator}</span>
                            {creatorIndex + 1 <
                              activity[authorsOrCreators[activity.contentType]]
                                .length && <span className="separator">|</span>}
                          </div>
                        );
                      }
                    )}
                </div>
              )}
            </div>
          </div>

          <div className="activityCell activityAttachments">
            <span className="activityTypeName">{activity.type.value}</span>

            {!!activity.attachments.length && (
              <div className="attachmentsInfo">
                {verifyFormat(activity) && (
                  <>
                    <span className="file">
                      Cap {activity.attachments[0].chapterNumber}
                    </span>
                    <span className="fileBlock">
                      Bloco {activity.attachments[0].blockNumber}
                    </span>
                    <span className="fileVersion">
                      {activity.attachments[0].version}º Versão
                    </span>
                  </>
                )}

                {!verifyFormat(activity) && (
                  <>
                    <span className="file">
                      {!!activity.attachments[0].attachmentType &&
                        activity.attachments[0].attachmentType.name}
                    </span>
                    <span className="fileVersion">{` - ${activity.attachments[0].version}º Versão`}</span>
                  </>
                )}

                {activity.attachments.length > 1 && (
                  <AttachmentsDropdown attachments={activity.attachments} />
                )}
              </div>
            )}
          </div>

          <div className="activityCell activityAnalist">
            {!!activity.user ? (
              <>
                <Avatar
                  bgColor={bgColor}
                  letter={activity.user.name.slice(0, 1)}
                />
                <span>{activity.user.name}</span>
              </>
            ) : (
              `-`
            )}
          </div>

          <div className="activityCell activityStatus">
            <StatusColorBall status={activity.status.value} />
            {activity.status.value}
          </div>

          <div className="activityCell activityCreatedAt">
            {appContext.formatDate(
              appContext.convertDatePtBr(activity.receivedAt)
            )}
          </div>

          <div className="activityCell activityConfig">
            <div
              className="configActivity"
              onClick={() => handleEditActivity(activity)}
            >
              <ConfigIcon />
            </div>
            {!!activity.user && (
              <div className="dragIcon" {...provided.dragHandleProps}>
                <DragAreaIcon />
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default ActivitiesUsers;

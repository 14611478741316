import React, { useContext, useState } from "react";
import "./styles/UpdateThesaurus.scss";
import ScriptBox from "../../../components/ScriptBox/ScriptBox";
import { getService, putService } from "../../../services/script-api";
import AppContext from "../../../context/appContext";
import Fieldset from "../../../components/Fieldset/Fieldset";
import Textarea from "../../../components/Textarea/Textarea";
import CreatableFieldset from "../../../components/CreatableFieldset/CreatableFieldset";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import Button from "../../../components/Button/Button";
import EditIcon from "../../../components/VectorIcons/EditIcon";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import AsyncSelect from "react-select/async";
import AsyncLoading from "../../../components/AsyncLoading/AsyncLoading";
import ThesaurusAlert from "../ThesaurusAlert/ThesaurusAlert";
import { sendEvent } from "../../../services/GoogleAnalytics/methods";

const UpdateThesaurus = (props) => {
  const {
    thesaurus,
    hasIntegration,
    currentOrigin,
    currentType,
    allowedToHaveParentTerm,
    reloadThesaurusList,
  } = props;

  const [currentThesaurus, setCurrentThesaurus] = useState({
    ...thesaurus,
    parent: !!thesaurus.parent
      ? { label: thesaurus.parent.value, value: thesaurus.parent.id }
      : null,
    parentThesaurusId: !!thesaurus.parent ? thesaurus.parent.id : null,
    forceSave: false,
  });
  const [stateRegisterError, setStateRegisterError] = useState("");

  const appContext = useContext(AppContext);

  const handleChangeTextThesaurus = (ev) => {
    setCurrentThesaurus({
      ...currentThesaurus,
      description: ev.target.value,
    });
  };

  const handleChangeNameThesaurus = (ev) => {
    setCurrentThesaurus({
      ...currentThesaurus,
      [ev.target.name]: ev.target.value,
    });
  };

  const filterRemissives = (remissives) => {
    let a = [];
    if (!!remissives && !!remissives.length) {
      remissives.forEach((e) => a.push(e.label));
    }
    return a;
  };

  const handleChangeRemissives = (newValue) => {
    setCurrentThesaurus({
      ...currentThesaurus,
      remissives: filterRemissives(newValue),
    });
  };

  const loadOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length > 0) {
      let url = `${currentType}/thesaurus?Term=${inputValue}&origins=${currentOrigin.originId}`;
      let list = [];
      getService(url, { term: inputValue }).then((e) => {
        e.data.map((item) =>
          list.push({
            value: item.id,
            label: item.name,
            name: item.name,
            id: item.id,
            originId: item.origin,
          })
        );
        callback(list);
      });
    }
  };

  const handleChangeAssociated = (item) => {
    if (!!item) {
      getService(`thesaurus/${item.id}`).then((res) => {
        setCurrentThesaurus({
          ...currentThesaurus,
          parentInfo: res.data,
          parentThesaurusId: !!item ? item.id : null,
        });
      });
    } else {
      setCurrentThesaurus({
        ...currentThesaurus,
        parentInfo: "",
      });
    }
  };

  const mountObjectSelect = (obj) => {
    let a = [];
    !!obj &&
      obj.forEach((e) => {
        a.push({
          label: e,
          value: e,
        });
      });

    return a;
  };

  const compileErrorValues = (errors) => {
    const errorFields = {
      termFieldValues: [],
      remissivesFieldValues: [],
    };

    const remissives = currentThesaurus.remissives.map((item) =>
      item.toLowerCase()
    );

    errors.forEach((error) => {
      if (error.Name.toLowerCase() === currentThesaurus.name.toLowerCase()) {
        errorFields.termFieldValues.push(error);
      }
      if (remissives.includes(error.Name.toLowerCase())) {
        errorFields.remissivesFieldValues.push(error);
      }
    });

    setStateRegisterError(errorFields);
    setCurrentThesaurus({ ...currentThesaurus, forceSave: true });
  };

  const putThesaurus = (e) => {
    e.preventDefault();

    const { id, name, description, parentThesaurusId, remissives, forceSave } =
      currentThesaurus;
    const putObj = {
      name,
      description,
      parentThesaurusId,
      remissives,
      forceSave,
    };

    if (!!name) {
      appContext.setShowGlassLoading(true);
      putService(`thesaurus/${id}`, putObj)
        .then((e) => {
          appContext.setShowGlassLoading(false);
          appContext.setCurrentStepBox("");
          sendEvent("Cadastro", "Edição: Thesaurus Admin");
          appContext.sendMessage("Thesaurus editado com sucesso!", "success");
          reloadThesaurusList(currentOrigin.originId, currentOrigin.title);
        })
        .catch((e) => {
          appContext.setShowGlassLoading(false);
          console.log(e.response.data.Validations);
          compileErrorValues(e.response.data.Validations[0].Data);
          console.log("error: ", e.response.data.Validations[0].Data);
        });
    }
  };

  return (
    <div id="UpdateThesaurus">
      <ScriptBox
        open={appContext.current.step === "detailsThesaurus"}
        title={`Editar ${currentOrigin.title}`}
        icon={<EditIcon color="#FFF" />}
        marginBottom={true}
      >
        <form
          className="contentFormEditThesaurus"
          onSubmit={(ev) => putThesaurus(ev)}
        >
          <Fieldset
            autoComplete="off"
            name="name"
            label={currentOrigin.title}
            type="text"
            placeholder="Digite o termo"
            handleChange={!hasIntegration && handleChangeNameThesaurus}
            disabled={hasIntegration}
            value={currentThesaurus?.name}
            isAlert={!!stateRegisterError?.termFieldValues?.length}
          />

          <div className="thesaurusAlertsContainer">
            {!!stateRegisterError?.termFieldValues?.length &&
              stateRegisterError.termFieldValues.map((item, index) => {
                return (
                  <ThesaurusAlert
                    key={index}
                    termName={item.Name}
                    thesaurusName={currentOrigin.title}
                    isRemissive={item.IsRemissive}
                    tree={item.Parent}
                  />
                );
              })}
          </div>

          <div className="formItem">
            <Textarea
              maxRows={16}
              label="Nota de escopo"
              placeholder="Digite a definição do termo"
              handleChange={handleChangeTextThesaurus}
              value={currentThesaurus?.description}
            />
          </div>

          <CreatableFieldset
            label="Remissiva(s)"
            createMessage="Adicionar a opção:"
            placeholder="Digite um termo e aperte ENTER"
            handleChange={handleChangeRemissives}
            renderCreatedAt={true}
            values={mountObjectSelect(currentThesaurus?.remissives)}
            classNames={`${
              !!stateRegisterError?.remissivesFieldValues?.length
                ? "isAlert"
                : ""
            }`}
          />

          <div className="thesaurusAlertsContainer">
            {!!stateRegisterError?.remissivesFieldValues?.length &&
              stateRegisterError.remissivesFieldValues.map((item, index) => {
                return (
                  <ThesaurusAlert
                    key={index}
                    termName={item.Name}
                    thesaurusName={currentOrigin.title}
                    isRemissive={item.IsRemissive}
                    tree={item.Parent}
                  />
                );
              })}
          </div>

          {!hasIntegration &&
            allowedToHaveParentTerm.includes(currentOrigin.title) && (
              <div className="formItem">
                <label>Termo pai</label>

                <AsyncSelect
                  isClearable
                  className="reactSelect"
                  classNamePrefix="reactSelect"
                  loadOptions={loadOptions}
                  onChange={handleChangeAssociated}
                  defaultValue={currentThesaurus.parent}
                  components={{
                    LoadingIndicator: () => <AsyncLoading />,
                  }}
                  noOptionsMessage={() => "Nenhum item encontrado"}
                  loadingMessage={() => "Carregando..."}
                  placeholder="Selecione"
                  openMenuOnClick={false}
                />
              </div>
            )}

          <BoxFooter>
            <SimpleButton
              text="Cancelar"
              classButton="buttonCancel large"
              dataQA=""
              clickEvent={() => appContext.setCurrentStepBox("closed")}
            />

            {!stateRegisterError ? (
              <Button
                text={`Salvar ${currentOrigin.title}`}
                toValidate={true}
                isValidForm={!!currentThesaurus?.name}
                classButton="buttonGreen large"
                typeButton="submit"
                dataQA=""
              />
            ) : (
              <Button
                text="Salvar Mesmo Assim"
                toValidate={true}
                isValidForm={!!currentThesaurus.name}
                classButton="buttonRed large"
                typeButton="submit"
                dataQA=""
              />
            )}
          </BoxFooter>
        </form>
      </ScriptBox>
    </div>
  );
};

export default UpdateThesaurus;

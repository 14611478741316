import React, { useContext, useEffect, useState } from "react";
import "./style/TableListActivity.scss";
import HeaderTable from "./HeaderTable/HeaderTable";
import TypeIconReturner from "../../../components/TypeIconReturner/TypeIconReturner";
import ConfigIcon from "../../../components/VectorIcons/ConfigIcon";
import DeleteIcon from "../../../components/VectorIcons/DeleteIcon";
import AppContext from "../../../context/appContext";
import _ from "lodash";
import FeedbackIcon from "../../../components/VectorIcons/FeedbackIcon";
import FeedbackIconDone from "../../../components/VectorIcons/FeedbackIconDone";
import { deleteService } from "../../../services/script-api";
import ConfirmationBox from "../../../components/ConfirmationBox/ConfirmationBox";
import AttachmentsDropdown from "../../../components/AttachmentsDropdown/AttachmentsDropdown";

const TableListActivity = (props) => {
  const {
    activities,
    filterAnalysts,
    reorderList,
    order,
    direction,
    setStateCurrentActivity,
    currentType,
    getActivities,
  } = props;

  const [state, setState] = useState({ loading: true, list: [] });

  const toSeparatorUser = () => {
    const users = [];
    let group = _.groupBy(activities, (u) => u.user?.id);
    Object.entries(group).forEach((e) => users.push(e[1][0].user));

    setState({
      loading: false,
      list: makeActiveUsers(users, true),
    });
  };

  const makeActiveUsers = (users, bool) => {
    let a = [];
    users.forEach((u) => {
      if (!!u) {
        u.isActive = bool;
      }
      a.push(u);
    });
    return a;
  };

  const getAnalyst = (nameAnalyst) => filterAnalysts(nameAnalyst);

  useEffect(() => {
    toSeparatorUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="TableListActivity">
      <div className="container">
        <ul className="containerListActivity">
          {!state.loading && (
            <HeaderTable
              order={order}
              direction={direction}
              reorderList={reorderList}
              activities={activities}
              users={state.list}
              getAnalyst={getAnalyst}
            />
          )}
        </ul>

        <ul className="containerListActivity">
          {activities.map((activity) => {
            return (
              <ItemList
                key={activity.id}
                getActivities={getActivities}
                setStateCurrentActivity={setStateCurrentActivity}
                activity={activity}
                currentType={currentType}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const ItemList = (props) => {
  const appContext = useContext(AppContext);

  const { activity, setStateCurrentActivity, currentType, getActivities } =
    props;

  const [stateOpenDeleteConfirmation, setStateOpenDeleteConfirmation] =
    useState({ open: false, id: "" });

  const handleOpenConfirmationBox = (id) => {
    setStateOpenDeleteConfirmation({
      ...stateOpenDeleteConfirmation,
      id: id,
      open: true,
    });
  };

  const deleteActivity = () => {
    appContext.setShowGlassLoading(true);
    deleteService("activities", stateOpenDeleteConfirmation.id)
      .then(() => {
        appContext.sendMessage("Atividade excluída com sucesso.");
        appContext.setShowGlassLoading(false);
        getActivities();
      })
      .catch(() => {
        appContext.sendMessage("Erro ao excluir a atividade!", "error");
        appContext.setShowGlassLoading(false);
      });
  };

  const closeConfirmationBox = () => {
    setStateOpenDeleteConfirmation({
      ...stateOpenDeleteConfirmation,
      open: false,
      id: "",
    });
  };

  const editActivity = (editActivityData) => {
    appContext.setCurrentStepBox("editProjectActivityList");
    setStateCurrentActivity(editActivityData);
  };

  const addFeedback = (activityData) => {
    appContext.setCurrentStepBox("editProjectFeedbackList");
    setStateCurrentActivity(activityData);
  };

  const verifyFormat = (item) =>
    !!item.attachments[0].attachmentType &&
    item.attachments[0].attachmentType.name === "Capítulo/Episódio";

  const authorsOrCreators = {
    "Obra Literária": "authors",
    Dramaturgia: "creators",
    Variedades: "creators",
  };

  return (
    <div className="itemList">
      <li className="title">
        <div className="containerTitle">
          <TypeIconReturner
            type={activity.contentType}
            showBackgroundColor={false}
            responsiveIcon={true}
          />

          <div className="containerText">
            <div className="textTitle"> {activity.contentTitle}</div>

            <div className="Author">
              {!!activity[authorsOrCreators[activity.contentType]].length &&
                activity[authorsOrCreators[activity.contentType]].map(
                  (creator, index) => {
                    return (
                      <div className="creator" key={index}>
                        <span>{creator}</span>
                        {index + 1 <
                          activity[authorsOrCreators[activity.contentType]]
                            .length && <span className="separator">|</span>}
                      </div>
                    );
                  }
                )}
            </div>

            <div className="typeActivity">{activity.format}</div>
          </div>
        </div>
      </li>

      <li className="attachments">
        <span className="activityTypeName">{activity.type.value}</span>

        {!!activity.attachments.length && (
          <div className="attachmentsInfo">
            {verifyFormat(activity) && (
              <>
                <span className="file">
                  Cap {activity.attachments[0].chapterNumber}
                </span>
                <span className="fileBlock">
                  Bloco {activity.attachments[0].blockNumber}
                </span>
                <span className="fileVersion">
                  {activity.attachments[0].version}º Versão
                </span>
              </>
            )}

            {!verifyFormat(activity) && (
              <>
                <span className="file">
                  {!!activity.attachments[0].attachmentType &&
                    activity.attachments[0].attachmentType.name}
                </span>
                <span className="fileVersion">{` - ${activity.attachments[0].version}º Versão`}</span>
              </>
            )}

            {activity.attachments.length > 1 && (
              <AttachmentsDropdown attachments={activity.attachments} />
            )}
          </div>
        )}
      </li>

      <li className="analyst">
        <div>
          {!!activity.user ? (
            <>
              <div
                style={{
                  background: appContext.generateColorFromName(
                    activity.user.name
                  ),
                }}
                className="icon"
              >
                {activity.user.name.slice(0, 1)}
              </div>
              {activity.user.name}
            </>
          ) : (
            <div />
          )}
        </div>
      </li>

      <li className="canceledOrDone">
        {!!activity.statusDate
          ? appContext.formatDate(
              appContext.convertDatePtBr(activity.statusDate)
            )
          : "-"}
      </li>

      <li className="info">
        <div>
          <div className="configIcon" onClick={() => editActivity(activity)}>
            <ConfigIcon />
          </div>

          {activity.status.id !== 6 && (
            <div
              className="deleteIcon"
              onClick={() => handleOpenConfirmationBox(activity.id)}
            >
              <DeleteIcon color="#000" />
            </div>
          )}

          {activity.status.id === 6 &&
            currentType !== "pesquisas" &&
            activity.contentType !== "Obra Literária" && (
              <div
                className="feedbackIcon"
                onClick={() => addFeedback(activity)}
              >
                {activity.feedback ? (
                  <FeedbackIconDone size={28} />
                ) : (
                  <div className="newFeedback">
                    <FeedbackIcon size={38} />
                  </div>
                )}
              </div>
            )}
        </div>
      </li>

      {stateOpenDeleteConfirmation.open && (
        <ConfirmationBox
          open={stateOpenDeleteConfirmation.open}
          textButton="Excluir Atividade"
          closedBox={closeConfirmationBox}
          handleEvent={() => deleteActivity()}
          title="Deseja excluir esta atividade?"
          text={`Ao confirmar, a atividade será deletada e não poderá ser recuperada.`}
        />
      )}
    </div>
  );
};

export default TableListActivity;

import React from "react";

const SheetIcon = (props) => {
  const { color = "#0098CD" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="23"
      viewBox="0 0 19 23"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={color}
          fillRule="nonzero"
          d="M11 0c.265 0 .52.105.707.293l6 6c.188.187.293.442.293.707v12c0 1.657-1.343 3-3 3h-4.566l2-2H15c.513 0 .936-.386.993-.883L16 19V7.415L10.585 2H3c-.552 0-1 .448-1 1v6.22l-2 2.001V3C0 1.402 1.249.096 2.824.005L3 0z"
          transform="translate(1)"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 16L13 16"
          transform="translate(1)"
        />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M11 1L11 7 17 7"
          transform="translate(1)"
        />
        <g
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(1) matrix(-1 0 0 1 12 10)"
        >
          <circle cx="5" cy="5" r="5" />
          <path d="M12 12L9 9" />
        </g>
      </g>
    </svg>
  );
};

export default SheetIcon;

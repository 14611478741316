import React from "react";
import "./styles/SimpleButton.scss";

const SimpleButton = (props) => {
  const {
    classButton,
    children,
    text,
    clickEvent,
    rippleEffect,
    isValidForm,
    toValidate,
    onBreadcrumb,
    dataQA,
    iconSide = "left",
    buttonColor,
  } = props;
  const typeClass = `${classButton} button ${rippleEffect && "rippleButton"}`;
  const isActive = `${toValidate ? (isValidForm ? "active" : "disabled") : ""}`;

  const _default = () => {
    return "default";
  };

  return (
    <div
      style={{ backgroundColor: buttonColor }}
      className={`${typeClass} ${isActive} simpleButton`}
      onClick={!!clickEvent ? clickEvent : () => _default()}
      data-qa={`${dataQA}`}
    >
      {iconSide === "left" ? (
        <>
          {children}
          <span className={`${onBreadcrumb && "hideOnBreadcrumb"}`}>
            {text}
          </span>
        </>
      ) : (
        <>
          <span className={`${onBreadcrumb && "hideOnBreadcrumb"}`}>
            {text}
          </span>
          {children}
        </>
      )}
    </div>
  );
};

export default SimpleButton;

import React from "react";
import "./styles/AddAttachments.scss";
import ListCount from "../../../../components/ListCount/ListCount";
import SimpleButton from "../../../../components/SimpleButton/SimpleButton";
import ListAttachments from '../../../../components/ListAttachments/ListAttachments'

const AddAttachments = (props) => {
  const {
    files,
    toggleActiveCheckBox,
    toggleActiveVersionCheckBox,
    clearActive,
    closedModal,
  } = props;

  const returnTotalAttachments = () => {
    const attachments = [];

    files.forEach((item) => {
      attachments.push(item.parent);
      item.version.forEach((item) => {
        attachments.push(item);
      });
    });

    return attachments.length;
  };

  const returnTotalSelecteds = () => {
    const selecteds = [];

    files.forEach((item) => {
      !!item.parent.isActive && selecteds.push(item.parent);
      item.version.forEach((item) => {
        !!item.isActive && selecteds.push(item);
      });
    });

    return selecteds.length;
  };

  const FooterMessage = () => {
    let message = "";
    const totalSelecteds = returnTotalSelecteds();

    if (!!totalSelecteds) {
      message = `${totalSelecteds} ${
        totalSelecteds > 1 ? "anexos selecionados" : "anexo selecionado"
      }`;
    } else {
      message = "É preciso selecionar pelo menos um anexo";
    }

    return <span>{message}</span>;
  };

  const confirmIsValid = () => !!returnTotalSelecteds() > 0;

  return (
    <div className="addAttachmentsModal">
      <ListCount total={returnTotalAttachments()} text="anexos encontrados" />

      <ListAttachments
        files={files}
        toggleActiveCheckBox={toggleActiveCheckBox}
        toggleActiveVersionCheckBox={toggleActiveVersionCheckBox}
      />

      <div className="footer">
        <div className="unmarkContainer">
          <div className="unmarkButton" onClick={() => clearActive()}>
            <div />
          </div>
          <FooterMessage />
        </div>

        <SimpleButton
          text="Confirmar"
          classButton="buttonBlue"
          dataQA="button-prospect-edit"
          isValidForm={confirmIsValid()}
          toValidate={true}
          clickEvent={() => closedModal()}
        />
      </div>
    </div>
  );
};

export default AddAttachments;

import React from "react";
import "./styles/StepCounter.scss";
import StepCounterIcon from "../VectorIcons/StepCounterIcon";

const StepCounter = (props) => {
  const { currentStep, maxStep } = props;

  return (
    <div className="stepCounter">
      <div className="stepIcon">
        <StepCounterIcon step={currentStep} maxStep={maxStep} />
      </div>
      <p>
        {currentStep} de {maxStep}
      </p>
    </div>
  );
};

export default StepCounter;

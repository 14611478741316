import React from "react";
import "./styles/TableChapterItemDates.scss";

const TableChapterItemDates = (props) => {
  const {
    scheduleDelivery,
    rescheduledDelivery,
    lastAttachmentReceiveDate,
    scheduleRelease,
    rescheduledRelease,
    lastAttachmentReleaseDate,
    type,
  } = props;

  return type === "projectSheet" ? (
    <>
      <div className="cell dates">
        <span className="date">{scheduleDelivery}</span>
        <span className="date">{rescheduledDelivery}</span>
        <span className="date">{lastAttachmentReceiveDate}</span>
      </div>

      <div className="cell dates">
        <span className="date">{scheduleRelease}</span>
        <span className="date">{rescheduledRelease}</span>
        <span className="date">{lastAttachmentReleaseDate}</span>
      </div>
    </>
  ) : (
    <>
      <div className="cell datesText">
        <span className="date ">
          {typeof rescheduledRelease === "string"
            ? rescheduledRelease
            : scheduleRelease}
        </span>
      </div>
      <div className="cell datesText">
        <span className="date">{lastAttachmentReleaseDate}</span>
      </div>
    </>
  );
};

export default TableChapterItemDates;

import React from "react";
import "./styles/CarouselCard.scss";
import { Link } from "react-router-dom";

const CarouselCard = (props) => {
  const { data, type, currentId } = props;

  return (
    <Link
      to={`/ficha-projeto/${type}/${data.id}`}
      className="carouselCardContainer"
    >
      <div
        className={`carouselCard ${
          data.id === currentId ? "currentSeason" : ""
        }`}
      >
        <span className="cardCarouselNumber">{data.seasonNumber}</span>
        <span className="cardCarouselTitle">{data.title}</span>
      </div>
    </Link>
  );
};

export default CarouselCard;

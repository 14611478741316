import React, { useEffect, useState, useContext } from "react";
import { getService } from "../../../services/script-api";
import SimpleScriptBox from "../../ProjectSheet/SimpleScriptBox/SimpleScriptBox";
import AddAttachments from "../NewActivity/AddAttachments/AddAttachments";
import AttachmentIcon from "../../../components/VectorIcons/AttachmentIcon";
import EditModalActivity from "./EditActivity/EditModalActivity";
import AttachmentsContext from "../../../context/attachmentsContext";

const makeOlderVersions = (file, _files) => {
  if (!!file.olderVersions?.length) {
    file.olderVersions.forEach((f) => {
      f.isActive = false;
      _files.push(f);
    });
  }
};

const makeAttachment = (file, _files) => {
  if (file.attachment) {
    file.attachment.isActive = false;
    _files.push(file.attachment);
  }
};

const returnInfo = (activity) => {
  return {
    title: activity.data.contentTitle,
    creators: activity.data.creators,
    format: activity.data.format,
    category: !!activity.data.subCategory
      ? activity.data.subCategory.value
      : "",
    subCategory: !!activity.data.subCategory
      ? activity.data.subCategory.category.value
      : "",
    description: activity.data.description,
  };
};

const EditActivity = (props) => {
  const { currentActivity, type, _getActvity, open } = props;

  const attachmentContext = useContext(AttachmentsContext);

  const [stateProjectAttachment, setStateProjectAttachment] = useState({
    loading: true,
    files: [],
  });

  const [stateActivityAttachment, setStateActivityAttachment] = useState({
    loading: true,
    files: [],
  });

  const [stateFiles, setStateFiles] = useState([]);
  const [stateSelectFiles, setStateSelectFiles] = useState(false);
  const [stateContentInfo, setStateContentInfo] = useState({
    loading: true,
    info: {},
  });

  const toSeparateVersion = (files) => {
    let obj = [];

    files.forEach((file) => {
      obj.push({ parent: file[0], version: file.filter((e) => e !== file[0]) });
    });
    return obj;
  };

  const chaptersOrEpisodes = () => {
    const types = {
      Dramaturgia: "Capítulo/Episódio",
      Variedades: "Episódio",
    };
    return types[type];
  };

  const compileFiles = (data) => {
    let allGroup = attachmentContext.compileAll(data, chaptersOrEpisodes());
    let chapterCompiled = attachmentContext.compiledChapter(
      data,
      type,
      chaptersOrEpisodes()
    );
    let materialCompiled = attachmentContext.compileMaterial(
      data,
      "Material Complementar"
    );

    let mergeGroups = { ...allGroup, ...chapterCompiled, ...materialCompiled };
    setStateFiles(
      toSeparateVersion(Object.entries(mergeGroups).map((files) => files[1]))
    );
  };

  const getAttachmentsProjects = () => {
    getService(`contents/${currentActivity.projectId}/attachments`).then(
      (activity) => {
        setStateProjectAttachment({
          ...stateProjectAttachment,
          loading: false,
          files: activity.data.attachments,
        });
      }
    );
  };

  const getAttachmentsActivity = () => {
    getService(`activities/${currentActivity.id}`).then((activity) => {
      setStateActivityAttachment({
        ...stateActivityAttachment,
        loading: false,
        files: activity.data.attachments,
      });
      setStateContentInfo({
        loading: false,
        info: returnInfo(activity),
      });
    });
  };

  const setActiveAttachments = (files) => {
    files.forEach((file) => {
      stateActivityAttachment.files.forEach((item) => {
        if (item.fileId === file.fileId) {
          file.isActive = true;
        }
      });
    });

    compileFiles(files);
  };

  let _files = [];

  const unifyFiles = () => {
    stateProjectAttachment.files.forEach((file, index) => {
      makeOlderVersions(file, _files);
      makeAttachment(file, _files);
    });

    setActiveAttachments(_files);
  };

  const toggleActiveCheckBox = (file, index) => {
    let newState = [...stateFiles];
    newState[index].parent.isActive = !newState[index].parent.isActive;
    setStateFiles(newState);
  };

  const toggleActiveVersionCheckBox = (item, index, versionIndex) => {
    let newState = [...stateFiles];
    const indexItem = newState[index].version.indexOf(item);

    if (indexItem === -1) {
      newState[index].version[versionIndex].isActive =
        !newState[index].version[versionIndex].isActive;
    } else {
      newState[index].version[indexItem].isActive =
        !newState[index].version[indexItem].isActive;
    }
    setStateFiles(newState);
  };

  const clearActive = () => {
    let newState = [...stateFiles];
    newState.forEach((file) => {
      file.parent.isActive = false;
      file.version.forEach((v) => (v.isActive = false));
    });

    setStateFiles(newState);
  };

  useEffect(() => {
    if (type !== "Pesquisa") {
      getAttachmentsProjects();
    }
    getAttachmentsActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!stateProjectAttachment.loading && !stateActivityAttachment.loading) {
      // makeActiveAttachments()
      unifyFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProjectAttachment, stateActivityAttachment]);

  return (
    <div>
      <EditModalActivity
        files={stateFiles}
        filesAttachment={stateActivityAttachment.files}
        toggleActiveCheckBox={toggleActiveCheckBox}
        toggleActiveVersionCheckBox={toggleActiveVersionCheckBox}
        listAttachments={() => setStateSelectFiles(true)}
        clearActive={clearActive}
        type={type}
        _getActvity={_getActvity}
        currentActivity={currentActivity}
        open={open}
        loading={
          !!stateActivityAttachment.loading && !!stateProjectAttachment.loading
        }
        contentInfo={stateContentInfo}
      />

      {stateSelectFiles && (
        <SimpleScriptBox
          open={stateSelectFiles}
          title="Selecionar anexos"
          icon={<AttachmentIcon />}
          removePadding={true}
          resizeHeader={true}
          toggleScriptBox={() => setStateSelectFiles(false)}
        >
          <AddAttachments
            files={stateFiles}
            toggleActiveCheckBox={toggleActiveCheckBox}
            toggleActiveVersionCheckBox={toggleActiveVersionCheckBox}
            clearActive={clearActive}
            closedModal={() => setStateSelectFiles(false)}
          />
        </SimpleScriptBox>
      )}
    </div>
  );
};

export default EditActivity;

import React from 'react'
import TypeIconReturner from '../TypeIconReturner/TypeIconReturner'
import "./styles/HeaderWithIconType.scss"

const HeaderWithIconType = (props) => {

  const { type, title } = props

  return (
    <div id="HeaderWithIconType">
      <TypeIconReturner
            type={type || 'obra-literaria'}
            showBackgroundColor={true}
            responsiveIcon={true}
          />

          <div className="projectResume">
            <h2 className="title">{title}</h2>
          </div>
    </div>
  )
}

export default HeaderWithIconType
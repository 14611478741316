import React from "react";
import "./styles/Option.scss";

const Option = ({ children, innerRef, innerProps, isFocused, ...props }) => {
  const { label } = props.data;

  return (
    <div
      className={`option ${isFocused ? "isFocused" : ""}`}
      ref={innerRef}
      {...innerProps}
    >
      <div className="productionInfo">
        <div className="label">{label}</div>
      </div>
    </div>
  );
};

export default Option;

import React from "react";
import "./styles/ScopeInformation.scss";
import SimpleButton from "../../SimpleButton/SimpleButton";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";

const ScopeInformation = (props) => {
  const {
    currentScope,
    searchInput,
    alreadyUsed,
    addThesaurus,
    remissiveStyles,
    showBreadcrumb,
    handleGoBack,
  } = props;

  return (
    <div
      className={`scopeInformation ${showBreadcrumb ? "showBreadcrumb" : ""}`}
    >
      <span className="scopeName">{currentScope.name}</span>

      <p className="scopeDescription">
        {!!currentScope.description
          ? currentScope.description
          : "Termo sem nota de escopo cadastrada."}
      </p>

      {!!currentScope.remissives.length && (
        <div className="scopeRemissives">
          <span className="remissivesTitle">Remissiva(s)</span>

          <ul>
            {currentScope.remissives.map((remissive, index) => (
              <li
                key={index}
                className={`${!!searchInput && remissiveStyles(remissive)}`}
              >
                {remissive}
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="scopeButtons">
        <SimpleButton
          text="Voltar"
          classButton="buttonCancel large"
          clickEvent={() => handleGoBack()}
        />

        <div className="selectScopeButton">
          {!!alreadyUsed() && (
            <CustomTooltip
              dataTip={`O termo ${currentScope.name} já foi selecionado.`}
              tipLabel={<div className="tip" />}
            />
          )}

          <SimpleButton
            text="Selecionar"
            classButton="buttonGreen large"
            toValidate={true}
            isValidForm={!alreadyUsed()}
            clickEvent={() => addThesaurus()}
          />
        </div>
      </div>
    </div>
  );
};

export default ScopeInformation;

import React from 'react';

const TagsProject = (props) => {

  const { tags } = props

  return (
    <div className="TagsProject">
      {!!tags && tags.map( (tag, i) => <span key={i}>{tag.name}{i === tags.length - 1 ? "" : ", "}</span>)}
    </div>
  );
};

export default TagsProject;
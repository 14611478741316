import React, { createContext, useEffect, useReducer } from "react";
import userReducer from "./userReducer";
import { current,getAccessToken, getIdToken, getRefreshToken } from "../shared/lib/login-store";
import _ from "lodash";
import { getService } from "../services/script-api";

const initialContext = {
  me: {
    loading: true,
    user: [],
  },
  id: null,
  filter: {},
  current: null,
  list: {
    loading: true,
    result: [],
    pagination: {},
  },
};

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialContext);

  const currentIsActive = () => !_.isNull(current());

  const getRoles = () => {
    getService("users/me").then((res) => {
      dispatch({ action: "setUser", key: "me", payload: res.data });
    });
  };

  const loadOrCreateUser = () => {
    return Promise.resolve();
  };

  const getFullName = (user) => {
    return !!currentIsActive()
      ? `${current().name || ""} ${current().lastName || ""}`
      : "Username";
  };

  const havePermission = (roles) =>
    _.difference(roles, state.me.user.sectionsAllowed);

  const monoPermission = (permission) => {
    const { sectionsAllowed } = state.me.user;
    return sectionsAllowed.length === 1 && sectionsAllowed[0] === permission;
  };

  const idToken = getIdToken();
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();


  useEffect(() => {
    if (idToken && accessToken && refreshToken)
      getRoles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider
      value={{
        ...state,
        loadOrCreateUser,
        currentIsActive,
        getFullName,
        havePermission,
        monoPermission,
        getRoles,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext as default, UserProvider };

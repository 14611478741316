import React from "react";
import "./styles/StatusSelect.scss";
import Select from "react-select";
import SelectArrowIcon from "../../../../components/VectorIcons/SelectArrowIcon";
import StatusColorBall from "../../StatusColorBall/StatusColorBall";

const Option = ({ children, innerRef, innerProps }) => {
  return (
    <div className="statusSelectOption" ref={innerRef} {...innerProps}>
      <StatusColorBall status={children} />

      <span className="optionText">{children}</span>
    </div>
  );
};

const SingleValue = ({ children, innerRef, innerProps }) => {
  return (
    <div className="statusSingleValue" ref={innerRef} {...innerProps}>
      <StatusColorBall status={children} />

      <span className="optionText">{children}</span>
    </div>
  );
};

const DropdownIndicator = ({ innerRef, innerProps, isFocused }) => {
  return (
    <div
      className={`dropdownIndicator ${isFocused ? "isFocused" : ""}`}
      ref={innerRef}
      {...innerProps}
    >
      <SelectArrowIcon />
    </div>
  );
};

const StatusSelect = (props) => {
  const { options, value, defaultValue, handleChange, placeholder } = props;

  return (
    <Select
      className="statusSelect"
      classNamePrefix="statusSelect"
      options={options}
      onChange={handleChange}
      value={value}
      defaultValue={defaultValue}
      components={{ Option, SingleValue, DropdownIndicator }}
      isSearchable={false}
      placeholder={placeholder}
    />
  );
};

export default StatusSelect;

import React, { useState, useContext } from "react";
import AppContext from "../../context/appContext";
import "./styles/MobileSearch.scss";
import SearchIcon from "../VectorIcons/SearchIcon";
import BackArrow from "../VectorIcons/BackArrow";
import Button from "../Button/Button";
import { Redirect } from "react-router-dom";
import ChannelContext from "../../context/externalProjectsContext";
import UserContext from "../../context/userContext";

const MobileSearch = () => {
  const appContext = useContext(AppContext);
  const channelContext = useContext(ChannelContext);
  const userContext = useContext(UserContext);

  const isChannelRole =
    userContext.havePermission(["ExternalProject"]).length === 0;
  const searchRole = isChannelRole ? "channels" : "script";

  const [state, setState] = useState({
    filter: "",
    redirect: false,
  });

  const scriptSearch = () => {
    appContext.setCurrentTypePath("contents");
    appContext.setCurrentTerm(state.filter);
    appContext.setFilterSearch({
      Term: state.filter,
      PageNumber: 1,
      Limit: 12,
    });
    appContext.setParameterized(false);
  };

  const channelsSearch = () => {
    channelContext.setCurrentTerm(state.filter);
    channelContext.setCurrentTypeSearch({
      filterType: "Todos",
      filterValue: { Limit: 12, term: state.filter },
    });
    appContext.setRedirectSearch(true);
  };

  const handleUserTypeSearch = (type = "script") => {
    const types = {
      script: scriptSearch,
      channels: channelsSearch,
    };
    return types[type]();
  };

  const handleRedirect = (type = "script") => {
    const types = {
      script: "/busca",
      channels: "/projetos-externos",
    };
    return types[type];
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUserTypeSearch(searchRole);
  };

  const handleChange = (ev) => {
    setState({
      ...state,
      filter: ev.target.value,
    });
  };

  const redirectUrl = handleRedirect(searchRole);

  return (
    appContext.showMobileSearch && (
      <div className="mobileSearch">
        <div
          className="backArrow"
          onClick={() => appContext.setShowMobileSearch(false)}
        >
          <BackArrow color="#17B4E1" />
        </div>

        <form onSubmit={(e) => handleSubmit(e)}>
          <input
            type="text"
            placeholder="Buscar"
            autoComplete="on"
            onChange={(e) => handleChange(e)}
            autoFocus
          />

          <Button typeButton="submit">
            <SearchIcon strokeCollor="#17B4E1" />
          </Button>
        </form>

        {state.redirect && <Redirect to={redirectUrl} />}
      </div>
    )
  );
};

export default MobileSearch;

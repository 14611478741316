import React, { useContext, useEffect, useState } from 'react'
import './styles/EditSheetBasic.scss'
import { getService, putService } from '../../../services/script-api'
import AppContext from '../../../context/appContext'
import SheetContext from '../../../context/sheetContext'
import BoxFooter from '../../../components/BoxFooter/BoxFooter'
import Button from '../../../components/Button/Button'
import SwitchText from '../../../components/SwitchText/SwitchText'
import RadioInput from '../../../components/RadioInput/RadioInput'
import Fieldset from '../../../components/Fieldset/Fieldset'
import _ from 'lodash'

const peoples = {
  1: 'fromAnalystIds',
  2: 'fromAreaIds',
  3: 'fromResearcherIds',
  4: 'toCreatorIds',
  5: 'toAreaIds',
}

const listsObj = {
  fromAnalystIds: [],
  fromAreaIds: [],
  fromResearcherIds: [],
  toCreatorIds: [],
  toAreaIds: [],
}

const EditSheetBasicResearch = (props) => {
  const { data, researchTypes } = props
  const { fromAnalysts, fromAreas, fromResearchers, toAreas, toCreators } = data

  const appContext = useContext(AppContext)
  const sheetContext = useContext(SheetContext)

  const returnType = () => researchTypes.filter((type) => type.id === data.type)[0]

  const compileValues = (value) => {
    const compiled = []
    value.forEach((item) => {
      !!item.value && compiled.push({ value: item.id, label: item.value })
    })
    return compiled
  }

  const researchedFromMock = [
    { id: 1, name: 'Analista(s)', values: compileValues(fromAnalysts) },
    { id: 2, name: 'Área(s)', values: compileValues(fromAreas) },
    { id: 3, name: 'Pesquisador(es)', values: compileValues(fromResearchers) },
  ]

  const researchToMock = [
    { id: 4, name: 'Criador(es)', values: compileValues(toCreators) },
    { id: 5, name: 'Área(s)', values: compileValues(toAreas) },
  ]

  const [stateResearchType, setStateResearchType] = useState(returnType())
  const [stateResearchTitle, setStateResearchTitle] = useState(data.title)
  const [stateError, setStateError] = useState({ title: false })
  const [stateResearchCategory, setStateResearchCategory] = useState(data.subCategory.category)
  const [stateResearchCategories, setStateResearchCategories] = useState([])
  const [stateResearchSubCategory, setStateResearchSubCategory] = useState(
    data.subCategory
  )
  const [stateResearchSubCategories, setStateResearchSubCategories] = useState(
    []
  )
  const [stateResearchFrom, setStateResearchFrom] = useState([])
  const [stateResearchTo, setStateResearchTo] = useState([])
  const [statePeople, setStatePeople] = useState([])

  useEffect(() => {
    const fromValues = []
    const toValues = []

    researchedFromMock.forEach((item) => {
      !!item.values.length &&
      fromValues.push({ type: item.id, peopleId: item.values })
    })

    researchToMock.forEach((item) => {
      !!item.values.length &&
      toValues.push({ type: item.id, peopleId: item.values })
    })

    const handleFromValues = !!fromValues.length
      ? fromValues
      : [
        {
          peopleId: 0,
          type: nextOptionSelected(stateResearchFrom, researchedFromMock),
        },
      ]

    const handleToValues = !!toValues.length
      ? toValues
      : [
        {
          peopleId: 0,
          type: nextOptionSelected(stateResearchTo, researchToMock),
        },
      ]

    setStateResearchFrom(handleFromValues)
    setStateResearchTo(handleToValues)
    // eslint-disable-next-line
  }, [data])

  const nextOptionSelected = (state, options) => {
    let currentUsedOptions = []
    state.forEach((item) => currentUsedOptions.push(item.type))

    let unusedOptions = options.filter((option) => {
      return currentUsedOptions.indexOf(option.id) === -1
    })

    if (!!unusedOptions.length > 0) {
      return unusedOptions[0].id
    }
  }

  const handleResearchType = (id, value) =>
    setStateResearchType({ id: id, value: value })

  const handleChangeResearchTitle = (ev) =>
    setStateResearchTitle(ev.target.value)

  const handleBlurTitle = () =>
    setStateError({ ...stateError, title: stateResearchTitle.trim() === '' })

  const handleResearchCategory = (id, value) => {
    setStateResearchCategory({ id: id, value: value })
    setStateResearchSubCategory('')
  }

  const handleResearchSubCategory = (id, value) => setStateResearchSubCategory({ id: id, value: value })

  const returnSubCategoryOptions = (categoryId) =>
    stateResearchSubCategories[categoryId - 1]

  const compilePeoples = (key) => {
    const compileToArray = []

    statePeople.forEach((item, index) => {
      !!item.peopleId.length &&
      item.peopleId.forEach((people) =>
        compileToArray.push({
          peopleId: people.value,
          type: statePeople[index].type,
        })
      )
    })

    compileToArray.forEach((item) => listsObj[peoples[item.type]].push(item.peopleId))

    return listsObj[key]
  }

  const editIsValid = () =>
    !Object.values(stateError).some((item) => !!item) && !!stateResearchSubCategory

  const isPeopleIds = (people) => !!compilePeoples(people).length ? compilePeoples(people) : []

  const putBasicSheet = (e) => {
    e.preventDefault()

    const putObject = {
      id: data.id,
      type: stateResearchType.id,
      subCategoryId: stateResearchSubCategory.id,
      origin: data.origin,
      fromAnalystIds: isPeopleIds('fromAnalystIds'),
      fromAreaIds: isPeopleIds('fromAreaIds'),
      fromResearcherIds: isPeopleIds('fromResearcherIds'),
      toCreatorIds: isPeopleIds('toCreatorIds'),
      toAreaIds: isPeopleIds('toAreaIds'),
      title: stateResearchTitle,
    }

    appContext.setShowGlassLoading(true)

    putService('researches', putObject)
      .then((res) => {
        appContext.setShowGlassLoading(false)
        appContext.setCurrentStepBox('')
        sheetContext._getProjectSheetResearch(props.id, 'researches')
        appContext.sendMessage(
          'Ficha de pesquisa editada com sucesso!',
          'success'
        )
      })
      .catch(() => {
        appContext.setShowGlassLoading(false)
        appContext.sendMessage('Erro ao editar a ficha de pesquisa.', 'error')
      })
  }

  const getCategoriesAndSubCategories = () => {
    getService('researches/data/subcategories').then((response) => {
      const grouped = Object.entries(
        _.groupBy(response.data, (u) => u.category.id)
      )

      let categories = []
      let subCategories = []

      grouped.forEach((group) => categories.push(group[1][0].category))
      grouped.forEach((group) => {
        subCategories.push(group[1])
      })

      setStateResearchCategories(categories)
      setStateResearchSubCategories(subCategories)
    })
  }

  useEffect(() => {
    getCategoriesAndSubCategories()
  }, [])

  useEffect(() => {
    const newState = [...stateResearchFrom, ...stateResearchTo]
    setStatePeople(newState)
    // eslint-disable-next-line
  }, [stateResearchFrom, stateResearchTo])

  return (
    <div id="EditSheetBasic">
      <form className="registerForm" onSubmit={(e) => putBasicSheet(e)}>
        <div className="formField types">
          <SwitchText
            label="Tipo"
            data={researchTypes}
            handleChange={handleResearchType}
            value={returnType().id}
            size="large"
          />
        </div>

        <div className="formField researchTitle">
          <Fieldset
            autoComplete="off"
            name="Title"
            label="Título"
            type="text"
            placeholder="Título da pesquisa"
            value={stateResearchTitle}
            handleChange={handleChangeResearchTitle}
            handleBlur={() => handleBlurTitle()}
            isError={stateError.title}
            msgError="Este campo é obrigatório"
          />
        </div>

        <div className="formField categories">
          <SwitchText
            label="Categoria"
            data={stateResearchCategories}
            handleChange={handleResearchCategory}
            value={stateResearchCategory.id}
            size="large"
          />
        </div>

        <div className="formField">
          <RadioInput
            label="Subcategoria"
            options={returnSubCategoryOptions(stateResearchCategory.id) || []}
            value={stateResearchSubCategory.id}
            handleChange={handleResearchSubCategory}
          />
        </div>

        <BoxFooter>
          <Button
            text="Salvar"
            toValidate={true}
            isValidForm={editIsValid()}
            classButton="buttonGreen large"
            typeButton="submit"
            dataQA="button-prospect-new-save"
          />
        </BoxFooter>
      </form>
    </div>
  )
}

export default EditSheetBasicResearch

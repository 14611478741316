import React from "react";

const ReleaseTextIcon = (props) => {
  const { color = "#0098CD" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g stroke={color} strokeWidth="2">
          <g>
            <g>
              <g>
                <path
                  d="M10.877 0L13.584 2.631 17.314 2.101 17.964 5.827 21.292 7.601 19.637 11 21.292 14.399 17.964 16.173 17.314 19.899 13.584 19.369 10.877 22 8.17 19.369 4.44 19.899 3.79 16.173 0.462 14.399 2.117 11 0.462 7.601 3.79 5.827 4.44 2.101 8.17 2.631z"
                  transform="translate(-1042.000000, -417.000000) translate(159.476000, 179.000000) translate(0.000000, 220.000000) translate(884.062000, 19.000000)"
                />
                <path
                  d="M15.372 8L9.184 14 6.372 11.273"
                  transform="translate(-1042.000000, -417.000000) translate(159.476000, 179.000000) translate(0.000000, 220.000000) translate(884.062000, 19.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ReleaseTextIcon;

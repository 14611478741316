import React from "react";
import "./styles/AvatarData.scss";
import Avatar from "../../Avatar/Avatar";

const AvatarData = (props) => {
  const { name } = props;

  return (
    <div className="navItem avatarName">
      <Avatar letter={name?.slice(0, 1)} />
      <span>{name}</span>
    </div>
  );
};

export default AvatarData;

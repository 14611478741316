import React from "react";

const AddProductionCompanieIcon = (props) => {
  const { color } = props;

  return (
    <svg width="24" height="24" fill="none" color={color} size="24">
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M3 11a1 1 0 10-2 0h2zm10.5 10a1 1 0 100-2v2zM3 18v-7H1v7h2zm10.5 1H4v2h9.5v-2zM1 18a3 3 0 003 3v-2a1 1 0 01-1-1H1z"
          fill={color}
        ></path>
        <path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20 17v6M17 20h6"
        ></path>
        <path
          d="M5 8l-3 3M10 8l-3 3M15 8l-3 3M20 8l-3 3"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        ></path>
        <path
          d="M19 13.5a1 1 0 102 0h-2zM21 8a1 1 0 10-2 0h2zm-2 0v5.5h2V8h-2z"
          fill={color}
        ></path>
        <path
          d="M16.729 1.4l2.29 2.291M11.9 2.694l2.29 2.291M7.07 3.988L9.36 6.28M2.24 5.282l2.291 2.291"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        ></path>
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={color} d="M0 0h24v24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddProductionCompanieIcon;

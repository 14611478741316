import React, { useState, useEffect, useRef, useContext } from "react";
import "./styles/FilterButton.scss";
import FilterIcon from "../VectorIcons/FilterIcon";
import Avatar from "../Avatar/Avatar";
import CheckBox from "../CheckBox/CheckBox";
import AppContext from "../../context/appContext";

const FilterButton = (props) => {
  const { text, options, toggleUser, toggleAll, checkAll, setCheckAll } = props;

  const [stateShowMenu, setStateShowMenu] = useState(false);
  const [stateOptions, setStateOptions] = useState(options);

  const componentRef = useRef(null);

  const appContext = useContext(AppContext);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const handleClickOutside = (event) => {
    const optionElement = event.target.closest(".avatarOption");
    if (
      componentRef.current &&
      !componentRef.current.contains(event.target) &&
      !optionElement
    ) {
      setStateShowMenu(false);
    }
  };

  useEffect(() => {
    setStateOptions(options);
    // eslint-disable-next-line
  }, [options]);

  const toggleActiveAll = () => {
    setCheckAll(!checkAll);
    let opt = [];
    let newState = [...stateOptions];
    newState.forEach((e) => (e.isActive = !checkAll));
    setStateOptions(newState);
    stateOptions.forEach((e) => {
      opt.push(e.name);
    });
    toggleAll(!!checkAll ? opt : []);
  };

  const toggleActive = (e, option) => {
    option.isActive = !option.isActive;

    const newState = [...stateOptions];
    newState.filter((e) => e.id === option.id).isActive = !option.isActive;
    setStateOptions(newState);
    setCheckAll(false);
    toggleUser(option.name);
  };

  const AvatarOption = (props) => {
    const { name } = props;

    return (
      <div className="avatarOption">
        <Avatar
          letter={name.slice(0, 1)}
          bgColor={{ backgroundColor: appContext.generateColorFromName(name) }}
        />
        <span className="optionText">{name}</span>
      </div>
    );
  };

  return (
    <div className="filterButtonContainer" ref={componentRef}>
      <div
        className="filterButton"
        onClick={() => setStateShowMenu(!stateShowMenu)}
      >
        <span className="filterText">{text}</span>

        <FilterIcon />
      </div>

      {stateShowMenu && (
        <div className="filterMenu">
          <div className="filterOption">
            <CheckBox
              key={1}
              index={1}
              value={checkAll}
              event={toggleActiveAll}
              isActive={checkAll}
              label="Ver todos"
            />
          </div>

          {stateOptions.map((option, i) => {
            return (
              <div className="filterOption" key={i}>
                <CheckBox
                  key={i}
                  index={i}
                  value={option.id}
                  event={(e) => toggleActive(e, option)}
                  isActive={option.isActive}
                  label={<AvatarOption name={option.name} />}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FilterButton;

import React from "react";
import "./styles/TitleHistories.scss";

const TitleHistories = (props) => {
  const { histories } = props;

  return (
    <li className="titleHistories">
      <span className="oldTitleKey">Título Anterior:</span>

      <span className="oldTitleValue">
        {histories.map((title, index) => {
          return (
            <React.Fragment key={index}>
              {title}
              {histories.length > index + 1 && (
                <span className="separator">•</span>
              )}
            </React.Fragment>
          );
        })}
      </span>
    </li>
  );
};

export default TitleHistories;

import React, { useState } from "react";
import "./styles/NoResultsFiles.scss";
import { Redirect } from "react-router-dom";
import SimpleButton from '../SimpleButton/SimpleButton'
import SheetIcon from '../VectorIcons/SheetIcon'

const NoResultsFiles = (props) => {
  const { redirectToSheet } = props;

  const [stateRedirect, setStateRedirect] = useState(false);

  const handleRedirect = () => setStateRedirect(true);

  return (
    <div className="noResultsFiles">
      <p>Para criar uma Atividade, o projeto precisa ter algum anexo.</p>
      <p>Você pode adicioná-lo pela Ficha do Projeto.</p>

      <SimpleButton
        text="Ir para a Ficha do projeto"
        classButton="buttonBlue"
        clickEvent={() => handleRedirect()}
      >
        <SheetIcon color="#FFF" />
      </SimpleButton>

      {stateRedirect && <Redirect to={redirectToSheet()} />}
    </div>
  );
};

export default NoResultsFiles;

import React, { useContext } from "react";
import AppContext from "../../context/appContext";
import "./style/ViewFile.scss";
import { DocumentViewer } from "react-documents";
import ScriptBox from "../ScriptBox/ScriptBox";
import SmallLoading from "../SmallLoading/SmallLoading";
import DownloadIcon from "../VectorIcons/DownloadIcon";

const ViewFile = ({ url, title }) => {
  const appContext = useContext(AppContext);

  const downloadEmployeeData = (url, name) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      });
    });
  };

  const selectViewer = () => {
    const fileType = title.slice(-3).toLowerCase();

    if (fileType === "mp3" || fileType === "mp4") {
      return (
        <DocumentViewer
          className="UrlViewer"
          url={url}
          viewer="url"
        ></DocumentViewer>
      );
    }

    if (
      fileType === "png" ||
      fileType === "peg" ||
      fileType === "jpg" ||
      fileType === "svg" ||
      fileType === "ico" ||
      fileType === "gif"
    ) {
      return <img className="ImgViewer" alt={title} src={url} />;
    }

    if (!!url) {
      return (
          <DocumentViewer
          className="GoogleViewer"
          url={url}
          viewer="google"
        ></DocumentViewer>
      );
    }
  };

  const closeModal = () => {
    appContext.setCurrentStepBox("closed");
  };

  return (
    <div id="ViewContainer">
      <ScriptBox
        open={true}
        title={
          title?.length > 40
            ? title?.slice(0, 40) + "..."
            : title || "Aguarde..."
        }
        icon={
          !!url ? (
            <div
              className="downloadAction"
              onClick={() => downloadEmployeeData(url, title)}
            >
              <DownloadIcon color="#FFF" />
            </div>
          ) : (
            <></>
          )
        }
        clear={() => closeModal()}
        size="large"
      >
        <div className="ViewWrapper">
          {!!url ? selectViewer() : <SmallLoading />}
        </div>
      </ScriptBox>
    </div>
  );
};

export default ViewFile;

import React from "react";
import "./styles/BlocksOrChaptersTable.scss";
import TableHeader from "./TableHeader/TableHeader";
import TableChapterItem from "./TableChapterItem/TableChapterItem";

const BlocksOrChaptersTable = (props) => {
  const {
    chapterOrEpisode,
    list,
    setStateAction,
    typeValues,
    handleOpenReleaseTexts,
    handleFileDetails,
    handleEditFile,
    getPlanning,
    handleDeleteChapter,
    type,
    projectTitle,
  } = props;

  const isChapter = chapterOrEpisode === "Capítulos";
  const mainCellTitle = isChapter ? "Bloco" : "Episódio";

  return (
    <div id="BlocksOrChaptersTable">
      <TableHeader isChapter={isChapter} type={type} />
      <ul>
        {list.map((item, index) => {
          return (
            <TableChapterItem
              handleFileDetails={handleFileDetails}
              key={index}
              item={item}
              isLastItem={index === list.length - 1}
              isFirstItem={index === 0}
              setStateAction={setStateAction}
              isChapter={isChapter}
              mainCellTitle={mainCellTitle}
              typeValues={typeValues}
              getPlanning={getPlanning}
              handleEditFile={handleEditFile}
              handleOpenReleaseTexts={handleOpenReleaseTexts}
              handleDeleteChapter={handleDeleteChapter}
              type={type}
              projectTitle={projectTitle}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default BlocksOrChaptersTable;


import React, { useState, useEffect } from "react";
import Fieldset from "../../../components/Fieldset/Fieldset";
import RadioInput from "../../../components/RadioInput/RadioInput";
import SwitchText from "../../../components/SwitchText/SwitchText";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import NextArrowIcon from "../../../components/VectorIcons/NextArrowIcon";
import Button from "../../../components/Button/Button";

const Step1 = (props) => {
  const {
    currentStep,
    setStateCurrentStep,
    maxSteps,
    setStateMaxSteps,
    getResearchType,
    getResearchTitle,
    getResearchCategory,
    getResearchSubCategory,
    researchTypes,
    categories,
    subCategories,
    postResearch,
    originsOptions,
    getOrigin,
    clearStep2,
  } = props;

  const [stateResearchType, setStateResearchType] = useState([]);
  const [stateResearchTitle, setStateResearchTitle] = useState("");
  const [stateResearchCategory, setStateResearchCategory] = useState({
    id: 1,
    value: "Produtos",
  });
  const [stateResearchSubCategory, setStateResearchSubCategory] = useState("");
  const [stateError, setStateError] = useState({ title: false });

  useEffect(() => {
    setStateResearchType(researchTypes[0]);
  }, [researchTypes]);

  const handleResearchType = (id, value) => {
    setStateResearchType({ id: id, value: value });
    getResearchType({ id: id, value: value });
  };

  const handleChangeResearchTitle = (ev) => {
    setStateResearchTitle(ev.target.value);
    getResearchTitle(ev.target.value);
  };

  const handleResearchCategory = (id, value) => {
    if (
      stateResearchCategory.value === "Publicação" &&
      value !== "Publicação"
    ) {
      getOrigin(originsOptions[0]);
    }
    value === "Publicação" && clearStep2();
    setStateResearchCategory({ id: id, value: value });
    getResearchCategory({ id: id, value: value });
  };

  const handleResearchSubCategory = (id, value) => {
    setStateResearchSubCategory({ id: id, value: value });
    getResearchSubCategory({ id: id, value: value });
  };

  const handleBlurTitle = () => {
    setStateError({ ...stateError, title: stateResearchTitle.trim() === "" });
  };

  useEffect(() => {
    stateResearchCategory.value === "Publicação" && setStateMaxSteps(2);
    stateResearchCategory.value !== "Publicação" &&
      maxSteps === 2 &&
      setStateMaxSteps(3);

    setStateResearchSubCategory(0);
    getResearchSubCategory(0);
    // eslint-disable-next-line
  }, [stateResearchCategory]);

  const returnSubCategoryOptions = (categoryId) =>
    subCategories[categoryId - 1];

  const step1Submit = () =>
    !!stateResearchTitle &&
    !!stateResearchSubCategory &&
    stateResearchCategory.value === "Publicação";
  const step1isValid = () => !!stateResearchTitle && !!stateResearchSubCategory;

  return (
    <>
      <div className="formField types">
        <SwitchText
          label="Tipo"
          data={researchTypes || []}
          handleChange={handleResearchType}
          value={!!stateResearchType ? stateResearchType.id : []}
          size="large"
        />
      </div>

      <div className="formField">
        <Fieldset
          autoComplete="off"
          name="Title"
          label="Título"
          type="text"
          placeholder="Título da pesquisa"
          value={stateResearchTitle}
          handleChange={handleChangeResearchTitle}
          handleBlur={() => handleBlurTitle()}
          isError={stateError.title}
          msgError="Este campo é obrigatório"
        />
      </div>

      <div className="formField categories">
        <SwitchText
          label="Categoria"
          data={categories || []}
          handleChange={handleResearchCategory}
          value={stateResearchCategory.id}
          size="large"
        />
      </div>

      <div className="formField">
        <RadioInput
          label="Subcategoria"
          options={returnSubCategoryOptions(stateResearchCategory.id) || []}
          value={stateResearchSubCategory.id}
          handleChange={handleResearchSubCategory}
        />
      </div>

      <div className="formField buttonField">
        {stateResearchCategory.value === "Publicação" && (
          <Button
            text="Finalizar agora"
            classButton="buttonGreen extraLarge"
            toValidate={true}
            isValidForm={step1Submit()}
            clickEvent={() => postResearch()}
          />
        )}

        <SimpleButton
          text={
            stateResearchCategory.value === "Publicação"
              ? "Próximo: Anexos"
              : "Avançar"
          }
          classButton="buttonBlue extraLarge"
          dataQA="button-prospect-edit"
          iconSide="right"
          toValidate={true}
          isValidForm={step1isValid()}
          clickEvent={
            step1isValid()
              ? () => setStateCurrentStep(currentStep + 1)
              : () => {}
          }
        >
          <NextArrowIcon />
        </SimpleButton>
      </div>
    </>
  );
};

export default Step1;

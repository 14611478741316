import React from "react";
import ThesaurusScope from "../../../../components/ThesaurusScope/ThesaurusScope";
import ThesaurusSelect from "../../../../components/ThesaurusSelect/ThesaurusSelect";

const PlotAndThematicAndUniverse = (props) => {
  const {
    options,
    handleChange,
    defaultValueTertiary,
    defaultValueSecundaries,
    defaultValuePrimaries,
    thesaurusName,
    keyName,
    origin,
  } = props;

  return (
    <div className="formContent">
      <div className="formFieldColumn">
        <label className="fieldLabel">Central</label>

        <ThesaurusScope
          thesaurusName={thesaurusName}
          thesaurus={options.primaries}
          selecteds={defaultValuePrimaries}
          searchable={true}
          showBreadcrumb={true}
          addThesaurus={(item) => handleChange(item, keyName, "primariesIds")}
          hasHierarchy={true}
          origin={origin}
        >
          <ThesaurusSelect
            value={defaultValuePrimaries}
            options={options.primaries}
            isMulti={true}
            onChange={(item) => handleChange(item, keyName, "primariesIds")}
            placeholder="Digite um nome e aperte ENTER"
            noOptionsMessage="Nenhum item encontrado"
          />
        </ThesaurusScope>
      </div>

      <div className="formFieldColumn">
        <label className="fieldLabel">Secundária</label>

        <ThesaurusScope
          thesaurusName={thesaurusName}
          thesaurus={options.secundaries}
          selecteds={defaultValueSecundaries}
          searchable={true}
          showBreadcrumb={true}
          addThesaurus={(item) => handleChange(item, keyName, "secundariesIds")}
          hasHierarchy={true}
          origin={origin}
        >
          <ThesaurusSelect
            value={defaultValueSecundaries}
            options={options.secundaries}
            isMulti={true}
            onChange={(item) => handleChange(item, keyName, "secundariesIds")}
            placeholder="Digite um nome e aperte ENTER"
            noOptionsMessage="Nenhum item encontrado"
          />
        </ThesaurusScope>
      </div>

      <div className="formFieldColumn">
        <label className="fieldLabel">Terciária</label>

        <ThesaurusScope
          thesaurusName={thesaurusName}
          thesaurus={options.tertiaries}
          selecteds={defaultValueTertiary}
          searchable={true}
          showBreadcrumb={true}
          addThesaurus={(item) => handleChange(item, keyName, "tertiaryIds")}
          hasHierarchy={true}
          origin={origin}
        >
          <ThesaurusSelect
            value={defaultValueTertiary}
            options={options.tertiaries}
            isMulti={true}
            onChange={(item) => handleChange(item, keyName, "tertiaryIds")}
            placeholder="Digite um nome e aperte ENTER"
            noOptionsMessage="Nenhum item encontrado"
          />
        </ThesaurusScope>
      </div>
    </div>
  );
};

export default PlotAndThematicAndUniverse;

import React from "react";
import "./styles/ContentPagination.scss";
import Pagination from "react-js-pagination";

const ContentPagination = (props) => {
  const {
    currentPage,
    totalItems,
    prevPage,
    nextPage,
    itemsPerPage,
    handleChange,
  } = props;

  const handlePageChange = (pageNumber) => {
    handleChange({ pageNumber: pageNumber });
  };

  if (totalItems === undefined) return "";

  return (
    <>
      <div id="PaginationContent">
        <div
          className={`prevPagination ${prevPage && "hasPage"}`}
          onClick={prevPage ? () => handlePageChange(prevPage) : null}
        >
          <span className="prevArrow" />
          <span className="prevArrow" />
        </div>

        <Pagination
          hideNavigation
          hideFirstLastPages
          activePage={currentPage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalItems}
          onChange={handlePageChange}
          hideDisabled
        />

        <div
          className={`nextPagination ${nextPage && "hasPage"}`}
          onClick={nextPage ? () => handlePageChange(nextPage) : null}
        >
          <span className="nextArrow" />
          <span className="nextArrow" />
        </div>
      </div>
    </>
  );
};

export default ContentPagination;

import React from "react";

const LiteraryWorkIcon = (props) => {

  const { color="#fff" } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="24"
      viewBox="0 0 24 29"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <g>
            <path
              d="M40 33.951V15.952c0-.712-.573-1.285-1.286-1.285H21.143c-2.839 0-5.143 2.303-5.143 5.142v17.142c0 2.839 2.304 5.143 5.143 5.143h17.571c.713 0 1.286-.573 1.286-1.286v-.857c0-.402-.188-.766-.477-1.002-.225-.825-.225-3.176 0-4.001.289-.231.477-.595.477-.997zM22.857 21.845c0-.177.145-.322.322-.322h11.357c.176 0 .321.145.321.322v1.071c0 .177-.145.322-.321.322H23.179c-.177 0-.322-.145-.322-.322v-1.071zm0 3.428c0-.177.145-.321.322-.321h11.357c.176 0 .321.144.321.321v1.072c0 .176-.145.321-.321.321H23.179c-.177 0-.322-.145-.322-.321v-1.072zm13.575 13.392H21.143c-.948 0-1.714-.766-1.714-1.714 0-.943.771-1.714 1.714-1.714h15.289c-.102.916-.102 2.512 0 3.428z"
              transform="translate(-96 -114) translate(80 100)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LiteraryWorkIcon;

import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import "./styles/LiteraryWork.scss";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import TopSection from "./TopSection/TopSection";
import { postService } from "../../services/script-api";
import AppContext from "../../context/appContext";
import RegisterSuccess from "../RegisterSuccess/RegisterSuccess";
import { sendEvent } from "../../services/GoogleAnalytics/methods";

const LiteraryWork = (props) => {
  const [stateCurrentStep, setStateCurrentStep] = useState(1);
  const [currentLiteraryWork, setCurrentLiteraryWork] = useState({});
  const appContext = useContext(AppContext);

  const newDate = new Date();
  let currentDate = new Date(
    newDate.getTime() - newDate.getTimezoneOffset() * 60000
  ).toISOString();

  const [state, setState] = useState({
    publicDomain: false,
    receivedAt: currentDate,
    authors: [],
    suggestedBy: [],
    publishers: [],
    title: "",
    formatId: "",
    publicationYear: "",
  });

  const [stateError, setStateError] = useState({ title: false });
  const [stateAssociationTypes, setStateAssociationTypes] = useState({
    project: false,
    research: false,
    literaryWork: false,
  });
  const [currentFormat, setCurrentFormat] = useState("");

  const handleBlurCreators = () =>
    setStateError({ ...stateError, suggestedBy: !state.suggestedBy.length });

  const getValues = ({ key, subKey, value, validate }) => {
    const handleKeysValue = !!subKey
      ? { ...state[key], [subKey]: value }
      : value;
    setState({ ...state, [key]: handleKeysValue });
    if (validate)
      setStateError({ ...stateError, [key]: !value || !value.length });
  };

  const setAssociationTypes = (key) =>
    setStateAssociationTypes({
      ...stateAssociationTypes,
      [key]: !stateAssociationTypes[key],
    });

  const getCurrentFormat = (value) => setCurrentFormat(value);

  const setStep = (step) => setStateCurrentStep(step);

  const sendLiteraryWork = () => {
    let obj = {
      ...state,
      authors: state.authors.map((e) => e.value),
      publishers: state.publishers.map((e) => e.value),
    };

    appContext.setShowGlassLoading(true);
    postService("literaryworks", obj)
      .then((res) => {
        appContext.sendMessage(
          "Obra literária cadastrada com sucesso!",
          "success"
        );
        appContext.setShowGlassLoading(false);
        setStep(3);
        setCurrentLiteraryWork({
          id: res.data,
          title: state.title,
        });
        sendEvent("Obra Literária", "Criação", currentFormat);
      })
      .catch(() => {
        appContext.sendMessage(
          "Error ao cadastrar uma obra literária!",
          "error"
        );
        appContext.setShowGlassLoading(false);
      });
  };

  const isValidYear = () =>
    state.publicationYear > 0 && state.publicationYear <= newDate.getFullYear();

  const isBiggerCurrentYear = () =>
    !!state.publicationYear
      ? state.publicationYear <= newDate.getFullYear()
      : !state.publicationYear;

  const setErrorYear = () =>
    setStateError({ ...stateError, publicationYear: !isValidYear() });

  const isValid = () =>
    !!state.authors?.length &&
    !!state.formatId &&
    !!state.title &&
    isBiggerCurrentYear();

  if (stateCurrentStep === 3) {
    return (
      <RegisterSuccess
        id={currentLiteraryWork.id}
        title={currentLiteraryWork.title}
        type="obra-literaria"
      />
    );
  }

  return (
    <div id="LiteraryWork">
      <Helmet>
        <title>Script - Cadastro de Obra Literária</title>
      </Helmet>
      <TopSection
        currentStep={stateCurrentStep}
        title={state.title}
        setStep={setStep}
      />

      <div className="literaryContainer">
        <form className="literaryForm">
          <div
            className={`${stateCurrentStep === 1 ? "showStep" : "hideStep"}`}
          >
            <Step1
              state={state}
              stateError={stateError}
              setValues={getValues}
              stateAssociationTypes={stateAssociationTypes}
              setAssociationTypes={setAssociationTypes}
              setStep={setStep}
              sendLiteraryWork={sendLiteraryWork}
              handleBlurCreators={handleBlurCreators}
              isValid={isValid}
              isValidYear={setErrorYear}
              getCurrentFormat={getCurrentFormat}
            />
          </div>

          <div
            className={`${stateCurrentStep === 2 ? "showStep" : "hideStep"}`}
          >
            <Step2 />
          </div>
        </form>
      </div>
    </div>
  );
};

export default LiteraryWork;

import React from "react";
import "./styles/ListVideos.scss";

const ListVideos = (props) => {
  const { title, subTitle, platform, url, password } = props;

  const limitingCharacters = (text) =>
    text.length > 20 ? `${text.slice(0, 20)}...` : text;

  return (
    <div className="ListVideos">
      <div className="nameAndPasswordVideos">
        <div onClick={() => window.open(url, "_blank")}>
          {limitingCharacters(title)}
        </div>
        {subTitle && <span>{subTitle}</span>}
      </div>
      <div className="platform">{platform}</div>
      {password && <span className="password">{`Senha: ${password}`}</span>}
    </div>
  );
};

export default ListVideos;

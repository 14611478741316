import React from "react";
// import AppContext from '../../../context/appContext'
import Button from "../../../components/Button/Button";
import UploadSection from "../../../components/UploadSection/UploadSection";
import UploadService from "../../../components/UploadService/UploadService";

const Step2 = (props) => {
  /*const { send, thesaurus, stateAttachments, getAttachments } = props

  const [_stateAttachments, _setStateAttachments] = useState([])
  const context = useContext(AppContext)

  const getDuplicateFiles = (files) => {
    let filesName = ""
    files.map( (e) => filesName += `<p>${e.name} ja foi selecionado!</p>`)
    context.sendMessage(filesName, 'error')
  }

  const formatFiles = (attachments) => {
    let files = []

    if (!!attachments.length) {

      attachments.forEach((file) => {
        
        let tempObjFiles = {
          'fileId': file._meta.tempFileName,
          'fileName': file.originalName,
          'version': file.version,
          'receivedAt': file.receiveAt,
          'attachmentTypeId': file.attachmentTypeId,
          'blockNumber': file.blockNumber,
          'chapterNumber': parseInt(file.chapterNumber)
        }

        files.push(tempObjFiles)
      })
    }
    return files
  }*/

  // const step2isValid = () => {
  //   const verifyStateFiles = stateAttachments.every(file => !!file.attachmentTypeId)
  //   const _verifyStateFiles = _stateAttachments.every(file => !!file.isSent)

  //   return !!stateAttachments.length ? verifyStateFiles && _verifyStateFiles : stateAttachments.length === 0
  // }

  // const handleFiles = (files) => {
  //   _setStateAttachments(files)
  //   getAttachments(formatFiles(files))
  // }

  // const handleSend = () => step2isValid() && send()

  return (
    <div className="formFieldColumn">
      <UploadSection>
        <UploadService
          // thesaurusFileType={thesaurus}
          // handleOnComplete={handleFiles}
          handleOnComplete={() => {}}
          // getDuplicateFiles={getDuplicateFiles}
        />
      </UploadSection>

      <div className="formField buttonField">
        <Button
          text="Finalizar agora"
          classButton="buttonGreen extraLarge"
          // isValidForm={step2isValid()}
          // toValidate={true}
          // clickEvent={() => handleSend()}
        />
      </div>
    </div>
  );
};

export default Step2;

import React from "react";
import "./styles/SeriesTimeline.scss";

const SeriesGraphicBar = (props) => {
  const { width, value, color, conflict, year } = props;
  const { durationPercentage, startedThisYear, daysFromStartOfYearPercentage } =
    value(conflict, year);

  return (
    <div className="timelineItem">
      <div className="timelineItemInfo">
        <span className="timelineConflictName">{conflict.name}</span>
      </div>

      <div className="timelineTrackContainer">
        <div className="timelineTrack" />
        <div
          className="graphicBarDotted"
          style={{
            width: width(durationPercentage),
            minWidth: "5px",
            left: `${startedThisYear ? daysFromStartOfYearPercentage : 0}%`,
            background: `
              repeating-linear-gradient(to right, 
              ${color(conflict.relevance)},
              ${color(conflict.relevance)} 5px, #fff 5px, #fff 10px)`,
          }}
        />
      </div>
    </div>
  );
};

export default SeriesGraphicBar;

import React, { useContext, useEffect, useState } from "react";
import "./styles/ConflictsFilter.scss";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatepicker";
import ConflictsIcon from "../../../components/VectorIcons/ConflictsIcon";
import FieldsetSelect from "../../../components/FieldsetSelect/FieldsetSelect";
import MapConflictContext from "../../../context/mapConflictContext";
import AppContext from "../../../context/appContext";
import Button from "../../../components/Button/Button";
import { getService } from "../../../services/script-api";
import { sendEvent } from "../../../services/GoogleAnalytics/methods";

const ConflictsFilter = (props) => {
  const { getTypeConflictSelected } = props;
  const [stateCurrentDatepicker, setStateCurrentDatepicker] = useState("");
  const [state, setState] = useState({
    beginDate: "",
    endDate: "",
    conflictType: "",
    disabled: true,
  });

  const [stateConflictMapTypes, setConflictMapTypes] = useState({
    loading: true,
    types: [],
  });
  const [currentConflictType, setCurrentConflictType] = useState("");

  const mapContext = useContext(MapConflictContext);
  const context = useContext(AppContext);

  const getDate = (date, key) => {
    let d = new Date(
      date.getTime() + date.getTimezoneOffset() * 60000
    ).toISOString();
    setState({
      ...state,
      [key]: d,
      disabled: false,
    });
  };

  useEffect(() => {
    setState({
      ...state,
      endDate: "",
      disabled: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.beginDate]);

  const handleChange = (e) => {
    getTypeConflictSelected(
      stateConflictMapTypes.types.filter(
        (el) => parseInt(el.id) === parseInt(e.target.value)
      )[0]
    );
    setCurrentConflictType(e.target.value);
  };

  const isValid = () =>
    state.endDate.trim() !== "" &&
    state.beginDate.trim() !== "" &&
    !!currentConflictType;

  const sendMapCreationAnalytics = () => {
    if (!!stateConflictMapTypes.types.length) {
      const [type] = stateConflictMapTypes.types.filter(
        (type) => type.id.toString() === currentConflictType
      );
      sendEvent("Mapa de Conflitos", `Geração: por ${type.value}`);
    }
  };

  const getConflictMapTypes = () => {
    getService("conflictmap/types").then((res) => {
      setConflictMapTypes({
        ...stateConflictMapTypes,
        loading: false,
        types: res.data,
      });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    sendMapCreationAnalytics();

    if (isValid()) {
      delete state.disabled;

      mapContext.setFilter({
        ...mapContext.filter,
        ...state,
        limit: 12,
        pageNumber: 1,
        conflictType: currentConflictType,
        newMap: true,
      });

      mapContext.setFilterAll({
        ...mapContext.filter,
        ...state,
        limit: 12000,
        pageNumber: 1,
        conflictType: currentConflictType,
        newMap: false,
      });
    }
  };

  useEffect(() => {
    getConflictMapTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="conflictsFilter">
      <form onSubmit={(e) => handleSubmit(e)} className="fieldsContainer">
        <FieldsetSelect
          label="Tipo de Conflito"
          placeholder="Tipo de Conflito"
          handleChange={handleChange}
          data={stateConflictMapTypes.types}
          selected={currentConflictType}
        />

        <div className="datepickerFields">
          <div
            className="customDatepickerHolder"
            style={{
              zIndex: `${stateCurrentDatepicker === "beginDate" ? "25" : "1"}`,
            }}
          >
            <CustomDatePicker
              label="Data Início"
              name="intervalStart"
              renderSide="left"
              onCalendarOpen={() => setStateCurrentDatepicker("beginDate")}
              onClose={() => setStateCurrentDatepicker("")}
              handleChangeDate={(date) => getDate(date, "beginDate")}
              disabled={false}
              placeholderText="Data ínicio"
              maxDate={context.sumDate(new Date(), 3095)}
            />

            <span className="recomendationMessage">
              Período máximo recomendado: <strong>3 anos</strong>.
            </span>
          </div>

          <div
            className="customDatepickerHolder"
            style={{
              zIndex: `${stateCurrentDatepicker === "endDate" ? "25" : "1"}`,
            }}
          >
            <CustomDatePicker
              label="Data Fim"
              name="intervalEnd"
              renderSide="right"
              onCalendarOpen={() => setStateCurrentDatepicker("endDate")}
              onClose={() => setStateCurrentDatepicker("")}
              handleChangeDate={(date) => getDate(date, "endDate")}
              disabled={state.disabled}
              placeholderText="Data fim"
              date={!!state.endDate ? new Date(state.endDate) : ""}
              maxDate={context.sumDate(state.beginDate, 1095)}
              startDate={context.sumDate(state.beginDate, 1)}
            />
          </div>
        </div>

        <Button
          text="Gerar conflitos"
          classButton="buttonBlue extraLarge"
          dataQA="button-prospect-edit"
          iconSide="left"
          typeButton="submit"
          isValidForm={isValid()}
          toValidate={true}
        >
          <ConflictsIcon />
        </Button>
      </form>
    </div>
  );
};

export default ConflictsFilter;

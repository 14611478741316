import React, { useState } from "react";
import "./styles/CharacterNamesInput.scss";
import AsyncCreatableSelect from "react-select/async-creatable";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import DeleteIcon from "../../../components/VectorIcons/DeleteIcon";
import AsyncLoading from "../../../components/AsyncLoading/AsyncLoading";

const CharacterNamesInput = (props) => {
  const { getIdentifications, index, defaultValues, loadOptions } = props;

  const {
    handleRemoveNameField,
    totalCharacterNames,
  } = props;

  const [stateRenderLastNname, setStateRenderLastName] = useState(
    !!defaultValues.lastName
  );
  const [stateRenderNickNname, setStateRenderNickName] = useState(
    !!defaultValues.nickName
  );

  return (
    <div
      className={`characterInput ${
        totalCharacterNames > 1 ? "showDeleteIcon" : ""
      }`}
    >
      <label className="fieldLabel">Nome</label>
      <AsyncCreatableSelect
        className="reactSelect blueBordered"
        classNamePrefix="reactSelect"
        value={defaultValues.name}
        onChange={(item) => getIdentifications("name", item, index)}
        placeholder="Digite um nome"
        isMulti
        loadOptions={(inputValue, callback) =>
          loadOptions(inputValue, callback, 29)
        }
        noOptionsMessage={() => "Nenhum item encontrado"}
        openMenuOnClick={false}
        formatCreateLabel={(inputValue) => {
          return `Adicionar a opção: "${inputValue}"`;
        }}
        loadingMessage={() => {
          return "Carregando...";
        }}
        components={{ LoadingIndicator: () => <AsyncLoading /> }}
      />

      {stateRenderLastNname && (
        <>
          <label className="fieldLabel">Sobrenome</label>
          <AsyncCreatableSelect
            className="reactSelect blueBordered"
            classNamePrefix="reactSelect"
            value={defaultValues.lastName}
            onChange={(item) => getIdentifications("lastName", item, index)}
            placeholder="Digite um sobrenome"
            isMulti
            loadOptions={(inputValue, callback) =>
              loadOptions(inputValue, callback, 30)
            }
            noOptionsMessage={() => "Nenhum item encontrado"}
            openMenuOnClick={false}
            formatCreateLabel={(inputValue) => {
              return `Adicionar a opção: "${inputValue}"`;
            }}
            loadingMessage={() => {
              return "Carregando...";
            }}
            components={{ LoadingIndicator: () => <AsyncLoading /> }}
          />
        </>
      )}

      {stateRenderNickNname && (
        <>
          <label className="fieldLabel">Apelido</label>
          <AsyncCreatableSelect
            className="reactSelect blueBordered"
            classNamePrefix="reactSelect"
            value={defaultValues.nickName}
            onChange={(item) => getIdentifications("nickName", item, index)}
            placeholder="Digite um apelido"
            isMulti
            loadOptions={(inputValue, callback) =>
              loadOptions(inputValue, callback, 31)
            }
            noOptionsMessage={() => "Nenhum item encontrado"}
            openMenuOnClick={false}
            formatCreateLabel={(inputValue) => {
              return `Adicionar a opção: "${inputValue}"`;
            }}
            loadingMessage={() => {
              return "Carregando...";
            }}
            components={{ LoadingIndicator: () => <AsyncLoading /> }}
          />
        </>
      )}

      <div className="buttonsList">
        {!stateRenderLastNname && (
          <SimpleButton
            text="+ Sobrenome"
            classButton="borderedButton"
            clickEvent={() => {
              setStateRenderLastName(true);
            }}
            dataQA=""
          />
        )}

        {!stateRenderNickNname && (
          <SimpleButton
            text="+ Apelido"
            classButton="borderedButton"
            clickEvent={() => {
              setStateRenderNickName(true);
            }}
            dataQA=""
          />
        )}
      </div>

      {totalCharacterNames > 1 && (
        <div className="deleteIcon" onClick={handleRemoveNameField}>
          <DeleteIcon />
        </div>
      )}
    </div>
  );
};

export default CharacterNamesInput;

import React from "react";
import NumberFormat from "react-number-format";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import globoBold from "../../../assets/fonts/Globo-Tx-Bd.ttf";
import globoRegular from "../../../assets/fonts/GloboRegular.ttf";
import scriptImage from "../../../assets/icons/scriptImage.png";
import ficcaoImg from "../../../assets/icons/ficcao.png";
import noFiccaoImg from "../../../assets/icons/nao-ficcao.png";
import bisImg from "../../../assets/channels/bis.png";
import globoplayImg from "../../../assets/channels/globoplay.png";
import gloobImg from "../../../assets/channels/gloob.png";
import gloobinhoImg from "../../../assets/channels/gloobinho.png";
import gntImg from "../../../assets/channels/gnt.png";
import modoviagemImg from "../../../assets/channels/modoviagem.png";
import multishowImg from "../../../assets/channels/multishow.png";
import offImg from "../../../assets/channels/off.png";
import universalImg from "../../../assets/channels/universal.png";
import viuImg from "../../../assets/channels/viu.png";
import vivaImg from "../../../assets/channels/viva.png";
import { returnFictionAndNotFictionLabel } from "../../../shared/utils/returnFictionAndNotFictionLabel";
import { calculateDifferenceInDays } from "../../../shared/utils/calculateDifferenceInDays";
import RenderPDF from "../../../components/RenderPDF";
import TagsProject from "../InfoPaper/InfoPaperExternalProject/TagsProject/TagsProject";

Font.register({
  family: "GloboTipoRegular",
  src: globoRegular,
  fontStyle: "normal",
  fontWeight: "normal",
});

Font.register({
  family: "GloboTipoBold",
  src: globoBold,
  fontStyle: "normal",
  fontWeight: "bold",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 50,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  pdf: {
    width: "90vw",
    height: "90vh",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
  },
  listTitle: {
    width: "36%",
  },
  listText: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "GloboTipoRegular",
    wordWrap: "wrap",
    width: "64%",
  },
  text: {
    marginBottom: 20,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "GloboTipoRegular",
    wordWrap: "wrap",
  },
  subTitle: {
    fontSize: 16,
    textAlign: "justify",
    fontFamily: "GloboTipoRegular",
    fontWeight: "bold",
  },
  bold: {
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "GloboTipoBold",
    fontWeight: "bold",
    paddingRight: "10px",
  },
  img: {
    width: "90px",
    height: "30px",
    marginBottom: "15px",
  },
  flexTitle: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginBottom: 10,
    maxWidth: "75%",
  },
  flexTypes: {
    maxWidth: "25%",
  },
  imgTitle: {
    width: "40px",
    height: "40px",
  },
  title: {
    padding: 5,
    fontSize: 24,
    fontFamily: "GloboTipoBold",
  },
  address: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "GloboTipoRegular",
    margin: 0,
  },
  number: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "GloboTipoRegular",
    textTransform: "capitalize",
  },
  locals: {
    marginBottom: 20,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "GloboTipoRegular",
    width: "100%",
    borderBottom: "1px solid black",
  },
  dotSeparator: {
    width: "4px",
    height: "4px",
    backgroundColor: "#bfc2f6",
    borderRadius: "50%",
    marginTop: "8px",
    marginLeft: "6px",
    marginRight: "6px",
    position: "relative",
  },
});

const ExternalPDF = ({ open, close, data, returnDays, owner }) => {
  const {
    title,
    productionCompanies,
    projectType,
    format,
    projectStatus,
    channels,
    chaptersOrEpisodes,
    complementaryData,
    tags,
    platform,
    deadline,
    costAndFinancialInformation,
    storyLine,
    videos,
  } = data;

  const daysAvaliation = returnDays(
    calculateDifferenceInDays(channels?.[0]?.deadline)
  );

  const typeProject = returnFictionAndNotFictionLabel(projectType);

  const selectChannelImage = (channel) => {
    switch (channel) {
      case "Bis":
        return bisImg;
      case "Canal OFF":
        return offImg;
      case "Globoplay":
        return globoplayImg;
      case "Gloob":
        return gloobImg;
      case "Gloobinho":
        return gloobinhoImg;
      case "GNT":
        return gntImg;
      case "Modo Viagem":
        return modoviagemImg;
      case "Multishow":
        return multishowImg;
      case "Universal":
        return universalImg;
      case "ViU":
        return viuImg;
      case "Viva":
        return vivaImg;
      default:
        return scriptImage;
    }
  };

  return (
    <RenderPDF open={open} close={close}>
      <PDFViewer style={styles.pdf}>
        <Document>
          <Page size="A4" style={styles.body}>
            <View>
              <View style={[styles.flex, { marginBottom: 20 }]}>
                <Image src={scriptImage} style={styles.img} />
                <Image
                  src={selectChannelImage(channels?.[0].channel.name)}
                  style={styles.img}
                />
              </View>
              <View style={styles.flex}>
                <View style={styles.flexTitle}>
                  {typeProject === "Ficção" ? (
                    <Image src={ficcaoImg} style={styles.imgTitle} />
                  ) : (
                    <Image src={noFiccaoImg} style={styles.imgTitle} />
                  )}
                  <Text style={styles.title}>{title}</Text>
                </View>
                <View style={styles.flexTypes}>
                  <Text style={styles.bold}>
                    {productionCompanies?.[0].name}
                  </Text>
                  <Text style={styles.text}>
                    {typeProject} | {format.name}
                  </Text>
                </View>
              </View>
              <Text style={styles.locals}>{""}</Text>
              <View style={[styles.listItem, { marginBottom: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.subTitle}>Status do Projeto</Text>
                </View>
                <Text style={styles.listText}>{projectStatus.description}</Text>
              </View>
              {daysAvaliation !== "-" && (
                <View style={[styles.listItem, { marginBottom: 20 }]}>
                  <View style={styles.listTitle}>
                    <Text style={styles.bold}>PRAZO PARA AVALIAÇÃO</Text>
                  </View>
                  <Text style={styles.listText}>{daysAvaliation}</Text>
                </View>
              )}
              <Text style={styles.locals}>{""}</Text>
              <Text style={styles.subTitle}>Dados do Básicos</Text>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>CANAL</Text>
                </View>
                <Text style={styles.listText}>
                  {channels?.[0].channel.name}
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 10 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>TÍTULO</Text>
                </View>
                <Text style={styles.listText}>{title}</Text>
              </View>
              <View style={[styles.listItem, { marginTop: 10 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>FORMATO</Text>
                </View>
                <Text style={styles.listText}>{format.name}</Text>
              </View>
              <View style={[styles.listItem, { marginTop: 10 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>EPISÓDIOS</Text>
                </View>
                <Text style={styles.listText}>{chaptersOrEpisodes}</Text>
              </View>
              <View style={[styles.listItem, { marginTop: 10 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>MINUTAGEM</Text>
                </View>
                <Text style={styles.listText}>
                  {complementaryData?.minutes}
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 10 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>PÚBLICO ALVO</Text>
                </View>
                <Text style={styles.listText}>
                  {complementaryData?.targetAudience}
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 10 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>TAGS</Text>
                </View>
                <Text style={styles.listText}>
                  <TagsProject tags={tags} />
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 10 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>PLATAFORMA</Text>
                </View>
                <Text style={styles.listText}>{platform?.name}</Text>
              </View>
              <View style={[styles.listItem, { marginTop: 10 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>PRAZO ESTIMADO</Text>
                </View>
                <Text style={styles.listText}>{deadline?.name}</Text>
              </View>
              <Text style={[styles.locals, { marginTop: 20 }]}>{""}</Text>
              <Text style={styles.subTitle}>Dados Complementares</Text>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>TAGLINE</Text>
                </View>
                <Text style={styles.listText}>
                  {complementaryData?.tagline}
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>STORYLINE</Text>
                </View>
                <Text style={styles.listText}>{storyLine}</Text>
              </View>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>SINOPSE GERAL</Text>
                </View>
                <Text style={styles.listText}>
                  {complementaryData?.summary}
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>SINOPSE REDUZIDA</Text>
                </View>
                <Text style={styles.listText}>
                  {complementaryData?.smallSummary}
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>DIFERENCIAL</Text>
                </View>
                <Text style={styles.listText}>
                  {complementaryData?.differential}
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>CURRÍCULO ROTEIRISTAS</Text>
                </View>
                <Text style={styles.listText}>
                  {complementaryData?.writerCurriculum}
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>CURRÍCULO DIREÇÃO</Text>
                </View>
                <Text style={styles.listText}>
                  {complementaryData?.directorCurriculum}
                </Text>
              </View>
              {!!videos?.length && (
                <>
                  <Text style={[styles.locals, { marginTop: 20 }]}>{""}</Text>
                  <Text style={styles.subTitle}>Vídeos</Text>
                  {videos?.map((video, index) => (
                    <View style={[styles.listItem, { marginTop: 20 }]}>
                      <View style={styles.listTitle}>
                        <Text style={styles.bold}>
                          {video?.title}
                          {!!video?.password && ` | senha: ${video?.password}`}
                        </Text>
                      </View>
                      <Text style={styles.listText}>{video?.url}</Text>
                    </View>
                  ))}
                </>
              )}
              <Text style={[styles.locals, { marginTop: 20 }]}>{""}</Text>
              <Text style={styles.subTitle}>Custos e Financiamentos</Text>
              {costAndFinancialInformation?.publicNoteCost !== 0 && (
                <View style={[styles.listItem, { marginTop: 20 }]}>
                  <View style={styles.listTitle}>
                    <Text style={styles.bold}>EDITAIS PÚBLICOS</Text>
                  </View>
                  <Text style={styles.listText}>
                    <NumberFormat
                      value={costAndFinancialInformation?.publicNoteCost}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={"R$"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </Text>
                </View>
              )}
              {costAndFinancialInformation?.ownResourcesCost !== 0 && (
                <View style={[styles.listItem, { marginTop: 20 }]}>
                  <View style={styles.listTitle}>
                    <Text style={styles.bold}>RECURSOS PRÓPRIOS</Text>
                  </View>
                  <Text style={styles.listText}>
                    <NumberFormat
                      value={costAndFinancialInformation?.ownResourcesCost}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={"R$"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </Text>
                </View>
              )}
              {costAndFinancialInformation?.nationalCoProducerCost !== 0 && (
                <View style={[styles.listItem, { marginTop: 20 }]}>
                  <View style={styles.listTitle}>
                    <Text style={styles.bold}>COPRODUTORES NACIONAIS</Text>
                  </View>
                  <Text style={styles.listText}>
                    <NumberFormat
                      value={
                        costAndFinancialInformation?.nationalCoProducerCost
                      }
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={"R$"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </Text>
                </View>
              )}
              {costAndFinancialInformation?.costPerEpisode !== 0 && (
                <View style={[styles.listItem, { marginTop: 20 }]}>
                  <View style={styles.listTitle}>
                    <Text style={styles.bold}>
                      PROPOSTA ESTIMATIVA DE CUSTO POR EPISÓDIO
                    </Text>
                  </View>
                  <Text style={styles.listText}>
                    <NumberFormat
                      value={costAndFinancialInformation?.costPerEpisode}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={"R$"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </Text>
                </View>
              )}
              {costAndFinancialInformation?.totalCost !== 0 && (
                <View style={[styles.listItem, { marginTop: 20 }]}>
                  <View style={styles.listTitle}>
                    <Text style={styles.bold}>
                      PROPOSTA ESTIMATIVA DE CUSTO TOTAL
                    </Text>
                  </View>
                  <Text style={styles.listText}>
                    <NumberFormat
                      value={costAndFinancialInformation?.totalCost}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={"R$"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </Text>
                </View>
              )}
              <Text style={[styles.locals, { marginTop: 20 }]}>{""}</Text>
              <Text style={styles.subTitle}>Dados da Produtora</Text>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>NÚMERO ANCINE</Text>
                </View>
                <Text style={styles.listText}>
                  {productionCompanies?.[0]?.ancine}
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>RAZÃO SOCIAL</Text>
                </View>
                <Text style={styles.listText}>
                  {productionCompanies?.[0]?.corporateName}
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>NOME FANTASIA</Text>
                </View>
                <Text style={styles.listText}>
                  {productionCompanies?.[0]?.name}
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>CNPJ</Text>
                </View>
                <Text style={styles.listText}>
                  {productionCompanies?.[0]?.cnpj}
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>INSCRIÇÃO ESTADUAL</Text>
                </View>
                <Text style={styles.listText}>
                  {productionCompanies?.[0]?.stateRegistration}
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>NOME DO RESPONSÁVEL</Text>
                </View>
                <Text style={styles.listText}>{owner?.name}</Text>
              </View>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>EMAIL</Text>
                </View>
                <Text style={styles.listText}>{owner?.email}</Text>
              </View>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>TELEFONE</Text>
                </View>
                <Text style={styles.listText}>
                  {productionCompanies?.[0]?.phones?.[0]?.number}
                </Text>
              </View>
              <View style={[styles.listItem, { marginTop: 20 }]}>
                <View style={styles.listTitle}>
                  <Text style={styles.bold}>ENDEREÇO</Text>
                </View>
                <View style={[styles.listText, { flexDirection: "column" }]}>
                  <Text>
                    {productionCompanies?.[0]?.address?.streetName},{" "}
                    {productionCompanies?.[0]?.address?.number}
                  </Text>
                  <Text>{productionCompanies?.[0]?.address?.complement}</Text>
                  <Text>{productionCompanies?.[0]?.address?.district}</Text>
                  <Text>
                    {productionCompanies?.[0]?.address?.city} /{" "}
                    {productionCompanies?.[0]?.address?.state}
                  </Text>
                  <Text>{productionCompanies?.[0]?.address?.postalCode}</Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </RenderPDF>
  );
};

export default ExternalPDF;

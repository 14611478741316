import React from "react";
import SheetActivityItem from "../SheetActivityItem/SheetActivityItem";
import "./styles/ActivitiesAttachmentsList.scss";

const ActivitiesAttachmentsList = (props) => {
  const { activities, contentType } = props;

  return (
    <div id="ActivitiesAttachmentsList">
      {activities.map((item, index) => {
        return (
          <SheetActivityItem
            key={index}
            item={item}
            contentType={contentType}
          />
        );
      })}
    </div>
  );
};

export default ActivitiesAttachmentsList;

import React from "react";

const ResearchesIcon = (props) => {
  const { color = "#fff" } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 4.8125V19.9375C16.5 21.0766 15.5766 22 14.4375 22H2.0625C0.923398 22 0 21.0766 0 19.9375V4.8125C0 3.6734 0.923398 2.75 2.0625 2.75H5.5C5.5 1.23363 6.73363 0 8.25 0C9.76637 0 11 1.23363 11 2.75H14.4375C15.5766 2.75 16.5 3.6734 16.5 4.8125ZM8.25 1.71875C7.68045 1.71875 7.21875 2.18045 7.21875 2.75C7.21875 3.31955 7.68045 3.78125 8.25 3.78125C8.81955 3.78125 9.28125 3.31955 9.28125 2.75C9.28125 2.18045 8.81955 1.71875 8.25 1.71875ZM12.375 6.61719V5.75781C12.375 5.68944 12.3478 5.62386 12.2995 5.57551C12.2511 5.52716 12.1856 5.5 12.1172 5.5H4.38281C4.31444 5.5 4.24886 5.52716 4.20051 5.57551C4.15216 5.62386 4.125 5.68944 4.125 5.75781V6.61719C4.125 6.68556 4.15216 6.75114 4.20051 6.79949C4.24886 6.84784 4.31444 6.875 4.38281 6.875H12.1172C12.1856 6.875 12.2511 6.84784 12.2995 6.79949C12.3478 6.75114 12.375 6.68556 12.375 6.61719Z"
        transform="translate(4 1)"
        fill={color}
      />
    </svg>
  );
};

export default ResearchesIcon;

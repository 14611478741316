const getReduce = (action, state) => {
  return {
    ...state,
    ...{ [action.key]: { ...state[action.key], result: action.payload } },
  };
};

const loadingReduce = (action, state) => {
  const newState = { ...state };
  newState[action.payload.subject]["loading"] = action.payload.active;
  return newState;
};

const setState = (action, state) => {
  const newState = { ...state, [action.key]: action.payload };
  return newState;
};

const removeConflictCharacter = (action, state) => {
  const dataListWithoutCharacter = state.list.result.data.map((d) => ({
    ...d,
    conflictingItems: d.conflictingItems.filter(
      (c) => c.characterId !== action.payload.characterId
    ),
  }));

  const newListResult = {
    ...state.list.result,
    data: dataListWithoutCharacter,
  };

  const dataListAllWithoutCharacter = state.listAll.result.data.map((d) => ({
    ...d,
    conflictingItems: d.conflictingItems.filter(
      (c) => c.characterId !== action.payload.characterId
    ),
  }));

  const newListAllResult = {
    ...state.listAll.result,
    data: dataListAllWithoutCharacter,
  };

  const newState = {
    ...state,
    list: {
      ...state.list,
      result: newListResult,
    },
    listAll: {
      ...state.listAll,
      result: newListAllResult,
    },
  };

  return newState;
};

export default (state, action) => {
  let _state = {
    loading: () => loadingReduce(action, state),
    list: () => getReduce(action, state),
    filter: () => setState(action, state),
    date: () => setState(action, state),
    removeCharacter: () => removeConflictCharacter(action, state),
  };

  return _state[action.action]();
};

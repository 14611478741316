import React, { useState, useContext } from "react";
import "./style/CreateAndUpdateBlockPlanning.scss";
import { postService, putService } from "../../../services/script-api";
import FormAndFields from "./FormAndFields/FormAndFields";
import AppContext from "../../../context/appContext";
import Contracts from "../Contracts/Contracts";
import { sendEvent } from "../../../services/GoogleAnalytics/methods";

const CreateAndUpdateBlockPlanning = (props) => {
  const { action, planningData, contentId, getPlanning, chapterOrEpisode } =
    props;

  const appContext = useContext(AppContext);

  const {
    scheduleDelivery,
    rescheduledDelivery,
    scheduleRelease,
    rescheduledRelease,
    plannedContentSight,
    plannedProdutionSight,
    plannedArtisticApproval,
    plannedChannelFeedback,
  } = { ...action.data };

  const initialStateCreate = {
    numberChaptersBlock: 6,
    scheduleDelivery: "",
    scheduleRelease: "",
  };

  const initialStateEdit = {
    scheduleDelivery: scheduleDelivery ? new Date(scheduleDelivery) : "",
    rescheduledDelivery: rescheduledDelivery
      ? new Date(rescheduledDelivery)
      : "",
    scheduleRelease: scheduleRelease ? new Date(scheduleRelease) : "",
    rescheduledRelease: rescheduledRelease ? new Date(rescheduledRelease) : "",
    plannedContentSight: plannedContentSight ? new Date(plannedContentSight) : "",
    plannedProdutionSight: plannedProdutionSight ? new Date(plannedProdutionSight) : "",
    plannedArtisticApproval: plannedArtisticApproval ? new Date(plannedArtisticApproval) : "",
    plannedChannelFeedback: plannedChannelFeedback ? new Date(plannedChannelFeedback) : "",
  };

  const initialStateTypes = {
    create: initialStateCreate,
    edit: initialStateEdit,
  };

  const [state, setState] = useState(initialStateTypes[action.type]);

  const handleChangeDate = (date, type) => {
    setState({
      ...state,
      [type]: date,
    });
  };

  const handleStartDates = {
    create: {
      scheduleDelivery: "",
      scheduleRelease: state.scheduleDelivery,
    },
    edit: {
      scheduleDelivery: "",
      rescheduledDelivery: state.scheduleDelivery,
      scheduleRelease: state.scheduleDelivery,
      rescheduledRelease:
        state.scheduleRelease ||
        state.rescheduledDelivery ||
        state.scheduleDelivery,
      plannedContentSight: "",
      plannedProdutionSight: "",
      plannedArtisticApproval: "",
      plannedChannelFeedback: "",
    },
  };

  const handleMaxDates = {
    create: {
      scheduleDelivery: state.scheduleRelease,
      scheduleRelease: "",
    },
    edit: {
      scheduleDelivery:
        state.rescheduledDelivery ||
        state.scheduleRelease ||
        state.rescheduledRelease,
      rescheduledDelivery: state.rescheduledRelease,
      scheduleRelease: state.rescheduledRelease,
      rescheduledRelease: "",
      plannedContentSight: "",
      plannedProdutionSight: "",
      plannedArtisticApproval: "",
      plannedChannelFeedback: "",
    },
  };

  const handleCreateSubmit = (ev) => {
    ev.preventDefault();
    appContext.setShowGlassLoading(true);

    const postObj = Contracts.postBlock({
      ...state,
      contentId,
      blockNumber: planningData?.nextValidBlock,
      episodeNumber: planningData?.nextValidEpisode,
      scheduleDelivery: state.scheduleDelivery || null,
      scheduleRelease: state.scheduleRelease || null,
      numberChaptersBlock:
        chapterOrEpisode === "Capítulos" ? state.numberChaptersBlock : planningData?.totalEpisodes + 1,
    });

    postService(planningData.endpoints.post, [postObj])
      .then(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(planningData.messages.postMessages.success);
        appContext.setCurrentStepBox("closed");
        sendEvent(
          planningData.analytics.post.category,
          planningData.analytics.post.action
        );
        getPlanning();
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(
          planningData.messages.postMessages.error,
          "error"
        );
      });
  };

  const handleEditSubmit = (ev) => {
    ev.preventDefault();
    appContext.setShowGlassLoading(true);

    const putObj = Contracts.PutBlock({
      ...state,
      contentId,
      id: action?.data?.id,
      blockNumber: action?.data?.blockNumber,
      episodeNumber: action?.data?.episodeNumber,
      scheduleDelivery: state.scheduleDelivery || null,
      rescheduledDelivery: state.rescheduledDelivery || null,
      scheduleRelease: state.scheduleRelease || null,
      rescheduledRelease: state.rescheduledRelease || null,
      plannedContentSight: state.plannedContentSight || null,
      plannedProdutionSight: state.plannedProdutionSight || null,
      plannedArtisticApproval: state.plannedArtisticApproval || null,
      plannedChannelFeedback: state.plannedChannelFeedback || null,
    });

    putService(planningData.endpoints.put, putObj)
      .then(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(planningData.messages.putMessages.success);
        appContext.setCurrentStepBox("closed");
        sendEvent(
          planningData.analytics.post.category,
          planningData.analytics.post.action,
          planningData.analytics.post.label
        );
        getPlanning();
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(
          planningData.messages.putMessages.error,
          "error"
        );
      });
  };

  const setAction = {
    create: (ev) => handleCreateSubmit(ev),
    edit: (ev) => handleEditSubmit(ev),
  };

  return (
    <div id="CreateAndUpdateBlockPlanning">
      <FormAndFields
        action={action}
        handleChangeDate={handleChangeDate}
        handleSubmit={setAction[action.type]}
        values={state}
        handleValidate={true}
        handleStartDates={handleStartDates}
        handleMaxDates={handleMaxDates}
        chapterOrEpisode={chapterOrEpisode}
        planningData={planningData}
        isChapter={chapterOrEpisode === "Capítulos"}
      />
    </div>
  );
};

export default CreateAndUpdateBlockPlanning;

import React from "react";
import "./styles/EvaluationPaper.scss";

const EvaluationPaper = (props) => {
  const { children } = props;

  return <div className="evaluationPaper">{children}</div>;
};

export default EvaluationPaper;

import React from 'react';
import ReorderButton from "../../../components/ReorderButton/ReorderButton";
import "./styles/HeaderListItem.scss"

const HeaderListItem = () => {
  return (
    <ul id="HeaderListItem">
      <li className="title">
        <ReorderButton
          text="TÍTULO/PRODUTORA"
          color="#0098CD"
          // event={() => handleReorderFilter("Title.Exact")}
        />
      </li>
      <li className="date">
        <ReorderButton
          text="DATA"
          color="#0098CD"
          // event={() => handleReorderFilter("Title.Exact")}
        />
      </li>
      <li className="status">STATUS</li>
      <li className="analise">
        <ReorderButton
          text="PRAZO P/ANÁLISE"
          color="#0098CD"
          // event={() => handleReorderFilter("Title.Exact")}
        />
      </li>
    </ul>
  );
};

export default HeaderListItem;
import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import UserContext from "./context/userContext";

export default (props) => {
  const { children, check } = props;
  const userContext = useContext(UserContext);

  return userContext.currentIsActive() /*&& !!userContext.user*/ ? (
    check === undefined || check ? (
      children
    ) : (
      <Redirect to={{ pathname: "/acesso-negado" }} />
    )
  ) : (
    window.location.pathname.indexOf("logout") < 0 && (
      <Redirect
        to={{ pathname: "/auth", state: { redirect: window.location.href } }}
      />
    )
  );
};

import React from "react";
import "./styles/ThesaurusSelect.scss";
import Select from "react-select";
import AsyncLoading from "../AsyncLoading/AsyncLoading";
import OptionThesaurus from "../OptionThesaurus/OptionThesaurus";

const ThesaurusSelect = (props) => {
  const {
    options,
    onChange,
    noOptionsMessage,
    loadingMessage,
    placeholder,
    value,
    defaultValue,
    isMulti = false,
    isClearable = false,
  } = props;

  const filterOption = (option, inputValue) => {
    const { label, data } = option;
    const remissives = data.remissives.map((item) => item.toLowerCase());
    const input = inputValue.toLowerCase();
    return (
      label.toLowerCase().includes(input) ||
      !!remissives.filter((remissive) => remissive.includes(input)).length
    );
  };

  return (
    <div className="thesaurusSelect">
      <Select
        className="reactSelect"
        classNamePrefix="reactSelect"
        options={options}
        filterOption={filterOption}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        components={{
          LoadingIndicator: () => <AsyncLoading />,
          Option: OptionThesaurus,
        }}
        noOptionsMessage={() => noOptionsMessage}
        loadingMessage={() => loadingMessage}
        isMulti={isMulti}
        isClearable={isClearable}
      />
    </div>
  );
};

export default ThesaurusSelect;

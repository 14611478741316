import React, { useLayoutEffect, useContext } from "react";
import "./styles/Home.scss";
import Footer from "../../components/Footer/Footer";
import ScriptIcon from "../../components/VectorIcons/ScriptIcon";
import SearchInput from "../../components/SearchInput/SearchInput";
import AppContext from "../../context/appContext";
import ParameterizedSearchButton from "./ParameterizedSearchButton/ParameterizedSearchButton";
import ParameterizedSearchModal from "./ParameterizedSearchModal/ParameterizedSearchModal";
import { Helmet } from "react-helmet";

const Home = (props) => {
  const appContext = useContext(AppContext);

  useLayoutEffect(() => {
    appContext.setShowHeader(true);
    appContext.setShowFullHeader(false);
    appContext.setCurrentSearch("");
    appContext.setFilterSearch("");

    return () => {
      appContext.setShowFullHeader(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleParameterizedSearch = (status) => {
    appContext.setCurrentStepBox(status);
  };
  return (
    <div id="Home">
      <Helmet>
        <title>Script</title>
      </Helmet>
      <div className="mainContainer">
        <ScriptIcon width="245" />
        <SearchInput />
        <ParameterizedSearchButton
          onClick={() => toggleParameterizedSearch("parameterizedSearch")}
        />
        {appContext.current.step === "parameterizedSearch" && (
          <ParameterizedSearchModal
            open={appContext.current.step === "parameterizedSearch"}
            clear={() => toggleParameterizedSearch("closed")}
            history={props.history}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Home;

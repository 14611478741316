import React from "react";
import "./styles/DownloadAllSection.scss";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import DownloadIcon from "../../../components/VectorIcons/DownloadIcon";

const DownloadAllSection = (props) => {
  const { message, action } = props;

  return (
    <div className="downloadAllSection">
      <span className="message">{message}</span>

      <SimpleButton
        text="Baixar todos os anexos"
        classButton="buttonBlue medium"
        clickEvent={action}
      >
        <DownloadIcon color="#fff" />
      </SimpleButton>
    </div>
  );
};

export default DownloadAllSection;

import React from 'react'
import RoundBackButton from '../../../components/RoundBackButton/RounbBackButton'
import StepCounter from '../../../components/StepCounter/StepCounter'
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb'

const ContentHeaderRegister = (props) => {

  const { currentStep, setCurrentStep, title, type } = props

  return (
    <Breadcrumb>
        <div className="registerContainer">
          <div>
            {currentStep > 1 && (
              <div
                className="backButtonContainer"
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                <RoundBackButton color="#17B4E1" backgroundColor="#24233D" />
              </div>
            )}

            <div className="registerTitle">
              <h2>
                {currentStep > 1
                  ? `${title}` : `Cadastrar: Projeto de ${type.charAt(0).toUpperCase()}${type.slice(1)}`}
              </h2>
              {currentStep > 1 && <span>{`${type.charAt(0).toUpperCase()}${type.slice(1)}`}</span>}
            </div>
          </div>
          <StepCounter currentStep={currentStep} maxStep={3} />
        </div>
      </Breadcrumb>
  )
}

export default ContentHeaderRegister
import React from "react";
import "./styles/DeliveryAndRelease.scss";

const DeliveryAndRelease = (props) => {
  const {
    showDates,
    scheduleDelivery,
    rescheduledDelivery,
    lastAttachmentReceiveDate,
    scheduleRelease,
    rescheduledRelease,
    lastAttachmentReleaseDate,
  } = props;

  return (
    <>
      <div className="datesHeader cell">
        <span className="dateType headerItem">Entrega</span>
        <div className="datesNames">
          <span className="dateName">
            Planejado
            {showDates && <span className="dateValue">{scheduleDelivery}</span>}
          </span>
          <span className="dateName">
            Replanejado{" "}
            {showDates && (
              <span className="dateValue">{rescheduledDelivery}</span>
            )}
          </span>
          <span className="dateName">
            Entregue{" "}
            {showDates && (
              <span className="dateValue">{lastAttachmentReceiveDate}</span>
            )}
          </span>
        </div>
      </div>

      <div className="datesHeader cell">
        <span className="dateType headerItem">Liberação</span>
        <div className="datesNames">
          <span className="dateName">
            Planejado{" "}
            {showDates && <span className="dateValue">{scheduleRelease}</span>}
          </span>
          <span className="dateName">
            Replanejado{" "}
            {showDates && (
              <span className="dateValue">{rescheduledRelease}</span>
            )}
          </span>
          <span className="dateName">
            Liberado{" "}
            {showDates && (
              <span className="dateValue">{lastAttachmentReleaseDate}</span>
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default DeliveryAndRelease;

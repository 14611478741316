import React, { useContext, useState, useEffect } from "react";
import "./styles/ResearchDetails.scss";
import { putService } from "../../../services/script-api";
import AppContext from "../../../context/appContext";
import SheetContext from "../../../context/sheetContext";
import SwitchText from "../../../components/SwitchText/SwitchText";
import FieldsetSelect from "../../../components/FieldsetSelect/FieldsetSelect";
import DeleteIcon from "../../../components/VectorIcons/DeleteIcon";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import Button from "../../../components/Button/Button";
import ResearchSelect from "./ResearchSelect/ResearchSelect";
import ResearchAsyncSelect from "./ResearchAsyncSelect/ResearchAsyncSelect";

const peoples = {
  1: "fromAnalystIds",
  2: "fromAreaIds",
  3: "fromResearcherIds",
  4: "toCreatorIds",
  5: "toAreaIds",
};
const listsObj = {
  fromAnalystIds: [],
  fromAreaIds: [],
  fromResearcherIds: [],
  toCreatorIds: [],
  toAreaIds: [],
};

const ResearchDetails = (props) => {
  const { data, origins, areaThesaurus } = props;
  const { fromAnalysts, fromAreas, fromResearchers, toAreas, toCreators } =
    data;

  const appContext = useContext(AppContext);
  const sheetContext = useContext(SheetContext);

  const [stateOrigin, setStateOrigin] = useState(data.origin);
  const [stateResearchFrom, setStateResearchFrom] = useState([]);
  const [stateResearchTo, setStateResearchTo] = useState([]);
  const [statePeople, setStatePeople] = useState([]);

  const compileValues = (value) => {
    const compiled = [];
    value.forEach((item) => {
      !!item.value && compiled.push({ value: item.id, label: item.value });
    });
    return compiled;
  };

  const researchedFromMock = [
    { id: 1, name: "Analista(s)", values: compileValues(fromAnalysts) },
    { id: 2, name: "Área(s)", values: compileValues(fromAreas) },
    { id: 3, name: "Pesquisador(es)", values: compileValues(fromResearchers) },
  ];

  const researchToMock = [
    { id: 4, name: "Criador(es)", values: compileValues(toCreators) },
    { id: 5, name: "Área(s)", values: compileValues(toAreas) },
  ];

  const nextOptionSelected = (state, options) => {
    let currentUsedOptions = [];
    state.forEach((item) => currentUsedOptions.push(item.type));

    let unusedOptions = options.filter((option) => {
      return currentUsedOptions.indexOf(option.id) === -1;
    });

    if (!!unusedOptions.length > 0) return unusedOptions[0].id;
  };

  useEffect(() => {
    const fromValues = [];
    const toValues = [];

    researchedFromMock.forEach((item) => {
      !!item.values.length &&
        fromValues.push({ type: item.id, peopleId: item.values });
    });

    researchToMock.forEach((item) => {
      !!item.values.length &&
        toValues.push({ type: item.id, peopleId: item.values });
    });

    const handleFromValues = !!fromValues.length
      ? fromValues
      : [
          {
            peopleId: 0,
            type: nextOptionSelected(stateResearchFrom, researchedFromMock),
          },
        ];

    const handleToValues = !!toValues.length
      ? toValues
      : [
          {
            peopleId: 0,
            type: nextOptionSelected(stateResearchTo, researchToMock),
          },
        ];

    setStateResearchFrom(handleFromValues);
    setStateResearchTo(handleToValues);
    // eslint-disable-next-line
  }, [data]);

  const handleOrigin = (id) => setStateOrigin(id);

  const compileNewOption = (options) => {
    return {
      id: options[0].id,
      name: options[0].name,
    };
  };

  const filterOptions = (position, state, options) => {
    let compiled = [];
    let currentUsedOptions = [];

    let currentPositionOption = options.filter(
      (item) => item.id === state[position].type
    );
    state.forEach((item) => currentUsedOptions.push(item.type));

    let unusedOptions = options.filter(
      (option) => currentUsedOptions.indexOf(option.id) === -1
    );
    compiled =
      currentPositionOption.length > 0
        ? [...unusedOptions, compileNewOption(currentPositionOption)]
        : unusedOptions;

    return compiled;
  };

  const handleChangePeopleType = (ev, state, setState, index) => {
    const newState = [...state];
    newState[index].type = parseInt(ev.target.value);
    newState[index].peopleId = [];
    setState(newState);
  };

  const handleAddResearchFrom = () => {
    const newState = [...stateResearchFrom];
    newState.push({
      peopleId: 0,
      type: nextOptionSelected(stateResearchFrom, researchedFromMock),
    });
    setStateResearchFrom(newState);
  };

  const handleAddResearchTo = () => {
    const newState = [...stateResearchTo];
    newState.push({
      peopleId: 0,
      type: nextOptionSelected(stateResearchTo, researchToMock),
    });
    setStateResearchTo(newState);
  };

  const handleChangePeopleId = (_data, index, state, setState) => {
    const peopleId = [];
    const newState = [...state];

    if (!!_data) _data.forEach((item) => peopleId.push(item));
    newState[index].peopleId = peopleId;
    setState(newState);
  };

  const removeContentOriginalField = (item, state, setState) => {
    const newState = [...state];
    let n = newState.filter((i) => i !== item);
    setState(n);
  };

  const compilePeoples = (key) => {
    const compileToArray = [];

    statePeople.forEach((item, index) => {
      !!item.peopleId.length &&
        item.peopleId.forEach((people) =>
          compileToArray.push({
            peopleId: people.value,
            type: statePeople[index].type,
          })
        );
    });
    compileToArray.forEach((item) =>
      listsObj[peoples[item.type]].push(item.peopleId)
    );

    return listsObj[key];
  };

  const researchDetailsIsValid = () =>
    !!stateOrigin && statePeople.every((item) => !!item.peopleId.length);

  const isPeopleIds = (people) =>
    !!compilePeoples(people).length ? compilePeoples(people) : [];

  useEffect(() => {
    const newState = [...stateResearchFrom, ...stateResearchTo];
    setStatePeople(newState);
    // eslint-disable-next-line
  }, [stateResearchFrom, stateResearchTo]);

  const putResearch = (e) => {
    e.preventDefault();

    const putObject = {
      id: data.id,
      type: data.type,
      subCategoryId: data.subCategory.id,
      origin: stateOrigin,
      fromAnalystIds: isPeopleIds("fromAnalystIds"),
      fromAreaIds: isPeopleIds("fromAreaIds"),
      fromResearcherIds: isPeopleIds("fromResearcherIds"),
      toCreatorIds: isPeopleIds("toCreatorIds"),
      toAreaIds: isPeopleIds("toAreaIds"),
      title: data.title,
    };

    appContext.setShowGlassLoading(true);

    putService("researches", putObject)
      .then((res) => {
        appContext.setShowGlassLoading(false);
        appContext.setCurrentStepBox("");
        sheetContext._getProjectSheetResearch(props.id, "researches");
        appContext.sendMessage(
          "Ficha de Pesquisa editada com sucesso!",
          "success"
        );
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Erro ao editar a ficha de Pesquisa.", "error");
      });
  };

  return (
    <div className="researchDetails">
      <form className="detailsForm" onSubmit={(ev) => putResearch(ev)}>
        <div className="formField origin">
          <SwitchText
            label="Origem"
            data={origins}
            handleChange={handleOrigin}
            value={data.origin}
            size="small"
          />
        </div>

        <div className="formFieldColumn">
          <label className="fieldLabel">Pesquisado por</label>

          {stateResearchFrom.map((item, index) => {
            return (
              <div className="formField" key={index}>
                <div
                  className={`contentOriginContainer ${
                    stateResearchFrom.length > 1 ? "showDeleteIcon" : ""
                  }`}
                >
                  <FieldsetSelect
                    placeholder=""
                    data={filterOptions(
                      index,
                      stateResearchFrom,
                      researchedFromMock
                    )}
                    selected={stateResearchFrom[index].type}
                    handleChange={(ev) =>
                      handleChangePeopleType(
                        ev,
                        stateResearchFrom,
                        setStateResearchFrom,
                        index
                      )
                    }
                    msgError="Este campo é obrigatório"
                    endpoint="dramaturgies"
                  />

                  {item.type === 1 && (
                    <ResearchAsyncSelect
                      item={item}
                      index={index}
                      handleChange={(item) =>
                        handleChangePeopleId(
                          item,
                          index,
                          stateResearchFrom,
                          setStateResearchFrom
                        )
                      }
                      endPoint="users/analysts"
                    />
                  )}

                  {item.type === 2 && (
                    <ResearchSelect
                      data={areaThesaurus}
                      item={item}
                      handleChange={(item) =>
                        handleChangePeopleId(
                          item,
                          index,
                          stateResearchFrom,
                          setStateResearchFrom
                        )
                      }
                    />
                  )}

                  {item.type === 3 && (
                    <ResearchAsyncSelect
                      item={item}
                      handleChange={(item) =>
                        handleChangePeopleId(
                          item,
                          index,
                          stateResearchFrom,
                          setStateResearchFrom
                        )
                      }
                      endPoint="integrations/stars"
                      index={index}
                    />
                  )}

                  {stateResearchFrom.length > 1 && (
                    <div
                      className="deleteIcon"
                      onClick={() =>
                        removeContentOriginalField(
                          item,
                          stateResearchFrom,
                          setStateResearchFrom
                        )
                      }
                    >
                      <DeleteIcon />
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          {stateResearchFrom.length < researchedFromMock.length && (
            <div className="dashedBox" onClick={() => handleAddResearchFrom()}>
              <span>+ Adicionar outro</span>
            </div>
          )}
        </div>

        <div className="formFieldColumn">
          <label className="fieldLabel">Para quem é esta pesquisa?</label>

          {stateResearchTo.map((item, index) => {
            return (
              <div className="formField" key={index}>
                <div
                  className={`contentOriginContainer ${
                    stateResearchTo.length > 1 ? "showDeleteIcon" : ""
                  }`}
                >
                  <FieldsetSelect
                    placeholder=""
                    data={filterOptions(index, stateResearchTo, researchToMock)}
                    selected={stateResearchTo[index].type}
                    handleChange={(ev) =>
                      handleChangePeopleType(
                        ev,
                        stateResearchTo,
                        setStateResearchTo,
                        index
                      )
                    }
                    msgError="Este campo é obrigatório"
                    endpoint="dramaturgies"
                  />

                  {item.type === 4 && (
                    <ResearchAsyncSelect
                      item={item}
                      handleChange={(item) =>
                        handleChangePeopleId(
                          item,
                          index,
                          stateResearchTo,
                          setStateResearchTo
                        )
                      }
                      endPoint="integrations/stars"
                      index={index}
                    />
                  )}

                  {item.type === 5 && (
                    <ResearchSelect
                      data={areaThesaurus}
                      item={item}
                      handleChange={(item) =>
                        handleChangePeopleId(
                          item,
                          index,
                          stateResearchTo,
                          setStateResearchTo
                        )
                      }
                    />
                  )}

                  {stateResearchTo.length > 1 && (
                    <div
                      className="deleteIcon"
                      onClick={() =>
                        removeContentOriginalField(
                          item,
                          stateResearchTo,
                          setStateResearchTo
                        )
                      }
                    >
                      <DeleteIcon />
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          {stateResearchTo.length < researchToMock.length && (
            <div className="dashedBox" onClick={() => handleAddResearchTo()}>
              <span>+ Adicionar outro</span>
            </div>
          )}
        </div>

        <BoxFooter>
          <Button
            text="Salvar"
            toValidate={true}
            isValidForm={researchDetailsIsValid()}
            classButton="buttonGreen large"
            typeButton="submit"
            dataQA="button-prospect-new-save"
          />
        </BoxFooter>
      </form>
    </div>
  );
};

export default ResearchDetails;

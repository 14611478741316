import React, { useContext } from 'react'
import './styles/Option.scss'
import TypeIconReturner from '../../TypeIconReturner/TypeIconReturner'
import AppContext from '../../../context/appContext'

const Option = ({ children, innerRef, innerProps, isFocused, ...props }) => {
  const appContext = useContext(AppContext)

  return (
    <div
      className={`associateFieldsetOption ${isFocused ? 'isFocused' : ''}`}
      ref={innerRef}
      {...innerProps}
    >
      <div className="typeIconContainer">
        <TypeIconReturner type={props.data.type} responsiveIcon={true}/>
      </div>

      <div className="optionInfo">
        <span className="optionName">{props.data.label}</span>

        <div className="creators">
          {props.data.type !== 'Pesquisa'
            ? (
              <React.Fragment>
                {
                  !!props.data.creators.length && props.data.creators.map((creator, index) => {
                    return (
                      <div className="creator" key={index}>
                        <span>{creator.name}</span>
                        {index + 1 < props.data.creators.length && (
                          <span className="separator">|</span>
                        )}
                      </div>
                    )
                  })
                }
                {!props.data.creators.length && '-'}
              </React.Fragment>)
            : !!props.data.researchers.length &&
            props.data.researchers.map((researcher, index) => {
              return (
                <div className="researcher" key={index}>
                  <span>{researcher}</span>
                  {index + 1 < props.data.researchers.length && (
                    <span className="separator">|</span>
                  )}
                </div>
              )
            })}
        </div>

        <span className="type">
          {props.data.type === 'Pesquisa' ?
            `${props.data.researchCategory} • ${props.data.researchSubCategory}`
            : !!props.data.format}

          <span>
            {props.data.format === "Novela"
            ? `${props.data.format} • ${props.data.soapOperaSchedule}`
            : !!props.data.format && props.data.format}
          </span>
        </span>

        <span className="receivedAt">
          {!!props.data.receivedAt &&
          appContext.formatDate(
            appContext.convertDatePtBr(props.data.receivedAt)
          )}
        </span>
      </div>
    </div>
  )
}

export default Option

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NextArrowIcon from "../../../../components/VectorIcons/NextArrowIcon";
import "./styles/Carousel.scss";

const Carousel = (props) => {
  const { children, dots, speed, infinite, slidesToShow, slidesToScroll } =
    props;

  const NextArrow = (props) => {
    const { className, onClick } = props;

    return (
      <div className={className} onClick={onClick}>
        <NextArrowIcon color="#767885" />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, onClick } = props;

    return (
      <div className={className} onClick={onClick}>
        <NextArrowIcon color="#767885" />
      </div>
    );
  };

  const settings = {
    dots: dots,
    speed: speed,
    infinite: infinite,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 830,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return <Slider {...settings}>{children}</Slider>;
};

export default Carousel;

import React from "react";

const PowerIcon = (props) => {
  const { color = "#EC1045" } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.36 6.64a9 9 0 11-12.73 0M12 2v10"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export default PowerIcon;

import React, { useContext } from "react";
import "./styles/SearchNavigation.scss";
import Masks from "../VectorIcons/Masks";
import Microphone from "../VectorIcons/Microphone";
import ResearchesIcon from "../VectorIcons/ResearchesIcon";
import AppContext from "../../context/appContext";
import LiteraryWorkIcon from '../VectorIcons/LiteraryWorkIcon'

const SearchNavigation = (props) => {
  const appContext = useContext(AppContext);

  const { contentTypesHeader } = props;

  const paths = {
    dramaturgia: "dramaturgies",
    variedades: "varieties",
    pesquisa: "researches",
    "obra-literaria": "literaryworks",
    "obra literária": "literaryworks",
    todos: "contents",
  };

  const isGetAll = (type) =>
    type === "Todos"
      ? { ...appContext.filter, Term: appContext.searchTerm, PageNumber: 1 }
      : { ...appContext.filter, title: appContext.searchTerm, PageNumber: 1 };

  const setFilter = (type) => {
    const filterObj = isGetAll(type);
    delete filterObj["OrderBy.Column"];
    delete filterObj["OrderBy.Direction"];
    appContext.setCurrentSearch(type);
    appContext.setFilterSearch(filterObj);
    appContext.setCurrentTypePath(paths[type.toLowerCase()]);
  };

  const icons = (type) => {
    let list = {
      Dramaturgia: (
        <Masks
          color={`${
            type === appContext.currentTypeSearch ? "#fff" : "#adafef"
          }`}
        />
      ),
      Variedades: (
        <Microphone
          color={`${
            type === appContext.currentTypeSearch ? "#fff" : "#adafef"
          }`}
        />
      ),
      Pesquisa: (
        <ResearchesIcon
          color={`${
            type === appContext.currentTypeSearch ? "#fff" : "#adafef"
          }`}
        />
      ),
      "Obra Literária": (
        <LiteraryWorkIcon
          color={`${
            type === appContext.currentTypeSearch ? "#fff" : "#adafef"
          }`}
        />
      )
    };

    return list[type];
  };

  const ContentTypesFree = (props) => {
    const { contents } = props;

    return (
      <div className="searchesNavigationContainer">
        {!!contents &&
          contents.map((item, i) => {
            return (
              <div
                className={`type ${
                  appContext.currentTypeSearch === item.type ? "active" : ""
                }`}
                onClick={() =>
                  contents.length > 1 ? setFilter(item.type) : () => {}
                }
                key={i}
              >
                {icons(item.type)}
                <span className="typeLabel">{item.type}</span>
                <span className="totalLabel">
                  <span>({item.pagingInfo.totalItems})</span>
                </span>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className="searchNavigation">
      <ContentTypesFree contents={contentTypesHeader} />
    </div>
  );
};

export default SearchNavigation;

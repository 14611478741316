import React, { useEffect, useState } from 'react'
import './styles/CommomParameters.scss'
import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatepicker'
import Fieldset from '../../../../components/Fieldset/Fieldset'
import FieldsetSelect from '../../../../components/FieldsetSelect/FieldsetSelect'
import SmallLoading from '../../../../components/SmallLoading/SmallLoading'
import { getService } from '../../../../services/script-api'
import ContentCreateProspects from '../../../../components/ContentCreateProspects/ContentCreateProspects'
import ContentCreatorProductCompanies
  from '../../../../components/ContentCreatorProductCompanies/ContentCreatorProductCompanies'

const CommomParameters = (props) => {
  const {
    type,
    handleChange,
    handleChangeDate,
    origins,
    currentOrigin,
    productionCompanies,
    getOptionsProductionCompanies,
    getOptionsCreators,
    filter,
    stateClear,
    setStateClear,
  } = props

  const handleChangeDateFilter = (date, name) => handleChangeDate(date, name)

  const [stateRecommendation, setStateRecommendation] = useState({
    loading: true,
    list: [],
  })

  const getRecommendation = () => {
    getService('projects/data/recommendations').then((res) => {
      setStateRecommendation({
        loading: false,
        list: res.data,
      })
    })
  }

  useEffect(() => {
    getRecommendation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id="CommomParameters">
      <div className={`parameter ${type !== 'Pesquisa' ? 'medium' : 'small'}`}>
        <Fieldset
          label="Título"
          type="text"
          name="title"
          placeholder="Digite uma palavra"
          value={filter.title || ''}
          handleChange={(e) => handleChange(e)}
        />
      </div>

      {type !== 'Pesquisa' && (
        <div className="parameter">
          <FieldsetSelect
            data={stateRecommendation.list}
            label="Recomendação"
            placeholder="Selecione"
            name="recommendationTypeIds"
            selected={filter.recommendationTypeIds}
            handleChange={(e) => handleChange(e)}
          />
        </div>
      )}

      {type !== 'Pesquisa' && (
        <div className="parameter large">
          <ContentCreateProspects
            getCreators={getOptionsCreators}
            label="Criado por:"
            placeholder="Digitar um nome e apertar ENTER"
            data={!!filter.creatorsLabel && filter.creatorsLabel.map( item => ({
              label: item.label,
              suggestedId: item.suggestedId,
              value: item.suggestedId,
              origin: item.origin,
              photo: item.photo,
              role: item.role
            }))}
          />
        </div>
      )}

      {type !== 'Pesquisa' && (
        <div className="parameter">
          {!origins.loading ? (
            <FieldsetSelect
              data={origins.data}
              label="Procedência"
              placeholder="Selecione"
              name="origins"
              handleChange={(e) => handleChange(e)}
              selected={currentOrigin}
            />
          ) : (
            <SmallLoading/>
          )}
        </div>
      )}

      <div className="parameter">
        <CustomDatePicker
          label="Recebido em"
          name="intervalStart"
          renderSide="left"
          handleChangeDate={(e) => handleChangeDateFilter(e, 'ReceivedAtBegin')}
          placeholderText="De"
          date={filter.ReceivedAtBegin}
          clear={stateClear}
          setClear={setStateClear}
        />
      </div>

      <div className="parameter">
        <CustomDatePicker
          label="&nbsp;"
          name="intervalEnd"
          renderSide="right"
          handleChangeDate={(e) => handleChangeDateFilter(e, 'ReceivedAtEnd')}
          placeholderText="Até"
          date={filter.ReceivedAtEnd}
          selected={filter.ReceivedAtEnd}
          clear={stateClear}
          setClear={setStateClear}
        />
      </div>

      {type !== 'Pesquisa' && currentOrigin === 'Mercado' && (
        <div className="parameter large">
          <ContentCreatorProductCompanies
						getProductionCompanies={getOptionsProductionCompanies}
						label="Produtora:"
						placeholder="Digitar um nome e apertar ENTER"
						data={!!productionCompanies && productionCompanies.map((item) => ({
							...item,
							label: item.label,
							value: item.value,
						}))}
					/>
        </div>
      )}
    </div>
  )
}

export default CommomParameters

import React, { useContext, useEffect, useState } from "react";
import "./styles/ParametersList.scss";
import AppContext from "../../../context/appContext";
import ListCount from "../../../components/ListCount/ListCount";
import _, { isArray } from "lodash";
import ParameterizedSearchModal from "../../Home/ParameterizedSearchModal/ParameterizedSearchModal";
import { getService } from "../../../services/script-api";

const Creators = (props) => {
  const { value } = props;
  return (
    <div>
      {value.map((e, index) => (
        <div key={index}>{e.Name || e.name || e.label}</div>
      ))}
    </div>
  );
};

const ParametersList = (props) => {
  const { parameters, hasParameters } = props;

  const appContext = useContext(AppContext);

  const [stateRecommendation, setStateRecommendation] = useState({
    loading: true,
    list: [],
  });

  const checkDate = (value) => (value < 10 ? "0" + value : value);

  const returnDate = (date) => {
    let d = new Date(date);
    let finalDate = `${checkDate(d.getDate())}/${checkDate(
      d.getMonth() + 1
    )}/${d.getFullYear()}`;

    return finalDate;
  };

  const getRecommendation = () => {
    getService("projects/data/recommendations").then((res) => {
      setStateRecommendation({
        loading: false,
        list: res.data,
      });
    });
  };

  const listItemParams = (label, value) => {
    let list = {
      CharacterIdentifications: { label: "Personagem", value },
      creators: { label: "Criador(es)", value: <Creators value={value} /> },
      ReceivedAtBegin: { label: "De", value: returnDate(value) },
      ReceivedAtEnd: { label: "Até", value: returnDate(value) },
      ProductionCompaniesLabel: {
        label: "Produtora(s)",
        value: <Creators value={value} />,
      },
      origins: { label: "Procedência", value },
      FormatId: {
        label: "Formato",
        value: parameters.listComplementaries?.FormatId?.label,
      },
      title: { label: "Título", value },
      recommendationTypeIds: {
        label: "Recomendação",
        value: stateRecommendation.list.filter(
          (e) => e.id === parseInt(value)
        )[0]?.value,
      },
    };

    return list[label];
  };

  const BlockParameter = (props) => {
    const { parameter } = props;
    const handleKey = parameter[0] === "ProductionCompaniesLabel" ? "ProductionCompanies" : parameter[0];

    return (
      <div className="parameterTag">
        <span className="parameterTerm">
          {listItemParams(parameter[0], parameter[1])?.value}
        </span>
        {!checkParameters() && (
          <div className="removeTag" onClick={() => removeKeyParams(handleKey)}>
            x
          </div>
        )}
      </div>
    );
  };

  const removeKeyParams = (key) => {
    let filter = { ...parameters };
    delete filter[key];
    delete filter[key + "Label"];

    if (key === "FormatId") delete filter.listComplementaries.FormatId;
    if (key === "CharacterIdentifications")
      delete filter.listComplementaries.character;
    appContext.setFilterSearch(filter);
  };

  const removeCharacterActivities = (id) => {
    let listFiltered = appContext.filter.listComplementaries[
      "Atividades / Características"
    ].filter((e) => e.label !== id);
    appContext.setFilterSearch({
      ...appContext.filter,
      characterActivities: appContext.filter.characterActivities.filter(
        (e) => e !== id
      ),
      listComplementaries: {
        ...appContext.filter.listComplementaries,
        "Atividades / Características": listFiltered,
      },
    });
  };

  const removeItemParams = (key, id) => {
    // REFACTOR
    if (key === "Atividades / Características") {
      removeCharacterActivities(id);
    } else {
      let filter = { ...parameters };
      filter.Complementaries = filter.Complementaries.filter(
        (item) => item !== id
      );
      filter.listComplementaries[key] = filter.listComplementaries[key].filter(
        (item) => item.id !== id
      );
      if (!filter.listComplementaries[key].length)
        delete filter.listComplementaries[key];
      appContext.setFilterSearch(filter);
    }
  };

  const removeComplementariesKeyParams = (key) => {
    let filter = { ...parameters };
    delete filter[key];
    delete filter.listComplementaries[key];
    appContext.setFilterSearch(filter);
  };

  useEffect(() => {
    getRecommendation();
    // eslint-disable-next-line
  }, []);

  const checkParameters = () => {
    const params = { ...parameters };
    delete params.creatorsLabel;
    delete params.ProductionCompaniesLabel;
    delete params.PageNumber;
    const paramsWithoutComplementaries = { ...params };
    delete paramsWithoutComplementaries.listComplementaries;
    delete paramsWithoutComplementaries.characterActivities;
    delete paramsWithoutComplementaries.Complementaries;
    delete paramsWithoutComplementaries.CharacterIdentifications;

    const parametersValuesArray = Object.values({
      ...paramsWithoutComplementaries,
      ...params.listComplementaries,
    });
    const validValues = parametersValuesArray.filter((item) =>
      isArray(item) ? item.length > 0 : !!item
    );

    if (validValues.length === 1) {
      if (isArray(validValues[0])) return validValues[0].length === 1;
    }
    return validValues.length === 1;
  };

  const RenderComplementaries = (props) => {
    const { item, index } = props;

    return (
      <div className="parameterContainer" key={index}>
        <span className="parameterLabel">{`${
          item[1][0]?.field || item[1]?.field
        }:`}</span>

        <span className="parameterTagList">
          {_.isArray(item[1]) && !!item[1].length ? (
            item[1]?.map((parameter, i) => {
              return (
                <div className="parameterTag" key={i}>
                  <div className="parameterTerm">{parameter.label}</div>
                  {!checkParameters() && (
                    <div
                      className="removeTag"
                      onClick={() => removeItemParams(item[0], parameter.value)}
                    >
                      x
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className="parameterTag">
              <span className="parameterTerm">{item[1].label}</span>
              {!checkParameters() && (
                <div
                  className="removeTag"
                  onClick={() => removeComplementariesKeyParams(item[0])}
                >
                  x
                </div>
              )}
            </div>
          )}
        </span>
      </div>
    );
  };

  const handleCounter = () => {
    const currentType = appContext.currentTypeSearch;
    return appContext.searchListHeader.pagingInfo.filter(
      (item) => item.type === currentType
    )[0]?.pagingInfo?.totalItems;
  };

  const toggleParameterizedSearch = (status) =>
    appContext.setCurrentStepBox(status);

  const handleTotalResultsMessage = (total) =>
    total > 1 ? "Resultados" : "Resultado";

  return (
    <div id="ParametersList">
      {appContext.isParameterized && hasParameters && (
        <>
          <div className="listInfo">
            <ListCount
              total={handleCounter()}
              text={handleTotalResultsMessage(handleCounter())}
            />
          </div>

          <div className="tagsList">
            {!!parameters &&
              Object.entries(parameters).map((parameter, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="parameterContainer">
                      {listItemParams(parameter[0])?.label &&
                        !!parameter[1] &&
                        !!parameter[1].length && (
                          <div className="parameterLabel">{`${
                            listItemParams(parameter[0])?.label
                          }:`}</div>
                        )}

                      {!!listItemParams(parameter[0]) &&
                        !!parameter[1] &&
                        !!parameter[1].length &&
                        parameter[0] !== "Complementaries" &&
                        parameter[0] !== "listComplementaries" && (
                          <BlockParameter parameter={parameter} />
                        )}

                      {parameter[0] === "listComplementaries" &&
                        Object.entries(parameter[1]).map((item, i) => {
                          return (
                            <React.Fragment key={i}>
                              {item[0] !== "character" && !!item[1].length && (
                                <RenderComplementaries
                                  item={item}
                                  index={i}
                                  key={i}
                                />
                              )}
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        </>
      )}

      <span
        className="changeParameters"
        onClick={() => appContext.setCurrentStepBox("parameterizedSearch")}
      >
        Alterar parâmetros
      </span>

      {appContext.current.step === "parameterizedSearch" && (
        <ParameterizedSearchModal
          open={appContext.current.step === "parameterizedSearch"}
          clear={() => toggleParameterizedSearch("closed")}
          history={props.history}
        />
      )}
    </div>
  );
};

export default ParametersList;

import React from "react";
import "./style/TotalCount.scss";

const TotalCount = (props) => {
  const { count, type } = props;

  return (
    <div className="totalCountActivities">
      <div className="container">
        Exibindo <span>{count}</span> atividades {type.toLowerCase()}
      </div>
    </div>
  );
};

export default TotalCount;

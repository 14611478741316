import React from "react";

const MoreWithCircle = (props) => {
  const { color = "#0098CD", size = 15 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 15 15"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-414 -326) translate(375 240) translate(40 75) translate(0 12)">
                <circle
                  cx="6.5"
                  cy="6.5"
                  r="7"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <rect width="1" height="7" x="6" y="3" fill={color} rx=".5" />
                <rect width="7" height="1" x="3" y="6" fill={color} rx=".5" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MoreWithCircle;

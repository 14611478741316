import React from "react";
import "./styles/TableLoading.scss";

const TableLoading = () => {
  return (
    <div className="loadingCircleContainer">
      <div className="loadingCircle" />
    </div>
  );
};

export default TableLoading;

import React, { useEffect, useState } from "react";
import "./styles/NumberInput.scss";
import MinusIcon from "../VectorIcons/MinusIcon";
import MoreIcon from "../VectorIcons/MoreIcon";

const NumberInput = (props) => {
  const {
    label,
    error,
    number,
    darkBackgroundColor,
    getVersion,
    errorMessage,
    maxNumber,
  } = props;

  const [state, setState] = useState(1);

  useEffect(() => {
    if (typeof number === "number") {
      setState(number);
    }
  }, [number]);

  const addNumber = () => {
    const newValue = state + 1;
    const canAdd = !maxNumber || newValue < maxNumber;
    if (canAdd) {
      setState(newValue);
      getVersion(newValue);
    }
  };

  const subtractNumber = () => {
    if (state > 1) {
      setState(state - 1);
      getVersion(state - 1);
    }
  };

  return (
    <div className={`numberInput ${error ? "error" : ""}`}>
      <span className="label">{label}</span>

      <div
        className={`numberInputContainer ${
          darkBackgroundColor ? "darkBackgroundColor" : ""
        }`}
      >
        <div className="inputButton minus" onClick={subtractNumber}>
          <MinusIcon color={state > 1 ? "#17B4E1" : "#C0C3F8"} />
        </div>

        <div className="numberValue">{state}</div>

        <div className="inputButton more" onClick={addNumber}>
          <MoreIcon color={!maxNumber || state + 1 < maxNumber ? "#17B4E1" : "#C0C3F8"} />
        </div>
      </div>

      {error && (
        <span className="errorMessage">
          {errorMessage ? errorMessage : "Nº não permitido"}
        </span>
      )}
    </div>
  );
};

export default NumberInput;

import React, { useState, useEffect, useRef } from "react";
import "./styles/ListHeader.scss";
import ReorderButton from "../../../components/ReorderButton/ReorderButton";
import FilterIcon from "../../../components/VectorIcons/FilterIcon";
import Filter from "../../../components/NoActivityAttachments/Filter/Filter";
import { getService } from "../../../services/script-api";
import { sendEvent } from "../../../services/GoogleAnalytics/methods";

const ListHeader = (props) => {
  const { direction, orderByReleaseDate, getFormatsFilter } = props;

  const [stateShowFormats, setStateShowFormats] = useState(false);
  const [formatsList, setFormatsList] = useState({
    dramaturgies: { loading: true, list: [] },
    varieties: { loading: true, list: [] },
  });
  const [stateFilterFormat, setStateFilterFormat] = useState([]);

  const filterFormatRef = useRef(null);

  const getIdFormats = (id) => {
    let newState = [...stateFilterFormat];
    if (newState.includes(id)) {
      newState = newState.filter((e) => e !== id);
    } else {
      newState.push(id);
    }

    setStateFilterFormat(newState);
  };

  const handleAnalytics = () => {
    const formats = [
      ...formatsList.dramaturgies.list,
      ...formatsList.varieties.list,
    ];
    const selectedFormats = formats.filter((item) =>
      stateFilterFormat.includes(item.id)
    );
    const formatsNames = selectedFormats
      .map((item) => item.name)
      .toString()
      .replace(/,/g, " - ");
    sendEvent("Textos Liberados", "Filtrar por formato", formatsNames);
  };

  const sendFilterFormat = () => {
    setStateShowFormats(false);
    getFormatsFilter(stateFilterFormat);
    handleAnalytics();
  };

  const getFormats = (type) => {
    getService(`${type}/thesaurus`, { origins: 22 }).then((res) => {
      const { data } = res;
      setFormatsList((prevState) => ({
        ...prevState,
        [type]: { loading: false, list: data },
      }));
    });
  };

  const handleClickOutside = (event) => {
    const current = filterFormatRef.current;
    if (current) {
      const clickedInside = current.contains(event.target);
      if (!clickedInside) setStateShowFormats(false);
    }
  };

  useEffect(() => {
    getFormats("dramaturgies");
    getFormats("varieties");
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div id="ListHeader">
      <li className="format" ref={filterFormatRef}>
        <span
          onClick={() => setStateShowFormats(!stateShowFormats)}
          className="formatFilter"
        >
          Formato <FilterIcon />
        </span>

        {!!stateShowFormats && (
          <Filter
            dramaturgy={formatsList.dramaturgies}
            varieties={formatsList.varieties}
            isDramaturgy={true}
            isVarieties={true}
            stateFilterFormat={stateFilterFormat}
            getIdFormats={getIdFormats}
            sendFilterFormat={sendFilterFormat}
          />
        )}
      </li>

      <li className="lastRelease" onClick={() => orderByReleaseDate()}>
        <ReorderButton
          text="Última Liberação"
          color="#3898CD"
          direction={direction}
        />
      </li>
    </div>
  );
};

export default ListHeader;

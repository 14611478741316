import React from "react";

const ConcludedActivitiesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 30 31"
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g stroke="#009C69" strokeWidth="2.667">
          <g>
            <g>
              <path
                d="M26.667 12.44v1.227c-.004 5.905-3.89 11.104-9.553 12.778-5.663 1.674-11.752-.575-14.966-5.529-3.214-4.954-2.787-11.431 1.05-15.92C7.034.507 13.366-.924 18.76 1.48"
                transform="translate(-900 -366) translate(840 351) translate(62 17)"
              />
              <path
                d="M26.667 3L13.333 16.347 9.333 12.347"
                transform="translate(-900 -366) translate(840 351) translate(62 17)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ConcludedActivitiesIcon;

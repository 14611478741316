import React from "react";
import "./styles/InfoCards.scss";
import ScriptBoxCaller from "../../../components/ScriptBoxCaller/ScriptBoxCaller";

const InfoCards = (props) => {
  const {
    title,
    countData = 0,
    callerIcon,
    callerEvent,
    handleEvent,
    children,
    pdf = false,
    _class = "",
    viewAll = "Ver todos os anexos",
  } = props;

  return (
    <div className="infoCard">
      <div className={`cardHeader ${_class}`}>
        <span className="cardTitle">{title}</span>
        <div className="uploadButton">
          <ScriptBoxCaller callerIcon={callerIcon} event={callerEvent} />
        </div>
      </div>

      {!pdf && <div className="cardContent">{children}</div>}

      {countData > 0 && (
        <div className="allAttachments">
          <ScriptBoxCaller callerText={viewAll} event={() => handleEvent()} />
        </div>
      )}
    </div>
  );
};

export default InfoCards;

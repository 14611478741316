import React from "react";

const PrintIcon = (props) => {
  const { color } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path
          d="M4 7L4 0 16 0 16 7M4 16H2c-1.105 0-2-.895-2-2V9c0-1.105.895-2 2-2h16c1.105 0 2 .895 2 2v5c0 1.105-.895 2-2 2h-2"
          transform="translate(1 1)"
        />
        <path d="M4 12H16V20H4z" transform="translate(1 1)" />
      </g>
    </svg>
  );
};

export default PrintIcon;

import React from "react";
import CustomTooltip from "../../../../../components/CustomTooltip/CustomTooltip";
import DeleteIcon from "../../../../../components/VectorIcons/DeleteIcon";
import DeleteTooltip from "../../DeleteTooltip/DeleteTooltip";

const DeleteIcons = (props) => {
  const { release, activityCount, returnColor } = props;

  if (!!release)
    return !!release ? (
      <CustomTooltip
        tipLabel={<DeleteIcon color={returnColor(release)} />}
        dataTip={<DeleteTooltip type="release" />}
      />
    ) : (
      <DeleteIcon color={returnColor(release)} />
    );

  return activityCount > 0 ? (
    <CustomTooltip
      tipLabel={<DeleteIcon color={returnColor(activityCount)} />}
      dataTip={<DeleteTooltip type="activity" />}
    />
  ) : (
    <DeleteIcon color={returnColor(activityCount)} />
  );
};

export default DeleteIcons;

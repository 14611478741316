import React, { useContext } from "react";
import AppContext from "../../context/appContext";
import Alerts from "../Alerts/Alerts";

const SystemAlerts = () => {
  const appContext = useContext(AppContext);

  if (!!appContext.message)
    return (
      <div className="containerFlex">
        <Alerts
          type={appContext.message.type}
          message={appContext.message.text}
        />
      </div>
    );

  return <></>;
};

export default SystemAlerts;

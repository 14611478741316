import React from "react";

const ReorderIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.72676V2.27324C0 1.40313 1.03432 0.94813 1.67572 1.53608L6 5.5L1.67572 9.46392C1.03432 10.0519 0 9.59687 0 8.72676Z"
        transform="translate(10) rotate(90)"
        fill={color}
      ></path>
    </svg>
  );
};

export default ReorderIcon;

import React from "react";
import "./styles/LoadError.scss";
import ErrorLoadIcon from "../VectorIcons/ErrorCloudIcon";
import RefreshIcon from "../VectorIcons/RefreshIcon";

const LoadError = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="loadError">
      <ErrorLoadIcon />
      <span className="message">Não é possível carregar</span>
      <span className="reloadMessage" onClick={() => refreshPage()}>
        <RefreshIcon />
        Tentar novamente
      </span>
    </div>
  );
};

export default LoadError;

import React, { useState } from "react";
import "./style/EditFile.scss";
import FieldsetSelect from "../../../components/FieldsetSelect/FieldsetSelect";
import NumberInput from "../../../components/NumberInput/NumberInput";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatepicker";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import Button from "../../../components/Button/Button";
import FieldsetNumberMasked from "../../../components/FieldsetNumberMasked/FieldsetNumberMasked";

const EditFile = (props) => {
  const {
    value,
    handleBlur,
    thesaurusFileType,
    file,
    sendFileEdit,
    condition,
  } = props;

  const initialState = {
    attachmentTypeId: {
      id: file.attachmentType?.id,
      name: file.attachmentType?.name,
    },
    version: file.version || "",
    chapterNumber: file.chapterNumber,
    blockNumber: file.blockNumber,
    receiveAt: file.receivedAt ? new Date(file.receivedAt) : new Date(),
  };

  const [state, setstate] = useState(initialState);

  const getType = (ev) => {
    const id = ev.nativeEvent.target.selectedIndex;
    const idText = ev.nativeEvent.target[id].text;
    const newState = {
      id: ev.target.value,
      name: idText,
    };

    if (newState.name !== condition) {
      setstate({
        ...state,
        blockNumber: 0,
        chapterNumber: 0,
        attachmentTypeId: newState,
      });
    } else {
      setstate({
        ...state,
        attachmentTypeId: newState,
      });
    }
  };

  const getVersion = (version) => {
    setstate({
      ...state,
      version: version,
    });
  };

  const getBlock = (version) => {
    setstate({
      ...state,
      blockNumber: version,
    });
  };

  const getDate = (date) => {
    let d = new Date(
      date.getTime() + date.getTimezoneOffset() * 60000
    ).toISOString();
    setstate({
      ...state,
      receiveAt: d,
    });
  };

  const getChapter = (ev) => {
    setstate({
      ...state,
      chapterNumber: ev.target.value,
    });
  };

  const sendEditFiles = (e) => {
    e.preventDefault();
    let obj = {
      id: file.id,
      version: state.version,
      receivedAt: state.receiveAt,
      attachmentTypeId: parseInt(state.attachmentTypeId.id),
      blockNumber: parseInt(state.blockNumber),
      chapterNumber: parseInt(state.chapterNumber),
      comment: file.comment,
      release: file.release,
      releaseDate: file.releaseDate,
    };

    sendFileEdit(obj);
  };

  const editIsValid = () => {
    if (state.attachmentTypeId.name === condition) {
      return parseInt(state.chapter) !== 0 && parseInt(state.blockNumber) !== 0;
    } else {
      return state.attachmentTypeId.name !== "Selecione um tipo";
    }
  };

  return (
    <div className="EditFileContainer">
      <form onSubmit={(e) => sendEditFiles(e)}>
        <div className="fileInfo">
          <FieldsetSelect
            label="Tipo"
            name="attachmentTypeId"
            placeholder="Selecione um tipo"
            darkBackgroundColor={false}
            origin="1"
            data={thesaurusFileType}
            value={value}
            handleChange={getType}
            handleBlur={handleBlur}
            selected={state.attachmentTypeId.id}
          />

          <NumberInput
            label="Versão"
            getVersion={getVersion}
            number={state.version === "" ? "" : parseInt(state.version)}
            darkBackgroundColor={false}
          />

          <CustomDatePicker
            label="Recebido Em"
            name="attachmentReceivedAt"
            darkBackgroundColor={false}
            handleChangeDate={getDate}
            maxDate={new Date()}
            date={new Date(state.receiveAt)}
          />
        </div>

        {state.attachmentTypeId.name === condition && (
          <div className="chapter">
            <NumberInput
              label="Nº DO BLOCO"
              darkBackgroundColor={false}
              number={
                state.blockNumber === "" ? "" : parseInt(state.blockNumber)
              }
              getVersion={getBlock}
            />

            <FieldsetNumberMasked
              mask="9999"
              autoComplete="off"
              name="name"
              label="CAPÍTULO(S)"
              type="text"
              placeholder="Digite um número entre 1 e 9999"
              handleChange={getChapter}
              value={state.chapterNumber}
              isValid={true}
              msgError="Este campo é obrigatório"
            />
          </div>
        )}

        <BoxFooter>
          <Button
            text="Salvar"
            toValidate={true}
            isValidForm={editIsValid()}
            classButton="buttonGreen large"
            typeButton="submit"
            dataQA="button-prospect-new-save"
          />
        </BoxFooter>
      </form>
    </div>
  );
};

export default EditFile;

import React from "react";
import ReactDOM from "react-dom";

const RenderPDF = ({ open, close, children }) => {
  if (!open) return null;

  return ReactDOM.createPortal(
    <div
      style={{
        position: "fixed",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        backgroundColor: "rgba(0,0,0,0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "all 0.5s",
      }}
    >
      <button
        style={{
          zIndex: 99,
          width: "30px",
          height: "30px",
          position: "absolute",
          top: "12px",
          right: "12px",
          borderRadius: "50%",
          border: "none",
          cursor: "pointer",
        }}
        onClick={close}
      >
        X
      </button>
      {children}
    </div>,
    document.getElementById("portal")
  );
};

export default RenderPDF;

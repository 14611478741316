import React from "react";
import "./styles/EvaluationHeader.scss";
import BackArrow from "../../../../components/VectorIcons/BackArrow";
import Avatar from "../../../../components/Avatar/Avatar";
import Button from "../../../../components/Button/Button";
import { Link } from "react-router-dom";

const EvaluationHeader = (props) => {
  const { type, userName, isEditable } = props;

  return (
    <>
      <div className="evaluationHeader">
        <div className="analistHeader">
          <Link className="backButton" to="/atividades">
            <BackArrow color="#FFF" />
          </Link>
          <div className="aboutEvaluation">
            <span>{`${type}`}</span>
            <div className="avatar">
              {!!userName && <Avatar letter={userName.slice(0, 1)} />}
              <span>{!!userName ? userName : "Sem analista"}</span>
            </div>
          </div>
        </div>

        {isEditable && (
          <Button
            text="Salvar"
            classButton="buttonGreen"
            typeButton="submit"
            dataQA=""
            toValidate={false}
            clickEvent={() => {}}
          />
        )}
      </div>

      <div className="subHeader" />
    </>
  );
};

export default EvaluationHeader;

import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./style/ProspectsAndProductions.scss";
import Button from "../../components/Button/Button";
import AppContext from "../../context/appContext";
import ScriptBox from "../../components/ScriptBox/ScriptBox";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import TableList from "../../components/TableList/TableList";
import ContentPagination from "../../components/ContentPagination/ContentPagination";
import ProspectIcon from "../../components/VectorIcons/ProspectIcon";
import AddProspectIcon from "../../components/VectorIcons/AddProspectIcon";
import ProductionsAndProspectsContext from "../../context/productionsAndProspectsContext";
import ListCount from "../../components/ListCount/ListCount";
import LoadError from "../../components/LoadError/LoadError";
import CreateAndEditProspectsAndCompanies from "./CreateAndEditProspectsAndCompanies/CreateAndEditProspectsAndCompanies";
import AddProductionCompaniesIcon from "../../components/VectorIcons/AddProductionCompanieIcon";
import EditIcon from "../../components/VectorIcons/EditIcon";

const ProspectsAndProductions = (props) => {
  const context = useContext(AppContext);
  const productionsAndProspectsContext = useContext(
    ProductionsAndProspectsContext
  );

  const { type } = props;

  const [stateEditContacts, setStateEditContacts] = useState({
    contacts: [],
    name: "",
  });
  const [currentAction, setCurrentAction] = useState("");

  const compareTypes = {
    prospects: {
      endPoint: "prospects",
      name: "Proponente",
      title: "Novo proponente",
      titleEdit: "Editar proponente",
      icon: <AddProspectIcon color="#FFF" />,
      editIcon: <EditIcon color="#FFF" />,
    },
    productioncompanies: {
      endPoint: "productioncompanies",
      name: "Produtora",
      title: "Nova produtora",
      titleEdit: "Editar produtora",
      icon: <AddProductionCompaniesIcon color="#FFF" />,
      editIcon: <EditIcon color="#FFF" />,
    },
  };

  useEffect(() => {
    productionsAndProspectsContext.setCurrentPath(compareTypes[type].endPoint);
    productionsAndProspectsContext.setFilter({});
    // eslint-disable-next-line
  }, []);

  const headerList = [
    { label: "Nome", className: "large" },
    { label: "Contato", className: "large contactsHeader" },
    { label: "", className: "editItem" },
  ];

  const clearForm = () => {
    setStateEditContacts({ contacts: [], name: "" });
    setCurrentAction("");
    context.setCurrentStepBox("");
  };

  const setEditContacts = (prospect) => {
    setCurrentAction("edit");
    compilingContact(prospect.contacts, prospect.name, prospect.id);
  };

  const compilingContact = (contacts, name, id) => {
    contacts.forEach((e, i) => {
      contacts[i].label = e.value;
    });

    setStateEditContacts({
      ...stateEditContacts,
      contacts: contacts,
      name: name,
      id: id,
    });

    context.setCurrentStepBox("newProspect");
  };

  const handlePageChange = (filter) =>
    productionsAndProspectsContext.setFilter(filter);
  const handleClickButton = () => {
    setCurrentAction("");
    context.setCurrentStepBox("newProspect");
  };

  useEffect(() => {
    if (context.current.step === "") {
      clearForm();
    }
    // eslint-disable-next-line
  }, [context.current.step]);

  return (
    <div id="Proponents">
      <Helmet>
        <title>Script - Lista de {compareTypes[type].name}s</title>
      </Helmet>
      <Breadcrumb>
        <h2>{compareTypes[type].name}s</h2>

        <Button
          text={`Novo ${compareTypes[type].name}`}
          classButton="buttonBlue"
          rippleEffect={context.isRippleEffect}
          onBreadcrumb={true}
          clickEvent={() => handleClickButton()}
          dataQA="button-new-prospect"
        >
          <AddProspectIcon color="#FFF" />
        </Button>
      </Breadcrumb>

      <div className="listContainer">
        {productionsAndProspectsContext.list ? (
          <>
            {productionsAndProspectsContext.list.pagingInfo && (
              <ListCount
                total={
                  productionsAndProspectsContext.list.pagingInfo.totalItems
                }
                text={`${compareTypes[type].name}s cadastrados`}
              />
            )}
            {!!productionsAndProspectsContext.list.result && (
              <TableList
                headerList={headerList}
                handleEdit={setEditContacts}
                nameIcon={<ProspectIcon color="#999BE0" />}
                renderCreatedAt={true}
                loading={productionsAndProspectsContext.list.loading}
                data={productionsAndProspectsContext.list.result}
              />
            )}
          </>
        ) : (
          <LoadError />
        )}
      </div>

      {context.current.step === "newProspect" && (
        <ScriptBox
          clear={clearForm}
          open={context.current.step === "newProspect"}
          title={
            currentAction === "edit"
              ? compareTypes[type].titleEdit
              : compareTypes[type].title
          }
          icon={
            currentAction === "edit"
              ? compareTypes[type].editIcon
              : compareTypes[type].icon
          }
          marginBottom={true}
        >
          <CreateAndEditProspectsAndCompanies
            closed={() => context.setCurrentStepBox("")}
            path={compareTypes[type].endPoint}
            name={compareTypes[type].name}
            type={type}
            data={stateEditContacts}
            action={currentAction}
            getProspectAndProductionCompanies={
              productionsAndProspectsContext.setFilter
            }
          />
        </ScriptBox>
      )}

      {!!productionsAndProspectsContext.list.pagingInfo &&
        productionsAndProspectsContext.list.pagingInfo.totalPages > 1 && (
          <div className="containerPagination">
            <div className="container">
              <ContentPagination
                totalItems={
                  productionsAndProspectsContext.list.pagingInfo.totalItems
                }
                itemsPerPage={
                  productionsAndProspectsContext.list.pagingInfo.itemsPerPage
                }
                currentPage={
                  productionsAndProspectsContext.list.pagingInfo.currentPage
                }
                prevPage={
                  productionsAndProspectsContext.list.pagingInfo.previousPage
                }
                nextPage={
                  productionsAndProspectsContext.list.pagingInfo.nextPage
                }
                totalPages={
                  productionsAndProspectsContext.list.pagingInfo.totalPages
                }
                handleChange={handlePageChange}
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default ProspectsAndProductions;

import React from 'react'
import ReleaseTextInfo from '../ReleaseTextsModal/ReleaseTextInfo/ReleaseTextInfo'
import "./style/ReleaseVersions.scss"

const ReleaseVersions = (props) => {

  const { data, handleChange } = props

  return (
    <>
      {!!data.length && data.map( (item, index) => {
        return (
          <div className="contentReleaseTextInfoVersion" key={index}>
            <ReleaseTextInfo handleChange={handleChange} haveVersion={true} currentAttachment={item} />
          </div>
        )
      })}
    </>
  )
}

export default ReleaseVersions

import React from "react";
import "./styles/Storyline.scss";
import ScriptBoxCaller from "../../../../components/ScriptBoxCaller/ScriptBoxCaller";

const Storyline = (props) => {
  const { storyline, handleDetailsModal } = props;

  return (
    <li className="contentStoryline">
      <p className="storylineText">
        {storyline && `${storyline.slice(0, 100).trim()}... `}

        {storyline.length > 99 ? (
          <ScriptBoxCaller callerText="mais" event={handleDetailsModal} />
        ) : (
          ""
        )}
      </p>
    </li>
  );
};

export default Storyline;

import React from "react";
import "./styles/TableItemDates.scss";

const TableItemDates = (props) => {
  const {
    dates = {},
    type,
    isChapter,
  } = props;

  const {
    scheduleDelivery,
    lastAttachmentReceiveDate,
    scheduleRelease,
    lastAttachmentReleaseDate,
    plannedArtisticApproval,
    realizedArtisticApproval,
    plannedChannelFeedback,
    realizedChannelFeedback,
    plannedContentSight,
    realizedContentSight,
    plannedProdutionSight,
    realizedProdutionSight,
    rescheduledDelivery,
    rescheduledRelease
  } = dates;
  
  const dateFields = [
    "Entrega",
    "Parecer conteúdo",
    "Parecer Produção",
    "Aprov. Dir. Gênero",
    "Liberação",
    "Notes do Canal",
  ];

  const aggroupedDates = {
    planned:[
      scheduleDelivery ,
      plannedContentSight,
      plannedProdutionSight,
      plannedArtisticApproval,
      scheduleRelease,
      plannedChannelFeedback,
    ],
    release:[
      lastAttachmentReceiveDate,
      realizedContentSight,
      realizedProdutionSight,
      realizedArtisticApproval,
      lastAttachmentReleaseDate,
      realizedChannelFeedback,
    ]
  }

  return type === "projectSheet" ? (
    <>
       {//Desktop
       }
       {isChapter ?
        <div className="cell dates">
          <span className="date">{scheduleDelivery}</span>
          <span className="date">{rescheduledDelivery}</span>
          <span className="date">{lastAttachmentReceiveDate}</span>
        </div>
        :
        <div className="cellDatesRow">
        {dateFields.map((field, index) => (
          <div className="cellDates" key={index}>
                <div className="date" style={{fontWeight:"lighter"}}>{aggroupedDates.planned[index]}</div>
                <div className="date" style={{fontWeight:"lighter"}}>{aggroupedDates.release[index]}</div>
          </div>
        ))}
      </div>
      }
          {//Mobile
          }

        {isChapter ?
        <div className="cell dates mobile">
          <span className="dateMobile">{scheduleDelivery}</span>
          <span className="dateMobile">{rescheduledDelivery}</span>
          <span className="dateMobile">{lastAttachmentReceiveDate}</span>
        </div>
        :
        <div className="cellDatesRowMobile">
        {dateFields.map((field, index) => (
          <div className="datesCellMobile" key={index}>
            <div className="datesTitleMobile">{field.toUpperCase()}</div>
            <div className="datesAggroupMobile">
              <div className="datesMobile">
                <div className="dateMobile">PLANEJADO</div>
                <div className="dateMobile">ENTREGUE</div>
              </div>
              <div className="datesMobile">
                <div className="dateMobile" style={{fontWeight:"lighter"}}>{aggroupedDates.planned[index]}</div>
                <div className="dateMobile" style={{fontWeight:"lighter"}}>{aggroupedDates.release[index]}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      }

    </>
  ) : (
    <>
      <div className="cellDatesText">
        <span className="date">
          {typeof rescheduledRelease === "string"
            ? rescheduledRelease
            : scheduleRelease}
        </span>
      </div>
      <div className="cellDatesText">
        <span className="date">{lastAttachmentReleaseDate}</span>
      </div>
    </>
  );
};

export default TableItemDates;

import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import move from "lodash-move/lib";
import AppContext from "../../../context/appContext";
import { putService } from "../../../services/script-api";
import _ from "lodash";
import ActivitiesNotUsers from "../ActivitiesNotUsers/ActivitiesNotUsers";
import ActivitiesUsers from "../ActivitiesUsers/ActivitiesUsers";

const reorder = (list, startIndex, endIndex) =>
  move(list, startIndex, endIndex);

const ActivitiesListItem = (props) => {
  const { item, bgColor, i, name, handleEditActivity, handleEditFeedback } =
    props;

  const appContext = useContext(AppContext);

  const [state, setState] = useState({ items: [] });

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "#f2f2f2" : "#ffffff",
    ...draggableStyle,
  });

  useEffect(() => {
    setState({
      ...state,
      items: _.orderBy(item, ["order"], ["asc"]),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = reorder(
      state.items,
      result.source.index,
      result.destination.index
    );

    setState({
      ...state,
      items: items,
    });

    sendReorder(mountList(items));
  };

  const mountList = (activities) => {
    let list = [];
    activities.forEach((e) => list.push(e.id));
    return { orderedIds: list };
  };

  const sendReorder = (activities) => {
    putService("activities/order", activities)
      .then((res) => {
        //appContext.sendMessage("Atividade reordenada com sucesso!");
      })
      .catch((e) => {
        appContext.sendMessage("Atividade reordenada com sucesso!", "error");
      });
  };

  const returnPlaceholderText = (type) => {
    const types = {
      "A direcionar": "Não há nenhuma atividade a direcionar.",
      "A Revisar": "Não há nenhuma atividade a revisar.",
      Cancelados: "Quando uma atividade for cancelada, ela aparecerá aqui.",
      Concluídas: "Quando uma atividade for concluída, ela aparecerá aqui.",
      Default: `Não há nenhuma atividade para o(a) analista ${type}.`,
    };
    return types[type] || types["Default"];
  };

  if (!item.length) {
    return (
      <div className="activityListPlaceholder">
        {returnPlaceholderText(name)}
      </div>
    );
  }

  const verifyFormat = (item) =>
    !!item.attachments[0].attachmentType &&
    (item.attachments[0].attachmentType.name === "Capítulo/Episódio" ||
      item.attachments[0].attachmentType.name === "Episódio");

  const types = [
    "Cancelados",
    "Concluídas",
    "Feedback Pendentes",
    "A Revisar",
    "A direcionar",
  ];

  const isListUsers = () => types.includes(name);

  let windowWidth = document.body.clientWidth;

  return (
    <>
      {!isListUsers() && state.items.length > 1 && windowWidth > 768 ? (
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          <Droppable droppableId={`${name}_${i}`}>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {state.items.map((activity, index) => {
                  return (
                    <ActivitiesUsers
                      key={index}
                      activity={activity}
                      index={index}
                      name={name}
                      verifyFormat={verifyFormat}
                      bgColor={bgColor}
                      getItemStyle={getItemStyle}
                      handleEditActivity={handleEditActivity}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        state.items.map((activity, index) => {
          return (
            <ActivitiesNotUsers
              key={index}
              activity={activity}
              index={index}
              name={name}
              verifyFormat={verifyFormat}
              bgColor={bgColor}
              handleEditFeedback={handleEditFeedback}
              handleEditActivity={handleEditActivity}
            />
          );
        })
      )}
    </>
  );
};

export default ActivitiesListItem;

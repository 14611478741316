import React, { useState, useContext, useEffect } from "react";
import "./styles/ProjectRecommendation.scss";
import RadioInput from "../../../components/RadioInput/RadioInput";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import Textarea from "../../../components/Textarea/Textarea";
import {
  getService,
  postService,
  putService,
} from "../../../services/script-api";
import UserContext from "../../../context/userContext";
import AppContext from "../../../context/appContext";
import SheetContext from "../../../context/sheetContext";
import SmallLoading from "../../../components/SmallLoading/SmallLoading";

const ProjectRecommendation = (props) => {
  const { recommendation, id, type } = props;
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const sheetContext = useContext(SheetContext);

  const initialState = {
    recommendationType: recommendation?.recommendationType.id || "",
    justification: recommendation?.justification || "",
  };

  const [stateRecommendation, setStateRecommendation] = useState(initialState);
  const [stateRecommendationOptions, setStateRecommendationOptions] = useState({
    loading: true,
    list: [],
  });

  const getRecommendation = () => {
    getService("projects/data/recommendations").then((res) => {
      setStateRecommendationOptions({
        loading: false,
        list: res.data,
      });
    });
  };

  const handleChangeJustification = (event) => {
    setStateRecommendation({
      ...stateRecommendation,
      justification: event.target.value,
    });
  };

  const handleChangeRecomendationType = (id) => {
    setStateRecommendation({
      ...stateRecommendation,
      recommendationType: id,
    });
  };

  const isValidForm = () =>
    Number.isInteger(stateRecommendation.recommendationType);

  const handleMessage = (status) => {
    const options = {
      success: !!recommendation ? "editada" : "cadastrada",
      error: !!recommendation ? "editar" : "cadastrar",
    };
    return options[status];
  };

  const handleService = (id, recommendation, obj) => {
    if (!!recommendation) {
      return putService(`${type}/${id}/recommendations/${recommendation}`, obj);
    } else {
      return postService(`${type}/${id}/recommendations`, obj);
    }
  };

  const handleRefreshOnSuccess = (type) => {
    const methods = {
      dramaturgies: sheetContext._getProjectSheet(id, type),
      varieties: sheetContext._getProjectSheetVarieties(id, type),
    };
    return methods[type];
  };

  const sendRecommendation = () => {
    const sendObj = {
      ...stateRecommendation,
      userid: userContext.me.user.id,
    };
    appContext.setShowGlassLoading(true);
    handleService(id, recommendation?.id, sendObj)
      .then((res) => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(
          `Recomendação ${handleMessage("success")} com sucesso!`
        );
        appContext.setCurrentStepBox("closed");
        handleRefreshOnSuccess(type);
      })
      .catch((error) => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(
          `Erro ao ${handleMessage("error")} recomendação!`,
          "error"
        );
      });
  };

  useEffect(() => {
    getRecommendation();
    // eslint-disable-next-line
  }, []);

  return (
    <div id="ProjectRecomendation">
      <div className="recomendationContent">
        <div className="recomendationTypeInput">
          {!stateRecommendationOptions.loading ? (
            <RadioInput
              options={stateRecommendationOptions.list.filter((e) => e.id > 0)}
              value={stateRecommendation?.recommendationType}
              handleChange={handleChangeRecomendationType}
            />
          ) : (
            <SmallLoading />
          )}
        </div>

        <div className="justificationInput">
          <Textarea
            maxRows={12}
            label="Justificativa"
            placeholder="Escreva uma justificativa para a recomendação"
            handleChange={(event) => handleChangeJustification(event)}
            value={stateRecommendation?.justification}
          />
        </div>
      </div>

      <div className="recomendationFooter">
        <SimpleButton
          text="Salvar"
          toValidate={true}
          isValidForm={isValidForm()}
          classButton="buttonGreen large"
          clickEvent={sendRecommendation}
        />
      </div>
    </div>
  );
};

export default ProjectRecommendation;

import React  from 'react'
import { getService } from '../../../../services/script-api'
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncLoading from '../../../../components/AsyncLoading/AsyncLoading'

const ActivitiesCharacter = (props) => {

  const { handleChange, defaultValue } = props

  const loadOptions = (inputValue, callback, origin) => {
    if (inputValue && inputValue.length > 0) {
      getService(`dramaturgies/thesaurus`, {
        Term: inputValue,
        origins: origin,
      }).then((list) => {
        const _list = list.data.map((item) => {
          return { value: item.id, label: item.name }
        })
        callback(_list)
      })
    }
  }

  return (
    <div className="formField">
      <label className="fieldLabel">Atividades/Características</label>

      <AsyncCreatableSelect
        className="reactSelect"
        classNamePrefix="reactSelect"
        loadOptions={(inputValue, callback) =>
          loadOptions(inputValue, callback, 32)
        }
        defaultValue={defaultValue}
        onChange={(item) => handleChange(item)}
        placeholder="Digite as atividades/características do personagem e aperte ENTER"
        isMulti
        noOptionsMessage={() => 'Nenhum item encontrado'}
        formatCreateLabel={(inputValue) => {
          return `Adicionar a opção: "${inputValue}"`
        }}
        loadingMessage={() => {
          return 'Carregando...'
        }}
        components={{ LoadingIndicator: () => <AsyncLoading/> }}
        openMenuOnClick={false}
      />

      <span className="optionalText">Opcional</span>
    </div>
  )
}

export default ActivitiesCharacter
import React, { useEffect, useState } from "react";
import CkEditor from "../../CkEditor/CkEditor";

const NormalText = (props) => {
  const { title, setter, type, index, handleFocus, data, isEditable } = props;

  const [state, setState] = useState(data);

  const handleChangeText = (ev, value) => {
    setState({
      ...state,
      text: value,
    });
  };

  useEffect(() => {
    setter(type, state, index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="fieldContainer">
      <div className="fieldHeader">
        <div className="fieldTitle">
          <span>{title}</span>
        </div>
      </div>

      <div className="editorArea">
        {isEditable ? (
          <CkEditor
            onChange={handleChangeText}
            onFocus={() => handleFocus(true)}
            onBlur={() => handleFocus(false)}
            data={data}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: data.text }} />
        )}
      </div>
    </div>
  );
};

export default NormalText;

import React, { useContext } from "react";
import "../styles/InfoPaper.scss";
import ScriptBoxCaller from "../../../../components/ScriptBoxCaller/ScriptBoxCaller";
import ProspectIcon from "../../../../components/VectorIcons/ProspectIcon";
import AppContext from "../../../../context/appContext";
import WatermarkContent from "../../../../components/Watermark/WatermarkContent";

const InfoFiled = (props) => {
  const { data, label, children } = props;
  return (
    <>
      {!!data && (
        <div className="infoField">
          <span className="infoName">{label}</span>
          {!!children ? (
            <span className="children">{children}</span>
          ) : (
            <span className="infoData">
              {typeof data === "object" ? data.value : data}
            </span>
          )}
        </div>
      )}
    </>
  );
};

const InfoFieldMap = (props) => {
  const { data, label } = props;

  return (
    <>
      {!!data && !!data.length && (
        <div className="infoField">
          <span className="infoName"> {label} </span>
          <div className="infoDataList">
            {data.map((item, index) => {
              return (
                <span className="infoData" key={index}>
                  {item.origin === "Prospect" && (
                    <ProspectIcon color="#B2B4F1" width={18} height={18} />
                  )}
                  {item.name} {index + 1 < data.length ? <span> | </span> : ""}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const InfoPaperBasic = (props) => {
  const {
    data,
    havePermission,
    callerEvent,
    callerIcon,
    paperTitle,
    channels,
    receivedAt,
  } = props;
  const {
    projectType,
    title,
    baseTitle,
    seasonNumber,
    creators,
    productionCompanies,
    origins,
    format,
    soapOperaSchedule,
    programFrequency,
    chaptersOrEpisodes,
    episodes,
    category,
    subCategory,
    histories,
    publicDomain,
    suggestedBy,
    publicationYear,
    publishers,
    authors,
  } = { ...data };

  const appContext = useContext(AppContext);

  return (
    <div className="infoPaper">
      <div className="paper">
        <div className="paperTitle">
          <span>{paperTitle}</span>
          {!!callerEvent && havePermission && (
            <ScriptBoxCaller callerIcon={callerIcon} event={callerEvent} />
          )}
        </div>
        <WatermarkContent> 
          <div className="infoFieldContainer">
            <InfoFiled data={projectType} label="Tipo" />
            <InfoFiled data={receivedAt} label="Recebido Em" />
            {seasonNumber > 1 && (
              <InfoFiled data={baseTitle} label="Produto Original" />
            )}
            <InfoFiled data={title} label="Título">
              {!!histories && !!histories?.length && (
                <div className="projectTitle">
                  <span>{title}</span>
                  <ScriptBoxCaller
                    callerText="Ver histórico de títulos"
                    event={() => appContext.setCurrentStepBox("titleHistory")}
                  />
                </div>
              )}
            </InfoFiled>
            {!!authors?.length && (
              <InfoFieldMap data={authors} label="Autor(ES)" />
            )}
            {!!publicDomain && (
              <InfoFiled
                data={publicDomain ? "Sim" : "Não"}
                label="Domínio público"
              />
            )}
            {!!publishers?.length && (
              <InfoFieldMap data={publishers} label="Editora" />
            )}
            <InfoFiled data={category} label="Categoria" />
            <InfoFiled data={subCategory} label="SubCategoria" />

            {!!seasonNumber && seasonNumber !== 1 && (
              <div className="infoField">
                <span className="infoName">Temporada</span>
                <span className="infoData">{`${seasonNumber}ª`}</span>
              </div>
            )}

            <InfoFieldMap data={creators} label="Criado por" />
            <InfoFieldMap data={productionCompanies} label="Produtora" />

            {!!origins && !!origins.length && (
              <div className="infoField">
                <span className="infoName">Procedência</span>
                <span className="infoData">
                  {origins.map((prospect, index) => {
                    return (
                      <span key={index}>
                        {prospect}
                        {index + 1 < origins.length ? <span> | </span> : ""}
                      </span>
                    );
                  })}
                </span>
              </div>
            )}

            {!!channels && !!channels.length && (
              <div className="infoField">
                <span className="infoName">Produto/Canais</span>
                <div className="clientsList">
                  {channels.map((channel, index) => {
                    return (
                      <div className="clientItem" key={index}>
                        {channel.parentName}
                        {!!channel.description ? ` / ${channel.description}` : ""}
                        {!!channel.children.length
                          ? channel.children.map((item, index) => {
                              return (
                                <span key={index}>
                                  {` / ${item.content.name}`}
                                  {!!item.descriptions[0]
                                    ? ` / ${item.descriptions[0]}`
                                    : ""}
                                </span>
                              );
                            })
                          : ""}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <InfoFiled data={format?.name} label="Formato" />
            {!!publicationYear && (
              <InfoFiled data={publicationYear} label="Ano de publicação" />
            )}

            {!!soapOperaSchedule && format?.name === "Novela" && (
              <InfoFiled data={soapOperaSchedule.value} label="Horário" />
            )}
            {!!programFrequency && format?.name === "Programa" && (
              <InfoFiled data={programFrequency.value} label="Periodicidade" />
            )}

            <InfoFiled data={chaptersOrEpisodes || ""} label="Capítulos" />
            <InfoFiled data={episodes} label="Episódios" />

            {!!suggestedBy && (
              <InfoFieldMap data={suggestedBy} label="Solicitado por" />
            )}
          </div>
        </WatermarkContent> 

      </div>
    </div>
  );
};

export default InfoPaperBasic;

import React, { useState, useEffect } from "react";
import "./styles/ReleasedFilterFields.scss";
import Fieldset from "../../../components/Fieldset/Fieldset";
import FieldsetSelect from "../../../components/FieldsetSelect/FieldsetSelect";
import { sendEvent } from "../../../services/GoogleAnalytics/methods";
import Button from "../../../components/Button/Button";

const ReleasedFilterFields = (props) => {
  const {
    setFilter,
    thesaurusList,
    selectedFilters,
    setSelectedFilters,
    handleAddChannelId,
    isChannelRole,
  } = props;

  const [title, setTitle] = useState("");
  const [primaryOptions, setPrimaryOptions] = useState([]);
  const [secondaryOptions, setSecondaryOptions] = useState([]);

  const handleChange = (key, value) => {
    const handleValue = value === "placeholder" ? "" : parseInt(value);
    if (value) {
      setSelectedFilters({ ...selectedFilters, [key]: handleValue });
    }
  };

  const compilePrimaryOptions = () => {
    const firstLevel = thesaurusList.filter((item) => !item.parentId);
    setPrimaryOptions(firstLevel);
  };

  const textReleaseRole = isChannelRole ? "Canais" : "Atendimento";

  const handleAnalytics = () => {
    const { primary } = selectedFilters;

    if (title) {
      sendEvent("Textos Liberados", "Filtrar por título do projeto", title);
      sendEvent(
        "Textos Liberados",
        "Filtrar por título do projeto",
        textReleaseRole
      );
    }

    if (primary) {
      const firstLevel = thesaurusList.find(
        (item) => item.id === selectedFilters.primary
      );
      const secondLevel = thesaurusList.find(
        (item) => item.id === selectedFilters.secondary
      );
      sendEvent(
        "Textos Liberados",
        "Filtrar por canais",
        `${firstLevel.name}${!!secondLevel ? " - " : ""}${
          secondLevel?.name || ""
        }`
      );
      sendEvent(
        "Textos Liberados",
        "Filtrar por título do projeto",
        textReleaseRole
      );
    }
  };

  const addFilter = (ev) => {
    ev.preventDefault();
    const selectedIdsArray = Object.values(selectedFilters);
    const validIds = selectedIdsArray.filter((item) => !!item);

    setFilter((prevState) => ({ ...prevState, title: title, pageNumber: 1 }));
    handleAddChannelId(validIds);
    handleAnalytics();
  };

  const handleSecondaryFieldName = (primaryId) => {
    const primaryOption = thesaurusList.find((item) => item.id === primaryId);
    const types = {
      "tv globo": "divisão",
      "canais globo": "canais",
      "produtos e serviços digitais": "produto/serviço",
    };
    return types[primaryOption.name.toLowerCase()];
  };

  useEffect(() => {
    compilePrimaryOptions();
    // eslint-disable-next-line
  }, [thesaurusList]);

  useEffect(() => {
    if (selectedFilters.primary) {
      const secondLevel = thesaurusList.filter(
        (item) => item.parentId === selectedFilters.primary
      );
      setSecondaryOptions(secondLevel);
    }
    setSelectedFilters({ ...selectedFilters, secondary: "" });
    // eslint-disable-next-line
  }, [selectedFilters.primary]);

  return (
    <div className="releasedFilterFields">
      <form onSubmit={(e) => addFilter(e)}>
        <div className="container">
          <h2>Filtrar Projetos</h2>

          <div className="fields">
            <Fieldset
              autoComplete="off"
              label="Título do Projeto"
              type="text"
              placeholder="Digite um título"
              value={title}
              handleChange={(ev) => setTitle(ev.target.value)}
              _class="titleField"
            />

            <FieldsetSelect
              name="channels"
              label="Canais/Produto"
              placeholder="Selecione"
              data={primaryOptions}
              selected={selectedFilters.primary}
              handleChange={(ev) => handleChange("primary", ev.target.value)}
            />

            {!!selectedFilters.primary && !!secondaryOptions.length && (
              <FieldsetSelect
                name="product/service"
                label={handleSecondaryFieldName(selectedFilters.primary)}
                placeholder="Selecione"
                data={secondaryOptions}
                selected={selectedFilters.secondary}
                handleChange={(ev) =>
                  handleChange("secondary", ev.target.value)
                }
              />
            )}

            <Button
              text="Filtrar"
              classButton="buttonBlue"
              typeButton="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReleasedFilterFields;

import React, { useState, useEffect, useContext } from "react";
import "./style/ThesaurusAdmin.scss";
import ThesaurusTypeNavigation from "../components/ThesaurusTypeNavigation/ThesaurusTypeNavigation";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Masks from "../../components/VectorIcons/Masks";
import Microphone from "../../components/VectorIcons/Microphone";
import NavigationThesarus from "../components/NavigationThesarus/NavigationThesarus";
import { getService } from "../../services/script-api";
import AppContext from "../../context/appContext";
import UpdateThesaurus from "./UpdateThesaurus/UpdateThesaurus";
import RegisterThesaurus from "./RegisterThesaurus/RegisterThesaurus";
import UpdateOrigins from "./UpdateOrigins/UpdateOrigins";
import BlockRight from "./BlockRight/BlockRight";

const ThesaurusAdmin = () => {
  const appContext = useContext(AppContext);

  const [stateOrigins, setStateOrigins] = useState({
    loading: true,
    origins: [],
  });
  const [stateCurrentOrigin, setStateCurrentOrigin] = useState("");
  const [stateCurrentType, setStateCurrentType] = useState("dramaturgies");
  const [stateOriginThesaurus, setStateOriginThesaurus] = useState({
    loading: true,
    title: "",
    list: [],
  });
  const [currentThesaurus, setCurrentThesaurus] = useState([]);
  const [hasIntegration, setHasIntegration] = useState(true);

  const setLoading = () => {
    setStateOriginThesaurus({
      loading: true,
      originId: "",
      title: "",
      list: [" "],
    });
  };

  const getOrigins = () => {
    getService(`thesaurus/origins`).then((res) => {
      setStateOrigins({
        loading: false,
        origins: res.data,
      });
    });
  };

  useEffect(() => {
    getOrigins();
    // eslint-disable-next-line
  }, []);

  const setCurrentOrigin = (values) => setStateCurrentOrigin(values);

  const getOriginThesaurus = (id, value) => {
    setLoading();
    getService(`${stateCurrentType}/thesaurus?origins=${id}`).then((res) =>
      setStateOriginThesaurus({
        loading: false,
        originId: id,
        title: value,
        list: res.data,
      })
    );
  };

  const setCurrentType = (type) => setStateCurrentType(type);

  const countItems = (count) => (count > 25 ? "threeColumns" : "twoColumns");

  const isActiveType = (type) => stateCurrentType === type;

  const _getThesaurusById = (id) => {
    getService(`thesaurus/${id}`).then((res) => {
      setCurrentThesaurus(res.data);
      appContext.setCurrentStepBox("detailsThesaurus");
    });
  };

  const returnContentType = (type) => {
    const types = {
      dramaturgies: 1,
      varieties: 2,
      research: 3,
    };
    return types[type];
  };

  const allowedToHaveParentTerm = ["Trama", "Temática", "Universo", "Época"];

  return (
    <div id="ThesaurusAdmin">
      <Breadcrumb>
        <h2>Gestão Thesaurus</h2>
        <div className="contentTypes">
          <ThesaurusTypeNavigation
            setCurrentType={() => setCurrentType("dramaturgies")}
            isActive={isActiveType("dramaturgies")}
            title="Dramaturgia"
            icon={
              <Masks
                color={isActiveType("dramaturgies") ? "#ffffff" : "#ADAFEF"}
              />
            }
          />

          <ThesaurusTypeNavigation
            setCurrentType={() => setCurrentType("varieties")}
            isActive={isActiveType("varieties")}
            title="Variedades"
            icon={
              <Microphone
                color={isActiveType("varieties") ? "#ffffff" : "#ADAFEF"}
              />
            }
          />
        </div>
      </Breadcrumb>

      <div className="container mainThesarus">
        <div className="blockLeft">
          <NavigationThesarus
            setCurrentOrigin={setCurrentOrigin}
            currentType={stateCurrentType}
            setHasIntegration={setHasIntegration}
            selected={stateOriginThesaurus.title}
            origins={stateOrigins}
            getOriginThesaurus={getOriginThesaurus}
          />
        </div>

        <BlockRight
          originsThesaurus={stateOriginThesaurus}
          hasIntegration={hasIntegration}
          _getThesaurusById={_getThesaurusById}
          countItems={countItems}
        />
      </div>

      {appContext.current.step === "detailsThesaurus" && (
        <UpdateThesaurus
          thesaurus={currentThesaurus}
          hasIntegration={hasIntegration}
          currentOrigin={stateOriginThesaurus}
          contentType={returnContentType(stateCurrentType)}
          currentType={stateCurrentType}
          allowedToHaveParentTerm={allowedToHaveParentTerm}
          reloadThesaurusList={getOriginThesaurus}
        />
      )}

      {appContext.current.step === "registerThesaurus" && (
        <RegisterThesaurus
          currentType={stateCurrentType}
          hasIntegration={hasIntegration}
          currentOrigin={stateOriginThesaurus}
          contentType={returnContentType(stateCurrentType)}
          allowedToHaveParentTerm={allowedToHaveParentTerm}
          reloadThesaurusList={getOriginThesaurus}
        />
      )}

      {appContext.current.step === "updateOrigins" && (
        <UpdateOrigins
          currentOrigin={stateCurrentOrigin}
          getOrigins={getOrigins}
          setCurrentOrigin={setCurrentOrigin}
        />
      )}
    </div>
  );
};

export default ThesaurusAdmin;

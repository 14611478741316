import React, { useState, useContext } from "react";
import AppContext from "../../../context/appContext";
import ScriptBox from "../../../components/ScriptBox/ScriptBox";
import EditIcon from "../../../components/VectorIcons/EditIcon";
import Textarea from "../../../components/Textarea/Textarea";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import Button from "../../../components/Button/Button";
import { putService } from "../../../services/script-api";

const UpdateOrigins = (props) => {
  const { currentOrigin, getOrigins, setCurrentOrigin } = props;

  const appContext = useContext(AppContext);

  const [stateOrigin, setStateOrigin] = useState(currentOrigin);

  const handleChangeTextThesaurus = (event) => {
    setStateOrigin({ ...stateOrigin, description: event.target.value });
  };

  const putThesaurus = (e) => {
    e.preventDefault();

    const { id, hasIntegration, description, order } = stateOrigin;

    appContext.setShowGlassLoading(true);
    putService(`thesaurus/origins`, { id, hasIntegration, description, order })
      .then((e) => {
        appContext.setShowGlassLoading(false);
        appContext.setCurrentStepBox("");
        appContext.sendMessage("Thesaurus editado com sucesso!", "success");
        getOrigins();
        setCurrentOrigin(stateOrigin);
      })
      .catch((e) => {
        appContext.setShowGlassLoading(false);
      });
  };

  return (
    <div id="UpdateOrigins">
      <ScriptBox
        open={appContext.current.step === "updateOrigins"}
        title={`Editar ${currentOrigin.thesaurusOrigin.value}`}
        icon={<EditIcon color="#FFF" />}
        marginBottom={true}
      >
        <form
          className="contentFormEditThesaurus"
          onSubmit={(ev) => putThesaurus(ev)}
        >
          <div className="formItem">
            <Textarea
              maxRows={16}
              label="Nota de escopo"
              placeholder="Digite a definição do termo"
              handleChange={handleChangeTextThesaurus}
              value={stateOrigin.description}
            />
          </div>

          <BoxFooter>
            <SimpleButton
              text="Cancelar"
              classButton="buttonCancel large"
              dataQA=""
              clickEvent={() => appContext.setCurrentStepBox("closed")}
            />

            <Button
              text={`Salvar ${currentOrigin.thesaurusOrigin.value}`}
              classButton="buttonGreen large"
              typeButton="submit"
              dataQA="button-prospect-new-save"
            />
          </BoxFooter>
        </form>
      </ScriptBox>
    </div>
  );
};

export default UpdateOrigins;

import React from "react";
import "./styles/FileDetailsChaptersOrEpisodes.scss";


const FileDetailsChaptersOrEpisodes = (props) => {
  const { file } = props;

  const dateFields = [
    "Entrega",
    "Parecer conteúdo",
    "Parecer Produção",
    "Aprov. Dir. Gênero",
    "Liberação",
    "Notes do Canal",
  ];

  return (
    <div className="fileDetailsChaptersOrEpisodes">
    <div className="cellDatesRowMobile">
        {dateFields.map((field, index) => (
          <div className="datesCellMobile" key={index}>
            <div className="datesTitleMobile">{field.toUpperCase()}</div>
            <div className="datesAggroupMobile">
              <div className="datesMobile">
                <div className="dateMobile">ENTREGUE</div>
              </div>
              <div className="datesMobile">
                <div className="dateMobile" style={{fontWeight:"lighter"}}>{file[index]}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileDetailsChaptersOrEpisodes;

import React, { useContext, useEffect, useState } from "react";
import "./styles/EditRelatedContent.scss";
import Switch from "../../../components/Switch/Switch";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import Button from "../../../components/Button/Button";
import AppContext from "../../../context/appContext";
import SheetContext from "../../../context/sheetContext";
import { patchService } from "../../../services/script-api";
import AssociatedProject from "../../../components/AssociatedProject/AssociatedProject";
import AssociatedSearch from "../../../components/AssociatedSearch/AssociatedSearch";

const EditRelatedContent = (props) => {
  const { data, id } = props;

  const context = useContext(AppContext);
  const sheetContext = useContext(SheetContext);

  const [stateToggleAssociation, setStateToggleAssociation] = useState(false);
  const [stateToggleResearch, setStateToggleResearch] = useState(false);
  const [stateAssociatedProjects, setStateAssociatedProjects] = useState([]);
  const [stateAssociatedResearches, setStateAssociatedResearches] = useState(
    []
  );

  useEffect(() => {
    const projectAssociations =
      !!data.associations &&
      data.associations.filter((item) => item.contentType !== "Pesquisa");
    const editableAssociations = projectAssociations.filter(
      (item) => !!item.editable
    );
    const hasAssociatedProjects = !!editableAssociations.length;
    const hasAssociatedResearches =
      !!data.associations.length &&
      !!data.associations.filter((item) => item.contentType === "Pesquisa")
        .length;
    setStateAssociatedProjects(
      hasAssociatedProjects ? compileSheetAssociatedProjects() : []
    );
    setStateAssociatedResearches(
      hasAssociatedResearches ? compileSheetAssociatedResearches() : []
    );
    setStateToggleAssociation(hasAssociatedProjects);
    setStateToggleResearch(hasAssociatedResearches);
    // eslint-disable-next-line
  }, [data.associations]);

  const compileSheetAssociatedProjects = () => {
    let projects = [];
    let projectAssociations = data.associations.filter(
      (item) => item.contentType !== "Pesquisa"
    );
    let editableAssociations = projectAssociations.filter(
      (item) => !!item.editable
    );

    if (!!editableAssociations.length) {
      editableAssociations.forEach((el) => {
        projects.push({ contentId: el.contentId, title: el.title });
      });
    }
    return projects;
  };

  const compileSheetAssociatedResearches = () => {
    let content = [];
    let researchAssociations = data.associations.filter(
      (item) => item.contentType === "Pesquisa"
    );

    if (!!researchAssociations.length) {
      researchAssociations.forEach((el) => {
        content.push({ contentId: el.contentId, title: el.title });
      });
    }
    return content;
  };

  const toggleAssociation = () =>
    setStateToggleAssociation(!stateToggleAssociation);
  const toggleResearch = () => setStateToggleResearch(!stateToggleResearch);

  const handleChangeAssociatedResearch = (data) => {
    let researches = [];

    if (!!data && !!data.length) {
      data.forEach((item) => {
        researches.push({ contentId: item.value, title: item.label });
      });
    }
    setStateAssociatedResearches(researches);
  };

  const compileAssociatedProjects = (data) => {
    let e = [];
    if (!!data && !!data.length) {
      data.forEach((item) => {
        e.push({ value: item.contentId, label: item.title });
      });
    }
    return e;
  };

  const compileAssociatedResearches = (data) => {
    let e = [];
    if (!!data && !!data.length) {
      data.forEach((item) => {
        e.push({ value: item.contentId, label: item.title });
      });
    }
    return e;
  };

  const compileAssociationProjects = () => {
    let projects = {
      typeAssociationId: null,
      contentsId: [],
    };

    !!stateAssociatedProjects.length &&
      stateAssociatedProjects.forEach((project) => {
        const contentsIdList = project.contentId;
        projects.contentsId.push(contentsIdList);
      });

    return projects;
  };

  const compileAssociationResearchs = () => {
    let list = [];

    stateAssociatedResearches.forEach((researchs) => {
      list.push(researchs.contentId);
    });

    return list;
  };

  const handleChangeAssociatedProjects = (data) => {
    let projects = [];

    if (!!data && !!data.length) {
      data.forEach((item) => {
        projects.push({ contentId: item.value, title: item.label });
      });
    }
    setStateAssociatedProjects(projects);
  };

  const submitIsValid = () => {
    if (stateToggleAssociation && stateToggleResearch) {
      return (
        !!stateAssociatedProjects.length && !!stateAssociatedResearches.length
      );
    } else if (stateToggleAssociation) {
      return !!stateAssociatedProjects.length;
    } else if (stateToggleResearch) {
      return !!stateAssociatedResearches.length;
    }
  };

  const _updateDataComplement = (ev) => {
    ev.preventDefault();

    let obj = {
      contentId: id,
      projects: stateToggleAssociation
        ? [compileAssociationProjects()]
        : [],
      researchesIds: stateToggleResearch
        ? compileAssociationResearchs()
        : [],
    };

    context.setShowGlassLoading(true);

    patchService("contents/associations", obj)
      .then(() => {
        context.setShowGlassLoading(false);
        context.sendMessage("Ficha atualizada com sucesso!");
        context.setCurrentStepBox("closed");
        sheetContext._getProjectSheetResearch(id, "researches");
      })
      .catch((e) => {
        context.setShowGlassLoading(false);
        context.sendMessage("Erro ao atualizar a ficha!", "error");
        // context.setCurrentStepBox('closed')
        sheetContext._getProjectSheetResearch(id, "researches");
      });
  };

  return (
    <form
      onSubmit={(e) => _updateDataComplement(e)}
      className="editRelatedContent researchAssociations"
    >
      <div className="formFieldColumn">
        <Switch
          blockLabel="Associar a projeto?"
          event={() => toggleAssociation()}
          switchOn={stateToggleAssociation}
        />

        {stateToggleAssociation && (
          <div className="formField" style={{ zIndex: 10 }}>
            <AssociatedProject
              handleChange={handleChangeAssociatedProjects}
              value={compileAssociatedProjects(stateAssociatedProjects)}
              defaultValue={compileAssociatedProjects(stateAssociatedProjects)}
            />
          </div>
        )}
      </div>

      <div className="formFieldColumn">
        <Switch
          blockLabel="Associar a outra pesquisa?"
          event={() => toggleResearch()}
          switchOn={stateToggleResearch}
        />

        {stateToggleResearch && (
          <div className="formField" style={{ zIndex: 10 }}>
            <AssociatedSearch
              id={data.id}
              handleChange={handleChangeAssociatedResearch}
              value={compileAssociatedResearches(stateAssociatedResearches)}
              defaultValue={compileAssociatedResearches(
                stateAssociatedResearches
              )}
            />
          </div>
        )}
      </div>

      <BoxFooter>
        <Button
          text="Salvar"
          toValidate={true}
          isValidForm={submitIsValid()}
          classButton="buttonGreen large"
          typeButton="submit"
          dataQA="button-prospect-new-save"
        />
      </BoxFooter>
    </form>
  );
};

export default EditRelatedContent;

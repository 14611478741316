import FilterButton from "../../../components/FilterButton/FilterButton";
import React from "react";

const HeaderList = (props) => {
  const { analysts, toggleUser, toggleAll, checkAll, setCheckAll } = props;

  return (
    <ul className="activitiesListHeader">
      <li className="activitiesItemHeader activityTitle">
        <span className="activityHeaderTitle">Título</span>
      </li>

      <li className="activitiesItemHeader activityAttachments">
        <span className="activityHeaderTitle">Tipo/Anexos</span>
      </li>

      <li className="activitiesItemHeader activityAnalist">
        <FilterButton
          text="Analista"
          options={analysts}
          toggleUser={toggleUser}
          toggleAll={toggleAll}
          checkAll={checkAll}
          setCheckAll={setCheckAll}
        />
      </li>

      <li className="activitiesItemHeader activityStatus">
        <span className="activityHeaderTitle">Status</span>
      </li>

      <li className="activitiesItemHeader activityCreatedAt">
        <span className="activityHeaderTitle">Criada em</span>
      </li>

      <li className="activitiesItemHeader activityConfig" />
    </ul>
  );
};

export default HeaderList;

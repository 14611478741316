import React, { useContext, useState } from "react";
import "./styles/SearchResults.scss";
import AppContext from "../../../context/appContext";
import TypeIconReturner from "../../../components/TypeIconReturner/TypeIconReturner";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import * as latinize from "latinize";
import ResultsHeader from "./ResultsHeader/ResultsHeader";
import Storyline from "./Storyline/Storyline";
import TitleHistories from "./TitleHistories/TitleHistories";
import ComplementariesInfo from "./ComplementariesInfo/ComplementariesInfo";
import DetailsModal from "./DetailsModal/DetailsModal";
import CreatorAndResearcher from "./CreatorAndResearcher/CreatorAndResearcher";

const SearchResults = (props) => {
  const { list, reorder, handleReorderFilter } = props;

  const appContext = useContext(AppContext);

  const handleClick = () => {
    appContext.setRedirectSearch(false);
  };

  const [stateDetailsBox, setStateDetailsBox] = useState({
    open: false,
    currentItem: "",
  });

  const handleDetailsItem = (item) => {
    setStateDetailsBox({
      ...stateDetailsBox,
      open: true,
      currentItem: item,
    });
  };

  const closedDescription = () => {
    setStateDetailsBox({
      ...stateDetailsBox,
      open: false,
    });
  };

  return (
    <div className="searchResultsTable">
      {!!list.length && (
        <ResultsHeader
          reorder={reorder}
          handleReorderFilter={handleReorderFilter}
        />
      )}

      {list.map((item, index) => {
        return (
          <div className="searchListItem" key={index}>
            <div className="searchListContentInfo">
              <div className="typeIcon">
                <TypeIconReturner
                  type={item.type}
                  responsiveIcon={true}
                />
              </div>

              <div className="contentTitle">
                <Link
                  to={appContext.redirectToSheet(item.type, item.id)}
                  onClick={() => handleClick()}
                  className="titleText"
                >
                  <Highlighter
                    highlightClassName="markMatch"
                    searchWords={appContext.checkPronouns(
                      appContext.searchTerm
                    )}
                    autoEscape={true}
                    textToHighlight={item.title}
                    sanitize={latinize}
                  />
                </Link>

                {(!!item.creators.length || !!item.researchers.length || !!item.authors) && (
                  <div className="contentCreatorSeparator" />
                )}

                <CreatorAndResearcher
                  type={item.type}
                  creators={item.creators}
                  authors={item.authors}
                  researchers={item.researchers}
                />
              </div>
            </div>

            {!!item.histories.length && (
              <TitleHistories histories={item.histories} />
            )}

            {!!item.storyline && (
              <Storyline
                storyline={item.storyline}
                handleDetailsModal={() => handleDetailsItem(item)}
              />
            )}

            <ComplementariesInfo
              complementaries={
                item.type !== "Pesquisa"
                  ? [
                      { field: "Formato", name: item.format },
                      ...item.complementaries,
                    ]
                  : [...item.complementaries]
              }
            />
            <li className="contentDate">
              {appContext.formatDate(
                appContext.convertDatePtBr(item.receivedAt)
              )}
            </li>
          </div>
        );
      })}

      {stateDetailsBox.open && (
        <DetailsModal
          details={stateDetailsBox}
          close={() => closedDescription()}
        />
      )}
    </div>
  );
};

export default SearchResults;

import React from "react";

const FeatherIcon = () => {
  return (
    <div className="featherIcon">
      <svg
        width="32"
        height="30"
        viewBox="0 0 32 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.8102 19.8177C29.9856 14.9525 32 8.25305 32 1.7515C32 1.41163 31.8648 1.08567 31.6242 0.845345C31.3836 0.605018 31.0572 0.470001 30.7169 0.470001C28.1866 0.470004 26.4892 0.71038 25.203 1.05787C24.2071 1.32694 23.4563 1.66743 22.8733 1.93181C22.716 2.00317 22.5707 2.06904 22.4363 2.12641C22.0201 2.30405 21.7283 2.68757 21.6686 3.13568C21.3341 5.64475 20.6671 7.93153 19.3359 10.2643L19.3213 10.1794C19.0539 8.63123 18.6757 6.78191 18.1746 5.41625C18.0378 5.04367 17.736 4.75537 17.3572 4.63557C16.9784 4.51577 16.5654 4.57798 16.2389 4.80403C9.96408 9.14776 6.0607 16.6047 5.73747 24.3914C4.9342 24.9119 4.32817 25.2517 3.78637 25.5554C3.31484 25.8198 2.89197 26.0569 2.43008 26.3621C1.30985 27.1023 0.315917 28.478 0 29.4318C0.0518 29.4318 0.103549 29.4319 0.155246 29.4319C1.31379 29.433 2.44638 29.434 3.54557 28.8984C4.54603 28.4108 6.0517 27.5351 7.25891 26.757C15.448 27.5871 21.6619 24.6513 25.8102 19.8177ZM16.264 8.00457C11.9754 11.5852 9.1458 16.9434 8.44952 22.6782C10.4771 21.1364 12.6408 19.0578 14.2928 17.3237C14.7812 16.811 15.5934 16.7908 16.1068 17.2787C16.6202 17.7665 16.6404 18.5777 16.1519 19.0904C14.6417 20.6757 12.5813 22.6862 10.5047 24.3277C16.357 24.1878 20.7707 21.7515 23.8618 18.1498C27.2924 14.1523 29.1531 8.64225 29.4044 3.05847C27.7829 3.12476 26.6841 3.31284 25.8731 3.53194C25.1352 3.73132 24.634 3.95122 24.1078 4.18875C23.5371 7.76025 22.2735 11.0194 19.579 14.4795C19.2672 14.8799 18.7483 15.0589 18.2556 14.9361C17.7628 14.8133 17.389 14.4118 17.302 13.9121C17.2734 13.7478 17.2319 13.4606 17.1811 13.1097C17.1604 12.9665 17.1381 12.8127 17.1146 12.6523C17.0304 12.0783 16.923 11.371 16.7925 10.6151C16.6418 9.74296 16.4648 8.83413 16.264 8.00457Z"
          fill="#17B4E1"
        />
      </svg>
    </div>
  );
};

export default FeatherIcon;

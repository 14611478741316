const dramaturgyParecerFieldTypes = [
  "Storyline",
  "Resumo do Parecer",
  "Pontos Fortes",
  "Pontos de Atenção",
  "Parecer Completo",
  "Conclusão",
  "Adequação",
  "Texto Livre",
];

const dramaturgyCertificacaoFieldTypes = [
  "Storyline",
  "Resumo do Parecer",
  "Pontos Fortes",
  "Pontos de Atenção",
  "Parecer Completo",
  "Conclusão",
  "Adequação",
  "Texto Livre",
];

const dramaturgyRelatorioTextoFieldTypes = [
  "Análise de trama",
  "Resumo",
  "Pontos de Atenção",
  "Observações",
  "Palavras-chave",
  "Texto Livre",
];

const dramaturgyRelatorioVideoFieldTypes = [
  "Pontos de Atenção",
  "Observações",
  "Texto Livre",
];

const varietyParecerFieldTypes = [
  "Logline",
  "Resumo do Parecer",
  "Pontos Fortes",
  "Pontos de Atenção",
  "Parecer Completo",
  "Conclusão",
  "Adequação",
  "Texto Livre",
];

const varietyCertificacaoFieldTypes = [
  "Logline",
  "Resumo do Parecer",
  "Pontos Fortes",
  "Pontos de Atenção",
  "Parecer Completo",
  "Conclusão",
  "Adequação",
  "Texto Livre",
];

const varietyRelatorioTextoFieldTypes = [
  "Análise de trama",
  "Resumo",
  "Pontos de Atenção",
  "Observações",
  "Palavras-chave",
  "Texto Livre",
];

const varietyRelatorioVideoFieldTypes = [
  "Resumo de Convidados",
  "Palavras-chave",
  "Pontos de Atenção",
  "Observações",
  "Texto Livre",
];

const setTypesFields = {
  dramaturgyParecerFieldTypes,
  dramaturgyCertificacaoFieldTypes,
  dramaturgyRelatorioTextoFieldTypes,
  dramaturgyRelatorioVideoFieldTypes,
  varietyParecerFieldTypes,
  varietyCertificacaoFieldTypes,
  varietyRelatorioTextoFieldTypes,
  varietyRelatorioVideoFieldTypes,
};

export default setTypesFields;

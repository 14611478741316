import React from "react";
import "./styles/GroupSelect.scss";
import Select from "react-select";
import SelectArrowIcon from "../../../../components/VectorIcons/SelectArrowIcon";

const DropdownIndicator = ({ innerRef, innerProps, isFocused }) => {
  return (
    <div
      className={`dropdownIndicator ${isFocused ? "isFocused" : ""}`}
      ref={innerRef}
      {...innerProps}
    >
      <SelectArrowIcon />
    </div>
  );
};

const GroupSelect = (props) => {
  const { options, value, defaultValue, placeholder, getGroupsId } = props;

  return (
    <Select
      className="groupSelect"
      classNamePrefix="groupSelect"
      placeholder={placeholder}
      options={options}
      onChange={getGroupsId}
      value={value}
      defaultValue={defaultValue}
      components={{ DropdownIndicator }}
      isSearchable={false}
    />
  );
};

export default GroupSelect;
